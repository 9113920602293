import axios from "axios";
import $ from "jquery";

const qs = require('querystring');

export const authService = {
    setSession,
    clearData,
    renewAccessToken,
    logout,
    isAuthenticated,
    updateUserData,
    getUserDetailsFromLocalStorage,
    getRefreshToken,
    setRefreshToken,
    setPromotionData,
    getPromotionData,
    setGeoLocation

};

function setSession(authResult, response) {
    localStorage.setItem('userData', JSON.stringify(authResult));
    setRefreshToken(response.headers['authorization']);
}

function setPromotionData(data) {
    localStorage.setItem('promotion', data);
}

function setGeoLocation() {
    if(navigator.geolocation && fnBrowserDetect() !== "safari") {
        navigator.geolocation.watchPosition((position) => {
            localStorage.setItem('latitude', position.coords.latitude);
            localStorage.setItem('longitude', position.coords.longitude);
        });
    } else {
        localStorage.setItem('latitude', undefined);
        localStorage.setItem('longitude', undefined);
    }
    // localStorage.setItem('latitude', undefined);
    // localStorage.setItem('longitude', undefined);
    setUserIp();
}

function setUserIp() {
    $.getJSON('https://api.ipify.org?format=json', function(data) {
        localStorage.setItem("ip", data.ip);
    });
}

function getPromotionData() {
    return localStorage.getItem('promotion');
}

function updateUserData(authResult) {
    localStorage.setItem('userData', JSON.stringify(authResult));
}

function setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
}

function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

function logout() {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/user/logout',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(() => {
            clearData();
            resolve();
        }).catch((e) => {
            console.log(e);
            reject(e);
        });
    });

}

function renewAccessToken() {
    return new Promise((resolve, reject) => {
        let url = process.env.REACT_APP_userServiceURL + '/user/renew/token';

        return axios({
            method: 'post',
            url: url,
            data: qs.stringify({
                refreshToken: authService.getRefreshToken()
            }),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then((response) => {
            if (
                response &&
                response.data &&
                response.data.statusCode === 200
            ) {
                console.log(response.data);
                resolve(response);
            } else {
                console.log(response);
                reject(response);
            }
        }).catch((e) => {
            console.log(e);
            reject(e);
        });
    });
}

function clearData() {
    localStorage.removeItem('userData');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('wenat');
}

function isAuthenticated() {
    const refreshToken = getRefreshToken();
    const data = getUserDetailsFromLocalStorage();

    if (refreshToken === null || refreshToken === "" || refreshToken === undefined) {
        clearData();
        return false;
    } else {
        return data !== null && !data.userDataPending;
    }
}

function getUserDetailsFromLocalStorage() {
    let userData = localStorage.getItem('userData');
    if (userData === undefined || userData === null) {
        return null;
    }
    return JSON.parse(userData);
}

function fnBrowserDetect() {           
    let userAgent = navigator.userAgent;
    if(userAgent.match(/chrome|chromium|crios/i)){
        return "chrome";
    } else if(userAgent.match(/firefox|fxios/i)){
        return "firefox";
    } else if(userAgent.match(/safari/i)){
        return "safari";
    } else if(userAgent.match(/opr\//i)){
        return "opera";
    } else if(userAgent.match(/edg/i)){
        return "edge";
    }
    return "No browser detection";     
}
