import React, { Component } from "react";
import { authService } from "../../../auth";
import { NavLink } from "react-router-dom";
import Images from "../../../commons/Images";
import { detailsPageRedirection, getCDNUrl } from "../../../commons/CommonFunctions";
import ReadMoreReadLess from "../../../commons/ReadMoreReadLess";
import MutualConnect from "../../../commons/MutualConnect";
import Follow from "../../../commons/Follow";
import {redirectToProfile, redirectToCircle, getFormattedDate} from './HashtagsCommonFunction';
import { defaultProjectImage } from "../../../project/component/Studio/StudioContentCommonFunction";
import SavePostButton from "../../../post/SavePostButton";
import FeaturePostButton from "../../../post/FeaturePostButton";
import CommonHashTagUserSection from "./CommonHashTagUserSection";
class CommonHashTagsPhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            photoListMap: [],
            photoListOriginalMap: [],
            paidContentPostLock: false
        }
    }

    getOptimizedImage = (personalInfo) => {
        if (
          personalInfo.resizedProfileImages &&
          personalInfo.resizedProfileImages["200x200"] != null
        ) {
          return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.userProfileImage;
    };

    componentDidMount() {
        let photoListMap = [];
        let photoListOriginalMap = [];
        this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds.map((attachment, index) => {
            photoListOriginalMap.push(getCDNUrl(attachment.attachmentUrl));            
            if (window.innerWidth > 600 && attachment.resizedImages && attachment.resizedImages['600x600'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['600x600']));
            } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['400x400'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['400x400']));
            } else {
                photoListMap.push(getCDNUrl(attachment.attachmentUrl));
            }
        })
        if (this.props.item && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost) {
            photoListMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
            photoListOriginalMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
            this.setState({
                paidContentPostLock: true
            })
        }

        this.setState({ 'photoListMap': photoListMap, 'photoListOriginalMap': photoListOriginalMap, });
    }

    render() {
        return (
            <div className="postbookMlist" key={this.props.idx}>
                <div className="postbookMlistinn">
                    <div className="postbookMlisttop">
                        <div className="postbookMhead">
                            {!this.props.item.userEntityType ? (
                                <figure
                                    style={{ cursor: "pointer" }}
                                    className="complogo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToProfile(this.props.item.userCustomUrl);
                                    }}
                                >
                                    <img
                                        src={
                                            this.props.item.userProfileImage &&
                                                this.props.item.userProfileImage !== ""
                                                ? getCDNUrl(
                                                    this.getOptimizedImage(this.props.item)
                                                )
                                                : this.props.item.userType !== "COMPANY"
                                                    ? "https://cdn.dscovr.com/images/users1.png"
                                                    : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                        }
                                        alt=""
                                    />
                                </figure>
                            ) : (
                                <figure
                                    style={{ cursor: "pointer" }}
                                    className="complogo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToCircle(
                                            this.props.item.params && this.props.item.params["circleSlug"]
                                        );
                                    }}
                                >
                                    <img
                                        src={
                                            this.props.item.params &&
                                                this.props.item.params["circleImage"] != null
                                                ? getCDNUrl(
                                                    this.props.item.params &&
                                                    this.props.item.params["circleImage"]
                                                )
                                                : "https://cdn.dscovr.com/images/circleDefault.webp"
                                        }
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                "https://cdn.dscovr.com/images/circleDefault.png";
                                        }}
                                        alt=""
                                    />
                                </figure>
                            )}
                            <div>
                                {/* {!this.props.item.userEntityType ? (
                                    <h5 className= {"compuser" + (this.props.item.userType === "COMPANY" ? " orgnzName" : "")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToProfile(
                                                this.props.item.userCustomUrl
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}>{this.props.item.userName}</h5>
                                ) : (
                                    <h5
                                        className="compuser"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToCircle(
                                                this.props.item.params &&
                                                this.props.item.params["circleSlug"]
                                            );
                                        }}
                                    >
                                        {this.props.item.params["circleTitle"]}
                                    </h5>
                                )} */}
                                 <CommonHashTagUserSection  item = {this.props.item} />
                                <div className="d-flex hpostdtls">
                                    {this.props.item && this.props.item.country &&
                                        <span className="address"><i
                                            className="iLocation" />{this.props.item && this.props.item.country}</span>}
                                    <span
                                        className="designation"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item.id)}>
                                            {getFormattedDate(this.props.item.createTime)}
                                        </a>
                                    </span>
                                    {this.state.loginSuccess === true ? ((this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId) ?
                                        <MutualConnect connectDepth={this.props.item.connectDepth}></MutualConnect> : null) : null}
                                    {this.state.loginSuccess === true && (this.props.item.connectDepth !== 1 && this.props.item.connectDepth !== 2) ? !this.props.item.userEntityType ? (this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === "PENDING_CONNECT" ?
                                        <>
                                            <NavLink className="follow follow-link active" to="/connected" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                        </>
                                        : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'REQUEST_RECEIVED' ?
                                            <>
                                                <NavLink className="follow follow-link active" to="/manage-invitation" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                            </>
                                            : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                                <></>

                                                : this.props.item && (!this.props.item.connectDepth || (this.props.item.connectDepth && this.props.item.connectDepth < 1)) &&
                                                <Follow followed={this.props.item.followed} userId={this.props.item.userId} deactivated={this.props.item.deactivated} />
                                    ) : /*((this.state.loginSuccess === true && this.props.item.userEntityType != null && this.props.item.userEntityType === "CIRCLE") ?
                                        <EntityFollow entityType={'CIRCLE'} entityId={this.props.item.params['circleId']}
                                            followed={this.props.item.followed}
                                            userId={this.state.userData.userId}
                                            isCircle={true} /> : null)*/ null : null
                                    }
                                </div>
                            </div>
                        </div>
                        {this.props.pageType === "BOOKMARK" && 
                            <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={true} entityType={"POST"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                        }
                        {this.props.pageType === "FEATURE_POST" && /* (this.props.item.userId === this.state.userData.userId) &&  */
                            <FeaturePostButton key={this.props.item.id} item={this.props.item} pageType={this.props.pageType} onSuccessFeaturePost={this.props.onSuccessFeaturePost} />
                        }
                    </div>
                    <Images
                        images={this.state.photoListMap} countFrom={3}
                        photoListOriginalMap={this.state.photoListOriginalMap} paidContentPostLock={this.state.paidContentPostLock}
                    />
                    <div className="postbookMconttop">
                        {this.props.item.description && this.props.item.description !== "" && (
                            <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item.id)}>
                                <ReadMoreReadLess
                                    id={this.props.item.id}
                                    description={this.props.item.description}
                                    item={this.props.item}
                                />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CommonHashTagsPhoto;