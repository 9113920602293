import React from "react";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ErorrMsgPopUp = withReactContent(alertPopUp)

export const userSubscriptionError = (type, message, entityType) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then((result) => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <>
                {message ? (type === "DONATE_NOW" ? <p>{message}</p> : <p>You can raise fund for {message} {entityType === "CIRCLE" ? (message > 1 ? "comminities" : "community") : entityType.toLowerCase()}{entityType !== "CIRCLE" && message > 1 && "s"} under your subscription plan.</p>) : type === "BANK" ? 
                    <><p>Please update your bank details.</p><a class="styleBtn" href={'/security-and-login'}>Update now</a></> : 
                    type === "DATE_TOP_UP" ? <p>Please choose one time frame.</p> :
                    type === "DATE_UPDATE" ? <p>Donation already started.</p> :
                    <p>Please select atleast one {entityType !== "CIRCLE" ? entityType.toLowerCase() : "community"}.</p>
                }
            </>,
        })
    })
}

export const getFormattedDate = (milliseconds) => {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (!milliseconds) {
        return "";
    }
    var utcSeconds = milliseconds;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear();
}

export const donationModalSwal = (count, type) => {
    return ({
        title: ` You can accept funds for ${count} ${type === "CIRCLE" ? (count > 1 ? "communities" : "community") : type.toLowerCase()}${type !== "CIRCLE" && count > 1 ? 's' : ''}.`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

export const donationModalForEntity = (type) => {
    return ({
        title: `You can accept funds for this ${type === "CIRCLE" ? "community" : type.toLowerCase()}.`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Later',
        denyButtonText: 'Ignore',
        allowOutsideClick: false,
    })
};

export const donationTopUpForEntity = (type, entityType) => {
    return ({
        title: donationTopUpForEntityMessage(type, entityType),
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: type === "BRONZE" ? 'Proceed' : 'Top Up Now',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

export const donationTopUpForEntityMessage = (type, entityType) => {
    return type === "BRONZE" ? `Please upgrade your subscription plan or TopUp to accept funds in your ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()}.` : `You have reached maximum limit of ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()} to raise funds under the plan.`;
}

export const additionalDaysTopUpForEntity = (entityType) => {
    return ({
        title: `Please upgrade your subscription plan to add days to the fund raise in your ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()}.`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

export const getOptimizedImage = (personalInfo, imageFieldName) => {
    if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
        return personalInfo.resizedProfileImages['200x200'];
    }
    return personalInfo[imageFieldName];
}

export const donationInfoTexts = (type, entityType) => {
    if(type === "VIEW") {
        return `Total number of WeNaturalists members who have seen your ${entityType === 'CIRCLE'?'community':entityType.toLowerCase()}`;
    } else if(type === "DONORS") {
        return "Total no. of contributions processed by WeNaturalists members";
    } else {
        return "Total amount WeNaturalists members have proceeded to contribute";
    }
}

export const userDonationSuccess = (entityType, pageType) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then((result) => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <p>{pageType == "ENTITY_DONATE" ? `You can now accept funds for this ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()}.` : `You can start raising funds for your selected ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()}.`}</p>,
        })
    })
}

export const buttonShowDetails = (donationId, type, entityType) => {
    return (
        <a
            href="javascript:void(0)"
            className="btninfo"
            data-tip={donationInfoTexts(type, entityType)}
            data-for={"donationInfoTexts" + donationId}
            data-place='top'
            data-event='click'
        >
            <i className="iInfo_I" style={{ position: 'static' }}></i>
        </a>
    )
}

export const actionProjectDonationSwal = (actionType, entityType) => {
    return ({
        title: `You are raising funds in this ${entityType === "CIRCLE" ? "community" : "project"}. ${actionType === "HIDE" ? "Are you sure you want to hide the project?" : actionType === "CANCEL" ? "Are you sure you want to cancel the project?" : actionType === "DELETE" ? `Are you sure you want to delete the ${entityType === "CIRCLE" ? "community" : "project"}?` : "Are you sure you want to make the project private?"}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
    })
};

export const disclaimerText = (entityType) => {
    return `WeNaturalists merely acts as a medium of collaboration between all its users. WeNaturalists does not take any responsibility for the transaction executed between the donor and the donee, for the ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()} offerings and its commitments, utilization of funds or verification of the same. For any query related to a specific ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()}, the transaction, use of funds, donor certificates or receipts, please contact the ${entityType === "CIRCLE" ? "community" : entityType.toLowerCase()} admin.`
}
