import React from 'react'
import ArticlePostItem from "./ArticlePostItem";
import PhotosPostItem from "./PhotosPostItem";
import AudioPostItem from "./AudioPostItem";
import QuotePostItem from "./QuotePostItem";
import VideoPostItem from "./VideoPostItem";
import {authService} from "../auth";
import axios from "axios";
import LinkPostItem from "./LinkPostItem";

import httpService from '../AxiosInterceptors';
import InfiniteScroll from "react-infinite-scroller";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import SharePostItem from "./SharePostItem";
import $ from "jquery";
import cloneDeep from 'lodash/cloneDeep';
import LazyLoad from 'react-lazyload';
import {Link} from "react-router-dom";
import { userAgentGetData } from '../commons/CommonFunctions';
import ForumPostItem from './ForumPostItem';
import PollPostItem from './PollPostItem';
import PresentationPostItem from './PresentationPostItem';

httpService.setupInterceptors();

const currentyYear = new Date().getFullYear();
class LatestPostList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            postList: [],
            userData: authService.getUserDetailsFromLocalStorage(),
            responseMessage: '',
            postMaxSize: 5,
            thresholdHeight: 1000,
            showZeroData:false,
        };
    }

    componentDidMount() {
        this.getLatestPosts(0, this.state.postMaxSize, false);
        this.checkZeroData();
    }

    callback_fn = (userId) => {
        this.updateFollowList(userId)
    }

    updateFollowList = (userId) => {
        if (this.state.postList && this.state.postList.content && this.state.postList.content.length > 0) {
            let postList = cloneDeep(this.state.postList);
            postList && postList.content && postList.content.forEach((item) => {
                if (item.userId === userId) {
                    item.followed = !item.followed;
                }
            });
            this.setState({'postList': postList});
        }
    }

    checkZeroData() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/entity/network/check_zero_conections?userId=' + this.state.userData.userId + userAgentGetData(),
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data  && response.status === 200) {

                this.setState({
                    'showZeroData': response.data.body,
                });
            }
        }).catch((err) => {
            console.log(err);

        });
    }

    getLatestPosts(page, size, concatWithPrevious) {
        let hideFlag = this.props.hideFlag ? true : false;
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/post/getNewsFeed?userId=' + this.state.userData.userId
                + '&newsFeedType=LATEST&page=' + page + '&size=' + size + userAgentGetData(),
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.status === 200) {
                let pagedData = response.data.body;
                if (concatWithPrevious === true && this.state.postList && this.state.postList.content) {
                    pagedData.content = this.state.postList.content.concat(pagedData.content);
                }
                this.setState({
                    'postList': pagedData,
                    'responseMessage': response.data.message,
                    'isResultLoading': false
                }, () => {
                    let postListHeight = $('.hpostlist').height();
                    if (postListHeight && postListHeight > 0) {
                        if (!this.state.initialThresholdHeight) {
                            this.setState({
                                'initialThresholdHeight': postListHeight,
                                'thresholdHeight': postListHeight * 0.6
                            });
                        }
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                isResultLoading: false,
                responseMessage: err.response.data.status
            });
        });
    }

    fetchMorePostData() {
        let postList = this.state.postList;
        if (this.state.postList && this.state.postList.page && this.state.postList.page.pageable) {
            let pageable = postList.page.pageable;
            if(pageable.pageNumber < 40){
                this.setState({isResultLoading: true});
                this.getLatestPosts(pageable.pageNumber + 1, this.state.postMaxSize, true);
            } 
        }
    }

    removePostListItem = (id) => {
        let postItemList = this.state.postList;
        const index = postItemList && postItemList.content.findIndex(data => data.id === id);
        if (index > -1) {
            postItemList.content.splice(index, 1); 
        }
        this.setState({
          postList: postItemList
        });
        if(postItemList?.content.length === 0) {
            this.getLatestPosts(!postItemList.page?.last ? postItemList.page?.pageable?.pageNumber + 1 :  0, this.state.postMaxSize, false);
        }
        this.forceUpdate();
    }

    render() {
        let content;
        let latestForumId = this.state.postList && this.state.postList.content && this.state.postList.content.filter((item) => item.postType === "FORUM");
        let latestPollId = this.state.postList && this.state.postList.content && this.state.postList.content.filter((item) => item.postType === "POLL");
        if ((this.state.postList && this.state.postList.content && this.state.postList.content.length === 0
            && this.state.responseMessage === "Success!") || (this.state.postList && this.state.postList.length === 0 && this.state.responseMessage === "Success!") || this.state.responseMessage == "400 BAD_REQUEST" ) {

            content = <div className="zdata zfeed">
                <div className="imgbox">
                    <img src="https://cdn.dscovr.com/images/zroProjectsActivity.webp"
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroProjectsActivity.png"}}
                    alt=""/>
                </div>
                <div className="desc">
                    <h2>Hello!</h2>
                    <h3>Welcome to this new world of nature.</h3>
                    {this.state.userData.type !== "COMPANY" ?
                        <div>
                            <p>Make new connections and explore possibilities.</p>
                            <p><Link to="/popular-users" className="zerotab">Connect Now</Link></p>
                        </div>
                        :
                        <div><p>Let the world learn about your work and activities.</p>
                            <p><a href="#addpostpop" data-toggle="modal">Post here</a></p></div>}
                </div>
            </div>
        } else if (this.state.postList && this.state.postList.content && this.state.postList.content.length > 0
            && this.state.responseMessage === "Success!" /*&& this.state.postList.empty === false*/) {
            content = <section className="hpostlist">
                {this.state.postList.content[0].showZeroData && this.state.userData.type !== "COMPANY" &&
                <div className="zdata zfeed">
                    <div className="imgbox">
                        <img src="https://cdn.dscovr.com/images/zroProjectsActivity.webp"
                             onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroProjectsActivity.png"}}
                             alt=""/>
                    </div>
                    <div className="desc">
                        <h2>Hello!</h2>
                        <h3>Welcome to this new world of nature.</h3>
                        {this.state.userData.type !== "COMPANY" ?
                            <div>
                                <p>Make new connections and explore possibilities.</p>
                                <p><Link to="/popular-users" className="zerotab">Connect Now</Link></p>
                            </div>
                            :
                            <div><p>Let the world learn about your work and activities.</p>
                            <p><a href="#addpostpop" data-toggle="modal">Post here</a></p></div>}
                    </div>
                </div>
                }
                <InfiniteScroll
                loadMore={this.fetchMorePostData.bind(this)}
                hasMore={!this.state.isResultLoading && this.state.postList && this.state.postList.page && !this.state.postList.page.last}
                threshold={this.state.thresholdHeight}
                loader={<section>
                    {Array(1)
                        .fill()
                        .map((item, index) => (
                            <SkeletonTheme>
                                <div className="hpostblock" key={index}>
                                    <figure className="userimg">
                                        <Skeleton circle={true} height={50} width={50}/>
                                    </figure>
                                    <div className="hpostblockinn">
                                        <div className="hposthead">
                                            <h3>
                                                <Skeleton height={36} width={`80%`}/>
                                            </h3>
                                            <div className="hpostdtls">
                                                <Skeleton height={36} width={`30%`}/>
                                            </div>
                                            <div className="hpostcontent">
                                                <div className="hpostconttop">
                                                    <p><Skeleton height={120} width={`100%`}/></p>
                                                </div>
                                            </div>
                                            <div className="card-metrics">
                                                <Skeleton width={`90%`}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </SkeletonTheme>
                        ))}
                </section>}
            >
                {
                    this.state.postList.content.map((item) => {
                        return item.postType === 'ARTICLE'
                            ? <ArticlePostItem enablePostSharing={true} item={item} key={item.id}
                                               callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/> 
                            : (item.attachmentIds && item.attachmentIds.length > 0)
                                ? (item.attachmentIds[0].attachmentType === 'VIDEO' ?
                                        <LazyLoad once><VideoPostItem enablePostSharing={true} item={item} key={item.id}
                                                                      callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/></LazyLoad>
                                        : (item.attachmentIds[0].attachmentType === 'AUDIO' ?
                                                <LazyLoad once><AudioPostItem enablePostSharing={true} item={item}
                                                                              key={item.id}
                                                                              callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/></LazyLoad>
                                                : (item.attachmentIds[0].attachmentType === 'PPT' || item.attachmentIds[0].attachmentType === 'PDF') ? 
                                                    <LazyLoad once><PresentationPostItem enablePostSharing={true} item={item} key={item.id}
                                                        callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear} /></LazyLoad> :
                                                                        <LazyLoad once><PhotosPostItem enablePostSharing={true} item={item}
                                                                                 key={item.id}
                                                                                 callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/>
                                                </LazyLoad>
                                        )
                                ) : (item && item.attachmentIds.length === 0 && item.paidPost && (item.attachmentType === "IMAGE" || item.attachmentType === "VIDEO"))
                                ? item.attachmentType === "IMAGE" ?
                                    <LazyLoad once>
                                        <PhotosPostItem enablePostSharing={true} item={item}
                                                                                 key={item.id}
                                                                                 callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/>
                                    </LazyLoad> : 
                                    item.attachmentType === "VIDEO" ? 
                                        <LazyLoad once>
                                            <VideoPostItem enablePostSharing={true} item={item} key={item.id}
                                                callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/></LazyLoad> : 
                                    <></>
                                : (item.postType === "FORUM") ?
                                    <LazyLoad once><ForumPostItem enablePostSharing={true} item={item} key={item.id}
                                        callbackFunc={this.getUserLatestPosts}
                                        callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear} latestForumId={latestForumId && latestForumId.length > 0 ? latestForumId[0].id : ""} /></LazyLoad>
                                    : (item.postType === "POLL") ?
                                        <LazyLoad once><PollPostItem enablePostSharing={true} item={item} key={item.id}
                                            callbackFunc={this.getUserLatestPosts}
                                            callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear} latestPollId={latestPollId && latestPollId.length > 0 ? latestPollId[0].id : ""} /></LazyLoad>
                                        : (item.postType === 'LINK')
                                            ? <LazyLoad once><LinkPostItem enablePostSharing={true} item={item} key={item.id}
                                                                        callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/></LazyLoad>
                                            : (item.sharedEntityId && item.sharedEntityId !== '')
                                                ? <SharePostItem enablePostSharing={true} item={item} key={item.id}
                                                                callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/>
                                                : <QuotePostItem enablePostSharing={true} item={item} key={item.id}
                                                                callback_fn={() => this.callback_fn(item.userId)} removePostListItem={this.removePostListItem} postSection="FEED" year={currentyYear}/>
                    })
                }
            </InfiniteScroll></section>
        } else {
            content = <section>
                {Array(9)
                    .fill()
                    .map((item, index) => (
                        <SkeletonTheme>
                            <div className="hpostblock" key={index}>
                                <figure className="userimg">
                                    <Skeleton circle={true} height={50} width={50}/>
                                </figure>
                                <div className="hpostblockinn">
                                    <div className="hposthead">
                                        <h3>
                                            <Skeleton height={36} width={`80%`}/>
                                        </h3>
                                        <div className="hpostdtls">
                                            <Skeleton height={36} width={`30%`}/>
                                        </div>
                                        <div className="hpostcontent">
                                            <div className="hpostconttop">
                                                <p><Skeleton height={120} width={`100%`}/></p>
                                            </div>
                                        </div>
                                        <div className="card-metrics">
                                            <Skeleton width={`90%`}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    ))}
            </section>;
        }
        return <div>{content}</div>
    }
}

export default LatestPostList
