import React from 'react'
import axios from 'axios'
import { authService } from '../auth';
import httpService from '../AxiosInterceptors';
import { Redirect } from "react-router-dom";
import * as _ from "lodash";
import ProfileDeactivation from "./ProfileDeactivation";
import uuid from "react-uuid";
import UserBankDetails from './UserBankDetails/UserBankDetails';
import UserHelpDeskDetails from './UserHelpDesk/UserHelpDeskDetails';
import { userAgentGetData } from '../commons/CommonFunctions';

httpService.setupInterceptors();
class SecurityAndLoginDetails extends React.Component {

    constructor(props) {
        super(props);
        let validation = {
            validatePassword: {
                uppercaseTest: new RegExp("^(?=.*[A-Z])"),
                minCharacterTest: new RegExp("^(?=.{8})"),
                style: { borderColor: "#b34c4c", padding: "10px" }
            },
            confirmPassword: {},
            newPassword: {},
            oldPassword: {}
        }
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            validation,
            errors: {},
            error: null,
            success: null,
            newPassword: '',
            upperCaseMsg: '1 Uppercase',
            deactivationKey : uuid(),
            minimumPasswordCharacter: "Password should be 8 characters",
            charCountCheckboxStatus : false,
            upperCaseCheckboxStatus : false
        };
    }


    componentDidMount() {
        window.$('.collapser').click(function () {
            window.$(this).next().collapse('toggle');
        });
        {/*window.$(".dgscroll").mPageScroll2id({
            offset:65,
            highlightSelector:".dgscroll"
        });*/
        }

        window.$('a.dgscroll').click(function (e) {
            e.preventDefault();
            var target = window.$(window.$(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top - 140;
                window.$('body, html').animate({ scrollTop: scrollTo + 'px' }, 300);
            }
        });

        this.getPasswordTimestamp();
    }

    refreshDeactivation = ()=>{
        this.setState({deactivationKey:uuid()});
    }

    getPasswordTimestamp = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/user/passwordDetails/' + this.props.userId + userAgentGetData(),
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.status === 200) {
                this.setState({ 'passwordTimestamp': response.data.body.lastUpdateTimestamp });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    analyzePassword = (value) => {
        let error = false;
        if (this.state.validation && this.state.validation.validatePassword && this.state.validation.validatePassword.uppercaseTest.test(value)) {
            //this.setState({ upperCaseMsg: '' });
            this.setState({upperCaseCheckboxStatus : true});
        } else {
            this.setState({ upperCaseMsg: '1 Uppercase' ,upperCaseCheckboxStatus : false });
            error = true;
        }
        if (this.state.validation && this.state.validation.validatePassword && this.state.validation.validatePassword.minCharacterTest.test(value)) {
            //this.setState({ minimumPasswordCharacter: '' });
            this.setState({charCountCheckboxStatus : true});
        } else {
            this.setState({ minimumPasswordCharacter: 'Password should 8 characters' ,charCountCheckboxStatus : false });
            error = true;
        }
        if(value !== this.state.confirmPassword){
            this.state.validation.confirmPassword = {
                error: true,
                message: 'Passwords do not match',
                style: { borderColor: "#b34c4c" }
            }
        }else if(value === this.state.confirmPassword){
            this.state.validation.confirmPassword = {
                error: false,
                message: '',
            }
        }
        if (error) {
            this.state.validation.newPassword = {
                error: true,
                message: 'Please enter valid Password',
                style: { borderColor: "#b34c4c" }
            }
        }
    }

    validateForm() {
        this.state.validation.newPassword = {};
        this.state.validation.oldPassword = {};
        this.state.validation.confirmPassword = {};

        let validationRequired = false;
        let errors = {};
        let formIsValid = true;

        if (
            _.isUndefined(this.state.oldPassword) ||
            _.isEmpty((this.state.oldPassword || "").toString()) ||
            _.isNull(this.state.oldPassword)
        ) {
            errors["oldPassword"] = "Current password is required";
            this.state.validation.oldPassword = {
                error: true,
                message: '',
                style: { borderColor: "#b34c4c" }
            }
            formIsValid = false;
            validationRequired = true;
        }

        if (this.state.newPassword === '') {
            if (_.isUndefined(this.state.newPassword) ||
                _.isEmpty((this.state.newPassword || "").toString()) ||
                _.isNull(this.state.newPassword)) {
                errors["newPassword"] = "Please enter a New password";
                this.state.validation.newPassword = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            this.setState({ newPassword: this.state.newPassword });
            this.state.validation.newPassword = {};
            let error = false;
            if (this.state.validation.validatePassword.uppercaseTest.test(this.state.newPassword)) {
                //this.setState({ upperCaseMsg: '' });
                this.setState({upperCaseCheckboxStatus : true});
            } else {
                this.setState({ upperCaseMsg: '1 Uppercase' ,upperCaseCheckboxStatus : false });
                error = true;
            }
            if (this.state.validation.validatePassword.minCharacterTest.test(this.state.newPassword)) {
                //this.setState({ minimumPasswordCharacter: '' });
                this.setState({charCountCheckboxStatus : true});
            } else {
                this.setState({ minimumPasswordCharacter: 'Password should be 8 characters' ,charCountCheckboxStatus : false });
                error = true;
            }
            if (error) {
                this.state.validation.newPassword = {
                    error: true,
                    message: 'Please enter valid Password',
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
            }

        }
        if (
            _.isUndefined(this.state.confirmPassword) ||
            _.isEmpty((this.state.confirmPassword || "").toString()) ||
            _.isNull(this.state.confirmPassword)
        ) {
            errors["confirmPassword"] = "Please enter confirm password";
            this.state.validation.confirmPassword = {
                error: true,
                message: '',
                style: { borderColor: "#b34c4c" }
            }
        }
        if (this.state.confirmPassword != this.state.newPassword) {
            errors["confirmPassword"] = "Password does not match. Please enter again."
            this.state.validation.confirmPassword = {
                error: true,
                message: '',
                style: { borderColor: "#b34c4c" }
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }


    handlePasswordUpdate = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            if (this.state.oldPassword && this.state.oldPassword.trim() !== '' &&
                this.state.newPassword && this.state.newPassword.trim() !== '' &&
                this.state.confirmPassword && this.state.confirmPassword.trim() !== '') {

                let data = {
                    userId: this.props.userId,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.newPassword,
                    confirmPassword: this.state.confirmPassword,
                    privateEntityId : this.state.userData.entityId
                };

                axios({
                    method: 'post',
                    url: process.env.REACT_APP_userServiceURL + "/personalize/update/password",
                    data: data,
                    withCredentials: true
                }).then(response => {
                    if (response && response.data.statusCode === 202) {
                        this.setState({
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                            upperCaseMsg: '1 Uppercase',
                            lowerCaseMsg: '1 Lowercase',
                            specialCharacterMsg: '1 Special Character',
                            numericValueMessage: 'and 1 numerical',
                            minimumPasswordCharacter: "Password must be at least 8 characters long with",
                            success: 'Password Updated Successfully'
                        });
                        this.getPasswordTimestamp();
                        authService.setRefreshToken(response.headers['authorization']);
                    }
                }).catch((err) => {
                    if (err && err.response && err.response.data) {
                        this.setState({
                            error: { message: err.response.data.message, err: err.response, statusCode: err.response.data.statusCode }
                        });
                    }
                });
            }
        }
        {
            setTimeout(() => {
                this.setState({
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    error: {},
                    errors: {},
                    success: '',
                    charCountCheckboxStatus : false,
                    upperCaseCheckboxStatus : false
                });
            }, 7000);

        }
    }

    handleOldPasswordInputChange = (e) => {
        this.state.validation.newPassword = {};
        this.state.validation.oldPassword = {};
        this.state.validation.confirmPassword = {};
        this.setState({
            errors:{}
        })
        if (
            _.isUndefined(e.target.value) ||
            _.isEmpty((e.target.value || "").toString()) ||
            _.isNull(e.target.value)
        ) {
            this.state.validation.oldPassword = {
                error: true,
                message: 'Current Password is required',
                style: { borderColor: "#b34c4c" }
            }
        }
        this.setState({ oldPassword: e.target.value });
    };

    handleNewPasswordInputChange = (value) => {
        this.state.validation.newPassword = {};
        this.state.validation.oldPassword = {};
        this.state.validation.confirmPassword = {};
        this.setState({
            errors:{}
        })
        this.analyzePassword(value.replace(/ /g,""));
        this.setState({ newPassword: value && value.replace(/ /g,"")});
    };

    handleConfirmPasswordInputChange = (e) => {
        this.setState({ confirmPassword: e.target.value });
        this.state.validation.newPassword = {};
        this.state.validation.oldPassword = {};
        this.state.validation.confirmPassword = {};
        this.setState({
            errors:{}
        })
        if (
            _.isUndefined(e.target.value) ||
            _.isEmpty((e.target.value || "").toString()) ||
            _.isNull(e.target.value)
        ) {
            this.state.validation.confirmPassword = {
                error: true,
                message: 'Please enter confirm password',
                style: { borderColor: "#b34c4c" }
            }
        }
        if (e.target.value != this.state.newPassword) {
            this.state.validation.confirmPassword = {
                error: true,
                message: 'Passwords do not match',
                style: { borderColor: "#b34c4c" }
            }
        }
    };

    render() {
        const { error } = this.state;
        const { userId, superAdminFlag } = this.props;
        if (!this.state.loginSuccess) {
            return <Redirect to='/login' />
        } else {
            return <div class="col-md-9">
                
                <div class="projectinn settings">
                    <div class="settcard dgcard">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="heading-1">
                                    <div class="left">
                                        <h2>Change your Password</h2>
                                        {this.state.passwordTimestamp &&
                                            <h4>Last updated on {new Date(this.state.passwordTimestamp).toLocaleDateString('en-GB', {
                                                day: '2-digit', month: 'short', year: 'numeric'
                                            })}</h4>
                                        }
                                    </div>
                                    <div class="right"><a role="button" data-toggle="collapse" href="#collapse-1"
                                        aria-expanded="false" class="btn edit">Edit</a></div>
                                </div>
                                <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1">
                                    <div class="card-body">
                                        <div class="settSequritybox password">
                                            <div class="formwrap">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group passfield">
                                                            <div className="passfieldinn">
                                                                <label>Enter Current Password</label>
                                                                <input type="password"
                                                                    class="form-control custom-input"
                                                                    value={this.state.oldPassword || ''}
                                                                    onChange={this.handleOldPasswordInputChange}
                                                                    placeholder="" name="oldPassword" required="" /*maxLength="15"*/ />
                                                                <span className="passtoggle" style={{ display: 'block', padding: '6px 0', marginTop:'13px' }}>Show</span>
                                                            </div>
                                                            <small className="login-feedback-error mt-1" style={{
                                                                display: "block",
                                                                color: "red",
                                                                fontWeight: "bold"
                                                            }}>{this.state.errors.oldPassword}</small>
                                                            {error && (error.statusCode!=400) &&
                                                                <small className="login-feedback-error mt-1" style={{
                                                                    display: "block",
                                                                    color: "red",
                                                                    fontWeight: "bold"
                                                                }}>{error.message}</small>}
                                                            <small className="login-feedback-error mt-1" style={{
                                                                display: "block",
                                                                color: "red",
                                                                fontWeight: "bold"
                                                            }}>{this.state.validation.oldPassword && this.state.validation.oldPassword.message}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group passfield">
                                                            <div className="passfieldinn">
                                                                <label>Enter New Password</label>
                                                                <input type="password"
                                                                    className="form-control custom-input"
                                                                    value={this.state.newPassword || ''}
                                                                    onChange={(e) => this.handleNewPasswordInputChange(e.target.value)}
                                                                    placeholder="" name="newPassword" required="" /*maxLength="15"*/ />
                                                                {/* <span className="passtoggle" style={{ display: 'block', paddingTop: '22px' }}>Show</span> */}
                                                                <small className="pass-tooltip">
                                                                    <div className={!this.state.charCountCheckboxStatus ? 'passCheckList' : 'passCheckList check'}>
                                                                        {this.state.minimumPasswordCharacter}
                                                                    </div>

                                                                    <div className={!this.state.upperCaseCheckboxStatus ? 'passCheckList' : 'passCheckList check'}>
                                                                        {this.state.upperCaseMsg}
                                                                    </div>
                                                                </small>
                                                            </div>                                                            
                                                            {/* <small> {this.state.minimumPasswordCharacter}</small>
                                                            <small>{this.state.upperCaseMsg}</small> */}
                                                            <small className="login-feedback-error mt-1" style={{
                                                                display: "block",
                                                                color: "red",
                                                                fontWeight: "bold"
                                                            }}>{this.state.errors.newPassword}</small>
                                                            {/* <small className="login-feedback-error mt-1" style={{
                                                                display: "block",
                                                                color: "red",
                                                                fontWeight: "bold"
                                                            }}>{this.state.validation.newPassword && this.state.validation.newPassword.message}</small> */}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group passfield">
                                                            <div className="passfieldinn">
                                                                <label>Re-enter Password</label>
                                                                <input type="password"
                                                                    className="form-control custom-input"
                                                                    value={this.state.confirmPassword || ''}
                                                                    onChange={this.handleConfirmPasswordInputChange}
                                                                    placeholder="" name="confirmPassword" required="" /*maxLength="15"*/ />
                                                                <span className="passtoggle" style={{ display: 'block', padding: '6px 0', marginTop:'13px' }}>Show</span>
                                                            </div>
                                                            <small className="login-feedback-error mt-1" style={{
                                                                display: "block",
                                                                color: "red",
                                                                fontWeight: "bold"
                                                            }}>{this.state.errors.confirmPassword}</small>
                                                            <small className="login-feedback-error mt-1" style={{
                                                                display: "block",
                                                                color: "red",
                                                                fontWeight: "bold"
                                                            }}>{this.state.validation.confirmPassword && this.state.validation.confirmPassword.message}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.success &&
                                                    <small className="mt-1" style={{
                                                        display: "block",
                                                        color: "green",
                                                        fontWeight: "bold"
                                                    }}>{this.state.success}</small>}
                                                {this.state.error &&
                                                    <small className="login-feedback-error mt-1" style={{
                                                        display: "block",
                                                        color: "red",
                                                        fontWeight: "bold"
                                                    }}>{this.state.error.message}</small>}    
                                                <div class="formbottom">
                                                    <button type="button" class="btn btn-primary"
                                                        onClick={this.handlePasswordUpdate}>Update
                                                    </button>
                                                    <a href="#collapse-3" class="btn btn-cancel collapsed"
                                                        data-toggle="collapse" aria-expanded="true"
                                                        role="button">Cancel</a>
                                                </div>
                                            </div>
                                            {/*<div class="forminfo">
                                                <p class="cinfo"><i class="iCaution"/>Your password should not be the
                                                    same as your Profile name</p>
                                                <ul>
                                                    <li>Password should have more than 8 characters and less than 20
                                                    </li>
                                                    <li>URL should contain characters: ?':!@#%^&*()~</li>
                                                </ul>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <ProfileDeactivation key={this.state.deactivationKey} refreshDeactivation={this.refreshDeactivation} userId={userId} />

                            {superAdminFlag && 
                                <>
                                    <UserBankDetails userId={userId} />
                                    
                                    <UserHelpDeskDetails userId={userId} />
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        }
    }
}

export default SecurityAndLoginDetails
