import { authService } from "../auth";
import { userAgentGetData } from "../commons/CommonFunctions";

const API_URL = process.env.REACT_APP_userServiceURL; 

const privateEntityId = authService.getUserDetailsFromLocalStorage()?.entityId;

const Apis = {
    //Licence API
    LicenceConfig: (data) => `${API_URL}/license/config?userId=${data?.userId}&privateEntityId=${privateEntityId}`,
    AccountInfoUserList: (data) => `${API_URL}/license/${data?.userType === "APPROVED" ? "approved" : "pending"}/user/list?userId=${data?.userId}&privateEntityId=${privateEntityId}&keyword=${data?.searchData}&page=${data?.page}&size=${data?.size}`,
    AdminUserList: (data) => `${API_URL}/license/admin/list?userId=${data?.userId}&privateEntityId=${privateEntityId}&keyword=${data?.searchData}&page=${data?.page}&size=${data?.size}`,
    LicenceDetails: (data) => `${API_URL}/license/data?userId=${data?.userId}&privateEntityId=${privateEntityId}`,
    UpdatePrefixCode: `${API_URL}/license/update/prefix`,
    UpdateUserName: `${API_URL}/license/update/name`,
    UpdateLicenceMemberApproval: `${API_URL}/license/update/approval/required`,
    UpdateLicenceUserStatus: `${API_URL}/license/update/member/status`,
    LicenseMemberRequest: `${API_URL}/license/update/member/request`,
    LicenceUserMakeremoveAdmin: (type) => `${API_URL}/license/${type === "MAKE_ADMIN" ? "make" : "remove"}/admin`,
    LicenceUserMemberRemove: `${API_URL}/license/remove/member`,
    CreateLicenceUser: `${API_URL}/personalize/account/create`,
    BulkLicenceUserUpload: `${API_URL}/personalize/bulk/upload`,
    ProvideLicenseToTheUser: `${API_URL}/license/add/user`,
    ProvideLicenseToBulkUser: `${API_URL}/license/multiple/add/user`,
    LicenceUserReactivate: `${API_URL}/license/reactivate/user`,
    LicenseUserWithdrawRequest: `${API_URL}/license/withdraw/member/request`,
    LicenseUserSendRequest: `${API_URL}/license/resend/member/request`,
    LicenceUserHistory: (data) => `${API_URL}/license/user/history/list?userId=${data?.userId}&privateEntityId=${privateEntityId}`,
    LicenceUserDataConfig: (data) => `${API_URL}/license/data?userId=${data?.userId}&privateEntityId=${privateEntityId}`,
    BulkUserHistoryData: (referenceId) => `${API_URL}/bulk/upload/history/data?referenceId=${referenceId}`,
    BulkUserHistoryList: (data) => `${API_URL}/bulk/upload/history/user/list?referenceId=${data?.referenceId}&timeStamp=${data?.timeStamp}`,

    //Location API
    CountryData: `${API_URL}/backend/region/countries`,
    StateData: (value) => `${API_URL}/backend/region/states/${value}`,
    CityData: (value) => `${API_URL}/backend/region/cities/${value}`,
    
    //Hashtags API
    GetHashtagByUserId: (data) => `${API_URL}/tags/getHashtagByUserId/?userId=${data?.userId}&otherUserId=${data?.otherUserId}&page=${data?.page}&size=${data?.size}&filter=${data?.filter}&privateEntityId=${privateEntityId}`,
    GetUserWiseHashTagsDetails: (data) => `${API_URL}/tags/getUserNewsFeed/hashTag/viewAll/?userId=${data?.userId}&otherUserId=${data?.otherUserId}&privateEntityId=${privateEntityId}&hashTag=${data?.hashTag}&category=${data?.category}${(data?.category !== "FORUM" && data?.category !== "POLL") ? `&newsFeedType=${data?.newsFeedType}` : ""}&page=${data?.page}&size=${data?.size}`,
    GetGlobalHashTagsDetails: (data) => `${API_URL}/tags/getNewsFeed/hashTag/viewAll?otherUserId=${data?.otherUserId}&hashTag=${data?.hashTag}&category=${data?.category}&newsFeedType=${data?.newsFeedType}&page=${data?.page}&size=${data?.size}&auth=true&privateEntityId=${privateEntityId}`,
    StatusHashtagsDetails: `${API_URL}/recommend/statushashtag/`,
    
    //Profile API
    GetUserByCustomUrl: (data) => `${API_URL}/profile/get_by_custom_url?customUrl=${data?.customUrl}&otherUserId=${data.otherUserId}&privateEntityId=${privateEntityId}${userAgentGetData()}`,
    ProfileDeactivationConfig: (data) => `${API_URL}/deactivation/get/updation/config/${data?.userId}?privateEntityId=${privateEntityId}`,
    UserDetailsCount: (data) => `${API_URL}/backend/user/count?userId=${data.userId}&privateEntityId=${privateEntityId}`,
    GetUserDataById: (data) => `${API_URL}/profile/get?id=${data?.id}&otherUserId=${data.otherUserId}&privateEntityId=${privateEntityId}${userAgentGetData()}`,
    GetMyWeNatProfilePages: (data) => `${API_URL}/license/user/related/list?userId=${data?.userId}`,
    GetUserDetailaByEmail: (data) => `${API_URL}/profile/get_by_email?email=${data?.email}`,

    //Project API
    ProjectDetailsById: (data) => `${API_URL}/participants/find-project-description-details-by-userId-and-projectId/${data?.userId}/${data?.projectId}?privateEntityId=${privateEntityId}`,
    ParticipantsFindInvitee: (data) => `${API_URL}/participants/find-invitees/${data?.userId}/${data?.creatorUserId}/${data?.entityType}?privateEntityId=${privateEntityId}`,
    ProjectProfileVisibility: (data) => `${API_URL}/backend/project/profile-invisible-data?userId=${data?.userId}&showOnlyMyPost=${data?.showOnlyMyPost}&page=${data?.page}&size=${data?.size}`,
    ProjectUserHideConfig: (projectId) => `${API_URL}/backend/participation/isparticipant-available?projectId=${projectId}`,
    ProjectHiddenConfig: (data) => `${API_URL}/backend/hidden/project/config?userId=${data.otherUserId}&projectId=${data.projectId}`,
    ProjectThreeDotConfig: (data) => `${API_URL}/backend/three/dot/project/get?requestingUserId=${data.requestingUserId}&entityId=${data.entityId}&status=${data.status}`,
    PrivateProjectChangeStatus: `${API_URL}/private/project/change/status`,

    //Circle API
    CircleConfig: (data) => `${API_URL}/circle/get/config/${data?.userId}?circleId=${data?.circleId}&privateEntityId=${privateEntityId}`,
    CircleMemberList: (data) => `${API_URL}/circle/get/member/list/${data?.userId}/${data?.circleId}/?query=${data?.searchData}&privateEntityId=${privateEntityId}&page=${data?.page}&size=${data?.size}`,
    CirclePermission: (data) => `${API_URL}/managecirclepermission/get?superAdminId=${data?.superAdminId}&adminId=${data?.adminId}&privateEntityId=${privateEntityId}`,
    CircleAcceptMember: `${API_URL}/circle/accept/request/member`,
    CircleUserConfig: (data) => `${API_URL}/circle/get/user/config/${data?.id}/${data?.userId}?privateEntityId=${privateEntityId}`,

    //Comment API
    GetCommentsByActivityId: (data) => `${API_URL}/comment/getCommentsByActivityId/${data?.id}?userId=${data?.userId}&privateEntityId=${privateEntityId}&page=${data?.page}&size=${data?.size}`,

    //Subscription API
    UserEntitySubscriptionConfig: `${API_URL}/user/subscription/entity/config`,

    //Password Section API
    UserSkipChangePassword: (userId) =>  `${API_URL}/personalize/skip/reset/password?userId=${userId}&privateEntityId=${privateEntityId}`,

    //Follow API
    CheckUserFollow: (data) =>  `${API_URL}/graph/users/${data.userId}/checkIfFollows/${data.otherUserId}`,

    //Hidden API
    HideEntity: `${API_URL}/hidden/hide`,

    //Subscription API
    UserSubscriptionGlobalConfig: `${API_URL}/user/subscription/global/config`,
    
}

export {API_URL, Apis}
