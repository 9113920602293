import React from 'react'
import {NavLink} from "react-router-dom";
import {authService} from "../auth";
import axios from 'axios';
import { getConfigForLicence } from '../services/LicenceApi';
class LeftPanelSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            managePermission: false,
            userLicenseConfig: {}
        }
    }

    getManagePermissionConfig = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/subscription/get/manage-permission?userId=' + this.state.userData.userId + "&privateEntityId=" + this.state.userData?.entityId ,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data  && response.data.status === '200 OK') {
                this.setState({managePermission:response.data.body});
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    getConfig = async () => {
        let response = await getConfigForLicence({"userId": this.state.userData.userId, "entityId": this.state.userData.entityId});
        if(response?.message === "Success!") {
            this.setState({
                userLicenseConfig: response.body
            })
            if(this.props.getLicenceConnfig) {
                this.props.getLicenceConnfig(response.body);
            }
        }
    }

    componentDidMount() {
        this.getManagePermissionConfig();
        this.getConfig();
    }

    render() {
        const { userType, managePermission, userLicenseConfig } = this.state;
        return <>

            <div className="col-md-3">
                <div className="leftpanel">
                    <div className="sidemenu-profedit" id="mainNav">
                        <ul>
                            <li>
                                <NavLink to={{pathname: "/security-and-login"}} activeClassName="active">
                                    <i className="iSheild"/>Account Settings
                                </NavLink>
                            </li>
                            {managePermission && userLicenseConfig?.type === "SUPER_ADMIN" && 
                                <li>
                                    <NavLink to={{pathname: "/manage-permissions"}} activeClassName="active">
                                        <i className="iManage-Permissions" style={{fontSize: '18px'}} />Manage Permissions
                                    </NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to={{pathname: "/manage-invitation"}} activeClassName="active">
                                    <i className="iManage-Invitations" /> Manage Invitations
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/notification-setting"}} activeClassName="active">
                                    <i className="iBell_notify" style={{fontSize: '16px'}} /> Manage Notification
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: `/${this.state.userData.customUrl}/studio`}} className={this.props.sectionName === "STUDIO" ? "active" : ""}>
                                    <i className="istudio" style={{fontSize: '16px'}} /> Manage Studio
                                </NavLink>
                            </li>
                            {userLicenseConfig?.type === "SUPER_ADMIN" && 
                                <li>
                                    <NavLink to={{pathname: "/private-projects"}} activeClassName="active">
                                        <i className="iSheild_Lock" style={{fontSize: '14px'}} /> Private Projects
                                    </NavLink>
                                </li>
                            }
                            
                            <li>
                                <NavLink to={{pathname: "/forum-poll-draft"}} className={this.props.pageSection === "DRAFT" ? "active" : ""}>
                                    <i className="iDraft_F" style={{fontSize: '16px'}} /> Drafts
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/hidden-content"}} activeClassName="active">
                                    <i className="iHidden-Content" style={{fontSize: '16px'}} /> Hidden Content
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink to={{pathname: "/analytics"}} activeClassName="active">
                                    <i className="ianalytics" style={{fontSize: '16px'}} /> Analytics
                                </NavLink>
                            </li>
                            {userLicenseConfig?.type === "SUPER_ADMIN" && 
                                <li>
                                    <NavLink to={{pathname: "/fund-raise"}} activeClassName="active">
                                        <i className="iDonation-stats" style={{fontSize: '16px'}} /> Fund Raise Stats
                                    </NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to={{pathname: "/saved-posts"}} activeClassName="active">
                                    <i className="iBookmark_F" style={{fontSize: '16px'}} /> Saved items
                                </NavLink>
                            </li>                        
                            <li>
                                <NavLink to={{pathname: "/my-forum"}} activeClassName="active">
                                    <i className="iForum_F" style={{fontSize: '16px'}} /> My Forum
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/my-poll"}} activeClassName="active">
                                    <i className="iPolls_FL" style={{fontSize: '16px'}} /> My Poll
                                </NavLink>
                            </li>
                            {userLicenseConfig?.accountInfoTabVisible && 
                                <li>
                                    <NavLink to={{pathname: "/account-info"}} activeClassName="active">
                                        <i className="iAccount-info" style={{fontSize: '16px'}} /> Account Info
                                    </NavLink>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>

        </>

    }
}

export default LeftPanelSettings
