import React, { Component } from "react";
import { authService } from "../auth";
import axios from "axios";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const ErorrMsgPopUp = withReactContent(alertPopUp);

class FeaturePostButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            featuredPostAccess: this.props.item.featuredPostAccess,
            featuredPost: this.props.item.featuredPost, 
            pageName: this.props.match.path == "/project/:type/:projectId" ? "PROJECT" : "OTHERS"
        }
    }

    makeRemoveFeaturePost = () => {
        let data = {
            userId: this.state.userData.userId,
            postId: this.props.item.id,
            markFeatured: this.props.pageType !== "FEATURE_POST" ? !this.state.featuredPost : false,
            privateEntityId : this.state.userData.entityId
        };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_userServiceURL}/backend/featured/post/add`,
            data: data,
            withCredentials: true,
        }).then((response) => {
            if(response && response.data && response.data.message === "Success!") {
                if(this.props.pageType !== "FEATURE_POST") {
                    this.setState({
                        featuredPost: !this.state.featuredPost
                    })
                } else {
                    this.props.onSuccessFeaturePost(this.props.item.id);
                }
            }
        }).catch((err) => {
            console.log('err', err);
            if(err && err.response && err.response.data && err.response.data.status === '409 CONFLICT') {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <><p>You can add upto 10 featured post.</p></>,
                    })
                })
            }
        });
    }

    render() {
        const { featuredPostAccess, featuredPost, pageName } = this.state;
        const { pageType } = this.props;
        return (
            <>
                {featuredPostAccess &&
                    (pageType !== "FEATURE_POST" ? 
                        <a className="dropdown-item" href="javascript:void(0)" onClick={this.makeRemoveFeaturePost} style={{textTransform: 'none'}}>
                            <i className={featuredPost ? "ifeature-icon_RM" : "ifeature-icon"} style={{ fontSize: '16px', verticalAlign: '-3px' }} /> {!featuredPost ? `Feature on ${pageName == "PROJECT" ? "Page" : "Profile"}` : "Remove from Featured"}
                        </a> :
                        <>
                            <a className="featurePostbtn btn" onClick={this.makeRemoveFeaturePost} style={{cursor: "pointer"}} data-for={this.props.item.id} data-tip="UserFeatureComponent" data-place={"left"}><i className="ifeature-icon_RM"></i></a>
                            <ReactTooltip
                                className="rtoolTip featureIcon"
                                place="left"
                                type="dark"
                                textColor="#00394D"
                                effect="solid"
                                backgroundColor="#ffffff"
                                clickable
                                id={this.props.item.id}
                            >
                                Remove from Featured
                            </ReactTooltip>
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(FeaturePostButton);
