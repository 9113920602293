import React, { PureComponent } from "react";
import { authService } from "../auth";
import axios from "axios";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import httpService from "../AxiosInterceptors";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import * as _ from "lodash";
import EventMessageSection from "../commons/EventMessageSection";
import PresentationPostView from "./PresentationPostView";
import ReactPlayer from "react-player";
import CommonPostHeaderSection from "./CommonPostHeaderSection";
import CommonPostMenuSection from "./CommonPostMenuSection";
import CommonLinkPreview from "../commons/CommonLinkPreview";
import CommonPostBottomSection from "./CommonPostBottomSection";

httpService.setupInterceptors();
class QuotePostItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      description: props.item.description,
      editedText: props.item.description,
      hashTags: [],
      errors: {},
      error: "",
      tags: [],
      smileDisplay: false,
      og_data: {}
    };
  }

  componentDidMount() {
    let tags = [];
    this.props.item &&
      this.props.item.hashTags &&
      this.props.item.hashTags.map((data, index) => {
        tags.push(data);
        this.setState({ tags: tags });
      });
    this.varifyLinkData(this.state.description);
  }

  isValidUrl = (str) => {
    let regexp =
      /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  };

  varifyLinkData = (text) => {
    var match =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.exec(
        text
      );
    this.setState({
      linkdata: "",
    });
    if (match && match[0] && this.isValidUrl(match[0])) {
      this.setState({
        linkdata: match[0],
      }, () => {
        this.getLinkPreviewUrl(this.state.linkdata)
      });
    }
  };


  getLinkPreviewUrl = (text) => {
    let data = {
      url: text,
      sendByMyWenat: true
    }
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
      data: data,
      withCredentials: true
    }).then((response) => {
      if (response?.data?.status === 200) {
        this.setState({
          og_data: response?.data?.data
        })
      }
    }).catch((error) => {
      console.log("Error", error);
    })
  };

  onSuccessUpdatePost = (data) => {
    this.setState({
      description: data.description,
      tags: data.tags
    })
  }

  render() {
    return (
      <div className={`hpostblock` + (this.props.item.nominated && this.props.postSection === "FEED" ? ' pNominate' : '')}>
        <div className="hpostblockinn">
          {this.props.item && this.props.item.eventMessage && (
            <EventMessageSection item={this.props.item} />
          )}
          {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

          <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

          <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={this.state.tags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType="QUOTE" />

          <div className="hpostcontent">
            <div className="hpostconttop">
              {((this.state.description && this.state.description !== "") ||
                this.state.tags) && (
                  <>
                    <ReadMoreReadLess
                      id={this.props.item.id}
                      description={this.state.description}
                      hashTags={this.state.tags}
                      item={this.props.item}
                    />
                    {this.state.linkdata && (
                      <>
                        <div className="linkpostpreview">
                          <div className="prevtitle">
                            Preview
                          </div>
                          <div className="postarticledesc">
                            {this.state.linkdata.indexOf(
                              "https://youtu.be"
                            ) !== -1 ||
                              this.state.linkdata.indexOf(
                                "www.youtube.com/watch"
                              ) !== -1 ? (
                              <>
                                <div className="vidPlayerOverWrap">
                                  <ReactPlayer
                                    url={this.state.linkdata}
                                    width={"100%"}
                                    playing={true}
                                    controls={true}
                                    muted={true}
                                  />
                                </div>
                                <div className="customLinkPost" onClick={() => {
                                  window.location.href = this.state.linkdata
                                }}>
                                  <div className="customLinkPostDesc">
                                    <h5>{this.state.og_data?.title}</h5>
                                    <p>{this.state.og_data?.description}</p>
                                    {/* <p>{this.state.linkdata}</p> */}
                                  </div>
                                </div>
                                <a
                                  href={this.state.linkdata}
                                  className="btn playYoutube"
                                  target="_blank"
                                >
                                  <i className="iYouTube_Logo" />{" "}
                                  Play on Youtube
                                </a>
                              </>
                            ) :
                              (!_.isEmpty(this.state.og_data) &&
                                <CommonLinkPreview linkdata={this.state.linkdata} og_data={this.state.og_data} />
                              )}
                          </div>
                        </div>
                      </>
                    )}
                    {this.props.item && this.props.item.secondaryAttachmentIds && this.props.item.secondaryAttachmentIds.length === 1 && (this.props.item.secondaryAttachmentIds[0].attachmentType === "PPT" || this.props.item.secondaryAttachmentIds[0].attachmentType === "PPTX") &&
                      <PresentationPostView attachments={this.props.item.secondaryAttachmentIds[0]} />
                    }
                  </>
                )}

              {this.props.item.postLocation && (
                <span className="address">
                  <i className="iLocation" />
                  {this.props.item.postLocation}
                </span>
              )}
            </div>

            <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} />

          </div>

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestPostCreationFulfilled: (value) =>
      dispatch(requestPostCreationFulfilled(value)),
  };
};

export default connect(null, mapDispatchToProps)(QuotePostItem);
