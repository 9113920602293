import React, { PureComponent } from 'react';
import { authService } from "../auth";
import { Link } from 'react-router-dom';
import httpService from "../AxiosInterceptors";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import EventMessageSection from '../commons/EventMessageSection';
import PollVoteSection from '../commons/PollVoteSection';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import CommonPostMenuSection from './CommonPostMenuSection';
import CommonPostBottomSection from './CommonPostBottomSection';

httpService.setupInterceptors();
class PollPostItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            description: props.item?.metaData?.description,
            editedText: props.item?.metaData?.description,
            hashTags: [],
            tags: [],
            smileDisplay: false,
            pollDataDetail: this.props.item?.metaData
        }
    }

    componentDidMount() {
        let tags = [];
        this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {
            tags.push(data);
            this.setState({ tags: tags });
        })
    }

    render() {
        const { pollDataDetail } = this.state;
        return (
            <div className="hpostblock">
                <div className="hpostblockinn">
                    {this.props.item && this.props.item.eventMessage && <EventMessageSection item={this.props.item} />}
                    {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

                    <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                    <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={pollDataDetail?.hashTags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType={"POLL"} />

                    <div className="hpostcontent">
                        <div className="hpostconttop">
                            <div className="forumrow post-forum">
                                <h4>
                                    <Link to={`/pollDetail/${pollDataDetail.slug}`}>
                                        {pollDataDetail.question}
                                    </Link>
                                </h4>
                                {((pollDataDetail.description && pollDataDetail.description !== '') || pollDataDetail.hashTags) &&
                                    <ReadMoreReadLess id={this.props.item.id} description={this.state.description} hashTags={pollDataDetail.hashTags} />
                                }
                                <PollVoteSection pollDataDetail={pollDataDetail} />
                            </div>
                        </div>

                        <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} postType={"POLL"} postData={pollDataDetail} />

                    </div>

                </div>
                {(this.props.latestPollId === this.props.item.id) && <div className='viewFPBottCard'><Link to={"/poll"} className='btn'>View all</Link> Polls on WeNaturalists</div>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(PollPostItem);