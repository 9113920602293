import React, { Component } from "react";
import { detailsPageRedirection, get720pTranscodedVideo, getCDNUrl, videoThumbnailFunction } from "../commons/CommonFunctions";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";

class VideoesUnauthItem extends Component {
  constructor(props) {
      super(props);
      this.state={

      }
  }

  render() {
    return (
      <a hreg="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item?.id)}>
        <div className="hpostconttop">
          {this.props.item && (this.props.item.description || (this.props.item.hashTags && this.props.item.hashTags.length > 0)) &&
            <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} hashTags={this.props.item.hashTags}  item={this.props.item} />
          }
          {this.props.item.attachmentIds && this.props.item.attachmentIds[0] && this.props.item.attachmentIds[0].attachmentUrl &&
            <figure className="hpostimggrid">
              <div className="himggrid vidbox">
                {/* <a data-fancybox={this.props.item.id} href={getCDNUrl(get720pTranscodedVideo(this.props.item.attachmentIds[0]))}> */}
                  <img src={((this.props.item?.thumbnail) || (this.props.item.attachmentIds[0].thumbnails && this.props.item.attachmentIds[0].thumbnails[0])) ? getCDNUrl(videoThumbnailFunction(this.props.item)) : "https://cdn.dscovr.com/images/video-blank2.webp"}
                    onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/video-blank2.png" }}
                    alt="" />
                  <span className="playbutt" onContextMenu="return false;"><i className="iPlay" /></span>
                {/* </a> */}
              </div>
            </figure>
          }
        </div>
      </a>
    );
  }
}

export default VideoesUnauthItem;