import React, { Component } from 'react';
import { NavLink, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import * as _ from "lodash";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en';
import { authService } from "../auth";
import { getCDNUrl, getOptimizedImage } from "../commons/CommonFunctions";
import '../commons/styles/fb-image-grid-style.min.css'
import { documentStudioImageData, getFullImage, studioImageListPage } from '../project/component/Studio/StudioContentCommonFunction';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

class StudioSharePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage()
        }
    }

    render() {
        var utcSeconds = this.props.item.createTime;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        const linkTarget = {
            pathname: "/profile/" + this.props.item.customUrl,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
                applied: true
            }
        };
        const postTarget = {
            pathname: "/studio/" + this.props.item.slug,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
                applied: true
            }
        };
        return (
            <div className="sharepostpreview">
                <div className="hpostblock">
                    <div className="hpostblockinn">
                        {this.props.entityItem && this.props.entityItem.creatorId !== this.props.item.userId && !this.props.isHashtagPage && 
                            <div className="hposthead">
                                {this.props.entityItem && this.props.entityItem.creatorId !== this.props.item.userId &&
                                    <>{!this.props.item.userEntityType ? <figure className="userimg" onClick={(e) => {
                                        e.preventDefault();
                                        this.redirectToProfile()
                                    }} style={{ cursor: 'pointer' }}>
                                        <img src={this.props.item.profileImage ?
                                            getCDNUrl(getOptimizedImage(this.props.item, 'profileImage')) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                            alt="" />
                                    </figure> : <figure className="userimg" onClick={(e) => {
                                        e.preventDefault();
                                        this.redirectToCircle(this.props.item && this.props.item.params && this.props.item.params['circleSlug'])
                                    }}>
                                        <img
                                            src={this.props.item && this.props.item.params && this.props.item.params['circleImage'] != null
                                                ? getCDNUrl(this.props.item.params && this.props.item.params['circleImage'])
                                                : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                            onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/circleDefault.png" }}
                                            alt="" />
                                    </figure>}
                                    </>
                                }
                                {!this.props.item.userEntityType ? <h3 className={(this.props.item?.userType === "COMPANY" ? " orgnzName" : "")}><NavLink style={{ textDecoration: "none" }}
                                    to={linkTarget}>{this.props.item.username}</NavLink> {/*<span><a href="javascript:void(0)">Follow</a></span>*/}
                                </h3> : <h3 onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToCircle(this.props.item.params && this.props.item.params['circleSlug'])
                                }}>{this.props.item.params['circleTitle']}</h3>}
                                {this.props.item && this.props.item.persona &&
                                <div className="hpostPersona">{this.props.item.persona}</div>}
                                <div className="hpostdtls">
                                    {this.props.item && this.props.item.addressDetail && this.props.item.addressDetail.country && <span className="address"><i
                                        className="iLocation" /> {this.props.item.addressDetail.country}</span>}
                                    {
                                        this.props.item.postLocation &&
                                        <span className="address"><i
                                            className="iLocation" />{this.props.item.postLocation}</span>
                                    }
                                    <span><NavLink
                                        to={postTarget}>{timeAgo.format(d, 'mini-now')}</NavLink></span>
                                </div>
                            </div>}
                        <div className="hpostconttop">
                            <div className={"grid-container studio" + ((this.state.loginSuccess && (this.props.item?.userId === this.state.userData.userId) || this.props.item.publishionForFree || !_.isEmpty(this.props.item.attachment)) ? "" : " ppLock")}>
                                <NavLink to={postTarget}>
                                    <span><img src={!_.isEmpty(this.props.item.thumbnail) ? getCDNUrl(this.props.item.thumbnail?.attachmentUrl) : (this.props.item.attachment && this.props.item?.attachment?.attachmentType !== "VIDEO") ? getCDNUrl(this.props.item.attachment?.attachmentType === "IMAGE" ? getFullImage(studioImageListPage(this.props.item)) : documentStudioImageData(this.props.item?.attachment)) : 'https://cdn.dscovr.com/images/studio-default.webp'} /></span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StudioSharePreview;