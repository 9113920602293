import React, { PureComponent } from 'react';
import { Redirect } from "react-router-dom";
import { authService } from '../auth';

class HashtagSearchItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            redirectToHatags: false
        }
    }

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        window.location.href = `/profile-hashtag/${this.props.item.tags}/${this.state.userData?.customUrl}`;
    }

    render() {
        const { userData } = this.state;
        return (
            <>
                {this.state.redirectToHatags && <Redirect push to={`/profile-hashtag/${this.props.item.tags}/${userData?.customUrl}`} />}
                <li>
                    <a href="javascript:void(0)" onClick={this.redirect}>
                        <div className="srchpostmain">
                            <div>
                                <h5># {this.props.item.tags}</h5>
                            </div>
                        </div>
                    </a>
                </li>
            </>
        );
    }
}

export default HashtagSearchItem;