import axios from "axios";
import { Apis } from "../config/api";

export const getConfigForLicence = async (data) => {
    try {
        const response = await axios.get(Apis.LicenceConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getAccountInfoUserList = async (data) => {
    try {
        const response = await axios.get(Apis.AccountInfoUserList(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getLicenceDetails = async (data) => {
    try {
        const response = await axios.get(Apis.LicenceDetails(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updatePrefixCode = async (postData) => {
    try {
        const response = await axios.post(Apis.UpdatePrefixCode, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const updateUserName = async (postData) => {
    try {
        const response = await axios.post(Apis.UpdateUserName, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const updateLicencePrefixCodeMemberApproval = async (postData) => {
    try {
        const response = await axios.post(Apis.UpdateLicenceMemberApproval, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateLicenceUserStatus = async (postData) => {
    try {
        const response = await axios.post(Apis.UpdateLicenceUserStatus, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const countryDatalist = async () => {
    try {
        const response = await axios.get(Apis.CountryData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const stateDataList = async (data) => {
    try {
        const response = await axios.get(Apis.StateData(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const cityDataList = async (data) => {
    try {
        const response = await axios.get(Apis.CityData(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const createLicenceUser = async (postData) => {
    try {
        const response = await axios.post(Apis.CreateLicenceUser, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const bulkLicenceUserUpload = async (postData) => {
    try {
        const response = await axios.post(Apis.BulkLicenceUserUpload, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const provideLicenseToTheUser = async (postData) => {
    try {
        const response = await axios.post(Apis.ProvideLicenseToTheUser, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const provideLicenseToBulkUser = async (postData) => {
    try {
        const response = await axios.post(Apis.ProvideLicenseToBulkUser, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const licenceUserMakeremoveAdmin = async (type, postData) => {
    try {
        const response = await axios.post(Apis.LicenceUserMakeremoveAdmin(type), postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const licenceUserMemberRemove = async (postData) => {
    try {
        const response = await axios.post(Apis.LicenceUserMemberRemove, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const licenceUserReactivate = async (postData) => {
    try {
        const response = await axios.post(Apis.LicenceUserReactivate, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const licenceUserWithdrawRequest = async (postData) => {
    try {
        const response = await axios.post(Apis.LicenseUserWithdrawRequest, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const licenceUserSendRequest = async (postData) => {
    try {
        const response = await axios.post(Apis.LicenseUserSendRequest, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const getAdminUserList = async (data) => {
    try {
        const response = await axios.get(Apis.AdminUserList(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getLicenceUserHistory = async (data) => {
    try {
        const response = await axios.get(Apis.LicenceUserHistory(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getLicenceUserDataConfig = async (data) => {
    try {
        const response = await axios.get(Apis.LicenceUserDataConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getBulkUserHistoryData = async (referenceId) => {
    try {
        const response = await axios.get(Apis.BulkUserHistoryData(referenceId));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getBulkUserHistoryList = async (postData) => {
    try {
        const response = await axios.get(Apis.BulkUserHistoryList(postData));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const licenseMemberRequest = async (postData) => {
    try {
        const response = await axios.post(Apis.LicenseMemberRequest, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}