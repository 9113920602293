import axios from "axios";
import React from "react";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getCDNUrl } from "../../../commons/CommonFunctions";

const ErorrMsgPopUp = withReactContent(alertPopUp);

export const defaultProjectImage = {
    attachmentType: "IMAGE",
    attachmentUrl: "https://cdn.dscovr.com/images/studio-default.webp",
    id: "defaultProjectImage",
}

export const getImageDetails = (item) => {
    let url = item.thumbnail ? getCDNUrl(getResizedImage(item.thumbnail)) : (item.contentType !== "VIDEO" ? (item.visibleToUser ? (item.contentType === "IMAGE" ? getCDNUrl(getResizedImage(item.attachment)) : getCDNUrl(getDocumentImage(item.attachment))) : "https://cdn.dscovr.com/images/studio-default.webp") : "https://cdn.dscovr.com/images/studio-default.webp");
    /* if(item.visibleToUser) {
        url = item.contentType === "IMAGE" ? getCDNUrl(item.thumbnail ? getCDNUrl(getResizedImage(item.thumbnail)) : getResizedImage(item.attachment)) : "https://cdn.dscovr.com/images/studio-default.webp";
    } else {
        url = item.thumbnail ? getCDNUrl(getResizedImage(item.thumbnail)) : item.contentType === "IMAGE" ? item.visibleToUser ? getCDNUrl(getResizedImage(item.attachment)) : "https://cdn.dscovr.com/images/studio-default.webp" : "https://cdn.dscovr.com/images/studio-default.webp";
    } */
    return url;
}

export const getResizedImage = (attachment) => {
    if (attachment.resizedImages && attachment.resizedImages['300x300'] != null) {
        return attachment.resizedImages['300x300'];
    }
    return attachment.attachmentUrl;
}

export const getDocumentImage = (attachment) => {
    if(attachment?.relatedImages.length > 0) {
        return attachment?.relatedImages[0];
    }
}

export const getFullImage = (attachment) => {
    //return attachment.attachmentUrl;
    if (window.innerWidth > 1000 && attachment.resizedImages && attachment.resizedImages && attachment.resizedImages['800x800'] != null) {
        return attachment.resizedImages['800x800'];
    } else if (window.innerWidth > 600 && window.innerWidth <= 1000 && attachment.resizedImages && attachment.resizedImages['600x600'] != null) {
        return attachment.resizedImages['600x600'];
    } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['400x400'] != null) {
        return attachment.resizedImages['400x400'];
    } else {
        return attachment.attachmentUrl;
    }
}

export const capitalizeFirstLetter = (text) => {
    return text && text[0].toUpperCase() + text.slice(1).toLowerCase();
};

export const handleViewClickStudioPost = (type, activityId, userId) => {
    let data = {
        actionType: type,
        activityId: activityId,
        userId: userId
    }
    axios({
        method: "post",
        url: `${process.env.REACT_APP_userServiceURL}/userAction/create`,
        data: data,
        withCredentials: true,
    }).then((response) => {
        if (response && response.data.message === "Success!") {
        } 
    })
    .catch((err) => {
        console.log(err);
    });
}

export const numFormatter = (num) => {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K';
    }else if(num > 1000000){
        return (num/1000000).toFixed(1) + 'M';
    }else if(num < 900){
        return num;
    }
}

export const getAttachmentType = (filename) => {
    let type = filename.split('.').pop();
    if(type === "jpg" || type === "jpeg" || type === "png") {
        return "IMAGE";
    }
    if(type === 'mp4' || type === 'ogg' || type === 'mpeg' || type === 'aac' || type === 'wav') {
        return "VIDEO"
    }
    return null;
}

export const getOptimizedImage = (personalInfo) => {
    if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['300x300'] != null) {
        return personalInfo.resizedProfileImages['300x300'];
    }
    return personalInfo.originalProfileImage;
}

export const redirecToProfile = (customUrl) => {
    window.location.href = `/profile/${customUrl}`;
}

export const getMaxChars = (str, max) => {
    if (str === undefined || str === null)
        return "";

    let n = str.length;
    if (max > n) {
        return str;
    }

    let res = str.substring(0, max);
    res += "...";
    return res;
}

export const redirecToStudiopage = (studioDataConfig, projectId) => {
    if(studioDataConfig && studioDataConfig.postCreationAllowed) {
        window.location.href = `/project/post-studio/${projectId}`;
    } else {
        studioPageErrorMessagePopUp(studioDataConfig);
    }
}

export const checkCompleteStudioForm = (shortBrief, details, tags, helpContactDetails, termCondCheck) => {
    if(shortBrief && (details && details.trim() !== "" && details !== "<p><br></p>") && tags.length > 0 && helpContactDetails.length > 0 && termCondCheck) {
        return true;
    }
    return false;
}

export const descriptionUpdatedText = (text) => {
    if(!text || text.length === 0) {
        return text;
    }
    text = text.split("@@@__").join("<span");
    text = text.split("^^__").join("\">")
    text = text.split("&&__").join("\">")
    text = text.split("&amp;&amp;__").join("\">")
    text = text.split("&amp;&amp;__").join("\">")
    text = text.split("@@@^^^").join("</span>");
    text = text.split("###__").join("<span class=\"hashLinkPost");
    text = text.split("&&__").join("<span class=\"hashLinkPost");
    text = text.split("###^^^").join("</span>");
    return text;
}

export const getUserOptimizedImage = (userDetails) => {
    if (userDetails.resizedProfileImages && userDetails.resizedProfileImages['200x200'] != null) {
        return userDetails.resizedProfileImages['200x200'];
    }
    return userDetails.originalProfileImage;
}

export const publishedTimeSection = (publishTime, type) => {
    return (
        new Date(publishTime).toLocaleDateString("en-GB", {year: "numeric",})
            .replace(/ /g, "-") === new Date().getFullYear().toString() ? (
            <span className="pstdate">
            {type === "PUBLISH" ? "Published" : "Updated"}  on{" "}
            {new Date(publishTime)
                .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                })
                .replace(/ /g, "-")}
            </span>
        ) : (
            <span className="pstdate">
            Published on{" "}
            {new Date(publishTime)
                .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                })
                .replace(/ /g, "-")}
            </span>
        )
    )
}

export const studioImageListPage = (studio) => {
    if(studio.thumbnail) {
        return studio.thumbnail;
    } else {
        if(studio.publishionForFree || studio.attachment) {
            return studio.attachment;
        }
    }
}

export const studioImageDetailsPage = (studio) => {
    return studio.attachment && studio.attachment.attachmentType === "IMAGE" ? studio.attachment : studio.thumbnail;
}

export const studioPageErrorMessagePopUp = (studioDataConfig) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then((result) => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <><p>{(studioDataConfig.postTopUpNotAvailable || !studioDataConfig.subscribed) ? "Upgrade to a required WeNaturalists Pro subscription to post in Studio." : (studioDataConfig.projectLimitExceeded || studioDataConfig.postLimitExceeded) ? `Your are already running maximum allowed studio ${studioDataConfig.projectLimitExceeded ? "project" : "post"}. Top Up to post more.` : ""}</p><a class="styleBtn" href={'/wenaturalists-pro'}>{(studioDataConfig.postTopUpNotAvailable || studioDataConfig.projectLimitExceeded) ? "Upgrade Now" : "Top Up Now"}</a>{/* {(studioDataConfig.postTopUpNotAvailable || studioDataConfig.projectLimitExceeded) &&<div className='registerFreetrial'><a href={'/wenaturalists-pro'}>Register for a Free Trial</a></div>} */}</>,
        })
    })
}

export const redirecToStudioDetailsPage = (studioData) => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "STUDIO",
    //         userId: studioData.userId,
    //         slug: studioData.slug,
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = studioData.compeleted ? `/studio/${studioData.slug}` : `/edit/post-studio/${studioData.slug}`;
    // }
    window.location.href = studioData.compeleted ? `/studio/${studioData.slug}` : `/edit/post-studio/${studioData.slug}`;
}

export const redirectToProfile = (studioData, logInUserId) => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "redirectToProfile",
    //         userId: studioData.userId,
    //         userType: studioData.userType,
    //         id: logInUserId,
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = `/profile/${studioData.userCustomUrl}`;
    // }
    window.location.href = `/profile/${studioData.userCustomUrl}`;
}

export const documentStudioImageData = (attachment) => {
    return attachment?.relatedImages?.length > 0 ? attachment?.relatedImages[0] : "https://cdn.dscovr.com/images/studio-default.webp"
}