import React, {Component} from "react";
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";
import Banner from "../pages/banner";
import {authService} from "../../auth";


class CircleProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            circleData: props.circleData
        }
    }

    componentDidMount() {
        window.$(window).scroll(function(){
            if(window.$(window).scrollTop() + window.$(window).height() > window.$(document).height() - 300) {
                window.$('.btn.bcmemb').removeClass('fixed');
            }else{
                window.$('.btn.bcmemb').addClass('fixed');
            }
        });
    }

    render() {
        return (
            <div
                className={(window.location.href.indexOf('/community/activity/') !== -1 ||  window.location.href.indexOf('/community/projects/') !== -1 || window.location.href.indexOf('/community/members/') !== -1 || window.location.href.indexOf('/community/award/') !== -1) ? "wrapper projectpage profilepage microcomOth ppersonal" : "wrapper projectpage profilepage pabout ppersonal"}>
                <Header/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <LeftPanel getLicenseConfig={this.props.getLicenseConfig} /* postPopUpOpenFlag={true} *//>
                            <div className="col-md-9">
                                <div className="projectinn microcommfeed">
                                    <Banner circleData={this.state.circleData} getEntityDonationConfig={this.props.getEntityDonationConfig} getDonationConfig={this.props.getDonationConfig} needDonationConfig={this.props.needDonationConfig} pageType={this.props.pageType} />
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default CircleProfile;
