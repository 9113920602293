import axios from "axios";
import { Apis } from "../config/api";
import { authService } from "../auth";

const privateEntityId = authService.getUserDetailsFromLocalStorage()?.entityId;
const userId = authService.getUserDetailsFromLocalStorage()?.userId;

export const getHashtagByUserId = async (data) => {
    try {
        const response = await axios.get(Apis.GetHashtagByUserId(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getUserWiseHashTagsDetails = async (data) => {
    data.otherUserId = userId;
    try {
        const response = await axios.get(Apis.GetUserWiseHashTagsDetails(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const getGlobalHashTagsDetails = async (data) => {
    try {
        const response = await axios.get(Apis.GetGlobalHashTagsDetails(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const getHasgtagsStatus = async (data) => {
    data.privateEntityId = privateEntityId;
    try {
        const response = await axios.post(Apis.StatusHashtagsDetails, data);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}