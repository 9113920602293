import React from "react";
import axios from "axios";
import { authService } from "../auth";
import { cloneDeep } from "lodash";
import { blockUserMessage, commonCommentSection, getCDNUrl, getFileIcon, getOptimizedImage } from "./CommonFunctions";
import MentionTextArea from "./MentionTextArea";
import Picker from "emoji-picker-react";
import * as _ from "lodash";
import CommentUserListPop from "./CommentUserListPop";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setProfileBasicInfoDetails } from "../reducer/profile/ProfileReducer";
import { userDetailsEntityCount } from "../services/UserApi";

const maxMbFileSize = 100 * 1024 * 1024; // 100MB
const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

class AddCommentBox extends React.PureComponent {
  constructor(props) {
    super(props);
    let validation = {
      commentText: {},
    };
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      userComment: "",
      validation,
      isCommentSubmitted: false,
      profileData: {},
      smiliDisplay: false,
      errors: {},
      currentUserDetails: !_.isEmpty(this.props.currentUserDetails) ? this.props.currentUserDetails : authService.getUserDetailsFromLocalStorage(),
      isOpenUserListPop: false,
      userEntityCount: props.item.userEntityCount ? props.item.userEntityCount : props.userEntityCount ? props.userEntityCount : 0,
      userId: authService.getUserDetailsFromLocalStorage()?.userId
    };
  }

  handleSmiliDisplay = () => {
    this.setState({
      smiliDisplay: this.state.smiliDisplay ? false : true,
    });
  };

  onToggleEmojiWindow = () => {
    const { smiliDisplay } = this.state;
    this.setState({ smiliDisplay: !smiliDisplay });
  };

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  handleCommentTyped = (event) => {
    this.setState({ userComment: event.target.value.trimStart() });
  };

  onEmojiClick = (event, emojiObject) => {
    let userComment = cloneDeep(this.state.userComment);
    userComment = userComment + " " + emojiObject.emoji;
    this.setState({
      userComment: userComment,
    });
  };

  // validate comment
  validate_comment = () => {
    this.state.validation.commentText = {};
    let errors = {};
    let formIsValid = true;
    if (
      this.state.userComment === "" &&
      (this.state.commentImageAttachments === undefined ||
        this.state.commentImageAttachments.length === 0)
    ) {
      errors["commentText"] = "Please write comment to publish";
      let error = {
        error: true,
        message: "Please write comment to publish",
        style: { borderColor: "#b34c4c", padding: "10px" },
      };
      this.setState({
        validation: error,
      });

      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleCommentSubmit = () => {
    if (this.validate_comment()) {
      const formData = new FormData();

      this.setState({ isCommentSubmitted: true });

      let params = {
        userId: this.state.currentUserDetails?.userId,
        activityId: this.props.item.id,
        userComment: this.state.userComment.trim("\n"),
        privateEntityId : this.state.userData && this.state.userData.entityId
      };

      if (
        this.state.commentAttachments &&
        this.state.commentAttachments.length > 0
      ) {
        this.state.commentAttachments.forEach((file) => {
          formData.append("files", file);
        });
      }

      if (
        this.state.commentImageAttachments &&
        this.state.commentImageAttachments.length > 0
      ) {
        this.state.commentImageAttachments.forEach((file) => {
          formData.append("files", file);
        });
      }

      formData.append("data", JSON.stringify(params));

      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/backend/comment/create",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            if (typeof this.props.getAllComments == "function") {
              this.props.getAllComments(0, (this.props.pageType !== "FEED" && this.props.pageType !== "EXPLORE") ? 5 : 3, false);
            }
            this.setState({
              isCommentSubmitted: false,
              userComment: "",
              smiliDisplay: false,
              commentAttachments: [],
              commentImageAttachments: [],
            });
          } else {
            console.log(response);
            this.setState({ isCommentSubmitted: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isCommentSubmitted: false });
          if (err?.response?.data?.status === "409 CONFLICT") {
            blockUserMessage("MESSAGE", err.response.data.message);
          }
        });
    }
    setTimeout(() => {
      this.setState({ error: "", validation: {}, errors: {} });
    }, 5000);
  };

  commentAttachmentsUpload = () => {
    this.refs.commentAttachmentsUploader.click();
  };

  commentimageUpload = () => {
    this.refs.commentImageUploader.click();
  };

  removeAttachments = () => {
    this.setState({
      commentAttachments: [],
    });
  };

  onCancelImage(event, value) {
    event.preventDefault();
    let attachs = cloneDeep(this.state.commentAttachments);
    attachs.forEach((data) => {
      let index = attachs.indexOf(value);
      if (index !== -1) {
        attachs.splice(index, 1);
      }
    });
    this.setState({ commentAttachments: attachs });
  }

  onCancelImageAttach(event, value) {
    event.preventDefault();
    let attachs = cloneDeep(this.state.commentImageAttachments);
    attachs.forEach((data) => {
      let index = attachs.indexOf(value);
      if (index !== -1) {
        attachs.splice(index, 1);
      }
    });
    this.setState({ commentImageAttachments: attachs });
  }

  // Upload only image attachement for comment
  uploadImageAttachments = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let commentAttachments = cloneDeep(this.state.commentImageAttachments);
      let commentAttachmentsPreviewList = cloneDeep(
        this.state.commentImageAttachmentsPreviewList
      );

      let filesObj = commentAttachments ? commentAttachments : [];
      let filesArr = commentAttachmentsPreviewList
        ? commentAttachmentsPreviewList
        : [];
      let fileSize = 0;
      for (let i of e.target.files) {
        const allowedAttachmentsTypeArr = ["jpg", "jpeg", "png", "gif"];
        const fileName = i.name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (!allowedAttachmentsTypeArr.includes(extFile)) {
          this.setState({
            error:
              "Only " +
              allowedAttachmentsTypeArr.join("/") +
              " files are allowed!",
          });
          document.getElementById("commentFileUploader").value = null;
        } else {
          filesObj.push(i);
          filesArr.push(URL.createObjectURL(i));
          fileSize += i.size;
        }
      }

      if (fileSize > maxMbFileSize) {
        this.setState({
          error:
            "Uploaded files exceeds limit of 100 MB. Replace/Remove some files",
        });
        document.getElementById("commentFileUploader").value = null;
      } else {
        this.setState({
          commentImageAttachments: filesObj,
          commentImageAttachmentsPreviewList: filesArr,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 5000);
  };

  // upload secondary attacehment type (eg. csv,doc,pdf)
  uploadCommentAttachments = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let commentAttachments = cloneDeep(this.state.commentAttachments);
      let commentAttachmentsPreviewList = cloneDeep(
        this.state.commentAttachmentsPreviewList
      );

      let filesObj = commentAttachments ? commentAttachments : [];
      let filesArr = commentAttachmentsPreviewList
        ? commentAttachmentsPreviewList
        : [];
      let fileSize = 0;

      for (let i of e.target.files) {
        const fileName = i.name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        const allowedAttachmentsTypeArr = [
          "csv",
          "xlsx",
          "xls",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "txt",
          "pdf",
        ];
        if (!allowedAttachmentsTypeArr.includes(extFile)) {
          this.setState({
            error:
              "Only " +
              allowedAttachmentsTypeArr.join("/") +
              " files are allowed!",
          });
          document.getElementById("commentFileUploader").value = null;
        } else {
          filesObj.push(i);
          filesArr.push(URL.createObjectURL(i));
          fileSize += i.size;
        }
      }
      if (fileSize > maxMbFileSize) {
        this.setState({
          error:
            "Uploaded files exceeds limit of 100 MB. Replace/Remove some files",
        });
        document.getElementById("commentFileUploader").value = null;
      } else {
        this.setState({
          commentAttachments: filesObj,
          commentAttachmentsPreviewList: filesArr,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 5000);
  };

  getUserEntityCount = async () => {
    let response = await userDetailsEntityCount({"userId": this.state.userId});
    if(response?.message === "Success!") {
      this.setState({
        userEntityCount: response.body
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.currentUserDetails) && (nextProps.currentUserDetails?.userId !== this.state.currentUserDetails?.userId)) {
      this.setState({
        currentUserDetails: nextProps.currentUserDetails
      })
    }
    if(!_.isEmpty(nextProps.profileBasicInfoData) && this.state.userId !== this.state.userData.userId) {
      let data = nextProps.profileBasicInfoData;
      this.setState({
        currentUserDetails: { "userId": data.userId, "profileImage": getOptimizedImage(data, "profileImage"), type: data.type }
      })
    }
  }

  componentDidMount() {
    document.querySelector(".wrapper").addEventListener("click", event => {
      // Get parent element and check if click happened outside parent only
      const parent = document.querySelector(".emoji-picker-react");
      const { smiliDisplay } = this.state;
      if (smiliDisplay && !parent.contains(event.target)) {
        this.onToggleEmojiWindow();
      }
    });

    window
      .$(".commenttype .form-control, .commenttypeBtnsInn")
      .on("click", function (event) {
        window.$(".commenttype").addClass("active");
      });
    window.$(document).on("click", function (event) {
      if (!window.$(event.target).closest(".commenttype form").length) {
        window.$(".commenttype").removeClass("active");
      }
    });

    if (this.props.pageType === "POST_DETAILS" || this.props.pageType === "FORUM_DETAILS" || this.props.pageType === "STUDIO_DETAILS" || this.props.pageType === "EXPLORE_DETAILS" || this.props.pageType === "POLL_DETAILS") {
      this.getUserEntityCount();
    }
    if(this.state.userId !== this.state.userData.userId) {
        if (this.props.profileBasicInfo) {
            this.props.profileBasicInfo({ userId: this.state.userId });
        }
    }

    //window.$(".textarea-scrollbar").scrollbar();    
  }

  displayPreview = (file) => {
    if (file) {
      var binaryData = [];
      binaryData.push(file);

      return URL.createObjectURL(
        new Blob(binaryData, { type: "application/zip" })
      );
    }
  };

  onClickCommentListUser = () => {
    this.setState({
      isOpenUserListPop: true
    })
  }

  onSuccessCommentUser = (userData) => {
    this.triggerCloseCommentUserList();
    this.setState({
      currentUserDetails: { "userId": userData.entityId, "profileImage": userData.entityImageUrl, type: userData.entityType }
    })
  }

  triggerCloseCommentUserList = () => {
    window.$("#commentUserListPop").modal("hide");
    this.setState({
      isOpenUserListPop: false
    })
  }

  render() {
    const { currentUserDetails, isOpenUserListPop, userEntityCount } = this.state;
    const { pageType, item } = this.props;
    return (
      <>
        <div id="commentbox" className="commenttype">
          {userEntityCount > 0 && pageType !== "FEED" ? 
            <button className="postAsButtn" onClick={() => pageType !== "FEED" ? this.onClickCommentListUser() : "javascript:void(0)"} style={{ cursor: pageType !== "FEED" ? "pointer" : "default" }}>
                {commonCommentSection(currentUserDetails)}
            </button> : 
            commonCommentSection(currentUserDetails)
          }
          <form action="">
            <div className="commTextareaWrap">
              <MentionTextArea
                className="form-control"
                value={this.state.userComment}
                onChange={this.handleCommentTyped}
                placeholder="Write a comment..."
              ></MentionTextArea>

              <span
                className="login-feedback-error mt-1"
                style={{ display: "block" }}
              >
                {this.state.errors.commentText}
              </span>
              <div className="attachImgall">
                {this.state.commentImageAttachments &&
                  this.state.commentImageAttachments.map(
                    (attachment, index) => {
                      return (
                        <span className="pdescattachfile" key={index}>
                          <img src={this.displayPreview(attachment)} />
                          <i
                            className="iCross_Rounded"
                            onClick={(e) =>
                              this.onCancelImageAttach(e, attachment)
                            }
                          />
                        </span>
                      );
                    }
                  )}
              </div>
            </div>

            <div className="commenttypeBtns">
              <div className="commenttypeBtnsInn">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleCommentSubmit();
                  }}
                  disabled={this.state.isCommentSubmitted}
                  className="butt"
                >
                  <i className="iSend_fl" />
                </button>
                <a
                  href="javascript:void(0)"
                  onClick={this.commentAttachmentsUpload}
                  className="attchbtn"
                >
                  <i className="iClip" />
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={this.commentimageUpload}
                  className={"attchbtn"}
                >
                  <i className="iImg" />
                </a>
                <a
                  href="javascript:void(0)"
                  className="attchbtn"
                  onClick={this.handleSmiliDisplay}
                >
                  <i className="iEmoji" />
                </a>
              </div>
            </div>
            <input
              type="file"
              multiple
              accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,image/*,audio/*,video/*"
              id="commentFileUploader"
              onChange={this.uploadCommentAttachments}
              ref="commentAttachmentsUploader"
              style={{ display: "none" }}
            />
            <input
              type="file"
              multiple
              accept="image/*"
              id="commentImageUploader"
              onChange={this.uploadImageAttachments}
              onClick={(event) => {
                event.target.value = null;
              }}
              ref="commentImageUploader"
              style={{ display: "none" }}
            />
            {this.state.commentAttachments &&
              this.state.commentAttachments.map((attachment, index) => {
                return (
                  <span className="pdescattachfile" key={index}>
                    {getFileIcon(attachment.name)} {attachment.name}
                    <i
                      className="iCross_Rounded"
                      onClick={(e) => this.onCancelImage(e, attachment)}
                    />
                  </span>
                );
              })}

            {this.state.error && this.state.error !== "" && (
              <div className="forumcommtvlistbott">
                <div className="fattach" style={{ color: "red" }}>
                  {this.state.error}
                </div>
              </div>
            )}
          </form>
        </div>
        {isOpenUserListPop && <CommentUserListPop key={this.props.item.id} currentUserDetails={currentUserDetails} onSuccessCommentUser={this.onSuccessCommentUser} triggerCloseCommentUserList={this.triggerCloseCommentUserList} pageType={this.props.pageType} />}
        {this.state.smiliDisplay && (
          <Picker
            pickerStyle={{ width: "100%", height: "200px" }}
            onEmojiClick={this.onEmojiClick}
            disableSearchBar="true"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileBasicInfoData: state.ProfileReducer.profileBasicInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileBasicInfo: (state) => dispatch(setProfileBasicInfoDetails(state))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCommentBox));
