import React, {PureComponent} from "react";
import {isWebView} from '../commons/CommonFunctions'
import "./download.min.css"

let isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod');
let isAndroid = navigator.userAgent.match('Android');

export default class Download extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            disablebar: false,
            disablebarMobileBrowser: false
        }
    }

    componentDidMount() {

        setTimeout(() => {
            localStorage.setItem('myWenatAppDownloaded', true);
            this.setState({disablebar: true});
        }, 15000)

    }

    continueInBrowser = () => {
        localStorage.setItem('myWenatAppDownloaded', true);
        this.setState({disablebarMobileBrowser: true});
    }

    downloadBar = () => {

        let linkDownload
        if (isiOS) {
            linkDownload = "itms-apps://itunes.apple.com/app/my-app/id1546197495?mt=8"
        } else if (isAndroid) {
            linkDownload = "https://play.google.com/store/apps/details?id=com.wenaturalists"
        }

        if (isWebView()) {
            return <></>
        } else {
            if (isiOS || isAndroid) {
                return !this.state.disablebarMobileBrowser && !localStorage.getItem('myWenatAppDownloaded') &&
                    <div className="bkbackdrop forMob" onClick={() => this.continueInBrowser()}>
                        <div className="bkappdwnldmobModal">
                            <div className="bkmobaplogobx">
                                <img src="https://cdn.dscovr.com/images/welogoNew.webp"
                                onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/welogoNew.png"}} alt=""/>
                            </div>
                            <p>Enhance your experience with the app</p>
                            <div className="mobbkapbtnbx">
                                <a href={linkDownload} className="bkdnapp"><i className="iDownloadFile"/>Download
                                    App</a>
                                <button className="bkcntune" onClick={() => this.continueInBrowser()}>Continue in
                                    browser
                                </button>
                            </div>
                        </div>
                    </div>
            } else {
                return (!this.state.disablebar && !localStorage.getItem('myWenatAppDownloaded') &&
                    <div className="bkappdownloadModal fordsktp">
                        <button type="button" className="dnladclose" onClick={() => this.continueInBrowser()}><span
                            aria-hidden="true">×</span></button>
                        <div className="container">
                            <div className="bkdwnldapp">
                                <img src="https://cdn.dscovr.com/images/PhoneAppIMG.webp"
                                onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/PhoneAppIMG.png"}} alt=""/>
                                <p>Enhance your experience with the mobile app</p>
                                <div className="bkapbtnbx">
                                    <a href="https://itunes.apple.com/app/my-app/id1546197495?mt=8" target="_blank">
                                        <img
                                        src="https://cdn.dscovr.com/images/AppstoreBadge.webp"
                                        onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/AppstoreBadge.png"}} alt=""/></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.wenaturalists" target="_blank">
                                        <img src="https://cdn.dscovr.com/images/PlaystoreBadge.webp"
                                        onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/PlaystoreBadge.png"}}
                                                            alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div>)
            }
        }

    }

    render() {
        return this.downloadBar()
    }
}
