import React, { memo } from "react";
import { Component } from "react";
import { authService } from "../../auth";
import axios from "axios";
import { getMaxChars, getOptimizedImage, redirecToProfile } from "../../project/component/Studio/StudioContentCommonFunction";
import StudioContentHelpDeskInfoPopup from "../../project/component/Studio/StudioContentHelpDeskInfoPopup";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UserHelpDeskPopUp from "./UserHelpDeskPopUp";

const ErorrMsgPopUp = withReactContent(alertPopUp);
class UserHelpDeskDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            helpDeskPopUp: false,
            helpDeskData: [],
            editHelpDesk: false,
            editHelpDeskId: "",
            errors: {},
            helpDeskDataItem: {},
            openDeleteModal: false,
            processingDeleteRequest: false,
            deleteHelpDeskDetails: {},
            collapseOpen: false
        }
    }

    onClickAddHelpDesk = () => {
        this.setState({
            helpDeskPopUp: true,
            editHelpDesk: false
        }, () => {
            window.$("#addHelpDeskPop").modal("show");
        })
    }

    triggerHelpDeskModalClose = () => {
        this.setState({
            helpDeskPopUp: false,
            editHelpDesk: false,
            editHelpDeskId: ""
        })
        window.$("#addHelpDeskPop").modal("hide");
    }

    getHelpDeskDetails = (collapseOpen) => {
        if(!collapseOpen) {
            axios({
                url: `${process.env.REACT_APP_userServiceURL}/userMasterData/getMasterUserDetails?userId=${this.props.userId}&privateEntityId=${this.state.userData?.entityId}`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.body && response.data.message === "Success!") {
                    let data = response.data.body;
                    this.setState({
                        helpDeskData: data
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
        }
        this.setState({
            collapseOpen: !collapseOpen
        })
    }

    deleteHelpDeskData = () => {
        this.setState({processingDeleteRequest: true});
        let deleteHelpDesk = this.state.deleteHelpDeskDetails;
        let postData = {
            id: deleteHelpDesk && deleteHelpDesk.id,
            userId: deleteHelpDesk && deleteHelpDesk.userId,
            requestingUserId: this.props.userId
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/userMasterData/delete`,
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === "Success!") {
                let helpDeskData = this.state.helpDeskData;
                const index = helpDeskData && helpDeskData.findIndex(data => data.id === this.state.deleteHelpDeskDetails.id);
                if (index > -1) {
                    helpDeskData.splice(index, 1); 
                }
                window.$("#confirmpopForDeleteHelpDesk").modal("hide");
                this.setState({processingDeleteRequest: false, helpDeskData: helpDeskData, deleteHelpDeskDetails: {}, openDeleteModal: false});
            }
        }).catch((err) => {
            console.log(err);
            this.setState({processingDeleteRequest: false})
            window.$("#confirmpopForDeleteHelpDesk").modal("hide");
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm();
                }
            }).then((result) => {
                return ErorrMsgPopUp.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: <><p>{err.response.data.message}</p></>,
                })
            })
        });
    }

    openEditHelpDesk = (userId) => {
        this.setState({
            helpDeskPopUp: true,
            editHelpDesk: true,
            editHelpDeskId: userId
        },() => {
            window.$("#addHelpDeskPop").modal("show");
        })
    }

    openDeleteHelpDeskModal = (id, userId) => {
        this.setState({
            openDeleteModal: true,
            deleteHelpDeskDetails: {"id": id, "userId": userId}
        },() => {
            window.$("#confirmpopForDeleteHelpDesk").modal("show");
        })
    }

    triggerDeleteClose = () => {
        this.setState({
            openDeleteModal: false
        })
        window.$("#confirmpopForDeleteHelpDesk").modal("hide");
    }

    getUserBriefDescription = (data) => {
        this.setState({
            helpDeskDataItem: data
        });
    }

    componentWillUnmount() {
        window.$("#confirmpopForDeleteHelpDesk").modal("hide");
        ErorrMsgPopUp.clickConfirm();
    }

    render() {
        const { helpDeskPopUp, helpDeskData, editHelpDesk, editHelpDeskId, openDeleteModal, processingDeleteRequest, collapseOpen } = this.state;
        const { userId } = this.props;
        return (
            <div class="card">
                <div class="card-header" id="heading-3">
                    <div class="left">
                        <h2>Help Desk</h2>
                        <p>Provide contact details of  who would be answering user queries</p>
                    </div>
                    <div class="right"><a role="button" data-toggle="collapse" href="#helpDeskDetails" aria-expanded="false" class="btn edit txtViewIco" onClick={(e) => this.getHelpDeskDetails(collapseOpen)}>View</a></div>
                </div>
                <div id="helpDeskDetails" class="collapse" data-parent="#accordion" aria-labelledby="heading-3">
                    <div class="card-body">
                        <div className='stdtlsChatHWrap'>
                            <div className='row'>
                                {helpDeskData && helpDeskData.length > 0 && helpDeskData.map((item) => {
                                    return (
                                        <div className='col-md-4'>
                                            <div className='stdtlsChatgrid'>
                                                <figure className='users' onClick={(e) => redirecToProfile(item.customUrl)}>
                                                    <img src={item.originalProfileImage ? getOptimizedImage(item) : item.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.webp" : "https://cdn.dscovr.com/images/DefaultBusiness.png"} alt='' />
                                                </figure>
                                                <div className="contxmenu main">
                                                    <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="iKebab" />
                                                    </button>
                                                    <div className="dropdown-menu fadeIn animated">
                                                        <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => this.openEditHelpDesk(item.userId)}>
                                                            <i className="iEditBox" /> Edit
                                                        </a>
                                                        <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => this.openDeleteHelpDeskModal(item.id, item.userId)}>
                                                            <i className="iTrashBin" /> Delete
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 onClick={(e) => redirecToProfile(item.customUrl)}><span>{item.username}</span></h5>
                                                    {(item.email || item.phoneNumber || item.persona) && <div className='scInfos'>
                                                        {item.email && <span><span class="email">{item.email}</span></span>}
                                                        {item.phoneNumber !== 0 && <span><span class="phone">{item.phoneNumber}</span></span>}
                                                        {item.persona && <span><span class="designat">{item.persona}</span></span>}
                                                        {item.status === "REQUEST_SENT" && <span><span class="status">Pending</span></span>}
                                                    </div>}
                                                    <p>
                                                        <a href='#studioHepdeshInfoPop' onClick={(e)=>this.getUserBriefDescription(item)} data-toggle="modal" className='knmore'>
                                                            {getMaxChars(item.shortBrief, 80)}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <StudioContentHelpDeskInfoPopup helpDeskDataItem={this.state.helpDeskDataItem} />

                        </div>

                        <div className='addNewHlpDesk'>
                            {helpDeskData && helpDeskData.length < 5 && <span>
                                <a href="javascript:void(0)" className="btn" onClick={(e) => this.onClickAddHelpDesk()}><i className='iAddition' /> Add New</a>
                            </span>}
                            {helpDeskPopUp && <UserHelpDeskPopUp triggerHelpDeskModalClose={this.triggerHelpDeskModalClose} getHelpDeskDetails={this.getHelpDeskDetails} editHelpDesk={editHelpDesk} editHelpDeskId={editHelpDeskId} userId={userId} />}
                        </div>

                        {openDeleteModal && <div className="modal fade dgpopup confirmpop" id="confirmpopForDeleteHelpDesk" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <button type="button" className="close" onClick={() => this.triggerDeleteClose()}><span aria-hidden="true">&times;</span></button>
                                    <div className="confirm-popup-modal-body">Are you sure you want to delete this help desk?</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-primary"
                                                disabled={processingDeleteRequest}
                                                onClick={() => this.deleteHelpDeskData()}>Yes
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={() => this.triggerDeleteClose()}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default memo(UserHelpDeskDetails);