import axios from 'axios';
import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import { authService } from '../../auth';
import Header from '../../Header';
import NotFound from "../../404";
import CircleProfile from '../component/circleProfile';
import AnalticsBarViewData from '../../Analytics/AnalticsBarViewData';
import uuid from 'react-uuid';
import AnalyticsHeatMap from '../../Analytics/AnalyticsHeatMap';
import * as _ from "lodash";
import DonationStats from '../../Donation/DonationStats';

const userFilterOption = (donorsCount) => [
    { value: "FOLLOWERS", label: "Followers" },
    { value: "MEMBERS", label: "Members" },
    { value: "DONATIONS", label: "Fund Raise", isDisabled: donorsCount > 0 ? false : true }
]

export default class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterTypeOptionSelect: { value: "FOLLOWERS", label: "Followers" },
            filterResponseData: [],
            graphUpdatedValue: uuid(),
            toggleBar: 'map',
            dropdownCheck: [],
            userData: authService.getUserDetailsFromLocalStorage(),
            persnaResponseData : [],
            donationConfig: {},
            entityConfig: {},
            donorsCount: 0
        }
    }

    componentDidMount() {
        window.$(".outsdinvfrndhdr").click(function () {
            window.$(".outsdinvfrndbdy").slideToggle("slow");
            window.$(".outsdinvfrndhdr").toggleClass("active");
        });

        window.$(window).scroll(function () {
            if (window.$(window).scrollTop() + window.$(window).height() > window.$(document).height() - 300) {
                window.$('.btn.bcmemb').removeClass('fixed');
            } else {
                window.$('.btn.bcmemb').addClass('fixed');
            }
        });

        this.getCircleDetails();
    }

    onChangeFilterTypeOptionSelect = (data) => {
        this.setState({
            filterTypeOptionSelect: data,
            toggleBar: 'map',
            graphUpdatedValue: uuid(),
        }, () => {
            // this.getAnalyticsDataByType(data.value);
            this.getCircleAnalytics(data.value)
            this.getCircleAnalyticsPersona(data.value)
        })
    }

    getCircleAnalytics = (type) => {
        let postData = {
            type: type,
            circleId: this.state.circleData.id,
            dataType: this.state.toggleBar.toUpperCase(),
            userId: this.state.userData?.userId
        }
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/recommend/circlewiseanalytics`,
            method: "POST",
            data: postData,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response) {
                this.setState({
                    filterResponseData: response?.data?.data,
                    graphUpdatedValue: uuid()
                })
            }
        }).catch((error) => {
            console.log("Error", error);
        })
    }

    getCircleAnalyticsPersona = (type) => {
        let postData = {
            type: type,
            circleId: this.state.circleData.id,
            dataType: 'BAR',
            userId: this.state.userData?.userId
        }
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/recommend/circlewiseanalytics`,
            method: "POST",
            data: postData,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response) {
                this.setState({
                    persnaResponseData: response?.data?.data,
                    graphUpdatedValue: uuid()
                })
            }
        }).catch((error) => {
            console.log("Error", error);
        })
    }
    
    getCircleDetails = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/circle/get-by-slug/' + this.props.match.params.slug + '?userId=' + this.state.userData.userId + '&privateEntityId='+this.state.userData.entityId,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res && res.status === '200 OK') {
                if (res.body.inactiveFlagForMemberSubscription && res.body.memberType && res.body.memberType !== 'Admin' && res.body.memberType !== 'Super Admin') {
                    window.location.href = "/community/" + this.props.match.params.slug;
                }
                this.setState({
                    circleData: res.body
                }, () => {
                    this.getCircleAnalytics("FOLLOWERS");
                    this.getCircleAnalyticsPersona("FOLLOWERS")
                })
            }
        }).catch((err) => {
            if (err.response && err.response.status === 404) {
                this.setState({ notFoundstatusCode: err.response.status })
            }
        });
    }

    onChangeUserFilterOptionSelect = (userType) => {
        this.setState({
            userFilterOptionSelect: userType,
            sectionCompareOptionFlag: false
        }, () => {
            this.onChangeFetchFilterData("userFilter");
        })
    }

    getEntityDonationConfig = (config) => {
        this.setState({
            donationConfig: config
        })
    }

    getDonationConfig = (config) => {
        this.setState({
            entityConfig: config
        })
    }

    getDonorsCount = (count) => {
        this.setState({
            donorsCount: count
        })
    }

    render() {
        if (this.state.notFoundstatusCode === 404) {
            return <><Header EditedText={true} />
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <NotFound />
                    </div>
                </main></>
        } else {
            const { filterTypeOptionSelect, graphUpdatedValue, donorsCount } = this.state;
            return (
                this.state.circleData && this.state.circleData.deactivated ? <Redirect to={"/community/unavailable"} /> :
                !_.isEmpty(this.state.circleData) && (this.state.circleData.memberType !== 'Super Admin' && this.state.circleData?.memberType !== 'Admin') ? ( <Redirect to={`/community/${this.props.match.params.slug}`} /> ) :
                    (this.state.circleData && Object.keys(this.state.circleData).length > 0 ?
                        <CircleProfile slug={this.props.match.params.slug} circleData={this.state.circleData} getEntityDonationConfig={this.getEntityDonationConfig} needDonationConfig={true} pageType={"STATS_PAGE"} getDonationConfig={this.getDonationConfig} >
                            <div className="prof-compfollowers prof-micocomm-members circleStatsWrap">
                                <DonationStats entityId={this.state.circleData?.id} entityType={"CIRCLE"} entitySlug={this.props.match.params.slug} entityName={this.state.circleData?.title} donationConfig={this.state.donationConfig} getDonorsCount={this.getDonorsCount} />                  
                                <div className="projectinn analyticsPg settMngPermsRow">
                                    <div class="pagetitle"><h2>COMMUNITY STATS</h2></div>
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-6 col-12'>
                                            <Select
                                                placeholder={"Select type"}
                                                className="wnforSelect"
                                                name={"userFilterOption"}
                                                options={userFilterOption(donorsCount)}
                                                value={filterTypeOptionSelect}
                                                onChange={this.onChangeFilterTypeOptionSelect}
                                                styles={{
                                                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                        const color = '#cfe7c7';
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isDisabled
                                                                ? null
                                                                : isSelected
                                                                    ? '#cfe7c7'
                                                                    : isFocused
                                                                        ? '#cfe7c7'
                                                                        : null,
                                                            color: isDisabled
                                                                ? '#ccc'
                                                                : isSelected
                                                                    ? color > 2
                                                                        ? '#00374d'
                                                                        : '#00374d'
                                                                    : data.color,
                                                            cursor: isDisabled ? 'not-allowed' : 'default',

                                                            ':active': {
                                                                ...styles[':active'],
                                                                backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                            },
                                                        };
                                                    },
                                                }}
                                            />
                                        </div>
                                        {/* <div className="col-md-8 analDropDesc">
                                            <p>{`Charts here provide ${this.state.filterTypeOptionSelect?.value.toLowerCase()} details for your Circle profile`}</p>
                                        </div> */}
                                    </div>


                                    <>

                                        {this.state.toggleBar === "bar" &&
                                            <>
                                                <>
                                                    <div className="shortbydrop">
                                                        <a onClick={() => {
                                                            this.setState({
                                                                toggleBar: 'map'
                                                            })
                                                        }} className={this.state.toggleBar === "bar" ? "btn" : "btn active"}><i className='imap' /> Map</a>
                                                        <a onClick={() => {
                                                            this.setState({
                                                                toggleBar: 'bar'
                                                            })
                                                        }} className={this.state.toggleBar === "bar" ? "btn active" : "btn"}><i className='iBarGraphs' /> Bar Graph</a>
                                                    </div>
                                                </>

                                                <AnalticsBarViewData data={this.state.filterResponseData} name={`Distribution of ${this.state.filterTypeOptionSelect?.value === "FOLLOWERS"? "Followers" : this.state.filterTypeOptionSelect?.value === "MEMBERS" ? "Members" : "Donors" }`} key={graphUpdatedValue} entityConfig={this.state.entityConfig} selectData={this.state.filterTypeOptionSelect?.value} />
                                            </>
                                        }
                                        {this.state.toggleBar === "map" &&
                                            <>
                                                <div className="shortbydrop">
                                                    <a onClick={() => {
                                                        this.setState({
                                                            toggleBar: 'map'
                                                        })
                                                    }} className={this.state.toggleBar === "bar" ? "btn" : "btn active"}><i className='imap' /> Map</a>
                                                    <a onClick={() => {
                                                        this.setState({
                                                            toggleBar: 'bar'
                                                        })
                                                    }} className={this.state.toggleBar === "bar" ? "btn active" : "btn"}><i className='iBarGraphs' /> Bar Graph</a>
                                                </div>

                                                <AnalyticsHeatMap data={this.state.filterResponseData} name={`Distribution of ${this.state.filterTypeOptionSelect?.value === "FOLLOWERS"? "Followers" : this.state.filterTypeOptionSelect?.value === "MEMBERS" ? "Members" : "Donors"}`} key={graphUpdatedValue} pageName="CircleAnalytics" entityConfig={this.state.entityConfig} selectData={this.state.filterTypeOptionSelect?.value} />
                                            </>
                                        }
                                        <AnalticsBarViewData data={this.state.persnaResponseData} name={`Persona wise ${this.state.filterTypeOptionSelect?.value === "FOLLOWERS" ? "Followed by" :  this.state.filterTypeOptionSelect?.value === "MEMBERS" ? "Members from" : "Donated by"}`} key={graphUpdatedValue} entityConfig={this.state.entityConfig} selectData={this.state.filterTypeOptionSelect?.value} />
                                    </>
                                </div>
                            </div>
                        </CircleProfile> : null))
        }
    }
}
