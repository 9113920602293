import React, { PureComponent } from 'react';
import { authService } from "../auth";
import { Link } from 'react-router-dom';
import httpService from "../AxiosInterceptors";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import EventMessageSection from '../commons/EventMessageSection';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import ForumPostBody from './ForumPostBody';
import CommonPostMenuSection from './CommonPostMenuSection';
import CommonPostBottomSection from './CommonPostBottomSection';

httpService.setupInterceptors();
class ForumPostItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            description: props.item?.metaData?.content,
            editedText: props.item?.metaData?.content,
            hashTags: [],
            tags: [],
            smileDisplay: false,
            forumDataDetail: this.props.item?.metaData
        }
    }

    componentDidMount() {
        let tags = [];
        this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {
            tags.push(data);
            this.setState({ tags: tags });
        })
    }

    render() {
        const { forumDataDetail } = this.state;
        return (
            <div className="hpostblock">
                <div className="hpostblockinn">
                    {this.props.item && this.props.item.eventMessage && <EventMessageSection item={this.props.item} />}
                    {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

                    <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                    <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={forumDataDetail?.hashTags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType={"FORUM"} />

                    <div className="hpostcontent">
                        <div className="hpostconttop">
                            <ForumPostBody title={forumDataDetail.title} description={this.state.description} hashTags={forumDataDetail?.hashTags} slug={forumDataDetail?.slug} postType={"FORUM"} item={this.props.item} />
                        </div>

                        <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} postType={"FORUM"} postData={forumDataDetail} />

                    </div>

                </div>

                {(this.props.latestForumId === this.props.item.id) && <div className='viewFPBottCard'><Link to={"/forum"} className='btn'>View all</Link> Forums on WeNaturalists</div>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(ForumPostItem);
