import {call, put, takeEvery} from "redux-saga/effects";
import * as _ from "lodash";
import {authService} from "../../auth";
import {SET_NETWORK_COUNT, setNetworkCountOnSuccess} from "../../reducer/network";

import axios from 'axios';
import httpService from "../../AxiosInterceptors";
import { userAgentGetData } from "../../commons/CommonFunctions";

httpService.setupInterceptors();

function networkUserCountApi() {
    let userId =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let url =
        process.env.REACT_APP_userServiceURL + '/network/' + userId + '/connectInvitations?privateEntityId='+authService.getUserDetailsFromLocalStorage().entityId
        + "&page=0&size=1" + userAgentGetData();

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });
}

/** saga worker that is responsible for the side effects */
function* loadNetworkCountResult(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(networkUserCountApi, action.payload);

        if (
            response &&
            response.data &&
            response.data.body &&
            !_.isNull(response.data.body)
        ) {
            yield put(setNetworkCountOnSuccess(response.data.body));
        } else {
            console.log(response);
        }
    } catch (e) {
        console.log(e);
    }
}


export default function* watchNetworkCountLoad() {
    yield takeEvery(SET_NETWORK_COUNT, loadNetworkCountResult);
}
