import React, { PureComponent } from 'react';
import { authService } from "../auth";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import httpService from "../AxiosInterceptors";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import { getCDNUrl } from "../commons/CommonFunctions";
import EventMessageSection from '../commons/EventMessageSection';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import CommonPostMenuSection from './CommonPostMenuSection';
import CommonPostBottomSection from './CommonPostBottomSection';

httpService.setupInterceptors();
class AudioPostItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            description: props.item.description,
            editedText: props.item.description,
            hashTags: [],
            tags: [],
            smileDisplay: false
        }
    }

    componentDidMount() {
        let tags = [];
        this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {
            tags.push(data);
            this.setState({ tags: tags });
        })
    }

    onSuccessUpdatePost = (data) => {
        this.setState({
            description: data.description,
            tags: data.tags
        })
    }

    render() {
        return (
            <div className={`hpostblock` + (this.props.item.nominated && this.props.postSection === "FEED" ? ' pNominate' : '')}>
                <div className="hpostblockinn">
                    {
                        this.props.item && this.props.item.eventMessage &&
                        <EventMessageSection item={this.props.item} />
                    }
                    {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

                    <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                    <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={this.state.tags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType="AUDIO" />

                    <div className="hpostcontent">
                        <div className="hpostconttop">
                            {((this.state.description && this.state.description !== '') || this.state.tags) &&
                                <ReadMoreReadLess id={this.props.item.id} description={this.state.description} hashTags={this.state.tags} item={this.props.item} />
                            }
                            {
                                this.props.item.attachmentIds && this.props.item.attachmentIds[0] &&
                                this.props.item.attachmentIds[0].attachmentUrl &&
                                <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                    <AudioPlayer
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        customAdditionalControls={[]}
                                        showDownloadProgress={false}
                                        src={getCDNUrl(this.props.item.attachmentIds[0].attachmentUrl)}
                                    />
                                    {/*<audio controls className="iru-tiny-player">
                                            <source src={this.props.item.attachmentIds[0].attachmentUrl}
                                                    type="audio/mpeg"/>
                                        </audio>*/}
                                </div>
                            }
                            {this.props.item.postLocation &&
                                <span className="address"><i
                                    className="iLocation" />{this.props.item.postLocation}</span>
                            }
                        </div>

                        <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} />

                    </div>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(AudioPostItem);
