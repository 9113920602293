import React, { Component } from 'react'
import { authService } from '../auth';
import axios from 'axios';
import * as _ from "lodash";
import mappingUtils from '../project/component/commons/utils/mappingUtils';
import { convertUnicode, defaultImageSection, getCDNUrl, userAgentGetData } from './CommonFunctions';
import BusinessPageList from '../post/BusinessPageList';
import CirclePostItem from '../post/CirclePostItem';
import ResearchPostList from '../post/ResearchPostList';
import CirclePostList from '../post/CirclePostList';
import { CapitalizeFirstLetter } from '../utils/StringUtility';
import ProjectPostList from '../post/ProjectPostList';
import Scrollbars from 'react-custom-scrollbars';

class CommonPostAsPopUp extends Component {
    constructor(props) {
        super(props);
        let userData = authService.getUserDetailsFromLocalStorage();
        this.state = {
            userData: userData,
            circleList: [],
            projectList: [],
            businessPageList: [],
            circleAdminList: [],
            userId: "",
            parentEntityId: "",
            hiddenPrivacy: false,
            entityType: userData.type,
            entityName: userData.type === "COMPANY" ? userData.companyName : CapitalizeFirstLetter(userData.firstName),
            userName: userData.type === "COMPANY" ? userData.companyName : CapitalizeFirstLetter(userData.firstName),
            secondaryEntityName: "Feeds",
            coverImage: userData && userData.profileImage ? getCDNUrl(this.getOptimizedImage(userData)) : userData.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png",
        }
    }

    getCircleList = (entityId) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/circle/get/list-for-post/${entityId}?privateEntityId=${this.state.userData.entityId}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.message === "Success!") {
                this.setState({
                    circleList: mappingUtils.circleSorting(response.data.body),
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    };


    getProjectList = (entityId) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/backend/getProjects/${entityId}?excludeJobs=true&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    projectList: response.data.body,
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    getBusinessPageList(entityId) {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/profile/get/private/business/pages?userId=${entityId}${userAgentGetData()}&privateEntityId=${this.state.userData.entityId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    businessPageList: mappingUtils.businessPagesSorting(response.data.body)
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getCircleAdminList(entityId) {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/circle/get/list-for-post/${entityId}?isAdmin=true&privateEntityId=${this.state.userData.entityId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    circleAdminList: mappingUtils.circleSorting(response.data.body)
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    setAsUser = (userId, entityType, entityName, entityId, coverImage) => {
        this.setState({
            userId: userId,
            entityType: entityType,
            entityId: entityType === "Circle" ? entityId : null,
            parentEntityId: entityType === "Circle" ? entityId : null,
            entityName: entityName,
            secondaryEntityName: entityType?.toLowerCase() === "circle" ? "Community Activity" : "Feeds",
            hiddenPrivacy: entityType?.toLowerCase() === "circle" || entityType?.toLowerCase() === "company" ? true : false,
            coverImage: coverImage ? coverImage : this.state.userData && this.state.userData.profileImage ? getCDNUrl(this.getOptimizedImage(this.state.userData)) : this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png",
        }, () => {
            this.getProjectList(this.state.userId);
            this.getCircleList(this.state.userId);
        });
    };

    setAsEntity = (entityId, entityType, secondaryEntityName) => {
        this.setState({
            parentEntityId: entityId,
            entityId: entityId,
            secondaryEntityName: secondaryEntityName,
            hiddenPrivacy: entityType === "Circle" || entityType === "Cause" || this.state.entityType === "Company" || (this.state.entityType === "Circle" && entityType === "Project") ? true : false
        });
    };

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages["200x200"] != null) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.profileImage;
    }

    getEditDataDetails = () => {
        if(!_.isEmpty(this.props.entityData)) {
            let entityData = this.props.entityData;
            this.setState({
                userId: entityData.userId,
                entityType: entityData.entityType,
                entityId: entityData.entityId,
                entityName: entityData.entityName,
                secondaryEntityName: entityData.secondaryEntityName,
                hiddenPrivacy: entityData.hiddenPrivacy,
                coverImage: entityData.coverImage
            })
        }
        if(this.props.secondaryEntityName) {
            this.setState({
                secondaryEntityName: this.props.secondaryEntityName
            })
        }
        if(this.props.entityName) {
            this.setState({
                entityName: this.props.entityName,
                userId: this.props.userId,
                entityType: this.props.entityType ? this.props.entityType : this.state.entityType
            })          
        }
        this.getProjectList(this.props.userId ? this.props.userId : this.state.userData.userId);
        this.getCircleList(this.props.userId ? this.props.userId : this.state.userData.userId);  
    }

    componentDidMount() {
        this.getEditDataDetails();
        this.getBusinessPageList(this.state.userData.userId);
        this.getCircleAdminList(this.state.userData.userId);
    }

    onSubmitData = () => {
        let entityData = { entityId: this.state.entityId, entityName: this.state.entityName, coverImage: this.state.coverImage, userId: this.state.userId, secondaryEntityName: this.state.secondaryEntityName,  hiddenPrivacy: this.state.hiddenPrivacy, entityType: this.state.entityType, parentEntityId: this.state.parentEntityId };
        this.props.onSelectPostAsData(entityData);
    }

    render() {
        const { userData, entityName, entityType } = this.state;
        const { isShare } = this.props;
        return (
            <div id="postFilterModal" className="postFilterModal modal fade dgpopup" tabIndex="1" data-backdrop="false" data-keyboard="false" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="megaBox">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="demohead">
                                            You are {isShare ? "sharing" : "posting"} as{" "}
                                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {entityType.toLowerCase()?.toLowerCase() !== "individual" ? entityName : convertUnicode(entityName)}
                                            </button>
                                        </div>
                                        <div className="postAsInnerWrap">
                                            <Scrollbars
                                                className={'forwcontlistmain'}
                                                autoHide
                                                autoHeight
                                                autoHeightMin={0}
                                                autoHeightMax={350}
                                                universal={true}
                                                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                                renderTrackVertical={props => <div {...props} style={{display: 'none'}} className="track-vertical"/>}
                                            >
                                            <div className="userpanel" onClick={() => this.setAsUser(
                                                    userData.userId, userData.type == "COMPANY" ? "Company" : "Individual", this.state.userName, null,
                                                    userData && userData.profileImage ? getCDNUrl(this.getOptimizedImage(userData)) : defaultImageSection(userData.type))}>
                                                <figure className="usimg">
                                                    <img src={userData && userData.profileImage ? getCDNUrl(this.getOptimizedImage(userData)) : defaultImageSection(userData.type)} alt="" />
                                                </figure>
                                                {this.state.userName}
                                            </div>
                                            {this.state.businessPageList && this.state.businessPageList.businessPage && this.state.businessPageList.businessPage.length > 0 && (
                                                userData && userData.type === "COMPANY" ? "" : (
                                                    <BusinessPageList
                                                        setAsUser={this.setAsUser}
                                                        businessPageList={this.state.businessPageList}
                                                    />
                                                )
                                            )}
                                            {this.state.circleAdminList && this.state.circleAdminList.length > 0 && (
                                                <>
                                                    {this.state.circleAdminList.map((item) =>
                                                        <CirclePostItem key={item.id} item={item} setAsUser={() => this.setAsUser(item.id, 'Circle', item.title, item.id, !_.isNull(item.profileImage) && !_.isUndefined(item.profileImage) ? getCDNUrl(this.getOptimizedImage(item)) : 'https://cdn.dscovr.com/images/circleDefault.png')} />
                                                    )}
                                                </>
                                            )}
                                            </Scrollbars>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="demohead">
                                            in{" "}
                                            <button type="button"
                                                className="btn dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {convertUnicode(this.state.secondaryEntityName)}
                                            </button>
                                        </div>

                                        <div className="postAsInnerWrap">
                                            <Scrollbars
                                                className={'forwcontlistmain'}
                                                autoHide
                                                autoHeight
                                                autoHeightMin={0}
                                                autoHeightMax={350}
                                                universal={true}
                                                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                            >
                                            {entityType?.toLowerCase() === "circle" ? (
                                                <>
                                                    <li className="postCardBlk">
                                                        <a href="javascript:void(0)" onClick={() => this.setAsEntity(this.state.userId,  "Circle", "Community Activity")}>
                                                            <figure className="ico">
                                                                <i className="iCircle_Default" style={{ fontSize: "15px" }}/>
                                                            </figure>
                                                            <div>
                                                                <h5>{"Community Activity"}</h5>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </>
                                            ) : (
                                                <li className="postCardBlk">
                                                    <a href="javascript:void(0)" onClick={() => this.setAsEntity(null, "Individual","Feeds")}>
                                                        <figure className="ico">
                                                            <i className="iWN_Feeds_OL" style={{ fontSize: "15px" }}/>
                                                        </figure>
                                                        <div>
                                                            <h5>{"Feeds"}</h5>
                                                        </div>
                                                    </a>
                                                </li>
                                            )}
                                            <div id="accordion">
                                                {this.state.projectList && this.state.projectList.length > 0 && (
                                                    <ProjectPostList setAsEntity={this.setAsEntity} projectList={this.state.projectList} />
                                                )}
                                                <ResearchPostList
                                                    setAsSource={this.setAsSourceSecondEntity}
                                                />

                                                {this.state.circleList && this.state.circleList.length > 0 && (
                                                    entityType?.toLowerCase() === "Circle" ?  "" : (
                                                        <CirclePostList setAsUser={this.setAsEntity} circleList={this.state.circleList} />
                                                    )
                                                )}
                                            </div>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottpostpopbody">
                                    <div className="bottpostpopright">
                                        <a href="javascript:void(0)" className="btn cancel" aria-label="Close" onClick={this.props.onCloseEditPostAs}>Cancel</a>
                                        <button type="button" className="btn" onClick={this.onSubmitData}>Proceed</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default CommonPostAsPopUp;
