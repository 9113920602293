import React from "react";
import axios from "axios";
import * as _ from "lodash";

import httpService from "../AxiosInterceptors";
import { isWebView } from "../commons/CommonFunctions";

httpService.setupInterceptors();

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    let validation = {
      username: {},
    };
    let token;
    this.state = {
      validation,
      isLoading: true,
      username: "",
      isDisabled: false,
      errors: {},
    };
  }

  componentDidMount() {
    axios({
      method: "post",
      url:
        process.env.REACT_APP_userServiceURL +
        "/api/authenticate?username=" +
        process.env.REACT_APP_authUser +
        "&password=" +
        process.env.REACT_APP_authSecret,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        this.token = response.headers.authorization;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleUserNameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ isDisabled: true });
      let postBody = {
        username: this.state.username.trim(),
      };
      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/user/reset/password",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        data: postBody,
      })
        .then((response) => {
          let res = response.data;

          if (res.status === "202 ACCEPTED") {
            res.body.mediaData = this.state.username;
            this.props.history.push({
              pathname: "/forgot-password-otp",
              state: {
                resetPassword: res.body,
              },
            });
          }
        })
        .catch((err) => {
          this.setState({ isDisabled: false });
          if (err && err.response && err.response.data) {
            this.setState({
              isLoaded: true,
              error: { message: err.response.data.message, err: err.response },
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        error: {},
      });
    }, 5000);
    setTimeout(() => {
      this.setState({
        errors: {},
      });
    }, 5000);
  };

  validateForm = () => {
    this.state.validation.username = {};
    let validationRequired = false;
    let errors = {};
    let formIsValid = true;
    if (
      _.isUndefined(this.state.username) ||
      _.isEmpty((this.state.username || "").toString()) ||
      _.isNull(this.state.username)
    ) {
      errors["username"] = "Email is mandatory";
      this.state.validation.username = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      formIsValid = false;
      validationRequired = true;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  render() {
    const { error } = this.state;
    return (
      <section className="loginwrap">
        <div className="container-fluid">
          <div className="login-box text-center">
            {!isWebView() ? (
              <a href="/" className="logotop">
                <span>my</span> <img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" />
              </a>
            ) : (
              <a href="/login" className="logotop">
                <span>my</span> <img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" />
              </a>
            )}
            <h1 className="login-header-text" style={{ paddingTop: "48px" }}>
              Reset your Password
            </h1>
            <p>Enter your email to receive OTP</p>
            <div className="login-form">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="custom-input form-control"
                    id="email"
                    value={this.state.username || ""}
                    onChange={this.handleUserNameChange}
                    aria-describedby="emailHelp"
                    placeholder="Email"
                  />
                  <span
                    className="login-feedback-error mt-1"
                    style={{ display: "block" }}
                  >
                    {this.state.errors.username}
                  </span>
                  <span className="login-feedback-error mt-1">
                    {this.state.validation.username &&
                      this.state.validation.username.message}
                  </span>
                  {error && (
                    <span
                      className="login-feedback-error mt-1"
                      style={{ display: "block" }}
                    >
                      {" "}
                      {error.message}{" "}
                    </span>
                  )}
                </div>
                <button
                  disabled={this.state.isDisabled}
                  type="submit"
                  className="login-btn btn btn-primary mt-3"
                >
                  Continue
                </button>
                <div className="cancel">
                  <a href="/login" className="counter_Btn btn">
                    Cancel
                  </a>
                </div>
              </form>
            </div>
            <p className="login-paragraph-text">
              If you do have forgotten the email or phone number linked to your
              account
            </p>
            <a
              href="mailto:support@wenaturalists.com"
              target="_blank"
              className="forgot-link"
            >
              Contact Support
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default ResetPassword;
