import React, {Component} from 'react';
import {authService} from "../auth";
import axios from "axios";
import {UserService} from "../UserData";
import "./styles/EndorsementPopUp.min.css";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import {convertUnicode} from "../commons/CommonFunctions";
import uuid from "react-uuid";


class OfflineParticipantRequestPopUp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            otherUserData: UserService.getOtherUserData(),
            skillsSuggestions: [],
            skills: [],
            isSubmitted: false,
            errors: {},
            loadClass :'modal fade dgpopup confirmpop',
            offlineBody :true,
            isLoading: false,
            modalEntityId: this.props.entityId + uuid(),
            visibleToProfileChecked:true,
            visibleToProfile:true,
            vaccancyExceed:false
        }

    }

    componentDidMount() {
        window.$('#confirmpop' + this.state.modalEntityId).modal('show');
    }

    componentWillUnmount() {
        window.$('#confirmpop' + this.state.modalEntityId).modal('hide');
    }

    hideModal = () => {
        window.$('#confirmpop' + this.state.modalEntityId).modal('hide');
        this.props.triggerParticipantRequestPopUpClose();
    }


    handleVisibleToProfile = (event)=>{
        event.preventDefault();
        this.setState({'visibleToProfileChecked':event.target.checked});
        let visibleToProfile = this.state.visibleToProfile;
        let postBody = {
            "projectId":this.props.entityId,
            "visibleToProfile": !this.state.visibleToProfile,
            "userId":this.state.userData.userId,
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/participants/update/visible-to-profile',
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then((response, dispatch) => {
            let res = response.data;
            if (response.status === 202) {
                this.setState({'visibleToProfile':!this.state.visibleToProfile});
                //this.props.triggerParticipantRequestPopUpClose();
            }
        }).catch((err) => {

        });

    };



    acceptRequest = (event) => {
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false})
        let postBody = {
            "id": this.props.participantRequestId,
            "participationPartyType": 'INDIVIDUAL_OR_RECRUITER',
            "notificationId": this.props.participantNotificationId,
            "creatorUserId":this.props.creatorUserId,
            "privateEntityId": this.state.userData.entityId
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/participants/offline/accept/request',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '201 CREATED') {
                this.props.updateNotificationDataForOfflineRequest(this.props.participantNotificationId);
                if(this.props.projectType.toLowerCase() !== 'job'){
                    this.setState({'loadClass':'modal fade dgpopup reportPop show','offlineBody':false});
                } else{
                    this.props.triggerParticipantRequestPopUpClose();
                }
            }
            this.setState({isLoading: false});
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                if(err.response.data.status === '406'){
                      this.setState({vaccancyExceed:true});
                }
                this.setState({
                    isLoaded: true,
                    isSubmitted: false,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        setTimeout(() => {
            this.setState({
                error: {},
                isLoading: false
            });
        }, 5000);
    };


    cancelRequest = (event) => {
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false})
        let postBody = {
            "id": this.props.participantRequestId,
            "notificationId": this.props.participantNotificationId
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/participation/offline/cancel/request',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '201 CREATED') {
                this.props.updateNotificationDataForOfflineRequest(this.props.participantNotificationId);
                this.hideModal();
            }
            this.setState({isLoading: false});
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    isSubmitted: false,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        setTimeout(() => {
            this.setState({
                error: {},
                isLoading: false
            });
        }, 5000);
    };

    render() {
        return <>
            <div className={this.state.loadClass} id={'confirmpop'+this.state.modalEntityId} tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop={'static'}>
                <div className="modal-dialog" role="document">
                    {this.state.offlineBody && !this.state.vaccancyExceed &&
                    <div className="modal-content">
                        <button type="button" className="close" onClick={this.hideModal}><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">
                            Are you sure you want to accept participant request
                            for {mappingUtils.capitalizeFirstLetter(this.props.projectType)} {convertUnicode(this.props.participantRequestTitle)}?
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.acceptRequest}
                                    disabled={this.state.isLoading}>Yes
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.cancelRequest}>No</button>
                        </div>
                    </div>
                    }
                    {this.state.vaccancyExceed &&
                    <div className="modal-content">
                        <button type="button" className="close" onClick={this.hideModal}><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">
                            Reached maximum number of invitations allowed. Contact Super Admin.


                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">

                            <button type="button" className="btn btn-secondary" onClick={this.hideModal}>OK</button>
                        </div>
                    </div>
                    }
                    {!this.state.offlineBody && !this.state.vaccancyExceed &&
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="forwcontwrap">
                                <div className="formwrap">
                                    <div className="custom-control custom-checkbox text-left">
                                        <input type="checkbox" className="custom-control-input"
                                               checked={this.state.visibleToProfileChecked}
                                               onChange={this.handleVisibleToProfile}
                                               id="projectFormVisibleToProfile"/>
                                        <label className="custom-control-label"
                                               htmlFor="projectFormVisibleToProfile" style={{cursor: 'pointer'}}>
                                            Display in Profile
                                            <span style={{display: 'block'}}>Ongoing or the Completed projects will reflect in your Profile under the experience section</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="formbottom text-center">
                                    <button type="button" className="btn btn-cancel"
                                            onClick={this.hideModal}>OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>

        </>


    }
}



export default OfflineParticipantRequestPopUp


