import React, { Component } from 'react';
import axios from "axios";
import { authService } from '../auth';
import { Link } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import HorizontalScroll from 'react-scroll-horizontal';
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import { getCDNUrl, isMobileDevice } from '../commons/CommonFunctions';
import LikeMemberItem from '../commons/LikeMemberItem';
if (isMobileDevice()) {
    import('../commons/styles/responsive-useragent.css').then(() => {
        console.log("Imported mobile css");
    });
}

class PollViewVoterPopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            selectedAnswer: this.props.pollAnswerList && this.props.pollAnswerList[0],
            selectedPollUserVoterList: []
        }
    }

    componentWillUnmount() {
        window.$("#pollViewVoterPopUp").modal('hide');
    }

    getPollVoterListDetails = (pageNo, answer ,concatWithPrevious) => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/poll/getUsers?pollId=${this.props.pollId}&userId=${this.state.userData.userId}&privateEntityId=${this.state.userData?.entityId}&answerId=${parseInt(answer.id)}&page=${pageNo}&size=10`,
        }).then((response) => {
            if (
                response &&
                response.data &&
                response.data.status === "200 OK" &&
                response.data.body
            ) {
                let pagedData = response.data.body;
                if (concatWithPrevious  && this.state.selectedPollUserVoterList && this.state.selectedPollUserVoterList.content) {
                    pagedData.content = this.state.selectedPollUserVoterList.content.concat(pagedData.content);
                }
                this.setState({
                    selectedAnswer: answer,
                    selectedPollUserVoterList: pagedData
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    viewMoreVoter = () => {
        let pagedData = this.state.selectedPollUserVoterList && this.state.selectedPollUserVoterList.page;
        if(!pagedData.last) {
            this.getPollVoterListDetails(pagedData.pageable && pagedData.pageable.pageNumber + 1, this.state.selectedAnswer, true);
        }
    }

    componentDidMount() {
        this.getPollVoterListDetails(0, this.state.selectedAnswer, false);
    }

    render() {
        const parent = { height: '50px' }
        const {selectedAnswer, selectedPollUserVoterList} = this.state;
        const {pollAnswerList} = this.props;
        return (
            <div id="pollViewVoterPopUp" className="modal fade dgpopup reqendorse pollVoterListPop">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="similarAllpopWrap forwcontwrap" style={{ maxWidth: "550px", position: 'relative' }}>
                                <button type="button" className="close topCrossModal" data-dismiss="modal" aria-label="Close" onClick={(e) => this.props.closePollViewVoter()}>
                                    <i className="iCross" />
                                </button>

                                <div className="similarAllpopHeader">
                                    <h2>Voters</h2>
                                </div>                                
                                
                                {pollAnswerList && pollAnswerList.length > 0 &&
                                    <div className="endorsementsbott">
                                        <HorizontalScroll reverseScroll style={parent} className="dgrow">
                                            {pollAnswerList && pollAnswerList.map((data, index) => {
                                                return (
                                                    <div key={index} className="dgcol" onClick={((e) => this.getPollVoterListDetails(0, data, false))}>
                                                        <div className={'endorsementsbottblk' + (selectedAnswer?.id === data.id ? " active" : "")}>
                                                            {/* <h5>{data.name}</h5> */}
                                                            {data.totalVote} <span>{data.totalVote > 1 ? "Votes" : "Vote"}</span>
                                                        </div>
                                                    </div>)
                                            })
                                            }
                                        </HorizontalScroll>
                                    </div>
                                }

                                <div className="similarAllpopBody">
                                    <p>{selectedAnswer?.name}</p>

                                    <Scrollbars
                                        className={''}
                                        autoHide
                                        autoHeight
                                        autoHeightMin={0}
                                        autoHeightMax={365}
                                        universal={true}
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                                        renderTrackVertical={props => <div {...props} className="track-vertical" />}
                                    >
                                        <div className="forwcontlistmain">
                                            <ul style={{display: "block", padding: "0",}}>
                                                {selectedPollUserVoterList && selectedPollUserVoterList.content && selectedPollUserVoterList.content.map((data, index) => {
                                                    return (
                                                        <LikeMemberItem item={data} key={index}/>
                                                    )
                                                })
                                                }   
                                            </ul>
                                            {selectedPollUserVoterList && selectedPollUserVoterList.page && !selectedPollUserVoterList.page.last &&
                                                <div class="btnblock text-center">
                                                    <button class="btn morebtnsm" onClick={() => this.viewMoreVoter()}>View More</button>
                                                </div>
                                            }
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PollViewVoterPopUp;