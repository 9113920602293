import React, { Component } from "react";
import axios from 'axios';
import { getCDNUrl, getEnviormentRegex } from "../commons/CommonFunctions";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import ReactPlayer from "react-player";
import * as _ from "lodash";
import AuthModalComponent from "../LoginExtra/AuthModalComponent";
import CommonLinkPreview from "../commons/CommonLinkPreview";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
class UnAuthLinkPostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postLinkTypeUrl: '',
      loginRequired: false,
      og_data: {}
    };
  }

  componentDidMount(){
    this.setState({
      postLinkTypeUrl: this.props.item?.postLinkTypeUrl
    },()=>{
      this.getLinkPreviewUrl(this.state.postLinkTypeUrl)
    })
  }

  isValidUrl = (str) => {
    let regexp =
      /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  };

      getLinkPreviewUrl = (text) => {
        let data = {
            url : text,
            sendByMyWenat: true
        }
        axios({
            method: 'POST',
            url : `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response)=>{
            if(response?.data?.status === 200){
                this.setState({
                    og_data: response?.data?.data
                })
            }
        }).catch((error)=>{
            console.log("Error",error);
        })
    };

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.userProfileImage;
  }

  handleLoginClose = () => {
    this.setState({ loginRequired: false });
    window.$("#loginModal").modal("hide");
  };
  

  openLoginModal = () => {
    this.setState({ loginRequired: true });
    window.$("#loginModal").modal("show");
  };
  render() {
    var utcSeconds = this.props.item.createTime;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    return (
      <div className="hpostblock"> {/*pNominate*/}
        {!this.props.item.userEntityType ? (
          <figure
            className="userimg"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={
                this.props.item.userProfileImage
                  ? getCDNUrl(this.getOptimizedImage(this.props.item))
                  : this.props.item.userType !== "COMPANY"
                  ? "https://cdn.dscovr.com/images/users1.png"
                  : "https://cdn.dscovr.com/images/DefaultBusiness.png"
              }
              alt=""
            />
          </figure>
        ) : (
          <figure
            className="userimg"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={
                this.props.item &&
                this.props.item.params &&
                this.props.item.params["circleImage"] != null
                  ? getCDNUrl(
                      this.props.item.params &&
                        this.props.item.params["circleImage"]
                    )
                  : "https://cdn.dscovr.com/images/circleDefault.webp"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://cdn.dscovr.com/images/circleDefault.png";
              }}
              alt=""
            />
          </figure>
        )}
        <div className="hpostblockinn">
          <div className="hposthead">
            {!this.props.item.userEntityType ? (
              <div className="d-flex">
                <h3>{this.props.item.userName}</h3>
              </div>
            ) : (
              <div className="d-flex">
                <h3
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {this.props.item.params["circleTitle"]}
                </h3>
              </div>
            )}
            {this.props.item && this.props.item.persona &&
              <div className="hpostPersona">{this.props.item.persona}</div>}
            <div className="hpostdtls">
              {this.props.item && this.props.item.country &&
              <span className="address">
                <i className="iLocation" />
                {this.props.item && this.props.item.country}
              </span>}

              <span>{timeAgo.format(d, "mini-now")}</span>
            </div>
          </div>
          <div className="hpostcontent">
            <div className="hpostconttop">
              {this.props.item &&
                this.props.item.description &&
                this.props.item.description !== "" && (
                  <ReadMoreReadLess
                    id={this.props.item.id}
                    description={this.props.item.description}
                    hashTags={this.props.item.hashTags}
                  />
                )}
              {this.state.postLinkTypeUrl &&
                this.isValidUrl(this.state.postLinkTypeUrl) && (
                  <>
                    <div className="linkpostpreview">
                      {/*<div className="prevtitle">
                                                Preview
                                            </div>*/}
                      <div className="postarticledesc">
                        {this.state.postLinkTypeUrl.indexOf(
                          "https://youtu.be"
                        ) !== -1 ||
                        this.state.postLinkTypeUrl.indexOf(
                          "www.youtube.com/watch"
                        ) !== -1 ? (
                          <>
                            <div className="vidPlayerOverWrap">
                              <ReactPlayer
                                url={this.state.postLinkTypeUrl}
                                width={"100%"}
                                playing={false}
                                controls={false}
                                muted={true}
                              />
                              <span className="vidOverlay"></span>
                            </div>
                            <div className="customLinkPost" onClick={()=>{
                              window.location.href = this.state.postLinkTypeUrl }}>
                              <div className="customLinkPostDesc">
                                <h5>{this.state.og_data?.title}</h5>
                                <p>{this.state.og_data?.description}</p>
                                {/* <p>{this.state.postLinkTypeUrl}</p> */}
                              </div>
                            </div>
                            <a
                              href={this.state.postLinkTypeUrl}
                              className="btn playYoutube"
                              target="_blank"
                            >
                              <i className="iYouTube_Logo" /> Play on Youtube
                            </a>
                          </>
                        ) : (!_.isEmpty(this.state.og_data) && 
                          <CommonLinkPreview linkdata={this.state.postLinkTypeUrl} og_data={this.state.og_data} />
                        )}
                      </div>
                    </div>
                  </>
                )}

              {this.props.item.postLocation && (
                <span className="address">
                  <i className="iLocation" />
                  {this.props.item.postLocation}
                </span>
              )}
            </div>

            
              {this.props.item.secondaryAttachmentIds &&
              this.props.item.secondaryAttachmentIds.length > 0 && (
                <div className="hpostcontbott">
                <a
                  href="javascript:void(0)"
                  onClick={this.openLoginModal}
                  className="fattach"
                  data-toggle="modal"
                >
                  <i className="iLink" />
                  {this.props.item.secondaryAttachmentIds.length === 1
                    ? this.props.item.secondaryAttachmentIds.length + " File "
                    : this.props.item.secondaryAttachmentIds.length +
                      " Files "}{" "}
                  Attached
                </a>
                </div>
              )}
            
          </div>
        </div>
        {this.state.loginRequired === true ? (
          <AuthModalComponent handleLoginClose={this.handleLoginClose} />
        ) : null}
      </div>
    );
  }
}

export default UnAuthLinkPostItem;
