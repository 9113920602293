import React, { Component } from "react";
import { authService } from "../auth";
import DatePicker from "react-datepicker";
import uuid from "react-uuid";
import axios from "axios";
import addDays from 'date-fns/addDays'  
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate, userDonationSuccess } from "./DonationCommonFunction";
import DonationTopUp from "./DonationTopUp";
import { getCDNUrl } from "../commons/CommonFunctions";
import * as _ from "lodash";
import DonationTopUpPayment from "./DonationTopUpPayment";
import { withRouter } from "react-router-dom";

class DonationDateSelecItemlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            displayRange: false,
            donationTopUpModal: false,
            donationSelectedEntity: [],
            donationTopUpData: {},
            isAvailable: false,
            additionalDaysTopUpList: [],
            donationPaymentModal: false,
            entitySearchData: "",
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : !_.isEmpty(this.props.donateNowConfig) && this.props.donateNowConfig?.superAdminId ? this.props.donateNowConfig?.superAdminId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

     onDateChange = (date, entity) => {
        let donationSelectedEntity = this.state.donationSelectedEntity;
        let index = donationSelectedEntity && donationSelectedEntity.findIndex(data => data.entityId === entity.entityId);
        if(index > -1) {
            donationSelectedEntity[index].startTime =  date.getTime();
            donationSelectedEntity[index].endTime = addDays(date, entity.timeFrame).getTime();
        }
        this.setState({
            donationSelectedEntity: donationSelectedEntity,
        });
    };

    displayRangeSelect = (id, flag) => {
        let donationSelectedEntity = this.state.donationSelectedEntity;
        let index = donationSelectedEntity && donationSelectedEntity.findIndex(data => data.entityId === id);
        if(index > -1) {
            donationSelectedEntity[index].displayRange =  flag;
        }
        this.setState({
            donationSelectedEntity: donationSelectedEntity,
        });
    };

    onClickDonationTopup = (entity) => {
        this.setState({
            donationTopUpData: entity, 
            donationTopUpModal: true
        }, () => {
            window.$("#donationTopUpPop").modal("show");
        })
    }

    onCloseDonationTopUp = () => {
        window.$("#donationTopUpPop").modal("hide");
        this.setState({
            donationTopUpData: {}, 
            donationTopUpModal: false
        })
    }

    onSuccessDonationTopUp = (daysData, id) => {
        window.$("#donationTopUpPop").modal("hide");
        let donationSelectedEntity = this.state.donationSelectedEntity;
        let index = donationSelectedEntity && donationSelectedEntity.findIndex(data => data.entityId === id);
        if(index > -1) {
            donationSelectedEntity[index].additionalDaysTopUp =  daysData;
            //let selectedDataTimeFrame = donationSelectedEntity[index].timeFrame;
            //selectedDataTimeFrame = donationSelectedEntity[index].timeFrame + daysData.timeFrame;
            //donationSelectedEntity[index].endTime = addDays(donationSelectedEntity[index].startTime, selectedDataTimeFrame).getTime()
        }
        this.setState({
            donationSelectedEntity: donationSelectedEntity,
            donationTopUpData: {}, 
            donationTopUpModal: false,
            isAvailable: true
        })
        this.displayRangeSelect(id, false);
    }

    submitDonationTopUpDetails = () => {
        let postData = {
            userId: this.state.userId,
            entityType: this.props.entityConfigData?.type
        }
        let entityList = [];
        this.state.donationSelectedEntity && this.state.donationSelectedEntity.map((entity) => {
            entityList.push({"entityId": entity.entityId, "startTime": entity.startTime, "endTime": entity.endTime, "additionalDaysTopUp": entity.additionalDaysTopUp})
        })
        postData.entityList = entityList;
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/update`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                userDonationSuccess(this.props.donationDateSelectData?.type, this.props.type);
                this.props.onCloseDonationDateListDetails("donationDateItemListPop");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    submitAdditionalTopUpData = () => {
        window.$("#donationDateItemListPop").modal("hide");
        let additionalDaysTopUpList = this.state.donationSelectedEntity && this.state.donationSelectedEntity.filter((entity) => !_.isEmpty(entity.additionalDaysTopUp));
        this.setState({
            additionalDaysTopUpList: additionalDaysTopUpList,
            donationPaymentModal: true
        }, () => {
            window.$("#donationTopUpPayment").modal("show");
        })
    }

    onCloseDonationPayment = () => {
        window.$("#donationTopUpPayment").modal("hide");
        this.setState({
            additionalDaysTopUpList: [],
            donationPaymentModal: false
        })
        window.$("#donationDateItemListPop").modal("show");
    }

    onSuccessDonationPayment = (entityList) => {
        window.$("#donationTopUpPayment").modal("hide");
        if(this.props.entityConfigData?.showAdditonalTopUp) {
            this.props.onCloseDonationDateListDetails("donationDateItemListPop")
        } else {
            let donationSelectedEntity = this.state.donationSelectedEntity;
            entityList && entityList.map((entity) => {
                let index = donationSelectedEntity && donationSelectedEntity.findIndex(data => data.entityId === entity.entityId);
                if(index > -1) {
                    donationSelectedEntity[index].startTime = entity.startTime;
                    donationSelectedEntity[index].timeFrame = entity.timeFrame;
                    donationSelectedEntity[index].endTime = entity.endTime;
                    donationSelectedEntity[index].additionalDaysTopUp = {};
                }
            })
            this.setState({
                donationSelectedEntity: donationSelectedEntity,
                additionalDaysTopUpList: [],
                donationPaymentModal: false,
                isAvailable: false
            },() => {
                window.$("#donationDateItemListPop").modal("show");
            })
        }
    }

    handelSearchChange = (event) => {
        this.setState({
            entitySearchData: event.target.value
        }, () => {
            let donationSelectedEntity= [];
            let filterData = this.state.donationSelectedEntity && this.state.donationSelectedEntity.filter((item) => item.entityName && item.entityName.toLowerCase().includes(this.state.entitySearchData && this.state.entitySearchData.toLowerCase()));
            this.state.donationSelectedEntity && this.state.donationSelectedEntity.map((item, key) => {
                let index = filterData && filterData.findIndex(a => a.entityName === item.entityName);
                if(index > -1) {
                    item.display = true;
                    donationSelectedEntity.push(item);
                } else {
                    item.display = false;
                    donationSelectedEntity.push(item);
                }
            })
            this.setState({
                donationSelectedEntity
            })
        })
    }

    componentDidMount() {
        let donationSelectedEntity = [];
        if(this.props.entityConfigData?.showAdditonalTopUp && this.props.type === "ENTITY_DONATE") {
            let item = this.props.donationDateSelectData;
            item.type = this.props.entityConfigData?.type;
            item.display = true;
            donationSelectedEntity.push(item);
        } else {
            this.props.donationDateSelectData && this.props.donationDateSelectData?.entityList?.length > 0 && this.props.donationDateSelectData.entityList.map((entity) => {
                entity.additionalDaysTopUp = {};
                entity.displayRange = false;
                entity.display = true;
                donationSelectedEntity.push(entity);
            })
        }
        this.setState({
            donationSelectedEntity: donationSelectedEntity
        })
    }

    render() {
        const { donationSelectedEntity, donationTopUpModal, donationTopUpData, isAvailable, additionalDaysTopUpList, donationPaymentModal } = this.state;
        const { donationDateSelectData, type, entityConfigData, entitySearchData, showAdditonalTopUp, donateNowConfig } = this.props;
        return (
            <>
                <div id="donationDateItemListPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{ margin: '0' }}>
                            <div className="modal-body" style={{ padding: '0 15px' }}>
                                <div className="sProjectListPop datePart">
                                    <button type="button" className="close topCrossModal" aria-label="Close" onClick={() => this.props.onCloseDonationDateListDetails("donationDateItemListPop")}>
                                        <i className="iCross" />
                                    </button>
                                    {showAdditonalTopUp ? 
                                        <h2>Top up days for Fund Raise {donationDateSelectData?.type === "PROJECT" ? "Project" : "Community"}{donationSelectedEntity && donationSelectedEntity.length > 1 && "s"}</h2> :
                                        <h2>{type !== "ENTITY_DONATE" && <>{donationDateSelectData?.type === "PROJECT" ? "Project" : "Community"}{donationSelectedEntity && donationSelectedEntity.length > 1 && "s"}</>} Fund Acceptance Dates</h2>}
                                    {type !== "ENTITY_DONATE" && donationSelectedEntity && donationSelectedEntity.length > 5 &&
                                        <div className="searchByCard">
                                            <form className="searchbox">
                                                <input type="text" className="search_query form-control" id="search" name="search" value={entitySearchData} placeholder="Search" onChange={this.handelSearchChange} autoComplete="off" />
                                            </form>
                                        </div>
                                    }
                                    <div>
                                        {donationSelectedEntity && donationSelectedEntity?.length > 0 && donationSelectedEntity.map((entity) => {
                                            return (
                                                <div className={"sProjectListRow" + (entity.display ? "" : " not-show")}>
                                                    <div className='row align-items-center'>
                                                        <div className="col-md-6 col-sm-7">
                                                            <div className="sProjectListUsersBx">
                                                                <figure className="users group">
                                                                    <img src={entity.imageUrl ? getCDNUrl(entity.imageUrl) : donationDateSelectData.type === "PROJECT" ? "https://cdn.dscovr.com/images/project-default.webp" : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                                                        onError={(e) => { e.target.onerror = null; e.target.src = donationDateSelectData.type === "PROJECT" ?  "https://cdn.dscovr.com/images/project-default.jpg" : "https://cdn.dscovr.com/images/circleDefault.png" }} alt="" />
                                                                </figure>
                                                                <div>
                                                                    <h5>{entity.entityName}</h5>
                                                                    <p>Posted on <span>{getFormattedDate(entity.postedDate)}</span></p>
                                                                    <p class="country">{entity.country}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-sm-5 selectBank">
                                                            <label>Selected Bank :</label>
                                                            <p>{entity.bankDetail?.institutionName}</p>
                                                        </div>
                                                        <div className="col-md-3 col-sm-5 ml-sm-auto dateSelectBlk">
                                                            <div className='globalDT'>
                                                                <div className={"dateVField withEdit"}>
                                                                    {getFormattedDate(entity.startTime)} - {getFormattedDate(entity.endTime)}
                                                                    {<button class="btn" onClick={() => entityConfigData.showAdditonalTopUp ? this.onClickDonationTopup(entity) : this.displayRangeSelect(entity.entityId, true)}><i className="iEditBox" /></button>}
                                                                </div>
                                                                {entity.displayRange && (
                                                                    <div className={entityConfigData && entityConfigData.additionalTopUpAvailable ? 'globalDTWrap withAddiTopup' : 'globalDTWrap'}>                                                                        
                                                                        <DatePicker
                                                                            selected={entity.startTime}
                                                                            onChange={(date) => this.onDateChange(date, entity)}
                                                                            minDate={new Date()}
                                                                            startDate={entity.startTime}
                                                                            endDate={entity.endTime}
                                                                            dropdownMode="select"
                                                                            inline
                                                                        />                                                                        
                                                                        {/* {entityConfigData && entityConfigData.additionalTopUpAvailable && 
                                                                            <div className='datePurchesInfo'>
                                                                                <p>You can raise funds for {entity.timeFrame} day{entity.timeFrame > 1 && 's'} per {entityConfigData?.type === "CIRCLE" ? "community" : entityConfigData?.type.toLowerCase()} in your subscription plan.</p>
                                                                                <p>TopUp to increase the number of days.
                                                                                    <button class="btn" onClick={(e) => this.onClickDonationTopup(entity)}>Topup Now</button>
                                                                                </p>
                                                                            </div>
                                                                        } */}
                                                                        <div className='btnsBlk'>
                                                                            <div className='ml-auto'>
                                                                                <button type="button" className="btn" onClick={() => this.displayRangeSelect(entity.entityId, false)}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {donationSelectedEntity && donationSelectedEntity.filter((data) => data.display)?.length === 0 &&
                                            <div className="zdata">
                                                <div className="desc">
                                                    <h4>{showAdditonalTopUp ? `Fund Raise is not active in any ${donationDateSelectData?.type.toLowerCase()} to add a topup.` : "No data available."}</h4>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='sProjectListPopBott'>
                                        {(isAvailable || !entityConfigData.showAdditonalTopUp) && <button type="button" class="btn btn-primary" onClick={() => isAvailable ? this.submitAdditionalTopUpData() : this.submitDonationTopUpDetails()}>{isAvailable ? "Make Payment" : "Submit"}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {donationTopUpModal && <DonationTopUp key={uuid()} additionalDaysPlanList={entityConfigData?.additionalDaysPlan} isIndian={entityConfigData?.indian} donationTopUpData={donationTopUpData} donationType={donationDateSelectData.type} onCloseDonationTopUp={this.onCloseDonationTopUp} onSuccessDonationTopUp={this.onSuccessDonationTopUp} />}

                {donationPaymentModal && <DonationTopUpPayment key={uuid} additionalDaysPlanList={additionalDaysTopUpList} entityConfigData={entityConfigData} onCloseDonationPayment={this.onCloseDonationPayment} onSuccessDonationPayment={this.onSuccessDonationPayment} />}
            </>
        )
    }
}

export default withRouter(DonationDateSelecItemlist);