import React, { Component, memo } from "react";
import axios from "axios";
import * as _ from "lodash";
import { authService } from "../../../auth";
import { Link, Redirect, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { getImageDetails, getResizedImage, numFormatter, publishedTimeSection, redirecToStudioDetailsPage, redirecToStudiopage, redirectToProfile } from "../../component/Studio/StudioContentCommonFunction";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import StudioPostPopUp from "../../component/Studio/StudioPostPopUp";
import StudioPaidUserList from "./StudioPaidUserList";
import ReactTooltip from "react-tooltip";
import StudioViewClickSection from "./StudioViewClickSection";
import StudioDeleteModal from "./StudioDeleteModal";
import SearchZeroDataSection from "../../../commons/SearchZeroDataSection";

const ErorrMsgPopUp = withReactContent(alertPopUp);

let thumbnailModalSwal = {
    title: '<h5>There is no thumbnail attached to your studio. A default image will be displayed when you share your studio. You can customize the image to display by adding a thumbnail to your studio.</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Add Now',
    cancelButtonText: 'Later',
    allowOutsideClick: false,
};

class StudioListData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            studioPostData: [],
            studioPostSuccess: false,
            thresholdHeight: 1000,
            studioPostPage: 0,
            studioPostSize: 12,
            responseMessage: "",
            showPublishPopUp: false,
            showPaidUserPopUp: false,
            studioItemId: "",
            popUpDetails: {},
            allowAccess: false,
            openDeleteModal: false,
            userId: this.props.userId ? this.props.userId: authService.getUserDetailsFromLocalStorage().userId
        }
    }

    getStudioPostData = (page, size, concatWithPrevious, checkSearchFlag) => {
        let cancelToken;
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source();

        let url = "";
        if(this.props.studioPageType === "GLOBAL") {
            url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getGlobalList?userId=${this.state.userId}&page=${page}&size=${size}&searchType=${this.props.studioFilterType}&searchQuery=${this.props.searchData}&studioType=${this.props.studioType}&privateEntityId=${this.state.userData.entityId}`;
        }
        if(this.props.studioPageType === "SUBSCRIBED") {
            url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUserPurchasedList?userId=${this.state.userId}&page=${page}&size=${size}&privateEntityId=${this.state.userData.entityId}`;
        }
        if(this.props.studioPageType === "MY_STUDIO") {
            url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUserWiseList?userId=${this.state.userId}&page=${page}&size=${size}&privateEntityId=${this.state.userData.entityId}`;
        }
        if(this.props.studioPageType === "PROJECT" || this.props.studioPageType === "SETTING") {
            url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getProjectPostList?userId=${this.state.userId}&projectId=${this.props.projectId}&page=${page}&size=${size}&privateEntityId=${this.state.userData.entityId}`;
        }
        if(this.props.studioPageType === "INTERESTED") {
            url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUserInterestedList?userId=${this.state.userId}&projectId=${this.props.projectId}&page=${page}&size=${size}&privateEntityId=${this.state.userData.entityId}`;
        }
        if(this.props.studioPageType === "HELP_DESK") {
            url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getMasterUserList?userId=${this.props.helpDeskPageType === "OTHERS_HELPDESK_CONNECT" ? this.state.userId : this.props.userDetailsId}&masterDataId=${this.props.helpDeskPageType === "OTHERS_HELPDESK_CONNECT" ? this.props.userDetailsId : this.state.userId}&page=${page}&size=${size}`;
        }
        if(this.props.studioPageType === "HIDDEN_STUDIO") {
            url = `${process.env.REACT_APP_userServiceURL}/hidden/studio/list?userId=${this.state.userId}&page=${page}&size=${size}&showOnlyMyPost=false&privateEntityId=${this.state.userData.entityId}`;
        }

        axios({
            url: url,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then((response) => {

            if (
                response &&
                response.data &&
                response.data.body &&
                response.data.status === "200 OK"
            ) {
                let pageData = response.data.body;
                if (concatWithPrevious && this.state.studioPostData && this.state.studioPostData.content) {
                    pageData.content = this.state.studioPostData.content.concat(pageData.content);
                }
                if(this.props.studioPageType === "GLOBAL" && checkSearchFlag && response.data.body.content.length > 10) {
                    this.props.checkStudioSearchFlag();
                }
                if(this.props.studioPageType === "SETTING" && checkSearchFlag && response.data.body.content.length === 0) {
                    this.props.checkShowStudioSection();
                }
                this.setState({
                    studioPostData: pageData,
                    studioPostSuccess: false,
                    responseMessage: response.data.message
                })
            }
        }).catch((error) => {
            console.log(error)
            this.setState({
                studioPostSuccess: false
            })
        });
    };


    fetchMoreStudioPostData() {
        if (this.state.studioPostData && this.state.studioPostData.content && !this.state.studioPostData.last) {
            let pageable = this.state.studioPostData.pageable;
            this.setState({
                studioPostSuccess: true,
                studioPostPage: pageable.pageNumber + 1
            }, () => {
                this.getStudioPostData(this.state.studioPostPage, this.state.studioPostSize, true);
            });
        }
    }

    getPublishPopUp = (data) => {
        this.setState({
            popUpDetails: { "id": data.id, "publishionForFree": data.publishionForFree, "amount": data.amount, "formType": (data.status !== "ENABLE" && data.publishedTime === 0) ? "Publish" : "Update", "bankAccountId": data.bankAccountId },
            showPublishPopUp: true
        }, () => {
            window.$("#paidProjPublishPop").modal("show");
        })
    }

    checkThumbnailPopUp = (data) => {
        const handleShowPopUp = async () => {
            const swalval = await ErorrMsgPopUp.fire(thumbnailModalSwal);
            if (swalval && swalval.value === true) {
                window.location.href = `/edit/post-studio/${data.slug}`;
            } else {
                this.getPublishPopUp(data);
            }
        };
        handleShowPopUp();
    };

    triggerPublishPopUpClose = (id, publishionForFree, amount, bankAccountId) => {
        let studioPostDataList = this.state.studioPostData;
        const index = studioPostDataList && studioPostDataList.content.findIndex(p => p.id === id)
        studioPostDataList.content[index].publishionForFree = publishionForFree;
        studioPostDataList.content[index].status = "ENABLE";
        studioPostDataList.content[index].amount = amount;
        studioPostDataList.content[index].bankAccountId = bankAccountId;
        this.setState({
            showPublishPopUp: false,
            studioPostData: studioPostDataList
        })
        window.$("#paidProjPublishPop").modal("hide");
    }

    

    triggerPublishPostPopUpClose = () => {
        this.setState({
            showPublishPopUp: false
        })
        window.$("#paidProjPublishPop").modal("hide");
    }

    triggerPaidUserPopUpClose = () => {
        this.setState({
            showPaidUserPopUp: false
        }, () => {
            window.$("#paidUserListPop").modal("hide");
        })
    }

    getPaidUserListPopup = (id) => {
        this.setState({
            showPaidUserPopUp: true,
            studioItemId: id
        }, () => {
            //window.$("#paidUserListPop").modal("show");
        })
    }

    openDeleteModal = (id) => {
        this.setState({
            openDeleteModal: true,
            studioItemId: id
        }, () => {
            window.$("#confirmpopForDeletePost").modal("show");
        })
    }

    triggerDeleteClose = () => {
        this.setState({openDeleteModal: false});
        window.$("#confirmpopForDeletePost").modal("hide");
    }

    studioItemListDataAfterDelete = (id) => {
        let studioPostDataList = this.state.studioPostData;
        const index = studioPostDataList && studioPostDataList.content.findIndex(data => data.id === id);
        if (index > -1) {
            studioPostDataList.content.splice(index, 1); 
        }
        this.setState({
            studioPostData: studioPostDataList
        })
    }

    unHideStudio = (id) => {
        let data = {
            userId: this.state.userId,
            activityId: id,
        };
          
        axios({
            method: "post",
            url: process.env.REACT_APP_userServiceURL +"/hidden/unhide",
            data: data,
            withCredentials: true,
        })
        .then((response) => {
            if(response && response.data && response.data.message == 'Success!'){
                let studioPostData  = this.state.studioPostData;
                let index = studioPostData && studioPostData.content && studioPostData.content.findIndex(item => item.id === id);
                if(index > -1){
                    studioPostData.content.splice(index,1);
                }
                this.setState({studioPostData: studioPostData})
            }
        })
        .catch((err) => {
            console.log('err',err)
        });
    }

    redirecToStudioDetailsPage = (studioData) => {
        this.props.history.push({
            pathname: studioData.compeleted ? `/studio/${studioData.slug}` : `/edit/post-studio/${studioData.slug}`,
            state: {
                userId: this.props.projectDetail?.partyType === "CIRCLE" ? this.props.projectDetail?.superAdminId : this.state.userId,
            }
        })
    }

    componentDidMount() {
        this.getStudioPostData(this.state.studioPostPage, this.state.studioPostSize, false, true);
    }

    render() {
        
        return (
            <>
                {this.state.studioPostData && this.state.studioPostData.content && this.state.studioPostData.content.length > 0 && (
                    <InfiniteScroll
                        loadMore={this.fetchMoreStudioPostData.bind(this)}
                        hasMore={!this.state.studioPostSuccess && !this.state.studioPostData.last}
                        threshold={this.state.thresholdHeight}
                    >
                        {this.props.studioPageType === "SETTING" && <h4>Studio Dashboard</h4>}

                        <div className={this.props.studioPageType === "SETTING" ? "stdActionDtlsWrap" : "row"}>
                            {this.state.studioPostData && this.state.studioPostData.content && this.state.studioPostData.content.map((item, index) => {
                               return (
                                    <>
                                        {(this.props.studioPageType === "PROJECT" || this.props.studioPageType === "GLOBAL" || this.props.studioPageType === "SUBSCRIBED" || this.props.studioPageType === "MY_STUDIO" || this.props.studioPageType === "HELP_DESK" || this.props.studioPageType === "INTERESTED" ||  this.props.studioPageType === "HIDDEN_STUDIO") ? 
                                        <div className="col-md-4 paidProjColmn">
                                            <div className={"paidProjBlks" + (item.visibleToUser ? "" : " ppLock")}>
                                                <div style={{ position: 'relative' }}>
                                                    <a href="javascript:void(0)" onClick={() => this.redirecToStudioDetailsPage(item)}>
                                                        <figure className="figImg">
                                                            <img src={getImageDetails(item)}
                                                                alt="" />
                                                            <i className={item.contentType === "IMAGE" ? "iImg" : item.contentType === "PPT" ? "iPPT_New" : item.contentType === "PDF" ? "iPDF" : "iPlay"} />
                                                        </figure>
                                                    </a>
                                                    {(this.props.studioPageType === "GLOBAL" || item.publishedTime !== 0 || item.createTime !== 0) &&
                                                        <div className= {"postedBy" + (item.userType === "COMPANY" ? " orgnzName" : "")}>
                                                            {(this.props.studioPageType === "GLOBAL" || this.props.studioPageType === "HELP_DESK" || this.props.studioPageType === "HIDDEN_STUDIO") && <a href="javascript:void(0)" onClick={()=> redirectToProfile(item, this.state?.userId)} to={`/profile/${item.userCustomUrl}`}>Posted By <span>{item.userName}</span></a>}
                                                            <div>{publishedTimeSection(item.publishedTime !== 0 ? item.publishedTime : item.createTime, item.publishedTime !== 0 ? "PUBLISH" : "UPDATE")}</div>
                                                        </div>
                                                    }
                                                    {this.props.studioPageType === "INTERESTED" && <a href="javascript:void(0)" className="btn ppLockPublish" onClick={(e) => {this.openDeleteModal(item.id);}}>Ignore</a>}

                                                    {this.props.studioPageType === "PROJECT" && this.props.postInStudioPermission && 
                                                        (item.compeleted ? 
                                                            <a href="javascript:void(0)" className="btn ppLockPublish" onClick={(e) => item.thumbnail ? this.getPublishPopUp(item) : this.checkThumbnailPopUp(item)}>{(item.status !== "ENABLE" && item.publishedTime === 0) ? "Publish" : "Update"}</a> : 
                                                            <div className="paidProjEditDel">                                                                
                                                                <button type="button" className="btn delBtn" onClick={(e) => {this.openDeleteModal(item.id);}}><i class="iTrashBin"></i></button>
                                                                <Link to={{pathname: `/edit/post-studio/${item.slug}`, state: { userId: this.props.projectDetail?.partyType === "CIRCLE" ? this.props.projectDetail?.superAdminId : this.state.userId }}} className="btn editBtn">Edit</Link>
                                                            </div>
                                                        )
                                                    }
                                                    {this.props.studioPageType === "HIDDEN_STUDIO" &&  
                                                        <a href="javascript:void(0)" className="btn ppLockPublish" onClick={()=>{this.unHideStudio(item.id)}}>
                                                            Unhide
                                                        </a>  
                                                    }
                                                </div>
                                                <div>
                                                    <a href="javascript:void(0)" onClick={() => this.redirecToStudioDetailsPage(item)} className="paidProjBlksDesc">
                                                        <h4>{item.title}</h4>
                                                        <p>{item.shortBrief}</p>
                                                    </a>
                                                </div>
                                                <StudioViewClickSection studioPostData={item} studioId={item.id} pageName={"studio"} />
                                            </div>
                                        </div> : 
                                        <div className="stdActionDtlsRow">                                            
                                            <div className="stdActionDtlsPostInfo">
                                                <figure className='users'>
                                                    <a href="javascript:void(0)" onClick={() => this.redirecToStudioDetailsPage(item)}>
                                                    <img src={getImageDetails(item)}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                                        }} alt='' />
                                                    </a> 
                                                </figure>
                                                <h3><a href="javascript:void(0)" onClick={() => this.redirecToStudioDetailsPage(item)}>{item.title}</a></h3>
                                            </div>
                                            <div className="stdActionDtlsPostAct">
                                                <StudioViewClickSection studioPostData={item} studioId={item.id} pageName={"settings"} />
                                                <div className="stdActionAmBTN">                                                    
                                                    <span className="stdActionAmount">${item.amount}</span>
                                                    <a href="javascript:void(0)" className="btn paidUsBtn" onClick={(e) => this.getPaidUserListPopup(item.id)}>Manage</a>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </>)
                            })}
                        </div>
                        <ReactTooltip
                            className="rtoolTip"
                            place="bottom"
                            type="dark"
                            effect="solid"
                            textColor="#00394D"
                            backgroundColor="#ffffff"
                        />
                    </InfiniteScroll>
                )}

                {this.state.showPublishPopUp && <StudioPostPopUp popUpDetails={this.state.popUpDetails} triggerPublishPopUpClose={this.triggerPublishPopUpClose} triggerPublishPostPopUpClose={this.triggerPublishPostPopUpClose} userId={this.props.projectDetail?.partyType === "CIRCLE" ? this.props.projectDetail?.superAdminId : this.state.userId} projectDetail={this.props.projectDetail} />}

                {this.state.showPaidUserPopUp && <StudioPaidUserList triggerPaidUserPopUpClose={this.triggerPaidUserPopUpClose} studioItemId={this.state.studioItemId} entiyId={this.props.projectId} userId={this.state.userId} />}

                {this.state.openDeleteModal && <StudioDeleteModal triggerDeleteClose={this.triggerDeleteClose} entityId={this.state.studioItemId} studioItemListDataAfterDelete={this.studioItemListDataAfterDelete} studioPage={"LIST"} studioPageType={this.props.studioPageType} userId={this.state.userId} />}

                {this.state.responseMessage === "Success!" && this.state.studioPostData && this.state.studioPostData.content?.length === 0 && this.props.studioPageType !== "SETTING" && !this.props.searchData &&
                    <div className="col-md-12">
                        <div className="zdata">
                            <div className="imgbox">
                                <img src="https://cdn.dscovr.com/images/zroProjectStudio.webp"
                                    onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroProjectStudio.png"}} alt="" width="600" />
                            </div>
                            <div className="desc">
                                {(this.props.studioPageType === "MY_STUDIO") ?
                                    <p>You can create your own studio to monetise your content - videos, images or documents. <br />
                                        Run training, courses, tutorials, and more.</p>
                                : (this.props.studioPageType === "SUBSCRIBED") ?
                                    <>
                                        <p>Excited to start your own Studio?</p>
                                        <p><Link class="zerotab" to={`/project/post-studio/${this.state.userId}`}>Create Now</Link></p>
                                    </>
                                : (this.props.studioPageType === "GLOBAL") ?
                                    <>
                                        <p>No Studio post yet. Create your own studio and share premium content - courses, tutorials, events, etc. in any form <br />
                                            of media or documents. You may choose to make it a paid service.</p>
                                    </>
                                :
                                    <>
                                        <p>Excited to start your own Studio? Publish your content here. You can make it paid too.</p>
                                        {this.props.studioPageType === "PROJECT" && this.props.postInStudioPermission && <p><a class="zerotab" href="javascript:void(0)" onClick={(e) => redirecToStudiopage(this.props.studioDataConfig, this.props.projectId)}>Post Now</a></p>}
                                    </>
                                }
                                
                                
                            </div>
                        </div>
                    </div>
                }

                {this.state.responseMessage === "Success!" && this.state.studioPostData && this.state.studioPostData.content?.length === 0 && this.props.studioPageType === "GLOBAL" && this.props.searchData &&
                    <SearchZeroDataSection />
                }
                
            </>
        );
    }
}

export default withRouter(memo(StudioListData));
