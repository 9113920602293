import React from 'react'
import {authService} from "../auth";
import axios from "axios";
import VideoPostItem from "../post/VideoPostItem";
import httpService from "../AxiosInterceptors";
import InfiniteScroll from "react-infinite-scroller";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import $ from "jquery";
import LazyLoad from "react-lazyload";
import { userAgentGetData } from '../commons/CommonFunctions';

httpService.setupInterceptors();

class CauseVideoPostList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            postList: [],
            userData: authService.getUserDetailsFromLocalStorage(),
            responseMessage: '',
            postMaxSize: 5,
            thresholdHeight: 1000
        };
    }


    componentDidMount() {
        this.getVideoPosts(0, this.state.postMaxSize, false);
    }

    fetchMorePostData() {
        let postList = this.state.postList;
        if (this.state.postList) {
            this.setState({isResultLoading: true});
            let pageable = postList.pageable;
            this.getVideoPosts(pageable.pageNumber + 1, this.state.postMaxSize, true);
        }
    }


    getVideoPosts(page, size, concatWithPrevious) {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/post/cause/getNewsFeed?causeId=' + this.props.causeId + '&userId=' + this.props.userId + '&newsFeedType=VIDEOS' +"&privateEntityId="+this.state.userData.entityId+ "&page=" + page + "&size=" + size + userAgentGetData(),
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.status === 200) {
                let pagedData = response.data.body;
                if (concatWithPrevious === true && this.state.postList && this.state.postList.content) {
                    pagedData.content = this.state.postList.content.concat(pagedData.content);
                }
                this.setState({
                    'postList': pagedData,
                    'responseMessage': response.data.message,
                    'isResultLoading': false
                }, () => {
                    let postListHeight = $('.hpostlist').height();
                    if (postListHeight && postListHeight > 0) {
                        if (!this.state.initialThresholdHeight) {
                            this.setState({
                                'initialThresholdHeight': postListHeight,
                                'thresholdHeight': postListHeight * 0.6
                            });
                        }
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                isResultLoading: false
            });
        });
    }

    removePostListItem = (id) => {
        let postItemList = this.state.postList;
        const index = postItemList && postItemList.content.findIndex(data => data.id === id);
        if (index > -1) {
            postItemList.content.splice(index, 1); 
        }
        this.setState({
          postList: postItemList
        });
        if(postItemList?.content.length === 0) {
            this.getVideoPosts(!postItemList.last ? postItemList.pageable.pageNumber + 1 :  0, this.state.postMaxSize, false);
        }
        this.forceUpdate();
    }


    render() {
        return <section className="hpostlist">
            {(this.state.postList && this.state.postList.content && this.state.postList.content.length === 0
                && this.state.responseMessage === "Success!" && this.state.postList.empty === true) &&
            <div className="zdata zfeed">
                <div className="imgbox">
                    <img src="https://cdn.dscovr.com/images/zroProjectsActivity.webp"
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroProjectsActivity.png"}} alt=""/>
                </div>
                <div className="desc">
                    {this.props.joined ? 
                        <p><a href="#addpostpop" data-toggle="modal">Share</a> your thoughts</p> : 
                        <p>No Activity</p>}
                </div>
            </div>
            }
            {(this.state.postList && this.state.postList.content && this.state.postList.content.length > 0
                && this.state.responseMessage === "Success!" && this.state.postList.empty === false) &&
            <InfiniteScroll
                loadMore={this.fetchMorePostData.bind(this)}
                hasMore={!this.state.isResultLoading && !this.state.postList.last}
                threshold={this.state.thresholdHeight}
                loader={<section>
                    {Array(1)
                        .fill()
                        .map((item, index) => (
                            <SkeletonTheme>
                                <div className="hpostblock" key={index}>
                                    <figure className="userimg">
                                        <Skeleton circle={true} height={50} width={50}/>
                                    </figure>
                                    <div className="hpostblockinn">
                                        <div className="hposthead">
                                            <h3>
                                                <Skeleton height={36} width={`80%`}/>
                                            </h3>
                                            <div className="hpostdtls">
                                                <Skeleton height={36} width={`30%`}/>
                                            </div>
                                            <div className="hpostcontent">
                                                <div className="hpostconttop">
                                                    <p><Skeleton height={120} width={`100%`}/></p>
                                                </div>
                                            </div>
                                            <div className="card-metrics">
                                                <Skeleton width={`90%`}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </SkeletonTheme>
                        ))}
                </section>}>
                {
                    this.state.postList && this.state.postList.content && this.state.postList.content.map((item) => {
                        return <LazyLoad once><VideoPostItem enablePostSharing={true} item={item} key={item.id}
                                                             callbackFunc={this.getVideoPosts} removePostListItem={this.removePostListItem} /></LazyLoad>
                    })
                }
            </InfiniteScroll>
            }

        </section>
    }
}

export default CauseVideoPostList
