import React, { Component, useEffect, useState } from "react";
import { authService } from "../../../auth";
import { Link, NavLink } from "react-router-dom";

// Third party imports
import Linkify from "react-linkify";
import stripHtml from "string-strip-html";
import ReactHtmlParser from "react-html-parser";
import { renderToString } from "react-dom/server";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { detailsPageRedirection, get720pTranscodedVideo, getCDNUrl, videoThumbnailFunction } from "../../../commons/CommonFunctions";
import MutualConnect from "../../../commons/MutualConnect";

import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Follow from "../../../commons/Follow";
import AuthModalComponent from "../../../LoginExtra/AuthModalComponent";
import EntityFollow from "../../../commons/EntityFollow";
import {renderTaggedUsers, redirectToProfile, redirectToCircle, getFormattedDate, options} from './HashtagsCommonFunction';
import { defaultProjectImage } from "../../../project/component/Studio/StudioContentCommonFunction";
import SavePostButton from "../../../post/SavePostButton";
import FeaturePostButton from "../../../post/FeaturePostButton";
import CommonHashTagUserSection from "./CommonHashTagUserSection";

class CommonHashTagsVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
        }
    }


    getOptimizedImage = (personalInfo) => {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }

    render() {
        let linkifiedHtml =
            '<p class="pdtlsLink">' +
            renderTaggedUsers(renderToString(
                <Linkify
                    componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                    ) => (
                        <a
                            target="blank"
                            href={decoratedHref}
                            key={key}
                        >
                            {decoratedText}
                        </a>
                    )}
                >
                    {this.props.item.description
                        ? stripHtml(this.props.item.description)
                        : ""}
                </Linkify>
            )) +
            "</p>";
        linkifiedHtml = renderToString(
            ReactHtmlParser(linkifiedHtml, options)
        );
        return (
            <div className="postbookMlist">
                <div className="postbookMlistinn">
                    <div className="postbookMlisttop">
                        <div className="postbookMhead">
                            {!this.props.item.userEntityType ? (
                                <figure
                                    style={{ cursor: "pointer" }}
                                    className="complogo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToProfile(
                                            this.props.item.userCustomUrl
                                        );
                                    }}
                                >
                                    <img
                                        src={
                                            this.props.item.userProfileImage &&
                                                this.props.item.userProfileImage !== ""
                                                ? getCDNUrl(
                                                    this.getOptimizedImage(this.props.item)
                                                )
                                                : this.props.item.userType !==
                                                    "COMPANY"
                                                    ? "https://cdn.dscovr.com/images/users1.png"
                                                    : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                        }
                                        alt=""
                                    />
                                </figure>
                            ) : (
                                <figure
                                    style={{ cursor: "pointer" }}
                                    className="complogo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToCircle(
                                            this.props.item.params &&
                                            this.props.item.params["circleSlug"]
                                        );
                                    }}
                                >
                                    <img
                                        src={
                                            this.props.item.params &&
                                                this.props.item.params["circleImage"] !=
                                                null
                                                ? getCDNUrl(
                                                    this.props.item.params &&
                                                    this.props.item.params[
                                                    "circleImage"
                                                    ]
                                                )
                                                : "https://cdn.dscovr.com/images/circleDefault.webp"
                                        }
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                "https://cdn.dscovr.com/images/circleDefault.png";
                                        }}
                                        alt=""
                                    />
                                </figure>
                            )}
                            <div>

                                {/* {!this.props.item.userEntityType ? (
                                    <h5 className="compuser"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToProfile(
                                                this.props.item.userCustomUrl
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}>{this.props.item.userName}</h5>
                                ) : (
                                    <h5
                                        className="compuser"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToCircle(
                                                this.props.item.params &&
                                                this.props.item.params["circleSlug"]
                                            );
                                        }}
                                    >
                                        {this.props.item.params["circleTitle"]}
                                    </h5>
                                )} */}
                                 <CommonHashTagUserSection  item = {this.props.item} />
                                <div className="d-flex hpostdtls">
                                    {this.props.item && this.props.item.country &&
                                        <span className="address"><i
                                            className="iLocation" />{this.props.item && this.props.item.country}</span>}
                                    <span className="designation" style={{ cursor: 'pointer' }}>
                                        <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item.id)}>
                                            {getFormattedDate(
                                                this.props.item.createTime
                                            )}
                                        </a>
                                    </span>
                                    {this.state.loginSuccess === true ? ((this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId) ?
                                        <MutualConnect connectDepth={this.props.item.connectDepth}></MutualConnect> : null) : null}
                                    {this.state.loginSuccess === true && !this.props.item.userEntityType ? (this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === "PENDING_CONNECT" ?
                                        <>
                                            <NavLink className="follow follow-link active" to="/connected" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                        </>
                                        : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'REQUEST_RECEIVED' ?
                                            <>
                                                <NavLink className="follow follow-link active" to="/manage-invitation" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                            </>
                                            : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                                <></>

                                                : this.props.item && (!this.props.item.connectDepth || (this.props.item.connectDepth && this.props.item.connectDepth < 1)) &&
                                                <Follow followed={this.props.item.followed} userId={this.props.item.userId} deactivated={this.props.item.deactivated} />
                                    ) : /*((this.state.loginSuccess === true && this.props.item.userEntityType != null && this.props.item.userEntityType === "CIRCLE") ?
                                        <EntityFollow entityType={'CIRCLE'} entityId={this.props.item.params['circleId']}
                                            followed={this.props.item.followed}
                                            userId={this.state.userData.userId}
                                            isCircle={true} /> : null)*/ null
                                    }
                                </div>
                            </div>
                        </div>
                        {this.props.pageType === "BOOKMARK" && 
                            <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={true} entityType={"POST"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                        }
                        {this.props.pageType === "FEATURE_POST" && /* (this.props.item.userId === this.state.userData.userId) && */
                            <FeaturePostButton key={this.props.item.id} item={this.props.item} pageType={this.props.pageType} onSuccessFeaturePost={this.props.onSuccessFeaturePost} />
                        }
                    </div>
                    {this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0 &&
                        this.props.item.attachmentIds[0].attachmentUrl && (
                            <figure className="hpostimggrid">
                                <div className="himggrid vidbox">
                                    {/*<span className="tagtitle">Video No1 Title</span>*/}
                                    <a
                                        data-fancybox={this.props.item.id}
                                        href={getCDNUrl(
                                            get720pTranscodedVideo(
                                                this.props.item.attachmentIds[0]
                                            )
                                        )}
                                    >
                                    {((this.props.item?.thumbnail) || (this.props.item.attachmentIds[0].thumbnails &&  this.props.item.attachmentIds[0].thumbnails[0])) ?
                                    <LazyLoadImage
                                            effect="blur"
                                            src={getCDNUrl(videoThumbnailFunction(this.props.item))}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                    "https://cdn.dscovr.com/images/video-blank.png";
                                            }}
                                            alt=""
                                    /> : <LazyLoadImage
                                            effect="blur"
                                            src="https://cdn.dscovr.com/images/video-blank.webp"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                    "https://cdn.dscovr.com/images/video-blank.png";
                                            }}
                                            alt=""
                                        />
                                    }
                                    <span className="playbutt"><i className="iPlay" /></span>
                                    </a>
                                </div>
                            </figure>
                        )}
                    {this.props.item.attachmentIds && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost &&
                        <figure className="hpostimggrid ppLock">
                            <div className="himggrid vidbox">
                                <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item.id)}>
                                    <img src={getCDNUrl(defaultProjectImage.attachmentUrl)} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/video-blank2.png"}} alt=""/>
                                    <span className="playbutt" onContextMenu="return false;"><i className="iPlay" /></span>
                                </a>
                            </div>
                        </figure>
                    }
                    <div className="postbookMconttop">
                        {this.props.item.description && this.props.item.description !== "" && (
                            <Link to={"/post/" + this.props.item.id}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: linkifiedHtml,
                                }}
                            />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CommonHashTagsVideo;