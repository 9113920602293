import React from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

import "react-quill/dist/quill.snow.css";
import "./commons/styles/quill.css";
import { authService } from "./auth";
import $ from "jquery";
import axios from "axios";
import { requestPostCreationFulfilled } from "./reducer/post";
import { connect } from "react-redux";
import * as _ from "lodash";
import stripHtml from "string-strip-html";
import Loader from "react-loader-spinner";
import httpService from "./AxiosInterceptors";
import ImageCompress from "quill-image-compress";
import {
  convertUnicode,
  doubleHashTagsFunc,
  doubleUserTagsFunc,
  getCDNUrl,
  getOptimizedImageQuill,
  isMobileDevice,
  pointCalculationUpdate,
  textToSpeech,
  trimDescription,
  userAgentGetData,
} from "./commons/CommonFunctions";
import mappingUtils from "./project/component/commons/utils/mappingUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Scrollbars } from "react-custom-scrollbars";
import HorizontalScroll from "react-scroll-horizontal";
import AutoSuggest from "./AutoSuggest";
import QuillMention from "quill-mention";
import { CapitalizeFirstLetter } from "./utils/StringUtility";
import MagicUrl from "quill-magic-url";
import CommonPostAsPopUp from "./commons/CommonPostAsPopUp";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";

if (isMobileDevice()) {
  import("./commons/styles/responsive-useragent.css").then(() => {
    console.log("Imported mobile css");
  });
}

let Link = Quill.import("formats/link");

let moduleToolBarFlag = true

httpService.setupInterceptors();

class CustomLink extends Link {
  static PROTOCOL_WHITELIST = ["http://", "https://"];

  static sanitize(url) {
    if (url) {
      for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++)
        if (url.startsWith(CustomLink.PROTOCOL_WHITELIST[i])) return url;
      return "http://" + url;
    }
    return url;
  }
}

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "script",
  "align",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

Quill.register(CustomLink);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/mentions", QuillMention);
Quill.register("modules/magicUrl", MagicUrl);

const maxMbFileSize = 15 * 1024 * 1024; // 15MB

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

let hashTagModule;
let atValue = [
  {
    id: "imranNew",
    display: "imranNew",
    value: "imranNew",
    link: `/profile/${"imranNew"}`,
    secondaryHeader: "",
    avatar: "https://cdn.dscovr.com/images/users1.png",
  },
];
let atHash = [
  {
    id: 1,
    value: "images",
    link: `/explore-hashtag/${"test"}`,
  },
];

let selectedHashtagsList = [];
let selectedUserTagList = [];
class BlogPopup extends React.Component {
  constructor(props) {
    super(props);

    this.Quillref = React.createRef();
    this.interval = null;
    let validation = {
      postText: {},
      titleText: {},
      hashTags: {},
    };
    let userData = authService.getUserDetailsFromLocalStorage();
    this.state = {
      userData: userData,
      postText: "",
      titleText: "",
      hashTags: [],
      errors: {},
      validation,
      postPrivacy: "ANYONE",
      previewModalOn: false,
      isPostSubmitted: false,
      userList: [],
      hiddenPrivacy: false,
      userId: "",
      postId: "",
      searchUserByChars: "",
      selectedList: [],
      visibleToUserIds: [],
      draftId: "",
      isModalLoading: false,
      isPreviewUsername: false,
      forcePost: false,
      entityName: userData ? userData.type === "COMPANY" ? userData.companyName : CapitalizeFirstLetter(userData.firstName) : "",
      entityType: userData.type,
      secondaryEntityName: "Feeds",
      circleAdminList: [],
      businessPageList: [],
      coverImageNew: userData && userData.profileImage ? getCDNUrl(this.getOptimizedImage(userData)) : userData.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png",
      userName: userData ? userData.type === "COMPANY" ? userData.companyName : CapitalizeFirstLetter(userData.firstName) : "",
      sucessMessage: "",
      tagSuggestions: {},
      privacyData: {},
      postAsFlag: false,
      entityData: {},
      isShowEditOption: false,
      intervalId: null,
      remove_id: uuid()
    };
  }

  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ["@", "#"],
    source: function (searchTerm, renderList, mentionChar) {
      let values;
      let tagSuggestionArray = [];
      let userSuggestionArray = [];
      let postBody = {
        description: searchTerm,
        "privateEntityId": authService.getUserDetailsFromLocalStorage()?.entityId
      };
      if (mentionChar === "#") {
        hashTagModule = true;
        axios({
          method: "post",
          url: process.env.REACT_APP_userServiceURL + "/tags/recommended",
          headers: { "Content-Type": "application/json" },
          data: postBody,
          withCredentials: true,
        })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.message === "Success!"
            ) {
              let hashTagsData = [];
              if (selectedHashtagsList && response.data.body) {
                hashTagsData = (response.data.body).filter((tag) => !(selectedHashtagsList).includes(tag));
              } else {
                hashTagsData = response.data.body;
              }
              hashTagsData && hashTagsData.map((tag, index) => {
                tagSuggestionArray.push({
                  id: index,
                  value: "# " + tag,
                  link: `/explore-hashtag/${tag}`,
                });
              });
              values = tagSuggestionArray;
              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                    ~values[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  )

                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (mentionChar === "@") {
        hashTagModule = false;
        if (
          authService.getUserDetailsFromLocalStorage() &&
          authService.getUserDetailsFromLocalStorage().userId
        ) {
          axios({
            method: "get",
            url:
              process.env.REACT_APP_userServiceURL +
              "/search/user/suggestions/" +
              authService.getUserDetailsFromLocalStorage().userId +
              "?query=" +
              searchTerm + "&privateEntityId=" + this.state.userData?.entityId,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }).then((response) => {
            let usersDataList = [];
            if (selectedUserTagList && response.data.body) {
              usersDataList = (response.data.body).filter((user) => !(selectedUserTagList).includes(user.customUrl));
            } else {
              usersDataList = response.data.body;
            }
            usersDataList && usersDataList.map((user, index) => {
              userSuggestionArray.push({
                id: user.customUrl,
                display: user.username,
                value: user.username,
                link: `/profile/${user.customUrl}`,
                secondaryHeader:
                  user.addressDetail && user.addressDetail.country
                    ? user.addressDetail.country +
                    (user.personalInfo && user.personalInfo.persona
                      ? ", " + user.personalInfo.persona
                      : "")
                    : user.personalInfo && user.personalInfo.persona
                      ? user.personalInfo.persona
                      : "",
                avatar:
                  user.personalInfo && user.personalInfo.profileImage
                    ? getCDNUrl(getOptimizedImageQuill(user.personalInfo))
                    : user.type !== "COMPANY"
                      ? "https://cdn.dscovr.com/images/users1.png"
                      : "https://cdn.dscovr.com/images/DefaultBusiness.png",
                type: user.type
              });
              values = userSuggestionArray;
              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                    ~values[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            });
          });
        }
      }
    },
    renderItem(item) {
      return !hashTagModule
        ? `
        <div class="mentionSuggestionsEntryContainer">
          <div class="mentionSuggestionsEntryContainerLeft">
            <img
              src=${getCDNUrl(item.avatar)}
              class="mentionSuggestionsEntryAvatar"
            />
          </div>
          <div class="mentionSuggestionsEntryContainerRight">
            <div  className= {"mentionSuggestionsEntryText" + ${(item.type === "COMPANY" ? " orgnzName" : "")}}>
              ${item.display}
            </div>
            <div class="mentionSuggestionsEntryTitle">
              ${item.secondaryHeader}
            </div>
          </div>
        </div>
      `
        : `
      <span>${item.value}</span>
    `;
    },
  };

  modules = {
    toolbar: [
      [{ header: "2" }, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      moduleToolBarFlag ? ["link", "image", "video"] : ["link", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      handleStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    imageCompress: {
      quality: 0.7, // default
      //maxWidth: 750, // default
      //maxHeight: 500, // default
      imageType: "image/jpeg" + "image/png", // default
    },
    mention: this.mentionModule,
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
  };

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  getDataList = (entityId, type) => {
    let url = "";
    if (type === "CAUSE") {
      url = `backend/public/cause/joined/list?userId=${entityId}&page=0&size=1000`;
    } else if (type === "PROJECT") {
      url = `backend/getProjects/${entityId}?excludeJobs=true&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`;
    } else {
      url = `circle/get/list-for-post/${entityId}?privateEntityId=${this.state.userData.entityId}`;
    }
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/${url}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response && response.data && response.data.body && response.status === 200) {
        let dataList = type !== "CAUSE" ? response.data.body : response.data.body?.content;
        let data = dataList && dataList.find((data) => data.slug === (type === "CAUSE" ? this.props.match.params?.id : type === "CIRCLE" ? this.props.match.params?.slug : (this.props.match.params?.projectId || this.props.match.params?.slug)));
        if (!_.isEmpty(data)) {
          this.setState({
            entityId: data.id,
            secondaryEntityName: type === "CAUSE" ? data.name : data.title
          })
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getEntityCount = (userId) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/userRelated/count?userId=${userId}&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response && response.data && response.data.message === "Success!") {
        this.setState({
          isShowEditOption: response.data.body > 0 ? true : false
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  componentDidMount() {
    this.getEntityCount(this.state.userData.userId);
    if (this.props.match?.path === "/cause/:id") {
      this.getDataList(this.state.userData.userId, "CAUSE");
    }
    if (this.props.match?.path === "/project/:type/:projectId" || this.props.match?.path === "/project/studio/:slug") {
      this.getDataList(this.state.userData.userId, "PROJECT");
    }
    if (this.props.match?.path === "/community/:slug" || this.props.match?.path === "/community/activity/:slug" || this.props.match?.path === "/community/all-activity/:slug" ||
      this.props.match?.path === "/community/projects/:slug" || this.props.match?.path === "/community/members/:slug" || this.props.match?.path === "/community/all-members/:slug" ||
      this.props.match?.path === "/community/stats/:slug" || this.props.match?.path === "/community/award/:slug") {
      this.getDataList(this.state.userData.userId, "CIRCLE");
    }
    window.$("input#colon").on({
      // e.which >= 32 && e.which <= 47 && e.which >= 58 && e.which <= 64 && e.which >= 91 && e.which <= 95
      keydown: function (e) {
        if (e.key === "#") return false;
      },
      change: function () {
        this.value = this.value.replace(/\s/g, "");
      },
    });
    window.$(".dropdown-submenu > a").on("click", function (e) {
      var submenu = $(this);
      $(".dropdown-submenu .dropdown-menu").removeClass("show");
      submenu.next(".dropdown-menu").addClass("show");
      e.stopPropagation();
    });

    this.Quillref.current.editor.root.setAttribute("spellcheck", "false");
    this.getPrivacyData(this.state.userData.userId);
    // create suggested hashtag
    this.triggerApiCall();
  }

  componentDidUpdate(prevProps, prevState) {
    var regex = /\s+/gi;
    if (this.state.postText !== prevState.postText) {
      if (!this.state.intervalId && stripHtml(this.state.postText).trim().replace(regex, " ").split(" ")
        .length > 49) {
        const intervalId = setInterval(() => {
          // make API call
          this.triggerApiCall();
        }, 5000);
        this.setState({ intervalId });
      }else if(stripHtml(this.state.postText).trim().replace(regex, " ").split(" ")
        .length === 1){
          clearInterval(this.state.intervalId);
          this.setState({
            intervalId: null,
          })
        }
    }
  }

  triggerApiCall() {
    // make API call here
    let data = {
      "post": this.state.postText,
      "postId": this.state.remove_id
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_userServiceURL + "/recommend/runtimehashtag/",
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      data: data
    }).then((res) => {
      if (res?.data?.status === 200) {
        let allHashTagsList = res?.data?.data;
        let hashTagsList = this.state.hashTags.concat([...new Set(allHashTagsList)]);
        this.setState({ hashTags: [...new Set(hashTagsList)] });
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  triggerDeleteApiCall = (tag) => {
    // make API call here
    let data = {
      "tag": tag.join(""),
      "postId": this.state.remove_id
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_userServiceURL + "/recommend/runtimehashtag/",
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      data: data
    }).then((res) => {
      if (res?.data?.status === 200) {
        let allHashTagsList = res?.data?.data;
        let hashTagsList = this.state.hashTags.concat([...new Set(allHashTagsList)]);
        this.setState({ hashTags: [...new Set(hashTagsList)] });
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }


  handleTextChange = (content, delta, source, editor) => {
    let imageTagLength = content.match(/<img/gim);
    let descriptionContent = content.replace(/#\s/g, "#");
    if (imageTagLength != null && imageTagLength.length >= 5) {
      window.$(".ql-image").css("display", "none");
    } else {
      window.$(".ql-image").css("display", "inline-block");
    }
    this.setState({ postText: descriptionContent }, () => {
      if (this.state.postText && this.state.postText.length > 0) {
        this.checkDraftInterval();
      }
    });
    selectedHashtagsList = doubleHashTagsFunc(content);
    selectedUserTagList = doubleUserTagsFunc(content);
  };


  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  handleTagsChange = (hashTags) => {
    let hashTagsList = [];
    let oldHashTag = this.state.hashTags;
    if (hashTags && hashTags.length > 0) {
      hashTags &&
        hashTags.map(function (data) {
          data = data.replace(
            /[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi,
            ""
          );
          if (data != "") {
            hashTagsList.push(data);
          }
        });
      let allHashTagsList = hashTagsList;
      hashTagsList = [...new Set(allHashTagsList)];
    }
    const removeData = oldHashTag.filter((item) => !hashTagsList.includes(item));
    this.triggerDeleteApiCall(removeData);
    this.setState({ hashTags: hashTagsList }, () => {
      if (this.state.hashTags && this.state.hashTags.length > 0) {
        this.checkDraftInterval();
      }
    });
  };

  handleTitleChange = (event) => {
    this.setState({ titleText: event.target.value }, () => {
      if (this.state.titleText && this.state.titleText.length > 0) {
        this.checkDraftInterval();
      }
    });
  };

  changePostPrivacy = (event) => {
    this.setState({ postPrivacy: event.target.value });
  };

  coverImageUpload = () => {
    this.refs.blogCoverImageFileUploader.click();
  };

  uploadCoverImageFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let fileSize = e.target.files[0].size;
      if (fileSize > maxMbFileSize) {
        this.setState({ error: "Uploaded file exceeds limit of 15 MB" });
        setTimeout(() => {
          this.setState({
            error: "",
            errors: {},
          });
        }, 5000);
      } else {
        this.setState({
          coverImagePreview: URL.createObjectURL(e.target.files[0]),
          coverImage: e.target.files[0],
        });
      }
    }
  };

  showPreviewModal = () => {
    this.setState({ previewModalOn: true });
  };

  togglePreviewModal = () => {
    this.setState({
      previewModalOn: !this.state.previewModalOn,
    });
  };

  componentWillUnmount() {
    this.hideModal(true);
    window.$("#blogSelectUserModal").modal("hide");
    window.$("#blogpostpreviewwrap").modal("hide");
    clearInterval(this.state.intervalId)
  }

  hideModal = (isModalOpen) => {
    clearInterval(this.interval);
    this.interval = null;
    this.setState({
      postText: "",
      isPostSubmitted: false,
      titleText: "",
      hashTags: [],
      error: null,
      coverImagePreview: null,
      coverImage: null,
      postPrivacy: "ANYONE",
      previewModalOn: false,
      isModalLoading: false,
      entityId: null,
      hiddenPrivacy: false,
      userId: null,
      isPreviewUsername: false,
      draftId: "",
      entityName: this.state.userName,
      sucessMessage: "",
      entityType: "Individual",
      secondaryEntityName: "Feeds",
      coverImageNew: this.state.userData && this.state.userData.profileImage ? getCDNUrl(this.getOptimizedImage(this.state.userData)) : this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png",
    });
    this.clearUsers();
    this.props.openModalClose();
    if (!isModalOpen) {
      this.props.acceptPopUpModalDetail(this.state.postId);
    }
    //window.$("#postblog").modal("hide");
    window.$("#cancelPopup2").modal("hide");
    this.refs.blogCoverImageFileUploader.value = "";
    if (this.refs.TagsRef) {
      this.refs.TagsRef.clearInput();
    }
  };

  confirmModal = () => {
    window.$("#cancelPopup2").modal("show");
    window.$("#postblog").modal("hide");
  };

  ignoreModal = () => {
    window.$("#cancelPopup2").modal("hide");
    window.$("#postblog").modal("show");
  };

  validateForm = () => {
    this.state.validation.postText = {};
    this.state.validation.titleText = {};
    this.state.validation.designation = {};
    let errors = {};
    let formIsValid = true;
    if (
      _.isUndefined(this.state.postText) ||
      _.isEmpty((this.state.postText || "").toString()) ||
      _.isNull(this.state.postText)
    ) {
      errors["postText"] = "Please enter the description";
      this.state.validation.postText = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      formIsValid = false;
    } else {
      var regex = /\s+/gi;
      this.state.validation.postText = {};
      if (
        stripHtml(this.state.postText).trim().replace(regex, " ").split(" ")
          .length < 250
      ) {
        errors["postText"] =
          "A article must have minimum 250 words. Use Post as to share smaller content.";
        this.state.validation.postText = {
          error: true,
          message:
            "A article must have minimum 250 words. Use Post as to share smaller content.",
          style: { borderColor: "#b34c4c", padding: "10px" },
        };
        formIsValid = false;
      }
    }
    if (
      _.isUndefined(this.state.titleText) ||
      _.isEmpty((this.state.titleText || "").toString()) ||
      _.isNull(this.state.titleText) ||
      this.state.titleText.trim() === ""
    ) {
      errors["titleText"] = "Please enter the title";
      this.state.validation.titleText = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      formIsValid = false;
    }
    if (
      this.state.postPrivacy == "SELECTED" &&
      this.state.selectedList.length == 0
    ) {
      errors["privacyText"] = "Please Select at least one user";
      formIsValid = false;
    }

    if (!this.state.hashTags || this.state.hashTags.length < 1) {
      errors["hashTags"] = "Please enter atleast one hashtag ";
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateDraftForm = () => {
    this.state.validation.titleText = {};
    let errors = {};
    let formIsValid = true;
    if (
      _.isUndefined(this.state.titleText) ||
      _.isEmpty((this.state.titleText || "").toString()) ||
      _.isNull(this.state.titleText)
    ) {
      errors["titleText"] = "Please enter the title";
      this.state.validation.titleText = {
        error: true,
        style: { borderColor: "#b34c4c" },
      };
      formIsValid = false;
    }
    if (
      this.state.postPrivacy == "SELECTED" &&
      this.state.selectedList.length == 0
    ) {
      errors["privacyText"] = "Please Select at least one user";
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  getConnects(page, size) {
    return new Promise((resolve) => {
      axios({
        method: "get",
        url:
          process.env.REACT_APP_userServiceURL +
          "/entity/network/" +
          this.state.userData.userId +'/'+this.state.userData.entityId +
          "/connects" +
          "?page=" +
          page +
          "&size=" +
          size + userAgentGetData(),
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            resolve(response.data.body.content);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  addUserToGroup(evt, data) {
    const selectedList = this.state.selectedList;
    selectedList.push(data);
    let array = [...this.state.userList];
    let index = array.indexOf(data);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ userList: array });
    }
    this.setState({ selectedList: selectedList });
    evt.preventDefault();
  }

  removeUserToGroup(evt, data) {
    const userList = this.state.userList;
    userList.push(data);
    let array = [...this.state.selectedList];
    let index = array.indexOf(data);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedList: array });
    }
    this.setState({ userList: userList });
    evt.preventDefault();
  }

  handleSelectedOnly = () => {
    this.setState({ isModalLoading: true });
    this.getConnects(0, 1000)
      .then((userList) => {
        let selectedList = [];
        if (
          this.state.visibleToUserIds &&
          this.state.visibleToUserIds.length > 0
        ) {
          selectedList = userList.filter(({ id }) =>
            this.state.visibleToUserIds.includes(id)
          );
          selectedList.forEach((data) => {
            let index = userList.indexOf(data);
            if (index !== -1) {
              userList.splice(index, 1);
            }
          });
        }
        this.setState(
          {
            userList: userList,
            selectedList: selectedList,
            isModalLoading: false,
          },
          () => { }
        );
      })
      .catch((err) => console.log(err));
    window.$("#blogSelectUserModal").modal("show");
  };

  keyPressedBySearchGroupByChars(event) {
    this.setState({ searchUserByChars: event.target.value });
  }

  clearUsers() {
    document.getElementById("searchGroupByChars").value = "";
    this.setState({
      selectedList: [],
      userList: [],
      searchUserByChars: "",
      visibleToUserIds: [],
    });
  }

  handleUsersSelectSubmit = (event) => {
    event.preventDefault();
    let userIds = [];
    for (let data of this.state.selectedList) {
      userIds.push(data.id);
    }

    document.getElementById("searchGroupByChars").value = "";
    this.setState({ visibleToUserIds: userIds, searchUserByChars: "" });

    window.$("#blogSelectUserModal").modal("hide");
  };

  handleCheckedPrivacy = () => {
    if (this.state.visibleToUserIds && this.state.visibleToUserIds.length > 0) {
      this.setState({ visibleToUserIds: [] });
    }
  };

  checkDraftInterval = () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.handleAutoSave();
      }, 60000);
    }
  };

  handleAutoSave = () => {
    if (this.state.titleText && this.state.titleText.length > 0) {
      const formData = new FormData();
      let params = {
        entityId: this.state.entityId ? this.state.entityId : null,
        description: this.state.postText,
        articleTitle: this.state.titleText,
        postType: "ARTICLE",
        hashTags: this.state.hashTags
          ? this.state.hashTags.map((item) => {
            return item.replace(/#/g, "");
          })
          : [],
        postVisibility: this.state.postPrivacy,
        visibleToUserIds: this.state.visibleToUserIds,
      };
      if (this.state.coverImage) {
        formData.append("coverImage", this.state.coverImage);
        params.attachmentType = "COVER_IMAGE";
      }
      let body = {
        id: this.state.draftId ? this.state.draftId : null,
        parameterDetails: params,
        type: "ARTICLE",
        userId: this.state.userData.userId,
        privateEntityId: this.state.userData.entityId
      };
      formData.append("data", JSON.stringify(body));
      axios({
        method: "post",
        url:
          process.env.REACT_APP_userServiceURL +
          "/backend/drafts/create/article",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            this.setState({
              draftId:
                response &&
                response.data &&
                response.data.body &&
                response.data.body.id,
            });
          } else {
            console.log(response);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            // this.setState({
            //   error: { message: err.response.data.message, err: err.response },
            // });
          }
        });
      setTimeout(() => {
        this.setState({
          error: "",
          errors: {},
        });
      }, 5000);
    }
  };

  handleDraftSubmit = () => {
    if (this.validateDraftForm()) {
      const formData = new FormData();
      this.setState({ isPostSubmitted: true });

      let params = {
        entityId: this.state.entityId ? this.state.entityId : null,
        description: this.state.postText,
        articleTitle: this.state.titleText,
        postType: "ARTICLE",
        hashTags: this.state.hashTags
          ? this.state.hashTags.map((item) => {
            return item.replace(/#/g, "");
          })
          : [],
        postVisibility: this.state.postPrivacy,
        visibleToUserIds: this.state.visibleToUserIds,
      };
      if (this.state.coverImage) {
        formData.append("coverImage", this.state.coverImage);
        params.attachmentType = "COVER_IMAGE";
      }
      let body = {
        parameterDetails: params,
        type: "ARTICLE",
        userId: this.state.userData.userId,
        id: this.state.draftId ? this.state.draftId : null,
        privateEntityId: this.state.userData.entityId
      };

      formData.append("data", JSON.stringify(body));

      axios({
        method: "post",
        url:
          process.env.REACT_APP_userServiceURL +
          "/backend/drafts/create/article",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            this.hideModal(true);
            this.setState({
              sucessMessage: "Message Sucessfully Saved in Article!",
            });
          } else {
            this.setState({ isPostSubmitted: false });
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            this.setState({
              isPostSubmitted: false,
              isLoaded: true,
              //error: { message: err.response.data.message, err: err.response },
            });
          }
        });
      setTimeout(() => {
        this.setState({
          error: "",
          errors: {},
          sucessMessage: "",
        });
      }, 5000);
    }
  };

  handlePostSubmit = () => {
    if (this.validateForm()) {
      const formData = new FormData();
      this.setState({ isPostSubmitted: true });
      let errors = {};
      let params = {
        creatorId: this.state.userData.userId,
        userId: this.state.userId ? this.state.userId : this.state.userData.userId,
        entityId: this.state.entityId ? this.state.entityId : null,
        description: this.state.postText,
        articleTitle: this.state.titleText,
        postType: "ARTICLE",
        hashTags: this.state.hashTags
          ? this.state.hashTags.map((item) => {
            return item.replace(/#/g, "");
          })
          : [],
        postVisibility: this.state.postPrivacy,
        visibleToUserIds: this.state.visibleToUserIds,
        privateEntityId : this.state.userData.entityId,
        forcePost: this.state.forcePost,
        privacyScope: this.state.privacyData
          ? this.state.privacyData.scope
          : null,
        userIds: this.state.privacyData ? this.state.privacyData.userIds : [],
      };
      if (this.state.draftId) {
        params.draftId = this.state.draftId;
      }

      if (this.state.coverImage) {
        formData.append("files", this.state.coverImage);
        params.attachmentType = "COVER_IMAGE";
      }
      formData.append("data", JSON.stringify(params));
      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/backend/post/create",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            this.setState({
              forcePost: false,
              postId: response.data.body,
            }, () => {
              textToSpeech(this.state.postId, this.state.postText);
            });
            pointCalculationUpdate(this.state.userData.userId);
            this.props.requestPostCreationFulfilled({ success: true });
            this.hideModal();
          } else {
            this.setState({ isPostSubmitted: false });
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.setState({
              isPostSubmitted: false,
              forcePost: true,
            });
            errors["connectionText"] =
              "No Connects Yet Post will be visible only to you Want to Proceed?";
          } else {
            if (err && err.response && err.response.data) {
              this.setState({
                isPostSubmitted: false,
                isLoaded: true,
                // error: {
                //   message: err.response.data.message,
                //   err: err.response,
                // },
              });
            }
          }
          this.setState({
            errors: errors,
          });
        });
    }
    setTimeout(() => {
      this.setState({
        error: "",
        errors: {},
      });
    }, 5000);
  };

  getPrivacyData = (userId) => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/privacy/getscope?userId=" +
        userId,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.status === 200) {
          if (response.data.body) {
            this.setState({
              privacyData: response.data.body,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showUser = () => {
    window.location.href = "/post/" + this.state.postId;
  };

  onClickEditPostAs = () => {
    this.setState({
      postAsFlag: true
    }, () => {
      window.$("#postFilterModal").modal("show");
    })
  }

  onCloseEditPostAs = () => {
    window.$("#postFilterModal").modal("show");
    this.setState({
      postAsFlag: false
    })
  }

  onSelectPostAsData = (data) => {
    this.setState({
      entityId: data.entityId,
      entityName: data.entityName,
      coverImageNew: data.coverImage,
      secondaryEntityName: data.secondaryEntityName,
      userId: data.userId,
      hiddenPrivacy: data.hiddenPrivacy,
      entityType: data.entityType,
      entityData: data
    })
    this.onCloseEditPostAs();
  }

  render() {
    var regex = /\s+/gi;
    let Stripbody = stripHtml(this.state.postText)
      .trim()
      .replace(regex, " ")
      .split(" ").length;
    return (
      <>
        <div
          id="postblog"
          className="modal fade dgpopup"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="blogpostwrap">
                  {(this.state.postText && this.state.postText !== "") ||
                    (this.state.titleText && this.state.titleText !== "") ||
                    (this.state.hashTags && this.state.hashTags.length > 0) ||
                    (this.state.coverImagePreview &&
                      this.state.coverImagePreview !== null) ? (
                    <button
                      className="close topCrossModal"
                      onClick={this.confirmModal}
                      aria-label="Close"
                    >
                      <i className="iCross" />
                    </button>
                  ) : (
                    <button
                      className="close topCrossModal"
                      onClick={() => this.hideModal(true)}
                      aria-label="Close"
                    >
                      <i className="iCross" />
                    </button>
                  )}

                  <figure className="userimg">
                    <img src={this.state.coverImageNew} alt="" />
                  </figure>
                  <div className="toppostinn">
                    <div className="topposthead">
                      <div className="dropdown">
                        You are posting as{" "}
                        <span className="valueBox">{this.state.entityType?.toLowerCase() === "company" ? this.state.entityName : convertUnicode(this.state.entityName)}</span>
                      </div>

                      <div className="dropdown last">
                        in{" "}
                        <span className="valueBox" style={{ cursor: 'text' }}>{convertUnicode(this.state.secondaryEntityName)}</span>
                        {this.state.isShowEditOption &&
                          <button type="button" className="editbutt butt" onClick={this.onClickEditPostAs}>
                            <i className="iEditBox" />
                          </button>
                        }
                        <a className="bkqstn" href="/post-help" target="_blank" title="Help">
                          <i className="iQuestion" />
                        </a>
                      </div>
                    </div>
                    <div className="blogpostbody">
                      <input
                        type="file"
                        accept="image/*"
                        id="blogImageUploader"
                        onChange={this.uploadCoverImageFile}
                        ref="blogCoverImageFileUploader"
                        style={{ display: "none" }}
                      />

                      <div className="blogcoverimg">
                        {this.state.coverImagePreview ? (
                          <img
                            src={this.state.coverImagePreview}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                            onClick={this.coverImageUpload}
                          />
                        ) : (
                          <div
                            onClick={this.coverImageUpload}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="iUploadPhoto" />
                            <span>Add Cover Image</span>
                            <p>1200px by 450px recommended</p>
                          </div>
                        )}
                      </div>
                      <div className="blogpostcontwrap">
                        <input
                          type="text"
                          value={this.state.titleText}
                          onChange={this.handleTitleChange}
                          placeholder={"Add Title"}
                        />
                        <span
                          className="login-feedback-error mt-1"
                          style={{ display: "block" }}
                        >
                          {this.state.errors.titleText}
                        </span>

                        <div className="reactQuillWrap">
                          <ReactQuill
                            value={this.state.postText}
                            onChange={this.handleTextChange}
                            modules={this.modules}
                            ref={this.Quillref}
                            //formats={formats}
                            placeholder="Write something here..."
                          />
                          <div className="blogMedinfo">
                            <a
                              href="/media-info"
                              title="Media Info"
                              className="btninfo"
                              target="_blank"
                            >
                              <i className="iInfo_I" />
                            </a>
                          </div>
                        </div>
                        <span
                          className="login-feedback-error mt-1"
                          style={{ display: "block" }}
                        >
                          {this.state.errors.postText}
                        </span>
                        <div className="tagwrap">
                          <div className="tagfield">
                            <AutoSuggest
                              hashTags={this.state.hashTags}
                              onDelete={this.onDelete}
                              onAddition={this.onAddition}
                              handleTagsChange={this.handleTagsChange}
                            />
                            {/*<TagsInput value={this.state.hashTags}
                                                                   inputProps={{
                                                                       id: 'colon',
                                                                       placeholder: 'Add Hashtags'
                                                                   }}
                                                                   ref={'TagsRef'}
                                                                   onChange={this.handleTagsChange}
                                                                   addKeys={[9, 13, 32, 62, 188]}
                                                        />*/}
                            {/* <span className="khastginfo">Press enter after each # tag</span> */}
                            <span
                              className="login-feedback-error mt-1"
                              style={{ display: "block" }}
                            >
                              {this.state.errors.hashTags}
                            </span>
                          </div>
                        </div>
                        {this.state.error && this.state.error !== "" && (
                          <span className="login-feedback-error">
                            {this.state.error}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="bottpostpopbody">
                      <div>
                        {this.state.userData &&
                          this.state.userData.type !== "COMPANY" &&
                          this.state.hiddenPrivacy === false && (
                            <div className="contxmenu">
                              <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="iPrivacyLock" />
                                {this.state.postPrivacy === "ANYONE"
                                  ? "Anyone"
                                  : this.state.postPrivacy === "CONNECTED"
                                    ? "Connected Only"
                                    : "Selected Only"}
                              </button>
                              <div
                                className="dropdown-menu fadeIn animated"
                                onChange={this.changePostPrivacy}
                              >
                                <label className="radio-container">
                                  Anyone
                                  <input
                                    type="radio"
                                    value="ANYONE"
                                    onClick={this.handleCheckedPrivacy}
                                    checked={
                                      this.state.postPrivacy === "ANYONE"
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                                <label className="radio-container">
                                  Connected Only
                                  <input
                                    type="radio"
                                    value="CONNECTED"
                                    onClick={this.handleCheckedPrivacy}
                                    checked={
                                      this.state.postPrivacy === "CONNECTED"
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                                <label className="radio-container">
                                  Selected Only
                                  <input
                                    type="radio"
                                    value="SELECTED"
                                    onClick={this.handleSelectedOnly}
                                    checked={
                                      this.state.postPrivacy === "SELECTED"
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                              </div>
                            </div>
                          )}

                        {this.state.sucessMessage !== "" &&
                          this.state.sucessMessage !== null &&
                          this.state.sucessMessage !== undefined ? (
                          <span className="login-success-error">
                            {this.state.sucessMessage}
                          </span>
                        ) : null}
                      </div>

                      <div className="bottpostpopright">
                        {this.state.errors && this.state.errors.privacyText && (
                          <span
                            className="login-feedback-error mt-1"
                            style={{
                              display: "block",
                              width: "auto",
                            }}
                          >
                            {this.state.errors.privacyText}
                          </span>
                        )}
                        {this.state.errors && this.state.errors.connectionText && (
                          <span
                            className="login-feedback-error  post-error-tooltip"
                            style={{
                              display: "block",
                              width: "auto",
                            }}
                          >
                            {this.state.errors.connectionText}
                          </span>
                        )}
                        {/*<div className="charechterinfo">Saving...</div> */}
                        <div className="charechterinfo">
                          {Stripbody} {Stripbody < 2 ? "word" : "words"}
                        </div>
                        <a
                          data-toggle="modal"
                          className="btn cancel"
                          data-target={"#blogpostpreviewwrap"}
                        >
                          Preview
                        </a>
                        <div className="contxmenu primntn_dropdown">
                          <button
                            type="button"
                            onClick={this.handlePostSubmit}
                            className="btn"
                            disabled={this.state.isPostSubmitted}
                          >
                            Publish
                          </button>
                          <button
                            type="button"
                            className="btn dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span className="sr-only">Toggle Dropdown</span>
                          </button>
                          <div className="dropdown-menu fadeIn animated">
                            {!this.state.isPostSubmitted && (
                              <a
                                href="javascript:void(0)"
                                onClick={this.handlePostSubmit}
                              >
                                Publish
                              </a>
                            )}
                            <button
                              onClick={this.handleDraftSubmit}
                              className="btn"
                              disabled={
                                this.state.isPostSubmitted ||
                                !this.state.titleText ||
                                this.state.titleText === ""
                              }
                            >
                              Draft
                            </button>
                            {/*<a href="javascript:void(0)">Draft</a>*/}
                            {(this.state.postText &&
                              this.state.postText !== "") ||
                              (this.state.titleText &&
                                this.state.titleText !== "") ||
                              (this.state.hashTags &&
                                this.state.hashTags.length > 0) ||
                              (this.state.coverImagePreview &&
                                this.state.coverImagePreview !== null) ? (
                              <a
                                href="javascript:void(0)"
                                onClick={this.confirmModal}
                              >
                                Discard
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                onClick={this.hideModal}
                              >
                                Discard
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.postAsFlag && <CommonPostAsPopUp onCloseEditPostAs={this.onCloseEditPostAs} onSelectPostAsData={this.onSelectPostAsData} entityData={this.state.entityData} secondaryEntityName={this.state.secondaryEntityName} entityName={this.state.entityName} userId={this.state.userId} entityType={this.state.entityType} />}

        {/*Confirmation Modal Start*/}
        <div
          className="modal fade dgpopup confirmpop"
          id={"cancelPopup2"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Cancel Confirmation</h5>                                
                            </div> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={this.ignoreModal}>
                  &times;
                </span>
              </button>
              <div className="confirm-popup-modal-body">
                Are you sure you want to cancel this Article?
              </div>
              <div className="modal-footer confirm-popup-modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.hideModal}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.ignoreModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Confirmation Modal End*/}
        <div
          id="blogpostpreviewwrap"
          className="modal fade dgpopup"
          data-backdrop="false"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="blogPreviewwrap">
                  <div className="projectinn explinner">
                    <div className="explpagebannwrap">
                      {this.state.coverImagePreview ? (
                        <LazyLoadImage
                          effect="blur"
                          src={this.state.coverImagePreview}
                          alt=""
                        />
                      ) : null}
                      {/* <div className="expldesctophead">
                        <div className="fdtheadleft">                          
                          <h1>{this.state.titleText}</h1>
                        </div>
                      </div> */}
                    </div>
                    <div className="expldescmain">
                      <div className="expldescall">
                        <h1>{this.state.titleText}</h1>
                        <div className="fuserfollow">
                            <figure className="userlogo">
                              <img src={this.state.coverImageNew} alt="" />
                            </figure>
                            {this.state.entityName}
                        </div>
                        <hr />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.postText,
                          }}
                        />
                        <div className="tagsblk">
                          {this.state.hashTags &&
                            this.state.hashTags.map((hashtag) => {
                              return <span className="hashLinkPost">{hashtag}</span>;
                            })}
                        </div>
                      </div>
                      <div className="bottmbtns">
                        <a className="btn btn-cancel" data-dismiss="modal">
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="blogSelectUserModal"
          className="chatlistpop modal fade dgpopup"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                {this.state.isModalLoading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader
                      type="Grid"
                      color="#dcde63"
                      height={60}
                      width={60}
                      timeout={8000}
                    />
                  </div>
                ) : (
                  <div className="forwcontwrap">
                    <div className="forwcontwraptop">
                      <h4>Select Users</h4>
                      <form action="" onSubmit="return false;">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type Contact Name"
                          id="searchGroupByChars"
                          onKeyUp={(evt) =>
                            this.keyPressedBySearchGroupByChars(evt)
                          }
                        />
                        <button
                          className="btn btnsearch"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      </form>

                      {this.state.selectedList &&
                        this.state.selectedList.length > 0 ? (
                        <HorizontalScroll reverseScroll>
                          <div className="srchlist">
                            {this.state.selectedList &&
                              this.state.selectedList.map((data, index) => {
                                return (
                                  <div key={index} className="srchlistblk">
                                    <figure className="img">
                                      <img
                                        src={
                                          data.personalInfo.profileImage != null
                                            ? getCDNUrl(
                                              this.getOptimizedImage(
                                                data.personalInfo
                                              )
                                            )
                                            : "https://cdn.dscovr.com/images/users1.png"
                                        }
                                        alt=""
                                      />
                                    </figure>
                                    <span>
                                      {data.firstName} {data.lastName}
                                      {data.companyName}
                                    </span>
                                    {index !== this.state.userData.userId && (
                                      <i
                                        className="iCross"
                                        onClick={(e) =>
                                          this.removeUserToGroup(e, data)
                                        }
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </HorizontalScroll>
                      ) : null}
                      <Scrollbars
                        className={"forwcontlistmain contactlist"}
                        autoHide
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={322}
                        universal={true}
                        renderTrackHorizontal={(props) => (
                          <div {...props} className="track-horizontal" />
                        )}
                        renderTrackVertical={(props) => (
                          <div {...props} className="track-vertical" />
                        )}
                      >
                        <ul>
                          {this.state.userList &&
                            this.state.userList
                              .filter(
                                (data) =>
                                  data.id !== this.state.userData.userId &&
                                  (this.state.searchUserByChars === "" ||
                                    (this.state.searchUserByChars &&
                                      data.firstName &&
                                      data.firstName
                                        .toLowerCase()
                                        .includes(
                                          this.state.searchUserByChars.toLowerCase()
                                        )) ||
                                    (this.state.searchUserByChars &&
                                      data.lastName &&
                                      data.lastName
                                        .toLowerCase()
                                        .includes(
                                          this.state.searchUserByChars.toLowerCase()
                                        )))
                              )
                              .map((data, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        this.addUserToGroup(e, data)
                                      }
                                    >
                                      <figure className="users">
                                        <img
                                          src={
                                            data.personalInfo.profileImage !=
                                              null
                                              ? getCDNUrl(
                                                this.getOptimizedImage(
                                                  data.personalInfo
                                                )
                                              )
                                              : "https://cdn.dscovr.com/images/users1.png"
                                          }
                                          alt=""
                                        />
                                      </figure>
                                      <div>
                                        <div className="name">
                                          {data.firstName} {data.lastName}
                                          {data.companyName}{" "}
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                        </ul>
                      </Scrollbars>
                    </div>

                    <div className="forwcontwrapbott">
                      <div className="itemscount">
                        {this.state.selectedList.length} Selected
                      </div>
                      <div>
                        <button
                          className="butt btnsecondary close"
                          data-dismiss="modal"
                          id="forwardMessageModalCloseBtn"
                          onClick={this.clearUsers.bind(this)}
                        >
                          Close
                        </button>
                        <button
                          disabled={this.state.selectedList.length === 0}
                          className="btn"
                          onClick={this.handleUsersSelectSubmit}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestPostCreationFulfilled: (value) =>
      dispatch(requestPostCreationFulfilled(value)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(BlogPopup));
