import React from 'react'
import { authService } from '../auth';
import httpService from '../AxiosInterceptors';
import { Redirect } from "react-router-dom";
import Header from "../Header";
import axios from "axios";
import {getCDNUrl} from "../commons/CommonFunctions";
import AssignAdminPop from "./AssignAdminPop";
import DeactivationWithConfirmFinalPopUp from "./DeactivationWithConfirmFinalPopUp";
import ConnectionNotFoundError from "./ConnectionNotFoundError";
import "./main-settings.min.css";

httpService.setupInterceptors();

class ProfileDeactivationAdminRights extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            errors: {},
            isDisabled : true,
            error: null,
            success: null,
            data:this.props.location && this.props.location.state && this.props.location.state.data,
            details:'',
            config:{},
            projectClassName:'nav-link active',
            projectTabClassName:'tab-pane fade active show',
            businessPageClassName:'nav-link',
            businessPageTabClassName:'tab-pane fade tabCompanypages',
            circleClassName:'nav-link',
            circleTabClassName:'tab-pane fade tabCircles',
            assignToSingleConnect : true,
            adminList :[],
            type:'',
            allAdminAssigned :false,
            submitted:false,
            forAll:false,
            entityId:null,
            message : '',
            redirectionRequired:false,
            redirectionLink:'/network'
        };
    }

    componentDidMount() {
        this.getDeactivationConfig();
        if(!this.props.location || !this.props.location.state || !this.props.location.state.data){
            window.location.href = '/security-and-login'
        }
    }

    getDeactivationConfig=()=>{
        this.setState({'isSubmitted': true});
        axios({
            method:'get',
            url:process.env.REACT_APP_userServiceURL + '/deactivation/get/config/'+this.state.userData.userId,
            headers:{ 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response)=>{
            let res = response.data
            if(res && res.status === '200 OK'){
                this.setState({'config': res.body,'isSubmitted': false},()=>{
                    if(this.state.config.totalProjectCount ===0 && this.state.config.totalBusinessPageCount ===0 && this.state.config.totalCircleCount ===  0){
                        window.location.href = '/';
                    }
                    if(this.state.config.totalProjectCount >0){
                        this.setState({'projectClassName':'nav-link active',projectTabClassName:'tab-pane fade active show'})
                    }else if(this.state.config.totalBusinessPageCount >0){
                        this.setState({'businessPageClassName':'nav-link active','projectClassName':'nav-link',businessPageTabClassName:'tab-pane fade active show',projectTabClassName:'tab-pane fade'})
                    }else if(this.state.config.totalCircleCount >0){
                        this.setState({'circleClassName':'nav-link active','projectClassName':'nav-link',circleTabClassName:'tab-pane fade active show',projectTabClassName:'tab-pane fade '})
                    }
                });
            } else {
                this.setState({'isSubmitted': false});

            }
        }).catch((err)=>{
            console.log(err)
            this.setState({'isSubmitted': false});
        })
    }

    assignAdminForAll = (user)=>{
        if(this.state.type === 'project'){
            let projects = this.state.config.projects;
            Object.keys(projects).map((data,index) => {
                let value = projects[data];
                if(value){
                    value.assigned = true;
                    value.username = user.username;
                    value.userId = user.id;
                }
        })
            this.setState({config: {...this.state.config, projects: projects}},()=>{
                this.checkAllAdminAssigned();
            });
        }else if(this.state.type === 'circle'){
            let circles = this.state.config.circles;
            Object.keys(circles).map((data,index) => {
                let value = circles[data];
                if(value){
                    value.assigned = true;
                    value.username = user.username;
                    value.userId = user.id;
                }
            })
            this.setState({config: {...this.state.config, circles: circles}},()=>{
                this.checkAllAdminAssigned();
            });
        }else if(this.state.type === 'businessPage'){
            let  businessPages =this.state.config.businessPages;
            Object.keys(businessPages).map((data,index) => {
                let value = businessPages[data];
                if(value){
                    value.assigned = true;
                    value.username = user.username;
                    value.userId = user.id;
                }
            })
            this.setState({config: {...this.state.config, businessPages: businessPages}},()=>{
                this.checkAllAdminAssigned();
            });
        }
    }

    assignAdminForSingle = (user)=>{
        if(this.state.type === 'project'){
            let projects = this.state.config.projects;
                let value = projects[this.state.entityId];
                if(value){
                    value.assigned = true;
                    value.username = user.username;
                    value.userId = user.id;
                }
            this.setState({config: {...this.state.config, projects: projects}},()=>{
                this.checkAllAdminAssigned();
            });
        }else if(this.state.type === 'circle'){
            let circles = this.state.config.circles;
                let value = circles[this.state.entityId];
                if(value){
                    value.assigned = true;
                    value.username = user.username;
                    value.userId = user.id;
                }

            this.setState({config: {...this.state.config, circles: circles}},()=>{
                this.checkAllAdminAssigned();
            });
        }else if(this.state.type === 'businessPage'){
            let  businessPages =this.state.config.businessPages;
                let value = businessPages[this.state.entityId];
                if(value){
                    value.assigned = true;
                    value.username = user.username;
                    value.userId = user.id;
                }
            this.setState({config: {...this.state.config, businessPages: businessPages}},()=>{
                this.checkAllAdminAssigned();
            });
        }
    }

    checkAllAdminAssigned = () =>{
        let allAdminAssigned = true;
        let  businessPages =this.state.config.businessPages;
        let circles = this.state.config.circles;
        let projects = this.state.config.projects;
        if(businessPages){
            Object.keys(businessPages).map((data,index) => {
                if(!businessPages[data].assigned){
                    allAdminAssigned = false;
                    return;
                }
            })
        }
        if(circles){
            Object.keys(circles).map((data,index) => {
                if(!circles[data].assigned){
                    allAdminAssigned = false;
                    return;
                }
            })
        }
        if(projects){
            Object.keys(projects).map((data,index) => {
                if(!projects[data].assigned){
                    allAdminAssigned = false;
                    return;
                }
            })
        }
        this.setState({'allAdminAssigned':allAdminAssigned});


    }



    getAssignAdminPopUpForAll = (event) => {
        event.preventDefault();
        this.setState({forAll:true,type:event.target.name,'adminList':this.state.config.connectionList},()=>{
            if(this.state.adminList && this.state.adminList.length === 0){
                let message = '';
                let redirectionRequired = false;
                if(this.state.userData.type === 'INDIVIDUAL'){
                    message = 'No connections to transfer the Admin Rights. Please make connections to proceed.';
                    redirectionRequired = true;
                }else if(this.state.userData.type === 'COMPANY'){
                    message = 'No employee to transfer the admin rights. Please add an employee to proceed.';
                }
                this.setState({message:message,redirectionRequired:redirectionRequired},()=>{
                    window.$('#connectionNotFound').modal('show');
                });

            } else{
                window.$('#assignsinglepop').modal('show');
            }
        })

    }

    getAssignAdminPopUpForSingle = (event,entityId,type) => {
        event.preventDefault();
        this.setState({forAll:false,entityId:entityId,type:type,'adminList':this.state.config.connectionList},()=>{
            if(this.state.adminList && this.state.adminList.length === 0){
                let message = '';
                let redirectionRequired = false;
                if(this.state.userData.type === 'INDIVIDUAL'){
                    message = 'No connections to transfer the Admin Rights. Please make connections to proceed.';
                    redirectionRequired = true;
                }else if(this.state.userData.type === 'COMPANY'){
                    message = 'No employee to transfer the admin rights. Please add an employee to proceed.';
                }
                this.setState({message:message,redirectionRequired:redirectionRequired},()=>{
                    window.$('#connectionNotFound').modal('show');
                });

            } else{
                window.$('#assignsinglepop').modal('show');
            }
        })

    }

    getOptimizedImage(entity) {
        if (entity.resizedEntityImage && entity.resizedEntityImage['300x300'] != null) {
            return entity.resizedEntityImage['300x300'];
        }
        return entity.entityImage;
    }

    getDefaultImage(entityType) {
        if(entityType && (entityType==='EVENT' || entityType === 'TRAINING')){
            return  'https://cdn.dscovr.com/images/project-default.jpg'
        }
        return 'https://cdn.dscovr.com/images/project-default.jpg';
    }

    getOptimizedBusinessPagesImage(entity) {
        if (entity.resizedEntityImage && entity.resizedEntityImage['200x200'] != null) {
            return entity.resizedEntityImage['200x200'];
        }
        return entity.entityImage;
    }

    cancel = (event) => {
       event.preventDefault();
       window.location.href='/security-and-login'
    }

    handleSubmit = (event) => {
        this.setState({'submitted':true});
        event.preventDefault();
        let adminRights = {};
        let projects = this.state.config.projects;
        let circles = this.state.config.circles;
        let businessPages = this.state.config.businessPages;
        if(projects){
            Object.keys(projects).map((data,index) => {
                let value = projects[data];
                if(value){
                    let insertData = {};
                    insertData.userId = value.userId;
                    insertData.entityId = value.entityId;
                    insertData.title = value.title;
                    insertData.entityType = value.entityType;
                    insertData.status = 'PENDING';
                    adminRights[data]=insertData;
                }


            })
        }
        if(circles){
            Object.keys(circles).map((data,index) => {
                let value = circles[data];
                if(value){
                    let insertData = {};
                    insertData.userId = value.userId;
                    insertData.entityId = value.entityId;
                    insertData.title = value.title;
                    insertData.entityType = value.entityType;
                    insertData.status = 'PENDING';
                    adminRights[data]=insertData;
                }


            })
        }
        if(businessPages){
            Object.keys(businessPages).map((data,index) => {
                let value = businessPages[data];
                if(value){
                    let insertData = {};
                    insertData.userId = value.userId;
                    insertData.entityId = value.entityId;
                    insertData.title = value.title;
                    insertData.entityType = value.entityType;
                    insertData.status = 'PENDING';
                    adminRights[data]=insertData;
                }


            })
        }

        let postBody = {
                "userId": this.state.userData.userId,
                "reason": this.state.data.reason,
                "description": this.state.data.description,
                "previousActivityOriginal": this.state.data.previousActivityOriginal,
                "adminRights": adminRights,
                "privateEntityId":this.state.userData.entityId
            }
        ;
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/deactivation/deactivate',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({'submitted':false});
                window.$('#finalPopUpAfterAdminRights').modal('show');
            }
        }).catch((err) => {
            if (err && err.response.data) {
                this.setState({
                    isLoaded: true,
                    'submitted':false,
                    error: {message: err.response.data.message, err: err.response}
                });

            }
        });

    };


    render() {
        const { error } = this.state;
        if (!this.state.loginSuccess) {
            return <Redirect to='/login' />
        } else {
            return <div className="wrapper">
                <Header hamburgerVisibility={false}/>
             <main className="dgcontainer hm3col">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="admin_rightTTle">
                                <h2>Transfer your Admin Rights</h2>
                                <div className="admin_rightTTlebtmbx">
                                    <p>Before deleting your account, please transfer your admin rights. The admin rights will be transferred once all your requests are accepted.</p>

                                </div>
                            </div>
                            <div className="dlttabBx">

                                <div className="circMemInviteTabwrap">
                                    <div className="mcustHorizMenu">
                                        <div className="mcustHorizinn">
                                            <ul id="tabs" className="nav">
                                                {this.state.config.totalProjectCount > 0 &&
                                                <li className="nav-item"><a href="#projects" data-target=""
                                                                            data-toggle="tab"
                                                                            className={this.state.projectClassName}>Projects <span>{this.state.config.totalProjectCount}</span></a>
                                                </li>
                                                }
                                                {this.state.config.totalBusinessPageCount > 0 &&
                                                <li className="nav-item"><a href="#company-pages" data-target=""
                                                                            data-toggle="tab"
                                                                            className={this.state.businessPageClassName}>Organization Pages <span>{this.state.config.totalBusinessPageCount}</span></a>
                                                </li>
                                                }
                                                {this.state.config.totalCircleCount > 0 &&
                                                <li className="nav-item"><a href="#circles" data-target=""
                                                                            data-toggle="tab"
                                                                            className={this.state.circleClassName}>Communities <span>{this.state.config.totalCircleCount}</span></a>
                                                </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="tabsContent" className="tab-content">
                                    <div id="projects" className={this.state.projectTabClassName}>
                                        <div className="invtButtns">
                                            <p>Assign admin rights of all Projects to a single connect</p> <a onClick={this.getAssignAdminPopUpForAll} name={'project'}
                                               className="btn addofflineproj">Assign</a>
                                        </div>
                                        {this.state.config.projects && Object.keys(this.state.config.projects).map((data,index) => {
                                            return <div className="invitpeopleblk sent" key={index}>
                                                <div className="profrcablock">
                                                    <figure className="users">
                                                        <img
                                                            src={this.state.config.projects[data].entityImage ? getCDNUrl(this.getOptimizedImage(this.state.config.projects[data])) : this.getDefaultImage(this.state.config.projects[data].entityType)}
                                                            alt="" />
                                                    </figure>
                                                    <div className="profrcadesc">
                                                        <h5><a href="javascript:void(0)">{this.state.config.projects[data].title}</a>
                                                        </h5>
                                                        <p>{this.state.config.projects[data].entityType}</p>
                                                    </div>
                                                </div>
                                                <div className="invitpeopbtns">
                                                    <div className="pendingWrap">
                                                        {this.state.config.projects[data].assigned?
                                                        <p>{this.state.config.projects[data].username}</p>:<p>Not Assigned</p>}
                                                    </div>
                                                    <div className="inviteby">
                                                        <button onClick={(e) => this.getAssignAdminPopUpForSingle(e,this.state.config.projects[data].entityId,'project')}
                                                                className="btn assignbtn">Assign Admin
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div id="company-pages" className={this.state.businessPageTabClassName}>
                                        <div className="invtButtns">
                                            <p>Assign admin rights of all Organization Pages to a single connect</p>
                                            <a onClick={this.getAssignAdminPopUpForAll} name={'businessPage'}
                                               className="btn addofflineproj">Assign</a>
                                        </div>
                                        {this.state.config.businessPages && Object.keys(this.state.config.businessPages).map((data,index) => {
                                            return <div className="invitpeopleblk sent" key={index}>
                                                <div className="profrcablock">
                                                    <figure className="users">
                                                        <img
                                                        src={this.state.config.businessPages[data].entityImage ?getCDNUrl(this.getOptimizedBusinessPagesImage(this.state.config.businessPages[data])) :'https://cdn.dscovr.com/images/DefaultBusiness.webp'} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/DefaultBusiness.png"}} alt=""/>
                                                    </figure>
                                                    <div className="profrcadesc">
                                                        <h5><a href="javascript:void(0)">{this.state.config.businessPages[data].title}</a></h5>
                                                    </div>
                                                </div>
                                                <div className="invitpeopbtns">
                                                    <div className="pendingWrap">
                                                        {this.state.config.businessPages[data].assigned?
                                                            <p>{this.state.config.businessPages[data].username}</p>:<p>Not Assigned</p>}
                                                    </div>
                                                    <div className="inviteby">
                                                        <button onClick={(e) => this.getAssignAdminPopUpForSingle(e,this.state.config.businessPages[data].entityId,'businessPage')}
                                                                className="btn assignbtn">Assign Admin
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                    <div id="circles" className={this.state.circleTabClassName}>
                                        <div className="invtButtns">
                                            <p>Assign admin rights of all Communities to a single connect</p>
                                            <a onClick={this.getAssignAdminPopUpForAll} name={'circle'}
                                               className="btn addofflineproj">Assign</a>
                                        </div>
                                        {this.state.config.circles && Object.keys(this.state.config.circles).map((data,index) => {
                                            return <div className="invitpeopleblk sent" key={index}>
                                                <div className="profrcablock">
                                                    <figure className="users">
                                                        <img src={this.state.config.circles[data].entityImage ? getCDNUrl(this.getOptimizedImage(this.state.config.circles[data])):"https://cdn.dscovr.com/images/circleDefault.webp" } onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}} alt=""/>
                                                    </figure>
                                                    <div className="profrcadesc">
                                                        <h5><a href="javascript:void(0)">{this.state.config.circles[data].title}</a></h5>
                                                        <p><i className="iLocation"></i>{this.state.config.circles[data].country}</p>
                                                    </div>
                                                </div>
                                                <div className="invitpeopbtns">
                                                    <div className="pendingWrap">
                                                        {this.state.config.circles[data].assigned?
                                                            <p>{this.state.config.circles[data].username}</p>:<p>Not Assigned</p>}
                                                    </div>
                                                    <div className="inviteby">
                                                        <button onClick={(e) => this.getAssignAdminPopUpForSingle(e,this.state.config.circles[data].entityId,'circle')}
                                                                className="btn assignbtn">Assign Admin
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                </div>
                                <AssignAdminPop adminList={this.state.adminList} assignAdminForAll={this.assignAdminForAll} forAll={this.state.forAll}
                                        assignAdminForSingle={this.assignAdminForSingle}/>
                            </div>
                            <div className="tab_btm d-flex justify-content-end">
                                <button type="button" className="btn btn-primary" data-dismiss="modal"
                                        disabled={!this.state.allAdminAssigned || this.state.submitted}
                                        onClick={this.handleSubmit}>Proceed
                                </button>
                                <button type="button" className="btn btn-cancel" onClick={this.cancel}>Cancel</button>
                            </div>
                        </div>
                        
                         <DeactivationWithConfirmFinalPopUp/>
                         <ConnectionNotFoundError message={this.state.message} redirectionRequired={this.state.redirectionRequired} redirectionLink={this.state.redirectionLink}/>
                    </div>
                </div>
             </main></div>
        }
    }
}

export default ProfileDeactivationAdminRights
