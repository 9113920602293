import React, { Component } from 'react'
import { authService } from '../auth';
import CommonAttachmentSection from '../commons/CommonAttachmentSection';
import UserReactionComponent from '../UserReaction/UserReactionComponent';
import Share from '../commons/Share';
import AddCommentBox from '../commons/AddCommentBox';
import Comment from '../commons/Comment';
import cloneDeep from "lodash/cloneDeep";
import axios from 'axios';
import CommentUserListPop from '../commons/CommentUserListPop';
import { getCDNUrl } from '../commons/CommonFunctions';
import { NavLink } from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import { getCommentsByActivityId } from '../services/CommentsApi';

class CommonPostBottomSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            comments: [],
            page: 0,
            size: 3,
            commentBtnActive: false,
            commentCount: props.item.commentCount,
            isOpenUserListPop: false,
            selectUserId: authService.getUserDetailsFromLocalStorage()?.userId,
            selectUserImage: authService.getUserDetailsFromLocalStorage()?.profileImage,
            currentUserDetails: {}
        }
    }

    commentsCollapse = () => {
        if (!this.state.commentBtnActive) {
            this.setState({
                page: 0
            }, () => {
                this.getAllComments(this.state.page, 3, false);
            })
        }
        this.setState({ 'commentBtnActive': !this.state.commentBtnActive });
    }

    fetchMoreCommentData = () => {
        this.setState(
            { isResultLoading: true, page: this.state.page + 1 },
            () => {
                this.getAllComments(this.state.page, this.state.size, true);
            }
        );
    };

    getAllComments = async (page, size, concatWithPrevious) => {
        let response = await getCommentsByActivityId({"id": this.props.item.id, "userId": this.state.userData.userId, "page": page, "size": size});
        if(response?.message === "Success!") {
            let pagedData = response.body;
            if (concatWithPrevious) {
                if (this.state.comments.content && this.state.comments.content.length > 0) {
                    let comments = cloneDeep(
                        this.state.comments.content
                    );
                    pagedData.content = comments.concat(pagedData.content);
                }
            }
            this.setState({
                'comments': pagedData,
                'commentCount': response.body.content.length > 0 ? response.body.content[0].commentsCount : 0,
                'pagination': response.body.last
            });
        }
    }

    refresh_comment = () => {
        this.setState({
            page: 0,
            size: 5
        }, () => {
            this.getAllComments(this.state.page, 3, false)
        })
    }

    onClickCommentListUser = () => {
        this.setState({
            isOpenUserListPop: true
        })
    }

    onSuccessCommentUser = (userData) => {
        this.triggerCloseCommentUserList();
        this.setState({
            currentUserDetails: { "userId": userData.entityId, "profileImage": userData.entityImageUrl, type: userData.entityType, "name": userData.entityId === this.state.userData.userId && userData.entityType === "INDIVIDUAL" ? this.state.userData.firstName : userData.entityName}
        })
    }

    triggerCloseCommentUserList = () => {
        window.$("#commentUserListPop").modal("hide");
        this.setState({
            isOpenUserListPop: false
        })
    }

    componentDidMount() {
        window.$('.hpostblockinn .collapser').click(function (event) {
            window.$(event.target).closest(".hpostblockinn").find(".commentglobal").collapse('toggle');
        });
        this.setState({
            currentUserDetails: { "userId": this.state.userData.userId, "profileImage": this.state.userData.profileImage, "type": this.state.userData.type, "name": this.state.userData.companyName ? this.state.userData.companyName : this.state.userData.firstName}
        })
    }

    render() {
        const { item, postType, postData } = this.props;
        const { isOpenUserListPop, currentUserDetails } = this.state;
        const postTarget = {
            pathname: item.postType === "FORUM" ? `/forumDetail/${this.props.item.metaData?.slug}` : item.postType === "POLL" ? `/pollDetail/${this.props.item.metaData?.slug}` : `/post/${this.props.item.id}`,
            key: uuidv4(),
            state: {
                applied: true
            }
        };
        return (
            <>
                <div className="hpostcontbott">
                    <div className='hpostcontbottLeft'>
                        {item.userEntityCount > 0 && 
                            <button className="postAsButtn" onClick={this.onClickCommentListUser}>
                                <figure className="userlogo">
                                    <img src={currentUserDetails?.profileImage != null && currentUserDetails?.profileImage !== "" ? getCDNUrl(currentUserDetails.profileImage) : currentUserDetails?.type === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : currentUserDetails?.type === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"} alt="" />
                                </figure>
                            </button>
                        }                        
                        {item.secondaryAttachmentIds && item.secondaryAttachmentIds.length > 0 ?
                            <CommonAttachmentSection attachments={item.secondaryAttachmentIds} itemId={item.id} /> :
                            <div className="fattach" />
                        }
                    </div>
                    <UserReactionComponent item={item} key={item.id} currentUserDetails={currentUserDetails} />
                    <div>
                        <a href="javascript:void(0)" onClick={this.commentsCollapse}
                            className="comments collapser"
                            data-toggle="collapse"><span
                                className="art_btn_bx">{this.state.commentCount}&nbsp;<span
                                    className="art_btn_txt">{this.state.commentCount <= 1 ? 'Comment' : 'Comments'}</span></span></a>
                    </div>
                    {(postType === "FORUM" || postType === "POLL") ? 
                        <Share
                            shareUrl={postType === "FORUM" ? "/forumDetail/" : "/pollDetail/"}
                            entityId={postData.id}
                            slug={postData.slug}
                            entityType={postType}
                            shareId={postData.id}
                            sharesCount={this.props.item.sharesCount}
                            hideShareOnPortalBtn={(this.props.item.postVisibility !== 'ANYONE') || (!this.props.enablePostSharing)} 
                            currentUserDetails={currentUserDetails}
                        /> :
                        !item.paidPost && 
                        <Share
                            shareUrl="/post/"
                            hideShareOnPortalBtn={(item.postVisibility !== 'ANYONE') || (!this.props.enablePostSharing)}
                            entityId={item.sharedEntityId ? item.sharedEntityId : item.id}
                            entityType={item.sharedEntityType ? item.sharedEntityType : "POST"}
                            shareId={item.id}
                            sharesCount={/* item.sharedEntityId ? item.sharedEntityParams.sharesCount :  */item.sharesCount} 
                            currentUserDetails={currentUserDetails}
                        /> 
                    }
                </div>
                <div className="fadeIn animated commentglobal collapse">
                    <AddCommentBox item={this.props.item} getAllComments={this.getAllComments} currentUserDetails={currentUserDetails} pageType={"FEED"} />
                    {this.state.comments && this.state.comments.content && this.state.comments.content.length > 0 &&
                        <div className="forumcommtvlistwrap scrollbar-primary">
                            {
                                this.state.comments.content.map((comment) => {
                                    return <Comment item={comment} key={comment.id}
                                        callback={this.getAllComments} refresh={this.refresh_comment} />
                                })
                            }
                        </div>
                    }
                    {!this.state.pagination && this.state.comments?.content?.length > 0 && 
                        <div className='text-center' style={{ paddingBottom: '15px' }}>
                            <NavLink to={postTarget} className='btn cLoadmore'>
                                View All
                            </NavLink>
                            {/* <a href="javascript:void(0)" onClick={(e) => this.fetchMoreCommentData()} className='btn cLoadmore'>View All</a> */}
                        </div>
                    }
                </div>

                {isOpenUserListPop && <CommentUserListPop key={this.props.item.id} currentUserDetails={currentUserDetails} onSuccessCommentUser={this.onSuccessCommentUser} triggerCloseCommentUserList={this.triggerCloseCommentUserList} pageType={"FEED"} />}
            </>
        )
    }
}

export default CommonPostBottomSection;
