import axios from "axios";
import { Apis } from "../config/api";

export const getUserByCustomUrl = async (data) => {
    try {
        const response = await axios.get(Apis.GetUserByCustomUrl(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const profileDeactivationConfig = async (data) => {
    try {
        const response = await axios.get(Apis.ProfileDeactivationConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const userDetailsEntityCount = async (data) => {
    try {
        const response = await axios.get(Apis.UserDetailsCount(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const getUserByUserId = async (data) => {
    try {
        const response = await axios.get(Apis.GetUserDataById(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const getMyWeNatProfilePages = async (data) => {
    try {
        const response = await axios.get(Apis.GetMyWeNatProfilePages(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const getUserDetailaByEmail = async (data) => {
    try {
        const response = await axios.get(Apis.GetUserDetailaByEmail(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}