import {authService} from "../auth";
import axios from "axios";

import httpService from '../AxiosInterceptors';

httpService.setupInterceptors();

/** Venture */

export const topCausesForVolunteerURL = process.env.REACT_APP_userServiceURL + `/backend/volunteering/top-causes/`;
export const topVolunteeringOrganizationURL = process.env.REACT_APP_userServiceURL + `/backend/volunteering/top-organizations/`;

export const fetchTopCausesForVolunteer = function (page = 0,
                                                    size = 6) {
    let url = topCausesForVolunteerURL + "?page=" +
        page +
        "&size=" +
        size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTopVolunteerOrganization = function (page = 0,
                                                       size = 6) {
    let url = topVolunteeringOrganizationURL + "?page=" + page + "&size=" + size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "participatingUserId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
}

/** Assignment */


export const topAssignmentOpportunityURL =
    process.env.REACT_APP_userServiceURL + `/backend/assignments/top-opportunities/`;

export const topAssignmentPerformersURL =
    process.env.REACT_APP_userServiceURL + "/backend/assignments/top-performers/";

export const trendingAssignments =
    process.env.REACT_APP_userServiceURL + "/project/filter/trending-assignments/";


export const fetchTopAssignmentOpportunity = function (page = 0,
                                                       size = 6) {
    let url = topAssignmentOpportunityURL + "?page=" +
        page +
        "&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId +
        "&size=" +
        size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTopAssignmentPerformer = function (page = 0,
                                                     size = 6) {
    let url = topAssignmentPerformersURL + "?page=" + page + "&size=" + size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "participatingUserId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
}

export const fetchTrendingAssignment = function (page ,size ) {
    let url = trendingAssignments+authService.getUserDetailsFromLocalStorage().userId + "?page=" + page +"&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId + "&size=" + size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
}


/** My Venture Show All */

export const runningProjectUrl =
    process.env.REACT_APP_userServiceURL + `/participants/running-project/`;

export const upcomingProjectUrl =
    process.env.REACT_APP_userServiceURL + "/participants/upcoming-projects/";

export const completedProjectUrl =
    process.env.REACT_APP_userServiceURL + "/participants/completed-projects/";

export const cancelledProjectUrl =
    process.env.REACT_APP_userServiceURL + `/participants/cancelled-projects/`;

export const circleCancelledProjectUrl =
    process.env.REACT_APP_userServiceURL + `/participants/circle-cancelled-projects/`;

export const fetchVentureRunningProject = function (
    filter = "popular",
    userActivityType,
    projectFilterType,
    page = 0,
    size = 6,
    userId
) {

    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;

    let url =
        runningProjectUrl +
        userId +'/'+uid +
        "/?filterType=" +
        filter +
        "&isPinned=" +
        false+
        '&userActivityType='+userActivityType+'&projectFilterType='+projectFilterType+
        "&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId +
        "&page=" +
        page +
        "&size=" +
        size;

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchVentureCancelledProject = function (
    page ,
    size,
    projectFilterType,
    userId,
    type,
    circleId
) {

    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;

    let url =
        cancelledProjectUrl +
        userId +
        "?projectFilterType="+projectFilterType +
        "&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId +
        "&page=" +
        page +
        "&size=" +
        size;
    if(type === "CIRCLE") {
        url = circleCancelledProjectUrl + userId + "/" + circleId + "?projectFilterType="+projectFilterType + "&page=" + page + "&size=" + size;
    }
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchVentureUpComingProject = function (
    filter = "popular",
    userActivityType,
    projectFilterType,
    page = 0,
    size = 6,
    userId
) {
    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let url =
        upcomingProjectUrl +
        userId +'/'+uid+
        "/?filterType=" +
        filter +
        "&isPinned=" +
        false+
        '&userActivityType='+userActivityType+'&projectFilterType='+projectFilterType+
        "&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId +
        "&page=" +
        page +
        "&size=" +
        size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchVentureCompletedProject = function (
    filter = "popular",
    userActivityType,
    projectFilterType,
    page = 0,
    size = 6,
    userId
) {
    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let url =
        completedProjectUrl +
        userId +'/'+uid+
        "/?filterType=" +
        filter +
        "&isPinned=" +
        false+
        '&userActivityType='+userActivityType+'&projectFilterType='+projectFilterType +
        "&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId +
        "&page=" +
        page +
        "&size=" +
        size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

/** Training Filter Start  */

export const topTutorsURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/trainings/top-tutors/?page=0&size=6";

export const topOrganizationsURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/trainings/top-organizations/?page=0&size=6";

export const topWorkshopTrainingCamps =
    process.env.REACT_APP_userServiceURL +
    "/backend/trainings/top-workshop-training-camps/?page=0&size=6";

export const trendingTraining =
    process.env.REACT_APP_userServiceURL + "/project/filter/trending-trainings/";

export const fetchTrainingTopTutors = function (projectId) {
    return axios({
        method: 'get',
        url: topTutorsURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "participatingUserId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const trendingTrainings = function (page,size) {
    let url = trendingTraining+authService.getUserDetailsFromLocalStorage().userId + "?page=" + page +"&privateEntityId="+authService.getUserDetailsFromLocalStorage().entityId + "&size=" + size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
}

export const fetchTopOrganizations = function () {
    return axios({
        method: 'get',
        url: topOrganizationsURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "participatingUserId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTopWorkshopsCamp = function () {
    return axios({
        method: 'get',
        url: topWorkshopTrainingCamps,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

/** */


export const fetchProjectDescriptionURL =
    process.env.REACT_APP_userServiceURL +
    "/participants/" +
    "find-project-description-details-by-userId-and-projectId/";

export const fetchProjectDescription = function (projectId) {
    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    return axios({
        method: 'get',
        url: fetchProjectDescriptionURL + uid + "/" + projectId+'?privateEntityId='+authService.getUserDetailsFromLocalStorage().entityId,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

/** Events Filter start */

/*export const trendingEventURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/events/trending-events/"+authService.getUserDetailsFromLocalStorage().userId+"?page=0&size=6";*/

export const topEventURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/events/top-events/?page=0&size=6";

export const topEventOrganizerURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/events/top-organizers/?page=0&size=6";
/** Events Filter end */

export const fetchTrendingEventsforFilter = function () {

    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;

    let trendingEventURL = process.env.REACT_APP_userServiceURL +
        "/project/filter/trending-events/"+uid+"?page=0&size=6";
    return axios({
        method: 'get',
        url: trendingEventURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return arrayToObject(response.data.body.content, "projectId");
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTopEvents = function () {
    return axios({
        method: 'get',
        url: topEventURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return arrayToObject(response.data.body.content, "projectId");
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchEventOrganizer = function () {
    return axios({
        method: 'get',
        url: topEventOrganizerURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return arrayToObject(response.data.body.content, "projectId");
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

/*** job filter start */
/*export const trendingJobOpportunityURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/jobs/trending-jobs/"+authService.getUserDetailsFromLocalStorage().userId;*/

export const topJobOpportunityURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/jobs/top-jobs/?page=0&size=6";

export const topJobRecruitersURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/jobs/top-recruiters/?page=0&size=6";

/***    job filter end */

/** home page filter start */
export const trendingOpportunityURL =
    process.env.REACT_APP_userServiceURL +
    "/project/filter/trending-opportunity/";

export const topPerformersURL =
    process.env.REACT_APP_userServiceURL +
    "/backend/show-all/top-performers/" +
    "?page=0&size=10";

export const topCompaniesURL =
    process.env.REACT_APP_userServiceURL + "/backend/show-all/top-companies/";

export const topRecruitersURL =
    process.env.REACT_APP_userServiceURL +
    `/backend/show-all/top-recruiters/?page=0&size=6`;

export const trendingEventsURL =
    process.env.REACT_APP_userServiceURL + "/backend/show-all/trending-events/";

/** home page filter end */
const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
    }, {});

export const fetchTopJobsRecruiter = function () {
    return axios({
        method: 'get',
        url: topJobRecruitersURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return arrayToObject(response.data.body.content, "projectId");
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTopJobs = function () {
    return axios({
        method: 'get',
        url: topJobOpportunityURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return arrayToObject(response.data.body.content, "projectId");
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTrendingJobs = function (page,size) {

    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;

    let trendingJobOpportunityURL =
        process.env.REACT_APP_userServiceURL +
        "/project/filter/trending-jobs/"+uid
    return axios({
        method: 'get',
        url: trendingJobOpportunityURL+"?page="+page+"&size="+size,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };

            //return arrayToObject(response.data.body.content, "projectId");

        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTrendingOpportunity = function (page , size ) {
    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let url = trendingOpportunityURL + uid + "?page=" + page + "&size=" + size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchTopPerformers = function () {
    return axios({
        method: 'get',
        url: topPerformersURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return arrayToObject(response.data.body.content, "projectId");
        } else return {};
    }).catch((e) => {
        console.log(e);
        return e;
    });
};

export const fetchTopCompanies = function (page = 0, size = 6) {
    let url = topCompaniesURL + "?page=" + page + "&pageSize=" + size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(
                    response.data.body.content,
                    "participatingUserId"
                ),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
        return e;
    });
};

export const fetchTopRecruiters = function () {
    return axios({
        method: 'get',
        url: topRecruitersURL,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(
                    response.data.body.content,
                    "participatingUserId"
                ),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
        return e;
    });
};

export const fetchTrendingEvents = function (page = 0, size = 6) {
    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let url = trendingEventsURL + uid + "?page=" + page + "&size=" + size;

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return {
                content: arrayToObject(response.data.body.content, "projectId"),
                seeMore: !response.data.body.last,
                currentPage: response.data.body.number,
                size: response.data.body.size,
            };
        } else return {};
    }).catch((e) => {
        console.log(e);
        return e;
    });
};
