import React, { Component } from 'react'
import { authService } from '../auth';
import { getCDNUrl } from '../commons/CommonFunctions';
import DatePicker from "react-datepicker";
import MentionTextArea from '../commons/MentionTextArea';
import { CapitalizeFirstLetter } from '../utils/StringUtility';
import AutoSuggest from '../AutoSuggest';
import axios from 'axios';
import * as _ from "lodash";
import { connect } from 'react-redux';
import { requestPostCreationFulfilled } from '../reducer/post';
import CommonPostAsForumPoll from '../commons/CommonPostAsForumPoll';

class PollPopUpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            tags: [],
            hashTags: [],
            status: 'ENABLE',
            userType: 'WENAT',
            startDate: '',
            endDate: '',
            name: "",
            pollOptions: [{ name: "", id: "" }, { name: "", id: "" }],
            pollQuestion: '',
            pollDescription: '',
            disableButton: false,
            showStatus: 'PUBLISH',
            error: {},
            entityDetails: {}
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
    }

    getOptimizedImage(personalInfo) {
        if (
            personalInfo.resizedProfileImages &&
            personalInfo.resizedProfileImages["200x200"] != null
        ) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.profileImage || personalInfo.entityImageUrl ? personalInfo.profileImage || personalInfo.entityImageUrl : (personalInfo.type !== "COMPANY" && personalInfo.entityType !== "BUSINESS_PAGES") ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png";
    }

    setEntity = (userDetails) => {
        this.setState({
            entityDetails: userDetails
        })
    }

    handleDelete(i) {
        this.setState({
            tags: this.state.tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        let { tags } = this.state;
        this.setState({ tags: [...this.state.tags, { id: tags.length + 1, text: tag }] });
    }

    handleTagsChange = (hashTags) => {
        let hashTagsList = [];
        if (hashTags && hashTags.length > 0) {
            hashTags && hashTags.map(function (data) {
                data = data.replace(/[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi, '');
                if (data != "") {
                    hashTagsList.push(data);
                }
            });
            let allHashTagsList = hashTagsList;
            hashTagsList = [...new Set(allHashTagsList)];
        }
        this.setState({ 'hashTags': hashTagsList });
    }

    handlePollQuestionChange = (e) => {
        this.setState({ pollQuestion: e.target.value });
    };

    handlePollDescriptionChange = (e) => {
        this.setState({ pollDescription: e.target.value });
    };

    handleStartDateChange = date => {
        if (date == null) {
            this.setState({ endDate: "" });
        }
        this.setState({ disableButton: false });
        var endDate = new Date(this.state.endDate);
        var startDate = new Date(date);
        let errors = {};
        if (endDate.getTime() <= startDate.getTime()) {
            this.setState({
                endDate: "",
                startDate: date
            });
            errors["startDate"] = "Please enter a valid poll question";
            setTimeout(() => {
                this.setState({
                    errors: {}
                })
            }, 0);
        } else {
            this.setState({
                startDate: date,
            });
        }
        this.setState({
            errors: errors
        })
    };

    handleEndDateChange = date => {
        var endDate = new Date(date);
        var startDate = new Date(this.state.startDate);
        let errors = {};
        if (endDate.getTime() < startDate.getTime()) {
            errors["endDate"] = "Please enter a valid poll question";
            setTimeout(() => {
                this.setState({
                    errors: {}
                })
            }, 0);
        } else {
            this.setState({
                endDate: date
            });
        }
        this.setState({
            errors: errors
        })
    };

    handleAddPollOptions = (e) => {
        e.preventDefault();
        this.setState({
            pollOptions: this.state.pollOptions.concat([{ name: "", id: "" }])
        });
    };

    handleRemovePollOptions = (idx) => () => {
        this.setState({
            pollOptions: this.state.pollOptions.filter((s, sidx) => idx !== sidx)
        });
    };

    handlePollOptionValueChange = idx => evt => {
        this.setState({ disableButton: false });
        const newPollOptions = this.state.pollOptions.map((options, sidx) => {
            if (idx !== sidx) return options;
            return { ...options, name: evt.target.value, id: idx + 1 };
        });
        this.setState({ pollOptions: newPollOptions });
    };

    handleStatusChange = (e, type) => {
        let errors = {};
        if (e.target.name === 'ENABLE')
            this.setState({ showStatus: 'PUBLISH' });
        else if (e.target.name === 'DISCARD') {
            this.setState({
                startDate: '',
                endDate: '',
                showStatus: 'PUBLISH',
                tags: [],
                hashTags: [],
                pollQuestion: '',
                pollOptions: [{ name: "", id: "" }, { name: "", id: "" }]
            });
            window.$('#addPoll').modal('hide');
            window.$('#PollcancelPopup').modal('hide');
            this.props.pollPopUpClose();
        } else if (e.target.name === 'DRAFT') {
            this.setState({
                showStatus: 'PUBLISH'
            });
            if (type === 'POLL' && this.state.pollQuestion.trim() === '') {
                errors["pollQuestion"] = "Please enter a valid poll question";
            } else {
                this.saveAsDraft(e, type);
                window.$('#addPoll').modal('hide');
                this.setState({ disableButton: true });
            }
            this.setState({
                errors: errors
            });
            setTimeout(
                () => this.setState({ errors: {} }),
                3000
            );
        } else
            this.setState({ showStatus: e.target.name });
        this.setState({ status: 'ENABLE' });
    };

    checkValid = (value) => {
        if (!_.isUndefined(value) && !_.isNull(value) && value !== '') {
            return true;
        }
        return false;
    }
    checkEmptyValidation = (value) => {
        return !_.isUndefined(value) && !_.isNull(value) && value !== '' && value.length > 0;
    }

    revokeSuccess() {
        this.setState({
            messages: "",
            success: false
        });
    }

    revokeError() {
        this.setState({
            messages: '',
            error: {},
        });
    }

    saveAsDraft = (event, type) => {
        event.preventDefault();
        this.setState({ 'disableButton': true });
        let postData = {};
        if (this.checkValid(this.state.userType))
            postData.userType = this.state.userType;
        if (this.checkValid(this.state.createdBy))
            postData.createdBy = this.state.createdBy;
        if (this.checkValid(this.state.pollQuestion))
            postData.pollQuestion = this.state.pollQuestion.trim();
        if (this.checkValid(this.state.pollDescription))
            postData.pollDescription = this.state.pollDescription;
        if (this.checkEmptyValidation(this.state.pollOptions))
            postData.pollOptions = this.state.pollOptions;
        if (this.checkValid(this.state.startDate))
            postData.startDate = this.state.startDate;
        if (this.checkValid(this.state.endDate))
            postData.endDate = this.state.endDate;
        if (this.checkEmptyValidation(this.state.tags)) {
            this.state.hashTags = [];
            for (let data of this.state.tags) {
                if (data.text.charAt(0) === '#')
                    data.text = data.text.substring(1);
                this.state.hashTags.push(data.text);
            }
        }
        postData.hashTags = this.state.hashTags;
        let postBody = {
            "userId": !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityId : this.state.userData.userId,
            "parameterDetails": postData,
            "type": type,
            "privateEntityId": this.state.userData.entityId
        };
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/backend/drafts/create`,
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '201 CREATED') {
                this.setState({
                    messages: res.message,
                    success: true,
                    startDate: '',
                    endDate: '',
                    tags: [],
                    hashTags: [],
                });
                setTimeout(() => {
                    window.$('#addPoll').modal('hide');
                    this.revokeSuccess();
                    this.props.pollPopUpClose();
                }, 1000);
                if (this.props.getPollList) {
                    this.props.getPollList();
                }
                if (this.props.getTrendingList) {
                    this.props.getTrendingList();
                }
            }
            this.setState({ disableButton: false });
        }).catch((err) => {
            this.setState({ disableButton: false, fail: true });
            setTimeout(() => {
                this.revokeError();
            }, 5000);
            this.setState({
                isLoaded: true,
                disableButton: false,
                error: { message: err.response.data.message, err: err.response },
            });
        });

    };

    validatePoll = () => {
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.pollQuestion) || _.isEmpty((this.state.pollQuestion || "").toString()) || _.isNull(this.state.pollQuestion)) {
            errors["pollQuestion"] = "Please enter a valid poll question";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.startDate) || _.isEmpty((this.state.startDate || "").toString()) || _.isNull(this.state.startDate))) {
            errors["startDate"] = "Please enter the start date";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.endDate) || _.isEmpty((this.state.endDate || "").toString()) || _.isNull(this.state.endDate))) {
            errors["endDate"] = "Please enter the end date";
            formIsValid = false;
        }
        if (this.state.startDate && this.state.endDate && ((this.state.startDate).getTime() == (this.state.endDate).getTime())) {
            errors["startDate"] = "Start date and end date can not be same".
                formIsValid = false;
        }
        if (this.state.hashTags === null || this.state.hashTags === undefined || this.state.hashTags.length === 0) {
            formIsValid = false;
            errors["hashTags"] = "Please enter a atleast one hashtag";
        }
        this.state.pollOptions.map((data, index) => {
            if (data.name.trim() == '')
                formIsValid = false;
            const newArray = this.state.pollOptions;
            newArray[index].error = '';
            if ((index === 0) && data.name.trim() === '') {
                newArray[0].error = 'Please enter an option';
                this.setState({ pollOptions: newArray })
                return false;
            } else if ((index === 1) && data.name.trim() === '') {
                newArray[1].error = 'Please enter an option';
                this.setState({ pollOptions: newArray })
                return false;
            } else if ((index < this.state.pollOptions.length) && data.name.trim() === '') {
                newArray[index].error = 'Please enter an option. You may also remove the option.';
                this.setState({ pollOptions: newArray })
                return false;
            }
        })
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    handlePollSubmit = (event) => {
        event.preventDefault();
        if (this.validatePoll()) {
            let postBody = {
                "userId": !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityId : this.state.userData.userId,
                "userName": !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityName : this.state.userData.companyName == null ? this.state.userData.firstName : this.state.userData.companyName,
                "question": this.state.pollQuestion.trim(),
                "hashTags": this.state.hashTags ? this.state.hashTags.map((item) => {
                    return item.replace(/#/g, '')
                }) : [],
                "description": this.state.pollDescription,
                "pollAnswersList": this.state.pollOptions,
                "status": this.state.status,
                "startDate": this.state.startDate.getTime(),
                "endDate": this.state.endDate.getTime(),
                "userType": this.state.userType,
                "createdBy": !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityName : this.state.userData.companyName == null ? this.state.userData.firstName : this.state.userData.companyName,
                "privateEntityId" : this.state.userData && this.state.userData.entityId
            };
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_userServiceURL}/backend/public/poll/create`,
                headers: { 'Content-Type': 'application/json' },
                data: postBody,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.status === '201 CREATED') {
                    this.setState({
                        messages: res.message,
                        success: true,
                        hashTags: [],
                    });
                    setTimeout(() => {
                        window.$('#addPoll').modal('hide');
                        this.revokeSuccess();
                        this.props.pollPopUpClose();
                        this.props.requestPostCreationFulfilled({ success: true });
                        this.props.pollPopUpClose();
                    }, 3000);
                    if (this.props.getPollList) {
                        this.props.getPollList(0, 5, false);
                    }
                    if (this.props.getTrendingList) {
                        this.props.getTrendingList(0, 5, false);
                    }
                    this.setState({ 'disableButton': true });
                }
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        errors: {},
                        error: { message: err.response.data.message, err: err.response },
                    });
                }
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: {}
                });
            }, 5000);

        }
        setTimeout(() => {
            const newArray = this.state.pollOptions;
            this.state.pollOptions.map((data, index) => {
                newArray[index].error = '';
            }, 5000);
        })
    };

    hideModal = () => {
        this.setState({
            startDate: '',
            endDate: '',
            showStatus: 'PUBLISH',
            tags: [],
            hashTags: [],
            pollQuestion: '',
            pollOptions: [{ name: "", id: "" }, { name: "", id: "" }]
        });
        this.props.pollPopUpClose();
    }

    ignoreModal=()=>{
        window.$('#PollcancelPopup').modal('hide');
        window.$('#addPoll').modal('show');
    }

    confirmModal = () => {
        window.$("#PollcancelPopup").modal("show");
        window.$("#addPoll").modal("hide");
    };

    render() {
        const { userData, pollDescription, pollQuestion, startDate, endDate, hashTags, errors, disableButton, showStatus, success, messages, error } = this.state;
        return (
            <>
                <div id="addPoll" className="modal fade dgpopup">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">

                                <div className="blogpostwrap addpollwrap">
                                    {((this.state.pollQuestion && this.state.pollQuestion.length > 0) || (pollDescription && pollDescription !== '') || (this.state.startDate && this.state.startDate !== null)
                                        || (this.state.hashTags && this.state.hashTags.length > 0)) ?
                                        <button onClick={this.confirmModal} className="close topCrossModal"><i className="iCross" /></button> :
                                        <button onClick={this.hideModal} className="close topCrossModal"><i className="iCross" /></button>}
                                    <figure className="userimg">
                                        <img src={getCDNUrl(this.getOptimizedImage(!_.isEmpty(this.state.entityDetails) ? this.state.entityDetails : this.state.userData))} alt="" />
                                    </figure>
                                    <div className="toppostinn">
                                        <CommonPostAsForumPoll setEntity={this.setEntity} />
                                        <div className="blogpostbody">
                                            <div className="blogtoptitle">Create a Poll</div>
                                            <div className="blogpostcontwrap">
                                                <div className="fpolltextatra">
                                                    <form action="">
                                                        <textarea id="pollQuestion" name="pollQuestion"
                                                            value={pollQuestion || ''}
                                                            onChange={this.handlePollQuestionChange}
                                                            className="form-control"
                                                            placeholder="Add a Poll Question" />
                                                        {errors["pollQuestion"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["pollQuestion"]}</div>}

                                                        <form action="">
                                                            <MentionTextArea value={pollDescription || ''}
                                                                onChange={this.handlePollDescriptionChange}
                                                                placeholder="Write Poll Description" name="pollDescription">
                                                            </MentionTextArea>
                                                        </form>

                                                        <div className="addoptionfieldmain">
                                                            <div className="form-group">
                                                                <div className="addoptionfield">
                                                                    <label>Add Options</label>
                                                                    {this.state.pollOptions && this.state.pollOptions.map((options, idx) => (
                                                                        <div className="shareholder" key={idx}>
                                                                            <div><input type="text"
                                                                                placeholder={`Option #${idx + 1}`}
                                                                                className="form-control"
                                                                                value={options.name}
                                                                                onChange={this.handlePollOptionValueChange(idx)} />
                                                                                {idx > 1 ?
                                                                                    <button type="button"
                                                                                        onClick={this.handleRemovePollOptions(idx)}
                                                                                        className="small">
                                                                                        <i className="iDelete" />
                                                                                    </button> : null
                                                                                }
                                                                                <span className="login-feedback-error" style={{ display: "block" }}>{options.error}</span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="text-right">
                                                                    <button onClick={this.handleAddPollOptions}
                                                                        className="addmore"><i
                                                                            className="iAddList" />Add
                                                                        More
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="form-group addpollstend">

                                                                <div className="datefield">
                                                                    <label
                                                                        style={{ height: "22px" }}>{startDate != '' &&
                                                                            <span>Start Date</span>}</label>
                                                                    <div className='globalDTWrap common'>
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            className={"form-control custom-input"}
                                                                            selected={startDate}
                                                                            dateFormat={"dd-MMM-yyyy"}
                                                                            onChange={this.handleStartDateChange}
                                                                            placeholderText="Start Date"
                                                                        />
                                                                    </div>
                                                                    {errors["startDate"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["startDate"]}</div>}
                                                                </div>
                                                                {startDate &&
                                                                    <div className="datefield">
                                                                        <label
                                                                            style={{ height: "22px" }}> {endDate != '' &&
                                                                                <span>End Date</span>}</label>
                                                                        <div className='globalDTWrap common'>
                                                                            <DatePicker
                                                                                minDate={(startDate).getTime() + 86400000}
                                                                                className={"form-control custom-input"}
                                                                                dateFormat={"dd-MMM-yyyy"}
                                                                                selected={endDate}
                                                                                onChange={this.handleEndDateChange}
                                                                                placeholderText="End Date"
                                                                            />
                                                                        </div>
                                                                        {errors["endDate"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["endDate"]}</div>}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="tagwrap">
                                                                <div className="tagfield">
                                                                    <AutoSuggest
                                                                        hashTags={hashTags}
                                                                        onDelete={this.handleDelete}
                                                                        onAddition={this.handleAddition}
                                                                        handleTagsChange={this.handleTagsChange} />
                                                                </div>
                                                            </div>
                                                            {errors["hashTags"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["hashTags"]}</div>}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bottpostpopbody justify-content-end">
                                            {error?.message && <span className=" alert alert-warning"> {error.message} </span>}
                                            {success && <span className="success"> {messages} </span>}
                                            <div className="bottpostpopright">
                                                <div className="contxmenu primntn_dropdown">
                                                    <button type="button" onClick={this.handlePollSubmit} name="status"
                                                        disabled={disableButton}
                                                        className="btn">{showStatus}
                                                    </button>
                                                    <button type="button"
                                                        className="btn dropdown-toggle dropdown-toggle-split"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false"><span
                                                            className="sr-only">Toggle Dropdown</span></button>
                                                    <div className="dropdown-menu fadeIn animated">
                                                        {!disableButton &&
                                                            <a name="PUBLISH"
                                                                onClick={this.handlePollSubmit}>Publish</a>
                                                        }
                                                        <a name="DRAFT"
                                                            onClick={((e) => this.handleStatusChange(e, 'POLL'))}>Draft</a>
                                                        {((pollQuestion && pollQuestion.length > 0) || (pollDescription && pollDescription !== '') || (startDate && startDate !== null)
                                                            || (hashTags && hashTags.length > 0)) ?
                                                            <a onClick={this.confirmModal} name='DISCARD'>Discard</a> :
                                                            <a onClick={((e) => this.handleStatusChange(e, 'POLL'))} name='DISCARD'>Discard</a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade dgpopup confirmpop"
                    id={"PollcancelPopup"} tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true" onClick={this.ignoreModal}>&times;</span></button>
                            <div className="confirm-popup-modal-body">Are you sure you want to cancel this poll?</div>
                            <div className="modal-footer confirm-popup-modal-footer">
                                <a className="btn btn-primary" name='DISCARD'
                                    onClick={((e) => this.handleStatusChange(e, 'POLL'))}>Yes
                                </a>
                                <button type="button" className="btn btn-secondary" onClick={this.ignoreModal}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) =>
            dispatch(requestPostCreationFulfilled(value)),
    };
};

export default connect(null, mapDispatchToProps)(PollPopUpModal);
