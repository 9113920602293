import React, {Component} from 'react';
import {authService} from "../auth";
import Pinned from "../commons/Pinned";
import ReportAbuse from "../commons/ReportAbuse";
import Follow from "../commons/Follow";
import Linkify from "react-linkify";
import {getCDNUrl, totalVoteCount} from "../commons/CommonFunctions";
import axios from "axios";
import {Link, NavLink, Redirect} from "react-router-dom";
import MutualConnect from "../commons/MutualConnect";
import Share from "../commons/Share";

import stripHtml from "string-strip-html";
import { renderToString } from "react-dom/server";
import ReactHtmlParser from "react-html-parser";
import PollViewVoterPopUp from './PollViewVoterPopUp';
import UserReactionComponent from '../UserReaction/UserReactionComponent';
import PollVoteSection from '../commons/PollVoteSection';
import SavePostButton from '../post/SavePostButton';
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import ReadMoreReadLess from '../commons/ReadMoreReadLess';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const userCustomUrl = authService.getUserDetailsFromLocalStorage()?.customUrl;

function matchHashTags(text) {
    if(!text || text.length === 0){
        return text;
    }
    var string = text;
    var regex = /(#[a-z\d-_]+)/gi;
    var matches = string.matchAll(regex);
    for (var match of matches) {
        if (match && match.length > 1) {
            string = string.replace(match[0], '<span class="hposthashitem" ' +
                'style="font-family: Montserrat,sans-serif;font-weight: 500;font-size: 12px;line-height: 15px;color: #bfc52e;">' +
                '<a href="/profile-hashtag/'+match[0].substr(1)+ "/" + userCustomUrl + '"><i class="iHashtag"></i>' + match[0].substr(1) + '</a></span>');
        }
    }
    return string;
}

function transform (node, index) {
    if(node.type === 'text') {
        let modifiedString = matchHashTags(node.data)
        if(modifiedString !== node.data) {
            node.type = 'tag';
            node.name = 'div';
            return <>{ReactHtmlParser(modifiedString)}</>
        }
    }
}

const options = {
    decodeEntities: true,
    transform
};

class PollItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            answerId: '',
            checked: 0,
            disable: true,
            prefixId: props.specificKey ? props.specificKey : '',
            pollAnswerList: [],
            pollUserVoteMap: [],
            pollViewVoterFlag: false,
            userId: this.props.userId ? this.props.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    renderTaggedUsers = (text) => {
        if(!text || text.length === 0) {
            return text;
        }
        text = text.split("@@@__").join("<a href=\"/profile/");
        text = text.split("^^__").join("\">")
        text = text.split("&&__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("@@@^^^").join("</a>");
        text = text.split("###__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/profile-hashtag/");
        text = text.split("&&__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/profile-hashtag/");
        text = text.split("###^^^").join("</a>");
        return text;
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    triggerReportClose = () => {
        this.setState({openReportModal: null});
    }

    redirectToProfile = (customUrl) => {
        this.setState({redirectToProfile: customUrl});
    }

    onChange(i) {
        this.setState({
            checked: i,
            disable: false
        });
    }

    componentWillUnmount() {
        window.$('#confirmpopForDeletePoll' + this.props.item.id + this.state.prefixId).modal('hide');
    }

    handleDetailPage(evt, news_url) {
        evt.preventDefault();

        let userAgent = navigator.userAgent
        let mobileandroid = /wenat_android/.test(userAgent)
        let mobileios = /wenat_ios/.test(userAgent)

        if (news_url !== undefined && news_url !== null) {
            if (mobileios || mobileandroid) {
                window.location.href = news_url;
            } else {
                window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
            }
        }
    }

    sum = function (items, prop) {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    };

    getMaxChars(str, max) {
        if (str != null) {
            if (!str)
                return "";

            var n = str.length;
            if (max > n) {
                return str;
            }

            var res = str.substring(0, max);
            res += "...";
            return res;
        }
    }
    handleHideModal = (id) => {
        let data = {
            userId: this.state.userId,
            activityId: id,
            entityType: 'POLL',
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/hide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 201) {
                this.props.refreshData(id);
                
            } else {
                this.setState({'isSubmitted': false});
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({'error': 'Entity has already been hidden', 'isSubmitted': false});
            } else {
                console.log(err);
                this.setState({'isSubmitted': false});
            }
        });
    }

    handleUnHideModal = (id) => {
        let data = {
            userId: this.state.userId,
            activityId: id
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/unhide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                this.props.refreshData(id);

            } else {
                this.setState({'isSubmitted': false});
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({'error': 'Entity has already been unhidden', 'isSubmitted': false});
            } else {
                this.setState({'isSubmitted': false});
            }
        });
    }

    openDeleteModal = () => {
        window.$('#confirmpopForDeletePoll' + this.props.item.id + this.state.prefixId).modal('show');
    }

    deletePoll = (id) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/poll/delete?pollId=' + id,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '202 ACCEPTED') {
                this.props.refreshData(id);
            }
        }).catch((err) => {
            this.setState({
                isLoaded: true,
                error: {message: err.response.data.message, err: err.response},
            });
        });
    };
    refreshAllData=()=>{
        this.props.refreshData();
    }    
      
    render() {
        var currentTime = new Date().getTime();
        let linkifiedHtml =
            '<p class="pdtlsLink">' +
            this.renderTaggedUsers(this.props.item.description ? this.props.item.description : "").replace(/&nbsp;/g, " ")
            .replace(/(\r\n|\r|\n)/g, "<br>")
            .replace(/(\\n)/g, "<br>") +
            "</p>";

        linkifiedHtml = renderToString(
            ReactHtmlParser(linkifiedHtml, options)
        );
        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.state.redirectToProfile}/>}
                <div className="forumrow pollrow profilepage">
                    {!this.props.pageType && <Pinned item={this.props.item} entityType={'POLL'} callbackRequired={true}/>}
                    {this.props.pageType === "BOOKMARK" && 
                        <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={true} entityType={"POLL"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                    }
                    <h4><Link to={{pathname: '/pollDetail/' + this.props.item.slug, state: {userId: this.state.userId}}}><i
                        className="iPolls"/>{this.props.item.question}
                    </Link>
                    </h4>
                    {/* <p
                        dangerouslySetInnerHTML={{
                        __html: linkifiedHtml,
                        }}
                    /> */}
                    {/* <ResponsiveEllipsis
                        unsafeHTML={linkifiedHtml}
                        maxLine="3"
                        ellipsis='...'
                        trimRight
                        basedOn='words'
                    /> */}
                    {this.props.item.description &&
                        <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} item={this.props.item} />
                    }
                    <PollVoteSection pollDataDetail={this.props.item} />
                    <div className="forumrowbott">
                        <div className={"fuserfollow" + (this.props.item.userType === "COMPANY" ? " orgnz" : "")}>
                            <figure className="userlogo" style={{cursor: 'pointer'}} onClick={(e) => {
                                e.preventDefault();
                                this.redirectToProfile(this.props.item.customUrl)
                            }}><img
                                src={this.props.item.profileImage != null ? getCDNUrl(this.getOptimizedImage(this.props.item)) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                alt=""/></figure>
                            <a href="javascript:void(0)" onClick={(e) => {
                                e.preventDefault();
                                this.redirectToProfile(this.props.item.customUrl)
                            }} style={{cursor: "pointer"}}>{this.props.item.userName}</a>
                            {/*{this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId &&
                            <MutualConnect  connectDepth = {this.props.item.connectDepth}></MutualConnect>}*/}
                            {/*{this.props.item.connectStatus === "PENDING_CONNECT" ?
                                <>
                                    <a href="javascript:void(0)"
                                       className="follow follow-link active"><i className="iTickedUser" /> Pending</a>
                                </>
                                    : this.props.item && ( !this.props.item.connectDepth  ||( this.props.item.connectDepth  &&this.props.item.connectDepth < 1 ))&&
                            <div className="follbutt"><Follow followed={this.props.item.followed} userId={this.props.item.userId}
                                    deactivated={this.props.deactivated}/></div>
                            }*/}

                            {(this.props.item.connectDepth && this.props.item.userId != this.state.userId) ?
                                <MutualConnect  connectDepth = {this.props.item.connectDepth}></MutualConnect>:null}
                            {(this.props.item.connectDepth !== 1 && this.props.item.connectDepth !== 2) ? 
                                // this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === "PENDING_CONNECT" ?
                                // <>
                                //     <a href="javascript:void(0)"
                                //        className="follow follow-link active">
                                //         <NavLink  to="/connected"   data-tip="msg to show"  data-place='bottom' data-for={'Pending'+ this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                //     </a>
                                // </>
                                // :this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'REQUEST_RECEIVED' ?
                                //     <>
                                //         <a href="javascript:void(0)"
                                //            className="follow follow-link active">
                                //             <NavLink  to="/manage-invitation"   data-tip="msg to show"  data-place='bottom' data-for={'Pending'+ this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                //         </a>
                                //     </>
                                //     :this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                //         <></>

                                //         : this.props.item && ( !this.props.item.connectDepth  ||( this.props.item.connectDepth  &&this.props.item.connectDepth < 1 ))&&
                                //         <Follow followed={this.props.item.followed} userId={this.props.item.userId}
                                //                 deactivated={this.props.deactivated}/>
                                this.props.item && ( !this.props.item.connectDepth  ||( this.props.item.connectDepth  &&this.props.item.connectDepth < 1 )) &&
                                        <Follow followed={this.props.item.followed} userId={this.props.item.userId}
                                                deactivated={this.props.deactivated}/>
                            : null}

                        </div>
                        <div className="forumrowbright">
                            {new Date(this.props.item.startDate).toLocaleDateString('en-GB', {
                                year: 'numeric'
                            }).replace(/ /g, '-') === new Date().getFullYear().toString() ? <span className="pstdate">{currentTime<this.props.item.startDate ? <span style={{color:"#dc3545"}}>Scheduled on</span> : <span>Published on</span>} {new Date(this.props.item.startDate).toLocaleDateString('en-GB', {
                                day: '2-digit', month: 'short'
                            }).replace(/ /g, '-')}</span> : <span
                                className="pstdate">{currentTime<this.props.item.startDate ? <span style={{color:"#dc3545"}}>Scheduled on</span> : <span>Published on</span>} {new Date(this.props.item.startDate).toLocaleDateString('en-GB', {
                                day: '2-digit', month: 'short', year: 'numeric'
                            }).replace(/ /g, '-')}</span>}
                            {this.props.item && this.props.item.likesCount != null && <UserReactionComponent item={this.props.item} key={this.props.item.id} />}
                            <Link to={{pathname: `/pollDetail/${this.props.item.slug}`, state: {userId: this.state.userId}}} className="comments">
                                <span>{this.props.item.commentCount}&nbsp;
                                    <span>Comment{this.props.item.commentCount > 1 && "s"}</span>
                                </span>
                            </Link>
                            {(currentTime>this.props.item.startDate) &&
                            <div>
                                {/*<a href="javascript:void(0)" className="share"
                                   onClick={(e) => this.handleDetailPage(e, process.env.REACT_APP_domainUrl + '/pollDetail/' + this.props.item.slug + '/#share-open')}><span>{this.props.item.sharesCount}{"  "}Share</span></a>*/}
                                <Share
                                    shareUrl="/pollDetail/"
                                    entityId={this.props.item.id}
                                    entityType={"POLL"}
                                    slug={this.props.item.slug}
                                    shareId={this.props.item.id}
                                    sharesCount={this.props.item.sharesCount}/>
                            </div>}
                        </div>
                        <div className="modal fade dgpopup confirmpop"
                             id={"confirmpopForDeletePoll" + this.props.item.id + this.state.prefixId} tabIndex="-1"
                             role="dialog"
                             aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static"
                             data-keyboard="false">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>                                    
                                </div> */}
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-label="Close"><span
                                        aria-hidden="true">&times;</span></button>
                                    <div className="confirm-popup-modal-body">Are you sure you want to delete this poll?
                                    </div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-primary"
                                                onClick={() => this.deletePoll(this.props.item.id)}>Yes
                                        </button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contxmenu">
                        {!this.props.pageType && <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"><i className="iKebab"/></button>}
                        <div className="dropdown-menu fadeIn animated">                            
                            {this.props.item.userId === this.state.userId &&
                            <span className="dropdown-item" onClick={e => {
                                this.openDeleteModal(this.props.item.id)
                            }}><i className="iTrashBin"/> Delete</span>
                            }
                            {this.props.hideShow && !this.props.pin &&
                            <span className="dropdown-item" onClick={e => {
                                this.handleHideModal(this.props.item.id)
                            }}><i className="iHide" style={{fontSize: '13px'}} /> Hide</span>
                            }
                            {this.props.unHideShow &&
                            <span className="dropdown-item" onClick={e => {
                                this.handleUnHideModal(this.props.item.id)
                            }}><i className="iEye_F" style={{fontSize: '13px'}} /> UnHide</span>}
                            {this.props.item.userId !== this.state.userId &&
                            <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => {
                                e.preventDefault();
                                this.setState({openReportModal: this.props.item.id});
                            }}><i className="iReportComment_OL"/> Report</a>
                            }
                        </div>

                        {this.state.openReportModal && this.state.openReportModal === this.props.item.id &&
                            <ReportAbuse entityId={this.props.item.id}
                                         entityType={"POLL"}
                                         refreshAllData={this.refreshAllData}
                                         triggerReportClose={this.triggerReportClose}/>
                        }                        
                    </div>
                </div>
            </>
        );
    }
}

export default PollItem;
