import React from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "./commons/styles/quill.css";
import { authService } from "./auth";
import $ from "jquery";
import axios from "axios";
import * as _ from "lodash";
import stripHtml from "string-strip-html";
import httpService from "./AxiosInterceptors";
import ImageCompress from "quill-image-compress";
import { doubleHashTagsFunc, doubleUserTagsFunc, getCDNUrl, getOptimizedImageQuill, isMobileDevice, textToSpeech} from "./commons/CommonFunctions";
import AutoSuggest from "./AutoSuggest";
import QuillMention from "quill-mention";
import MagicUrl from "quill-magic-url";
import { connect } from "react-redux";
import { requestPostCreationFulfilled } from "./reducer/post";

if (isMobileDevice()) {
    import("./commons/styles/responsive-useragent.css").then(() => {
        console.log("Imported mobile css");
    });
}

let moduleToolBarFlag = true

httpService.setupInterceptors();

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/mentions", QuillMention);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

let hashTagModule;
let selectedHashtagsList = [];
let selectedUserTagList = [];

class EditArticlePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.Quillref = React.createRef();
        this.interval = null;
        let validation = {
            postText: {},
            titleText: {},
            hashTags: {},
        };
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            articleData: props.item,
            postText: props.item.description,
            titleText: props.item.title,
            hashTags: props.item.hashTags,
            errors: {},
            validation,
            isPostUpdating: false,
        };
    }

    mentionModule = {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@", "#"],
        source: function (searchTerm, renderList, mentionChar) {
            let values;
            let tagSuggestionArray = [];
            let userSuggestionArray = [];
            let postBody = {
                description: searchTerm,
                "privateEntityId": authService.getUserDetailsFromLocalStorage()?.entityId
            };
            if (mentionChar === "#") {
                hashTagModule = true;
                axios({
                    method: "post",
                    url: process.env.REACT_APP_userServiceURL + "/tags/recommended",
                    headers: { "Content-Type": "application/json" },
                    data: postBody,
                    withCredentials: true,
                })
                    .then((response) => {
                        if (
                            response &&
                            response.data &&
                            response.data.message === "Success!"
                        ) {
                            let hashTagsData = [];
                            if(selectedHashtagsList && response.data.body) {
                                hashTagsData = (response.data.body).filter((tag) => !(selectedHashtagsList).includes(tag));
                            } else {
                                hashTagsData = response.data.body;
                            }
                            hashTagsData &&
                                hashTagsData.map((tag, index) => {
                                    tagSuggestionArray.push({
                                        id: index,
                                        value: "# " + tag,
                                        link: `/explore-hashtag/${tag}`,
                                    });
                                });
                            values = tagSuggestionArray;
                            if (searchTerm.length === 0) {
                                renderList(values, searchTerm);
                            } else {
                                const matches = [];
                                for (let i = 0; i < values.length; i++)
                                    if (
                                        ~values[i].value
                                            .toLowerCase()
                                            .indexOf(searchTerm.toLowerCase())
                                    )
                                        matches.push(values[i]);
                                renderList(matches, searchTerm);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (mentionChar === "@") {
                hashTagModule = false;
                if (
                    authService.getUserDetailsFromLocalStorage() &&
                    authService.getUserDetailsFromLocalStorage().userId
                ) {
                    axios({
                        method: "get",
                        url:
                            process.env.REACT_APP_userServiceURL +
                            "/search/user/suggestions/" +
                            authService.getUserDetailsFromLocalStorage().userId +
                            "?query=" +
                            searchTerm + "&privateEntityId=" + this.state.userData?.entityId,
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }).then((response) => {
                        let usersDataList = [];
                        if(selectedUserTagList && response.data.body) {
                            usersDataList = (response.data.body).filter((user) => !(selectedUserTagList).includes(user.customUrl));
                        } else {
                            usersDataList = response.data.body;
                        }
                        usersDataList &&
                            usersDataList.map((user, index) => {
                                userSuggestionArray.push({
                                    id: user.customUrl,
                                    display: user.username,
                                    value: user.username,
                                    link: `/profile/${user.customUrl}`,
                                    secondaryHeader:
                                        user.addressDetail && user.addressDetail.country
                                            ? user.addressDetail.country +
                                            (user.personalInfo && user.personalInfo.persona
                                                ? ", " + user.personalInfo.persona
                                                : "")
                                            : user.personalInfo && user.personalInfo.persona
                                                ? user.personalInfo.persona
                                                : "",
                                    avatar:
                                        user.personalInfo && user.personalInfo.profileImage
                                            ? getCDNUrl(getOptimizedImageQuill(user.personalInfo))
                                            : user.type !== "COMPANY"
                                                ? "https://cdn.dscovr.com/images/users1.png"
                                                : "https://cdn.dscovr.com/images/DefaultBusiness.png",
                                    type: user.type
                                });
                                values = userSuggestionArray;
                                if (searchTerm.length === 0) {
                                    renderList(values, searchTerm);
                                } else {
                                    const matches = [];
                                    for (let i = 0; i < values.length; i++)
                                        if (
                                            ~values[i].value
                                                .toLowerCase()
                                                .indexOf(searchTerm.toLowerCase())
                                        )
                                            matches.push(values[i]);
                                    renderList(matches, searchTerm);
                                }
                            });
                    });
                }
            }
        },
        renderItem(item) {
            return !hashTagModule
                ? `
        <div class="mentionSuggestionsEntryContainer">
          <div class="mentionSuggestionsEntryContainerLeft">
            <img
              src=${getCDNUrl(item.avatar)}
              class="mentionSuggestionsEntryAvatar"
            />
          </div>
          <div class="mentionSuggestionsEntryContainerRight">
            <div className= {"mentionSuggestionsEntryText" + ${(item.type === "COMPANY" ? " orgnzName" : "")}}>
              ${item.display}
            </div>
            <div class="mentionSuggestionsEntryTitle">
              ${item.secondaryHeader}
            </div>
          </div>
        </div>
      `
                : `
      <span>${item.value}</span>
    `;
        },
    };

    modules = {
        toolbar: [
            [{ header: "2" }, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            moduleToolBarFlag ? ["link", "image", "video"] : ["link", "video"],
        ],
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            parchment: Quill.import("parchment"),
            handleStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
        },
        imageCompress: {
            quality: 0.7, // default
            //maxWidth: 750, // default
            //maxHeight: 500, // default
            imageType: "image/jpeg" + "image/png", // default
        },
        mention: this.mentionModule,
        magicUrl: {
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
            globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        },
    };

    componentDidMount() {
        window.$("input#colon").on({
            keydown: function (e) {
                if (e.key === "#") return false;
            },
            change: function () {
                this.value = this.value.replace(/\s/g, "");
            },
        });
        window.$(".dropdown-submenu > a").on("click", function (e) {
            var submenu = $(this);
            $(".dropdown-submenu .dropdown-menu").removeClass("show");
            submenu.next(".dropdown-menu").addClass("show");
            e.stopPropagation();
        });

        let imageTagLength = (this.props.item.description).match(/<img/gim);
        if(imageTagLength != null && imageTagLength.length >= 3) {
            window.$(".ql-image").css("display", "none");  
        } else {
            window.$(".ql-image").css("display", "inline-block");
        }
        this.Quillref.current.editor.root.setAttribute("spellcheck", "false");
    }

    handleTextChange = (content, delta, source, editor) => {
        let imageTagLength = content.match(/<img/gim);
        let descriptionContent = content.replace(/#\s/g, "#");
        if (imageTagLength != null && imageTagLength.length >= 3) {
            window.$(".ql-image").css("display", "none");
        } else {
            window.$(".ql-image").css("display", "inline-block");
        }
        this.setState({ postText: descriptionContent });

        selectedHashtagsList = doubleHashTagsFunc(content);
        selectedUserTagList = doubleUserTagsFunc(content);
    };

    onDelete(i) {
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);
        this.setState({ tags });
    }

    onAddition(tag) {
        const tags = [].concat(this.state.tags, tag);
        this.setState({ tags });
    }

    handleTagsChange = (hashTags) => {
        let hashTagsList = [];
        if (hashTags && hashTags.length > 0) {
            hashTags &&
                hashTags.map(function (data) {
                    data = data.replace(
                        /[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi,
                        ""
                    );
                    if (data != "") {
                        hashTagsList.push(data);
                    }
                });
            let allHashTagsList = hashTagsList;
            hashTagsList = [...new Set(allHashTagsList)];
        }
        this.setState({ hashTags: hashTagsList });
    };

    handleTitleChange = (event) => {
        this.setState({ titleText: event.target.value });
    };

    componentWillUnmount() {

    }

    validateForm = () => {
        this.state.validation.postText = {};
        this.state.validation.titleText = {};
        let errors = {};
        let formIsValid = true;
        if (
            _.isUndefined(this.state.postText) ||
            _.isEmpty((this.state.postText || "").toString()) ||
            _.isNull(this.state.postText)
        ) {
            errors["postText"] = "Please enter the description";
            this.state.validation.postText = {
                error: true,
                style: { borderColor: "#b34c4c" },
            };
            formIsValid = false;
        } else {
            var regex = /\s+/gi;
            this.state.validation.postText = {};
            if (
                stripHtml(this.state.postText).trim().replace(regex, " ").split(" ")
                    .length < 250
            ) {
                errors["postText"] =
                    "A article must have minimum 250 words. Use Post as to share smaller content.";
                this.state.validation.postText = {
                    error: true,
                    message:
                        "A article must have minimum 250 words. Use Post as to share smaller content.",
                    style: { borderColor: "#b34c4c", padding: "10px" },
                };
                formIsValid = false;
            }
        }
        if (
            _.isUndefined(this.state.titleText) ||
            _.isEmpty((this.state.titleText || "").toString()) ||
            _.isNull(this.state.titleText) ||
            this.state.titleText.trim() === ""
        ) {
            errors["titleText"] = "Please enter the title";
            this.state.validation.titleText = {
                error: true,
                style: { borderColor: "#b34c4c" },
            };
            formIsValid = false;
        }
        if (!this.state.hashTags || this.state.hashTags.length < 1) {
            errors["hashTags"] = "Please enter atleast one hashtag ";
            formIsValid = false;
        }
        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    hideUpdateModal = (updateFlag) => {
        let updatedArticleData = { 
            "title":  updateFlag ? this.state.titleText : this.props.item.title,
            "description": updateFlag ? this.state.postText : this.props.item.description,
            "hashTags": updateFlag ? this.state.hashTags : this.props.item.hashTags
        }
        this.setState({
            articleData: {},
        }, () => {
            window.$("#editArticlePopUp").modal("hide");
            this.props.closeEditModal(updatedArticleData);
        })
    }

    handlePostUpdate = () => {
        if (this.validateForm()) {
            this.setState({ isPostUpdating: true });
            let postData = {
                postId: this.props.item.id,
                description: this.state.postText,
                articleTitle: this.state.titleText,
                hashTags: this.state.hashTags
                    ? this.state.hashTags.map((item) => {
                        return item.replace(/#/g, "");
                    })
                    : [],
            };
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/post/update`,
                data: postData,
                withCredentials: true,
            })
                .then((response) => {
                    if (response && response.data && response.data.message === "Success!") {
                        this.setState({
                            isPostUpdating: false,
                        },() => {
                            textToSpeech(this.props.item.id, this.state.postText);
                            this.hideUpdateModal(true);  
                        });
                        this.props.requestPostCreationFulfilled({success: true});
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setTimeout(() => {
            this.setState({
                error: "",
                errors: {},
            });
        }, 5000);
    };

    render() {
        return (
            <div id="editArticlePopUp" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="blogpostwrap">
                                <div className="toppostinn">
                                    <div className="topposthead clearfix">
                                        <i className="iBlogico">
                                            <img
                                                src="https://cdn.dscovr.com/images/blog-post-ico.webp"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                        "https://cdn.dscovr.com/images/blog-post-ico.png";
                                                }}
                                                alt=""
                                            />
                                        </i>
                                    </div>
                                    <div className="blogpostbody">
                                        <div className="blogpostcontwrap">
                                            <input
                                                type="text"
                                                value={this.state.titleText}
                                                onChange={this.handleTitleChange}
                                                placeholder={"Add Title"}
                                            />
                                            <span
                                                className="login-feedback-error mt-1"
                                                style={{ display: "block" }}
                                            >
                                                {this.state.errors.titleText}
                                            </span>

                                            <div className="reactQuillWrap">
                                                <ReactQuill
                                                    value={this.state.postText}
                                                    onChange={this.handleTextChange}
                                                    modules={this.modules}
                                                    ref={this.Quillref}
                                                    //formats={formats}
                                                    placeholder="Write something here..."
                                                />
                                                <div className="blogMedinfo">
                                                    <a
                                                        href="/media-info"
                                                        title="Media Info"
                                                        className="btninfo"
                                                        target="_blank"
                                                    >
                                                        <i className="iInfo_I" />
                                                    </a>
                                                </div>
                                            </div>
                                            <span
                                                className="login-feedback-error mt-1"
                                                style={{ display: "block" }}
                                            >
                                                {this.state.errors.postText}
                                            </span>
                                            <div className="tagwrap">
                                                <div className="tagfield">
                                                    <AutoSuggest
                                                        hashTags={this.state.hashTags}
                                                        onDelete={this.onDelete}
                                                        onAddition={this.onAddition}
                                                        handleTagsChange={this.handleTagsChange}
                                                    />
                                                    <span
                                                        className="login-feedback-error mt-1"
                                                        style={{ display: "block" }}
                                                    >
                                                        {this.state.errors.hashTags}
                                                    </span>
                                                </div>
                                            </div>
                                            {this.state.error && this.state.error !== "" && (
                                                <span className="login-feedback-error">
                                                    {this.state.error}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="bottpostpopbody">
                                        <div className="bottpostpopright">
                                            <a href="javascript:void(0)" className="btn cancel"
                                                onClick={() => this.hideUpdateModal(false)}>Cancel</a>
                                            <input type="submit" onClick={this.handlePostUpdate}
                                                className="btn"
                                                value={this.state.isPostUpdating ? "Loading..." : "UPDATE"}
                                                disabled={this.state.isPostUpdating} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(EditArticlePopUp);
