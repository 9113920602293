import React, {Component} from 'react';
import {authService} from "../../../auth";
import Header from "../../../Header";
import LeftPanel from "../../../LeftPanel";
import axios from "axios";
import OtherCauses from "./OtherCauses";
import CausePostNavbar from "../../../cause/CausePostNavbar";
import stripHtml from "string-strip-html";
import BypassHeader from '../../../BypassHeader'
import '../../../explore/landing.min.css';
import "../../../explore/responsive-landing.min.css";
import "../../../explore/main-explore.min.css";
import UnauthShare from '../../../commons/UnauthShare';
import AuthModalComponent from '../../../LoginExtra/AuthModalComponent';
import SignUpModal from "../../../SignUpModal/signUpModal";
import NotFound from "../../../404";
import uuid from "react-uuid";
import {Link, NavLink} from "react-router-dom";
import httpService from "../../../AxiosInterceptors";
import LeftPanelUnauth from "../../../commons/LeftPanelUnauth";
import { getCDNUrl } from '../../../commons/CommonFunctions';
import UserReactionComponent from '../../../UserReaction/UserReactionComponent';

var dateFormat = require('dateformat');

httpService.setupInterceptors();

class CauseDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            causeDetail: '',
            item: {},
            successMessge: false,
            loginRequired: false,
            notFoundstatusCode: ''
        }
    }

    componentDidMount() {            
        this.getCauseDetails();
    }

    getNavBar() {
        if (this.state.userData && this.state.userData.userId) {
            if (this.state.causeDetail.id) {
                return <CausePostNavbar slug={this.state.causeDetail.slug} causeId={this.state.causeDetail.id}
                                        userId={this.state.userData.userId} history={this.props.history}
                                        joined={this.state.causeDetail.joined} />
            }
        } else {
            if (this.state.causeDetail.id) {
                return <CausePostNavbar slug={this.state.causeDetail && this.state.causeDetail.slug}
                                        causeId={this.state.causeDetail.id} history={this.props.history}
                                        joined={this.state.causeDetail.joined} />
            }
        }
    }
    loginRequired=(slug)=> {
        this.setState({'loginRequired': true, 'pathname':slug})
        window.$('#loginModal').modal('show');
    }
    handleLoginClose = () => {
        this.setState({'loginRequired': false})
        window.$('#loginModal').modal('hide');
    }

    signUpRequired=()=>{
        this.setState({'signUpRequired':true})
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({'signUpRequired': false})
        window.$('#signupModal').modal('hide');
    }

    getCauseDetails() {
        let url;
        if (this.state.userData && this.state.userData.userId) {
            url = '/backend/public/cause/getBySlug?slug=' + this.props.match.params.id + '&userId=' + this.state.userData.userId
        } else {
            url = '/backend/unauth/cause/getBySlug?slug=' + this.props.match.params.id
        }
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + url,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                this.setState({'causeDetail': response.data.body});
                document.title = response.data.body.name
                this.getBlogDetailPage();
            }
        }).catch((err) => {
            if (err && err.response && err.response.status) {
                this.setState({
                    'notFoundstatusCode': err.response.status
                })
            }
        });
    };


    removeCause(event) {
        event.preventDefault();
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/cause/delete?id=' + this.state.causeDetail.id + '&userId=' + this.state.userData.userId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.getCauseDetails();

            }
        }).catch((err) => {
            console.log(err);
        });
    };

    handleEvent = (event) => {
        if (this.state.causeDetail.joined) {
            this.removeCause(event);
        } else {
            this.handleSubmit(event);
        }
    };

    handleSubmit(event) {
        event.preventDefault();
        let selectedIds = [];
        selectedIds.push(this.state.causeDetail.id);

        let postBody = {
            "userId": this.state.userData.userId,
            "causeId": selectedIds
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/cause/join',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response, dispatch) => {
            let res = response.data;
            if (res.status === '201 CREATED') {
                this.getCauseDetails();
            }
        }).catch((err) => {

        });

    };

    getBlogDetailPage() {
        let url;
        if (this.state.userData && this.state.userData.userId) {
            url = '/backend/explore_service/api/explore_blog/getById?id=' + this.state.causeDetail.linkIds[0] + '&userId=' + this.state.userData.userId
        } else {
            url = '/backend/unauth/explore_blog/getById?id=' + this.state.causeDetail.linkIds[0]
        }
        if (this.state.causeDetail.linkIds && this.state.causeDetail.linkIds.length > 0) {
            axios({
                method: 'get',
                url: process.env.REACT_APP_userServiceURL + url,
                withCredentials: true
            }).then(response => {
                if (response && response.data.body !== null) {
                    this.setState({
                        "item": response.data.body,
                        "successMessge": true
                    });
                }
            });
        }
    }


    getElementsFromList(list) {

        var str = "";
        for (var each in list) {
            str += "#" + list[each] + " ";
        }
        return str;
    }

    getFormattedDate(milliseconds) {
        var utcSeconds = milliseconds;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);

        if (isNaN(d) === false) {
            return dateFormat(d, "d-mmm-yyyy");
        }
        return "";
    }

    handleDetailPage(evt, news_url) {
        evt.preventDefault();

        let userAgent = navigator.userAgent
        let mobileandroid = /wenat_android/.test(userAgent)
        let mobileios = /wenat_ios/.test(userAgent)

        if (news_url !== undefined && news_url !== null) {
            if (mobileios || mobileandroid) {
                window.location.href = news_url;
            } else {
                window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
            }
        }

        //alert(news_url);
        // if (news_url !== undefined && news_url !== null) {
        //     window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        // }
    }

    getStripData(str, max) {
        if (str != null && str != undefined) {
            str = stripHtml(str);
            if (str === undefined || str === null)
                return "";

            var n = str.length;
            if (max > n) {
                return str;
            }

            var res = str.substring(0, max);
            res += "...";
            return res;
        }
    }

    handleDetailPageShare(evt, news_url) {
        evt.preventDefault();

        let userAgent = navigator.userAgent
        let mobileandroid = /wenat_android/.test(userAgent)
        let mobileios = /wenat_ios/.test(userAgent)

        if (news_url !== undefined && news_url !== null) {
            if (mobileios || mobileandroid) {
                window.location.href = news_url;
            } else {
                window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
            }
        }

        //alert(news_url);
        // if (news_url !== undefined && news_url !== null) {
        //     window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        // }
    }


    render() {
        if (!this.state.loginSuccess) {
            return <div
                className={this.state.notFoundstatusCode === 404 ? "wrapper projectpage profilepage nologinpage p404" : "wrapper projectpage profilepage nologinpage"}>
                <BypassHeader notFound={this.state.notFoundstatusCode === 404 ? true : false}
                              display={this.state.notFoundstatusCode === 404 ? true : false}/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        {this.state.notFoundstatusCode === 404 ? <NotFound/> : <div className="row">
                            <LeftPanelUnauth signUpRequired={this.signUpRequired}/>
                            <div className="col-md-9">
                                <div className="projectinn">

                                    <div className="profActivityWrap causefeedwrap">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mainpanel">
                                                    <div className="causefeedbanner">
                                                        <div className="causefeedbannerinn">
                                                            <a data-fancybox={uuid()}
                                                               href={this.state.causeDetail.imageUrl} alt="">
                                                                {
                                                                    this.state.causeDetail &&
                                                                    <img src={getCDNUrl(this.state.causeDetail.imageUrl)} alt=""/>
                                                                }
                                                            </a>
                                                            {/* <a href="javascript:void(0)" onClick={this.handleEvent} className={this.state.causeDetail && this.state.causeDetail.joined?'support btn active':'support btn'} >{this.state.causeDetail.joined?  "Supporting" : "Support Cause"}</a> */}
                                                        </div>
                                                        <div className="captionblk">
                                                            <h1>{this.state.causeDetail && this.state.causeDetail.name}</h1>
                                                            <div className="capbott">
                                                                <div className="title">
                                                                    {this.state.causeDetail.hashtag &&
                                                                    Object.values(this.state.causeDetail.hashtag).map((val, idx) => {
                                                                        return <span className="hashLinkPost">{val.replace(/\s/g, '')}</span>
                                                                        {/* idx <= 2 &&*/
                                                                        }

                                                                    })
                                                                    }

                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                        <>
                                                            {window.location.href.indexOf('share-open') !== -1 ?
                                                                this.props.match.params && this.props.match.params.id &&
                                                                <UnauthShare shareUrl={"/cause/"}
                                                                             entityId={this.state.causeDetail.id}
                                                                             shareId={this.props.match.params.id}
                                                                             shareOpen='true'/> :
                                                                this.state.item && this.props.match.params.id &&
                                                                <UnauthShare shareUrl={"/cause/"}
                                                                             entityId={this.state.causeDetail.id}
                                                                             shareId={this.props.match.params.id}/>}
                                                        </>
                                                    </div>
                                                    <div>

                                                        {this.state.successMessge &&
                                                        <div className=" explorepage explLatarticle clearfix"
                                                             style={{marginBottom: '20px'}}>
                                                            <div className="explstorieslist">

                                                                <div className="explstoriesdesc" style={{minHeight: 'inherit'}}>
                                                                    <h3>
                                                                        <a onClick={(e) => this.handleDetailPage(e, "/explore-detail/blog/" + this.state.item.slug)}
                                                                           href="#">{this.state.item.title}</a>
                                                                    </h3>

                                                                    <div className="descinn">
                                                                        <p>{this.getStripData(this.state.item.content, 195)}</p>
                                                                        <a onClick={(e) => this.handleDetailPage(e, "/explore-detail/blog/" + this.state.item.slug)}
                                                                           href="#"> Know More</a>
                                                                    </div>

                                                                </div>
                                                                <div className="postbookMbott">
                                                                    <div>
                                                                        {
                                                                            /*
                                                                             <a href="" className="like active">201</a>
                                                                             <a href="" className="comments">503</a>
                                                                            */
                                                                        }

                                                                    </div>
                                                                    <div className="pbMtime">Published
                                                                        on {this.getFormattedDate(this.state.item.publishedTime)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    {this.getNavBar()}

                                                    <div className="bkbackdrop2">
                                                        <div className="unauthLoginPop">
                                                            <div className="unauthLoginPopdesc">
                                                                <a href="javascript:void(0)" onClick={this.loginRequired.bind(this)} className="bkloginBtn"><i className="iArrow-Up"/>You must <span>login</span> to explore more</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <OtherCauses id={this.props.match.params.id}  /> */}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>}
                    </div>
                </main>
                
                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname} history={this.props.history}/> : null}
                {this.state.signUpRequired === true ? <SignUpModal  handleSignUpClose={this.handleSignUpClose} history={this.props.history}/> : null}
            </div>
        } else {
            return (
                <div className="wrapper projectpage profilepage">
                    <Header EditedText={true}/>
                    <main className="dgcontainer hm3col">
                        <div className="container">
                            {this.state.notFoundstatusCode === 404 ? <NotFound/> :
                                <div className="row">
                                    <LeftPanel/>
                                    <div className="col-md-9">
                                        <div className="projectinn">

                                            <div className="profActivityWrap causefeedwrap">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="mainpanel">
                                                            <div className="causefeedbanner">
                                                                <div className="causefeedbannerinn">
                                                                    <a data-fancybox={uuid()}
                                                                       href={this.state.causeDetail.imageUrl} alt="">
                                                                        {
                                                                            this.state.causeDetail &&
                                                                            <img src={getCDNUrl(this.state.causeDetail.imageUrl)}
                                                                                  alt=""/>
                                                                        }
                                                                    </a>
                                                                    <a href="javascript:void(0)"
                                                                        onClick={this.handleEvent}
                                                                        className={this.state.causeDetail && this.state.causeDetail.joined ? 'support btn active' : 'support btn'}>{this.state.causeDetail.joined ? "Supporting" : "Support Cause"}</a>
                                                                </div>
                                                                <div className="captionblk">
                                                                    <h1>{this.state.causeDetail && this.state.causeDetail.name}</h1>
                                                                    <div className="capbott">
                                                                        <div className="title">
                                                                            {this.state.causeDetail.hashtag &&

                                                                            Object.values(this.state.causeDetail.hashtag).map((val, idx) => {
                                                                                return <span className="hashLinkPost">{val.replace(/\s/g, '')}</span>
                                                                            })
                                                                            }
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>
                                                                <>
                                                                    {window.location.href.indexOf('share-open') !== -1 ?
                                                                        this.props.match.params && this.props.match.params.id &&
                                                                        <UnauthShare shareUrl={"/cause/"}
                                                                                     entityId={this.state.causeDetail.id}
                                                                                     shareId={this.props.match.params.id}
                                                                                     shareOpen='true'/> :
                                                                        this.state.item && this.props.match.params.id &&
                                                                        <UnauthShare shareUrl={"/cause/"}
                                                                                     entityId={this.state.causeDetail.id}
                                                                                     shareId={this.props.match.params.id}/>}
                                                                </>
                                                            </div>
                                                            <div>

                                                                {this.state.successMessge &&
                                                                <div className=" explorepage explLatarticle clearfix"
                                                                     style={{marginBottom: '20px'}}>
                                                                    <div className="explstorieslist">

                                                                        <div className="explstoriesdesc" style={{minHeight: 'inherit'}}>
                                                                            <h3>
                                                                                <a onClick={(e) => this.handleDetailPage(e, "/explore-detail/blog/" + this.state.item.slug)}
                                                                                   href="#">{this.state.item.title}</a>
                                                                            </h3>

                                                                            <div className="descinn">
                                                                                <p>{this.getStripData(this.state.item.content, 195)}
                                                                                </p>
                                                                                <a onClick={(e) => this.handleDetailPage(e, "/explore-detail/blog/" + this.state.item.slug)}
                                                                                   href="#"> Know More</a>
                                                                            </div>

                                                                        </div>
                                                                        <div className="postbookMbott">
                                                                            <div>
                                                                                {
                                                                                    /*
                                                                                     <a href="" className="like active">201</a>
                                                                                     <a href="" className="comments">503</a>
                                                                                    */
                                                                                }
                                                                                <UserReactionComponent item={this.state.item} key={this.state.item.id} />
                                                                                <a href="javascript:void(0)"
                                                                                   className="share"
                                                                                   onClick={(e) => this.handleDetailPageShare(e, process.env.REACT_APP_domainUrl + '/explore-detail/blog/' + this.state.item.slug + '/#share-open')}/>
                                                                            </div>
                                                                            <div className="pbMtime">Published
                                                                                on {this.getFormattedDate(this.state.item.publishedTime)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                            {this.getNavBar()}

                                                        </div>
                                                    </div>
                                                    {this.state.causeDetail && this.state.causeDetail.id &&
                                                    <OtherCauses causeId={this.state.causeDetail.id} supportedUserIds={this.state.causeDetail.supportedUserIds}/>
                                                    }


                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </main>
                </div>
            );
        }
    }
}


export default CauseDetail;

