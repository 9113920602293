import React from 'react'
import Header from '../Header';
import LeftPanelSettings from './LeftPanelSettings';
import {Redirect} from 'react-router-dom';
import {authService} from '../auth';
import NotificationSettingDetails from "./NotificationSettingDetails";
import axios from "axios";

class NotificationSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            localStorageData: authService.getUserDetailsFromLocalStorage(),
            userId: authService.getUserDetailsFromLocalStorage()?.userId
        };
    }

    componentDidMount() {
        window.$(".feqtopsearch").sticky({topSpacing: 65, responsiveWidth: true});
        document.title = "Explore a new world of Naturalists | WeNaturalists";
    }

    render() {
        if (!this.state.loginSuccess) {
            return <Redirect to='/login'/>
        } else {
            return <div className="wrapper settingspage">

                <Header/>
                <main className="dgcontainer hm3col">
                    <div className="container">

                        <div className="row">

                            <LeftPanelSettings />
                            <NotificationSettingDetails userId={this.state.userId} />

                        </div>
                    </div>
                </main>

            </div>
        }
    }
}

export default NotificationSetting
