import React from "react";
import axios from "axios";
import { authService } from "../auth";
import { cloneDeep } from "lodash";
import { blockUserMessage, getCDNUrl, getFileIcon } from "./CommonFunctions";
import MentionTextArea from "./MentionTextArea";
import Picker from "emoji-picker-react";

const maxMbFileSize = 100 * 1024 * 1024; // 100MB

class AddReplyCommentBox extends React.Component {
  constructor(props) {
    super(props);
    let validation = {
      commentText: {},
    };
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      userComment: "",
      validation,
      errors: {},
      isCommentSubmitted: false,
      replyBoxClose: false,
      SmileDisplay: false,
    };
  }

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  handleCommentTyped = (event) => {
    this.setState({ userComment: event.target.value.trimStart() });
  };

  // validate comment
  validate_comment = () => {
    this.state.validation.commentText = {};
    let formIsValid = true;
    let errors = {};
    if (
      this.state.userComment === "" &&
      (this.state.commentImageAttachments === undefined ||
        this.state.commentImageAttachments.length === 0)
    ) {
      errors["commentText"] = "Please write comment to publish";
      let error = {
        error: true,
        message: "Please write comment to publish",
        style: { borderColor: "#b34c4c", padding: "10px" },
      };
      this.setState({
        validation: error,
      });
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleCommentSubmit = () => {
    if (this.validate_comment()) {
      const formData = new FormData();

      this.setState({ isCommentSubmitted: true });

      let params = {
        userId: this.state.userData.userId,
        activityId: this.props.commentId,
        userComment: this.state.userComment,
        commentType: "REPLY",
        privateEntityId : this.state.userData && this.state.userData.entityId
      };

      if (
        this.state.commentAttachments &&
        this.state.commentAttachments.length > 0
      ) {
        this.state.commentAttachments.forEach((file) => {
          formData.append("files", file);
        });
      }

      if (
        this.state.commentImageAttachments &&
        this.state.commentImageAttachments.length > 0
      ) {
        this.state.commentImageAttachments.forEach((file) => {
          formData.append("files", file);
        });
      }

      formData.append("data", JSON.stringify(params));

      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/backend/comment/create",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            if (typeof this.props.getAllComments == "function") {
              this.props.getAllComments(0, 1000);
            }
            if (this.props.addHideReplyCommentBox) {
              this.props.addHideReplyCommentBox();
            }
            this.setState({
              SmileDisplay: false,
              isCommentSubmitted: false,
              userComment: "",
              commentAttachments: [],
            });
          } else {
            console.log(response);
            this.setState({ isCommentSubmitted: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isCommentSubmitted: false });
          if(err?.response?.data?.status === "409 CONFLICT") {
              blockUserMessage("MESSAGE", err.response.data.message);
          }
        });
    }
    setTimeout(() => {
      this.setState({
        error: "",
        validation: {},
        errors: {},
      });
    }, 5000);
  };

  commentAttachmentsUpload = () => {
    this.refs.commentAttachmentsUploader.click();
  };

  removeAttachments = () => {
    this.setState({
      commentAttachments: [],
    });
  };

  onCancelImage(event, value) {
    event.preventDefault();
    let attachs = cloneDeep(this.state.commentAttachments);
    attachs.forEach((data) => {
      let index = attachs.indexOf(value);
      if (index !== -1) {
        attachs.splice(index, 1);
      }
    });
    this.setState({ commentAttachments: attachs });
  }

  //   comment reply image upload click
  commentimageUpload = () => {
    this.refs.commentImageUploader.click();
  };

  onCancelImageAttach(event, value) {
    event.preventDefault();
    let attachs = cloneDeep(this.state.commentImageAttachments);
    attachs.forEach((data) => {
      let index = attachs.indexOf(value);
      if (index !== -1) {
        attachs.splice(index, 1);
      }
    });
    this.setState({ commentImageAttachments: attachs });
  }

  // Upload only image attachement for comment
  uploadImageAttachments = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let commentAttachments = cloneDeep(this.state.commentImageAttachments);
      let commentAttachmentsPreviewList = cloneDeep(
        this.state.commentImageAttachmentsPreviewList
      );

      let filesObj = commentAttachments ? commentAttachments : [];
      let filesArr = commentAttachmentsPreviewList
        ? commentAttachmentsPreviewList
        : [];
      let fileSize = 0;
      for (let i of e.target.files) {
        const allowedAttachmentsTypeArr = ["jpg", "jpeg", "png", "gif"];
        const fileName = i.name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (!allowedAttachmentsTypeArr.includes(extFile)) {
          this.setState({
            error:
              "Only " +
              allowedAttachmentsTypeArr.join("/") +
              " files are allowed!",
          });
          document.getElementById("commentFileUploader").value = null;
        } else {
          filesObj.push(i);
          filesArr.push(URL.createObjectURL(i));
          fileSize += i.size;
        }
      }

      if (fileSize > maxMbFileSize) {
        this.setState({
          error:
            "Uploaded files exceeds limit of 100 MB. Replace/Remove some files",
        });
        document.getElementById("commentFileUploader").value = null;
      } else {
        this.setState({
          commentImageAttachments: filesObj,
          commentImageAttachmentsPreviewList: filesArr,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 5000);
  };

  // Upload secondary attachement (eg. pdf,csv,ppt,doc)
  uploadCommentAttachments = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let commentAttachments = cloneDeep(this.state.commentAttachments);
      let commentAttachmentsPreviewList = cloneDeep(
        this.state.commentAttachmentsPreviewList
      );

      let filesObj = commentAttachments ? commentAttachments : [];
      let filesArr = commentAttachmentsPreviewList
        ? commentAttachmentsPreviewList
        : [];
      let fileSize = 0;

      for (let i of e.target.files) {
        const fileName = i.name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        const allowedAttachmentsTypeArr = [
          "csv",
          "xlsx",
          "xls",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "txt",
          "pdf",
        ];
        if (!allowedAttachmentsTypeArr.includes(extFile)) {
          this.setState({
            error:
              "Only " +
              allowedAttachmentsTypeArr.join("/") +
              " files are allowed!",
          });
          document.getElementById("commentFileUploader").value = null;
        } else {
          filesObj.push(i);
          filesArr.push(URL.createObjectURL(i));
          fileSize += i.size;
        }
      }
      if (fileSize > maxMbFileSize) {
        this.setState({
          error:
            "Uploaded files exceeds limit of 100 MB. Replace/Remove some files",
        });
        document.getElementById("commentFileUploader").value = null;
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 5000);
      } else {
        this.setState({
          commentAttachments: filesObj,
          commentAttachmentsPreviewList: filesArr,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 5000);
  };

  componentDidMount() {
    window
      .$(".commenttype .form-control, .commenttypeBtnsInn")
      .on("click", function (event) {
        window.$(".commenttype").addClass("active");
      });
    window.$(document).on("click", function (event) {
      if (!window.$(event.target).closest(".commenttype form").length) {
        window.$(".commenttype").removeClass("active");
      }
    });

    //window.$(".textarea-scrollbar").scrollbar();

    document.querySelector(".wrapper").addEventListener("click", event => {
      // Get parent element and check if click happened outside parent only
      const parent = document.querySelector(".emoji-picker-react");
      const { SmileDisplay } = this.state;
      if (SmileDisplay && !parent.contains(event.target)) {
        this.onToggleEmojiWindow();
      }
    });
  }

  //Smile change handler
  handleSmileDisplay = () => {
    this.setState({
      SmileDisplay: this.state.SmileDisplay ? false : true,
    });
  };

  onToggleEmojiWindow = () => {
    const { SmileDisplay } = this.state;
    this.setState({ SmileDisplay: !SmileDisplay });
  };

  onEmojiClick = (event, emojiObject) => {
    let userComment = cloneDeep(this.state.userComment);
    userComment = userComment + " " + emojiObject.emoji;
    this.setState({
      userComment: userComment,
    });
  };

  // Display preview image
  displayPreview = (file) => {
    if (file) {
      var binaryData = [];
      binaryData.push(file);

      return URL.createObjectURL(
        new Blob(binaryData, { type: "application/zip" })
      );
    }
  };

  render() {
    return (
      <div id="commentbox" className="commenttype">
        <figure className="userlogo">
          <img
            src={
              this.state.userData.profileImage
                ? getCDNUrl(this.getOptimizedImage(this.state.userData))
                : this.state.userData.type !== "COMPANY"
                ? "https://cdn.dscovr.com/images/users1.png"
                : "https://cdn.dscovr.com/images/DefaultBusiness.png"
            }
            alt=""
          />
        </figure>
        <form action="">
          <div className="commTextareaWrap">
            <MentionTextArea
              className="form-control textarea-scrollbar scrollbar-outer"
              value={this.state.userComment}
              onChange={this.handleCommentTyped}
              placeholder="Reply..."
            ></MentionTextArea>
            <span
              className="login-feedback-error mt-1"
              style={{ display: "block" }}
            >
              {this.state.errors.commentText}
            </span>
            <div className="attachImgall">
              {this.state.commentImageAttachments &&
                this.state.commentImageAttachments.map((attachment, index) => {
                  return (
                    <span className="pdescattachfile" key={index}>
                      <img src={this.displayPreview(attachment)} />
                      <i
                        className="iCross_Rounded"
                        onClick={(e) => this.onCancelImageAttach(e, attachment)}
                      />
                    </span>
                  );
                })}
            </div>
            <div className="attachImgall"></div>
          </div>
          {this.state.SmileDisplay && (
            <Picker
              pickerStyle={{ width: "100%", height: "200px" }}
              onEmojiClick={this.onEmojiClick}
            />
          )}
          <div className="commenttypeBtns">
            <div className="commenttypeBtnsInn">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleCommentSubmit();
                }}
                disabled={this.state.isCommentSubmitted}
                className="butt"
              >
                <i className="iSend_fl" />
              </button>
              <a
                href="javascript:void(0)"
                onClick={this.commentAttachmentsUpload}
                className="attchbtn"
              >
                <i className="iClip" />
              </a>
              <a
                href="javascript:void(0)"
                onClick={this.commentimageUpload}
                className={"attchbtn"}
              >
                <i className="iImg" />
              </a>
              <a
                href="javascript:void(0)"
                className="attchbtn"
                onClick={this.handleSmileDisplay}
              >
                <i className="iEmoji" />
              </a>
            </div>
          </div>
          <input
            type="file"
            multiple
            accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,image/*,audio/*,video/*"
            id="commentFileUploader"
            onChange={this.uploadCommentAttachments}
            ref="commentAttachmentsUploader"
            style={{ display: "none" }}
          />
          <input
            type="file"
            multiple
            accept="image/*"
            id="commentImageUploader"
            onChange={this.uploadImageAttachments}
            onClick={(event) => {
              event.target.value = null;
            }}
            ref="commentImageUploader"
            style={{ display: "none" }}
          />
          {this.state.commentAttachments &&
            this.state.commentAttachments.map((attachment, index) => {
              return (
                <span className="pdescattachfile" key={index}>
                  {getFileIcon(attachment.name)} {attachment.name}
                  <i
                    className="iCross_Rounded"
                    onClick={(e) => this.onCancelImage(e, attachment)}
                  />
                </span>
              );
            })}
          {this.state.error && this.state.error !== "" && (
            <div className="forumcommtvlistbott">
              <div className="fattach" style={{ color: "red" }}>
                {this.state.error}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default AddReplyCommentBox;
