import React, { Component } from "react";
import { authService } from "../auth";
import Select from 'react-select';
import axios from "axios";
import "./donation-subscription.min.css";
import { getCDNUrl } from "../commons/CommonFunctions";
import * as _ from "lodash";
import { userSubscriptionError, getFormattedDate } from "./DonationCommonFunction";
import { withRouter } from "react-router-dom";

class DonationSubscriptionItemList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            donationDetails: [],
            bankDataList: [],
            entitySearchData: "",
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : !_.isEmpty(this.props.donateNowConfig) && this.props.donateNowConfig?.superAdminId ? this.props.donateNowConfig?.superAdminId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    validateForm = () => {
        let seletedData = this.state.donationDetails && this.state.donationDetails.filter((entity) => entity.isChecked);

        if(seletedData.length === 0) {
            userSubscriptionError("ENTITYLIST")
        }
    }

    handlePublishDonationList = () => {
        let seletedData = this.state.donationDetails && this.state.donationDetails.filter((entity) => entity.isChecked);
        if(seletedData.length > 0) {
            let postData = {
                userId: this.state.userId,
                entityType: this.props.donationDetails?.type,
                privateEntityId: this.state.userData?.entityId
            }
            let entityList = [];
            this.state.donationDetails && this.state.donationDetails.map((item) => {
                if(item.isChecked) {
                    entityList.push({"entityId": item.entityId, "bankId": item.selectedBank && item.selectedBank.value})
                }
            })
            postData.entityList = entityList;
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_userServiceURL}/user/subscription/donation/create`,
                headers: { 'Content-Type': 'application/json' },
                data: postData,
                withCredentials: true
            }).then(response => {
                if (response && response.data && response.data.body && response.data.message === 'Success!') {
                    this.props.onSuccessDonationEntitySubmit(response.data.body);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            userSubscriptionError("ENTITYLIST", null, this.props.donationDetails?.type)
        }
    }
    onCloseProjectListDateSelect = () => {
        window.$("#sDateProjectListPop").modal("hide");
    }

    onClickDonationTopup = () => {
        window.$("#donationTopUpPop").modal("show");
    }

    checkedEntityItem = (event) => {
        let donationDetailsList = this.state.donationDetails;
        let checkedItemLength = donationDetailsList && donationDetailsList.filter((data) => data.isChecked).length;
        if((checkedItemLength < this.props.donationDetails?.totalAvailable) || !event.target.checked) {
            let index = donationDetailsList && donationDetailsList.findIndex((data) => data.entityId === event.target.id);
            if(index > -1) {
                donationDetailsList[index].isChecked = event.target.checked;
            }
            this.setState({
                donationDetails: donationDetailsList
            })
        } else {
            userSubscriptionError("MAX_ENTITY", this.props.donationDetails?.totalAvailable, this.props.donationDetails?.type);
        }
        
    }

    onChangeBankDetails = (id, value) => {
        let donationDetailsList = this.state.donationDetails;
        let index = donationDetailsList && donationDetailsList.findIndex((data) => data.entityId === id);
        if(index > -1) {
            donationDetailsList[index].selectedBank = value;
        }
        this.setState({
            donationDetails: donationDetailsList
        })
    }

    getEntitySection = (entity) => {
        return (
            <div className="sProjectListUsersBx">
                <figure className="users group">
                    <img src={entity.imageUrl ? getCDNUrl(entity.imageUrl) : this.props.donationDetails?.type === "PROJECT" ? "https://cdn.dscovr.com/images/project-default.webp" : "https://cdn.dscovr.com/images/circleDefault.webp"}
                        onError={(e) => { e.target.onerror = null; e.target.src = this.props.donationDetails?.type === "PROJECT" ?  "https://cdn.dscovr.com/images/project-default.jpg" : "https://cdn.dscovr.com/images/circleDefault.png" }} alt="" />
                </figure>
                <div>
                    <h5>{entity.entityName}</h5>
                    <p>Posted on <span>{getFormattedDate(entity.postedDate)}</span></p>
                    <p class="country">{entity.country}</p>
                </div>
            </div>
        )
    }

    handelSearchChange = (event) => {
        this.setState({
            entitySearchData: event.target.value.replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')
        }, () => {
            let donationDetails = [];
            let filterData = this.state.donationDetails && this.state.donationDetails.filter((item) => item.entityName && item.entityName.toLowerCase().includes(this.state.entitySearchData && this.state.entitySearchData.toLowerCase()));
            this.state.donationDetails && this.state.donationDetails.map((item, key) => {
                let index = filterData && filterData.findIndex(a => a.entityName === item.entityName);
                if(index > -1) {
                    item.display = true;
                } else {
                    item.display = false;
                }
                donationDetails.push(item);
            })
            this.setState({
                donationDetails
            })
        })
    }

    componentDidMount() {
        let data = [];
        let selectBankData = this.props.donationDetails.bankDetails && this.props.donationDetails.bankDetails.find((data) => data.primary);
        if(_.isEmpty(selectBankData)) {
            selectBankData = this.props.donationDetails.bankDetails && this.props.donationDetails.bankDetails[0];
        }
        if(this.props.type === "ENTITY_DONATE") {
            let item = this.props.donationDetails?.entity;
            item.isChecked = true;
            item.selectedBank = {"label": selectBankData.institutionName, "value": selectBankData.id};
            item.display = true;
            data.push(item);
        } else {
            this.props.donationDetails && this.props.donationDetails.entityList && this.props.donationDetails.entityList.map((item) => {
                item.isChecked = false;
                item.selectedBank = {"label": selectBankData.institutionName, "value": selectBankData.id};
                item.display = true;
                data.push(item);
            })
        }

        let bankData = [];
        this.props.donationDetails && this.props.donationDetails.bankDetails && this.props.donationDetails.bankDetails.map((item) => {
            bankData.push({"label": item.institutionName, "value": item.id})
        })

        this.setState({
            donationDetails: data,
            bankDataList: bankData
        })
    }

    render() {
        const { donationDetails, bankDataList, entitySearchData } = this.state;
        return (
            <div id="donationItemListPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ margin: '0' }}>
                        <div className="modal-body" style={{ padding: '0 15px' }}>
                            <div className="sProjectListPop">
                                <button type="button" className="close topCrossModal" aria-label="Close" onClick={() => this.props.onCloseDonationListDetails("donationItemListPop")}>
                                    <i className="iCross" />
                                </button>
                                <h2>Select {this.props.type !== "ENTITY_DONATE" ? (this.props.donationDetails?.type === "PROJECT" ? "Project" : (donationDetails && donationDetails.length > 1 ? "Communities" : "Community")) : "Bank Account"}{this.props.donationDetails?.type !== "CIRCLE" && this.props.type !== "ENTITY_DONATE" && donationDetails && donationDetails.length > 1 && "s"}</h2>
                                {this.props.type !== "ENTITY_DONATE" && donationDetails && donationDetails.length > 5 &&
                                    <div className="searchByCard">
                                        <form className="searchbox" onSubmit={(e) => {e.preventDefault();}}>
                                            <input type="text" className="search_query form-control" id="search" name="search" value={entitySearchData} placeholder="Search" onChange={this.handelSearchChange} autoComplete="off" />
                                        </form>
                                    </div>
                                }
                                <div>
                                {donationDetails && donationDetails.length > 0 && donationDetails.map((entity) => {
                                    return (
                                        <div className={"sProjectListRow" + (entity.display ? "" : " not-show")}>
                                            <div className='row'>
                                                <div className="col-sm-8">
                                                    {this.props.type !== "ENTITY_DONATE" ?
                                                        <a tabIndex={entity.entityId} className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id={`${entity.entityId}`} checked={entity.isChecked} onClick={this.checkedEntityItem} />
                                                            <label className="custom-control-label" htmlFor={`${entity.entityId}`}>
                                                                {this.getEntitySection(entity)}
                                                            </label>
                                                        </a> :
                                                        <>{this.getEntitySection(entity)}</>
                                                    }
                                                </div>
                                                <div className="col-sm-4 selectBank sProjectListInfo" style={{marginLeft: '0', border: 'none'}}>
                                                    {bankDataList  && bankDataList.length > 1 ? 
                                                        <Select
                                                            placeholder={"Select Bank"}
                                                            className="wnforSelect"
                                                            name={"userFilterOption"}
                                                            options={bankDataList}
                                                            value={entity.selectedBank}
                                                            onChange={(e) => this.onChangeBankDetails(entity.entityId, e)}
                                                            styles={{
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                    const color = '#cfe7c7';
                                                                    return {
                                                                        ...styles,
                                                                        backgroundColor: isDisabled
                                                                            ? null
                                                                            : isSelected
                                                                                ? '#cfe7c7'
                                                                                : isFocused
                                                                                    ? '#cfe7c7'
                                                                                    : null,
                                                                        color: isDisabled
                                                                            ? '#ccc'
                                                                            : isSelected
                                                                                ? color > 2
                                                                                    ? '#00374d'
                                                                                    : '#00374d'
                                                                                : data.color,
                                                                        cursor: isDisabled ? 'not-allowed' : 'default',

                                                                        ':active': {
                                                                            ...styles[':active'],
                                                                            backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                                        },
                                                                    };
                                                                },
                                                            }}
                                                        /> :
                                                        <div>
                                                            <label>Bank Name</label>
                                                            <p>{entity.selectedBank?.label}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )})}
                                    {donationDetails && donationDetails.filter((data) => data.display)?.length === 0 &&
                                        <div className="zdata">
                                            <div className="desc">
                                                <h4>No data available.</h4>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='sProjectListPopBott'>
                                    {/* <p>You can now raise funds for a period as per your WeNaturalists Pro subscription.</p> */}
                                    <button type="button" class="btn btn-primary" onClick={(e) => this.handlePublishDonationList()}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DonationSubscriptionItemList);