import React, { Component, useState } from 'react';
import { authService } from "../../../auth";
import { renderToString } from "react-dom/server";
import { Link, NavLink } from "react-router-dom";
import { getCDNUrl } from "../../../commons/CommonFunctions";
import MutualConnect from "../../../commons/MutualConnect";

// Third Party imports
import Linkify from "react-linkify";
import axios from 'axios';
import stripHtml from "string-strip-html";
import Follow from "../../../commons/Follow";
import ReactHtmlParser from "react-html-parser";
import EntityFollow from "../../../commons/EntityFollow";
import ReactPlayer from "react-player";
import { ReactTinyLink } from "react-tiny-link";
import AudioPlayer from 'react-h5-audio-player';
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import {getFormattedDate, isValidUrl, redirectToPost, redirectToCircle, redirectToProfile, renderTaggedUsers, options} from "./HashtagsCommonFunction";
import ExploreBlogSharePreview from '../../../post/ExploreBlogSharePreview';
import ProjectSharePreview from '../../../post/ProjectSharePreview';
import ForumSharePreview from '../../../post/ForumSharePreview';
import PollSharePreview from '../../../post/PollSharePreview';
import PostSharePreview from '../../../post/PostSharePreview';
import StorySharePreview from '../../../post/StorySharePreview';
import StudioSharePreview from '../../../post/StudioSharePreview';
import ShareContentRemovedPreview from '../../../post/ShareContentRemovedPreview';
import SavePostButton from '../../../post/SavePostButton';
import FeaturePostButton from '../../../post/FeaturePostButton';
import ForumPostBody from '../../../post/ForumPostBody';
import CommonHashTagUserSection from './CommonHashTagUserSection';
import PresentationPostView from '../../../post/PresentationPostView';
import CommonAttachmentSection from '../../../commons/CommonAttachmentSection';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class CommonHashTagsOthers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            og_data: {}
        }
    }

    getLinkPreviewUrl = (text) => {
        let data = {
            url : text,
            sendByMyWenat: true
        }
        axios({
            method: 'POST',
            url : `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response)=>{
            if(response?.data?.status === 200){
                this.setState({
                    og_data: response?.data?.data
                })
            }
        }).catch((error)=>{
            console.log("Error",error);
        })
    };

    getOptimizedImage = (personalInfo) => {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }

    componentDidMount(){
        this.getLinkPreviewUrl(this.props.item.postLinkTypeUrl)
    }

    render() {
        let linkifiedHtml =
            '<p class="pdtlsLink">' +
            renderTaggedUsers(renderToString(
                <Linkify
                    componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                    ) => (
                        <a
                            target="blank"
                            href={decoratedHref}
                            key={key}
                        >
                            {decoratedText}
                        </a>
                    )}
                >
                    {this.props.item.description
                        ? stripHtml(this.props.item.description)
                        : ""}
                </Linkify>
            )).replace(/#+/g, '#') +
            "</p>";
        linkifiedHtml = renderToString(
            ReactHtmlParser(linkifiedHtml, options)
        );
        return (
            <div className="explstorieslist">
                <div className="explstoriesdesc">
                    <div className="postbookMhead">
                        {!this.props.item.userEntityType ? (
                            <figure
                                style={{ cursor: "pointer" }}
                                className="complogo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirectToProfile(
                                        this.props.item.userCustomUrl
                                    );
                                }}
                            >
                                <img
                                    src={
                                        this.props.item.userProfileImage &&
                                            this.props.item.userProfileImage !== ""
                                            ? getCDNUrl(
                                                this.getOptimizedImage(this.props.item)
                                            )
                                            : this.props.item.userType !== "COMPANY"
                                                ? "https://cdn.dscovr.com/images/users1.png"
                                                : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                    }
                                    alt=""
                                />
                            </figure>
                        ) : (
                            <figure
                                style={{ cursor: "pointer" }}
                                className="complogo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirectToCircle(
                                        this.props.item.params &&
                                        this.props.item.params["circleSlug"]
                                    );
                                }}
                            >
                                <img
                                    src={
                                        this.props.item.params &&
                                            this.props.item.params["circleImage"] !=
                                            null
                                            ? getCDNUrl(
                                                this.props.item.params &&
                                                this.props.item.params[
                                                "circleImage"
                                                ]
                                            )
                                            : "https://cdn.dscovr.com/images/circleDefault.webp"
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            "https://cdn.dscovr.com/images/circleDefault.png";
                                    }}
                                    alt=""
                                />
                            </figure>
                        )}
                        <div style={{ paddingRight: '0' }}>
                            {/* {!this.props.item.userEntityType ? (
                                <h5 className="compuser"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToProfile(
                                            this.props.item.userCustomUrl
                                        );
                                    }}
                                    style={{ cursor: 'pointer' }}>{this.props.item.userName}</h5>
                            ) : (
                                <h5
                                    className="compuser"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToCircle(
                                            this.props.item.params &&
                                            this.props.item.params["circleSlug"]
                                        );
                                    }}
                                >
                                    {this.props.item.params["circleTitle"]}
                                </h5>
                            )} */}
                             <CommonHashTagUserSection  item = {this.props.item} />
                            <div className="d-flex hpostdtls">
                                {this.props.item && this.props.item.country &&
                                    <span className="address"><i
                                        className="iLocation" />{this.props.item && this.props.item.country}</span>}
                                <span className="designation" style={{ cursor: 'pointer' }}>
                                    <Link to={this.props.item.postType === "FORUM" ? `/forumDetail/${this.props.item.metaData?.slug}` : this.props.item.postType === "POLL" ? `/pollDetail/${this.props.item.metaData?.slug}` : `/post/${this.props.item.id}`}>
                                        {getFormattedDate(
                                            this.props.item.createTime
                                        )}
                                    </Link>
                                </span>
                                {this.state.loginSuccess === true ? ((this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId) ?
                                    <MutualConnect connectDepth={this.props.item.connectDepth}></MutualConnect> : null) : null}
                                {this.state.loginSuccess === true && (this.props.item.connectDepth !== 1 && this.props.item.connectDepth !== 2) ?
                                    //     !this.props.item.userEntityType? (this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === "PENDING_CONNECT" ?
                                    //     <>
                                    //         <NavLink className="follow follow-link active" to="/connected" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                    //     </>
                                    //     : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'REQUEST_RECEIVED' ?
                                    //         <>
                                    //             <NavLink className="follow follow-link active" to="/manage-invitation" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                    //         </>
                                    //         : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                    //             <></>

                                    //             : this.props.item && (!this.props.item.connectDepth || (this.props.item.connectDepth && this.props.item.connectDepth < 1)) &&
                                    //             <Follow followed={this.props.item.followed} userId={this.props.item.userId} deactivated={this.props.item.deactivated} />
                                    // ) : ((loginSuccess === true  &&  this.props.item.userEntityType != null && this.props.item.userEntityType === "CIRCLE" )?
                                    //     <EntityFollow entityType={'CIRCLE'} entityId={this.props.item.params['circleId']}
                                    //                   followed={this.props.item.followed}
                                    //                   userId={userData.userId}
                                    //                   isCircle={true}/>: null) : null
                                    this.props.item && (!this.props.item.connectDepth || (this.props.item.connectDepth && this.props.item.connectDepth < 1)) && !this.props.item.userEntityType ?
                                        <Follow followed={this.props.item.followed} userId={this.props.item.userId} deactivated={this.props.item.deactivated} />
                                        : /*this.props.item.userEntityType != null && this.props.item.userEntityType === "CIRCLE" ?
                                            <EntityFollow entityType={'CIRCLE'} entityId={this.props.item.params['circleId']}
                                                followed={this.props.item.followed}
                                                userId={this.state.userData.userId}
                                                isCircle={true} /> : null*/ null : null
                                }
                            </div>
                        </div>
                    </div>
                    {this.props.pageType === "BOOKMARK" && 
                        <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={true} entityType={"POST"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                    }
                    {this.props.pageType === "FEATURE_POST"  && /* (this.props.item.userId === this.state.userData.userId) &&  */
                        <FeaturePostButton key={this.props.item.id} item={this.props.item} pageType={this.props.pageType} onSuccessFeaturePost={this.props.onSuccessFeaturePost} />
                    }
                    <h4>
                        {this.props.item.hashTags && this.props.item.hashTags.map((hashTag, index) => {
                            return <span>
                                <a href={`/profile-hashtag/${hashTag}/${this.props?.item?.userCustomUrl}`} className="hashLinkPost">{hashTag}</a>
                            </span>
                        })
                        }
                    </h4>
                    {this.props.item.title &&
                        <h3>
                            <Link to={"/post/" + this.props.item.id}>
                                {this.props.item.title}
                            </Link>
                        </h3>
                    }
                    {(this.props.item.description || (this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0)) &&
                        <div className="descinn">
                            {this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0 &&
                                (this.props.item.attachmentIds[0].attachmentType === 'AUDIO' ?
                                    <div>
                                        <AudioPlayer
                                            showJumpControls={false}
                                            customVolumeControls={[]}
                                            customAdditionalControls={[]}
                                            showDownloadProgress={false}
                                            src={getCDNUrl(this.props.item.attachmentIds[0].attachmentUrl)}
                                        />
                                    </div> :
                                    (this.props.item.attachmentIds[0].attachmentType === "PPT" || this.props.item.attachmentIds[0].attachmentType === "PDF") &&
                                        <PresentationPostView attachments={this.props.item.attachmentIds[0]} />
                                )
                            }
                            <Link to={"/post/" + this.props.item.id}>
                                <ResponsiveEllipsis
                                    unsafeHTML={linkifiedHtml}
                                    maxLine="4"
                                    ellipsis='...'
                                    trimRight
                                    basedOn='words'
                                />
                            </Link>                            
                        </div>
                    }
                    <div className="hpostcontbott">
                        {(this.props.item.secondaryAttachmentIds && this.props.item.secondaryAttachmentIds.length > 0 && this.props.item.secondaryAttachmentIds[0].attachmentType !== "PPT" && this.props.item.secondaryAttachmentIds[0].attachmentType !== "PPTX") ? 
                            <CommonAttachmentSection attachments={this.props.item.secondaryAttachmentIds} itemId={this.props.item.id} /> : 
                            <div className="fattach"/>
                        }
                    </div>

                    {this.props.item.sharedEntityId && this.props.item.sharedEntityId !== '' &&
                        <>
                            {this.props.item.sharedEntityParams && this.props.item.sharedEntityParams["content-not-found"] ? <ShareContentRemovedPreview /> :
                                this.props.item.sharedEntityType === 'EXPLOREBLOG' ? <ExploreBlogSharePreview type={this.props.item.sharedEntityType} item={this.props.item.sharedEntityParams} isHashtagPage={true} /> :
                                ((this.props.item.sharedEntityType === 'ASSIGNMENT') || (this.props.item.sharedEntityType === 'JOB') || (this.props.item.sharedEntityType === 'TRAINING') || (this.props.item.sharedEntityType === 'EXPEDITION') || (this.props.item.sharedEntityType === 'STORYBOOK') || (this.props.item.sharedEntityType === 'FUNDRAISE') || (this.props.item.sharedEntityType === 'EVENT')) ? <ProjectSharePreview type={this.props.item.sharedEntityType} item={this.props.item.sharedEntityParams}/> : 
                                this.props.item.sharedEntityType === 'FORUM' ? <ForumSharePreview type={this.props.item.sharedEntityType} item={this.props.item.sharedEntityParams} entityItem={this.props.item} isHashtagPage={true} /> : 
                                this.props.item.sharedEntityType === 'POLL' ? <PollSharePreview type={this.props.item.sharedEntityType} item={this.props.item.sharedEntityParams} entityItem={this.props.item} isHashtagPage={true} /> :
                                this.props.item.sharedEntityType === 'POST' ? <PostSharePreview type={this.props.item.sharedEntityType} item={this.props.item.sharedEntityParams} entityItem={this.props.item} isHashtagPage={true} /> :
                                this.props.item.sharedEntityType === 'STORY' ? <StorySharePreview type={this.props.item.sharedEntityType} item={this.props.item.sharedEntityParams} isHashtagPage={true} /> :
                                this.props.item?.sharedEntityType === 'STUDIO' ? <StudioSharePreview type={this.props.item?.sharedEntityType} item={this.props.item?.sharedEntityParams} entityItem={this.props.item} isHashtagPage={true} /> : null
                            }
                        </>
                    }
                    {
                        this.props.item.postLinkTypeUrl
                        && isValidUrl(this.props.item.postLinkTypeUrl)
                        && <>
                            <div className="linkpostpreview">
                                {/*<div className="prevtitle">
                                                Preview
                                            </div>*/}
                                <div className="postarticledesc">
                                    {this.props.item.postLinkTypeUrl.indexOf('https://youtu.be') !== -1 || this.props.item.postLinkTypeUrl.indexOf('www.youtube.com/watch') !== -1 ? <>
                                        <div className="vidPlayerOverWrap">
                                            <ReactPlayer
                                                url={this.props.item.postLinkTypeUrl}
                                                width={"100%"}
                                                height={'80px'}
                                                playing={false}
                                                controls={false}
                                                muted={true}
                                            />
                                            <span className="vidOverlay" onClick={(e) => redirectToPost()}></span>
                                        </div>
                                        {/* <ReactTinyLink
                                            cardSize="small"
                                            showGraphic={false}
                                            maxLine={2}
                                            onClick={(e) => { return 0 }}
                                            minLine={1}
                                            url={this.props.item.postLinkTypeUrl}
                                            proxyUrl={"https://cors-anywhere.beegram.net"} /> */}
                                        <div className="customLinkPost" onClick={()=>{
                                          window.location.href = this.props.item.postLinkTypeUrl }}>
                                            <div className="customLinkPostDesc">
                                                <h5>{this.state.og_data?.title}</h5>
                                                <p>{this.state.og_data?.description}</p>
                                                {/* <p>{this.props.item.postLinkTypeUrl}</p> */}
                                            </div>
                                        </div>
                                        <a href={this.props.item.postLinkTypeUrl} className="btn playYoutube" target="_blank">
                                            <i className="iYouTube_Logo" /> Play on Youtube</a>
                                    </> :
                                            <div className="customLinkPost" onClick={()=>{
                                                window.location.href = this.props.item.postLinkTypeUrl }}>
                                                <figure><img src={this.state.og_data?.image} alt={this.state.og_data?.title}/></figure>
                                                <div className="customLinkPostDesc">
                                                    <h5>{this.state.og_data?.title}</h5>
                                                    <p>{this.state.og_data?.description}</p>
                                                    {/* <p>{this.props.item.postLinkTypeUrl}</p> */}
                                                </div>
                                            </div>}
                                </div>
                            </div>
                        </>
                    }
                    {(this.props.item.postType === "FORUM" || this.props.item.postType === "POLL") &&
                        <ForumPostBody title={this.props.item.postType === "FORUM" ? this.props.item?.metaData?.title : this.props.item?.metaData?.question} description={this.props.item.postType === "FORUM" ? this.props.item?.metaData?.content : this.props.item?.metaData?.description} hashTags={this.props.item?.metaData?.hashTags} pageType={this.props.pageType} slug={this.props.item?.metaData?.slug} postType={this.props.item.postType} />
                    }
                </div>
            </div>
        )
    }
}

export default CommonHashTagsOthers;