import React from "react";
import {authService} from "../auth";
import axios from "axios";
import httpService from "../AxiosInterceptors";
import cloneDeep from "lodash/cloneDeep";
import {NavLink, withRouter} from "react-router-dom";
import uuid from "react-uuid";
import ReactTooltip from "react-tooltip";
import { blockUserMessage } from "./CommonFunctions";

httpService.setupInterceptors();

var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|wenat_android|wenat_ios/i.test(navigator.userAgent) ? true : false;

class ProfileConnectButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            connectStatus: props.connectStatusInfo && props.connectStatusInfo.connectStatus,
            connectRequestBlockedExpiryTimeInDays: props.connectStatusInfo && props.connectStatusInfo.connectRequestBlockedExpiryTimeRemainingInMillis > 0
                ? Math.ceil((((props.connectStatusInfo.connectRequestBlockedExpiryTimeRemainingInMillis / 1000) / 60) / 60) / 24) : -1,
            connectRequestBlocked: props.connectStatusInfo && props.connectStatusInfo.connectRequestBlocked,
            uniqueKey: uuid()
        }
    }

    handleConnectStatusChange = () => {
        let newState = 'CONNECTED';
        let oldState = cloneDeep(this.state.connectStatus);

        if (this.state.connectStatus === 'NOT_CONNECTED' || this.state.connectStatus === 'IGNORE_PENDING_CONNECT') {
            newState = 'PENDING_CONNECT';
        } else if (this.state.connectStatus === 'PENDING_CONNECT') {
            newState = 'NOT_CONNECTED';
        } else if (this.state.connectStatus === 'CONNECTED') {
            newState = 'NOT_CONNECTED';
        } else if (this.state.connectStatus === 'REQUEST_RECEIVED') {
            this.props.history.push({
                pathname: '/manage-invitation',
                //state: {customUrl: this.props.customUrl, userId: this.props.userId}
            })
            return;
        } else if (this.state.connectStatus === 'IGNORE_PENDING_CONNECT') {
            newState = 'NOT_CONNECTED';
        }

        this.setState({'connectStatus': newState})

        let url;
        if (oldState === 'NOT_CONNECTED' || this.state.connectStatus === 'IGNORE_PENDING_CONNECT') {
            url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/invite/' + this.props.userId+'?privateEntityId='+this.state.userData.entityId;
        } else if (oldState === 'PENDING_CONNECT') {
            url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/removeInvite/' + this.props.userId+'?privateEntityId='+this.state.userData.entityId
        } else if (oldState === 'CONNECTED') {
            url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/unconnect/' + this.props.userId+'?privateEntityId='+this.state.userData.entityId
        }

        if (url && url !== '') {
            axios({
                method: 'post',
                url: url,
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response && response.status === 202) {
                    this.updateStatus(this.props.userId,this.state.userData.userId);

                } else {
                    this.setState({'connectStatus': oldState})
                }
            }).catch((err) => {
                if(err?.response?.data?.status === '400 BAD_REQUEST' && (oldState === 'NOT_CONNECTED' || this.state.connectStatus === 'IGNORE_PENDING_CONNECT')) {
                    blockUserMessage("MESSAGE", err.response.data.message);
                }
                this.setState({'connectStatus': oldState})
                console.log(err);
            });
        }
    }

    getConnectStatus = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + "/graph/users/" + this.state.userData.userId + "/connectionStatus/" + this.props.userId,
            withCredentials: true,
            headers: {"Content-Type": "application/json"},
        }).then((response) => {

            if (response && response.status === 200) {
                if (response.data && response.data.body) {
                    let connectStatusInfo = response.data.body;
                    this.setState({
                        connectStatus: connectStatusInfo && connectStatusInfo.connectStatus,
                        connectRequestBlockedExpiryTimeInDays: connectStatusInfo && connectStatusInfo.connectRequestBlockedExpiryTimeRemainingInMillis > 0
                            ? Math.ceil((((connectStatusInfo.connectRequestBlockedExpiryTimeRemainingInMillis / 1000) / 60) / 60) / 24) : -1,
                        connectRequestBlocked: connectStatusInfo && connectStatusInfo.connectRequestBlocked
                    });
                }

            }
        }).catch((e) => {
            console.log(e);
        });
    }

    componentWillUnmount() {
        window.$('#removePopup' + this.state.uniqueKey).modal('hide');
    }

    confirmModal = (event) => {
        if(this.props.popularUserPage) {
            window.$(event.target.parentElement).addClass("modal-show");
        }
        window.$('#removePopup' + this.state.uniqueKey).modal('show')
    }

    ignoreModal = () => {
        if(this.props.popularUserPage) {
            window.$(".dropdown-menu").removeClass("modal-show");
        }
        window.$('#removePopup' + this.state.uniqueKey).modal('hide')
    }

    updateStatus = (userId,requestingUserId) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/user/purge-cache?userId='+userId+'&requestingUserId='+requestingUserId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            this.getConnectStatus();
            if(this.props.getConnectStatus){
                this.props.getConnectStatus()
            }
            if (this.props.callback_fn) {
                this.props.callback_fn();
            }
            if(this.props.getPopularUsers){
                this.props.getPopularUsers();
            }
            if(this.props.getRecommendedUsers){
                //this.props.getRecommendedUsers();
            }
            if(this.props.changeConnectStatus){
                this.props.changeConnectStatus();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        return (
            this.state.loginSuccess && this.state.userData.type === 'INDIVIDUAL'
            && this.state.userData.userId !== this.props.userId && this.state.connectStatus && this.state.connectStatus !== ''
                ? <>{((this.state.connectStatus === 'NOT_CONNECTED'
                    || this.state.connectStatus === 'IGNORE_PENDING_CONNECT' || this.state.connectStatus === "CONNECT_REQUEST_BLOCKED_FOREVER") ?
                    (((this.state.connectRequestBlocked === true && this.state.connectRequestBlockedExpiryTimeInDays > 0) || this.state.connectStatus === "CONNECT_REQUEST_BLOCKED_FOREVER") ?
                            <>
                                
                                {!this.props.popup ?
                                <>
                                <ReactTooltip
                                    className="rtoolTip"
                                    place="bottom"
                                    type="dark"
                                    textColor="#00394D"
                                    globalEventOff={isMobile ? "click" : ""}
                                    backgroundColor="#ffffff"/>
                                <a href="javascript:void(0)"
                                   data-event={isMobile ? "click" : ""}
                                   data-tip={(this.props.userDetails?.blocked || this.state.connectStatus === "CONNECT_REQUEST_BLOCKED_FOREVER") ? "Permissions Denied" : "You can send connection request after " + this.state.connectRequestBlockedExpiryTimeInDays + (this.state.connectRequestBlockedExpiryTimeInDays <= 1 ? " day" : " days")}
                                   className={"follow follow-link disable"}>Connect
                                </a></>:
                                    <>
                                        <ReactTooltip
                                            className="rtoolTip"
                                            place="bottom"
                                            type="dark"
                                            id={'Connect'+this.props.userId}
                                            textColor="#00394D"
                                            globalEventOff={isMobile ? "click" : ""}
                                            backgroundColor="#ffffff">{(this.props.userDetails?.blocked || this.state.connectStatus === "CONNECT_REQUEST_BLOCKED_FOREVER") ? "Permissions Denied" : "You can send connection request after " + this.state.connectRequestBlockedExpiryTimeInDays + (this.state.connectRequestBlockedExpiryTimeInDays <= 1 ? " day" : " days")}</ReactTooltip>
                                        <a href="javascript:void(0)" data-event={isMobile ? "click" : ""} data-tip="msg to show"  data-place= {this.props.showTooltipInTop ? 'top' : 'bottom'} data-for={'Connect'+this.props.userId}
                                        className={!this.props.youMayKnow ? "dropdown-item" + ((this.state.connectStatus === 'IGNORE_PENDING_CONNECT' ) ? ' disable ' : '')  :"btn accept disable"} style={{cursor : (this.props.userDetails?.blocked || this.state.connectStatus === "CONNECT_REQUEST_BLOCKED_FOREVER") && "not-allowed"}}>
                                        <i className="iAddUser" /> Connect</a></>}
                            </>

                            :!this.props.popup ?

                            <a href="javascript:void(0)" onClick={this.handleConnectStatusChange}
                               className="follow follow-link">Connect</a>:
                            <a href="javascript:void(0)" className={!this.props.youMayKnow ? "dropdown-item"  :"btn accept" } onClick={this.handleConnectStatusChange}>
                                <i className="iAddUser" /> Connect</a>
                    )
                    :
                    this.state.connectStatus === 'CONNECTED' ?
                        <>
                            <a href="javascript:void(0)" onClick={(event) => this.confirmModal(event)}
                               className={"follow follow-link" + (!this.props.popularUserPage ? " active" : "")}>{this.props.popularUserPage && <i className="iTickedUser" />} Connected</a>
                        </>
                        :
                        this.state.connectStatus === 'PENDING_CONNECT' ?
                            <>
                                {!this.props.popup ?
                                <a href="javascript:void(0)" onClick={this.confirmModal}
                                   className="follow follow-link active">Pending</a> :
                                    <NavLink to="/connected" data-tip="msg to show" data-place='bottom' data-for={'Pending'+this.props.userId}
                                              className={!this.props.youMayKnow ? "dropdown-item"  :"btn accept active"}><i className="iTickedUser" /> Pending</NavLink>}
                            </>
                            :
                            this.state.connectStatus === 'REQUEST_RECEIVED' ?
                                <>{!this.props.popup ?
                                    <a href="javascript:void(0)" onClick={this.handleConnectStatusChange}
                                       className="follow follow-link active">Request Received</a>:                                    
                                    <NavLink  to="/manage-invitation"   data-tip="msg to show"  data-place='bottom' data-for={'Pending'+this.props.userId}
                                        className="dropdown-item"><i className="iTickedUser" /> Pending</NavLink>}
                                </>
                                :
                                this.state.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                    <></>
                                    : null
                )}
                    <div className="modal fade dgpopup confirmpop"
                         id={"removePopup" + this.state.uniqueKey} tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static"
                         data-keyboard="false">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Remove Confirmation</h5>
                                </div> */}
                                <button type="button" className="close" aria-label="Close" onClick={(e) => this.ignoreModal()}><span
                                    aria-hidden="true">&times;</span></button>
                                <div className="confirm-popup-modal-body">
                                    Are you sure you want to remove {this.props.userName} as your connect? You
                                    will not be able to connect the member for next 30 days.
                                </div>
                                <div className="modal-footer confirm-popup-modal-footer">
                                    <button type="button" className={"btn-primary" + (!this.props.popularUserPage ? " btn" : "")}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.handleConnectStatusChange();
                                                this.ignoreModal();
                                            }}>Continue
                                    </button>
                                    <button type="button" className={"btn-secondary" + (!this.props.popularUserPage ? " btn" : "")}
                                            onClick={this.ignoreModal}>Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null

        );

    }

}

export default withRouter(ProfileConnectButton)
