import React from 'react'
import en from 'javascript-time-ago/locale/en';
import TimeAgo from "javascript-time-ago";
import ReactHtmlParser from "react-html-parser";
import { authService } from '../../../auth';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const userCustomUrl = authService.getUserDetailsFromLocalStorage()?.customUrl;

const matchHashTags= (text) => {
    if (!text || text.length === 0) {
        return text;
    }
    var string = text;
    var regex = /(#[a-z\d-_]+)/gi;
    var matches = string.matchAll(regex);
    for (var match of matches) {
        if (match && match.length > 1) {
            string = string.replace(
                match[0],
                '<span class="hposthashitem" ' +
                'style="font-family: Montserrat,sans-serif;font-weight: 500;font-size: 12px;line-height: 15px;color: #bfc52e;">' +
                '<a href="/profile-hashtag/' + match[0].substr(1) + "/" + userCustomUrl + '"><i class="iHashtag"></i>' + match[0].substr(1) + '</a></span>');
        }
    }
    return string;
}

const transform = (node, index) => {
    if (node.type === "text") {
        let modifiedString = matchHashTags(node.data);
        if (modifiedString !== node.data) {
            node.type = "tag";
            node.name = "div";
            return <>{ReactHtmlParser(modifiedString)}</>;
        }
    }
}

export const options = {
    decodeEntities: true,
    transform,
};

// onClick redirection to user profile
export const redirectToCircle = (customUrl) => {
    window.location.href = "/community/" + customUrl;
}

// onClick redirection to user profile
export const redirectToProfile = (customUrl) => {
    window.location.href = "/profile/" + customUrl;
}

export const redirectToProfileHashTags = (slug ,customUrl) => {
    window.location.href = `/profile-hashtag/${slug}/${customUrl}`;
}

//Formatted date
export const getFormattedDate = (milliseconds) => {
    var utcSeconds = milliseconds;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    return timeAgo.format(d, 'mini-now')
}

export const renderTaggedUsers = (text) => {
    if (!text || text.length === 0) {
        return text;
    }
    if (text.includes("# # ")) {
        text = text.replace("# # ", "");
    }
    text = text.split("@@@__").join("<a href=\"/profile/");
    text = text.split("^^__").join("\">")
    text = text.split("&&__").join("\">")
    text = text.split("&amp;&amp;__").join("\">")
    text = text.split("&amp;&amp;__").join("\">")
    text = text.split("@@@^^^").join("</a>");
    text = text.split("###__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/profile-hashtag/");
    text = text.split("&&__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/profile-hashtag/");
    text = text.split("###^^^").join("</a>");
    //text =  this.checkHashTag(text);
    return text;
}

export const redirectToProjects = (customUrl) => {
    if(customUrl != null) {
        window.location.href = "/project/feeds/" + customUrl;
    }
}

export const nFormatter = (num, digits) => {
    if (!num) {
        return num
    }
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const redirectToPost = () =>{
    window.location.href = "/post/" + this.props.item.id;
}

export const isValidUrl = (str) => {
    let regexp = /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str)
}

export const convertToFirstCapital = (s) => {
    if (s) {
        if (s.length <= 1) {
            return s.toUpperCase();
        }
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }
    return s;
};

export const numberShortForm = (data) => {
    if(data != undefined) {
        let number = data;
        var quotient = Math.floor(number/10);
        var remainder = number % 10;
        if(data <= 99) {
            return data;
        } 
        return '99+';
    }
    return null
}

export const getTotalOthers = (others,audio,cause,circle,feed,link)=>{
    let finalResult = 0
    if(others != undefined && others != null){
        finalResult = finalResult + others;
    }if(audio != undefined && audio != null){
        finalResult =finalResult + audio;
    } if(cause != undefined && cause != null){
        finalResult = finalResult + cause;
    } if(feed != undefined && feed != null){
        finalResult =finalResult + feed;
    } if(circle != undefined && circle != null){
        finalResult = finalResult + circle;
    } if(link != undefined && link != null){
        finalResult =finalResult + link;
    }
    return numberShortForm(finalResult);
}