import React, { Component } from "react";
import { Link, NavLink, Redirect, withRouter } from "react-router-dom";
import { authService } from "../../auth";
import Share from "../../commons/Share";
import { getCDNUrl, getDownloadFileName, getOptimizedCoverImage, getOptimizedImage, isMobileDevice, userAgentGetData } from "../../commons/CommonFunctions";
import ReadMoreReadLess from "../../commons/ReadMoreReadLess";
import axios from "axios";
import EntityFollow from "../../commons/EntityFollow";
import * as _ from "lodash";
import DeactivateErrorPopUp from "../../commons/DeactivateErrorPopUp";
import HorizontalScroll from 'react-scroll-horizontal';
import mappingUtils from "../../project/component/commons/utils/mappingUtils";
import { Scrollbars } from "react-custom-scrollbars";
import MemberItem from "../../commons/MemberItem";
import uuid from "react-uuid";
import SendMessageModal from "../../commons/SendMessageModal";
import FeaturePostSection from "../../profile/container/profile/FeaturePostSection";
import DownloadComponent from "../../commons/DownloadComponent";
import { circleUserConfig, getCircleConfig } from "../../services/CircleApi";
import DonationComponent from "../../Donation/DonationComponent";
import { getUserByUserId } from "../../services/UserApi";

if (isMobileDevice()) {
    import('../../commons/styles/responsive-useragent.css').then(() => {
        console.log("Imported mobile css");
    });
}
const nFormatter = (num, digits) => {
    if (!num) {
        return num
    }
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutSection: true,
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            circleData: props.circleData,
            circleSpecialites: [],
            circleLocation: {},
            deactivationConfig: {},
            editErrorMessage: 'Your profile is undergoing deactivation process. During this period you will not able to edit Community',
            privacySetting: {},
            sharingSetting: {},
            circleFollowerList: [],
            awardDetailsCount: "",
            superAdminUserData: {},
            openSendMessageModal: "",
            clickViewStats: false
        };
    }

    componentDidMount() {
        this.getProfileDeactivationConfig();
        this.getCircleSpecialities()
        this.getUserConfig()
        this.getCircleLocation()
        this.getDataByCircleId()
        this.getFollowingCircleList();
        this.getAllAwardListCount();
        this.getUserDataByUserId();
        if (window.location.href.indexOf('/community/activity/') !== -1 || window.location.href.indexOf('/community/projects/') !== -1 || window.location.href.indexOf('/community/members/') !== -1 || window.location.href.indexOf('/community/award/') !== -1 || window.location.href.indexOf('/community/setting/') !== -1 || window.location.href.indexOf('/community/stats/') !== -1) {
            this.handleAbout()
        } else {
            this.handleActivity()
        }
        // window.$(".projtopbar").sticky({topSpacing: 65, responsiveWidth: true});
        // if (window.$('#sticky-wrapper').hasClass('sticky-wrapper'))
        //     window.$('.projectinn').css({'padding-bottom' : '150px'});

        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });

    }
    getFollowingCircleList() {
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + '/circle/getCircleFollower/?id=' + this.state.circleData.id + '&userId=' + this.state.userData.userId + '&privateEntityId='+this.state.userData?.entityId,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === 'Success!') {
                    this.setState({
                        circleFollowerList: response.data.body.circleFollowerDto,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    getDataByCircleId = async () => {
        let response = await getCircleConfig({"userId": this.state.userData.userId, "circleId": this.state.circleData.id});
        if(response?.message === "Success!") {
            this.setState({
                privacySetting: response.body.privacySettingConfigDto,
                sharingSetting: response.body.sharingSettingConfigDto
            })
        }
    }

    getProfileDeactivationConfig = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL +
                "/profile/" +
                "get/deactivation/config?id=" + authService.getUserDetailsFromLocalStorage().userId,
            headers: { 'Content-Type': 'application/json' },

            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '200 OK') {
                this.setState({ 'deactivationConfig': res.body })
            }

        }).catch((err) => {
            if (err && err.response) {
                this.setState({
                    error: { message: err.response.data.message, err: err.response }
                })
            }
            this.setState({
                isLoaded: true
            });
        });
    };

    getAllAwardListCount() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/wpondNomination/getAwardCount?userId=' + this.state.circleData.id,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({
                    awardDetailsCount: response.data.body?.awardCount
                })
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    handleAbout = () => {
        this.setState({
            aboutSection: false
        })
        return false
    }

    handleActivity = () => {
        this.setState({
            aboutSection: true
        })
        return false
    }

    getFormattedDate = (milliseconds) => {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (!milliseconds) {
            return "";
        }
        var utcSeconds = milliseconds;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear();
    }

    getCircleSpecialities = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/circle/get/specialities?circleId=' + this.state.circleData.id,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res && res.status === '200 OK') {
                this.setState({
                    circleSpecialites: res.body
                }, () => {

                })
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    getUserConfig = async () => {
        let response = await circleUserConfig({"id": this.state.circleData.id, "userId": this.state.userData.userId});
        if(response?.message === "Success!") {
            this.setState({
                userConfigData: response?.body
            })
        }
    }

    redirectToEdit = (event) => {
        event.preventDefault();
        if (this.state.deactivationConfig && this.state.deactivationConfig.profileDeactivated) {
            window.$('#deactivationProcessError').modal('show');

        } else {
            window.location.href = '/community/edit/' + this.state.circleData.slug;
        }
    }

    getCircleLocation = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/circle/get/location/' + this.state.circleData.id,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res && res.status === '200 OK') {
                this.setState({
                    circleLocation: res.body
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    async getUserDataByUserId() {
        let response = await getUserByUserId({"id": this.state.circleData.superAdminId, "otherUserId": this.state.userData.userId});
        if(response?.message === "Success!") {
            this.setState({ superAdminUserData: response?.body });
        }
    }

    triggerMessageModalClose = () => {
        this.setState({ openSendMessageModal: null });
    }

    changeTab = () => {
        this.setState({
            clickViewStats: true
        })
    }

    componentWillUnmount() {
        window.$('#circleFollowers').modal('hide');
    }

    render() {
        const parent = { height: `19px` }
        const parentnav = { height: '45px' }
        return (
            <>
                {this.state.clickViewStats && <Redirect to={`/community/stats/${this.state.circleData.slug}`} />}
                {this.state.aboutSection === true ? <div className="profbannwrap">
                    <img
                        src={this.state.circleData && this.state.circleData.coverImage && !_.isNull(this.state.circleData.coverImage) ? getCDNUrl(getOptimizedCoverImage(this.state.circleData)) : 'https://cdn.dscovr.com/images/prof-banner.webp'}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://cdn.dscovr.com/images/prof-banner.png"
                        }} id="preview" />
                    <div className="microcommaboutdesc">
                        <div className="microcommaboutinn">
                            <figure className="userbannico">
                                <a data-fancybox={uuid()}
                                    href={(this.state.circleData && this.state.circleData.profileImage) ? getCDNUrl(this.state.circleData.profileImage) : "https://cdn.dscovr.com/images/circleDefault.webp"}>
                                    <img
                                        src={(this.state.circleData && this.state.circleData.profileImage) ? getCDNUrl(getOptimizedImage(this.state.circleData, 'profileImage')) : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "https://cdn.dscovr.com/images/circleDefault.png"
                                        }} alt="" />
                                </a>
                            </figure>
                            <div>
                                <h1 className="h1ttl">{this.state.circleData && this.state.circleData.title}</h1>
                                <div className="category">
                                    <span> {this.getFormattedDate(this.state.circleData && this.state.circleData.incorporationDate)}</span>
                                </div>
                                <span className="date"><i
                                    className="iLocation" /> {this.state.circleLocation && this.state.circleLocation.country ? this.state.circleLocation.city + ', ' + this.state.circleLocation.state + ', ' + this.state.circleLocation.country : null}</span>
                                {/* {!_.isEmpty(this.state.circleData) && this.state.circleData.memberType === 'Super Admin' && <DownloadComponent id={this.state.circleData.id} fileName={getDownloadFileName(this.state.circleData.title)} userName={this.state.circleData.title} type={"CIRCLE"} />} */}
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className="projtopbar" style={{ marginBottom: '0px' }}>
                    <div className={this.state.aboutSection === true ? "proftopuserinfo about" : "proftopuserinfo"}>
                        <div className="proftopusersm">
                            <figure className="userlogo">
                                <img
                                    src={(this.state.circleData && this.state.circleData.profileImage) ? getCDNUrl(getOptimizedImage(this.state.circleData, 'profileImage')) : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "https://cdn.dscovr.com/images/circleDefault.png"
                                    }} alt="" />
                            </figure>
                            <h1><strong>{this.state.circleData && this.state.circleData.title}</strong></h1>
                        </div>
                    </div>
                    <div className="projtopbarleft">
                        <HorizontalScroll style={parentnav} reverseScroll className="mcustHorizMenu">
                            <ul id="tabs" className="nav">
                                {this.state.circleData && <li className="nav-item">
                                    {this.state.circleData.slug &&
                                        <NavLink to={"/community/" + this.state.circleData.slug}
                                            activeClassName={this.state.aboutSection ? "active" : ""}>About</NavLink>}
                                </li>}
                                {this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription) && this.state.circleData.active === true &&
                                    <li className="nav-item">
                                        {(this.state.circleData.slug) &&
                                            <NavLink to={"/community/activity/" + this.state.circleData.slug}
                                                activeClassName="active">Activity</NavLink>}
                                    </li>}
                                <li className="nav-item">
                                    {(this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription || (this.state.circleData.memberType && (this.state.circleData.memberType === 'Admin' || this.state.circleData.memberType === 'Super Admin'))) && this.state.circleData.slug) &&
                                        <NavLink to={"/community/projects/" + this.state.circleData.slug}
                                            activeClassName="active">Projects</NavLink>}
                                </li>
                                {this.state.circleData && !this.state.circleData.inactiveFlagForMemberSubscription && this.state.awardDetailsCount > 0 &&
                                    <li className="nav-item">
                                        {this.state.circleData.slug &&
                                            <NavLink to={"/community/award/" + this.state.circleData.slug}
                                                activeClassName="active">Awards</NavLink>}
                                    </li>
                                }
                                <li className="nav-item">
                                    {(this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription || (this.state.circleData.memberType && (this.state.circleData.memberType === 'Admin' || this.state.circleData.memberType === 'Super Admin'))) && this.state.circleData.slug) &&
                                        <NavLink to={"/community/members/" + this.state.circleData.slug}
                                            activeClassName="active">Members</NavLink>}
                                </li>                                
                                {(this.state.circleData.memberType && this.state.circleData.memberType === 'Super Admin' || this.state.circleData?.memberType === 'Admin') &&
                                    <li className="nav-item">
                                            <NavLink to={"/community/stats/" + this.state.circleData.slug}
                                                activeClassName="active"><i className="iBarGraphs" /></NavLink>
                                    </li>
                                }
                            </ul>
                        </HorizontalScroll>

                        <div className="projtopbarright">
                            {
                                this.state.circleData && this.state.circleData.slug &&
                                <Share shareUrl={"/community/"}
                                    hideShareOnPortalBtn={true}
                                    sharingSetting={this.state.sharingSetting}
                                    shareId={this.state.circleData.slug} />
                            }
                            {this.state.userConfigData && this.state.circleData && this.state.circleData.id &&
                                <EntityFollow entityType={'CIRCLE'} entityId={this.state.circleData.id}
                                    followed={this.state.userConfigData.followed}
                                    privacySetting={this.state.privacySetting}
                                    userId={this.state.userData.userId} />
                            }
                        </div>
                    </div>
                </div>

                {this.state.loginSuccess && this.state.circleData?.id && <DonationComponent entityId={this.state.circleData.id} entitySlug={this.state.circleData?.slug} superAdminId={this.props.circleData?.superAdminId} donationBar={this.props.circleData?.donation} entityType={"CIRCLE"} changeTab={this.changeTab} getEntityDonationConfig={this.props.getEntityDonationConfig} needDonationConfig={this.props.needDonationConfig} getDonationConfig={this.props.getDonationConfig} pageType={this.props.pageType} entityDetails={this.state.circleData} />}

                {this.state.aboutSection && <FeaturePostSection userId={this.state.circleData.id} />}
                
                {this.state.aboutSection === true ? <div className="profuserinfowrap">
                    <div className="profuserdescwrap">
                        <div className="profuserdesctop">
                            <div className="profuserdesccont">
                                <h2 className="title">Who we are</h2>
                                <div className="feeddescwrap">
                                    <ReadMoreReadLess id={this.state.circleData && this.state.circleData.slug}
                                        description={this.state.circleData && this.state.circleData.description} />
                                </div>
                                {this.state.circleSpecialites && this.state.circleSpecialites.length > 0 &&
                                    <div className="profuserdescnav">
                                        <HorizontalScroll style={parent} reverseScroll>
                                            <ul>
                                                <li className="title">Our Specialization:</li>
                                                {this.state.circleSpecialites.map((data) => {
                                                    return <li>{data}</li>
                                                })
                                                }
                                            </ul>
                                        </HorizontalScroll>
                                    </div>
                                }
                            </div>
                        </div>
                        {this.state.circleData && !this.state.circleData.active ?
                            <div className="profuserdescbott">
                                <div className="circleCounts">
                                    {this.state.circleData && this.state.circleData.inactiveFlagForMemberSubscription ?
                                        (this.state.circleData.superAdminId === this.state.userData.userId ?

                                            <div className="zdata"><h3>The Community is no longer active. </h3></div> :
                                            <div className="zdata"><h3>The Community is no longer active.<br />{(this.state.circleData.memberType || this.state.circleData.followed) &&
                                                <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); this.setState({ openSendMessageModal: this.state.circleData && this.state.circleData.superAdminId }); }}> Contact Super admin.</a>} </h3>
                                            </div>) :

                                        <div className="zdata"><h3>This Community will be active once it meets the minimum
                                            members criteria</h3></div>}
                                </div>
                                {this.state.circleData && this.state.userConfigData?.circleUserPermissionMetadata?.canEdit ?
                                    <a href="javascript:void(0)" onClick={this.redirectToEdit} className="edtbtn btn">
                                        <i className="iEditBox" /> Edit
                                    </a> : <span />
                                }
                            </div> :
                            this.state.circleData && this.state.userConfigData &&
                                <div className="profuserdescbott">
                                    <div className="circleCounts">
                                        {this.state.userConfigData && <>
                                            <span>
                                                {(this.state.circleData && this.state.circleData.slug) &&
                                                    <NavLink to={"/community/members/" + this.state.circleData.slug}><strong>{nFormatter(this.state.userConfigData.membersCount)}</strong> Members</NavLink>}
                                            </span>
                                            <span>
                                                {(this.state.circleData.slug) && 
                                                    <NavLink to={"/community/activity/" + this.state.circleData.slug} activeClassName="active"><strong>{nFormatter(this.state.userConfigData.postCount)}</strong> Posts</NavLink>}
                                            </span>
                                            {<span><a href="javascript:void(0);" data-toggle="modal" data-target="#circleFollowers"><strong>{nFormatter(this.state.userConfigData.followerCount)}</strong> Followers</a></span>}</>
                                        }
                                        <div id="circleFollowers" className="modal fade dgpopup reqendorse" tabIndex="-1" data-backdrop="static">
                                            <div className="modal-dialog likesblk">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <div className="similarAllpopWrap forwcontwrap" style={{ maxWidth: '424px' }}>
                                                            <div>
                                                                <div className="similarAllpopHeader">
                                                                    <h2>Followers</h2>
                                                                </div>
                                                                <div className={"similarAllpopBody"}>
                                                                    <Scrollbars
                                                                        className={'forwcontlistmain'}
                                                                        autoHide
                                                                        autoHeight
                                                                        autoHeightMin={0}
                                                                        autoHeightMax={322}
                                                                        universal={true}
                                                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                                                                        renderTrackVertical={props => <div {...props} className="track-vertical" />}
                                                                    >
                                                                        <div>
                                                                            <ul>
                                                                                <ul>
                                                                                    {this.state.circleFollowerList && this.state.circleFollowerList.map((user, index) => {
                                                                                        return <MemberItem item={user}
                                                                                            key={index} />
                                                                                    })}

                                                                                </ul>
                                                                            </ul>
                                                                        </div>
                                                                    </Scrollbars>
                                                                </div>
                                                            </div>
                                                            <div className="forwcontwrapbott">
                                                                <div>
                                                                    <button className="butt btnsecondary close" data-dismiss="modal">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.circleData && this.state.userConfigData?.circleUserPermissionMetadata?.canEdit ?
                                        <a href="javascript:void(0)" onClick={this.redirectToEdit} className="edtbtn btn">
                                            <i className="iEditBox" /> Edit
                                        </a> : <span />
                                    }
                                </div>
                        }
                        {this.state.openSendMessageModal && this.state.openSendMessageModal === this.state.superAdminUserData.userId &&
                            <SendMessageModal profileId={this.state.superAdminUserData.userId}
                                userName={this.state.superAdminUserData.userName}
                                triggerMessageModalClose={this.triggerMessageModalClose} />
                        }
                        <DeactivateErrorPopUp entityType={this.state.entityType} message={this.state.editErrorMessage} />
                    </div>
                </div> : null}
            </>
        );
    }
}

export default withRouter(Banner);
