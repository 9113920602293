import React, {PureComponent} from 'react';
import {authService} from "../auth";
import {requestPostCreationFulfilled} from "../reducer/post";
import {connect} from "react-redux";
import httpService from "../AxiosInterceptors";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import { getCDNUrl } from "../commons/CommonFunctions";
import Images from '../commons/Images'
import '../commons/styles/fb-image-grid-style.min.css'
import { defaultProjectImage } from '../project/component/Studio/StudioContentCommonFunction';
import EventMessageSection from '../commons/EventMessageSection';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import CommonPostMenuSection from './CommonPostMenuSection';
import CommonPostBottomSection from './CommonPostBottomSection';

httpService.setupInterceptors();
class PhotosPostItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            description: props.item.description,
            hashTags: [],
            tags: [],
            smileDisplay:false,
            paidContentPostLock: false
        }
    }

    componentDidMount() {
        let tags = [];
        this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {
            tags.push(data);
            this.setState({tags: tags});
        })

        let photoListMap = [];
        let photoListOriginalMap = [];
        this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds.map((attachment, index) => {
            photoListOriginalMap.push(getCDNUrl(attachment.attachmentUrl));
            if ((window.innerWidth > 1000 || (window.innerWidth > 600 && window.innerWidth <= 1000)) && attachment.resizedImages && attachment.resizedImages['1000x1000'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['1000x1000']));
            } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['800x800'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['800x800']));
            } else {
                photoListMap.push(getCDNUrl(attachment.attachmentUrl));
            }
        })
        if(this.props.item && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost) {
            photoListMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
            photoListOriginalMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
            this.setState({
                paidContentPostLock: true
            })
        }
        
        this.setState({'photoListMap': photoListMap, 'photoListOriginalMap': photoListOriginalMap,});
    }

    onSuccessUpdatePost = (data) => {
        this.setState({
            description: data.description,
            tags: data.tags
        })
    }

    render() {
        return (
            <div className={`hpostblock` + (this.props.item.nominated && this.props.postSection === 'FEED' ? ' pNominate' : '')}>                    
                <div className="hpostblockinn">
                    {
                        this.props.item && this.props.item.eventMessage &&
                        <EventMessageSection item={this.props.item} />
                    }
                    {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}
                    
                    <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                    <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={this.state.tags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType={"PHOTO"} />                      

                    <div className="hpostcontent">

                        <div className="hpostconttop">                            
                            {((this.state.description && this.state.description !== '') || this.state.tags ) &&
                                <ReadMoreReadLess id={this.props.item.id} description={this.state.description} hashTags={this.state.tags} item={this.props.item} />
                            }
                            {this.state.photoListMap && this.state.photoListMap.length > 0 &&
                                <Images images={this.state.photoListMap} countFrom={5} photoListOriginalMap={this.state.photoListOriginalMap} paidContentPostLock={this.state.paidContentPostLock} />
                            }                                
                            {this.props.item.postLocation &&
                            <span className="address"><i
                                className="iLocation"/>{this.props.item.postLocation}</span>
                            }
                        </div>

                        <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} />
                        
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(PhotosPostItem);
