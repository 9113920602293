import axios from "axios";
import { Apis } from "../config/api";

export const participantsFindInvitee = async (data) => {
    try {
        const response = await axios.get(Apis.ParticipantsFindInvitee(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProjectDetailsById = async (data) => {
    try {
        const response = await axios.get(Apis.ProjectDetailsById(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProjectProfileVisibility = async (data) => {
    try {
        const response = await axios.get(Apis.ProjectProfileVisibility(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProjectUserHideConfig = async (projectId) => {
    try {
        const response = await axios.get(Apis.ProjectUserHideConfig(projectId));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProjectHiddenConfig = async (data) => {
    try {
        const response = await axios.get(Apis.ProjectHiddenConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProjectThreeDotConfig = async (data) => {
    try {
        const response = await axios.get(Apis.ProjectThreeDotConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getPrivateProjectChangeStatus = async (postData) => {
    try {
        const response = await axios.post(Apis.PrivateProjectChangeStatus, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}