import React from "react";
import axios from "axios";
import date from "date-and-time";
import dateUtil from "date-and-time";
import * as _ from "lodash";

import httpService from '../../../../AxiosInterceptors';
import {getCDNUrl} from "../../../../commons/CommonFunctions";

httpService.setupInterceptors();

const mappingUtils = (function (namespace = {}) {
    namespace.fetchtopPerformers = async (pageNo = 0, pageSize = 6) => {
        // let listOfPerformers = [];

        return await axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/backend/show-all/top-performers/" +
                "?page=" +
                pageNo +
                "&size=" +
                pageSize,
            headers: {"Content-Type": "application/json", cache: true},
            withCredentials: true,
        });
        //return listOfPerformers;
    };
    namespace.getNoOfDaysAfterPosted = function (postedDate) {
        if (postedDate == undefined || postedDate == null) return 0;
        let dateOfPosting = new Date(postedDate);
        let noOfDays = date.subtract(new Date(), dateOfPosting).toDays();
        return Math.trunc(noOfDays);
    };

    namespace.getEmploymentType = function (employmentType) {
        if (_.isUndefined(employmentType) || _.isNull(employmentType)) {
            return '';
        } else {
            if (employmentType === 'FULL_TIME') {
                return ' - Full-Time';
            } else if (employmentType === 'PART_TIME') {
                return ' - Part-Time';
            } else if (employmentType === 'SELF_EMPLOYED') {
                return ' - Self-Employed';
            } else {
                return ' - ' + this.capitalizeFirstLetter(employmentType);
            }
        }
        return '';
    };

    namespace.getNoOfDaysRemaining = function (endDate) {
        let noOfDays = date.subtract(new Date(endDate), new Date()).toDays();
        return Math.trunc(noOfDays);
    };


    namespace.capitalizeFirstLetter = function (s) {
        return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
    };

    namespace.getResponsiveness = function () {
        let responsiveness = {
            0: {
                items: 1,
            },

            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        };
        return responsiveness;
    };


    namespace.mapVentureBlock = (content) => {
        let project = content.project;
        let slug = project.slug;
        let projectId = project.id;
        let isOffLine = project.isOffLine;
        let companyName = project.companyName;
        let creatorName = content.creatorName;
        let feedPageShow = content.feedPageShow;
        let visibleToProfile = content.visibleToProfile;
        let sharingSettingConfigDto = content.sharingSettingConfigDto;
        let canDelete = content.canDelete;
        let studioUserAvailable = content.studioUserAvailable;
        let canEdit = content.canEdit;
        let canHide = content.canHide;
        let canCancel = content.canCancel;
        let cancelled = content.cancelled;
        let changeProjectVisibility = content.changeProjectVisibility;
        let privateVisibility = content.privateVisibility;
        let subscribed = content.subscribed;
        let userId =
            _.isNull(content.participatingUserId) ||
            _.isUndefined(content.participatingUserId)
                ? project.userId
                : content.participatingUserId;
        let customUrl = content.customUrl;
        let packageType = project.packageType;
        let endingTime = project.endingTime;
        let createdOn =
            project.createTime > 0
                ? dateUtil.format(new Date(project.createTime), "DD MMM YYYY")
                : null;
        let coverImage = mappingUtils.getCoverImage(project);
        let projectType = content.projectType;
        let partyType = content.partyType;
        let secondaryEntityType = content.secondaryEntityType;
        let isRunning = mappingUtils.checkRunning(project.beginningTime, project.endingTime);
        let pinned = content.pinned;
        let description = project.description;
        let shortBrief = project.shortBrief;
        let dateOfParticipation = 'NA';
        if (content.dateOfParticipation > 0) {
            dateOfParticipation = dateUtil.format(new Date(content.dateOfParticipation), "DD MMM YYYY");
        }
        let startDate = dateUtil.format(new Date(project.beginningTime), "DD MMM YYYY");
        let endDate;
        if (project.endingTime === 0) {
            endDate = dateUtil.format(new Date(project.lastTimeOfApplication), "DD MMM YYYY");
        } else {
            endDate = dateUtil.format(new Date(project.endingTime), "DD MMM YYYY");
        }
        let demolishedDate = content.demolishedDate > 0
            ? dateUtil.format(new Date(content.demolishedDate), "DD MMM YYYY")
            : null;

        let lastDateOfApplication =
            project.lastTimeOfApplication > 0 &&
            project.lastTimeOfApplication
                ? dateUtil.format(new Date(project.lastTimeOfApplication), "DD MMM YYYY")
                : null;
        let lastDateOfApplicationForFooter = project.lastTimeOfApplication;
        let location =project.location;
        let state = location && location.state;
        let city = location && location.city;
        let country = location && location.country;
        let address = location && location.address;
        let jobType = project.jobType;
        let currency = project.currency;
        let isNegotiable = project.isNegotiable;
        let isDisclosed = project.isDisclosed;
        let role = project.role;
        let daysAfterPosted = namespace.getNoOfDaysAfterPosted(
            content.dateOfParticipation
        );
        let opening = content.openings;
        let netNoOfOpenings = content.netTotalPositions;
        let daysRemaining = undefined;
        if (lastDateOfApplication && lastDateOfApplication !== null) {
            daysRemaining = namespace.getNoOfDaysRemaining(
                project.lastTimeOfApplication
            );
        }

        let title = project.title;
        let fees = project.cost;
        let participationCount = content.participationCount;
        let projectDetails = {};
        projectDetails["projectId"] = projectId;
        projectDetails["noOfDaysPosted"] = namespace.getNoOfDaysAfterPosted(
            content.dateOfParticipation
        );
        projectDetails["dateOfParticipation"] = dateOfParticipation;
        projectDetails["profileVisibilityTabShow"] = content.profileVisibilityTabShow;
        projectDetails["creatorUserId"] = content.creatorUserId;
        projectDetails["userId"] = project.userId;
        projectDetails["title"] = title;
        projectDetails["slug"] = slug;
        projectDetails["location"] = project.location;
        projectDetails["sharingSettingConfigDto"] = sharingSettingConfigDto;
        projectDetails["feedPageShow"] = feedPageShow;
        projectDetails["visibleToProfile"] = visibleToProfile;
        projectDetails["canDelete"] = canDelete;
        projectDetails["canEdit"] = canEdit;
        projectDetails["canHide"] = canHide;
        projectDetails["createTime"] = project.createTime;
        projectDetails["running"] = isRunning;
        projectDetails["canCancel"] = canCancel;
        projectDetails["cancelled"] = cancelled;
        projectDetails["demolishedDate"] = demolishedDate;
        projectDetails["coverImage"] = coverImage;
        projectDetails["fees"] = fees;
        projectDetails["pinned"] = pinned;
        projectDetails["id"] = projectId;
        projectDetails["projectType"] = projectType;
        projectDetails["endingTime"] = project.endingTime;
        projectDetails["participationCount"] = participationCount;
        projectDetails["role"] = role;
        projectDetails["noOfOpening"] = project.noOfOpening;
        projectDetails["secondaryEntityType"] = secondaryEntityType;
        projectDetails["partyType"] = partyType;
        projectDetails["isOffLine"] = isOffLine;
        projectDetails["changeProjectVisibility"] = changeProjectVisibility;
        projectDetails["privateVisibility"] = privateVisibility;
        projectDetails["subscribed"] = subscribed;
        projectDetails["studioUserAvailable"] = studioUserAvailable;
        projectDetails["donation"] = content.donation;
        projectDetails["bookMark"] = content.bookMark;
        return {
            startDate,
            endDate,
            lastDateOfApplicationForFooter,
            description,
            endingTime,
            companyName,
            isOffLine,
            coverImage,
            secondaryEntityType,
            isNegotiable,
            canEdit,
            location,
            creatorName,
            createdOn,
            customUrl,
            currency,
            packageType,
            isDisclosed,
            startDateDay: dateUtil.format(new Date(project.beginningTime), "DD"),
            startDateMonth: dateUtil.format(new Date(project.beginningTime), "MMM"),
            startDateYear: dateUtil.format(new Date(project.beginningTime), "YYYY"),
            fees,
            city,
            state,
            country,
            address,
            title,
            userId,
            participationCount,
            projectId,
            jobType,
            shortBrief,
            daysAfterPosted,
            daysRemaining,
            projectDetails,
            netNoOfOpenings,
            opening,
            dateOfParticipation,
            slug
        };
    };

    namespace.checkRunning = (startTime, endTime) => {
        if (startTime && endTime) {
            if (startTime <= new Date().getTime() && endTime >= new Date().getTime()) {
                return true;
            }
        }
        return false;

    }

    namespace.checkAdminAvailable = (userId,adminUserId, loggedInUserId, adminIds) => {
        if(userId === loggedInUserId || adminUserId === loggedInUserId || (adminIds && adminIds.includes(loggedInUserId))){
            return true;
        }
        return false;

    }


    namespace.getProjectTypeValue = (text) =>{
        if(text.toLowerCase() === 'fundraise'){
            return 'Fund Raise'
        }
        if(text.toLowerCase() === 'storybook'){
            return 'Story Book'
        }
        return text
    }

    namespace.checkAdminAvailableForIndividual = (userId, loggedInUserId, adminIds) => {
        if(userId === loggedInUserId || (adminIds && adminIds.includes(loggedInUserId))){
            return true;
        }
        return false;

    }

    namespace.getPrivacySettingDataForCircle = (data, type) => {
        if(!data || !type || !data.privacySetting){
            return true;
        }
        if((Object.keys(data.privacySetting).length === 0) || (data.privacySetting[type])){
            return  true;
        }
        return  false;

    }

    namespace.getPrivacySettingData = (data, type) => {
        if(!data || !type || !data.privacySetting){
            return false;
        }
        if((Object.keys(data.privacySetting).length === 0) || (data.privacySetting[type])){
            return  true;
        }
        return  false;

    }

    namespace.getSharingSettingData = (data, type) => {
        if(!data || !type || !data.sharingSetting || _.isUndefined(data.sharingSetting[type])){
            return true;
        }
        if((Object.keys(data.sharingSetting).length === 0) || (data.sharingSetting[type])){
            return  true;
        }
        return  false;

    }

    namespace.getFormattedDate = (milliseconds) => {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (!milliseconds) {
            return "";
        }
        var utcSeconds = milliseconds;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear();
    }

    namespace.getSharingSettingDataForUnAuth = (data, type) => {
        if(!data || !type || !data.sharingSetting){
            return true;
        }
        if((data.sharingSetting[type])){
            return  true;
        }
        return  false;

    }

    namespace.mapTrendingEvents = (content) => {
        let participationCount = content.participationCount;
        let project = content.project;
        let slug = project.slug
        let canEdit = content.canEdit;
        let changeProjectVisibility = content.changeProjectVisibility;
        let privateVisibility = content.privateVisibility;
        let subscribed = content.subscribed;
        let studioUserAvailable = content.studioUserAvailable;
        let creatorName = content.creatorName;
        let feedPageShow = content.feedPageShow;
        let visibleToProfile = content.visibleToProfile;
        let canDelete = content.canDelete;
        let canCancel = content.canCancel;
        let cancelled = content.cancelled;
        let customUrl = content.customUrl;
        let packageType = project.packageType;
        let sharingSettingConfigDto = content.sharingSettingConfigDto;
        let projectId = project.id || project.projectId;
        let currency = project.currency;
        let secondaryEntityType = content.secondaryEntityType || project.secondaryEntityType;
        let isDisclosed = project.isDisclosed;

        let startDate = dateUtil.format(new Date(project.beginningTime), "DD MMM YYYY");
        let description = project.description;
        let dateOfParticipation = 'NA';
        if (content.dateOfParticipation > 0) {
            dateOfParticipation = dateUtil.format(new Date(content.dateOfParticipation), "DD MMM YYYY");
        }
        let shortBrief = project.shortBrief;
        let location = project.location;
        let city = location && location.city;
        let state = location && location.state;
        let country = location && location.country;
        let address = location && location.address;
        let fees = project.cost || project.remuneration || project.renumeration;
        let title = project.title;
        let donation = content.donation;
        let bookMark = content.bookMark || false;
        let userId =
            _.isNull(content.participatingUserId) ||
            _.isUndefined(content.participatingUserId)
                ? project.userId
                : content.participatingUserId;
        let obj = {
            userId,
            participationCount,
            startDate,
            startDateDay: dateUtil.format(new Date(project.beginningTime), "DD"),
            startDateMonth: dateUtil.format(new Date(project.beginningTime), "MMM"),
            startDateYear: dateUtil.format(new Date(project.beginningTime), "YYYY"),
            title,
            creatorName,
            customUrl,
            canEdit,
            changeProjectVisibility,
            privateVisibility,
            subscribed,
            studioUserAvailable,
            packageType,
            currency,
            isDisclosed,
            secondaryEntityType,
            sharingSettingConfigDto,
            projectId,
            city,
            state,
            country,
            address,
            location,
            fees,
            description,
            shortBrief,
            slug,
            donation
        };
        let role = project.jobRole || project.assignmentRole;
        let pDetails = project;
        pDetails["noOfDaysPosted"] = namespace.getNoOfDaysAfterPosted(
            content.dateOfParticipation
        );
        pDetails["dateOfParticipation"] = dateOfParticipation;
        pDetails["profileVisibilityTabShow"] = content.profileVisibilityTabShow;
        pDetails["creatorUserId"] = content.creatorUserId;
        pDetails["userId"] = project.userId;
        pDetails["creatorName"] = creatorName;
        pDetails["location"] = location;
        pDetails["visibleToProfile"] = visibleToProfile;
        pDetails["canDelete"] = canDelete;
        pDetails["canEdit"] = canEdit;
        pDetails["canCancel"] = canCancel;
        pDetails["cancelled"] = cancelled;
        pDetails["feedPageShow"] = feedPageShow;
        pDetails["fees"] = fees;
        pDetails["participationCount"] = participationCount;
        pDetails["sharingSettingConfigDto"] = sharingSettingConfigDto;
        pDetails["role"] = role;
        pDetails["shortBrief"] = shortBrief;
        pDetails["secondaryEntityType"] = secondaryEntityType;
        pDetails["noOfOpening"] = project.netNoOfOpenings;
        pDetails["projectId"] = project.id || project.projectId;
        pDetails["coverImage"] = mappingUtils.getCoverImage(project);
        pDetails["slug"] = slug;
        pDetails["changeProjectVisibility"] = changeProjectVisibility;
        pDetails["privateVisibility"] = privateVisibility;
        pDetails["subscribed"] = subscribed;
        pDetails["studioUserAvailable"] = studioUserAvailable;
        pDetails["bookMark"] = bookMark;
        obj.projectDetails = project;
        return obj;
    };

    namespace.mapTopCompany = (content) => {
        let participationCount = content.participationCount;
        let postedBy = content.postedby;
        let userId = content.participatingUserId;
        let userObj = {userId, participationCount, postedBy};

        return userObj;
    };

    namespace.getCoverImage = (project) => {
        if (!project) {
            return project;
        }
        // if (project.resizedImages && project.resizedImages.compressed) {
        //     return getCDNUrl(project.resizedImages.compressed);
        // }
        // return getCDNUrl(project.coverImage);

        if (window.innerWidth > 600 && project.resizedImages && project.resizedImages['600x600']) {
            return getCDNUrl(project.resizedImages['600x600']);
        } else if (window.innerWidth > 320 && window.innerWidth <= 600 && project.resizedImages && project.resizedImages['400x400']) {
            return getCDNUrl(project.resizedImages['400x400']);
        } else {
            return getCDNUrl(project.coverImage);
        }
    };

    namespace.getDraftCoverImage = (resizedImages, originalImages) => {
        if (window.innerWidth > 600 && resizedImages && resizedImages['600x600']) {
            return getCDNUrl(resizedImages['600x600']);
        } else if (window.innerWidth > 320 && window.innerWidth <= 600 && resizedImages && resizedImages['400x400']) {
            return getCDNUrl(resizedImages['400x400']);
        } else {
            return getCDNUrl(originalImages);
        }
    };


    namespace.sanitize = (url) => {
        let PROTOCOL_WHITELIST = ['http://', 'https://'];
        if (url) {
            for (let i = 0; i < PROTOCOL_WHITELIST.length; i++)
                if (url.startsWith(PROTOCOL_WHITELIST[i]))
                    return url;
            return 'http://' + url
        }
        return url;
    }

    namespace.checkEmptyWebsite = (website) => {
        if (_.isUndefined(website) || _.isNull(website) || website === '') {
            return false;
        }
        return true;
    }



    namespace.checkOffLine = (isOffLine) => {
        if (!_.isUndefined(isOffLine) && !_.isNull(isOffLine) && isOffLine === true) {
            return true;
        }
        return false;
    }

    namespace.checkThreeDots = (isMakeAdmin, isRevokeMemberShip, revokeAdmin, isRemoveMemberShip,adminManagePermissionAllowed) => {
        if (isMakeAdmin || isRevokeMemberShip || revokeAdmin || isRemoveMemberShip || adminManagePermissionAllowed) {
            return true;
        }
        return false;
    }

    namespace.checkFirstCriteriaForMessageGroup = (groupDetails, userId, eachMember) => {
        if(groupDetails.admins && userId !== eachMember && groupDetails.admins.indexOf(userId) !== -1 && groupDetails.admins.indexOf(eachMember) === -1){
            return true;
        }
        return false;
    }

    namespace.checkSecondCriteriaForMessageGroup = (groupDetails, userId, eachMember) => {
       if(groupDetails.admins  && groupDetails.admins.indexOf(userId) !== -1 && groupDetails.admins.indexOf(eachMember) !== -1 && groupDetails.admins.length > 1){
            return true;
       }
        return false;
    }


    namespace.checkThirdCriteriaForMessageGroup = (groupDetails, userId, eachMember) => {
        if(groupDetails.admins && groupDetails.admins.indexOf(userId) !== -1 && (userId !== eachMember || groupDetails.admins.length > 1) ){
            return true;
        }
        return false;
    }




    namespace.returnDefaultImage = (userType) => {
        if (!userType) {
            return 'https://cdn.dscovr.com/images/users1.png';
        } else if (userType === 'INDIVIDUAL') {
            return 'https://cdn.dscovr.com/images/users1.png';
        } else if (userType === 'COMPANY') {
            return 'https://cdn.dscovr.com/images/DefaultBusiness.png';
        }
        return null;

    }

    namespace.getUserType = (authData) => {
        if (!authData || !authData.type) {
            return 'INDIVIDUAL';
        } else if (authData.type === 'INDIVIDUAL') {
            return 'INDIVIDUAL';
        } else{
            return 'BUSINESS_PAGES'
        }

    }

    namespace.getPartyType = (partyType) => {
        if (!partyType) {
            return 'INDIVIDUAL';
        } else if (partyType === 'INDIVIDUAL_OR_RECRUITER') {
            return 'INDIVIDUAL';
        }
        return  partyType;

    }

    namespace.sorting = (items) => {
        if (items.length === 0) {
            return [];
        }
        items.sort(function (a, b) {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
        return items;
    }

    namespace.isMessageAcknowledged = (items) => {
        if (!items || items.length === 0) {
            return false;
        }
        let value = true;
        Object.keys(items).map(function(keyName, keyIndex) {
            if(items[keyName]){
                value = false;
                return false;
            }
        })

        return value;
    }

    namespace.isMessageTimeElapsed = (time) => {
        let timeDifference = new Date().getTime() - time;

        if( timeDifference> 3600000){
            return  true;
        }

        return false;
    }

    namespace.circleSorting = (items) => {
        if (items.length === 0) {
            return [];
        }
        items.sort(function (a, b) {
            let nameA = a.title.toUpperCase();
            let nameB = b.title.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
        return items;
    }
    namespace.businessPagesSorting = (data) => {
        if (data.businessPage.length === 0) {
            data.businessPage = [];
            return data;
        }
        data.businessPage.sort(function (a, b) {
            let nameA = a.companyName.toUpperCase();
            let nameB = b.companyName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
        return data;
    }


    namespace.isValidProjectType = (type) => {
        if (_.isUndefined(type) || _.isNull(type) || type === '' || type.toLowerCase() === 'select') {
            return false;
        }
        return true;

    }

    namespace.isProject = (type) => {
        if (_.isUndefined(type) || _.isNull(type) || type === '' || type.toLowerCase() === 'select') {
            return false;
        }
        return (type.toLowerCase === 'job' ||
            type.toLowerCase === 'assignment' ||
            type.toLowerCase === 'event' ||
            type.toLowerCase === 'training' ||
            type.toLowerCase === 'expedition' ||
            type.toLowerCase === 'storybook' ||
            type.toLowerCase === 'fundraise');



    }

    namespace.getCountry = (project) => {
        if (project.location) {
            return project.location.country;
        }
    }

    namespace.getState = (project) => {
        if (project.location) {
            return project.location.state;
        }
    }

    namespace.getCity = (project) => {
        if (project.location) {
            return project.location.city;
        }
    }

    namespace.getEndDateCustom = (endDateTime,lastTimeOfApplication,projectType) => {

        if(projectType && (projectType === 'JOB' || projectType === 'ASSIGNMENT') && lastTimeOfApplication && endDateTime === 0){
            if(lastTimeOfApplication < new Date().getTime()){
                return new Date(lastTimeOfApplication).toLocaleDateString('en-GB', {
                    day: '2-digit', month: 'short', year: 'numeric'
                }).replace(/ /g, '-');
            }
        }
        if (endDateTime === 0 || endDateTime > new Date().getTime()) {
            return 'Running';
        } else {
            return new Date(endDateTime).toLocaleDateString('en-GB', {
                day: '2-digit', month: 'short', year: 'numeric'
            }).replace(/ /g, '-');
        }
    }

    namespace.getEndDateCustomForExperience = (endDateTime) => {
        if (endDateTime === 0 || endDateTime > new Date().getTime()) {
            return 'Present';
        } else {
            return new Date(endDateTime).toLocaleDateString('en-GB', {
                day: '2-digit', month: 'short', year: 'numeric'
            }).replace(/ /g, '-');
        }
    }

    namespace.getStartDateCustom = (startTime) => {
        if (_.isNull(startTime) || _.isUndefined(startTime)) {
            return "NA";
        }
        return new Date(startTime).toLocaleDateString('en-GB', {
            day: '2-digit', month: 'short', year: 'numeric'
        }).replace(/ /g, '-');
    }

    namespace.getAdminCustomDate = (time) => {
        if (_.isNull(time) || _.isUndefined(time)) {
            return "NA";
        }
        return new Date(time).toLocaleDateString('en-GB', {
             month: 'short', year: 'numeric'
        }).replace(/ /g, '-');
    }

    namespace.getProjectUserType = (type) => {
        if (type === 'INDIVIDUAL') {
            return 'INDIVIDUAL';
        } else{
            return 'BUSINESS_PAGES'
        }
    }

    namespace.mapTrendingOpportunity = (project) => {
        let projectId = project.projectId;
        let proj = project.project;
        let slug = proj.slug;
        let creatorName = project.creatorName;
        let userId =
            _.isNull(project.participatingUserId) ||
            _.isUndefined(project.participatingUserId)
                ? project.userId
                : project.participatingUserId;
        let customUrl = project.customUrl;
        let params = project.params;
        let packageType = proj.packageType;
        let sharingSettingConfigDto = project.sharingSettingConfigDto;
        let participationCount = project.participationCount;
        let isDisclosed = proj.isDisclosed;
        let companyName = proj.companyName;
        let currency = proj.currency;
        let canEdit = project.canEdit;
        let changeProjectVisibility = project.changeProjectVisibility;
        let privateVisibility = project.privateVisibility;
        let subscribed = project.subscribed;
        let studioUserAvailable = project.studioUserAvailable;
        let projectType = project.secondaryEntityType;
        let dateOfParticipation;
        let partyType = project.partyType;
        let feedPageShow = project.feedPageShow;
        let bookMark = project.bookMark || false;
        if (project.dateOfParticipation > 0) {
            dateOfParticipation = dateUtil.format(new Date(project.dateOfParticipation), "DD MMM YYYY");
        }

        let shortBrief = proj.shortBrief;
            let location = proj.location;
            let title = proj.title;
            let fees = proj.cost;
            let role = proj.role;
        let pDetails = proj;
        pDetails["projectType"] = projectType;
        pDetails["slug"] = slug;
        pDetails["noOfDaysPosted"] = namespace.getNoOfDaysAfterPosted(
            project.dateOfParticipation
        );
        pDetails["dateOfParticipation"] = dateOfParticipation;
        pDetails["profileVisibilityTabShow"] = project.profileVisibilityTabShow;
        pDetails["creatorUserId"] = project.creatorUserId;
        pDetails["userId"] = project.userId;
        pDetails["title"] = title;
        pDetails["location"] = location;
        pDetails["fees"] = fees;
        pDetails["currency"] = currency;
        pDetails["participationCount"] = participationCount;
        pDetails["role"] = role;
        pDetails["secondaryEntityType"] = project.secondaryEntityType;
        pDetails["noOfOpening"] = project.netNoOfOpenings;
        pDetails["projectId"] = project.projectId;
        pDetails["partyType"] = partyType;
        pDetails["coverImage"] = mappingUtils.getCoverImage(proj);
        pDetails["feedPageShow"] = feedPageShow;
        pDetails["canEdit"] = canEdit;
        pDetails["changeProjectVisibility"] = changeProjectVisibility;
        pDetails["privateVisibility"] = privateVisibility;
        pDetails["subscribed"] = subscribed;
        pDetails["studioUserAvailable"] = studioUserAvailable;
        pDetails["donation"] = project.donation;
        pDetails["bookMark"] = bookMark;

        return {
            date: project.dateOfParticipation,
            participationCount,
            companyName,
            startDateDay: dateUtil.format(new Date(pDetails.beginningTime), "DD"),
            startDateMonth: dateUtil.format(new Date(pDetails.beginningTime), "MMM"),
            startDateYear: dateUtil.format(new Date(pDetails.beginningTime), "YYYY"),
            creatorName,
            customUrl,
            packageType,
            isDisclosed,
            location,
            userId,
            feedPageShow,
            title,
            sharingSettingConfigDto,
            fees,
            currency,
            projectType,
            shortBrief,
            projectId,
            noOfDaysPosted: namespace.getNoOfDaysAfterPosted(dateOfParticipation),
            modalPopupId: proj.id + projectType,
            projectDetails: pDetails,
            slug,
            params
        };
    };
    return namespace;
})();

export default mappingUtils;
