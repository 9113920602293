import React, { Component } from 'react';
import { authService } from '../../../auth';
import { projectFromTypeList } from '../../../commons/CommonFunctions';
import { withRouter } from 'react-router-dom';


class ProjectTypeSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            projectDonationConfig: this.props.projectDonationConfig,
            subscriptionConfig: this.props.subscriptionConfig
        }
    }

    addANewProjectHandler = async (event, formType) => {
        event.preventDefault();
        let activeForm = event.target.name;
        let modal = '#projadd' + activeForm;
            window.$("#addpostpop").modal("hide");
            this.setState({ activeForm: activeForm }, () => {
                this.setState({ refresh: true });
                if (activeForm !== 'offLine') {
                    this.props.history.push({
                        pathname: '/project/create-projects',
                        state: {
                            formId: "projadd" + activeForm.toLowerCase(),
                            Key: activeForm,
                            type: activeForm,
                            mode: "insert",
                            isOffLine: formType === "ONLINE" ? false : true,
                            formVO: this.state.formVO,
                            isOfflineProject: formType === "ONLINE" ? false : true,
                            refresh: this.state.refresh,
                        }
                    })
                } else {
                    window.$(modal).modal('show');
                }
            });

    };

    render() {
        const { formType, pageType } = this.props;
        return (
            <>
                {projectFromTypeList && projectFromTypeList.map((item) => {
                    return (
                        ((formType === "ONLINE" && item.value !== "storybook") || (formType === "OFFLINE" && item.value !== "job")) &&
                        <div className={pageType === "PROJECT" ? '' : "col-6 col-sm-4"}>
                            <a href="javascript:void(0)" className={pageType === "PROJECT" ? "dropdown-item" : "postCard"} name={item.value} onClick={(e) => this.addANewProjectHandler(e, formType)}>
                                {item.name}
                            </a>
                        </div>
                    )
                })}
            </>
        )
    }
}

export default withRouter(ProjectTypeSection);
