import React, { PureComponent } from 'react';
import { authService } from "../auth";
import httpService from "../AxiosInterceptors";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import ExploreBlogSharePreview from "./ExploreBlogSharePreview";
import PostSharePreview from "./PostSharePreview";
import ProjectSharePreview from "./ProjectSharePreview";
import ForumSharePreview from "./ForumSharePreview";
import PollSharePreview from "./PollSharePreview";
import StorySharePreview from "./StorySharePreview";
import ShareContentRemovedPreview from "./ShareContentRemovedPreview";
import StudioSharePreview from "./StudioSharePreview";
import EventMessageSection from '../commons/EventMessageSection';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import CommonPostMenuSection from './CommonPostMenuSection';
import CommonPostBottomSection from './CommonPostBottomSection';

httpService.setupInterceptors();

class SharePostItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            description: props.item.description,
            editedText: props.item.description,
            hashTags: [],
            tags: [],
            smileDisplay: false
        }
    }

    componentDidMount() {
        let tags = [];
        this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {
            tags.push(data);
            this.setState({ tags: tags });
        })

    }

    onSuccessUpdatePost = (data) => {
        this.setState({
            description: data.description,
            tags: data.tags
        })
    }

    render() {
        return (
            <div className="hpostblock">
                <div className="hpostblockinn">
                    {
                        this.props.item && this.props.item.eventMessage &&
                        <EventMessageSection item={this.props.item} />
                    }
                    {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

                    <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                    <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={this.state.tags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType="SHARE" />

                    <div className="hpostcontent">
                        <div className="hpostconttop">
                            {((this.state.description && this.state.description !== '') || this.state.tags) &&
                                <ReadMoreReadLess id={this.props.item.id} description={this.state.description} hashTags={this.state.tags} />}

                            {
                                (this.props.item.sharedEntityId && this.props.item.sharedEntityId !== '')
                                    ? (this.props.item.sharedEntityParams && Object.keys(this.props.item.sharedEntityParams).length > 0
                                        ? (this.props.item.sharedEntityParams['content-not-found'] ?
                                            <ShareContentRemovedPreview />
                                            : (!this.props.item.sharedEntityParams['max-depth-reached'] ?
                                                (this.props.item.sharedEntityType === 'EXPLOREBLOG'
                                                    ?
                                                    <ExploreBlogSharePreview type={this.props.item.sharedEntityType}
                                                        item={this.props.item.sharedEntityParams} />
                                                    : ((this.props.item.sharedEntityType === 'ASSIGNMENT')
                                                        || (this.props.item.sharedEntityType === 'JOB')
                                                        || (this.props.item.sharedEntityType === 'TRAINING')
                                                        || (this.props.item.sharedEntityType === 'EXPEDITION')
                                                        || (this.props.item.sharedEntityType === 'STORYBOOK')
                                                        || (this.props.item.sharedEntityType === 'FUNDRAISE')
                                                        || (this.props.item.sharedEntityType === 'EVENT'))
                                                        ?
                                                        <ProjectSharePreview type={this.props.item.sharedEntityType}
                                                            item={this.props.item.sharedEntityParams} />
                                                        : ((this.props.item.sharedEntityType === 'FORUM' || this.props.item.sharedEntityParams?.postType === "FORUM")
                                                            ? <ForumSharePreview
                                                                type={this.props.item.sharedEntityType}
                                                                item={this.props.item.sharedEntityParams}
                                                                entityItem={this.props.item} />
                                                            : ((this.props.item.sharedEntityType === 'POLL' || this.props.item.sharedEntityParams?.postType === "POLL")
                                                                ? <PollSharePreview
                                                                    type={this.props.item.sharedEntityType}
                                                                    item={this.props.item.sharedEntityParams}
                                                                    entityItem={this.props.item} />
                                                                : (this.props.item.sharedEntityType === 'POST'
                                                                    ? <PostSharePreview
                                                                        type={this.props.item.sharedEntityType}
                                                                        item={this.props.item.sharedEntityParams}
                                                                        entityItem={this.props.item} />
                                                                    : (this.props.item.sharedEntityType === 'STORY'
                                                                        ? <StorySharePreview
                                                                            type={this.props.item.sharedEntityType}
                                                                            item={this.props.item.sharedEntityParams} />
                                                                        : (this.props.item?.sharedEntityType === 'STUDIO' ?
                                                                            <StudioSharePreview
                                                                                type={this.props.item?.sharedEntityType}
                                                                                item={this.props.item?.sharedEntityParams}
                                                                                entityItem={this.props.item}
                                                                            /> : null)))))) : null)
                                        ) : null)
                                    : null

                            }
                        </div>

                        <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} />

                    </div>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(SharePostItem);
