import React from 'react'
import Header from '../Header';
import LeftPanelSettings from './LeftPanelSettings';
import {Redirect} from 'react-router-dom';
import {authService} from '../auth';
import SecurityAndLoginDetails from "./SecurityAndLoginDetails";
class SecurityAndLogin extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            localStorageData: authService.getUserDetailsFromLocalStorage(),
            userId: authService.getUserDetailsFromLocalStorage()?.userId,
            superAdminFlag: false
        };
    }

    getLicenceConnfig = (data) => {
        this.setState({
            superAdminFlag: data?.type === "SUPER_ADMIN"
        })
    }

    componentDidMount() {
        window.$(".feqtopsearch").sticky({topSpacing: 65, responsiveWidth: true});
        document.title = "Explore a new world of Naturalists | WeNaturalists";
    }

    render() {
        if (!this.state.loginSuccess) {
            return <Redirect to='/login'/>
        } else {
            const { userId, superAdminFlag } = this.state;
            return <div className="wrapper settingspage">
                <Header/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <LeftPanelSettings getLicenceConnfig={this.getLicenceConnfig} />
                            <SecurityAndLoginDetails userId={userId} superAdminFlag={superAdminFlag} />
                        </div>
                    </div>
                </main>
            </div>
        }
    }
}

export default SecurityAndLogin
