import React, { memo } from "react";
import { Component } from "react";
import { authService } from "../../auth";
import _ from "lodash";
import axios from "axios";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { userAgentGetData } from "../../commons/CommonFunctions";

const ErorrMsgPopUp = withReactContent(alertPopUp);
class UserHelpDeskPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            wenatProfileLink: "",
            profileName: "",
            emailId: "",
            phoneNo: "",
            designation: "",
            shortBrief: "",
            masterUserData: {},
            helpDeskId: "",
            isSubmitDisabled: false,
            profileErrorMessage: "",
            errors: {}
        }
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.wenatProfileLink) || _.isEmpty((this.state.wenatProfileLink || "").toString()) || _.isNull(this.state.wenatProfileLink)) {
            errors["wenatProfileLink"] = "Please enter wenat profile link.";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.profileName) || _.isEmpty((this.state.profileName || "").toString()) || _.isNull(this.state.profileName))) {
            errors["profileName"] = "Please enter name.";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.emailId) || _.isEmpty((this.state.emailId || "").toString()) || _.isNull(this.state.emailId))) {
            errors["emailId"] = "Please enter email id.";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.designation) || _.isEmpty((this.state.designation || "").toString()) || _.isNull(this.state.designation))) {
            errors["designation"] = "Please enter designation.";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.shortBrief) || _.isEmpty((this.state.shortBrief || "").toString()) || _.isNull(this.state.shortBrief))) {
            errors["shortBrief"] = "Please enter short brief.";
            formIsValid = false;
        }
        if (this.state.wenatProfileLink) {
            let valudateLink = this.state.wenatProfileLink;
            let urlValid = valudateLink.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            );
            let urlValidWenat = valudateLink.startsWith(process.env.REACT_APP_domainUrl);
            if (!urlValidWenat || urlValid == null) {
                formIsValid = false;
                errors["wenatProfileLink"] = "Please enter a valid link";
            }
        }
        if (this.state.shortBrief) {
            if ((this.state.shortBrief).match(/\S+/g).length > 250) {
                formIsValid = false;
                errors["shortBrief"] = "Maximum 250 words allowed.";
            }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    onHandleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    }

    onBlueProfileChange = () => {
        let customUrl = (this.state.wenatProfileLink).substring((this.state.wenatProfileLink).lastIndexOf("/") + 1);
        if(customUrl) {
            axios({
                url: `${process.env.REACT_APP_userServiceURL}/profile/get_user_by_custom_url?customUrl=${customUrl}&otherUserId=${this.props.userId}&privateEntityId=${this.state.userData?.entityId}&${userAgentGetData()}`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.body && response.data.message === "Success!") {
                    let data = response.data.body;
                    this.setState({
                        emailId: data.email,
                        phoneNo: data.mobile,
                        profileName: data.userName,
                        profileErrorMessage: "",
                        masterUserData: data
                    })
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    profileErrorMessage: error.response.data.message,
                    emailId: "",
                    phoneNo: "",
                    profileName: "",
                    masterUserData: {}
                })
            });
        }

        setTimeout(() => {
            this.setState({
                profileErrorMessage: "",
            })
        }, 5000);
    }

    onCloseModal = () => {
        const {wenatProfileLink, profileName, emailId, phoneNo, designation, shortBrief} = this.state;
        if(wenatProfileLink || profileName || emailId || phoneNo || designation || shortBrief) {
            window.$("#cancelPopup").modal("show");
        } else {
            this.props.triggerHelpDeskModalClose();
        }
    }

    onSubmitHelpDesk = () => {
        if(this.validateForm()) {
            this.setState({
                isSubmitDisabled: true,
            })
            let postData = {
                userId: this.props.userId,
                masterUserId:  _.isEmpty(this.state.masterUserData) ? this.props.editHelpDeskId : this.state.masterUserData.userId,
                designation: this.state.designation,
                shortBrief: this.state.shortBrief,
                privateEntityId : this.state.userData.entityId
            }
            let url = `${process.env.REACT_APP_userServiceURL}/userMasterData/create`;
            if(this.props.editHelpDesk) {
                url = `${process.env.REACT_APP_userServiceURL}/userMasterData/update`;
                postData.id = this.state.helpDeskId;
            }
            axios({
                url: url,
                method: "POST",
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if ( response && response.data && response.data.message === "Success!") {
                    this.setState({
                        isSubmitDisabled: false,
                    })
                    this.props.triggerHelpDeskModalClose();
                    this.props.getHelpDeskDetails();
                }
            }).catch((error) => {
                console.log(error)
                this.setState({
                    isSubmitDisabled: false,
                })
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <><p>{error.response.data.message}</p></>,
                    })
                })
            });
        }
    }

    getHelpDeskDetailsById = (userId) => {
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/userMasterData/getMasterUserById?userId=${userId}&requestingUserId=${this.props.userId}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.message === "Success!") {
                let data = response.data.body;
                this.setState({
                    wenatProfileLink: `${process.env.REACT_APP_domainUrl}/profile/${data.customUrl}`,
                    profileName: data.username,
                    emailId: data.email,
                    phoneNo: data.phoneNumber,
                    designation: data.designation,
                    shortBrief: data.shortBrief,
                    helpDeskId: data.id
                })
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    componentWillUnmount() {
        window.$("#addHelpDeskPop").modal("hide");
        window.$("#cancelPopup").modal("hide");
        ErorrMsgPopUp.clickConfirm();
    }

    componentDidMount() {
        if(this.props.editHelpDesk) {
            this.getHelpDeskDetailsById(this.props.editHelpDeskId);
        }
    }

    render() {
        const {wenatProfileLink, profileName, emailId, phoneNo, designation, shortBrief, isSubmitDisabled} = this.state;
        return (
            <><div id="addHelpDeskPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="addHelpDeskPop">
                                <button type="button" className="close topCrossModal" aria-label="Close" onClick={this.onCloseModal}>
                                    <i className="iCross" />
                                </button>
                                <h2>Contact Details</h2>
                                <div>
                                    <div className="form-group">
                                        <label>WeNaturalists Profile Link <sup className="required-field">*</sup></label>
                                        <input type="text" class="form-control custom-input" placeholder="Add WeNaturalists Profile link here..." name="wenatProfileLink" value={wenatProfileLink} onChange={this.onHandleChange} onBlur={this.onBlueProfileChange} disabled={this.props.editHelpDesk} />
                                        {this.state.errors["wenatProfileLink"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["wenatProfileLink"]}</div>}
                                        {this.state.profileErrorMessage && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.profileErrorMessage}</div>}
                                    </div>
                                    <div className="form-group">
                                        {/* <label>Name <sup className="required-field">*</sup></label> */}
                                        <input type="text" class="form-control custom-input" placeholder="Name" name="profileName" value={profileName} disabled />
                                        {this.state.errors["profileName"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["profileName"]}</div>}
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                {/* <label>Email ID <sup className="required-field">*</sup></label> */}
                                                <input type="text" class="form-control custom-input" placeholder="Email ID" name="emailId" value={emailId} disabled />
                                                {this.state.errors["emailId"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["emailId"]}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                {/* <label>Phone Number</label> */}
                                                <input type="text" class="form-control custom-input" placeholder="Phone Number" name="phoneNo" value={phoneNo} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Designation <sup className="required-field">*</sup></label>
                                        <input type="text" class="form-control custom-input" placeholder="Write here..." name="designation" value={designation} onChange={this.onHandleChange} />
                                        {this.state.errors["designation"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["designation"]}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>Short brief <sup className="required-field">*</sup></label>
                                        <textarea type="text" class="form-control custom-input" placeholder="Write here..." name="shortBrief" value={shortBrief} onChange={this.onHandleChange} />
                                        {this.state.errors["shortBrief"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["shortBrief"]}</div>}
                                    </div>
                                </div>
                                <div class="formbottom">
                                    <button type="submit" class="btn btn-primary" onClick={this.onSubmitHelpDesk} disabled={isSubmitDisabled}>Submit</button>
                                    <button type="button" class="btn btn-cancel" aria-label="Close" onClick={this.onCloseModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade dgpopup confirmpop" id="cancelPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">Are you sure want to cancel this help desk?</div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.props.triggerHelpDeskModalClose}>Yes</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default memo(UserHelpDeskPopUp);
