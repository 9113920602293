import React from 'react'
import Autosuggest from 'react-autosuggest'
import TagsInput from "react-tagsinput";
import axios from "axios";
import uuid from "react-uuid";
import { authService } from './auth';



class AutoSuggest extends React.Component {
    constructor () {
        super()
        this.state = {
            tags: [],
            hashTags: [],
            suggestions:[]
        }
    }


    onSuggestionsFetchRequested = ({ value }) => {
        if(value.charAt(0) === '#'){
            value = value.substring(1);
        }
        let postBody = {
            "description": value,
            "privateEntityId": authService.getUserDetailsFromLocalStorage()?.entityId
        };
        let url = ""
        if(this.props.profile){
            postBody.userId = this.props.userId
            url = '/tags/userwisetagsearch?userId='+this.props.userId+'&hashTag='+value+"&privateEntityId="+authService.getUserDetailsFromLocalStorage()?.entityId; 
        }else{
            url = '/tags/recommended'
        }
        axios({
            method: this.props.profile ? 'GET' : 'POST',
            url: process.env.REACT_APP_userServiceURL + url,
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({'suggestions':response.data.body && response.data.body.map((tag) => ({
                        id: uuid(),
                        name: '# '+tag
                    }))
                });
            }
        }).catch((err) => {
            console.log(err);
        })

    }



    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

     autocompleteRenderInput =  ({addTag, ...props})  => {
        const handleOnChange = (e, {newValue, method}) => {
            if (method === 'enter') {
                e.preventDefault()
            } else {
                props.onChange(e)
            }
        }

        let inputValue = (props.value && props.value.trim().toLowerCase()) || ''
         if(inputValue.charAt(0) === '#'){
             inputValue = inputValue.substring(1);
         }
        const inputLength = inputValue.length

        let suggestions = this.state.suggestions.filter((state) => {
            //return state.name.toLowerCase().slice(0, inputLength) === inputValue
            return state.name.toLowerCase();
        })

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={suggestions}
                shouldRenderSuggestions={(value) => value && value.trim().length > 0}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
                inputProps={{...props, onChange: handleOnChange}}
                onSuggestionSelected={(e, {suggestion}) => {
                    addTag(suggestion.name)
                }}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            />
        )
    }


    render () {

        return <TagsInput value={this.props.hashTags}
                          inputProps={{
                              placeholder: 'Add Hashtags'
                          }}
                          ref={'TagsRef'}
                          addKeys={[9, 13, 32, 62, 188]}
                          renderInput={this.autocompleteRenderInput}
                          onDelete={this.props.onDelete}
                          onAddition={this.props.onAddition.bind(this)}
                          onChange={this.props.handleTagsChange}/>

    }
}

export default AutoSuggest