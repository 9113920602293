import {combineReducers} from "redux";
import showAllReducer from "./showAllReducer.js";
import draftReducer from "./draftReducer.js";
import homePageReducer from "./project/homePageReducer.js";
import applicationsReducer from "./project/applicantReducer.js";
import prefRuducer from "./userPref";
import {routerReducer} from "react-router-redux";
import networkCountReducer from "./network";
import ProfileReducer from "./profile/ProfileReducer";
import authReducer from "./auth";
import postReducer from "./post";
import notificationsReducer from "./notificationsReducer";
import blogPageClickReducer from "./blogPageClickReducer";
import messageCountReducer from "./message";
import projectPageClickReducer from "./projectPageClickReducer.js";

export default combineReducers({
    showAllReducer,
    drafts: draftReducer,
    home: homePageReducer,
    preference: prefRuducer,
    routing: routerReducer,
    applications: applicationsReducer,
    networkCountPref: networkCountReducer,
    messageCountPref: messageCountReducer,
    postReducer: postReducer,
    ProfileReducer: ProfileReducer,
    authReducer: authReducer,
    notificationsReducer: notificationsReducer,
    blogPageClickReducer:blogPageClickReducer,
    projectPageClickReducer: projectPageClickReducer
});
