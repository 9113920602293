import React from 'react'

import { authService } from '../auth';
import axios from "axios";
import OtpInput from "react-otp-input";
import FooterDiv from "../FooterDiv";

import httpService from '../AxiosInterceptors';

httpService.setupInterceptors();

class Otp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            signUpSuccess: false,
            emailAvailable: false,
            otp: '',
            numInputs: 6,
            separator: '',
            isDisabled: false,
            hasErrored: false,
            isInputNum: true,
            OTP: '',
            setOTP: '',
            data: {},
            resendOTP: false,
            timer: 0,
            value: 0,
            message: '',
            success: false,
            textOtp: '',
            minutes: 10,
            seconds: 0,

        };

    }

    handleOtpChange = otp => {
        this.setState({ otp });
    };

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    componentDidMount() {
        let os = this.getMobileOperatingSystem()
        if(os !== 'iOS'){
            window.$(".OTP").keydown(function(event) {
                // Allow only backspace and delete
                if ( event.keyCode == 46 || event.keyCode == 8 ) {
                    // let it happen, don't do anything
                }
                else {
                    // Ensure that it is a number and stop the keypress
                    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        event.preventDefault(); 
                    }   
                }
            });
        }
        if(this.props.location && this.props.location.state && this.props.location.state.signUpResponse){
            this.setState({
                data: this.props.location.state.signUpResponse
            },()=>{
                console.log(this.state.data)
            })
        }
        this.resendButtonWait();


        // google otp conversion
        {
            process.env.REACT_APP_environment === 'prod' && window.gtag('config', 'AW-713421902')
            process.env.REACT_APP_environment === 'prod' && window.gtag('event', 'conversion', {'send_to': 'AW-713421902/Zj2ICOmrx_QCEM7ol9QC'});
        }

    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    validate(evt) {
        var theEvent = evt ;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }


    resendButtonWait = (event) => {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let postBody = {
            "userId": this.state.data.userId,
            "transactionId": this.state.data.transactionId,
            "otp": this.state.otp
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/otp/signup/verify',
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                let userData = authService.getUserDetailsFromLocalStorage();
                if(userData){
                    userData.phone = res.body.mobile;
                    userData.email = res.body.email;
                    authService.updateUserData(userData);
                }
                if (this.state.userData.type === 'INDIVIDUAL') {
                    this.props.history.push({
                        pathname: '/address',
                        state: {
                            signUpResponse: this.state.data
                        }

                    })
                } else {
                    if(res.body.profileInfoUpdated){
                        userData.userDataPending = false;
                        authService.updateUserData(userData);
                        window.location.href="/";
                    } else{
                        this.props.history.push({
                            pathname: '/companyprofile'
                        })
                    }

                }
            }
        }).catch((err) => {

            if (err && err.response && err.response.data) {
                setTimeout(() => {
                    this.revokeError();
                }, 5000);
                this.setState({
                    isLoaded: true,
                    error: { message: err.response.data.message, err: err.response }
                });

            }
            this.clearOtp();
        });

    };

    resendOtp = (event) => {
        event.preventDefault();
        this.setState({ resendOTP: false });
        let postBody = {
            "userId": this.state.data.userId,
            "otpType": 'SIGNUPRESEND'
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/otp/send',
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                if (this.state.data) {
                    this.state.data.transactionId = res.body.transactionId;
                }
                this.setState({ message: 'OTP resent successfully', minutes:10,seconds:0 });
                this.setState({ success: true });
                this.setState({ resendOTP: false });
                this.resendButtonWait()
                setTimeout(() => {
                    this.setState({ message: '' });
                    this.setState({ success: false });
                    this.resendButtonWait();
                }, 5000);

            }
        }).catch((err) => {
            console.log(err);
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    resendOTP: true,
                    error: { message: err.response.data.message, err: err.response }
                });

            }

        });
    };

    clearOtp = () => {
        this.setState({ otp: '' });
    };

    revokeError() {
        this.setState({ messages: '' });
        this.setState({ error: false });
    }

    render() {
        const { value, OTP, setOTP, otp, numInputs, separator, isDisabled, hasErrored, isInputNum, timer, minutes, seconds  } = this.state;
        //const PhoneOTPMedium = this.state.userData.phone;
        //const EmailOtpMedium = this.state.userData.email;
        const isIndian = this.state.data.indianResidence;
        let textOtp;
        // if (isIndian) {
        //     textOtp = <p>Enter the 6 digit OTP sent on your phone <a href="javascript:void(0);" className="otp-link">+91 &nbsp;
        //         {this.state.data.mobile}</a></p>
        // } else {
            textOtp = <p>Enter the 6 digit OTP sent on your email  &nbsp;
                <a href="javascript:void(0);" className="otp-link">{this.state.data.email}</a></p>
        // }
        return <section className="signup-wrapper">
            <div className="signup-container pt-3">
                <a href="/login" className="goback"> Go Back</a>
                <div className="signup-box text-center">
                    <div className="signup-boxinn otpwrap">

                        <a href="/" className='logotop'><span>my</span><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" className="logotop" /></a>
                        <h1 className="signup-header-text">Authentication</h1>
                        {textOtp}
                        <div className="signup-form-container">
                            <form className="otp-form" onSubmit={this.handleSubmit}>
                                <div className="optfield">
                                    <OtpInput
                                        /*inputStyle={{
                                            width: '3rem',
                                            height: '3rem',
                                            margin: '20px 1rem',
                                            fontSize: '2rem',
                                            borderRadius: 4,
                                            border: '2px solid rgba(0,0,0,0.3)'
                                        }}*/
                                        numInputs={numInputs}
                                        isDisabled={isDisabled}
                                        hasErrored={hasErrored}
                                        errorStyle="error"
                                        onChange={this.handleOtpChange}
                                        onkeypress={this.validate}
                                        inputStyle="OTP"
                                        separator={<span>{separator}</span>}
                                        pattern='[0-9]{0,5}'
                                        shouldAutoFocus
                                        value={otp}
                                    />

                                    {this.state.error &&
                                        <div className="login-feedback-error">
                                            <strong></strong> {this.state.error.message}.
                                    </div>
                                    }
                                </div>

                                <div>
                                    <button
                                        className="login-btn btn btn-primary mt-3"
                                        disabled={otp.length < numInputs}>
                                        Submit
                                    </button>
                                    <p className="resend">
                                        {minutes === 0 && seconds === 0
                                            ? <button type="button"
                                                onClick={this.resendOtp}
                                                className="counter_Btn btn mt-1">
                                                Resend
                                    </button>
                                            : <h1>OTP will expire in {" "} {minutes + ' min '}:{ ' '+seconds < 10 ? `0${seconds} sec` : ' '+seconds + ' sec'}</h1>
                                        }
                                        {
                                            this.state.success ? this.state.message : null
                                        }
                                    </p>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <FooterDiv />
            </div>

        </section>

    }
}

export default Otp
