import React from 'react'
import FooterDiv from "../FooterDiv";
import Header from "../Header";
import './main-faq.min.css';
import {authService} from "../auth";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import * as _ from "lodash";
import {getCDNUrl} from "../commons/CommonFunctions";
import httpService from "../AxiosInterceptors";
import {Link} from "react-router-dom";
import LeftPanel from '../LeftPanel';
import * as StringUtility from "../utils/StringUtility";

httpService.setupInterceptors();

class FaQFeedback extends React.Component {
    constructor(props) {
        super(props);
        let validation = {
            title: {},
            description: {}
        }
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            value: 0,
            error: false,
            messages: null,
            validation,
            submitted: false,
            feedbackGiven: true,
            averageRating: 0,
            averageRatingForLastThirtyDays: 0,
            title: '',
            description: '',
            feedbacks: [],
            feedbacksCount: 0,
            pageSize: 5,
            pageNo: 0,
            pagination: false,
            active: 'iStar staractive',
            inActive: 'iStar',
            uniqueIdOfWeNat: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed',
            errorMessage: '',
            disable: true
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        this.state.title = this.title.value;
        this.state.description = this.description.value;
        this.setState({isLoading: true})
        if (this.validateForm()) {
            this.setState({'submitted': true});
            let body = {
                "userId": this.state.userData.userId,
                "entityId": this.state.uniqueIdOfWeNat,
                "title": this.state.title,
                "description": this.state.description,
                "rating": this.state.value,
                "privateEntityId" : this.state.userData && this.state.userData.entityId
            };
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/backend/feedback/create/',
                headers: {'Content-Type': 'application/json'},
                data: body,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.status === '201 CREATED') {
                    this.title.value = '';
                    this.description.value = '';
                    this.state.value = '';
                    this.setState({'title': '', 'description': ''});
                    this.state.isSubmitted = true;
                    this.setState({isLoading: false})
                    this.setState({disable: true})
                    this.setState({
                        "messages": 'Your Feedback has been submitted successfully',
                        "success": true
                    })
                    setTimeout(() => {
                        this.props.history.push('/FaQ')
                    }, 2000);
                }
            }).catch((err) => {
                this.title.value = '';
                this.description.value = '';
                this.state.value = '';
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        isSubmitted: false,
                        error: {message: err.response.data.message, err: err.response}
                    });
                    if (this.state.error.message === 'you have already given feedback for this topic') {
                        this.setState({
                            error: {message: 'You have already provided a feedback'},
                            disable: true
                        });
                    }
                }
                ;
                setTimeout(() => {
                    this.setState({
                        error: {},
                        isLoading: false,
                        errorMessage: '',
                    });
                }, 5000);
            })
        }
    };

    validateForm() {
        this.state.validation.description = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.description) ||
            _.isEmpty((this.state.description || "").toString()) ||
            _.isNull(this.state.description)) {
            this.setState({
                error: {message: 'Please provide a valid description'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.title) ||
            _.isEmpty((this.state.title || "").toString()) ||
            _.isNull(this.state.title)) {
            this.setState({
                error: {message: 'Please enter a valid title'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.value) ||
            _.isEmpty((this.state.value || "").toString())) {
            this.setState({
                error: {message: 'Please enter a valid feedback'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        this.setState({
            errors: errors
        });
        setTimeout(() => {
            this.setState({
                error: {},
            });
        }, 5000);
        return formIsValid;
    }

    handleTitleChange = (e) => {
        if (e.target.value.length > 0) {
            this.setState({disable: false});
        } else {
            this.setState({disable: true});
        }
    };

    handleFeedbackChange = (e) => {
        if (e.target.value.length > 0) {
            this.setState({disable: false});
        } else {
            this.setState({disable: true});
        }
    };
    setValue = (event, value) => {
        this.setState({'value': value});
        if (value > 0) {
            this.setState({disable: false});
        } else {
            this.setState({disable: true});
        }
    };

    render() {
        return <div className="wrapper faqpage staticPage">
            <Header/>
            <main className="dgcontainer hm3col">
                <div className="container">

                    <div className="giveFeedbForm dgcard">
                        <Link to={"/FaQ"} className="goback">Go Back</Link>
                        <form onSubmit={this.handleSubmit}>
                            <div className="giveFeedbFormheader">
                                <i className="iFeedback"/>
                                <h2>Share your Feedback!</h2>
                                <div className="compselect">
                                    <div className="compselectinn">
                                        <figure className="usimg">
                                            <img
                                                src={this.state.userData.type==="INDIVIDUAL"?
                                                    this.state.userData.profileImage != null ? getCDNUrl(this.state.userData && this.state.userData.profileImage) : "https://cdn.dscovr.com/images/users1.png"
                                                    :this.state.userData.profileImage != null ? getCDNUrl(this.state.userData && this.state.userData.profileImage) : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                                }
                                                alt=""/></figure>
                                        <h5>{this.state.userData.type==="INDIVIDUAL"?StringUtility.convertToFirstCapital(this.state.userData.firstName) +' '+ StringUtility.convertToFirstCapital(this.state.userData.lastName)
                                            :this.state.userData.companyName}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="addfeedbackform">
                                <div className="addfeedbformbox">
                                    <div className="gbrating">
                                        <Box component="fieldset" mb={4} borderColor="transparent">
                                            <Rating
                                                name="simple-controlled"
                                                value={this.state.value}
                                                onChange={(event, newValue) => {
                                                    this.setValue(event, newValue);
                                                }}
                                            />
                                        </Box>

                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control dginput"
                                               ref={input => this.title = input}
                                               onChange={this.handleTitleChange}
                                               placeholder="Add title..."/>
                                    </div>
                                    <div className="form-group">
                                        <textarea name="" id=""
                                                  ref={input => this.description = input}
                                                  onChange={this.handleFeedbackChange}
                                                  className="form-control dginput"
                                                  placeholder="Write feedback here..."/>
                                    </div>
                                    <div className="bottbtnwrap">
                                        {this.state.error.message !== "" && this.state.error.message !== undefined &&
                                        <div className="login-feedback-error">
                                            {this.state.error.message}
                                        </div>
                                        }
                                        {this.state.success &&
                                        <div className="login-feedback-error">
                                            {this.state.messages}
                                        </div>}
                                        <input type="submit" disabled={this.state.disable} value="Submit"
                                               className="btn btnsubmit"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div className="faqbottwrap">
                        <div className="faqbottgrid">
                            <i className="iCall_Service"/>
                            <h3>Contact Support</h3>
                            <p>Do you have more questions? Feel free to contact <a href="#contactInfopop1"
                                                                                   data-toggle="modal">Support</a>.</p>
                        </div>
                        <div id="contactInfopop1" className="modal fade dgpopup">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">

                                        <div className="contactInfopop">
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close"><span
                                                aria-hidden="true">&times;</span></button>

                                            <div className="contactInfopoprow"><span>Phone Number</span><p><a
                                                href="tel:9920605555">+91 9920605555</a></p></div>
                                            <div className="contactInfopoprow"><span>Email</span><p><a
                                                href="mailto:support@wenaturalists.com"
                                                target="_blank">support@wenaturalists.com</a></p></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </main>
            <LeftPanel/>
            <FooterDiv/>
        </div>
    }
}

export default FaQFeedback
