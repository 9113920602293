import {all} from "redux-saga/effects";

import draftSaga from "./draftSaga";
import watchPrefLoad from "./userPref";
import projectSaga, {watchEntityList, watchProjectDescription} from "./projectSaga";
import ProfileSaga, {
    watchContactInfo,
    watchPersonalProfileAddressData,
    watchPersonalProfileBioData,
    watchPersonalProfileBusinessPages,
    watchPersonalProfileEducations,
    watchPersonalProfileExperience,
    watchPersonalProfileInterestsData,
    watchPersonalProfileJoinedCauses,
    watchPersonalProfileNotJoinedCauses,
    watchPersonalProfilePopularHashTags,
    watchPersonalProfileRecentActivity, watchPersonalProfileSkillsAndSpecializationData,
    watchPersonalProfileSkillsData,
    watchPersonalProfileSpecialitiesData,
    watchProfileBaicInfoData
} from "../profile/sagas/ProfileSaga";
import participantsSaga, {watchApplicants} from "./project/applicantSaga";
import homeSaga, {
    showallWatchTrendingEvents,
    watchAddTopEventOrganizers,
    watchAddTopEvents,
    watchAddTopJobRecruiter,
    watchAddTopJobs,
    watchAddTrendingEvents,
    watchAddTrendingJobs, watchCancelledProject,
    watchCompletedProject,
    watchRunningProject,
    watchSetTopCompany,
    watchTopAssignmentOpportunity,
    watchTopAssignmentPerformer,
    watchTopRecruiter,
    watchTrainingTopOrganizers,
    watchTrainingTopTutors,
    watchTrainingTopWorkshops,
    watchTrendingAssignment, watchTrendingTraining,
    watchUpComingProject
} from "./project/homePageSaga";
import {watchProjectUserClick, watchUserClick} from "./pageClick/pageClickSaga";
import watchNetworkCountLoad from "./network/networkCountSaga";
import watchMessageCountLoad from "./message/messageCountSaga";

import {watchOtherUserDataOnLoad, watchUserDataOnLoad, watchUserProfileDataOnLoad} from "./authenticationSaga";

export default function* rootSaga() {

    yield all([
        ProfileSaga(),
        watchContactInfo(),
        watchNetworkCountLoad(),
        watchMessageCountLoad(),
        watchUserDataOnLoad(),
        watchOtherUserDataOnLoad(),
        watchUserProfileDataOnLoad(),
        //watchProjectDescription(),
        participantsSaga(),
        watchApplicants(),
        watchPrefLoad(),
        watchProjectDescription(),
        watchEntityList(),
        draftSaga(),
        projectSaga(),
        homeSaga(),
        watchTrendingAssignment(),
        watchTrendingTraining(),
        watchTopAssignmentOpportunity(),
        watchTopAssignmentPerformer(),
        watchAddTrendingJobs(),
        watchAddTopJobs(),
        watchAddTopJobRecruiter(),
        watchAddTopEvents(),
        watchAddTrendingEvents(),
        watchAddTopEventOrganizers(),
        showallWatchTrendingEvents(),
        watchSetTopCompany(),
        watchTopRecruiter(),
        watchCompletedProject(),
        watchUpComingProject(),
        watchRunningProject(),
        watchCancelledProject(),
        watchTrainingTopOrganizers(),
        watchTrainingTopTutors(),
        watchTrainingTopWorkshops(),
        watchPersonalProfileAddressData(),
        watchPersonalProfileSkillsData(),
        watchPersonalProfileSkillsAndSpecializationData(),
        watchPersonalProfileBioData(),
        watchPersonalProfileSpecialitiesData(),
        watchPersonalProfileInterestsData(),
        watchPersonalProfileEducations(),
        watchPersonalProfileExperience(),
        watchPersonalProfilePopularHashTags(),
        watchPersonalProfileNotJoinedCauses(),
        watchPersonalProfileJoinedCauses(),
        watchPersonalProfileRecentActivity(),
        watchPersonalProfileBusinessPages(),
        watchUserClick(),
        watchProjectUserClick(),
        watchProfileBaicInfoData()
    ]);

}
