import {call, put, takeEvery} from "redux-saga/effects";
import * as _ from "lodash";

import axios from 'axios';
import httpService from "../../AxiosInterceptors";
import {SET_MESSAGE_COUNT, setMessageCountOnSuccess} from "../../reducer/message";
import {authService} from "../../auth";

httpService.setupInterceptors();

function messageCountApi() {
    let userId =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;

    let url =process.env.REACT_APP_userServiceURL + '/messaging/message_service/api/message/getAllUnReadMessageBYAllTab?userId=' + userId+'&privateEntityId='+authService.getUserDetailsFromLocalStorage().entityId;


    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });

}

/** saga worker that is responsible for the side effects */
function* loadMessageCountResult(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(messageCountApi, action.payload);

        if (
            response &&
            response.data &&
            !_.isNull(response.data.body)
        ) {
            yield put(setMessageCountOnSuccess(response.data.body));
        } else {
            console.log(response);
        }
    } catch (e) {
        console.log(e);
    }
}


export default function* watchMessageCountLoad() {
    yield takeEvery(SET_MESSAGE_COUNT, loadMessageCountResult);
}
