import axios from "axios";
import { Apis } from "../config/api";

export const getCircleConfig = async (data) => {
    try {
        const response = await axios.get(Apis.CircleConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getCircleMemberList = async (data) => {
    try {
        const response = await axios.get(Apis.CircleMemberList(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getCirclePermission = async (data) => {
    try {
        const response = await axios.get(Apis.CirclePermission(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}

export const circleAcceptMemberRequest = async (postData) => {
    try {
        const response = await axios.post(Apis.CircleAcceptMember, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}

export const circleUserConfig = async (data) => {
    try {
        const response = await axios.get(Apis.CircleUserConfig(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}