import React, { Component, memo } from "react";
import { authService } from "../../auth";
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";
import ExploreTopBar from '../ExploreTopBar';
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import HashTagsCategory from './HashTagsCategory';
import NotFound from "../../404";
import BypassHeader from "../../BypassHeader";
import LeftPanelUnauth from "../../commons/LeftPanelUnauth";
import CommonHashTagsStudio from "./Common/CommonHashTagsStudio";

class AllHashTagsStudios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            hashTagsStudios: {},
            hashTagsStudioPageNo: 0,
            studioSuccess: false,
            thresholdHeight: 1000,
            tagName: "",
            loginSuccess: authService.isAuthenticated(),
            pageNotExist: false,
            signUpNeed: false
        }
    }

    tagNameValue = () => {
        this.setState({
            tagName: this.props.match.params.slug
        })
    }

    hashTagsStudioData = (page, limit, concatWithPrevious) => {
        let cancelToken;
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request");
        }
        cancelToken = axios.CancelToken.source();
        this.setState({
            studioSuccess: false
        })
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/tags/studio/list?${this.state.loginSuccess ? `&otherUserId=${this.state.userData.userId}` : ""}&hashTag=${this.state.tagName}&page=${page}&size=${limit}&auth=${this.state.loginSuccess}`,
            withCredentials: true,
            cancelToken: cancelToken.token,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                let pageData = response.data.body;
                if (concatWithPrevious && this.state.hashTagsStudios && this.state.hashTagsStudios.content) {
                    pageData.content = this.state.hashTagsStudios.content.concat(pageData.content);
                }
                this.setState({
                    hashTagsStudios: pageData,
                    studioSuccess: false
                })
            }
        }).catch((error) => {
            this.setState({
                studioSuccess: false
            })
            console.log(error);
        });
    };

    loadmoreHashTagsStudios = () => {
        if (this.state.hashTagsStudios && this.state.hashTagsStudios.content) {
            this.setState({
                studioSuccess: true
            })
            let pageable = this.state.hashTagsStudios.page.pageable;
            this.setState({
                hashTagsStudioPageNo: pageable.pageNumber + 1
            }, () => {
                this.hashTagsStudioData(pageable.pageNumber + 1, 24, true);
            })
        }
    };

    getHashtagsDetails = () => {
        let postData = {
            hashtag: this.props.match.params.slug
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/recommend/statushashtag/`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.status === 200) {
                this.hashTagsStudioData(this.state.hashTagsStudioPageNo, 24, false);
            }
            if (response && response.data && response.data.status === 410) {
                this.setState({
                    pageNotExist: true
                })
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                pageNotExist: err.response && err.response.status === 410 ? true : false
            })
        });
    }

    signUpRequired = () => {
        this.setState({
            signUpNeed: true
        }, () => {
            window.$('#signupModal').modal('show');
        })
    }

    componentDidMount() {
        this.tagNameValue();
        this.getHashtagsDetails();
        document.title = `#${this.props.match.params.slug} on WeNaturalists`;
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }

    render() {
        const { loginSuccess, pageNotExist, tagName, studioSuccess, thresholdHeight } = this.state;
        return (
            <div className={"wrapper projectpage explorepage" + (loginSuccess ? "" : " nologinpage")}>
                {loginSuccess ? <Header /> : <BypassHeader />}
                <main className="dgcontainer hm3col">
                    <div className="container">
                        {pageNotExist ?
                            <NotFound /> :
                            <div className="row">
                                {loginSuccess ? <LeftPanel /> : <LeftPanelUnauth signUpRequired={this.signUpRequired} />}
                                <div className="col-md-9">
                                    <div className="projectinn explore trending exploreExtra">
                                        <ExploreTopBar tagName={"Studios"} />
                                        {loginSuccess && <HashTagsCategory tagName={tagName} tagType="studios" />}

                                        <div className="exploregenerel expladventwrap trnd alltrndBlgs">
                                            {this.state.hashTagsStudios && this.state.hashTagsStudios.content && (
                                                <InfiniteScroll
                                                    loadMore={this.loadmoreHashTagsStudios.bind(this)}
                                                    hasMore={!studioSuccess && !this.state.hashTagsStudios?.page?.last}
                                                    threshold={thresholdHeight}
                                                >
                                                    <div className="row all">
                                                        {this.state.hashTagsStudios &&
                                                            this.state.hashTagsStudios.content &&
                                                            this.state.hashTagsStudios.content.map((item, idx) => {
                                                                return (
                                                                    <div className="col-md-4">
                                                                        <CommonHashTagsStudio item={item} key={item.id} idx={idx} />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </InfiniteScroll>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </main>
            </div>
        )
    }
}

export default memo(AllHashTagsStudios)