import React from 'react'
import { Link } from "react-router-dom";
import FooterDiv from "./FooterDiv";
import { licenseMemberRequest } from './services/LicenceApi';

class LicenseMemberUpdateStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseAlreadyVerified: false,
            verify: false,
        };
    }

    componentDidMount() {
        if(this.props.location?.state?.uniqueCode && this.props.location?.state?.userId && this.props.location?.state?.status) {
            this.validateLicenseUserStatus();
        } else {
            this.props.history.push({ pathname: '/' })
        }
    }

    validateLicenseUserStatus = async () => {
        let postData = {
            personalizeUserEntityId: this.props.location?.state?.uniqueCode,
            userId: this.props.location?.state?.userId,
            status: this.props.location?.state?.status
        }
        let response = await licenseMemberRequest(postData);
        if(response?.message === "Success!") {
            this.setState({
                verify: true
            })
        }
        if(response?.status === "404 NOT_FOUND") {
            this.setState({
                licenseAlreadyVerified: true
            })
        }
    };

    render() {
        const { verify, licenseAlreadyVerified } = this.state;
        return <section className="signup-wrapper">
            <div className="signup-container pt-3">
                <div className="signup-box text-center">
                    <div className="signup-boxinn userdtls">
                        <Link to="/" className="logotop">
                            <span>my</span>
                            <img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" className="logotop" />
                        </Link>

                        {verify &&
                            <>
                                <p>{this.props.location?.state?.status === "ACCEPTED" ? "Your myWeNat account membership is successfully activated. Login details have been sent on email." : "Your myWeNat account membership is deactivated."}</p>

                                <div className='emailVeriPartImg'>
                                    <img
                                        src="https://cdn.dscovr.com/images/emailVerify.png"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "https://cdn.dscovr.com/images/emailVerify.png"
                                        }} alt="" width={250} />
                                </div>
                                
                            </>
                        }

                        {licenseAlreadyVerified &&
                            <>
                                <p>Your request has been already submitted</p>
                                <div className='emailVeriPartImg'>
                                    <img
                                        src="https://cdn.dscovr.com/images/emailAlreadyVerify.png"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "https://cdn.dscovr.com/images/emailAlreadyVerify.png"
                                        }} alt="" width={250} />
                                </div>
                            </>
                        }

                    </div>
                </div>

                <FooterDiv />
            </div>
        </section>


    }
}

export default LicenseMemberUpdateStatus;