import React from "react";
import { Component } from "react";
import axios from "axios";
import _ from "lodash";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "../project/container/Project/css/main-projects.min.css";
import uuid from "react-uuid";
import PaymentPopUp from "../commons/PaymentPopUp";
import { authService } from "../auth";
import { blockUserMessage } from "../commons/CommonFunctions";
import { withRouter } from "react-router-dom";

const ErorrMsgPopUp = withReactContent(alertPopUp);

class DonationPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            amount: this.props.donateNowConfig?.targetAmount?.targetAmount > 0 &&  this.props.donateNowConfig?.targetAmount?.minimumAmount > 0 ? Math.min(this.props.donateNowConfig?.targetAmount?.targetAmount - this.props.donationBarData?.minValue, this.props.donateNowConfig?.targetAmount?.minimumAmount) : this.props.donateNowConfig?.targetAmount?.minimumAmount > 0 ? this.props.donateNowConfig?.targetAmount?.minimumAmount : "",
            fieldDisabled: this.props.donateNowConfig?.targetAmount?.targetAmount > 0 &&  this.props.donateNowConfig?.targetAmount?.minimumAmount > 0 && ((this.props.donateNowConfig?.targetAmount?.targetAmount - this.props.donationBarData?.minValue) < this.props.donateNowConfig?.targetAmount?.minimumAmount) ? true : false,
            isDisabled: false,
            paymentDetails: {},
            showPayNowPopUp: false,
            donationBarData: this.props.donationBarData,
            errors: {},
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    handleChange = (event) => {
        let value = (event.target.value).replace(/[,]/ig, "");
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            this.setState({
                amount: value.trimStart(),
            });
        }
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if ((_.isUndefined(this.state.amount) || _.isEmpty((this.state.amount || "").toString()) || _.isNull(this.state.amount))) {
            errors["amount"] = "Please enter amount.";
            formIsValid = false;
        }
        if(this.state.amount) {
            if(this.state.amount < 1) {
                errors["amount"] = "Amount should be greater than 0.";
                formIsValid = false;
            }
            if(this.state.amount > 0 && parseInt(this.state.amount) < this.props.donateNowConfig?.targetAmount?.minimumAmount && !this.state.fieldDisabled) {
                errors["amount"] = "Minimum contribution required is " + (this.props.indian ? "₹" : "$") + this.props.donateNowConfig?.targetAmount?.minimumAmount;
                formIsValid = false;
            }
            if(this.state.amount > 0 && parseInt(this.state.amount) > (this.props.donationBarData?.maxValue - this.props.donationBarData?.minValue) && this.props.donateNowConfig?.targetAmount?.targetAmount > 0) {
                errors["amount"] = "You can contribute up to " + (this.props.indian ? "₹" : "$") + (this.props.donationBarData?.maxValue - this.props.donationBarData?.minValue);
                formIsValid = false;
            }
        }

        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    submitProceedTpPayment = () => {
        if (this.validateForm()) {
            this.setState({ isDisabled: true });
            let postData = {
                userId: this.state.userId,
                id: this.props.donateNowConfig?.id,
                entityType: this.props.entityType,
                amount: this.state.amount
            }
            let donationBarData = this.props.donationBarData;
            if(this.state.amount > 0) {
                donationBarData.minValue = donationBarData.minValue + parseInt(this.state.amount);
            }
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_userServiceURL}/donation/donated`,
                headers: { 'Content-Type': 'application/json' },
                data: postData,
                withCredentials: true
            }).then(response => {
                if (response && response.data && response.data.message === 'Success!') {
                    window.$("#donationPopUp").modal("hide");
                    this.getBankDetailsData(this.props.donateNowConfig?.bankDetail?.id);
                    this.setState({
                        isDisabled: false,
                        donationBarData: donationBarData
                    })
                }
            }).catch((err) => {
                console.log(err);
                this.setState({
                    isDisabled: false
                })
                if(err?.response?.data?.status === '409') {
                    window.$("#donationPopUp").modal("hide");
                    blockUserMessage("MESSAGE", err.response.data.message);
                }
            });
        }
    }

    getBankDetailsData = (bankAccountId) => {
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/getById?id=${bankAccountId}&privateEntityId=${this.state.userData.entityId}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.message === "Success!") {
                this.setState({
                    showPayNowPopUp: true,
                    paymentDetails: response.data.body
                }, () => {
                    window.$("#payNowPop").modal("show");
                })
            }
        }).catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm();
                }
            }).then((result) => {
                return ErorrMsgPopUp.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: <><p>{error.response.data.message}</p></>,
                })
            })
        });
    }

    triggerPaymentClose = () => {
        this.setState({
            showPayNowPopUp: false
        })
        window.$("#payNowPop").modal("hide");
        this.props.onSuccessDonationTargetUpdate(this.state.donationBarData);
    }

    componentWillUnmount() {
        window.$("#donationPopUp").modal("hide");
    }

    render() {
        const { isDisabled, errors, amount, showPayNowPopUp, paymentDetails, fieldDisabled } = this.state;
        return (
            <>
                <div id="donationPopUp" className="modal fade dgpopup paidProjWrap" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="paidProjPublishPop donationPopUp">
                                    <button type="button" className="close topCrossModal" onClick={(e) => this.props.onCloseDonationPopUp("donationPopUp")}>
                                        <i className="iCross" /></button>
                                    <div class="paidProjPublishPopHeader"><h2>Enter Amount</h2></div>
                                    <div className="paidProjRadioWrap">
                                        <div>
                                            <label className="radio-container">
                                                <h3>Amount</h3>
                                                <div className={"amtInput"+ (this.props.indian ? " currency-inr" : "")}>
                                                    <input type="text" className="form-control custom-input" placeholder="Add amount" value={new Intl.NumberFormat('en-US').format(amount)} onChange={this.handleChange} disabled={fieldDisabled} />
                                                    {errors["amount"] && <span className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["amount"]}</span>}
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="forwcontwrapbott">
                                        <div>
                                            <button className={"btn" + (isDisabled ? " disabled" : "")} disabled={isDisabled} onClick={(e) => this.submitProceedTpPayment()}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showPayNowPopUp && 
                    <div className="payinfo">
                        <PaymentPopUp paymentDetails={paymentDetails} entityType={this.props.entityType} profileId = {this.props.donateNowConfig?.superAdminId} userName = {this.props.donateNowConfig?.superAdminName} triggerPaymentClose={this.triggerPaymentClose}  />
                    </div>
                }
            </>
        )
    }
}

export default withRouter(DonationPopUp);
