import React, { Component } from 'react';
import { Link, NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import { authService } from "../auth";
import * as _ from "lodash";
import Select from "react-select";
import CountryCode from "../country_code.json";
import httpService from "../AxiosInterceptors";
import { isWebView, userAgentPostData } from "../commons/CommonFunctions";
import FooterDiv from "../FooterDiv";
httpService.setupInterceptors();

class SignUpModal extends Component {
    constructor(props) {
        super(props);
        let validation = {
            validatePassword: {
                uppercaseTest: new RegExp("^(?=.*[A-Z])"),
                minCharacterTest: new RegExp("^(?=.{8})"),
                style: { borderColor: "#b34c4c", padding: "10px" }
            },
            firstName: {},
            lastName: {},
            phone: {},
            email: {},
            password: {},
            countryCode: {}
        }

        CountryCode.sort((a, b) => (a.Dial > b.Dial) ? 1 : ((b.Dial > a.Dial) ? -1 : 0));

        this.state = {
            isLoading: false,
            token: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            validation,
            isIndianResident: true,
            password: '',
            agreeToTerms: true,
            loginSuccess: authService.isAuthenticated(),
            signUpSuccess: false,
            emailAvailable: false,
            signUpResponse: {},
            upperCaseMsg: '1 Uppercase',
            minimumPasswordCharacter: "Password must be at least 8 characters long with",
            errors: {},
            fields: {},
            countryCodeInfo: [],
            countryCode: [],
            countryCodeList: [].concat(CountryCode),
            // countryCodeInfo: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.$('.selectpicker').selectpicker();
        window.$('#signupModal').modal('show');

        // window.gtag('config', 'AW-713421902')
        // window.gtag('event', 'conversion', { 'send_to': 'AW-713421902/Sk5zCL373eMCEM7ol9QC'})
    }
    newTabLink = () => {
        window.open("mailto:support@wenaturalists.com", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
    }

    componentWillUnmount() {
        window.$('#signupModal').modal('hide');
    }


    getCountryCodeNew = (newValue, actionMeta) => {
        this.setState({ selectedOption4: newValue.value });
        this.setState({ 'countryCodeInfo': newValue });
    }


    getCountryCodeOptions = () => {
        let types = this.state.countryCodeList.map((option) => {
            return {
                value: option.Unicode + ' +' + option.Dial,
                dial: option.Dial,
                country: option.Name,
                label: option.Unicode + ' +' + option.Dial + ' ' + option.Name,
                name: 'countrycode'
            }
        });
        return types;
    };

    countryDropDownMenu() {
        const { selectedOption4 } = this.state;
        return <Select
            options={this.getCountryCodeOptions()}
            onChange={this.getCountryCodeNew}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            placeholder={'Code *'}
            name={'countrycode'}
            classNamePrefix='react-select'
            styles={{
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const color = '#cfe7c7';
                    return {
                        ...styles,
                        backgroundColor: isDisabled
                            ? null
                            : isSelected
                                ? '#cfe7c7'
                                : isFocused
                                    ? '#cfe7c7'
                                    : null,
                        color: isDisabled
                            ? '#ccc'
                            : isSelected
                                ? color > 2
                                    ? '#00374d'
                                    : '#00374d'
                                : data.color,
                        cursor: isDisabled ? 'not-allowed' : 'default',

                        ':active': {
                            ...styles[':active'],
                            backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                        },
                    };
                },
            }}
        />
        //    return <select className="selectpicker" data-width="fit"
        //         name="countryCode"
        //         onChange={this.onSelectCountryCode}
        //         style={this.state.validation.countryCode.style}
        //         required={true}>
        //         {this.getCountryCodeOptions()}
        //     </select>
    }


    getCountryCode = () => {
        var maxLength;
        var minLength;
        if (this.state.countryCodeInfo && this.state.countryCodeInfo.dial === '91') {
            maxLength = 10;
            minLength = 10
        } else {
            maxLength = 15;
            minLength = 3
        }
        return (
            <div className="form-group">
                <div className="chphonefield">
                    {this.countryDropDownMenu()}
                    <input type="text"
                        className="custom-input form-control"
                        id="validationCustomUsername"
                        aria-describedby="inputGroupPrepend"
                        style={this.state.validation.phone.error ? this.state.validation.phone.style : null}
                        value={this.state.phone}
                        data-tip={
                            this.state.validation.phone.error &&
                            this.state.validation.phone.message
                        }
                        onBlur={(e) => this.validate("phone", e.target.value)}
                        onChange={(e) => this.validate("phone", e.target.value)}
                        minLength={minLength} maxLength={maxLength}
                        placeholder="Phone No.*" />


                    <span className="login-feedback-error mt-1"
                        style={{ display: "block" }}>{this.state.errors.countryCode}</span>
                    <span
                        className="login-feedback-error mt-1">{this.state.validation.countryCode && this.state.validation.countryCode.message}</span>
                </div>
                {this.state.errors && this.state.errors.phone ?
                    <span className="login-feedback-error mt-1"
                        style={{ display: "block" }}>{this.state.errors.phone}</span>
                    : null}
                {this.state.validation && this.state.validation.phone ?
                    <span
                        className="login-feedback-error mt-1">{this.state.validation.phone && this.state.validation.phone.message}</span>
                    : null}

            </div>


        );
    };
    analyzePassword = (value) => {
        let error = false;
        if (this.state.validation.validatePassword.uppercaseTest.test(value)) {
            this.setState({ upperCaseMsg: '' });
        } else {
            this.setState({ upperCaseMsg: '1 Uppercase' });
            error = true;
        }
        if (this.state.validation.validatePassword.minCharacterTest.test(value)) {
            this.setState({ minimumPasswordCharacter: '' });
        } else {
            this.setState({ minimumPasswordCharacter: 'Password must be at least 8 characters long with' });
            error = true;
        }
        if (error) {
            this.state.validation.password = {
                error: true,
                message: 'Please enter valid Password',
                style: { borderColor: "#b34c4c" }
            }
        }


    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true })
        if (this.validateForm()) {
            let postBody = {
                "firstName": this.state.firstName.trim(),
                "lastName": this.state.lastName.trim(),
                "password": this.state.password,
                "agreeToTerms": this.state.agreeToTerms,
                "indianResident": this.state.isIndianResident,
                "mobile": this.state.phone,
                "email": this.state.email.toLowerCase(),
                "country": this.state.countryCodeInfo.country,
                "countryISDCode": this.state.countryCodeInfo.dial,
                "userAgent": userAgentPostData()
            };

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/signup/individual',
                headers: { 'Content-Type': 'application/json' },
                data: postBody,
                withCredentials: true
            }).then((response) => {
                let res = response.data;

                if (res.message === 'Success!') {
                    let authResult = {};
                    if (this.state.isIndianResident) {
                        authResult.phone = this.state.phone;
                    } else {
                        this.setState({ emailAvailable: true });
                        authResult.email = this.state.email;
                    }
                    authResult.isIndianResident = res.body.indianResidence;
                    authResult.type = res.body.type;
                    authResult.userDataPending = res.body.userDataPending;
                    authResult.userId = res.body.userId;
                    authResult.firstName = res.body.firstName;
                    authResult.lastName = res.body.lastName;
                    authResult.customUrl = res.body.customUrl;
                    authService.setSession(authResult, response);

                    if (window.ReactNativeWebView && authResult.userId) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            type: 'login',
                            userId: authResult.userId
                        }));
                    }

                    this.setState({ signUpResponse: response, isLoading: false });
                    this.props.history.push({
                        pathname: '/otp',
                        state: {
                            signUpResponse: this.state.signUpResponse.data.body
                        }
                    })

                    {
                        process.env.REACT_APP_environment === 'prod' && window.gtag('config', 'AW-713421902')
                        window.gtag('event', 'conversion', { 'send_to': 'AW-713421902/Sk5zCL373eMCEM7ol9QC' })
                        window.gtag_report_conversion(undefined)
                    }

                }
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        isLoading: false,
                        error: { message: err.response.data.message, err: err.response }
                    });
                    authService.clearData();
                }
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    error: {}
                });
            }, 5000);

        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);

        }

    };

    validateForm() {
        this.state.validation.firstName = {};
        this.state.validation.lastName = {};
        this.state.validation.phone = {};
        this.state.validation.email = {};
        this.state.validation.password = {};
        this.state.validation.countryCode = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (this.state.firstName === '') {
            if (_.isUndefined(this.state.firstName) ||
                _.isEmpty((this.state.firstName || "").toString()) ||
                _.isNull(this.state.firstName)) {
                errors["firstName"] = "Please enter your first name";
                this.state.validation.firstName = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const fn = /^[a-zA-Z\s]+$/;
            if (this.state.firstName === '' || fn.test(this.state.firstName)) {
                this.setState({ firstName: this.state.firstName });
            }

            this.state.validation.firstName = {};
            if (
                _.isUndefined(this.state.firstName) ||
                _.isEmpty((this.state.firstName || "").toString()) ||
                _.isNull(this.state.firstName) ||
                this.state.firstName.trim().replace(" ", "").length > 25 ||
                this.state.firstName.trim().replace(" ", "").length < 2
            ) {
                this.state.validation.firstName = {
                    error: true,
                    message: 'First name must have minimum 2 characters',
                    style: { borderColor: "#b34c4c", padding: "10px" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;

            }
        }
        if (this.state.lastName === '') {
            if (_.isUndefined(this.state.lastName) ||
                _.isEmpty((this.state.lastName || "").toString()) ||
                _.isNull(this.state.lastName)) {
                errors["lastName"] = "Please enter your last name";
                this.state.validation.lastName = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const ln = /^[a-zA-Z\s]+$/;
            if (this.state.lastName === '' || ln.test(this.state.lastName)) {
                this.setState({ lastName: this.state.lastName });
            }
            this.state.validation.lastName = {};
            if (
                _.isUndefined(this.state.lastName) ||
                _.isEmpty((this.state.lastName || "").toString()) ||
                _.isNull(this.state.lastName) ||
                this.state.lastName.trim().replace(" ", "").length > 25 ||
                this.state.lastName.trim().replace(" ", "").length < 2
            ) {
                this.state.validation.lastName = {
                    error: true,
                    message: 'Last name must have minimum 2 characters',
                    style: { borderColor: "#b34c4c", padding: "10px" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;
            }
        }
        if (_.isUndefined(this.state.phone) ||
            _.isEmpty((this.state.phone || "").toString()) ||
            _.isNull(this.state.phone)) {
            errors["phone"] = "Please enter your phone number";
            this.state.validation.phone = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            this.setState({ isLoading: false })
            formIsValid = false;
            validationRequired = true;
        } else {
            const re = /^[0-9\b]+$/;
            if (this.state.phone === '' || re.test(this.state.phone)) {
                this.setState({ phone: this.state.phone });
            }

            this.state.validation.phone = {};
            if (this.state.countryCodeInfo.dial === '91') {
                const regExp = /^[6-9\b]+$/;
                if (this.state.phone === '' || regExp.test(this.state.phone)) {
                    this.setState({ phone: this.state.phone });
                }
                if (this.state.phone.trim().replace(" ", "").length > 10 ||
                    this.state.phone.trim().replace(" ", "").length < 10 || !regExp.test(this.state.phone[0])
                ) {
                    this.state.validation.phone = {
                        error: true,
                        message: 'Please enter valid mobile number',
                        style: { borderColor: "#b34c4c" }
                    }
                    this.setState({ isLoading: false })
                    formIsValid = false;
                }
            }
        }
        if (_.isUndefined(this.state.countryCodeInfo.dial) || _.isEmpty(this.state.countryCodeInfo.dial) || _.isNull(this.state.countryCodeInfo.dial)) {
            errors["countryCode"] = "Please select the country code";
            this.state.validation.countryCode = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            this.setState({ isLoading: false })
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.email) ||
            _.isEmpty((this.state.email || "").toString()) ||
            _.isNull(this.state.email)) {
            errors["email"] = "Please enter your email address";
            this.state.validation.email = {
                error: true,
                style: { borderColor: "#b34c4c", padding: "10px" }
            }
            this.setState({ isLoading: false })
            formIsValid = false;
            validationRequired = true;
        } else {
            const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ email: this.state.email });
            this.state.validation.email = {};
            if (!email.test(this.state.email)) {
                this.state.validation.email = {
                    error: true,
                    message: 'Please enter a valid email address',
                    style: { borderColor: "#b34c4c" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;
            }
        }
        if (this.state.password === '') {
            if (_.isUndefined(this.state.password) ||
                _.isEmpty((this.state.password || "").toString()) ||
                _.isNull(this.state.password)) {
                errors["password"] = "Please enter a password";
                this.state.validation.password = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            this.setState({ password: this.state.password });
            this.state.validation.password = {};
            let error = false;
            if (this.state.validation.validatePassword.uppercaseTest.test(this.state.password)) {
                this.setState({ upperCaseMsg: '' });
            } else {
                this.setState({ upperCaseMsg: '1 Uppercase' });
                error = true;
            }
            if (this.state.validation.validatePassword.minCharacterTest.test(this.state.password)) {
                this.setState({ minimumPasswordCharacter: '' });
            } else {
                this.setState({ minimumPasswordCharacter: 'Password must be at least 8 characters long with' });
                error = true;
            }
            if (error) {
                this.state.validation.password = {
                    error: true,
                    message: 'Please enter valid Password',
                    style: { borderColor: "#b34c4c" }
                }
                this.setState({ isLoading: false })
                formIsValid = false;
            }

        }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    validate = (fieldName, value) => {
        let isValid = true;
        switch (fieldName) {
            case "firstName":
                const fn = /^[a-zA-Z\s]+$/;
                if (value === '' || fn.test(value)) {
                    this.setState({ firstName: value });
                }

                this.state.validation.firstName = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 25 ||
                    value.trim().replace(" ", "").length < 2
                ) {
                    this.state.validation.firstName = {
                        error: true,
                        message: 'First name must have minimum 2 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;

            case "lastName":
                const ln = /^[a-zA-Z\s]+$/;
                if (value === '' || ln.test(value)) {
                    this.setState({ lastName: value });
                }
                this.state.validation.lastName = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 25 ||
                    value.trim().replace(" ", "").length < 2
                ) {
                    this.state.validation.lastName = {
                        error: true,
                        message: 'Last name must have minimum 2 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;

            case "phone":
                const re = /^[0-9\b]+$/;
                if (value === '' || re.test(value)) {
                    this.setState({ phone: value });
                }

                this.state.validation.phone = {};
                if (this.state.countryCodeInfo.dial === '91') {
                    const regExp = /^[6-9\b]+$/;
                    if (
                        _.isUndefined(value) ||
                        _.isEmpty((value || "").toString()) ||
                        _.isNull(value) ||
                        value.trim().replace(" ", "").length > 10 ||
                        value.trim().replace(" ", "").length < 10
                    ) {
                        this.state.validation.phone = {
                            error: true,
                            message: 'Please enter your phone number',
                            style: { borderColor: "#b34c4c" }
                        }

                    } else if (!regExp.test(value[0])) {
                        this.state.validation.phone = {
                            error: true,
                            message: 'Please enter valid phone number',
                            style: { borderColor: "#b34c4c" }
                        }

                    }
                }
                break;

            case "email":
                const email =
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.setState({ email: value });
                this.state.validation.email = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    !email.test(value)
                ) {
                    this.state.validation.email = {
                        error: true,
                        message: 'Please enter a valid email address',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }
                }
                break;
            case "password":
                this.setState({ password: value });
                this.state.validation.password = {};
                this.analyzePassword(value);
                break;

            default:
                isValid = true;
                break;
        }
        return isValid;
    };

    render() {
        const { error } = this.state;
        if (this.state.loginSuccess) {
            return <Redirect to='/' />
        } else {
            return (
                <>
                    <div id="signupModal" className="modal fade dgpopup authLoginModal signup-box"
                        data-backdrop="static">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="addendorsepop addExpirience text-center">
                                        {this.props.handleSignUpClose ? <button className="close" onClick={this.props.handleSignUpClose}><span aria-hidden="true">&times;</span></button> :
                                            <NavLink to="/"><button className="close"><span aria-hidden="true">&times;</span></button></NavLink>
                                        }
                                        <Link to="/"><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg"
                                            className="logotop" alt="" /></Link>
                                        <h1 className="signup-header-text">Join Our Community</h1>
                                        <div className="signup-form-container">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12">
                                                        <div className="form-group">
                                                            <input type="text"
                                                                className="default-caps custom-input form-control"
                                                                style={this.state.validation.firstName.error ? this.state.validation.firstName.style : null}
                                                                value={this.state.firstName}
                                                                data-tip={
                                                                    this.state.validation.firstName.error &&
                                                                    this.state.validation.firstName.message
                                                                }
                                                                onBlur={(e) => this.validate("firstName", e.target.value)}
                                                                onChange={(e) => this.validate("firstName", e.target.value)}
                                                                autoComplete="given-name"
                                                                placeholder="First name *" maxLength="25" />
                                                            <span className="login-feedback-error mt-1"
                                                                style={{ display: "block" }}>{this.state.errors.firstName}</span>
                                                            <span
                                                                style={{ display: "block" }}
                                                                className="login-feedback-error mt-1">{this.state.validation.firstName && this.state.validation.firstName.message}</span>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12">
                                                        <div className="form-group">
                                                            <input type="text"
                                                                className="default-caps custom-input form-control"
                                                                style={this.state.validation.lastName.error ? this.state.validation.lastName.style : null}
                                                                value={this.state.lastName}
                                                                data-tip={
                                                                    this.state.validation.lastName.error &&
                                                                    this.state.validation.lastName.message
                                                                }
                                                                onBlur={(e) => this.validate("lastName", e.target.value)}
                                                                onChange={(e) => this.validate("lastName", e.target.value)}
                                                                autoComplete="family-name"
                                                                placeholder="Last name *" maxLength="25" />
                                                            <span className="login-feedback-error mt-1"
                                                                style={{ display: "block" }}>{this.state.errors.lastName}</span>
                                                            <span
                                                                className="login-feedback-error mt-1">{this.state.validation.lastName && this.state.validation.lastName.message}</span>

                                                        </div>
                                                    </div>
                                                </div>


                                                {/*<div className="form-group">
                                        <div className="row">
                                            <div className="col-md-7 col-xs-12 mailphfield">
                                                <div className="chphonefield">
                                                    <input type="text" className="custom-input form-control"
                                                        id="phone"
                                                        style={this.state.validation.phone.error ? this.state.validation.phone.style : null}
                                                        value={this.state.phone}
                                                        data-tip={
                                                            this.state.validation.phone.error &&
                                                            this.state.validation.phone.message
                                                        }
                                                        onBlur={(e) => this.validate("phone", e.target.value)}
                                                        onChange={(e) => this.validate("phone", e.target.value)}
                                                        maxLength="10"
                                                        placeholder="Phone No.*" />
                                                    <span className="ccode">+91</span>

                                                </div>
                                                <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors.phone}</span>
                                                <span className="login-feedback-error mt-1">{this.state.validation.phone && this.state.validation.phone.message}</span>
                                                <div className="chphonefield email">
                                                    <input type="text" className="custom-input form-control"
                                                        id="email"
                                                        style={this.state.validation.email.error ? this.state.validation.email.style : null}
                                                        value={this.state.email}
                                                        data-tip={
                                                            this.state.validation.email.error &&
                                                            this.state.validation.email.message
                                                        }
                                                        onBlur={(e) => this.validate("email", e.target.value)}
                                                        onChange={(e) => this.validate("email", e.target.value)}
                                                        aria-describedby="inputGroupPrepend" placeholder="Email *" />
                                                    <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors.email}</span>
                                                    <span className="login-feedback-error mt-1">{this.state.validation.email && this.state.validation.password.email}</span>

                                                </div>
                                            </div>
                                            <div className="col-md-5 col-xs-12">
                                                <div className="custom-control custom-checkbox mr-sm-2 mt-2">
                                                    <input type="checkbox" className="custom-control-input"
                                                        checked={this.state.isIndianResident}
                                                        onChange={this.handleIndianResidenceChange}
                                                        id="customControlAutosizing" />
                                                    <label className="custom-control-label"
                                                        htmlFor="customControlAutosizing">Indian
                                                        Resident</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}


                                                {this.getCountryCode()}

                                                <div className="form-group">
                                                    <div className="chphonefield email">
                                                        <input type="text" className="custom-input form-control"
                                                            id="email"
                                                            style={this.state.validation.email ? this.state.validation.email.style : null}
                                                            value={this.state.email}
                                                            data-tip={
                                                                this.state.validation.email.error &&
                                                                this.state.validation.email.message
                                                            }
                                                            onBlur={(e) => this.validate("email", e.target.value)}
                                                            onChange={(e) => this.validate("email", e.target.value)}
                                                            aria-describedby="inputGroupPrepend"
                                                            placeholder="Email *" />
                                                        <span className="login-feedback-error mt-1"
                                                            style={{ display: "block" }}>{this.state.errors.email}</span>
                                                        <span
                                                            className="login-feedback-error mt-1">{this.state.validation.email && this.state.validation.email.message}</span>

                                                    </div>
                                                </div>


                                                <div className="form-group passfield">
                                                    <div className="passfieldinn">
                                                        <input type="password"
                                                            className="custom-input form-control"
                                                            maxLength="15"
                                                            style={this.state.validation.password.error ? this.state.validation.password.style : null}
                                                            value={this.state.password}
                                                            data-tip={
                                                                this.state.validation.password.error &&
                                                                this.state.validation.password.message
                                                            }
                                                            onBlur={(e) => this.validate("password", e.target.value)}
                                                            onChange={(e) => this.validate("password", e.target.value)}
                                                            aria-describedby="inputGroupPrepend"
                                                            placeholder="Password *"
                                                        />
                                                        <span className="passtoggle" style={{
                                                            display: 'block'
                                                        }}>Show</span>

                                                    </div>
                                                    {/*<small>(Must be 8 characters or more)</small>*/}
                                                    <small
                                                        className="mt-1"> {this.state.minimumPasswordCharacter} {this.state.upperCaseMsg}</small>

                                                    <small className="login-feedback-error mt-1" style={{
                                                        display: "block",
                                                        color: "red",
                                                        fontWeight: "bold"
                                                    }}>{this.state.errors.password}</small>
                                                    <small className="login-feedback-error mt-1" style={{
                                                        display: "block",
                                                        color: "red",
                                                        fontWeight: "bold"
                                                    }}>{this.state.validation.password && this.state.validation.password.message}</small>

                                                </div>
                                                {error &&
                                                    <span className="login-feedback-error mt-1"
                                                        style={{ display: "block" }}> {error.message} </span>}


                                                <p className="login-paragraph-text">I accept the
                                                    WeNaturalists <strong><a href={`${process.env.REACT_APP_weNatURL}/TermsConditions`}
                                                        lassName="privacy-link"
                                                        target="_blank"> Terms &
                                                        Conditions</a>, <a href={`${process.env.REACT_APP_weNatURL}/UserAgreement`}
                                                            className="privacy-link"
                                                            target="_blank"> User
                                                        Agreement</a>, <a href={`${process.env.REACT_APP_weNatURL}/PrivacyPolicy`}
                                                            className="privacy-link"
                                                            target="_blank">Privacy
                                                        Policy</a>,
                                                        and <a href={`${process.env.REACT_APP_weNatURL}/CookiePolicy`} className="privacy-link"
                                                            target="_blank">Cookie
                                                            Policy.</a></strong></p>

                                                <button type="submit" className="login-btn btn btn-primary mt-1"
                                                    disabled={this.state.isLoading}>Agree & Join
                                                </button>
                                            </form>
                                            <p className="login-paragraph-text already">Already a member? <NavLink
                                                to="/login" className="signup-link">Log in</NavLink></p>
                                            <div className="extbutt">
                                                {/*<div><NavLink to="/signup-company" className="btn">Join as a Company</NavLink>
                                    </div>*/}
                                                {
                                                    isWebView() ?
                                                        <div><a href="javascript:void(0)" onClick={() => this.newTabLink()}><span>Contact Support</span></a>
                                                        </div>
                                                        :
                                                        <div><a href="mailto:support@wenaturalists.com" target="_blank">Contact
                                                            Support</a>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <FooterDiv />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default SignUpModal;