import React from 'react'

import LoginComponent from './LoginComponent';
import FooterDiv from './FooterDiv';

class Login extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    redirect = (data) => {
        /*if (data.mobileVerificationRequired) {
            this.props.history.push({
                pathname: '/otp',
                state: {
                    signUpResponse: data
                }
            })
        }*/
        if (data.emailVerificationRequired) {
            this.props.history.push({
                pathname: '/signup-verify',
                state: {
                    signUpResponse: data
                }
            })
        } else if (data.addressRequired) {
            this.props.history.push({
                pathname: '/address',
                state: {
                    signUpResponse: data
                }
            })
        } else if (data.personalProfileRequired) {
            this.props.history.push({
                pathname: '/personal'
            })
        } else if (data.operatorInfoRequired) {
            this.props.history.push({
                pathname: '/operatorinfo'
            })
        } else if (data.companyProfileRequired) {
            this.props.history.push({
                pathname: '/companyprofile'
            })
        } else if(data.passwordChangeRequired) {
            this.props.history.push({ pathname: '/change-password', state: {firstLogin: true} })
        }
        
    }

    render() {
        return <div>

            <LoginComponent redirect={this.redirect}/>
            <FooterDiv/>
        </div>
    }
}

export default Login 

