import React from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import ImagePreview from "./commons/ImagePreview";
import "./commons/styles/webCamera.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { authService } from "./auth";
import axios from "axios";
import $, { event } from "jquery";
import { requestPostCreationFulfilled } from "./reducer/post";
import { connect } from "react-redux";
import stripHtml from "string-strip-html";
import Loader from "react-loader-spinner";
import httpService from "./AxiosInterceptors";
import cloneDeep from "lodash/cloneDeep";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  convertUnicode,
  getCDNUrl,
  isMobileDevice,
  isWebView,
  matchPostLinkUrl,
  userAgentPostData,
  userAgentGetData,
  download_file_pdf
} from "./commons/CommonFunctions";
import mappingUtils from "./project/component/commons/utils/mappingUtils";
import { Scrollbars } from "react-custom-scrollbars";
import HorizontalScroll from "react-scroll-horizontal";
import MentionTextArea from "./commons/MentionTextArea";
import Picker from "emoji-picker-react";
import AutoSuggest from "./AutoSuggest";
import { CapitalizeFirstLetter } from "./utils/StringUtility";
import * as _ from "lodash";
import PresentationViewGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CommonLinkPreview from "./commons/CommonLinkPreview";
import CommonPostAsPopUp from "./commons/CommonPostAsPopUp";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import uuid from "react-uuid";

const ErorrMsgPopUp = withReactContent(alertPopUp);

if (isMobileDevice()) {
  import("./commons/styles/responsive-useragent.css").then(() => {
    console.log("Imported mobile css");
  });
}

httpService.setupInterceptors();

const maxMbFileSize = 100 * 1024 * 1024;

const re = /<a\s.*?href=["']([^"']*?[^"']*?)[^>]*>(.*?)<\/a>/g;

class PostPopup extends React.Component {
  constructor(props) {
    super(props);
    this.googleMapRef = React.createRef();
    let validation = {
      postText: {},
    };
    let userData = authService.getUserDetailsFromLocalStorage();
    this.state = {
      linkdata: "",
      userData: userData,
      webcamOn: false,
      isLoading: false,
      locationSearchOptions: [],
      locationSearchBox: false,
      postPrivacy: "ANYONE",
      postText: "",
      validation,
      userId: "",
      imageerror: false,
      errors: {},
      hashTags: [],
      isPostSubmitted: false,
      userList: [],
      searchUserByChars: "",
      selectedList: [],
      visibleToUserIds: [],
      postId: "",
      hiddenPrivacy: false,
      isModalLoading: false,
      displayError: false,
      error: "",
      progress: 0,
      imageUrl: "",
      progressInfos: [],
      currentFile: undefined,
      displayPreview: false,
      displayVideoPreview: false,
      displayAudioPreview: false,
      forcePost: false,
      entityName: userData
        ? userData.type === "COMPANY"
          ? userData.companyName
          : CapitalizeFirstLetter(userData.firstName)
        : "",
      entityType: authService.getUserDetailsFromLocalStorage().type,
      secondaryEntityName: "Feeds",
      coverImage:
        userData && userData.profileImage
          ? getCDNUrl(this.getOptimizedImage(userData))
          : userData.type !== "COMPANY"
            ? "https://cdn.dscovr.com/images/users1.png"
            : "https://cdn.dscovr.com/images/DefaultBusiness.png",
      circleAdminList: [],
      businessPageList: [],
      userName: userData
        ? userData.type === "COMPANY"
          ? userData.companyName
          : CapitalizeFirstLetter(userData.firstName)
        : "",
      smiliDisplay: false,
      imageList: [],
      secondaryAttachments: [],
      og_data: {},
      displayPresentationView: false,
      showPresentationSection: false,
      presentationViewImagesList: [],
      presentationImages: [],
      pdfDownloadablePptUrl: "",
      rotatedFileDetails: {},
      thumbnailAttachmentFile: {},
      documentTitle: "",
      uploadFiletype: "",
      postAsFlag: false,
      entityData: {},
      isShowEditOption: false,
      intervalId: null,
      thumbnailAttachmentUrl: "",
      thumbnailId: "",
      customThumbnailFlag: false,
      defaultThumbnailAttachment: {},
      remove_id: uuid(),
      fallback: false,
      videoIntervalId: null
    };
  }

  getLinkPreviewUrl = (text) => {
    let urlValidPostWenat = text.startsWith(process.env.REACT_APP_domainUrl + "/post");
    let data = {
      url: !urlValidPostWenat ? text : "",
      sendByMyWenat: true
    }
    if (urlValidPostWenat) {
      data.id = text.substring(text.lastIndexOf("/") + 1);
    }
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
      data: data,
      headers: { 'User-Agent': 'my-wenat/1.0.0','Request-Send-By':'mywenat' },
      withCredentials: true
    }).then((response) => {
      if (response?.data?.status === 200) {
        if (!_.isEmpty(response?.data?.data) || response?.data?.data.length > 0) {
          this.setState({
            og_data: response?.data?.data
          })
        } else {
          this.getLinkPreviewUrlDataDetails(data.id);
        }
      }
    }).catch((error) => {
      console.log("Error", error);
    })
  };

  getLinkPreviewUrlDataDetails = (id) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_userServiceURL}/unauth/post-metatags-ById?id=${id}`,
      withCredentials: true
    }).then((response) => {
      if (response && response.data && response.data.status === "200 OK") {
        let data = response.data.body;
        let title = data?.createdBy + " shared on myWeNaturalists | Ecosystem for Nature Professionals.";
        let description = this.getMaxWords(stripHtml(data.metaDescription), 20);
        let image = data.imageUrl ? data.imageUrl : data.postType === "AUDIO" ? "https://cdn.dscovr.com/images/Audio.png" : data.postType === "ARTICLE" ? "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png" : data.postType === "LINK" ? "https://cdn.dscovr.com/images/Link.png" : data.postType === "VIDEO" ? "https://cdn.dscovr.com/images/video-blank2.png" : data.postType === "IMAGE" ? "https://cdn.dscovr.com/images/Photo.png" : "https://cdn.dscovr.com/images/social-share-icon2.png"
        this.setState({
          og_data: { "title": title, "description": description, "image": image }
        })
      }
    }).catch((error) => {
      console.log("Error", error);
    })
  }

  getMaxWords(str, max) {
    if (str != null) {
      var res = str.split(/\s+/).slice(0, max).join(" ");
      res += "...";
      return res;
    }
  }

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  handleSmiliDisplay = () => {
    this.setState({
      smiliDisplay: this.state.smiliDisplay ? false : true,
    });
  };

  onToggleEmojiWindow = () => {
    const { smiliDisplay } = this.state;
    this.setState({ smiliDisplay: !smiliDisplay });
  };

  check_words = (e) => {
    const BACKSPACE = 8;
    const DELETE = 46;
    const MAX_WORDS = 400;
    const valid_keys = [BACKSPACE, DELETE];
    const words = this.value && this.value.split(" ");

    if (
      words &&
      words.length >= MAX_WORDS &&
      valid_keys.indexOf(e.keyCode) === -1
    ) {
      e.preventDefault();
      words.length = MAX_WORDS;
      this.value = words.join(" ");
    }
  };

  getDataList = (entityId, type) => {
    let url = "";
    if (type === "CAUSE") {
      url = `backend/public/cause/joined/list?userId=${entityId}&page=0&size=1000`;
    } else if (type === "PROJECT") {
      url = `backend/getProjects/${entityId}?excludeJobs=true&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`;
    } else {
      url = `circle/get/list-for-post/${entityId}?privateEntityId=${this.state.userData.entityId}`;
    }
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/${url}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response && response.data && response.data.body && response.status === 200) {
        let dataList = type !== "CAUSE" ? response.data.body : response.data.body?.content;
        let data = dataList && dataList.find((data) => data.slug === (type === "CAUSE" ? this.props.match.params?.id : type === "CIRCLE" ? this.props.match.params?.slug : (this.props.match.params?.projectId || this.props.match.params?.slug)));
        if (!_.isEmpty(data)) {
          this.setState({
            entityId: data.id,
            secondaryEntityName: type === "CAUSE" ? data.name : data.title
          })
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getEntityCount = (userId) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/userRelated/count?userId=${userId}&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response && response.data && response.data.message === "Success!") {
        this.setState({
          isShowEditOption: response.data.body > 0 ? true : false
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  componentDidMount() {
    this.getEntityCount(this.state.userData.userId);
    if (this.props.match?.path === "/cause/:id") {
      this.getDataList(this.state.userData.userId, "CAUSE");
    }
    if (this.props.match?.path === "/project/:type/:projectId" || this.props.match?.path === "/project/studio/:slug") {
      this.getDataList(this.state.userData.userId, "PROJECT");
    }
    if (this.props.match?.path === "/community/:slug" || this.props.match?.path === "/community/activity/:slug" || this.props.match?.path === "/community/all-activity/:slug" ||
      this.props.match?.path === "/community/projects/:slug" || this.props.match?.path === "/community/members/:slug" || this.props.match?.path === "/community/all-members/:slug" ||
      this.props.match?.path === "/community/stats/:slug" || this.props.match?.path === "/community/award/:slug") {
      this.getDataList(this.state.userData.userId, "CIRCLE");
    }
    window.$(".dropdown-submenu > a").on("click", function (e) {
      var submenu = $(this);
      $(".dropdown-submenu .dropdown-menu").removeClass("show");
      submenu.next(".dropdown-menu").addClass("show");
      e.stopPropagation();
    });
    window.$("input#colon").on({
      // e.which >= 32 && e.which <= 47 && e.which >= 58 && e.which <= 64 && e.which >= 91 && e.which <= 95
      keypress: function (e) {
        if (e.key === "#") return false;
      },
      change: function () {
        this.value = this.value.replace(/\s/g, "");
      },
    });
    // var textarea = document.getElementById('input_box');
    // if (textarea !== null && textarea !== ' ' && textarea !== undefined) {
    //     textarea.addEventListener('keydown', this.check_words);
    //     textarea.addEventListener('keyup', this.check_words);
    // }

    document.querySelector(".wrapper").addEventListener("click", event => {
      // Get parent element and check if click happened outside parent only
      const parent = document.querySelector(".emoji-picker-react");
      const { smiliDisplay } = this.state;
      if (smiliDisplay && !parent.contains(event.target)) {
        this.onToggleEmojiWindow();
      }
    });

    // create suggested hashtag
    this.triggerApiCall();
  }

  componentDidUpdate(prevProps, prevState) {
    var regex = /\s+/gi;
    if (this.state.postText !== prevState.postText) {
      if (!this.state.intervalId && stripHtml(this.state.postText)
        .trim()
        .replace(regex, " ")
        .split(" ").length > 49) {
        const intervalId = setInterval(() => {
          // make API call
          this.triggerApiCall();
        }, 5000);
        this.setState({ intervalId });
      }else if(stripHtml(this.state.postText)
        .trim()
        .replace(regex, " ")
        .split(" ").length === 1){
          clearInterval(this.state.intervalId);
          this.setState({
            intervalId: null
          })
      }
      
    }
  }

  triggerApiCall() {
    // make API call here
    let data = {
      "post": this.state.postText,
      "postId": this.state.remove_id
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_userServiceURL + "/recommend/runtimehashtag/",
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      data: data
    }).then((res) => {
      if (res?.data?.status === 200) {
        let allHashTagsList = res?.data?.data;
        let hashTagsList = this.state.hashTags.concat([...new Set(allHashTagsList)]);
        this.setState({ hashTags: [...new Set(hashTagsList)] });
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  startStopWebcam = () => {
    if (this.state.webcamOn === true) {
      this.setState({ webcamOn: false });
    } else {
      this.setState({ webcamOn: true });
    }
  };

  openLocationDialogBox = () => {
    this.setState({ locationSearchBox: true });
  };

  dismissLocationSearchBox = () => {
    this.setState({ locationSearchBox: false });
  };

  // Image Upload Function
  uploadImages = (e) => {
    this.setState({
      isPostSubmitted: true,
      error: "File Uploading...",
    });
    let _progressInfos = cloneDeep(this.state.progressInfos);
    _progressInfos = _progressInfos ? _progressInfos : [];
    let currentArrSize = _progressInfos.length;
    let promises = [];
    window.$("#postImagePreview").modal("show");
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        _progressInfos.push({
          percentage: 0,
          fileName: e.target.files[i].name,
          url: URL.createObjectURL(e.target.files[i]),
          size: e.target.files[i].size,
          rotationFlag: false,
          rotationDegree: 0
        });
      }
      this.setState({
        progressInfos: _progressInfos,
      });
      for (let i = 0; i < e.target.files.length; i++) {
        let currentFile = null;
        let fileSize = 0;
        currentFile = e.target.files[i];
        fileSize += e.target.files[i].size;
        const fileName = e.target.files[i].name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        const allowedAttachmentsTypeArr = ["jpg", "jpeg", "png", "gif"];

        if (!allowedAttachmentsTypeArr.includes(extFile)) {
          this.setState({
            error:
              "Only " +
              allowedAttachmentsTypeArr.join("/") +
              " files are allowed!",
            displayError: true,
          });
          document.getElementById("imageUploader").value = null;
          setTimeout(() => {
            this.setState({
              error: "",
              displayError: false,
              isPostSubmitted: false,
            });
          }, 5000);
          return;
        }
        if (fileSize > maxMbFileSize) {
          this.setState({
            error:
              "Uploaded files exceeds limit of 100 MB. Replace/Remove some files",
            displayError: true,
          });
          document.getElementById("imageUploader").value = null;
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        } else {
          if (currentFile !== null) {
            this.setState({
              displayPreview: true,
            });
            // this.uploadAttachment(i, "IMAGE", currentFile);
            promises.push(
              this.uploadImageAttachment(currentArrSize + i, currentFile)
            );
          }
        }
      }
    }
    Promise.all(promises)
      .then((values) => {
        this.setState({
          isPostSubmitted: false,
          error: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          displayPreview: false,
        });
      });
  };

  //   Video Upload Function
  uploadVideoFile = (e) => {
    this.setState({
      isPostSubmitted: true,
      error: "File Uploading...",
    });
    let _progressInfos = cloneDeep(this.state.progressInfos);
    _progressInfos = _progressInfos ? _progressInfos : [];
    let promises = [];
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        let fileSize = e.target.files[i].size;
        if (fileSize > maxMbFileSize) {
          this.setState({
            error: "Uploaded file exceeds limit of 100 MB",
            displayError: true,
          });
          document.getElementById("videoUploader").value = null;
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        } else {
          window.$("#postVideoPreview").modal("show");
          _progressInfos.push({
            percentage: 0,
            size: e.target.files[i].size,
            fileName: e.target.files[i].name,
            url: URL.createObjectURL(e.target.files[i]),
          });

          this.setState({
            //   videoFilePreview: URL.createObjectURL(e.target.files[i]),
            progressInfos: _progressInfos,
          });

          const fileName = document.getElementById("videoUploader").value;
          const idxDot = fileName.lastIndexOf(".") + 1;
          const extFile = fileName
            .substr(idxDot, fileName.length)
            .toLowerCase();
          const allowedAttachmentsTypeArr = [
            "mp4",
            "webm",
            "mkv",
            "mov",
            "avi",
            "ogg",
            "flv",
            "wmv",
            "mpeg"
          ];
          if (allowedAttachmentsTypeArr.includes(extFile)) {
            this.setState({
              displayVideoPreview: true,
            });
            promises.push(
              this.uploadVideoAttachment("VIDEO", e.target.files[i])
            );
            // this.uploadAttachment('VIDEO', e.target.files[0]);
          } else {
            this.setState({
              error:
                "Only " +
                allowedAttachmentsTypeArr.join("/") +
                " files are allowed!",
              displayError: true,
              isPostSubmitted: false,
              videoFilePreview: null,
              videoFile: null,
              progressInfos: [],
              displayVideoPreview: false,
            });
            document.getElementById("videoUploader").value = null;
            window.$("#postVideoPreview").modal("hide");
            window.$("#postAudioPreview").modal("hide");
            setTimeout(() => {
              this.setState({
                error: "",
                displayError: false,
              });
            }, 5000);
          }
        }
      }
    }
    Promise.all(promises)
      .then((values) => {
        this.setState({
          isPostSubmitted: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: "Some error occurred, Retry!",
          displayError: true,
          videoFilePreview: null,
          videoFile: null,
          progressInfos: [],
          displayVideoPreview: false,
        });
        document.getElementById("videoUploader").value = null;
        window.$("#postVideoPreview").modal("hide");
        window.$("#postAudioPreview").modal("hide");
        setTimeout(() => {
          this.setState({
            error: "",
            displayError: false,
          });
        }, 5000);
      });
  };


  handleChangeThumbnailUpload = (e) => {
    let currentFile = e.target.files[0];
    let thunbnailAttachmentId = currentFile.length;
    const fileName = document.getElementById('thumbnailImageFileUploader').value;
    const idxDot = fileName.lastIndexOf(".") + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    const allowedAttachmentsTypeArr = ['jpg', 'jpeg', 'png']

    let thumbnailAttachmentFile = {
        fileName: e.target.files[0].name,
        attachmentUrl: URL.createObjectURL(e.target.files[0]),
        rotationFlag: false,
        rotationDegree: 0
    };
    this.setState({
        thumbnailAttachmentFile: thumbnailAttachmentFile
    })

    if (allowedAttachmentsTypeArr.includes(extFile)) {
      this.uploadImageAttachment(thunbnailAttachmentId, currentFile, true)
    } else {
      ErorrMsgPopUp.fire({
        didOpen: () => {
          ErorrMsgPopUp.clickConfirm();
        }
      }).then((result) => {
        return ErorrMsgPopUp.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          title: <><p>{'Only ' + allowedAttachmentsTypeArr.join("/") + ' files are allowed!'}</p></>,
        })
      })
    }
  }

  // Video, Audio, File Upload Api Calls
  uploadImageAttachment(id, currentFile, thumbnailFlag) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", currentFile);
      formData.append("userId", this.state.userData.userId);
      let attachmentTypeJson = "IMAGE";
      formData.append("attachmentType", attachmentTypeJson);

      const onUploadProgress = (event) => {
        let progressInfos = cloneDeep(this.state.progressInfos);
        if (progressInfos[id]) {
          progressInfos[id].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
        }

        this.setState({ progressInfos: progressInfos });
      };

      axios({
        method: "post",
        url:
          process.env.REACT_APP_userServiceURL +
          "/backend/post/uploadAttachment",
        data: formData,
        withCredentials: true,
        onUploadProgress
      })
        .then((response) => {
          if (response && response.data && response.data.body) {
            if (thumbnailFlag) {
              this.setState({ thumbnailId: response.data.body?.id, thumbnailAttachmentUrl: response.data.body?.attachmentUrl, customThumbnailFlag: true })
            } else {
              let imageList = cloneDeep(this.state.imageList);
              let imagePreviewList = cloneDeep(this.state.imagePreviewList);
              imageList = imageList ? imageList : [];
              imagePreviewList = imagePreviewList ? imagePreviewList : [];
              const rotationImageCount = this.state.progressInfos && this.state.progressInfos.filter(data => data.rotationFlag).length;

              if (rotationImageCount > 0) {
                var parsedUrl = response.data.body.attachmentUrl.substring(response.data.body.attachmentUrl.lastIndexOf("/") + 1);
                let rotatedFilename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
                let progressInfos = this.state.progressInfos;
                let found = imageList.findIndex(function (item) {
                  var parsedUrl = item.attachmentUrl.substring(item.attachmentUrl.lastIndexOf("/") + 1);
                  let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
                  return rotatedFilename == filename;
                });
                if (found > -1) {
                  imageList.splice(found, 1, response.data.body);
                  progressInfos[id].rotationFlag = false;
                  this.setState({ imageList: imageList, progressInfos })
                }
              } else {
                imageList.push(response.data.body);
                imagePreviewList.push(response.data.body.attachmentUrl);
                this.setState({
                  imageList: imageList,
                  imagePreviewList: imagePreviewList,
                  currentFile: response.data.body.attachmentUrl,
                });

              }
            }
          }
          resolve();
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }

  uploadVideoAttachment(attachmentType, currentFile) {
    return new Promise((resolve, reject) => {
      this.setState({ error: "File Uploading..." });
      const formData = new FormData();
      formData.append("file", currentFile);
      formData.append("userId", this.state.userData.userId);
      const onUploadProgress = (event) => {
        let progressInfos = cloneDeep(this.state.progressInfos);
        progressInfos[0].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({ progressInfos: progressInfos });
      };
      let attachmentTypeJson;
      attachmentTypeJson = attachmentType;

      formData.append("attachmentType", attachmentTypeJson);
      axios({
        method: "post",
        url:
          process.env.REACT_APP_userServiceURL +
          "/backend/post/uploadAttachment",
        data: formData,
        withCredentials: true,
        onUploadProgress,
      })
        .then((response) => {
          this.setState({ error: "" });
          if (
            response &&
            response.data.body !== null &&
            response.data.body.id !== null
          ) {
            if (attachmentType === "VIDEO") {
              this.setState({
                videoFilePreview: response.data.body.attachmentUrl,
                videoFile: response.data.body.id,
                defaultThumbnailAttachment: response.data.body,
                thumbnailAttachmentUrl: response.data.body?.thumbnails?.length > 0 ? response.data.body?.thumbnails[0] : "",
                thumbnailId: "",
                customThumbnailFlag: false
              });
              var timesRun = 0;
              const videoIntervalId = setInterval(() => {
                  timesRun += 1;
                  this.getVideoThumbnailData(videoIntervalId, timesRun);
              }, 5000);
            }
            window.$("#postVideoPreview").modal("hide");
          }
          resolve();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: "Please try again...", displayError: true });
          reject(err);
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        });
    });
  }

  //   Audio File Upload

  uploadAudioFile = (e) => {
    this.setState({
      isPostSubmitted: true,
      error: "File Uploading...",
    });
    let _progressInfos = cloneDeep(this.state.progressInfos);
    _progressInfos = _progressInfos ? _progressInfos : [];
    let promises = [];
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        let fileSize = e.target.files[i].size;
        if (fileSize > maxMbFileSize) {
          this.setState({
            error: "Uploaded file exceeds limit of 100 MB",
            displayError: true,
          });
          document.getElementById("audioUploader").value = null;
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        } else {
          window.$("#postAudioPreview").modal("show");
          _progressInfos.push({
            percentage: 0,
            size: e.target.files[i].size,
            fileName: e.target.files[i].name,
            url: URL.createObjectURL(e.target.files[i]),
          });
          this.setState({
            // audioFilePreview: URL.createObjectURL(e.target.files[i]),
            progressInfos: _progressInfos,
          });
          const fileName = document.getElementById("audioUploader").value;
          const idxDot = fileName.lastIndexOf(".") + 1;
          const extFile = fileName
            .substr(idxDot, fileName.length)
            .toLowerCase();

          //fileSize += this.getExistingTotalAttachmentsSize();
          const allowedAttachmentsTypeArr = [
            "mp3",
            "ogg",            
            "aac",
            "wav",
          ];
          if (allowedAttachmentsTypeArr.includes(extFile)) {
            this.setState({
              displayAudioPreview: true,
            });
            promises.push(
              this.uploadAudioAttachment("AUDIO", e.target.files[i])
            );
          } else {
            this.setState({
              error:
                "Only " +
                allowedAttachmentsTypeArr.join("/") +
                " files are allowed!",
              displayError: true,
              isPostSubmitted: false,
              audioFilePreview: null,
              audioFile: null,
              progressInfos: [],
              displayAudioPreview: false,
            });
            document.getElementById("audioUploader").value = null;
            window.$("#postVideoPreview").modal("hide");
            window.$("#postAudioPreview").modal("hide");
            setTimeout(() => {
              this.setState({
                error: "",
                errors: "",
                displayError: false,
              });
            }, 5000);
          }
        }
      }
    }
    Promise.all(promises)
      .then((values) => {
        this.setState({
          isPostSubmitted: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: "Some error occured, Retry!",
          displayError: true,
          audioFilePreview: null,
          audioFile: null,
          progressInfos: [],
          displayAudioPreview: false,
        });
        document.getElementById("audioUploader").value = null;
        window.$("#postVideoPreview").modal("hide");
        window.$("#postAudioPreview").modal("hide");
        setTimeout(() => {
          this.setState({
            error: "",
            errors: "",
            displayError: false,
          });
        }, 5000);
      });
  };

  uploadAudioAttachment(attachmentType, currentFile) {
    return new Promise((resolve, reject) => {
      this.setState({ error: "File Uploading..." });
      const formData = new FormData();
      formData.append("file", currentFile);
      formData.append("userId", this.state.userData.userId);
      const onUploadProgress = (event) => {
        let progressInfos = cloneDeep(this.state.progressInfos);
        progressInfos[0].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({ progressInfos: progressInfos });
      };
      let attachmentTypeJson;
      attachmentTypeJson = attachmentType;
      formData.append("attachmentType", attachmentTypeJson);
      axios({
        method: "post",
        url:
          process.env.REACT_APP_userServiceURL +
          "/backend/post/uploadAttachment",
        data: formData,
        withCredentials: true,
        onUploadProgress,
      })
        .then((response) => {
          this.setState({ error: "" });
          if (
            response &&
            response.data.body !== null &&
            response.data.body.id !== null
          ) {
            if (attachmentType === "AUDIO") {
              this.setState({
                audioFilePreview: response.data.body.attachmentUrl,
                audioFile: response.data.body.id,
              });
            }
            window.$("#postAudioPreview").modal("hide");
          }
          resolve();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: "Please try again...", displayError: true });
          reject(err);
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        });
    });
  }

  uploadAttachment(attachmentType, currentFile) {
    return new Promise((resolve, reject) => {
      this.setState({ error: "File Uploading..." });
      const formData = new FormData();
      formData.append("file", currentFile);
      formData.append("userId", this.state.userData.userId);
      let attachmentTypeJson;
      if (attachmentType === "SECONDARY") {
        attachmentTypeJson = "UNKNOWN";
      } else if (attachmentType === "WEBCAM") {
        attachmentTypeJson = "IMAGE";
      } else {
        attachmentTypeJson = attachmentType;
      }

      formData.append("attachmentType", attachmentTypeJson);
      axios({
        method: "post",
        url:
          process.env.REACT_APP_userServiceURL +
          "/backend/post/uploadAttachment",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          this.setState({ error: "", isPostSubmitted: false });
          if (
            response &&
            response.data.body !== null &&
            response.data.body.id !== null
          ) {
            if (attachmentType === "IMAGE") {
              let imageList = cloneDeep(this.state.imageList);
              let imagePreviewList = cloneDeep(this.state.imagePreviewList);
              imageList = imageList ? imageList : [];
              imagePreviewList = imagePreviewList ? imagePreviewList : [];
              imageList.push(response.data.body.id);
              imagePreviewList.push(response.data.body.attachmentUrl);

              this.setState({
                imageList: imageList,
                imagePreviewList: imagePreviewList,
              });
            } else if (attachmentType === "AUDIO") {
              this.setState({
                audioFilePreview: response.data.body.attachmentUrl,
                audioFile: response.data.body.id,
              });
            } else if (attachmentType === "VIDEO") {
              this.setState({
                videoFilePreview: response.data.body.attachmentUrl,
                videoFile: response.data.body.id,
              });
            } else if (attachmentType === "SECONDARY") {
              let secondaryAttachments = cloneDeep(
                this.state.secondaryAttachments
              );
              secondaryAttachments = secondaryAttachments
                ? secondaryAttachments
                : [];
              secondaryAttachments.push(response.data.body.id);

              this.setState({
                secondaryAttachments: secondaryAttachments
              });
            } else if (attachmentType === "WEBCAM") {
              this.setState({
                imageUri: response.data.body.attachmentUrl,
                webcamImageFile: response.data.body.id,
                webcamOn: false,
              });
            }
            window.$("#postVideoPreview").modal("hide");
            window.$("#postAudioPreview").modal("hide");
          }
          resolve();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: "Please try again...", displayError: true });
          reject(err);
          setTimeout(() => {
            this.setState({
              error: "",
              isPostSubmitted: false
            });
          }, 5000);
        });
    });
  }

  uploadPptAttachment(currentFile, fileType) {
    return new Promise((resolve, reject) => {
      this.setState({ error: "File Uploading..." });
      const formData = new FormData();
      formData.append("file", currentFile);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_pptCovertURL}/${fileType === "pdf" ? "convert-to-png" : "convert-to-pdf"}`,
        data: formData,
        withCredentials: false,
      })
        .then((response) => {
          this.setState({ error: "", isPostSubmitted: false });
          if (response && response.data && response.status === 200) {
            let presentationViewImagesList = [];
            this.setState({ showPresentationSection: true })
            response.data && response.data.images && response.data.images.map((data) => {
              presentationViewImagesList.push({ original: data, thumbnail: data })
            })

            this.setState({
              presentationViewImagesList: presentationViewImagesList,
              presentationImages: response.data?.images,
              pdfDownloadablePptUrl: response.data?.pdf,
              uploadFiletype: fileType === "pdf" ? "PDF" : "PPT"
            });
          }
          resolve();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: "Please try again...", displayError: true });
          reject(err);
          setTimeout(() => {
            this.setState({
              error: "",
              isPostSubmitted: false,
              displayPresentationView: false,
              showPresentationSection: false,
              presentationViewImagesList: [],
              presentationImages: [],
              uploadFiletype: ""
            });
          }, 5000);
        });
    });
  }

  imagesUpload = () => {
    this.refs.imagesFileUploader.click();
  };

  audioUpload = () => {
    this.refs.audioFileUploader.click();
  };

  videoUpload = () => {
    this.refs.videoFileUploader.click();
  };

  thumbnailUpload = () => {
    this.refs.thumbnailImageFileUploader.click();
  };

  uploadSecondaryAttachments = (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        let currentFile = null;
        let fileSize = 0;
        currentFile = e.target.files[i];
        fileSize += e.target.files[i].size;
        const fileName = e.target.files[i].name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        //const allowedAttachmentsTypeArr = type === "PPT" ? ["ppt", "pptx"] : type === "PDF" ? ["pdf"] : ["csv", "xlsx", "xls", "doc", "docx", "txt"];
        const allowedAttachmentsTypeArr = type === "ATTACHMENT" ? ["ppt", "pptx", "pdf"] : [ "csv", "xlsx", "xls", "doc", "docx", "txt"];

        if (!allowedAttachmentsTypeArr.includes(extFile)) {
          this.setState({
            error: "Only " + allowedAttachmentsTypeArr.join("/") + " files are allowed!",
            displayError: true,
            isPostSubmitted: false,
          });
          document.getElementById(type === "ATTACHMENT" ? "pptPdfAttachmentsFileUploader" : "secondaryAttachmentsFileUploader").value = null;
          setTimeout(() => {
            this.setState({
              error: "",
              displayError: false,
            });
          }, 5000);
          return;
        } else {
          this.setState({
            displayPresentationView: (extFile === "ppt" || extFile === "pptx" || extFile === "pdf") ? true : false
          })
        }

        if (fileSize > maxMbFileSize) {
          this.setState({
            error: "Uploaded files exceeds total limit of 100 MB. Replace/Remove some files",
          });
          document.getElementById(type === "ATTACHMENT" ? "pptPdfAttachmentsFileUploader" : "secondaryAttachmentsFileUploader").value = null;
          setTimeout(() => {
            this.setState({
              error: "",
              isPostSubmitted: false,
            });
          }, 5000);
        } else {
          if (currentFile !== null) {
            this.setState(
              {
                isPostSubmitted: true,
                error: "File Uploading...",
              },
              () => {
                if (extFile === "ppt" || extFile === "pptx" || extFile === "pdf") {
                  this.uploadPptAttachment(currentFile, extFile)
                } else {
                  this.uploadAttachment("SECONDARY", currentFile);
                }
              }
            );
          }
        }
      }
    }
  };

  secondaryAttachmentsUpload = () => {
    this.refs.secondaryAttachmentsUploader.click();
  };

  pptPdfAttachmentsUpload = () => {
    this.refs.pptPdfAttachmentsUploader.click();
  };

  pdfAttachmentsUpload = () => {
    this.refs.pdfAttachmentsUploader.click();
  };

  getExistingTotalAttachmentsSize() {
    let fileSize = 0;

    if (this.state.secondaryAttachments) {
      for (let i = 0; i < this.state.secondaryAttachments.length; i++) {
        fileSize += this.state.secondaryAttachments[i].size;
      }
    }

    if (this.state.videoFile) {
      fileSize += this.state.videoFile.size;
    }

    if (this.state.audioFile) {
      fileSize += this.state.audioFile.size;
    }

    if (this.state.imageList) {
      for (let i = 0; i < this.state.imageList.length; i++) {
        fileSize += this.state.imageList[i].size;
      }
    }

    if (this.state.webcamImageFile) {
      fileSize += this.state.webcamImageFile.size;
    }

    return fileSize;
  }

  dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  onCameraError = (e) => {
    setTimeout(() => {
      $(".react-html5-camera-photo").hide();
      this.setState({ webcamOn: false });
    }, 5000);
  };

  handleTakePhotoAnimationDone = (imageUri) => {
    let blob = this.dataURItoBlob(imageUri);
    let file = new File([blob], "webcam_post.jpg", { type: "image/jpeg" });
    let fileSize = file.size;

    //fileSize += this.getExistingTotalAttachmentsSize();

    if (fileSize > maxMbFileSize) {
      this.setState({ error: "Uploaded file exceeds limit of 100 MB" });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 5000);
    } else {
      this.uploadAttachment("WEBCAM", file);

      /*this.setState({
                'imageUri': imageUri,
                'webcamImageFile': file,
                'webcamOn': false
            });*/
    }
  };

  onCancelImage = () => {
    this.setState({
      imageUri: null,
      webcamImageFile: null,
    });
  };

  removeSecondaryAttachments = () => {
    this.setState(
      {
        secondaryAttachments: [],
        displayPresentationView: false,
        showPresentationSection: false,
        presentationViewImagesList: [],
        presentationImages: [],
        uploadFiletype: ""
      },
      () => {
        document.getElementById("secondaryAttachmentsFileUploader").value = null;
      }
    );
  };

  removeAudioFile = () => {
    this.setState({
      audioFile: null,
      audioFilePreview: null,
      progressInfos: [],
      displayVideoPreview: false,
      displayAudioPreview: false,
    });

    window.$("#postAudioPreview").modal("hide");
  };

  removeVideoFile = () => {
    this.setState({
      videoFile: null,
      videoFilePreview: null,
      progressInfos: [],
      displayVideoPreview: false,
      displayAudioPreview: false,
      thumbnailAttachmentFile: {},
      thumbnailAttachmentUrl: "",
      thumbnailId: "",
      defaultThumbnailAttachment: {},
      customThumbnailFlag: false
    });

    window.$("#postVideoPreview").modal("hide");
  };

  removeThumbnailAttachmentFile = () => {
    this.setState({
      thumbnailAttachmentFile: {},
      thumbnailAttachmentUrl: !_.isEmpty(this.state.defaultThumbnailAttachment) ? this.state.defaultThumbnailAttachment?.thumbnails?.length > 0 && this.state.defaultThumbnailAttachment?.thumbnails[0] : "",
      thumbnailId: "",
      customThumbnailFlag: false
    })

  }

  removeLocalImage = (fileDetails, index) => {
    let imagePreviewList = cloneDeep(this.state.progressInfos);
    let imageList = cloneDeep(this.state.imageList);
    let selectedFileName = fileDetails.fileName.replace(/ /g, "_").replace(/[^A-Za-z0-9_.]/ig, "");
    if (imagePreviewList && imagePreviewList.length > 0) {
      imagePreviewList.splice(index, 1);
      if (imageList) {
        let found = imageList.findIndex(function (item) {
          var parsedUrl = item.attachmentUrl.substring(item.attachmentUrl.lastIndexOf("/") + 1);
          let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
          return selectedFileName == filename;
        });
        // imageList.splice(_.findIndex(imageList, function(item) {
        //     var parsedUrl = item.attachmentUrl.substring(item.attachmentUrl.lastIndexOf("/") + 1);
        //     let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
        //     return filename === fileDetails.fileName;

        // }), 1);
        if (found > -1) {
          imageList.splice(found, 1);
        }
      }
      if (!this.state.linkdata || imageList.length === 0) {
        document.getElementById("imageUploader").value = null;
        window.$("#postImagePreview").modal("hide");
      }
      this.setState({
        progressInfos: imagePreviewList,
        imageList: imageList,
        displayPreview: imageList && imageList.length === 0 ? false : true
      });
    }
  };

  removeImage = (index) => {
    let imagePreviewList = this.state.imagePreviewList;

    if (imagePreviewList && imagePreviewList.length > 0) {
      let imageList = this.state.imageList;
      imagePreviewList.splice(index, 1);
      imageList.splice(index, 1);

      if (imageList.length === 0) {
        document.getElementById("imageUploader").value = null;
      }
      this.setState({
        imagePreviewList: imagePreviewList,
        imageList: imageList,
      });
    }
  };

  componentWillUnmount() {
    this.hideModal(true);
    window.$("#postSelectUserModal").modal("hide");
    clearInterval(this.state.intervalId)
  }

  hideModal = (isModalOpen) => {
    if (this.state.webcamOn === true) {
      this.setState({ webcamOn: false });
    } else {
      this.setState({
        webcamOn: false,
        isPostSubmitted: false,
        isLoading: false,
        taggedLocation: null,
        locationSearchOptions: [],
        imageList: null,
        imagePreviewList: null,
        audioFilePreview: null,
        audioFile: null,
        videoFilePreview: null,
        videoFile: null,
        secondaryAttachments: null,
        error: null,
        postText: "",
        hashTags: [],
        imageUri: null,
        webcamImageFile: null,
        postPrivacy: "ANYONE",
        isModalLoading: false,
        hiddenPrivacy: false,
        displayPreview: false,
        displayVideoPreview: false,
        displayAudioPreview: false,
        progressInfos: [],
        userId: this.state.userData && this.state.userData.userId,
        entityType: "Individual",
        entityId: null,
        entityName: this.state.userName,
        secondaryEntityName: "Feeds",
        coverImage:
          this.state.userData && this.state.userData.profileImage
            ? getCDNUrl(this.getOptimizedImage(this.state.userData))
            : this.state.userData.type != "COMPANY"
              ? "https://cdn.dscovr.com/images/users1.png"
              : "https://cdn.dscovr.com/images/DefaultBusiness.png",
        projectList: this.props.projectList,
        causeList: this.props.causeList,
        circleList: this.props.circleList,
        smiliDisplay: false,
        linkdata: "",
        remove_id: ""
      });
      this.clearUsers();
      if (document.getElementById("secondaryAttachmentsFileUploader")) {
        document.getElementById("secondaryAttachmentsFileUploader").value =
          null;
      }
      if (document.getElementById("audioUploader")) {
        document.getElementById("audioUploader").value = null;
      }
      if (document.getElementById("videoUploader")) {
        document.getElementById("videoUploader").value = null;
      }
      if (document.getElementById("imageUploader")) {
        document.getElementById("imageUploader").value = null;
      }
      this.props.openModalClose();
      if (!isModalOpen) {
        this.props.acceptPopUpModalDetail(this.state.postId);
      }
      //window.$("#toppostwrap").modal("hide");
      window.$("#cancelPopup").modal("hide");
      if (this.refs.TagsRef) {
        this.refs.TagsRef.clearInput();
      }
    }
  };

  /*handleSearch = (query) => {
        this.setState({'isLoading': true});
        fetch(`https://us1.locationiq.com/v1/search.php?key=` + process.env.REACT_APP_locationIQKey + `&q=${query}&format=json`)
            .then((resp) => resp.json())
            .then(items => {
                if (items && !items.error) {
                    const options = items.map((item) => ({
                        name: item.display_name,
                        lat: item.lat,
                        lon: item.lon
                    }));

                    this.setState({'locationSearchOptions': options});
                    this.setState({'isLoading': false});
                }
            });
    }*/

  handleLocationSelected = (selectedOption) => {
    if (selectedOption && Object.keys(selectedOption).length > 0) {
      let locationName = selectedOption.label;
      let placeId = selectedOption.value.place_id;
      if (placeId && placeId !== "") {
        let googleMap = new window.google.maps.Map(
          this.googleMapRef.current,
          {}
        );

        const service = new window.google.maps.places.PlacesService(googleMap);

        service.getDetails(
          {
            placeId: placeId,
          },
          (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              let latitude = place.geometry.location.lat();
              let longitude = place.geometry.location.lng();
              let coordinatesObj = {
                name: locationName,
                lat: latitude,
                lon: longitude,
              };
              this.setState({ taggedLocation: coordinatesObj });
            }
          }
        );
      }
    }
    this.setState({ locationSearchBox: false });
  };

  /*handleLocationSelected = (selectedOption) => {
        if (selectedOption && selectedOption.length > 0) {
            this.setState({'taggedLocation': selectedOption[0]});
        }
        this.setState({'locationSearchBox': false});
    }*/

  changePostPrivacy = (event) => {
    this.setState({ postPrivacy: event.target.value });
  };

  handlePostTextUpdate = (event) => {
    let value = event.target.value;
    value = value.replace(re, "");
    this.setState({ postText: value }, () => { });
    this.varifyLinkData(value);
    this.check_words(event);
  };

  onEmojiClick = (event, emojiObject) => {
    let postText = cloneDeep(this.state.postText);
    postText = postText + " " + emojiObject.emoji;
    this.setState({
      postText: postText,
    });
  };

  triggerDeleteApiCall = (tag) => {
    // make API call here
    let data = {
      "tag": tag.join(""),
      "postId": this.state.remove_id
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_userServiceURL + "/recommend/runtimehashtag/",
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      data: data
    }).then((res) => {
      if (res?.data?.status === 200) {
        let allHashTagsList = res?.data?.data;
        let hashTagsList = this.state.hashTags.concat([...new Set(allHashTagsList)]);
        this.setState({ hashTags: [...new Set(hashTagsList)] });
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  handleTagsChange = (hashTags) => {
    let hashTagsList = [];
    let oldHashTag = this.state.hashTags;
    if (hashTags && hashTags.length > 0) {
      hashTags &&
        hashTags.map(function (data) {
          data = data.replace(
            /[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi,
            ""
          );
          if (data != "") {
            hashTagsList.push(data);
          }
        });
      let allHashTagsList = hashTagsList;
      hashTagsList = [...new Set(allHashTagsList)];
    }
    const removeData = oldHashTag.filter((item) => !hashTagsList.includes(item));
    this.triggerDeleteApiCall(removeData);
    this.setState({ hashTags: hashTagsList });
  };

  validateForm = () => {
    this.state.validation.postText = {};
    let errors = {};
    let formIsValid = true;
    var regex = /\s+/gi;
    if (
      (!this.state.postText.trim() || this.state.postText.trim() === "") &&
      (!this.state.hashTags || this.state.hashTags.length === 0) &&
      !this.state.taggedLocation &&
      !this.state.webcamImageFile &&
      !this.state.videoFile &&
      !this.state.audioFile &&
      (!this.state.imageList || this.state.imageList.length === 0) &&
      this.state.presentationImages?.length === 0
    ) {
      errors["postText"] = "Please Post something to publish";
      this.state.validation.postText = {
        error: true,
        message: "Please Post something to publish",
        style: { borderColor: "#b34c4c", padding: "10px" },
      };
      formIsValid = false;
    }
    if (
      stripHtml(this.state.postText).trim().replace(regex, " ").split(" ")
        .length > 400
    ) {
      errors["postText"] = "Please enter upto 400 words";
      this.state.validation.postText = {
        error: true,
        message: "Please enter upto 400 words",
        style: { borderColor: "#b34c4c", padding: "10px" },
      };
      formIsValid = false;
    }
    if (
      this.state.postPrivacy == "SELECTED" &&
      this.state.selectedList.length == 0
    ) {
      errors["privacyText"] = "Please Select at least one user";
      formIsValid = false;
    }
    if (this.state.displayPresentationView && this.state.presentationImages?.length > 0 && !this.state.documentTitle) {
      errors["documentTitle"] = "Please add document title";
      formIsValid = false;
    }
    if (this.state.documentTitle && (this.state.documentTitle).replace(/\s/g, "").length > 60) {
      errors["documentTitle"] = "Document title should not be greater than 60 characters";
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleCheckedPrivacy = () => {
    if (this.state.visibleToUserIds && this.state.visibleToUserIds.length > 0) {
      this.setState({ visibleToUserIds: [] });
    }
  };

  handlePostSubmit = () => {
    if (this.validateForm()) {
      const formData = new FormData();
      this.setState({ isPostSubmitted: true });
      let attachmentList = [];
      let errors = {};
      let params = {};

      params = {
        creatorId: this.state.userData.userId,
        userId: this.state.userId
          ? this.state.userId
          : this.state.userData.userId,
        entityId: this.state.entityId ? this.state.entityId : null,
        description: this.state.postText.replace(/(http(s))/g, " $1").trim("\n")/* .replace(/#+/g, '#') */,
        postType: "POST",
        hashTags: this.state.hashTags
          ? this.state.hashTags.map((item) => {
            return item.replace(/#/g, "");
          })
          : [],
        postVisibility: this.state.postPrivacy,
        visibleToUserIds: this.state.visibleToUserIds,
        forcePost: this.state.forcePost,
        privateEntityId : this.state.userData && this.state.userData.entityId,
        userAgent: userAgentPostData()
      };

      let linkParams = {
        creatorId: this.state.userData.userId,
        userId: this.state.userId
          ? this.state.userId
          : this.state.userData.userId,
        entityId: this.state.entityId ? this.state.entityId : null,
        description: this.state.postText.replace(/(http(s))/g, " $1").trim("\n")/* .replace(/#+/g, '#') */,
        postType: "LINK",
        hashTags: this.state.hashTags
          ? this.state.hashTags.map((item) => {
            return item.replace(/#/g, "");
          })
          : [],
        postVisibility: this.state.postPrivacy,
        visibleToUserIds: this.state.visibleToUserIds,
        postLinkTypeUrl: this.state.linkdata,
        forcePost: this.state.forcePost,
        privateEntityId : this.state.userData && this.state.userData.entityId,
        userAgent: userAgentPostData()
      };

      if (this.state.videoFile) {
        //formData.append("files", this.state.videoFile);
        params.attachmentType = "VIDEO";

        attachmentList.push(this.state.videoFile);
        params.attachmentIds = attachmentList;
        if (this.state.thumbnailId) {
          params.thumbnail = this.state.thumbnailId
        }
      } else if (this.state.audioFile) {
        //formData.append("files", this.state.audioFile);
        params.attachmentType = "AUDIO";
        attachmentList.push(this.state.audioFile);
        params.attachmentIds = attachmentList;
      } else if (this.state.imageList && this.state.imageList.length > 0) {
        /*this.state.imageList.forEach((image) => {
                    formData.append("files", image);
                });*/
        params.attachmentType = "IMAGE";
        this.state.imageList &&
          this.state.imageList.map((attachs) => {
            attachmentList.push(attachs.id);
          });
        params.attachmentIds = attachmentList;
      } else if (this.state.webcamImageFile) {
        //formData.append("files", this.state.webcamImageFile);
        params.attachmentType = "IMAGE";
        attachmentList.push(this.state.webcamImageFile);
        params.attachmentIds = attachmentList;
      }

      if (this.state.secondaryAttachments) {
        /*this.state.secondaryAttachments.forEach((attachment) => {
                    formData.append("secondaryAttachments", attachment);
                });*/
        params.secondaryAttachmentIds = this.state.secondaryAttachments;
      }

      if (this.state.presentationViewImagesList && this.state.presentationViewImagesList.length > 0) {
        let presentationImages = [];
        this.state.presentationImages && this.state.presentationImages.map((image) => {
          presentationImages.push(encodeURIComponent(image))
        })
        params.fileUploadRequest = {
          images: presentationImages,
          pdf: encodeURIComponent(this.state.pdfDownloadablePptUrl),
          title: this.state.documentTitle.replace(/\s+/g,' ').trim(),
          type: this.state.uploadFiletype
        }
      }

      if (this.state.taggedLocation) {
        params.postLocation = this.state.taggedLocation.name;
        params.latitude = this.state.taggedLocation.lat;
        params.longitude = this.state.taggedLocation.lon;
      }

      this.state.linkdata && ((this.state.imageList && this.state.imageList.length === 0) && !this.state.videoFile && !this.state.audioFile && (this.state.secondaryAttachments && this.state.secondaryAttachments.length === 0) && (this.state.presentationViewImagesList && this.state.presentationViewImagesList.length === 0))
        ? formData.append("data", JSON.stringify(linkParams))
        : formData.append("data", JSON.stringify(params));
      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/post/createUpdated",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            this.setState({ forcePost: false, postId: response.data.body });
            this.props.requestPostCreationFulfilled({ success: true });
            this.hideModal();
          } else {
            this.setState({ isPostSubmitted: false });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 404) {
            errors["connectionText"] = "No Connects Yet Post will be visible only to you Want to Proceed?";
            this.setState({
              forcePost: true,
              isPostSubmitted: false,
              errors: errors,
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        error: "",
        errors: {},
      });
    }, 5000);
  };

  confirmModal = () => {
    window.$("#cancelPopup").modal("show");
    window.$("#toppostwrap").modal("hide");
  };

  ignoreModal = () => {
    window.$("#cancelPopup").modal("hide");
    window.$("#toppostwrap").modal("show");
  };

  getConnects(page, size) {
    return new Promise((resolve) => {
      axios({
        method: "get",
        url:
          process.env.REACT_APP_userServiceURL +
          "/entity/network/" +
          this.state.userData.userId +
            '/'+this.state.userData.entityId+
          "/connects" +
          "?page=" +
          page +
          "&size=" +
          size + userAgentGetData(),
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            resolve(response.data.body.content);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  addUserToGroup(evt, data) {
    const selectedList = this.state.selectedList;
    selectedList.push(data);
    let array = [...this.state.userList];
    let index = array.indexOf(data);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ userList: array });
    }
    this.setState({ selectedList: selectedList });
    evt.preventDefault();
  }

  removeUserToGroup(evt, data) {
    const userList = this.state.userList;
    userList.push(data);
    let array = [...this.state.selectedList];
    let index = array.indexOf(data);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedList: array });
    }
    this.setState({ userList: userList });
    evt.preventDefault();
  }

  handleSelectedOnly = () => {
    this.setState({ isModalLoading: true });
    this.getConnects(0, 1000)
      .then((userList) => {
        let selectedList = [];
        if (
          this.state.visibleToUserIds &&
          this.state.visibleToUserIds.length > 0
        ) {
          selectedList = userList.filter(({ id }) =>
            this.state.visibleToUserIds.includes(id)
          );
          selectedList.forEach((data) => {
            let index = userList.indexOf(data);
            if (index !== -1) {
              userList.splice(index, 1);
            }
          });
        }
        this.setState(
          {
            userList: userList,
            selectedList: selectedList,
            isModalLoading: false,
          },
          () => { }
        );
      })
      .catch((err) => console.log(err));
    window.$("#postSelectUserModal").modal("show");
  };

  keyPressedBySearchGroupByChars(event) {
    this.setState({ searchUserByChars: event.target.value });
  }

  clearUsers() {
    document.getElementById("searchGroupByChars").value = "";
    this.setState({
      selectedList: [],
      userList: [],
      searchUserByChars: "",
      visibleToUserIds: [],
    });
  }

  handleUsersSelectSubmit = (event) => {
    event.preventDefault();
    let userIds = [];
    for (let data of this.state.selectedList) {
      userIds.push(data.id);
    }

    document.getElementById("searchGroupByChars").value = "";
    this.setState({ visibleToUserIds: userIds, searchUserByChars: "" });

    window.$("#postSelectUserModal").modal("hide");
  };

  setAsUser = (userId, entityType, entityName, entityId, coverImage) => {
    this.setState(
      {
        userId: userId,
        entityType: entityType,
        entityId: entityType === "Circle" ? entityId : null,
        entityName: entityName,
        secondaryEntityName: entityType === "Circle" ? "Community Activity" : "Feeds",
        hiddenPrivacy: entityType === "Circle" || entityType === "Company" ? true : false,
        postPrivacy: "ANYONE",
        coverImage: coverImage
          ? coverImage
          : this.state.userData && this.state.userData.profileImage
            ? getCDNUrl(this.getOptimizedImage(this.state.userData))
            : this.state.userData.type != "COMPANY"
              ? "https://cdn.dscovr.com/images/users1.png"
              : "https://cdn.dscovr.com/images/DefaultBusiness.png",
      },
      () => {
        this.getProjectList(this.state.userId);
        this.getCauseList(this.state.userId);
        this.getCircleList(this.state.userId);
      }
    );
  };

  setAsEntity = (entityId, entityType, secondaryEntityName) => {
    this.setState({
      entityId: entityId,
      secondaryEntityName: secondaryEntityName,
      hiddenPrivacy:
        entityType === "Circle" ||
          entityType === "Cause" ||
          this.state.entityType === "Company" ||
          (this.state.entityType === "Circle" && entityType === "Project")
          ? true
          : false,
      postPrivacy: "ANYONE",
    });
  };

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  showUser = () => {
    window.location.href = "/post/" + this.state.postId;
  };

  isValidUrl = (str) => {
    let regexp =
      /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  };

  varifyLinkData = (text) => {
    var match =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.exec(
        text
      );
    this.setState({
      linkdata: "",
    });
    if (match && match[0] && this.isValidUrl(match[0])) {
      let data = matchPostLinkUrl(match[0]);
      this.setState({
        linkdata: data,
      }, () => {
        this.getLinkPreviewUrl(this.state.linkdata)
      });
    }
  };

  customControlsPresentation = (props) => {
    return (
      <>
        {this.state.pdfDownloadablePptUrl ?
          <button type="button" className="btn download-attachment" onClick={(e) => download_file_pdf(e, this.state.pdfDownloadablePptUrl)}>
            <i className="iDownloadFile" />
          </button> :
          null
        }
      </>

    );
  };

  rotateImage = (index) => {
    const updProgressInfos = this.state.progressInfos.map((obj, inx) => {
      if (inx == index) {
        obj.rotationFlag = true;
        obj.rotationDegree = obj.rotationDegree + 90;
        obj.rotationDegree = obj.rotationDegree >= 360 ? -360 : obj.rotationDegree;
      }
      return obj;
    })
    this.setState({ progressInfos: updProgressInfos })
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = this.state.progressInfos[index].url;
    img.onload = () => {
      canvas.width = img.height;
      canvas.height = img.width;
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((this.state.progressInfos[index].rotationDegree) * (Math.PI / 180));
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      canvas.toBlob((blob) => {
        let currentFile = new File([blob], this.state.progressInfos[index].fileName, { type: "image/jpeg" });
        this.uploadImageAttachment(index, currentFile);
      }, 'image/jpeg');
    };
  };

  rotateThumbnailImage = () => {
      const rotateImageDetails = this.state.thumbnailAttachmentFile;
      rotateImageDetails.rotationDegree = rotateImageDetails.rotationDegree + 90;
      rotateImageDetails.rotationDegree = rotateImageDetails.rotationDegree >= 360 ? -360 : rotateImageDetails.rotationDegree;
      this.setState({ thumbnailAttachmentFile: rotateImageDetails })
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = this.state.thumbnailAttachmentFile?.attachmentUrl;
      img.onload = () => {
          canvas.width = img.height;
          canvas.height = img.width;
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((this.state.thumbnailAttachmentFile?.rotationDegree) * (Math.PI / 180));
          ctx.drawImage(img, -img.width / 2, -img.height / 2);
          canvas.toBlob((blob) => {
              let currentFile = new File([blob], this.state.thumbnailAttachmentFile?.fileName, { type: "image/jpeg" });
              this.uploadImageAttachment(uuid(), currentFile, true);
          }, 'image/jpeg');
      };
  };

  rotateWebViewImage = (rotationDegree) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = this.state.imageUri;
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      canvas.width = img.height;
      canvas.height = img.width;
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotationDegree) * (Math.PI / 180));
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      canvas.toBlob((blob) => {
        let currentFile = new File([blob], "webcam_post.jpg", { type: "image/jpeg" });
        this.uploadAttachment("WEBCAM", currentFile);
      }, 'image/jpeg');
    };
  };

  cancelPostFilterModal = () => {
    window.$("#postFilterModal").modal("hide");
  };

  removePPtAttachment = () => {
    this.setState({
      displayPresentationView: false,
      showPresentationSection: false,
      presentationViewImagesList: [],
      presentationImages: [],
      pdfDownloadablePptUrl: "",
      documentTitle: ""
    })
  }

  onHandleChange = (event) => {

    this.setState({
      documentTitle: event.target.value.trimStart()
    })
  }

  onClickEditPostAs = () => {
    this.setState({
      postAsFlag: true
    }, () => {
      window.$("#postFilterModal").modal("show");
    })
  }

  onCloseEditPostAs = () => {
    window.$("#postFilterModal").modal("show");
    this.setState({
      postAsFlag: false
    })
  }

  onSelectPostAsData = (data) => {
    this.setState({
      entityId: data.entityId,
      entityName: data.entityName,
      coverImage: data.coverImage,
      userId: data.userId,
      secondaryEntityName: data.secondaryEntityName,
      hiddenPrivacy: data.hiddenPrivacy,
      entityType: data.entityType,
      entityData: data
    })
    this.onCloseEditPostAs();
  }

  updateImage = (imagePath) => {
    const imageLoader = new Image();
      imageLoader.src = imagePath;
      imageLoader.onload = () => {
          this.setState({
            thumbnailAttachmentUrl: imagePath
          });
      };
  }

  getVideoThumbnailData = (videoIntervalId, timesRun) => {
      if(timesRun > 3) {
          clearInterval(this.state.videoIntervalId)
      } else {
          this.setState({
              thumbnailAttachmentUrl: this.state.thumbnailAttachmentUrl+"?"+new Date(),
              videoIntervalId: videoIntervalId
          })
      }
  }

  reloadSrc = e => { 
      if(this.state.fallback){
        e.target.src = "https://cdn.dscovr.com/images/video-blank2.png";
      }else{
        e.target.src = this.state.thumbnailAttachmentUrl
        this.setState({
          fallback: true
        })
      }
  }

  render() {
    var regex = /\s+/gi;
    let Stripbody = stripHtml(this.state.postText)
      .trim()
      .replace(regex, " ")
      .split(" ").length;
    let os = this.getMobileOperatingSystem();

    return (
      <>
        <div
          id="toppostwrap"
          className="modal fade dgpopup openPostbox"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                {this.state.locationSearchBox === true ? (
                  <section className="toppostwrap">
                    <div className="postLocationPop">
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={(e) => {
                          e.preventDefault();
                          this.dismissLocationSearchBox();
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4>Search Location</h4>
                      <div id="map" ref={this.googleMapRef} />
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_googleMapKey}
                        minLengthAutocomplete={3}
                        debounce={800}
                        selectProps={{
                          placeholder: "Search for Location ...",
                          isClearable: true,
                          onChange: this.handleLocationSelected,
                          styles: {
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused
                                ? "#cfe7c7"
                                : null,
                            }),
                          },
                        }}
                      />
                    </div>
                  </section>
                ) : (
                  <section className="toppostwrap">
                    {(this.state.postText && this.state.postText !== "") ||
                      (this.state.imageList && this.state.imageList.length > 0) ||
                      (this.state.videoFile && this.state.videoFile.length > 0) ||
                      (this.state.hashTags && this.state.hashTags.length > 0) ||
                      (this.state.audioFile && this.state.audioFile.length > 0) ||
                      (this.state.secondaryAttachments &&
                        this.state.secondaryAttachments.length > 0) ? (
                      <button
                        className="close topCrossModal"
                        onClick={this.confirmModal}
                      >
                        <i className="iCross" />
                      </button>
                    ) : (
                      <button
                        className="close topCrossModal"
                        onClick={() => this.hideModal(true)}
                      >
                        <i className="iCross" />
                      </button>
                    )}

                    <figure className="userimg">
                      <img src={this.state.coverImage} alt="" />
                    </figure>
                    <div className="toppostinn">
                      <div className="topposthead">
                        <div className="dropdown">
                          You are posting as{" "}
                          <span className="valueBox">{this.state.entityType?.toLowerCase() === "company" ? this.state.entityName : convertUnicode(this.state.entityName)}</span>
                        </div>

                        <div className="dropdown last">
                          in{" "}
                          <span className="valueBox" style={{ cursor: 'text' }}>{convertUnicode(this.state.secondaryEntityName)}</span>
                          {this.state.isShowEditOption &&
                            <button type="button" className="editbutt butt" onClick={this.onClickEditPostAs}>
                              <i className="iEditBox" />
                            </button>
                          }
                          <a className="bkqstn" href="/post-help" target="_blank" title="Help">
                            <i className="iQuestion" />
                          </a>
                        </div>
                      </div>

                      {this.state.error &&
                        this.state.error === "File Uploading..." ? (
                        <span className="alartImgUpload">
                          {this.state.error}
                        </span>
                      ) : null}
                      <div className="toppostpopbody">
                        <div className="toppostpoptop">
                          <div className="tpbfieldlogo">
                            <img
                              src="https://cdn.dscovr.com/images/quotes-logo.webp"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://cdn.dscovr.com/images/quotes-logo.png";
                              }}
                              alt=""
                            />
                          </div>
                          <div className="tpbfield">
                            <form action="">
                              <MentionTextArea
                                value={this.state.postText}
                                onChange={this.handlePostTextUpdate}
                                placeholder="Write here..."
                                style={{
                                  backgroundColor: "#ff0000",
                                }}
                              ></MentionTextArea>
                              {this.state.showPresentationSection &&
                                <>
                                  <div className="presentation-title">
                                    <label>Add Document title <sup className="required-field">*</sup></label>
                                    <input type="text" class="form-control custom-input" placeholder="Add document title here..." name="documentTitle" value={this.state.documentTitle} onChange={this.onHandleChange} />
                                    <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["documentTitle"]}</span>
                                  </div>

                                  <div className={this.state.pdfDownloadablePptUrl ? "presentation-view-gallery" : ""}>
                                    <a href="javascript:void(0)" className="closeimg d-flex justify-content-end" onClick={() => this.removePPtAttachment()}><i className="iCross_Rounded" /></a>
                                    <PresentationViewGallery items={this.state.presentationViewImagesList} showIndex={true} autoPlay={false} showThumbnails={false} showPlayButton={false} disableKeyDown={true} infinite={false} renderCustomControls={this.customControlsPresentation} />
                                  </div>
                                </>
                              }
                              {this.state.linkdata &&
                                <div className="linkpostpreview">
                                  <div className="prevtitle">Preview</div>
                                  <div className="postarticledesc">
                                    {!_.isEmpty(this.state.og_data) &&
                                      <CommonLinkPreview linkdata={this.state.linkdata} og_data={this.state.og_data} />
                                    }
                                  </div>
                                </div>
                              }
                            </form>
                            <span
                              className="login-feedback-error mt-1"
                              style={{ display: "block" }}
                            >
                              {this.state.errors.postText}
                            </span>
                          </div>
                          {this.state.smiliDisplay && (
                            <Picker
                              pickerStyle={{ width: "100%", height: "200px" }}
                              onEmojiClick={this.onEmojiClick}
                            />
                          )}
                        </div>
                        {this.state.webcamOn && (
                          <Camera
                            onCameraError={this.onCameraError}
                            onTakePhotoAnimationDone={
                              this.handleTakePhotoAnimationDone
                            }
                            isFullscreen={false}
                          />
                        )}
                        {this.state.imageUri && (
                          <ImagePreview
                            dataUri={this.state.imageUri}
                            isFullscreen={false}
                            onCancelImage={this.onCancelImage}
                            rotateWebViewImage={this.rotateWebViewImage}
                          />
                        )}
                        {/* {
                                                        this.state.imagePreviewList && this.state.imagePreviewList.length > 0
                                                        && <div style={{display: 'flex'}}>
                                                            {this.state.imagePreviewList.map((image, index) => (
                                                                <div key={index}
                                                                     style={{flex: '33.33%', padding: '5px'}}>
                                                                    <a href="javascript:void(0)" onClick={e => {
                                                                        e.preventDefault();
                                                                        this.removeImage(index);
                                                                    }} style={{
                                                                        position: 'absolute',
                                                                        color: 'black',
                                                                        background: 'white'
                                                                    }}>
                                                                        <i className="fa fa-window-close"/>
                                                                    </a>
                                                                    <img src={image} alt="" style={{width: 100}}/>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    } */}
                        {this.state.audioFilePreview && (
                          <>
                            <div className="imagesList">
                              {this.state.progressInfos &&
                                this.state.progressInfos.map(
                                  (progressInfo, index) => (
                                    <div className="imagesListGrid video">
                                      <div className="imgGridinn">
                                        {" "}
                                        <a
                                          href="javascript:void(0)"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.removeAudioFile();
                                          }}
                                          className="closeimg"
                                        >
                                          <i className="iCross" />
                                        </a>
                                        {/* <audio controls src={this.state.audioFilePreview}
                                                                       id="audiotrack"
                                                                       preload="auto" itemProp="audio"/> */}
                                        {progressInfo.percentage < 100 ? (
                                          <div className="imgListOverlay"></div>
                                        ) : null}
                                        <div className="vidico">
                                          <i className="iAudio" />
                                        </div>
                                        <div className="vidDesc">
                                          <h4>{progressInfo.fileName}</h4>
                                          <span>
                                            {(
                                              progressInfo.size /
                                              1024 /
                                              1024
                                            ).toFixed(2)}{" "}
                                            MB
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </>
                        )}
                        {this.state.videoFilePreview && (
                          <>
                            <div className="imagesList">
                              {this.state.progressInfos &&
                                this.state.progressInfos.map(
                                  (progressInfo, index) => (
                                    <div className="imagesListGrid video">
                                      <div className="imgGridinn">
                                        {" "}
                                        <a
                                          href="javascript:void(0)"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.removeVideoFile();
                                          }}
                                          className="closeimg"
                                        >
                                          <i className="iCross" />
                                        </a>

                                        {progressInfo.percentage < 100 ? (
                                          <div className="imgListOverlay"></div>
                                        ) : null}
                                        <div className={"vidico" + ((this.state.customThumbnailFlag || this.state.thumbnailAttachmentUrl) ? " video-thumbnail" : "")}>
                                          {!this.state.thumbnailAttachmentUrl ? <i className="iVid" /> :
                                            <>
                                                <img style={{ transform: `rotate(${this.state.thumbnailAttachmentFile?.rotationDegree}deg)` }} src={getCDNUrl(this.state.customThumbnailFlag ? this.state.thumbnailAttachmentFile?.attachmentUrl : this.state.thumbnailAttachmentUrl)} alt="" onError={this.reloadSrc} />
                                                {/* <img src={getCDNUrl(this.state.thumbnailAttachmentUrl)} onError={this.reloadSrc} /> */}
                                                {this.state.customThumbnailFlag && 
                                                    <>
                                                        <i className="iCross" onClick={(e) => { e.preventDefault(); this.removeThumbnailAttachmentFile(); }}></i>
                                                        <button onClick={(e) => { e.preventDefault(); this.rotateThumbnailImage() }} type="button" className="rotateImg"><i className="irotate" /></button>
                                                    </>
                                                }
                                            </>
                                          }
                                        </div>
                                        <div className="vidDesc">
                                          <h4>{progressInfo.fileName}</h4>
                                          <div className="vidDescBottInfo">
                                            <span>
                                              {(
                                                progressInfo.size /
                                                1024 /
                                                1024
                                              ).toFixed(2)}{" "}
                                              MB
                                            </span>

                                            <div className="thumbBtn">
                                              <input
                                                type="file"
                                                // accept="image/*"
                                                accept=".jpg,.jpeg,.png"
                                                onChange={(e) => this.handleChangeThumbnailUpload(e)}
                                                id="thumbnailImageFileUploader"
                                                ref="thumbnailImageFileUploader"
                                                style={{ display: "none" }} />
                                              {Object.keys(this.state.thumbnailAttachmentFile).length === 0 &&
                                                <button type="button" className="btn" onClick={this.thumbnailUpload}>Change Thumbnail</button>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </>
                        )}
                        {this.state.error &&
                          this.state.error !== "" &&
                          this.state.error !== "File Uploading..." && (
                            <span
                              className={
                                this.state.displayError
                                  ? "login-feedback-error"
                                  : "login-success-error"
                              }
                            >
                              {this.state.error}
                            </span>
                          )}
                        {this.state.taggedLocation && (
                          <div
                            style={{
                              marginBottom: "10px",
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            <span style={{ color: "#97A600" }}>- at </span>
                            <b style={{ fontSize: 12 }}>
                              {this.state.taggedLocation.name}
                            </b>
                          </div>
                        )}
                        {this.state.secondaryAttachments &&
                          this.state.secondaryAttachments.length > 0 && (
                            <div className="forumcommtvlistbott fattachview">
                              <a href="javascript:void(0)" className="fattach">
                                <i className="iLink" />
                                {this.state.secondaryAttachments.length === 1
                                  ? this.state.secondaryAttachments.length +
                                  " File "
                                  : this.state.secondaryAttachments.length +
                                  " Files "}{" "}
                                Attached
                              </a>
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.removeSecondaryAttachments();
                                }}
                              >
                                <i className="fa fa-window-close" />
                              </a>
                            </div>
                          )}
                        <div className="toppostpopbott">
                          <div className="tagfield">
                            <AutoSuggest
                              hashTags={this.state.hashTags}
                              onDelete={this.onDelete}
                              onAddition={this.onAddition}
                              handleTagsChange={this.handleTagsChange}
                            />
                            {/*<TagsInput value={this.state.hashTags}
                                                                       inputProps={{
                                                                           id: 'colon',
                                                                           placeholder: 'Add Hashtags'
                                                                       }}
                                                                       ref={'TagsRef'}
                                                                       addKeys={[9, 13, 32, 62, 188]}
                                                                       renderInput={this.autocompleteRenderInput}
                                                                       onDelete={this.onDelete.bind(this)}
                                                                       onAddition={this.onAddition.bind(this)}
                                                                       onChange={this.handleTagsChange}/>*/}
                            {/* <span className="khastginfo">Press enter after each # tag</span> */}
                          </div>

                          <div className="toppostpopfeatures">
                            <a
                              href="/media-info"
                              title="Media Info"
                              className="btninfo"
                              target="_blank"
                            >
                              <i className="iInfo_I" />
                            </a>
                            {!this.state.linkdata && !this.state.webcamOn && (
                              <a
                                href="javascript:void(0)"
                                title="Add Location"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.openLocationDialogBox();
                                }}
                              >
                                <i className="iLocation" />
                              </a>
                            )}

                            {!this.state.linkdata &&
                              this.state.displayAudioPreview !== true &&
                              this.state.displayVideoPreview !== true &&
                              !this.state.imageUri &&
                              !this.state.audioFile &&
                              !this.state.videoFile &&
                              (!this.state.imageList ||
                                this.state.imageList.length === 0) &&
                              !this.state.videoFilePreview &&
                              !this.state.audioFilePreview &&
                              !this.state.displayPresentationView &&
                              os !== "iOS" &&
                              !isWebView() && (
                                <>
                                  <a
                                    href="javascript:void(0)"
                                    title="Add Photos"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.startStopWebcam();
                                    }}
                                  >
                                    <i className="iTakePhoto" />
                                  </a>
                                </>
                              )}

                            {this.state.displayAudioPreview !== true &&
                              this.state.displayVideoPreview !== true &&
                              !this.state.imageUri &&
                              !this.state.audioFile &&
                              !this.state.videoFile &&
                              !this.state.webcamOn &&
                              !this.state.videoFilePreview &&
                              !this.state.audioFilePreview &&
                              !this.state.displayPresentationView && (
                                <>
                                  <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    onChange={this.uploadImages}
                                    multiple
                                    ref="imagesFileUploader"
                                    style={{ display: "none" }}
                                    id="imageUploader"
                                  />

                                  <a
                                    href="javascript:void(0)"
                                    title="Upload Images"
                                    onClick={this.imagesUpload}
                                  >
                                    <i className="iUploadPhoto" />
                                  </a>
                                </>
                              )}
                            {this.state.displayPreview !== true &&
                              this.state.displayAudioPreview !== true &&
                              this.state.displayVideoPreview !== true &&
                              !this.state.imageUri &&
                              !this.state.audioFile &&
                              !this.state.videoFile &&
                              !this.state.videoFilePreview &&
                              (!this.state.imageList ||
                                this.state.imageList.length === 0) &&
                              !this.state.webcamOn &&
                              !this.state.audioFilePreview &&
                              !this.state.displayPresentationView && (
                                <>
                                  <input
                                    type="file"
                                    accept=".mp3,.ogg,.aac,.wav"
                                    onChange={this.uploadAudioFile}
                                    ref="audioFileUploader"
                                    style={{ display: "none" }}
                                    id="audioUploader"
                                  />

                                  <a
                                    href="javascript:void(0)"
                                    title="Upload audio"
                                    onClick={this.audioUpload}
                                  >
                                    <i className="iAudio" />
                                  </a>

                                  <input
                                    type="file"
                                    accept=".mp4,.webm,.mkv,.mov,.avi,.ogg,.flv,.wmv,.mpeg"
                                    onChange={this.uploadVideoFile}
                                    ref="videoFileUploader"
                                    style={{ display: "none" }}
                                    id="videoUploader"
                                  />

                                  <a
                                    href="javascript:void(0)"
                                    title="Upload Video"
                                    onClick={this.videoUpload}
                                  >
                                    <i className="iAddVideo" />
                                  </a>
                                </>
                              )}

                            {!this.state.displayPresentationView &&
                              this.state.displayPreview !== true &&
                              this.state.displayAudioPreview !== true &&
                              this.state.displayVideoPreview !== true &&
                              !this.state.imageUri &&
                              !this.state.audioFile &&
                              !this.state.videoFile &&
                              !this.state.videoFilePreview &&
                              (!this.state.imageList ||
                                this.state.imageList.length === 0) &&
                              !this.state.webcamOn &&
                              !this.state.audioFilePreview &&
                              <>
                                {/* <>
                                  <input
                                    type="file"
                                    accept=".ppt,.pptx"
                                    id="pptAttachmentsFileUploader"
                                    onChange={(e) => this.uploadSecondaryAttachments(e, "PPT")}
                                    ref="pptAttachmentsUploader"
                                    style={{ display: "none" }}
                                  />
                                  <a href="javascript:void(0)" title="Upload Ppt" onClick={this.pptAttachmentsUpload} className="attchbtn">
                                    <i className="iPPT_New" />
                                  </a>
                                </>
                                <>
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    id="pdfAttachmentsFileUploader"
                                    onChange={(e) => this.uploadSecondaryAttachments(e, "PDF")}
                                    ref="pdfAttachmentsUploader"
                                    style={{ display: "none" }}
                                  />
                                  <a href="javascript:void(0)" title="Upload Pdf" onClick={this.pdfAttachmentsUpload} className="attchbtn">
                                    <i className="iPDF" />
                                  </a>
                                </> */}
                                <input
                                  type="file"
                                  //multiple
                                  accept=".ppt, .pptx, .pdf"
                                  id="pptPdfAttachmentsFileUploader"
                                  onChange={(e) => this.uploadSecondaryAttachments(e, "ATTACHMENT")}
                                  ref="pptPdfAttachmentsUploader"
                                  style={{ display: "none" }}
                                />

                                <a href="javascript:void(0)" title="Upload Ppt, Pdf" onClick={this.pptPdfAttachmentsUpload} className="attchbtn">
                                  <i className="fa fa-file-text-o" />
                                </a>
                                <input
                                  type="file"
                                  multiple
                                  accept=".csv,.xlsx,.xls,.doc,.docx,.txt"
                                  id="secondaryAttachmentsFileUploader"
                                  onChange={this.uploadSecondaryAttachments}
                                  ref="secondaryAttachmentsUploader"
                                  style={{ display: "none" }}
                                />

                                <a href="javascript:void(0)" title="Upload Document" onClick={this.secondaryAttachmentsUpload} className="attchbtn">
                                  <i className="iClip" />
                                </a>
                              </>
                            }

                            <a
                              href="javascript:void(0)"
                              className="attchbtn"
                              onClick={this.handleSmiliDisplay}
                            >
                              <i className="iEmoji" />
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="bottpostpopbody">
                        {this.state.userData &&
                          this.state.userData.type !== "COMPANY" &&
                          this.state.hiddenPrivacy === false && (
                            <div className="contxmenu">
                              <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="iPrivacyLock" />
                                {this.state.postPrivacy === "ANYONE"
                                  ? "Anyone"
                                  : this.state.postPrivacy === "CONNECTED"
                                    ? "Connected Only"
                                    : "Selected Only"}
                              </button>
                              <div
                                className="dropdown-menu fadeIn animated"
                                onChange={this.changePostPrivacy}
                              >
                                <label className="radio-container">
                                  Anyone
                                  <input
                                    type="radio"
                                    value="ANYONE"
                                    onClick={this.handleCheckedPrivacy}
                                    checked={
                                      this.state.postPrivacy === "ANYONE"
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                                <label className="radio-container">
                                  Connected Only
                                  <input
                                    type="radio"
                                    value="CONNECTED"
                                    onClick={this.handleCheckedPrivacy}
                                    checked={
                                      this.state.postPrivacy === "CONNECTED"
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                                <label className="radio-container">
                                  Selected Only
                                  <input
                                    type="radio"
                                    value="SELECTED"
                                    onClick={this.handleSelectedOnly}
                                    checked={
                                      this.state.postPrivacy === "SELECTED"
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                              </div>
                            </div>
                          )}

                        {this.state.displayPreview === true &&
                          this.state.progressInfos &&
                          this.state.progressInfos.length > 0 ? (
                          <div className="previewimagebtn">
                            <a href="#postImagePreview" data-toggle="modal">
                              <i className="iPhotos"></i> Preview
                            </a>
                          </div>
                        ) : null}

                        {this.state.displayVideoPreview &&
                          this.state.error &&
                          this.state.error === "File Uploading..." ? (
                          <div className="previewimagebtn">
                            <a href="#postVideoPreview" data-toggle="modal">
                              <i className="iPhotos"></i> Preview
                            </a>
                          </div>
                        ) : null}

                        {this.state.displayAudioPreview &&
                          this.state.error &&
                          this.state.error === "File Uploading..." ? (
                          <div className="previewimagebtn">
                            <a href="#postAudioPreview" data-toggle="modal">
                              <i className="iPhotos"></i> Preview
                            </a>
                          </div>
                        ) : null}

                        <div className="bottpostpopright">
                          {this.state.errors && this.state.errors.privacyText && (
                            <span
                              className="login-feedback-error mt-1"
                              style={{
                                display: "block",
                                width: "auto",
                              }}
                            >
                              {this.state.errors.privacyText}
                            </span>
                          )}
                          {this.state.errors &&
                            this.state.errors.connectionText && (
                              <span
                                className="login-feedback-error  post-error-tooltip"
                                style={{
                                  display: "block",
                                  width: "auto",
                                }}
                              >
                                {this.state.errors.connectionText}
                              </span>
                            )}
                          {Stripbody > 400 ? (
                            <></>
                          ) : (
                            <div className="charechterinfo">
                              {400 - Stripbody}{" "}
                              {400 - Stripbody <= 1 ? "word " : "words "}
                              left
                            </div>
                          )}

                          {(this.state.postText &&
                            this.state.postText !== "") ||
                            (this.state.imageList &&
                              this.state.imageList.length > 0) ||
                            (this.state.videoFile &&
                              this.state.videoFile.length > 0) ||
                            (this.state.hashTags &&
                              this.state.hashTags.length > 0) ||
                            (this.state.audioFile &&
                              this.state.audioFile.length > 0) ||
                            (this.state.secondaryAttachments &&
                              this.state.secondaryAttachments.length > 0) ? (
                            <a
                              href="javascript:void(0)"
                              className="btn cancel"
                              onClick={this.confirmModal}
                            >
                              Cancel
                            </a>
                          ) : (
                            <a
                              href="javascript:void(0)"
                              className="btn cancel"
                              onClick={() => this.hideModal(true)}
                            >
                              Cancel
                            </a>
                          )}
                          <input
                            type="submit"
                            onClick={this.handlePostSubmit}
                            className="btn"
                            value={
                              (this.state.error &&
                                this.state.error === "File Uploading...") ||
                                this.state.isPostSubmitted
                                ? "Loading..."
                                : "POST"
                            }
                            disabled={
                              (this.state.error &&
                                this.state.error === "File Uploading...") ||
                              this.state.isPostSubmitted
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.postAsFlag && <CommonPostAsPopUp onCloseEditPostAs={this.onCloseEditPostAs} onSelectPostAsData={this.onSelectPostAsData} entityData={this.state.entityData} secondaryEntityName={this.state.secondaryEntityName} entityName={this.state.entityName} userId={this.state.userId} entityType={this.state.entityType} />}

        {/* Image Preview Modal */}
        <div
          id="postImagePreview"
          className="modal fade dgpopup"
          tabindex="-1"
          data-backdrop="static"
          data-keyboard="false"
          style={{
            background: "rgba(0,57,77,.7)",
            backdropFilter: "blur(5px)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="postImagePreviewWrap">
                  <h4>Upload Images</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div className="imagesList">
                    {this.state.progressInfos &&
                      this.state.progressInfos.map((progressInfo, index) => (

                        <div className="imagesListGrid">
                          <div className="imgGridinn">
                            {" "}
                            {/* <img src={progressInfo.url} alt="" /> */}
                            <div className="imgGridPrevPanel">
                              {/* <button onClick={(e) => { e.preventDefault(); this.onclickRotateLeft(progressInfo,index)}} type="button" className="rotateImg left"><i className="iArrow-BottomLeft" /></button> */}
                              <img style={{ transform: `rotate(${progressInfo.rotationDegree}deg)` }} src={progressInfo.url} alt="" />
                              <button onClick={(e) => { e.preventDefault(); this.rotateImage(index) }} type="button" className="rotateImg"><i className="irotate" /></button>
                            </div>
                            <a
                              href="javascript:void(0)"
                              className="closeimg"
                              onClick={(e) => {
                                e.preventDefault();
                                this.removeLocalImage(progressInfo, index);
                              }}
                            >
                              <i className="iCross" />
                            </a>
                            {this.state.displayError !== true &&
                              progressInfo.percentage < 100 ? (
                              <div className="imgListOverlay"></div>
                            ) : null}
                            <div className="progress active">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow={progressInfo.percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progressInfo.percentage + "%" }}
                              >
                                {/* <span className="progress-type">{progressInfo.fileName}%</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Image Preview Modal */}

        {/* Video Preview Modal */}
        <div
          id="postVideoPreview"
          className="modal fade dgpopup"
          tabindex="-1"
          data-backdrop="false"
          style={{
            background: "rgba(0,57,77,.7)",
            backdropFilter: "blur(5px)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="postImagePreviewWrap">
                  <h4>Upload Video</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div className="imagesList">
                    {this.state.progressInfos &&
                      this.state.progressInfos.map((progressInfo, index) => (
                        <div className="imagesListGrid video">
                          <div className="imgGridinn">
                            {" "}
                            {this.state.displayError !== true &&
                              progressInfo.percentage < 100 ? (
                              <div className="imgListOverlay"></div>
                            ) : null}
                            <div className="progress active">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow={progressInfo.percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progressInfo.percentage + "%" }}
                              ></div>
                            </div>
                            <div className="vidico">
                              <i className="iVid" />
                            </div>
                            <div className="vidDesc">
                              <h4>{progressInfo.fileName}</h4>
                              <span>
                                {(progressInfo.size / 1024 / 1024).toFixed(2)}{" "}
                                MB
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Video Preview Modal */}

        {/* Audio Preview Modal */}
        <div
          id="postAudioPreview"
          className="modal fade dgpopup"
          tabindex="-1"
          data-backdrop="false"
          style={{
            background: "rgba(0,57,77,.7)",
            backdropFilter: "blur(5px)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="postImagePreviewWrap">
                  <h4>Upload Audio</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div className="imagesList">
                    {this.state.progressInfos &&
                      this.state.progressInfos.map((progressInfo, index) => (
                        <div className="imagesListGrid video">
                          <div className="imgGridinn">
                            {" "}
                            {progressInfo.percentage < 100 ? (
                              <div className="imgListOverlay"></div>
                            ) : null}
                            <div className="progress active">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow={progressInfo.percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progressInfo.percentage + "%" }}
                              ></div>
                            </div>
                            <div className="vidico">
                              <i className="iAudio" />
                            </div>
                            <div className="vidDesc">
                              <h4>{progressInfo.fileName}</h4>
                              <span>
                                {(progressInfo.size / 1024 / 1024).toFixed(2)}{" "}
                                MB
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Audio Preview Modal */}

        {/*Confirmation Modal Start*/}
        <div
          className="modal fade dgpopup confirmpop"
          id={"cancelPopup"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Cancel Confirmation</h5>                                
                            </div> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={this.ignoreModal}>
                  &times;
                </span>
              </button>
              <div className="confirm-popup-modal-body">
                Are you sure you want to cancel this post?
              </div>
              <div className="modal-footer confirm-popup-modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.hideModal}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.ignoreModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Confirmation Modal End*/}

        <div
          id="postSelectUserModal"
          className="chatlistpop modal fade dgpopup"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                {this.state.isModalLoading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader
                      type="Grid"
                      color="#dcde63"
                      height={60}
                      width={60}
                      timeout={8000}
                    />
                  </div>
                ) : (
                  <div className="forwcontwrap">
                    <div className="forwcontwraptop">
                      <h4>Select Users</h4>
                      <form action="" onSubmit="return false;">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type Connect Name"
                          id="searchGroupByChars"
                          onKeyUp={(evt) =>
                            this.keyPressedBySearchGroupByChars(evt)
                          }
                        />
                        <button
                          className="btn btnsearch"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      </form>

                      {this.state.selectedList &&
                        this.state.selectedList.length > 0 ? (
                        <HorizontalScroll reverseScroll>
                          <div className="srchlist">
                            {this.state.selectedList &&
                              this.state.selectedList.map((data, index) => {
                                return (
                                  <div key={index} className="srchlistblk">
                                    <figure className="img">
                                      <img
                                        src={
                                          data.personalInfo.profileImage != null
                                            ? getCDNUrl(
                                              this.getOptimizedImage(
                                                data.personalInfo
                                              )
                                            )
                                            : "https://cdn.dscovr.com/images/users1.png"
                                        }
                                        alt=""
                                      />
                                    </figure>
                                    <span>
                                      {data.firstName} {data.lastName}
                                      {data.companyName}
                                    </span>
                                    {index !== this.state.userData.userId && (
                                      <i
                                        className="iCross"
                                        onClick={(e) =>
                                          this.removeUserToGroup(e, data)
                                        }
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </HorizontalScroll>
                      ) : null}
                      <Scrollbars
                        className={"forwcontlistmain contactlist"}
                        autoHide
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={322}
                        universal={true}
                        renderTrackHorizontal={(props) => (
                          <div {...props} className="track-horizontal" />
                        )}
                        renderTrackVertical={(props) => (
                          <div {...props} className="track-vertical" />
                        )}
                      >
                        <ul>
                          {this.state.userList &&
                            this.state.userList
                              .filter(
                                (data) =>
                                  data.id !== this.state.userData.userId &&
                                  (this.state.searchUserByChars === "" ||
                                    (this.state.searchUserByChars &&
                                      data.firstName &&
                                      data.firstName
                                        .toLowerCase()
                                        .includes(
                                          this.state.searchUserByChars.toLowerCase()
                                        )) ||
                                    (this.state.searchUserByChars &&
                                      data.lastName &&
                                      data.lastName
                                        .toLowerCase()
                                        .includes(
                                          this.state.searchUserByChars.toLowerCase()
                                        )))
                              )
                              .map((data, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        this.addUserToGroup(e, data)
                                      }
                                    >
                                      <figure className="users">
                                        <img
                                          src={
                                            data.personalInfo.profileImage !=
                                              null
                                              ? getCDNUrl(
                                                this.getOptimizedImage(
                                                  data.personalInfo
                                                )
                                              )
                                              : "https://cdn.dscovr.com/images/users1.png"
                                          }
                                          alt=""
                                        />
                                      </figure>
                                      <div>
                                        <div className="name">
                                          {data.firstName} {data.lastName}
                                          {data.companyName}{" "}
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                        </ul>
                      </Scrollbars>
                    </div>

                    <div className="forwcontwrapbott">
                      <div className="itemscount">
                        {this.state.selectedList.length} Selected
                      </div>
                      <div>
                        <button
                          className="butt btnsecondary close"
                          data-dismiss="modal"
                          id="forwardMessageModalCloseBtn"
                          onClick={this.clearUsers.bind(this)}
                        >
                          Close
                        </button>
                        <button
                          disabled={this.state.selectedList.length === 0}
                          className="btn"
                          onClick={this.handleUsersSelectSubmit}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestPostCreationFulfilled: (value) =>
      dispatch(requestPostCreationFulfilled(value)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(PostPopup));
