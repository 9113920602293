import React, {Component} from 'react';
import axios from "axios";
import {authService} from "../../../auth";
import * as _ from "lodash";
import {convertUnicode} from "../../../commons/CommonFunctions";
import dateUtil from "date-and-time";
import {Redirect, withRouter} from "react-router-dom";


class RunningProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData:authService.getUserDetailsFromLocalStorage(),
            projectData:[],
            isView:false,
            feedPageShow:true,
            projectId:'',
            userId: authService.getUserDetailsFromLocalStorage()?.userId
        }
    }

    componentDidMount() {
        if(this.props.getRunningProject){
            this.props.getRunningProject();
        }
    }

    setProjectDetails = (item,projectDetails) => {
        this.setState({
            projectId:  item.projectId,
            feedPageShow:item.feedPageShow,
            slug:projectDetails.slug,
            isView:true,
        })
        this.props.setProjectDetails(projectDetails);
    };


    handleUnHideModal = (id) => {
        let data = {
            userId: this.state.userId,
            activityId: id
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/unhide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                if(this.props.getRunningProject){
                    this.props.getRunningProject();
                }
            } else {
                this.setState({'isSubmitted': false});
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({'error': 'Entity has already been unhidden', 'isSubmitted': false});
            } else {
                this.setState({'isSubmitted': false});
            }
        });
    }

    eventsAndTrainingBlock = (item) => {
        let startDate = new Date(item && item.project && item.project.beginningTime);
        let startDatenew= dateUtil.format(startDate, "DD MMM YYYY");
        let startDateDay= dateUtil.format(new Date(startDatenew), "DD");
        let startDateMonth= dateUtil.format(new Date(startDate), "MMM");
        let startDateYear= dateUtil.format(new Date(startDate), "YYYY");
        return (

            <div key={item.projectId + "opportunity"} className="col-md-4">
                <div className="projectHiddenCol">
                    <figure className="bannimg" style={item.feedPageShow ? { cursor: 'pointer' }:{ cursor: 'default' }} onClick={(e) => this.setProjectDetails(item,item.project)}>
                        <img
                            src={item && item.project && !_.isNull(item.project.coverImage) && !_.isUndefined(item.project.coverImage) ? item.project.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/project-default.jpg"}}
                            alt="" />
                        {/*{fees > 0 && isDisclosed === false &&
                        <span
                            className="price"> {currency} {fees.toLocaleString("en")} {" "}{!_.isNull(packageType) && !_.isUndefined(packageType) && packageType.toLowerCase() !== 'custom' && packageType}</span>
                        }*/}
                    </figure>
                    <div className="projeventdesc">
                        <span onClick={(e) => this.setProjectDetails(item,item.project)}>{item.secondaryEntityType}</span>
                        <h4 onClick={(e) => this.setProjectDetails(item,item.project)}><a
                            style={{ cursor: 'pointer' }}>

                            {item && item.project && item.project.title && item.project.title.split("\\n").map(title => (<>{convertUnicode(title)}<br /></>))}
                            {/*{title}*/}
                        </a></h4>
                    </div>
                    <a className="unhidebtn" href="javascript:void(0)"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleUnHideModal(item.projectId)
                        }}><i class="iEye_OL" /></a>
                </div>
            </div>
        );
    };

     completedBlock = (item) => {
         let createDateTime = new Date(item.createTime);
         let lastTimeOfApplication = item.project.lastTimeOfApplication > 0 && item.project.lastTimeOfApplication
             ? new Date(item.project.lastTimeOfApplication)
             : null;
         let endingDate =
             item.project.endingTime > 0 && item.project.endingTime
                 ? new Date(item.project.endingTime)
                 : null;
         let endDate  =
             endingDate
                 ? dateUtil.format(endingDate, "DD MMM YYYY")
                 : lastTimeOfApplication ?dateUtil.format(lastTimeOfApplication, "DD MMM YYYY"):null ;
         let createdOn = createDateTime > 0
             ? dateUtil.format(createDateTime, "DD MMM YYYY")
             : null;

        return (
            <div className="col-md-4">
                <div className="projectHiddenCol">
                    <figure className="bannimg" style={item.feedPageShow ? { cursor: 'pointer' }:{ cursor: 'default' }} onClick={(e) => this.setProjectDetails(item,item.project)}>
                        <img
                            src={item && item.project && !_.isNull(item.project.coverImage) && !_.isUndefined(item.project.coverImage) ? item.project.coverImage: 'https://cdn.dscovr.com/images/project-default.webp'} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/project-default.jpg"}}
                            alt="" />
                    </figure>
                    <div className="projeventdesc">
                        <span style={item.feedPageShow ? { cursor: 'pointer' }:{ cursor: 'default' }} onClick={(e) => this.setProjectDetails(item,item.project)}>{item.secondaryEntityType}</span>
                        <h4  style={item.feedPageShow ? { cursor: 'pointer' }:{ cursor: 'default' }} onClick={(e) => this.setProjectDetails(item,item.project)}>{convertUnicode(item && item.project.title)}</h4>
                    </div>
                    <a className="unhidebtn" href="javascript:void(0)"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleUnHideModal(item.projectId)
                        }}><i class="iEye_OL" /></a>
                </div>
            </div>

        );
    };

    render() {
        if (this.state.isView && this.state.feedPageShow) {
            if(this.state.slug){
                return <Redirect to={{pathname: '/project/feeds/' + this.state.slug, state: {userId: this.state.userId}}} push />
            }else{
                return <Redirect to={{pathname: '/project/feeds/' + this.state.projectId, state: {userId: this.state.userId}}} push />
            }
        }
        return (
            <div className="projgeneralsec projupcomingwrap">
                <h3>
                    {this.props.projectDataRunning && this.props.projectDataRunning.length < 2 ? "Ongoing Project" : "Ongoing Projects"} {" "}
                </h3>
                <div className="row">
                    {this.props.projectDataRunning && this.props.projectDataRunning.length === 0 && 
                    <div className="zdata col-12" style={{marginTop: '-10px', textAlign: 'left'}}>
                        <div className="desc">
                            <p style={{margin: '0px'}}>You have no hidden ongoing projects</p>
                        </div>
                    </div>}
                    {this.props.projectDataRunning && this.props.projectDataRunning.map((item)=>{
                        return (item && (item.secondaryEntityType === 'EVENT' || item.secondaryEntityType === 'TRAINING' || item.secondaryEntityType === 'EXPEDITION'
                            || item.secondaryEntityType === 'STORYBOOK' || item.secondaryEntityType === 'FUNDRAISE')) ?
                            this.eventsAndTrainingBlock(item)
                            : this.completedBlock(item)
                    })}
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(RunningProject);