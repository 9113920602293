import React from "react";
import {Mention, MentionsInput} from 'react-mentions';
import '../commons/styles/react-mentions.css'
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from "axios";
import httpService from "../AxiosInterceptors";
import {authService} from "../auth";
import {getCDNUrl} from "./CommonFunctions";
import uuid from "react-uuid";

httpService.setupInterceptors();

class MentionTextArea extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            text: props.value ? props.value : '',
            userTagHide:props.userTagHide,
            userData: authService.getUserDetailsFromLocalStorage(),
            selectedTags: [],
            selectedUser: []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.state.text) {
            this.setState({text: nextProps.value});
        }
    }

    handleTextChange = (event) => {
        let selectedTags = this.hashTagsAndUserList(event.target.value, "HASHTAG");
        let selectedUser = this.hashTagsAndUserList(event.target.value, "USER_LIST");
        // let selectedTags = event.target.value.match(/(?<=###__).+?(?=\&\&)/g);
        //let selectedUser = event.target.value.match(/(?<=@@@__).+?(?=\^\^)/g);
        this.setState({text: event.target.value, selectedTags: selectedTags, selectedUser: selectedUser}, () => {
            if (this.props.onChange) {
                this.props.onChange(event)
            }
        })
    }

    hashTagsAndUserList = (str, type) => {
        var regex = type === "HASHTAG" ? /###__(.*?)&&/g : /@@@__(.*?)__/g;
        let data = str.match(regex);
        let list = data && data.map(s => s.slice(5).slice(0, type === "HASHTAG" ? -2 : -4));
        return list;
    }

    handleKeyPress = (event) => {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event)
        }
    }

    componentWillUnmount() {
        this.setState({text: ''})
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    fetchUsers = (query, callback) => {
        if (!query) return
        if(this.props.textAreaType !== "circleEdit") {
            axios({
                method: 'get',
                url: process.env.REACT_APP_userServiceURL + '/search/user/suggestions/' + this.state.userData.userId + '?query=' + query + "&privateEntityId=" + this.state.userData?.entityId,
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response && response.data && response.data.message === 'Success!') {
                    let usersData = [];
                    if(this.state.selectedUser) {
                        usersData = (response.data.body).filter((user) => !(this.state.selectedUser).includes(user.customUrl));
                    } else {
                        usersData = response.data.body;
                    }
                    return usersData && usersData.map((user) => ({
                        id: user.customUrl,
                        display: user.username,
                        secondaryHeader: (user.addressDetail && user.addressDetail.country ? user.addressDetail.country + ((user.personalInfo && user.personalInfo.persona) ? (", " + user.personalInfo.persona) : '') : ((user.personalInfo && user.personalInfo.persona) ? user.personalInfo.persona : '')),
                        avatar: (user.personalInfo && user.personalInfo.profileImage ?
                            getCDNUrl(this.getOptimizedImage(user.personalInfo)) : (user.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")),
                        type : user.type    
                    }));
                }
            }).catch((err) => {
                console.log(err);
            }).then(callback);
        }
    }

    fetchTags = (query, callback) => {
        if (!query) return
        let postBody = {
            "description": query,
            "privateEntityId": authService.getUserDetailsFromLocalStorage()?.entityId
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/tags/recommended',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                let hashTagsData = [];
                if(this.state.selectedTags) {
                    hashTagsData = (response.data.body).filter((tag) => !(this.state.selectedTags).includes(tag));
                } else {
                    hashTagsData = response.data.body;
                }
                return hashTagsData && hashTagsData.map((tag) => ({
                    id: tag,
                    display: tag
                }));
            }
        }).catch((err) => {
            console.log(err);
        }).then(callback);
    }

    render() {
        return (
            <MentionsInput
                className={this.props.className ? this.props.className + " comments-textarea" : "comments-textarea"}
                onClick={this.props.onClick}
                value={this.state.text}
                spellCheck="false"
                onChange={this.handleTextChange}
                onKeyPress={this.handleKeyPress}
                allowSpaceInQuery={true}
                allowSuggestionsAboveCursor={true}
                placeholder={this.props.placeholder ? this.props.placeholder : "Write here."}>


                <Mention
                    trigger="@"
                    data={this.fetchUsers}
                    //data={this.props.suggestionsData ? this.props.suggestionsData : mentions}
                    markup='@@@____id__^^____display__@@@^^^'
                    appendSpaceOnAdd={true}
                    renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay,
                        index,
                        focused,
                    ) => {
                        return (
                            <div className="mentionSuggestionsEntryContainer">
                                <div
                                    className="mentionSuggestionsEntryContainerLeft">
                                    <LazyLoadImage className="mentionSuggestionsEntryAvatar"
                                                   src={suggestion.avatar}
                                                   role="presentation"/>
                                </div>

                                <div
                                    className="mentionSuggestionsEntryContainerRight">
                                    <div
                                        className= {"mentionSuggestionsEntryText" + (suggestion.type === "COMPANY" ? " orgnzName" : "")}
                                    >
                                        {suggestion.display}
                                    </div>

                                    <div
                                        className="mentionSuggestionsEntryTitle">
                                        {suggestion.secondaryHeader}
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                    style={{
                        backgroundColor: '#daf4fa'
                    }}
                />



                <Mention
                    trigger="#"
                    data={this.fetchTags}
                    //data={this.props.suggestionsData ? this.props.suggestionsData : mentions}
                    markup='###____id__&&____display__###^^^'
                    appendSpaceOnAdd={true}
                    renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay,
                        index,
                        focused,
                    ) => {
                        return (
                            <span>{'# '+suggestion.display}</span>
                        )
                    }}
                    style={{
                        backgroundColor: '#daf4fa'
                    }}
                />
            </MentionsInput>
        );
    }
}

export default MentionTextArea
