import React, { Component } from "react";
import { authService } from "../auth";
import axios from "axios";

class PinPostButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            pinnedAccess: this.props.item.pinnedAccess,
            pinned: this.props.item.pinned
        }
    }

    onClickPinPost = () => {
        let data = {
            userId: this.state.userData.userId,
            postId: this.props.item.id,
            pinned: !this.state.pinned,
            privateEntityId : this.state.userData.entityId
        };
        let postItem = this.props.item;
        postItem.pinned = !this.state.pinned;
        axios({
            method: "post",
            url: `${process.env.REACT_APP_userServiceURL}/backend/pinned/post`,
            data: data,
            withCredentials: true,
        }).then((response) => {
            if(response && response.data && response.data.message === "Success!") {
                this.props.onSuccessPinPostRequest(postItem);
                this.setState({
                    pinned: !this.state.pinned
                })
                window.scrollTo(0, 0);
            }
        }).catch((err) => {
            console.log('err', err)
        });
    }

    render() {
        const { pinnedAccess, pinned } = this.state;
        return (
            <>
                {pinnedAccess &&
                    <a className="dropdown-item" href="javascript:void(0)" onClick={this.onClickPinPost} >
                        <i className={!pinned ? "iPin" : "ipin-icon_RM"} style={{ fontSize: '14px' }} /> {!pinned ? "Pin" : "UnPin"}
                    </a>
                }
            </>
        )
    }
}

export default PinPostButton;
