import React, {Component} from 'react';
import axios from "axios";
import NotFound from "./404";
import Header from "./Header";
import FooterDiv from "./FooterDiv";
import BypassHeader from './BypassHeader';
import {authService} from './auth';

class unsubscribe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SuccessMessage: '',
            ErrorMessage: '',
            loginSuccess: authService.isAuthenticated(),
        };
    }

    componentDidMount() {
        this.unsubscribeUser()
    }

    unsubscribeUser = () => {
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id')
        const groupId = query.get('groupId')
        if (id && groupId) {
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/backend/unauth/email/unsubscribe',
                data: {id: id, groupId: groupId},
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response && response.status === 202) {
                    this.setState({
                        SuccessMessage: 'You have been successfully unsubscribed from the mailing list'
                    })
                } else {
                    console.log(response);
                }
            }).catch((err) => {
                if (err.response && err.response.data) {
                    this.setState({
                        ErrorMessage: err.response.data && err.response.data.message
                    })
                }
                console.log(err);
            });
        }
    };

    render() {
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id')
        const groupId = query.get('groupId')
        if (id && groupId) {
            return (
                <div className="wrapper nologinpage">
                    {!this.state.loginSuccess ? <BypassHeader display={true}/> : <Header EditedText={true}/>}
                    <main className="dgcontainer hm3col">
                        <div className="container">
                            <div className="msgbody">
                                <h2>{this.state.SuccessMessage !== '' ? this.state.SuccessMessage : null}</h2>
                                <h2>{this.state.ErrorMessage !== '' ? this.state.ErrorMessage : null}</h2>
                            </div>
                        </div>
                    </main>
                </div>
            );
        } else {
            return <div className="wrapper nologinpage">
                {!this.state.loginSuccess ? <BypassHeader display={true}/> : <Header EditedText={true}/>}
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <NotFound/>
                    </div>
                </main>
                {!this.state.loginSuccess ? <FooterDiv/> : ''}
            </div>
        }

    }
}

export default unsubscribe;
