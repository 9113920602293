import React, { Component } from "react";
import { getCDNUrl, getOptimizedArticleImage } from "../commons/CommonFunctions";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import AuthModalComponent from "../LoginExtra/AuthModalComponent";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

class UnAuthArticlePostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginRequired: false,
    };
  }
  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.userProfileImage;
  }

  redirectToArticleDetail(url) {
    let userAgent = navigator.userAgent;
    let mobileandroid = /wenat_android/.test(userAgent);
    let mobileios = /wenat_ios/.test(userAgent);

    if (url !== undefined && url !== null) {
      if (window.ReactNativeWebView) {
        window.location.href = url;
      } else {
        window.open(
          url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000"
        );
      }
    }
  }

  handleLoginClose = () => {
    this.setState({ loginRequired: false });
    window.$("#loginModal").modal("hide");
  };

  render() {
    return (
      <div className="hpostblock article"> {/*pNominate*/}
        {!this.props.item.userEntityType ? (
          <figure
            className="userimg"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={
                this.props.item.userProfileImage
                  ? getCDNUrl(this.getOptimizedImage(this.props.item))
                  : this.props.item.userType !== "COMPANY"
                  ? "https://cdn.dscovr.com/images/users1.png"
                  : "https://cdn.dscovr.com/images/DefaultBusiness.webp"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://cdn.dscovr.com/images/DefaultBusiness.png";
              }}
              alt=""
            />
          </figure>
        ) : (
          <figure
            className="userimg"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={
                this.props.item &&
                this.props.item.params &&
                this.props.item.params["circleImage"] != null
                  ? getCDNUrl(
                      this.props.item.params &&
                        this.props.item.params["circleImage"]
                    )
                  : "https://cdn.dscovr.com/images/circleDefault.webp"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://cdn.dscovr.com/images/circleDefault.png";
              }}
              alt=""
            />
          </figure>
        )}
        <div className="hpostblockinn">            
          <div className="hposthead">
            {!this.props.item.userEntityType ? (
              <div className="d-flex">
                <h3>{this.props.item.userName}</h3>
              </div>
            ) : (
              <div className="d-flex">
                <h3
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {this.props.item.params["circleTitle"]}
                </h3>
              </div>
            )}
            {this.props.item && this.props.item.persona &&
                <div className="hpostPersona">{this.props.item.persona}</div>}
            <div className="hpostdtls">
              {this.props.item && this.props.item.country &&
              <span className="address">
                <i className="iLocation" />
                {this.props.item && this.props.item.country}
              </span>}
              <span>
                {timeAgo.format(this.props.item.createTime, "mini-now")}
              </span>
            </div>
          </div>
          <div className="hpostcontent">
            <div className="hpostconttop">
              <div className="postarticleblock">
                <div className="postarticleimg">
                  {this.props.item.attachmentIds &&
                  this.props.item.attachmentIds[0] &&
                  this.props.item.attachmentIds[0].attachmentUrl ? (
                    <img
                      src={getCDNUrl(
                        getOptimizedArticleImage(
                          this.props.item.attachmentIds[0]
                        )
                      )}
                      alt=""
                    />
                  ) : (
                    <img
                      src="https://cdn.dscovr.com/images/banner-explore-blog-small.webp"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png";
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div className="postarticledesc">
                  <div className="paauthor">{this.props.item.userName}</div>
                  <h2 className="articlePostTitle">{this.props.item.title}</h2>
                  <div
                    className="padesctxt"
                    dangerouslySetInnerHTML={{
                      __html: this.props.item.description
                        ? this.props.item.description
                            .replace(/&nbsp;/g, " ")
                            .replace(/(\r\n|\r|\n)/g, "<br>")
                        : "",
                    }}
                  />
                  <div className="text-center">
                    <a
                      href="javascript:void(0)"
                      className="more btn"
                      onClick={(e) => {
                        e.preventDefault();
                        this.redirectToArticleDetail(
                          "/explore-detail/article/" + this.props.item.slug
                        );
                      }}
                    >
                      read more
                    </a>
                  </div>
                </div>
              </div>

              {this.props.item.hashTags && this.props.item.hashTags.length > 0 && (
                <div className="hposthash" style={{padding: '0 15px'}}>
                  {this.props.item.hashTags.map((item) => {
                    return (
                      <div key={item} className="hposthashitem">
                        <a href={"/profile-hashtag/" + item + "/" + this.props.item?.userCustomUrl}>
                          <i className="iHashtag" />
                          {item}
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
              {this.props.item.postLocation && (
                <span className="address">
                  <i className="iLocation" />
                  {this.props.item.postLocation}
                </span>
              )}
            </div>
          </div>
        </div>
        {this.state.loginRequired === true ? (
          <AuthModalComponent handleLoginClose={this.handleLoginClose} />
        ) : null}
      </div>
    );
  }
}

export default UnAuthArticlePostItem;
