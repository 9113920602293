import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import Modal from './Modal'
import PropTypes from 'prop-types';
import LazyBackground from "./LazyBackground";

class Images extends Component {
    static defaultProps = {
        images: [],
        hideOverlay: false,
        renderOverlay: () => 'Preview Image',
        overlayBackgroundColor: 'rgba(0,45,61,.8)',
        onClickEach: null,
        countFrom: 5
    }

    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            countFrom: props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
            conditionalRender: false
        };

        this.openModal = this.openModal.bind(this);
        this.onClose = this.onClose.bind(this);

        if (props.countFrom <= 0 || props.countFrom > 5) {
            console.warn('countFrom is limited to 5!')
        }
    }

    openModal(index) {
        const {onClickEach, images} = this.props;

        if (onClickEach) {
            return onClickEach({src: images[index], index})
        }

        this.setState({modal: true, url: images[index], index})
    }

    onClose() {
        this.setState({modal: false})
    }

    renderOne() {
        const {images} = this.props;
        const {countFrom} = this.state;
        const overlay = images.length > countFrom && countFrom === 1 ? this.renderCountOverlay(true) : this.renderOverlay();

        return <Container>
            <Row>
                {/* <LazyBackground xs={12} md={12} className={`height-one background`}
                                onClick={this.openModal.bind(this, 0)}
                                src={`${images[0]}`}>
                    {overlay}
                </LazyBackground> */}
                <div className={"background col-xs-12 col-md-12"} style={{cursor: 'pointer', padding: '0'}} onClick={this.openModal.bind(this, 0)}>
                    <img src={`${images[0]}`} alt='' />
                    {overlay}
                </div>
            </Row>
        </Container>;
    }

    renderTwo() {
        const {images} = this.props;
        const {countFrom} = this.state;
        const overlay = images.length > countFrom && [2, 3].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay();
        const conditionalRender = [3, 4].includes(images.length) || images.length > +countFrom && [3, 4].includes(+countFrom);

        return <Container>
            <Row>
                <LazyBackground xs={6} md={6} className="height-two background"
                                onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
                                src={`${conditionalRender ? images[1] : images[0]}`}>
                    {this.renderOverlay()}
                </LazyBackground>
                <LazyBackground xs={6} md={6} className="height-two background"
                                onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
                                src={`${conditionalRender ? images[2] : images[1]}`}>
                    {overlay}
                </LazyBackground>
            </Row>
        </Container>;
    }

    renderThree() {
        const {images} = this.props;
        const {countFrom} = this.state;
        const conditionalRender = images.length === 4 || images.length > +countFrom && +countFrom === 4;
        const overlay = !countFrom || countFrom > 5 || images.length > countFrom && [4, 5].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay(conditionalRender ? 3 : 4);

        return <Container>
            <Row>
                <LazyBackground xs={4} md={4} className="height-three background"
                                onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
                                src={`${conditionalRender ? images[1] : images[2]}`}>
                    {this.renderOverlay(conditionalRender ? 1 : 2)}
                </LazyBackground>
                <LazyBackground xs={4} md={4} className="height-three background"
                                onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
                                src={`${conditionalRender ? images[2] : images[3]}`}>
                    {this.renderOverlay(conditionalRender ? 2 : 3)}
                </LazyBackground>
                <LazyBackground xs={4} md={4} className="height-three background"
                                onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
                                src={`${conditionalRender ? images[3] : images[4]}`}>
                    {overlay}
                </LazyBackground>
            </Row>
        </Container>;
    }

    renderOverlay(id) {
        const {hideOverlay, overlayBackgroundColor} = this.props;

        if (hideOverlay) {
            return false
        }

        return [
            <div key={`cover-${id}`} className="cover slide" style={{backgroundColor: overlayBackgroundColor}}/>,
            <div key={`cover-text-${id}`} className="cover-text slide animate-text" style={{fontSize: '30px'}}>
                {/* {renderOverlay()} */}<i className="iPhotos"/>
            </div>
        ]
    }

    renderCountOverlay(more) {
        const {images} = this.props;
        const {countFrom} = this.state;
        const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

        return [more && <div key="count" className="cover"/>, more &&
        <div key="count-sub" className="cover-text" style={{fontSize: '200%'}}><p>+{extra}</p></div>]
    }

    render() {
        var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|wenat_android|wenat_ios/i.test(navigator.userAgent);

        const {modal, index, countFrom} = this.state;
        const {images} = this.props;
        const imagesToShow = [...images];

        if (countFrom && images.length > countFrom) {
            imagesToShow.length = countFrom;
        }

        return (
            <>{imagesToShow.length > 2 && imagesToShow.length < 4 ? <div className="grid-container rnd2">
                {[1, 3, 4].includes(imagesToShow.length) && this.renderOne()}
                {imagesToShow.length >= 2 && imagesToShow.length !== 4 && this.renderTwo()}
                {modal && <Modal onClose={this.onClose} index={index}
                                 images={this.props.photoListOriginalMap}/>}
            </div> : <div className={"grid-container" + (this.props.paidContentPostLock ? " ppLock" : "")}>
                {[1, 3, 4].includes(imagesToShow.length) && this.renderOne()}
                {imagesToShow.length >= 2 && imagesToShow.length !== 4 && this.renderTwo()}
                {imagesToShow.length >= 4 && this.renderThree()}

                {modal && <Modal onClose={this.onClose} index={index}
                                 images={this.props.photoListOriginalMap}/>}
            </div>}</>

        )
    }

}

Images.propTypes = {
    images: PropTypes.array.isRequired,
    hideOverlay: PropTypes.bool,
    renderOverlay: PropTypes.func,
    overlayBackgroundColor: PropTypes.string,
    onClickEach: PropTypes.func,
    countFrom: PropTypes.number,
};

export default Images;
