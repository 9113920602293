import React from 'react'
import axios from "axios";
import * as _ from "lodash";

import httpService from '../AxiosInterceptors';
import {Link} from "react-router-dom";
import { authService } from '../auth';
import { skipChangePassword } from '../services/PasswordApi';

httpService.setupInterceptors();

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        let token;
        let validation = {
            validatePassword: {
                uppercaseTest: new RegExp("^(?=.*[A-Z])"),
                minCharacterTest: new RegExp("^(?=.{8})"),
                style: {borderColor: "#b34c4c", padding: "10px"}
            },
            password: {},
            confirmPassword: {}
        }
        this.state = {
            validation,
            userData: authService.getUserDetailsFromLocalStorage(),
            password: '',
            success: false,
            confirmPassword: '',
            error: false,
            errors: {},
            messages: null,
            isLoading: true,
            upperCaseMsg: '1 Uppercase',
            minimumPasswordCharacter: "Choose a password with at least 8 characters",
            token:'',
            charCountCheckboxStatus : false,
            upperCaseCheckboxStatus : false,
            firstLogin: props?.location?.state?.firstLogin
        };

    }

    componentDidMount() {
        this.setState({data: this.props?.location?.state?.changePassword});
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/api/authenticate?username=' + process.env.REACT_APP_authUser + '&password=' + process.env.REACT_APP_authSecret,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
            .then((response) => {
                this.setState({'token':response.headers.authorization})
            }).catch((e) => {
            console.log(e)
        })
    }

    analyzePassword = (event) => {
        let value = event.target.value && event.target.value.replace(/ /g,"")
        this.setState({password: value});
        let error = false;
        this.setState({
            errors:{}
        })
        this.state.validation.password = {};
        this.state.validation.confirmPassword = {};
        if (this.state.validation.validatePassword.uppercaseTest.test(value)) {
            //this.setState({upperCaseMsg: ''});
            this.setState({upperCaseCheckboxStatus : true});
        } else {
            this.setState({upperCaseMsg: '1 Uppercase' ,upperCaseCheckboxStatus : false});
            error = true;
        }
        if (this.state.validation.validatePassword.minCharacterTest.test(value)) {
            //this.setState({minimumPasswordCharacter: ''});
            this.setState({charCountCheckboxStatus : true});
        } else {
            this.setState({minimumPasswordCharacter: "Password should be 8 characters" ,charCountCheckboxStatus : false});
            error = true;
        }
        if(value !== this.state.confirmPassword){
            this.state.validation.confirmPassword = {
                error: true,
                message: 'Passwords do not match',
                style: { borderColor: "#b34c4c" }
            }
        }else if(value === this.state.confirmPassword){
            this.state.validation.confirmPassword = {
                error: false,
                message: '',
            }
        }
        if (error) {
            this.state.validation.password = {
                error: true,
                message: 'Please enter valid Password',
                style: {borderColor: "#b34c4c"}
            }
        }


    };

    analyzeConfirmPassword = (event) => {
        this.state.validation.confirmPassword = {};
        this.state.validation.password = {};
        this.setState({
            errors:{}
        })
        this.setState({confirmPassword: event.target.value && event.target.value.replace(/ /g,"")}, () => {
            if (this.state.password !== this.state.confirmPassword) {
                this.state.validation.confirmPassword = {
                    error: true,
                    message: 'Passwords do not match',
                    style: {borderColor: "#b34c4c"}
                }
            }
        });

    };

    revokeError() {
        this.setState({messages: ''});
        this.setState({error: false});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateForm()) {
            let postBody = {
                "newPassword": this.state.password,
                "confirmPassword": this.state.confirmPassword
            };
            if(this.state.firstLogin) {
                postBody.userId= this.state.userData.userId;
                postBody.privateEntityId = this.state.userData?.entityId;
                postBody.personalizeEntityUpdationRequired = true;
            } else {
                postBody.transactionId= this.state.data.transactionId;
            }
            let url = '/user/change/password';
            if(this.state.firstLogin) {
                url = '/personalize/initial/update/password';
            }
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + url,
                headers: {'Content-Type': 'application/json', 'Authorization': this.state.token},
                data: postBody,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.status === '202 ACCEPTED') {
                    this.setState({success: true});
                    this.setState({messages: res.message});
                    if(this.state.firstLogin) {
                        setTimeout(() => {
                            authService.clearData();
                            window.location.href = "/";
                        }, 4000);
                    } else {
                        setTimeout(() => {
                            this.props.history.push({
                                pathname: '/login',
                            })
                        }, 7000);
                    }
                }
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    setTimeout(() => {
                        this.revokeError();
                    }, 5000);
                    if(err.response.data.message.indexOf('JWT String argument') ===- 1) {
                        this.setState({
                            isLoaded: true,
                            error: {message: err.response.data.message, err: err.response}
                        });
                    }
                }
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    password: '',
                    confirmPassword: '',
                    error: false
                });
            }, 7000);

        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 7000);

        }
    };

    skipChangePassword = async () => {
        let response = await skipChangePassword(this.state.userData?.userId);
        if(response?.status === "202 ACCEPTED") {
            let updatedData = this.state.userData;
            updatedData.passwordChangeRequired = true;
            localStorage.setItem("userData", JSON.stringify(updatedData));
            this.props.history.push({pathname: `/profile/${this.state.userData?.customUrl}`})
        }
    }

    validateForm() {
        this.state.validation.password = {};
        this.state.validation.confirmPassword = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (this.state.password === '') {
            if (_.isUndefined(this.state.password) ||
                _.isEmpty((this.state.password || "").toString()) ||
                _.isNull(this.state.password)) {
                errors["password"] = "Please enter a password";
                this.state.validation.password = {
                    error: true,
                    style: {borderColor: "#b34c4c"}
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            this.setState({password: this.state.password});
            this.state.validation.password = {};
            let error = false;
            if (this.state.validation.validatePassword.uppercaseTest.test(this.state.password)) {
                //this.setState({upperCaseMsg: ''});
                this.setState({upperCaseCheckboxStatus : true});
            } else {
                this.setState({upperCaseMsg: '1 Uppercase', upperCaseCheckboxStatus : false });
                error = true;
            }
            if (this.state.validation.validatePassword.minCharacterTest.test(this.state.password)) {
                //this.setState({minimumPasswordCharacter: ''});
                this.setState({charCountCheckboxStatus : true});
            } else {
                this.setState({minimumPasswordCharacter: "Password should be 8 characters", charCountCheckboxStatus : false});
                error = true;
            }
            if (error) {
                this.state.validation.password = {
                    error: true,
                    message: 'Please enter valid Password',
                    style: {borderColor: "#b34c4c"}
                }
                formIsValid = false;
            }

        }
        if (_.isUndefined(this.state.confirmPassword) ||
            _.isEmpty((this.state.confirmPassword || "").toString()) ||
            _.isNull(this.state.confirmPassword)) {
            errors["confirmPassword"] = "Please enter a confirm password";
            this.state.validation.confirmPassword = {
                error: true,
                style: {borderColor: "#b34c4c"}
            }
            formIsValid = false;
            validationRequired = true;
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    render() {
        return <section className="loginwrap">
            <div className="container-fluid">
                <div className="login-box text-center">
                    <a href="/" className="logotop">
                        <span>my</span> <img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" />
                    </a>
                    <h1 className="login-header-text" style={{paddingTop: '48px', marginBottom: '30px'}}>Enter New
                        Password</h1>
                    <div className="login-form">
                        <form >
                            <div className="form-group mb-2 passfield">
                                <input type="password" className="custom-input form-control"
                                       style={this.state.validation.password.error ? this.state.validation.password.style : null}
                                       value={this.state.password}
                                       data-tip={
                                           this.state.validation.password.error &&
                                           this.state.validation.password.message
                                       }
                                       //maxLength="15"
                                       onBlur={this.analyzePassword}
                                       onChange={this.analyzePassword}
                                       aria-describedby="inputGroupPrepend" placeholder="Password"
                                />
                                <span className="passtoggle" style={{
                                    display: 'block'
                                }}>Show</span>
                                <small className="pass-tooltip">
                                    <div className={!this.state.charCountCheckboxStatus ? 'passCheckList' : 'passCheckList check'}>
                                        {this.state.minimumPasswordCharacter}
                                    </div>

                                    <div className={!this.state.upperCaseCheckboxStatus ? 'passCheckList' : 'passCheckList check'}>
                                        {this.state.upperCaseMsg}
                                    </div>
                                </small>
                            </div>
                            {/* <small> {this.state.minimumPasswordCharacter}</small>
                            <small>{this.state.upperCaseMsg}</small> */}
                            
                            <span className="login-feedback-error mt-1"
                                  style={{display: "block"}}>{this.state.errors.password}</span>
                            {/* <span className="login-feedback-error mt-1">{this.state.validation.password && this.state.validation.password.message}</span> */}
                            <div className="form-group">
                                <input type="password" className="custom-input form-control"
                                       style={this.state.validation.confirmPassword.error ? this.state.validation.confirmPassword.style : null}
                                       value={this.state.confirmPassword}
                                       data-tip={
                                           this.state.validation.confirmPassword.error &&
                                           this.state.validation.confirmPassword.message
                                       }
                                       //maxLength="15"
                                       onBlur={this.analyzeConfirmPassword}
                                       onChange={this.analyzeConfirmPassword}
                                       aria-describedby="inputGroupPrepend" placeholder="Re-enter Password"
                                />

                                <span className="login-feedback-error mt-1"
                                      style={{display: "block"}}>{this.state.errors.confirmPassword}</span>
                                <span
                                    className="login-feedback-error mt-1">{this.state.validation.confirmPassword && this.state.validation.confirmPassword.message}</span>

                            </div>
                            {this.state.error &&
                                    <span className="login-feedback-error" style={{ display: "block" }}> {this.state.error.message} </span>}
                            {this.state.success &&
                            <span className="login-success-error"> {this.state.messages} </span>}
                            <button type="submit" onClick={(e)=>{this.handleSubmit(e)}} className="login-btn btn btn-primary mt-3"
                                    style={{padding: '12px 20px'}}>Change Password
                            </button>
                            <div className="cancel">
                                {this.state.firstLogin ? 
                                    <a href="javascript:void(0)" className="counter_Btn btn" onClick={() => this.skipChangePassword()}>Skip for now</a> :
                                    <Link to="/login" className="counter_Btn btn">Cancel</Link>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>


    }
}

export default ChangePassword
