import React from 'react'
import Header from '../Header';
import './main-userdashboard.min.css';
import axios from "axios";
import { authService } from '../auth';
import LeftPanelSettings from '../settings/LeftPanelSettings';
import InfiniteScroll from "react-infinite-scroller";
import CommonHashTagsProject from '../explore/HashTags/Common/CommonHashTagsProject';
import CommonHashTagsPhoto from '../explore/HashTags/Common/CommonHashTagsPhoto';
import CommonHashTagsBlog from '../explore/HashTags/Common/CommonHashTagsBlog';
import CommonHashTagsVideo from '../explore/HashTags/Common/CommonHashTagsVideo';
import "../explore/main-explore.min.css";
import Loader from "react-loader-spinner";
import CommonHashTagsOthers from '../explore/HashTags/Common/CommonHashTagsOther';
import ForumItem from '../forum/ForumItem';
import { userAgentGetData } from '../commons/CommonFunctions';
import PollItem from '../forum/PollItem';
import { Link} from 'react-router-dom';




class Bookmark extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            activeTab : this.props?.location?.state?.selectedTab ? this.props.location.state.selectedTab  : "POST" ,
            savePostDataList: {},
            saveProjectDataList: {},
            saveForumDataList: {},
            savePollDataList: {},
            postSuccess: false,
            thresholdHeight: 1000,
            pageNo: 0,
            size: 12,
            managePermission: false,
            userId: authService.getUserDetailsFromLocalStorage()?.userId
        };
    }

    onChangeSavePostType = (activeTab) => {
        if(this.state.activeTab !== activeTab) {
            this.setState({
                activeTab: activeTab,
                pageNo: 0,
                savePostDataList: {},
                saveProjectDataList: {},
                saveForumDataList: {},
                savePollDataList: {}
            }, () => {
                this.getSavePostListByType(0, this.state.size, false);
            })
        }
    }

    getSavePostListByType = (page, size, concatWithPrevious) => {
        let cancelToken;
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source();
        this.setState({
            postSuccess: true,
            isChangeTabAvailable: false
        })
        let savedDataList = this.state.activeTab === "POST" ? this.state.savePostDataList : this.state.activeTab === "PROJECT" ? this.state.saveProjectDataList : this.state.activeTab === "FORUM" ? this.state.saveForumDataList : this.state.savePollDataList;
        let url = `bookmark/${this.state.activeTab === "POST" ? "post" : "project"}/list?userId=${this.state.userId}&page=${page}&size=${size}&privateEntityId=${this.state.userData.entityId}`;
        if(this.state.activeTab === "FORUM") {
            url = `forum/list?showType=PINNED&userId=${this.state.userId}&searchParam=&page=${page}&size=${size}&privateEntityId=${this.state.userData.entityId}`
        }
        if(this.state.activeTab === "POLL") {
            url = `poll/list?showType=PINNED&filterType=POLL&userId=${this.state.userId}&searchParam=&page=${page}&size=${size}${userAgentGetData()}&privateEntityId=${this.state.userData.entityId}`
        }
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let pageData = response.data.body
                if (concatWithPrevious && savedDataList && savedDataList.content) {
                    pageData.content = savedDataList.content.concat(pageData.content);
                }
                this.setState({
                    [this.state.activeTab === "POST" ? "savePostDataList" : this.state.activeTab === "PROJECT" ? "saveProjectDataList" : this.state.activeTab === "FORUM" ? "saveForumDataList" : "savePollDataList"]: pageData,
                    postSuccess: false,
                    isChangeTabAvailable: true
                })
            }
        }).catch((err) => {
            console.log('err', err);
            this.setState({
                postSuccess: false
            })
        });
    }

    fetchMoreSavePostData() {
        let savedDataList = this.state.activeTab === "POST" ? this.state.savePostDataList : this.state.activeTab === "PROJECT" ? this.state.saveProjectDataList : this.state.activeTab === "FORUM" ? this.state.saveForumDataList : this.state.savePollDataList;
        if (savedDataList && savedDataList.content && (((this.state.activeTab === "POST" || this.state.activeTab === "PROJECT") && !savedDataList.last) || ((this.state.activeTab === "FORUM" || this.state.activeTab === "POLL") && !savedDataList.page?.last))) {
            let pageable = savedDataList.pageable;
            this.setState({
                postSuccess: true,
                pageNo: pageable.pageNumber + 1
            }, () => {
                this.getSavePostListByType(this.state.pageNo, this.state.size, true);
            });
        }
    }

    onSuccessUnsaveBookmarkPost = (id) => {
        let saveItemList = this.state.activeTab === "POST" ? this.state.savePostDataList : this.state.activeTab === "PROJECT" ? this.state.saveProjectDataList : this.state.activeTab === "FORUM" ? this.state.saveForumDataList : this.state.savePollDataList;
        const index = saveItemList && saveItemList.content.findIndex(data => (this.state.activeTab !== "PROJECT" ? data.id : data.projectId) === id);
        if (index > -1) {
            saveItemList.content.splice(index, 1);
        }
        this.setState({
            [this.state.activeTab === "POST" ? "savePostDataList" : this.state.activeTab === "PROJECT" ? "saveProjectDataList" : this.state.activeTab === "FORUM" ? "saveForumDataList" : "savePollDataList"]: saveItemList
        });
        this.forceUpdate();
    }

    componentDidMount() {
        document.title = "WeNaturalists Bookmark";
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
        this.getSavePostListByType(this.state.pageNo, this.state.size, false);
    }

    render() {
        const { activeTab, postSuccess, thresholdHeight, managePermission, userId } = this.state;
        const savePostDataList = this.state.activeTab === "POST" ? this.state.savePostDataList : this.state.activeTab === "PROJECT" ? this.state.saveProjectDataList : this.state.activeTab === "FORUM" ? this.state.saveForumDataList : this.state.savePollDataList;
        return <div className={"wrapper projectpage explorepage settingspage" + (activeTab === "FORUM" || activeTab === "POLL" ? " forumpage" : "")}>
            <Header />
            <main className="dgcontainer hm3col">
                <div className="container">
                    <div className="row">

                        <LeftPanelSettings managePermission={managePermission} />

                        <div className="col-md-9">                            
                            <div className="projectinn stdGlobalPage">
                                <div className="projtopbar">
                                    <div className="projtopbarleft">
                                        <h1>Saved Items</h1>
                                    </div>
                                </div>

                                
                                
                                <div className="explpopnaturalist trnd exploregenerelExtra draftglobalSticky">
                                    <div className="draftglobalwrap whatsTrend">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="explpopnaturfirst">
                                                    <ul class="category">
                                                        <li className={"btn" + (activeTab === "POST" ? " active" : "") + (postSuccess ? " disabled" : "")} onClick={(e) => this.onChangeSavePostType("POST")} style={{pointerEvents: postSuccess ? "none" : 'all'}}>
                                                            Post
                                                        </li>
                                                        <li className={"btn" + (activeTab === "PROJECT" ? " active" : "") + (postSuccess ? " disabled" : "")} onClick={(e) => this.onChangeSavePostType("PROJECT")} style={{pointerEvents: postSuccess ? "none" : 'all'}}>
                                                            Project
                                                        </li>
                                                        <li className={"btn" + (activeTab === "FORUM" ? " active" : "") + (postSuccess ? " disabled" : "")} onClick={(e) => this.onChangeSavePostType("FORUM")} style={{pointerEvents: postSuccess ? "none" : 'all'}}>
                                                            Forum
                                                        </li>
                                                        <li className={"btn" + (activeTab === "POLL" ? " active" : "") + (postSuccess ? " disabled" : "")} onClick={(e) => this.onChangeSavePostType("POLL")} style={{pointerEvents: postSuccess ? "none" : 'all'}}>
                                                            Poll
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='dailyeditionblk explLatarticle trndBlgs alltrndBlgs'>
                                    <InfiniteScroll
                                        loadMore={this.fetchMoreSavePostData.bind(this)}
                                        hasMore={!postSuccess && ((activeTab !== "FORUM" && activeTab !== "POLL") ? !savePostDataList.last : !savePostDataList?.page?.last)}
                                        threshold={thresholdHeight}
                                        loader={<span style={{ textAlign: "center", display: "block" }}>
                                            <div style={{ display: "inline-block", marginTop: "100px" }}>
                                                <Loader type="Grid" color="#dcde63" height={60} width={60} timeout={8000} />
                                            </div>
                                        </span>
                                        }
                                    >
                                        <div className={(activeTab !== "FORUM" && activeTab !== "POLL") ? "row all" : "bookmarkWrap forumsection"}>
                                            {savePostDataList && savePostDataList.content && savePostDataList.content.map((item, index) => {
                                                return (
                                                    activeTab !== "FORUM" && activeTab !== "POLL" ?
                                                        <div className="col-md-4 bookmarkWrap">
                                                            {activeTab === "POST" ?
                                                                (item.postType === 'ARTICLE' ?
                                                                    <CommonHashTagsBlog item={item} key={item.id} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} /> :
                                                                    item.attachmentIds && item.attachmentIds.length > 0
                                                                        ? item.attachmentIds[0].attachmentType === 'VIDEO' ?
                                                                            <CommonHashTagsVideo item={item} key={item.id} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} /> :
                                                                            item.attachmentType === "IMAGE" ?
                                                                                <CommonHashTagsPhoto item={item} key={item.id} idx={index} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} /> :
                                                                                <CommonHashTagsOthers item={item} key={item.id} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} />

                                                                        : <CommonHashTagsOthers item={item} key={item.id} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} />) :
                                                                    <CommonHashTagsProject data={item} key={item.id} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} />  
                                                            }
                                                        </div> : 
                                                        activeTab === "FORUM" ?
                                                            <ForumItem item={item} key={item.id} specificKey={"trending"} pin={item.pinned} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} /> :
                                                            <PollItem item={item} key={item.id} pin={item.pinned} pageType={"BOOKMARK"} onSuccessUnsaveBookmarkPost={this.onSuccessUnsaveBookmarkPost} />

                                                )
                                            })}
                                            {savePostDataList && savePostDataList?.content?.length === 0 &&
                                                <div className='col-md-12'>
                                                    <div className="zdata zfeed">
                                                        <div className="imgbox">
                                                            <img src={activeTab === "FORUM" ? "https://cdn.dscovr.com/images/zroForumPinned-new.webp" : 
                                                                activeTab === "POLL" ? "https://cdn.dscovr.com/images/zroPollsPinned-new.webp" : 
                                                                "https://cdn.dscovr.com/images/zeroNodateNew1.webp" }
                                                                onError={(e) => { e.target.onerror = null; e.target.src = (activeTab === "FORUM" ? "https://cdn.dscovr.com/images/zroForumPinned-new.png" : activeTab === "POLL" ? "https://cdn.dscovr.com/images/zroPollsPinned-new.png" : "https://cdn.dscovr.com/images/zeroNodateNew1.png") }}
                                                                width={520}
                                                                alt="" />
                                                        </div>
                                                        <div className="desc">
                                                            {activeTab === "FORUM" ?
                                                                <>
                                                                <h4>Pining your favourite forums is a great way to revisit them later.</h4>
                                                                <p>Pin {" "}<Link to={"/forum"}>forums</Link>{" "} you like!</p>
                                                                </> :
                                                                activeTab === "POLL" ? 
                                                                <>
                                                                <h4>Pining your favourite polls is a great way to revisit them later.</h4>
                                                                <p>Pin {" "}<Link to={"/poll"}>polls</Link>{" "} you like!</p>
                                                                </> :
                                                                <p>You have no saved {activeTab.toLowerCase()}.</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    }
}
export default Bookmark;
