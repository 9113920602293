import axios from 'axios';
import {authService} from './auth';

const qs = require('querystring');

export default {

    setupInterceptors: () => {

        let isRefreshing = false;
        let failedQueue = [];

        const processQueue = (error) => {
            failedQueue.forEach(prom => {
                if (error) {
                    prom.reject(error);
                } else {
                    prom.resolve();
                }
            })

            failedQueue = [];
        }

        axios.interceptors.response.use((response) => {
            // Return a successful response back to the calling service
            return response;

        }, (error) => {

            // Return any error which is not due to authentication back to the calling service
            if (error.response === undefined || error.response.status !== 401) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            //Exclude User Login
            if (error.config.url === process.env.REACT_APP_userServiceURL + '/personalize/login') {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            //Exclude Company Migration
            if (error.config.url === process.env.REACT_APP_userServiceURL + '/user/company-operator-to-individual') {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            // Logout user if token refresh didn't work or user is disabled
            if (error.config.url === process.env.REACT_APP_userServiceURL + '/user/renew/token'
                || (error.response && error.response.message === 'Account is disabled.')) {

                let userData = authService.getUserDetailsFromLocalStorage();

                if(window.ReactNativeWebView && userData.userId)
                {
                    window.ReactNativeWebView.postMessage(JSON.stringify({type:'logout', userId: userData.userId}));
                }
                authService.clearData();
                window.location = "/login";

                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            const originalRequest = error.config;


            if (!originalRequest._retry) {

                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({resolve, reject})
                    }).then(() => {
                        return axios(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    })
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise((resolve, reject) => {
                    let url = process.env.REACT_APP_userServiceURL + '/user/renew/token';

                    return axios({
                        method: 'post',
                        url: url,
                        data: qs.stringify({
                            refreshToken: authService.getRefreshToken()
                        }),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then((response) => {
                        if (
                            response &&
                            response.data &&
                            response.data.statusCode === 200
                        ) {
                            processQueue(null);
                            resolve(axios(originalRequest));
                        } else {
                            console.log(response);
                            reject(response);
                        }
                    }).catch((e) => {
                        processQueue(e);
                        reject(e);
                    }).finally(() => {
                        isRefreshing = false
                    });
                });
            }

            return Promise.reject(error);
        });

    }
}
