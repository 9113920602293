import React, { Component } from 'react'
import { renderToString } from 'react-dom/server';
import { renderTaggedUsers } from '../commons/CommonFunctions';
import ReactHtmlParser from 'react-html-parser';
import { options } from '../explore/HashTags/Common/HashtagsCommonFunction';
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Link } from 'react-router-dom';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

export default class ForumPostBody extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { title, description, hashTags, slug, pageType, postType } = this.props;
        let linkfiedHtml =
            '<p class="pdtlsLink forum-pdtlsLink">' +
                renderTaggedUsers(description ? description : "")
                .replace(/<img[^>]*>/g, "")
                .replace(/<h2[^>]*>/g, "")
                .replace(/<blockquote[^>]*>/g, "")
                .replace(/&nbsp;/g, " ")
                .replace(/(\r\n|\r|\n)/g, "<br>")
                .replace(/(\\n)/g, "<br>") +
            "</p>";
        let linkfiedHashtagHtml = ((hashTags && hashTags !== undefined && hashTags !== null && hashTags.length > 0) ? 
            "<div class=\"hposthash\">"+
            hashTags.map((item) => {
                        return ("<a href=\"/profile-hashtag/"+item+ '/' + this.props.item?.userCustomUrl +"\">"
                        + "<i class=\"iHashtag\">"+"</i>" +item+
                        "</a>")
                    }
                )
           + "</div>" : "")
        linkfiedHashtagHtml = linkfiedHashtagHtml.replace(/,/g, '');
        linkfiedHtml = renderToString(ReactHtmlParser(linkfiedHtml+linkfiedHashtagHtml,options));
        return (
            <div className="forumrow post-forum">
                <h4>
                    <Link to={`/${postType === "FORUM" ? "forumDetail" : "pollDetail"}/${slug}`}>
                        {title}
                    </Link>
                </h4>
                <ResponsiveEllipsis
                    unsafeHTML={linkfiedHtml}
                    maxLine={pageType === 'BOOKMARK' || pageType === 'FEATURE_POST' ? "6" : "3"}
                    ellipsis='...'
                    trimRight
                    basedOn='words'
                />                
            </div>
        )
    }
}
