import React from "react";
import axios from "axios";
import {authService} from "../auth";
import uuid from "react-uuid";
import {convertToFirstCapital} from "../utils/StringUtility";

class OperatorRequest extends React.Component {

    constructor(props) {
        super(props);
        let userData = authService.getUserDetailsFromLocalStorage();
        this.state = {
            userData: userData,
            details: '',
            error: '',
            modalEntityId: this.props.entityId + uuid(),
            isDisabled : true
        }
    }

    follow = (userId) => {
        let url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/follows/' + userId+'?privateEntityId='+this.state.userData.entityId;
        axios({
            method: 'post',
            url: url,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {

            }
        }).catch((err) => {
            console.log(err);
        });
    }


    acceptRequest=(event)=>{
        this.setState({'isSubmitted': true});
        event.preventDefault();
        let postData={
            companyId: this.props.entityId,
            userId: this.state.userData && this.state.userData.userId,
            notificationId:this.props.notificationId,
            status:'APPROVED',
            privateEntityId: this.state.userData?.entityId
        }
        axios({
            method:'post',
            url:process.env.REACT_APP_userServiceURL + '/operator/update/status',
            headers:{ 'Content-Type': 'application/json' },
            data:postData,
            withCredentials: true
        }).then((response)=>{
            let res = response.data
            if(res && res.status === '202 ACCEPTED'){
                this.follow(this.props.entityId);
                this.setState({
                    'successMsg': ' You are now the admin of the organization '+this.props.entityTitle,
                    'isSubmitted': false
                });
                if(this.props.updateNotificationData){
                    this.props.updateNotificationData(this.props.notificationId)
                }

            } else {
                this.setState({'isSubmitted': false});

            }
        }).catch((err)=>{
            console.log(err)
            this.setState({'isSubmitted': false});
        })
    }

    declineRequest = (event) => {
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false})
        let postData={
            companyId: this.props.entityId,
            userId: this.state.userData && this.state.userData.userId,
            notificationId:this.props.notificationId,
            status:'DECLINED',
            privateEntityId: this.state.userData?.entityId
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/operator/update/status',
            headers: {'Content-Type': 'application/json'},
            data: postData,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '202 ACCEPTED') {
                this.hideModal();
                if(this.props.updateNotificationData){
                    this.props.updateNotificationData(this.props.notificationId)
                }
            }
            this.setState({isLoading: false});
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    isSubmitted: false,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        setTimeout(() => {
            this.setState({
                error: '',
                isLoading: false
            });
        }, 5000);
    };


    componentDidMount() {
        window.$('#operatorRequest' + this.state.modalEntityId).modal('show');
    }

    componentWillUnmount() {
        this.hideModal();
    }


    hideModal = () => {
        this.setState({
            isSubmitted: false,
            error: ''
        });
        window.$('#operatorRequest'+this.state.modalEntityId).modal('hide');
        this.props.triggerOperatorRequestPopUpClose();
    }

    hideModalAndRefreshPage = () => {
        this.setState({
            isSubmitted: false,
            details: '',
            reason: '',
            error: ''
        });
        window.$('#operatorRequest' + this.state.modalEntityId).modal('hide');
        this.props.triggerOperatorRequestPopUpClose();
    }

    render() {
        return (
            <div id={"operatorRequest" + this.state.modalEntityId} className="modal fade dgpopup confirmpop" data-backdrop="static" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button type="button" className="close" onClick={this.hideModal}><span
                            aria-hidden="true">&times;</span></button>

                        {
                            this.state.error && this.state.error !== '' ?
                                <>
                                    <div className="confirm-popup-modal-body">{this.state.error}</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={this.hideModal}>Cancel
                                        </button>
                                    </div>
                                </>
                                : (this.state.successMsg && this.state.successMsg !== '' ?
                                <>
                                    <div className="confirm-popup-modal-body"><span className="default-caps">{this.props.username} </span>{this.state.successMsg}</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={this.hideModalAndRefreshPage}>Ok
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="confirm-popup-modal-body">Are you sure you want to accept the admin rights for the organization  {this.props.entityTitle?<span>{this.props.entityTitle}</span>: 'his profile'} ?</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-primary"
                                                onClick={this.acceptRequest}
                                                disabled={this.state.isSubmitted}
                                                data-toggle="modal">{this.state.isSubmitted ? "Loading..." : "ACCEPT"}
                                        </button>
                                        <button type="button" className="btn btn-secondary"
                                                onClick={this.declineRequest}
                                                disabled={this.state.isSubmitted}
                                                data-toggle="modal">{this.state.isSubmitted ? "Loading..." : "IGNORE"}
                                        </button>
                                    </div>
                                </>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}



export default OperatorRequest;
