import React from "react";
import { TwitterShareButton } from "react-share";
import FacebookShareButton from "react-share/es/FacebookShareButton";
import WhatsappShareButton from "react-share/es/WhatsappShareButton";
import LinkedinShareButton from "react-share/es/LinkedinShareButton";
import TumblrShareButton from "react-share/es/TumblrShareButton";
import RedditShareButton from "react-share/es/RedditShareButton";
import TelegramShareButton from "react-share/es/TelegramShareButton";
import $ from "jquery";
import { authService } from "../auth";
import axios from "axios";
import Loader from "react-loader-spinner";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import uuid from "react-uuid";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import { blockUserMessage, convertUnicode, defaultImageSection, getCDNUrl, userAgentGetData, userAgentPostData } from "./CommonFunctions";
import { RWebShare } from "react-web-share";
import { isMobileDevice } from "../commons/CommonFunctions";
import { Scrollbars } from "react-custom-scrollbars";
import HorizontalScroll from "react-scroll-horizontal";
import MentionTextArea from "./MentionTextArea";
import ReactTooltip from "react-tooltip";
import InfiniteScroll from "react-infinite-scroller";
import LikeMemberItem from "./LikeMemberItem";
import cloneDeep from "lodash/cloneDeep";
import Picker from "emoji-picker-react";
import AutoSuggest from "../AutoSuggest";
import * as _ from "lodash";
import CommonPostAsPopUp from "./CommonPostAsPopUp";
import { withRouter } from "react-router-dom";
import CommonLinkPreview from "./CommonLinkPreview";
import stripHtml from "string-strip-html";

var isMobile = isMobileDevice();

if (isMobile) {
  import("../commons/styles/responsive-useragent.css").then(() => {
    console.log("Imported mobile css");
  });
}

class Share extends React.Component {
  constructor(props) {
    super(props);
    let validation = {
      postText: {},
    };
    let userData = authService.getUserDetailsFromLocalStorage();
    this.state = {
      userData: userData,
      postPrivacy: "ANYONE",
      postText: "",
      validation,
      errors: {},
      hashTags: [],
      isPostSubmitted: false,
      userList: [],
      searchUserByChars: "",
      selectedList: [],
      circleAdminList: [],
      businessPageList: [],
      visibleToUserIds: [],
      hiddenPrivacy: false,
      isModalLoading: false,
      parentEntityId: null,
      forcePost: false,
      sharesCount: props.sharesCount ? props.sharesCount : "",
      sharepopDisplay: props.sharesCount > 0 ? true : false,
      postId: "",
      sharedUserPagedData: {},
      shareDataTip: "Action Restricted",
      modalEntityId: props.entityId + uuid(),
      entityName: userData ? userData.type === "COMPANY" ? userData.companyName : userData.firstName : "",
      entityType: userData && userData.type,
      secondaryEntityName: "Feeds",
      coverImage: userData && userData.profileImage ? getCDNUrl(this.getOptimizedImage(userData)) : userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png",
      userName: userData ? userData.type === "COMPANY" ? userData.companyName : userData.firstName : "",
      smileDisplay: false,
      shared: false,
      showId: "",
      restrictionUserFlag: false,
      entityData: {},
      postAsFlag: false,
      currentUserDetails: {},
      isShowEditOption: false,
      og_data: {},
    };
  }

  handleSmileDisplay = () => {
    this.setState({
      smileDisplay: this.state.smileDisplay ? false : true,
    });
  };

  onToggleEmojiWindow = () => {
    const { smileDisplay } = this.state;
    this.setState({ smileDisplay: !smileDisplay });
  };

  onEmojiClick = (event, emojiObject) => {
    let postText = cloneDeep(this.state.postText);
    postText = postText + " " + emojiObject.emoji;
    this.setState({
      postText: postText,
    });
  };

  getCircleAdminList() {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/circle/get/list-for-post/" +
        this.state.userData.userId +
        "?isAdmin=true&privateEntityId="+this.state.userData.entityId,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.status === 200) {
          if (response.data.body) {
            this.setState({
              circleAdminList: mappingUtils.circleSorting(response.data.body),
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getBusinessPageList = (userId) => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/profile/get/private/business/pages?userId=" +
        this.state.userData.userId + userAgentGetData()+'&privateEntityId='+this.state.userData.entityId,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.status === 200) {
          if (response.data.body) {
            this.setState({
              businessPageList: mappingUtils.businessPagesSorting(
                response.data.body
              ),
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onCopy = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({
          copied: false,
        });
        $(".dropdown-menu").removeClass("show");
        $(".dropdown-menu").removeAttr("style");
        $(".dropdown-menu").removeAttr("x-placement");
      }, 5000);
    });
  };

  getLinkPreviewUrl = (text) => {
    let urlValidPostWenat = text.startsWith(process.env.REACT_APP_domainUrl + "/post");
    let data = {
      url: text,
      sendByMyWenat: true
    }
    if (urlValidPostWenat) {
      data.id = text.substring(text.lastIndexOf("/") + 1);
    }
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
      data: data,
      withCredentials: true
    }).then((response) => {
      if (response?.data?.status === 200) {
        if (!_.isEmpty(response?.data?.data) || response?.data?.data.length > 0) {
          this.setState({
            og_data: response?.data?.data
          })
        } else {
          this.getLinkPreviewUrlDataDetails(data.id);
        }
      }
    }).catch((error) => {
      console.log("Error", error);
    })
  };

  getMaxWords(str, max) {
    if (str != null) {
      var res = str.split(/\s+/).slice(0, max).join(" ");
      res += "...";
      return res;
    }
  }

  getLinkPreviewUrlDataDetails = (id) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_userServiceURL}/unauth/post-metatags-ById?id=${id}`,
      withCredentials: true
    }).then((response) => {
      if (response && response.data && response.data.status === "200 OK") {
        let data = response.data.body;
        let title = data?.createdBy + " shared on myWeNaturalists | Ecosystem for Nature Professionals.";
        let description = this.getMaxWords(stripHtml(data.metaDescription), 20);
        let image = data.imageUrl ? data.imageUrl : data.postType === "AUDIO" ? "https://cdn.dscovr.com/images/Audio.png" : data.postType === "ARTICLE" ? "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png" : data.postType === "LINK" ? "https://cdn.dscovr.com/images/Link.png" : data.postType === "VIDEO" ? "https://cdn.dscovr.com/images/video-blank2.png" : data.postType === "IMAGE" ? "https://cdn.dscovr.com/images/Photo.png" : "https://cdn.dscovr.com/images/social-share-icon2.png"
        this.setState({
          og_data: { "title": title, "description": description, "image": image }
        })
      }
    }).catch((error) => {
      console.log("Error", error);
    })
  }

  componentDidMount() {
    var that = this;
    $("#copyLnk" + (this.props.slug ? this.props.slug : this.props.shareId)).on(
      "click",
      function (event) {
        event.stopPropagation();
        const url =
          that.props.absoluteShareUrl && that.props.absoluteShareUrl !== ""
            ? that.props.absoluteShareUrl
            : process.env.REACT_APP_domainUrl +
            that.props.shareUrl +
            (that.props.slug ? that.props.slug : that.props.shareId);
        const textField = document.createElement("textarea");
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        that.onCopy();
      }
    );
    window.$(".dropdown-submenu > a").on("click", function (e) {
      var submenu = $(this);
      $(".dropdown-submenu .dropdown-menu").removeClass("show");
      submenu.next(".dropdown-menu").addClass("show");
      e.stopPropagation();
    });
    window.$(".socialico.we").click(function (event) {
      window.$(this).parents(".contxmenu").addClass("open");
    });
    window.$(".dgpopup .cancel").click(function (event) {
      window.$(this).parents(".contxmenu").removeClass("open");
    });

    document.querySelector("#root").addEventListener("click", event => {
      // Get parent element and check if click happened outside parent only
      const parent = document.querySelector(".emoji-picker-react");
      const { smileDisplay } = this.state;
      if (smileDisplay && !parent.contains(event.target)) {
        this.onToggleEmojiWindow();
      }
    });
  }

  getConnects(page, size) {
    return new Promise((resolve) => {
      axios({
        method: "get",
        url:
          process.env.REACT_APP_userServiceURL +
          "/entity/network/" +
          this.state.userData.userId +
            '/'+this.state.userData.entityId+
          "/connects" +
          "?page=" +
          page +
          "&size=" +
          size + userAgentGetData(),
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            resolve(response.data.body.content);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  handlePostSubmit = () => {
    if (this.validateForm()) {
      this.setState({ isPostSubmitted: true });
      let errors = {};
      let data = {
        creatorId: this.state.userData.userId,
        userId: this.state.userId ? this.state.userId : this.state.userData.userId,
        parentEntityId: this.state.parentEntityId,
        shareId: mappingUtils.isProject(this.props.entityType) ? this.props.entityId : this.props.shareId,
        entityId: this.props.entityId,
        entityType: this.props.entityType,
        description: this.state.postText,
        hashTags: this.state.hashTags ? this.state.hashTags.map((item) => { return item.replace(/#/g, ""); }) : [],
        postVisibility: this.state.postPrivacy,
        visibleToUserIds: this.state.visibleToUserIds,
        forcePost: this.state.forcePost,
        userAgent: userAgentPostData(),
        privateEntityId : this.state.userData && this.state.userData.entityId
      };

      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/post/share",
        data: data,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 201) {
            this.setState(
              {
                forcePost: false,
                postId: response.data.body.postId,
                //'sharesCount': this.state.sharepopDisplay ? this.state.sharesCount+1 : this.state.sharesCount,
                sharesCount: response.data.body.showCount,
              },
              () => {
                if (
                  this.state.sharesCount &&
                  this.props.entityType !== "STORY"
                ) {
                  this.setState({ sharepopDisplay: true });
                }
                //this.hideModal();
                window.$("[id^=toppostwrap]").modal("hide");
                setTimeout(() => {
                  window
                    .$("#AcceptShare" + this.state.modalEntityId)
                    .modal("show");
                }, 500);
                setTimeout(() => {
                  this.setState({
                    isPostSubmitted: false,
                    error: null,
                    postText: "",
                    hashTags: [],
                    postPrivacy: "ANYONE",
                    isModalLoading: false,
                    parentEntityId: null,
                    hiddenPrivacy: false,
                    entityName: this.state.userName,
                    userId: null,
                    entityType: "",
                    smileDisplay: false,
                    secondaryEntityName: "Feeds",
                    coverImage:
                      this.state.userData && this.state.userData.profileImage
                        ? getCDNUrl(this.getOptimizedImage(this.state.userData))
                        : this.state.userData.type != "COMPANY"
                          ? "https://cdn.dscovr.com/images/users1.png"
                          : "https://cdn.dscovr.com/images/DefaultBusiness.png",
                  });
                  this.clearUsers();
                  window.$(".contxmenu").removeClass("open");
                  if (this.refs.TagsRef) {
                    this.refs.TagsRef.clearInput();
                  }
                  window
                    .$("#toppostwrap" + this.state.modalEntityId)
                    .modal("hide");
                  window
                    .$("#AcceptShare" + this.state.modalEntityId)
                    .modal("hide");
                }, 60000);
              }
            );
            /*
                                        this.props.requestPostCreationFulfilled({success: true});
                    */
          } else {
            console.log(response);
            this.setState({ isPostSubmitted: false });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.status === "409" || err?.response?.data?.status === "409 CONFLICT") {
            this.setState({
              isPostSubmitted: false
            })
            blockUserMessage("MESSAGE", err.response.data.message);
          }
          if (err.response && err.response.status === 404) {
            errors["connectionText"] =
              "No Connects Yet Post will be visible only to you Want to Proceed?";
            console.log(err);
            this.setState({
              isPostSubmitted: false,
              forcePost: true,
            });
          }
        });
      this.setState({
        errors: errors,
      });
    }
    setTimeout(() => {
      this.setState({
        error: "",
        errors: {},
      });
    }, 5000);
  };

  getDataList = (entityId, type) => {
    let url = "";
    if (type === "CAUSE") {
      url = `backend/public/cause/joined/list?userId=${entityId}&page=0&size=1000`;
    } else if (type === "PROJECT") {
      url = `backend/getProjects/${entityId}?excludeJobs=true&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`;
    } else {
      url = `circle/get/list-for-post/${entityId}?privateEntityId=${this.state.userData.entityId}`;
    }
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/${url}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response && response.data && response.data.body && response.status === 200) {
        let dataList = type !== "CAUSE" ? response.data.body : response.data.body?.content;
        let data = dataList && dataList.find((data) => data.slug === (type === "CAUSE" ? this.props.match.params?.id : type === "CIRCLE" ? this.props.match.params?.slug : (this.props.match.params?.projectId || this.props.match.params?.slug)));
        if (!_.isEmpty(data)) {
          this.setState({
            entityId: data.id,
            parentEntityId: data.id,
            secondaryEntityName: type === "CAUSE" ? data.name : data.title
          })
        } else {
          this.setState({
            parentEntityId: this.state.userId ? this.state.userId : this.state.userData.userId
          })
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getEntityCount = (userId) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/userRelated/count?userId=${userId}&requestingUserId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response && response.data && response.data.message === "Success!") {
        this.setState({
          isShowEditOption: response.data.body > 0 ? true : false
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  openSharePost = () => {
    this.getEntityCount(this.state.userId ? this.state.userId : this.state.userData.userId);
    if (this.props.match?.path === "/cause/:id") {
      this.getDataList(this.state.userId ? this.state.userId : this.state.userData.userId, "CAUSE");
    } else if (this.props.match?.path === "/project/:type/:projectId" || this.props.match?.path === "/project/studio/:slug") {
      this.getDataList(this.state.userId ? this.state.userId : this.state.userData.userId, "PROJECT");
    } else if (this.props.match?.path === "/community/:slug" || this.props.match?.path === "/community/activity/:slug" || this.props.match?.path === "/community/all-activity/:slug" ||
      this.props.match?.path === "/community/projects/:slug" || this.props.match?.path === "/community/members/:slug" || this.props.match?.path === "/community/all-members/:slug" ||
      this.props.match?.path === "/community/stats/:slug" || this.props.match?.path === "/community/award/:slug") {
      this.getDataList(this.state.userId ? this.state.userId : this.state.userData.userId, "CIRCLE");
    } else {
      this.setState({
        parentEntityId: this.state.userId ? this.state.userId : this.state.userData.userId
      })
    }
    // this.getLinkPreviewUrl(process.env.REACT_APP_domainUrl + this.props.shareUrl + (this.props.slug ? this.props.slug : this.props.shareId))
    this.getLinkPreviewUrl(this.props.absoluteShareUrl && this.props.absoluteShareUrl !== ""
            ? this.props.absoluteShareUrl
            : process.env.REACT_APP_domainUrl +
            this.props.shareUrl +
            (this.props.slug ? this.props.slug : this.props.shareId))
    window.$("#toppostwrap" + this.state.modalEntityId).modal("show");
  };

  componentWillUnmount() {
    window.$("#shareuser" + (this.props.slug ? this.props.slug : this.props.shareId) + this.state.showId).modal("hide");
    window.$("#postSelectUserModal" + this.state.modalEntityId).modal("hide");
    window.$("#AcceptShare" + this.state.modalEntityId).modal("hide");
    this.hideModal();
  }

  hideModal = () => {
    this.setState({
      isPostSubmitted: false,
      error: null,
      postText: "",
      hashTags: [],
      postPrivacy: "ANYONE",
      isModalLoading: false,
      parentEntityId: null,
      hiddenPrivacy: !_.isEmpty(this.state.currentUserDetails) && (this.state.currentUserDetails?.type === "BUSINESS_PAGES" || this.state.currentUserDetails?.type === "CIRCLE") ? true : false,
      entityName: !_.isEmpty(this.state.currentUserDetails) ? this.state.currentUserDetails?.name : this.state.userName,
      userId: !_.isEmpty(this.state.currentUserDetails) ? this.state.currentUserDetails?.userId : null,
      entityType: !_.isEmpty(this.state.currentUserDetails) ? this.state.currentUserDetails?.type : "",
      smileDisplay: false,
      secondaryEntityName: !_.isEmpty(this.state.currentUserDetails) && this.state.currentUserDetails?.type === "CIRCLE" ? "Community Activity" : "Feeds",
      coverImage: !_.isEmpty(this.state.currentUserDetails) ? this.state.currentUserDetails?.profileImage ? this.state.currentUserDetails?.profileImage : defaultImageSection(this.state.currentUserDetails?.type) :
        this.state.userData && this.state.userData.profileImage
          ? getCDNUrl(this.getOptimizedImage(this.state.userData))
          : this.state.userData.type != "COMPANY"
            ? "https://cdn.dscovr.com/images/users1.png"
            : "https://cdn.dscovr.com/images/DefaultBusiness.png",
    });
    this.clearUsers();
    window.$(".contxmenu").removeClass("open");
    if (this.refs.TagsRef) {
      this.refs.TagsRef.clearInput();
    }
    window.$("#toppostwrap" + this.state.modalEntityId).modal("hide");
    window.$("#AcceptShare" + this.state.modalEntityId).modal("hide");
  };

  changePostPrivacy = (event) => {
    this.setState({ postPrivacy: event.target.value });
  };

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  handleCheckedPrivacy = () => {
    if (this.state.visibleToUserIds && this.state.visibleToUserIds.length > 0) {
      this.setState({ visibleToUserIds: [] });
    }
  };

  handlePostTextUpdate = (event) => {
    this.setState({ postText: event.target.value });
  };

  handleTagsChange = (hashTags) => {
    let hashTagsList = [];
    if (hashTags && hashTags.length > 0) {
      hashTags &&
        hashTags.map(function (data) {
          data = data.replace(
            /[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi,
            ""
          );
          if (data != "") {
            hashTagsList.push(data);
          }
        });
      let allHashTagsList = hashTagsList;
      hashTagsList = [...new Set(allHashTagsList)];
    }
    this.setState({ hashTags: hashTagsList });
  };

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  keyPressedBySearchGroupByChars(event) {
    this.setState({ searchUserByChars: event.target.value });
  }

  clearUsers() {
    window.$("#postSelectUserModal" + this.state.modalEntityId).modal("hide");
    if (document.getElementById("searchGroupByChars")) {
      document.getElementById("searchGroupByChars").value = "";
    }
    this.setState({
      selectedList: [],
      userList: [],
      searchUserByChars: "",
      visibleToUserIds: [],
    });
  }

  addUserToGroup(evt, data) {
    const selectedList = this.state.selectedList;
    selectedList.push(data);
    let array = [...this.state.userList];
    let index = array.indexOf(data);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ userList: array });
    }
    this.setState({ selectedList: selectedList });
    evt.preventDefault();
  }

  removeUserToGroup(evt, data) {
    const userList = this.state.userList;
    userList.push(data);
    let array = [...this.state.selectedList];
    let index = array.indexOf(data);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedList: array });
    }
    this.setState({ userList: userList });
    evt.preventDefault();
  }

  handleUsersSelectSubmit = (event) => {
    event.preventDefault();
    let userIds = [];
    for (let data of this.state.selectedList) {
      userIds.push(data.id);
    }

    document.getElementById("searchGroupByChars").value = "";
    this.setState({ visibleToUserIds: userIds, searchUserByChars: "" });
    window.$("#postSelectUserModal" + this.state.modalEntityId).modal("hide");
  };

  handleSelectedOnly = () => {
    this.setState({ isModalLoading: true });
    this.getConnects(0, 1000)
      .then((userList) => {
        let selectedList = [];
        if (
          this.state.visibleToUserIds &&
          this.state.visibleToUserIds.length > 0
        ) {
          selectedList = userList.filter(({ id }) =>
            this.state.visibleToUserIds.includes(id)
          );
          selectedList.forEach((data) => {
            let index = userList.indexOf(data);
            if (index !== -1) {
              userList.splice(index, 1);
            }
          });
        }
        this.setState(
          {
            userList: userList,
            selectedList: selectedList,
            isModalLoading: false,
          },
          () => { }
        );
      })
      .catch((err) => console.log(err));
    window.$("#postSelectUserModal" + this.state.modalEntityId).modal("show");
  };

  setAsUser = (userId, entityType, entityName, entityId, coverImage) => {
    this.setState(
      {
        userId: userId,
        entityType: entityType,
        entityId: entityType === "Circle" ? entityId : null,
        parentEntityId: entityType === "Circle" ? entityId : null,
        entityName: entityName,
        secondaryEntityName:
          entityType === "Circle" ? "Community Activity" : "Feeds",
        hiddenPrivacy:
          entityType === "Circle" || entityType === "Company" ? true : false,
        postPrivacy: "ANYONE",
        coverImage: coverImage
          ? coverImage
          : this.state.userData && this.state.userData.profileImage
            ? getCDNUrl(this.getOptimizedImage(this.state.userData))
            : this.state.userData.type != "COMPANY"
              ? "https://cdn.dscovr.com/images/users1.png"
              : "https://cdn.dscovr.com/images/DefaultBusiness.png",
      },
      () => {
        this.getProjectList(this.state.userId);
        this.getCauseList(this.state.userId);
        this.getCircleList(this.state.userId);
      }
    );
  };

  setAsEntity = (entityId, entityType, secondaryEntityName) => {
    this.setState({
      parentEntityId: entityId,
      secondaryEntityName: secondaryEntityName,
      hiddenPrivacy:
        entityType === "Circle" ||
          entityType === "Cause" ||
          this.state.entityType === "Company" ||
          (this.state.entityType === "Circle" && entityType === "Project")
          ? true
          : false,
      postPrivacy: "ANYONE",
    });
  };

  copyLink = (isAllowed) => {
    if (isAllowed) {
      var that = this;
      const url =
        that.props.absoluteShareUrl && that.props.absoluteShareUrl !== ""
          ? that.props.absoluteShareUrl
          : process.env.REACT_APP_domainUrl +
          that.props.shareUrl +
          (that.props.slug ? that.props.slug : that.props.shareId);
      const textField = document.createElement("textarea");
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      that.onCopy();
    }
  };

  getUsersWhoShare(page, size) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        //entityId replaced by shareId
        url:
          process.env.REACT_APP_userServiceURL +
          "/share/getUsers/" +
          this.props.shareId +
          "?userId=" +
          this.state.userData.userId +
          "&page=" +
          page +
          "&size=" +
          size + "&privateEntityId=" + this.state.userData?.entityId,
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.body &&
            response.status === 200
          ) {
            resolve(response.data.body.page);
            let likeListHeight = $("#scrollableDiv").height();
            if (likeListHeight && likeListHeight > 0) {
              if (!this.state.initialThresholdHeight) {
                this.setState({
                  initialThresholdHeight: likeListHeight,
                  thresholdHeight: likeListHeight * 0.6,
                });
              }
            }
          } else {
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  }

  checkRestrictionUserFlag = () => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_userServiceURL}/backend/share/check-restriction?activityId=${this.props.shareId}&userId=${this.state.userData.userId}&privateEntityId=${this.state.userData.entityId}`,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    }).then(response => {
      if (response && response.data && response.data.message === 'Success!') {
        this.setState({
          restrictionUserFlag: response.data.body
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  showShareMembers = () => {
    let showId = uuid();
    this.checkRestrictionUserFlag();
    this.setState({ isModalLoading: true, showId: showId }, () => {
      this.getUsersWhoShare(0, 24)
        .then((pagedData) => {
          this.setState({
            sharedUserPagedData: pagedData,
            isModalLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isModalLoading: false });
        });
      window
        .$(
          "#shareuser" +
          (this.props.slug ? this.props.slug : this.props.shareId) +
          showId
        )
        .modal("show");
    });
  };

  dismissModal = () => {
    this.setState({ sharedUserPagedData: {} });
    window
      .$(
        "#shareuser" +
        (this.props.slug ? this.props.slug : this.props.shareId) +
        this.state.showId
      )
      .modal("hide");
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (
      this.state.postPrivacy == "SELECTED" &&
      this.state.selectedList.length == 0
    ) {
      errors["privacyText"] = "Please Select at least one user";
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  commonCode = () => {
    let data = <></>;

    data = (
      <div>
        <div className="allsocialsharetop">
          {this.props.hideShareOnPortalBtn && (
            <a
              href="javascript:void(0)"
              style={{
                opacity: ".3",
                cursor: "default !important",
                pointerEvents: "none",
              }}
              className="socialico we"
            >
              <i className="iLogo_Fitted" />
            </a>
          )}

          {!this.props.hideShareOnPortalBtn && this.state.modalEntityId && (
            <div
              className="socialico m-0"
              style={{ margin: "0" }}
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <button
                href="javascript:void(0)"
                onClick={(e) => {
                  e.preventDefault();
                  this.openSharePost();
                }}
                className="socialico we"
              >
                <i className="iLogo_Fitted" />
              </button>
            </div>
          )}

          {this.state.copied && <span>Copied</span>}
          <div
            className="socialico copyLnk"
            data-tip="msg to show"
            data-for="registerTip"
            data-place="bottom"
          >
            <button
              id={
                "copyLnk" +
                (this.props.slug ? this.props.slug : this.props.shareId)
              }
              href="javascript:void(0)"
              className="socialico copyLnk"
            >
              <i
                className="iTxEdi_AddLink"
                onClick={(e) => {
                  e.preventDefault();
                  this.copyLink();
                }}
              />
            </button>
          </div>
          {!isMobile ? (
            this.props.absoluteShareUrl &&
              this.props.absoluteShareUrl !== "" ? (
              <div
                className="socialico"
                data-tip="msg to show"
                data-for="registerTip"
                data-place="bottom"
              >
                <a
                    href={
                      "mailto:?subject=Wenaturalist&body=" +
                      this.props.absoluteShareUrl
                    }
                    target="_blank"
                    className="socialico"
                  >
                    <i className="fa fa-envelope" />
                  </a>
              </div>
            ) : (
              <div
                className="socialico"
                data-tip="msg to show"
                data-for="registerTip"
                data-place="bottom"
              >
                {<a
                    href={
                      "mailto:?subject=Wenaturalist&body=" +
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                    target="_blank"
                    className="socialico"
                  >
                    <i className="fa fa-envelope" />
                  </a>}
              </div>
            )
          ) : (
            <RWebShare
              data={{
                url:
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId),
                title: "Share Via",
              }}
            >
              <button className="socialico btn">
                <i className="iMeatballs" />
              </button>
            </RWebShare>
          )}
        </div>
        {!isMobile ? (
          <div className="allsocialsharebott">
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <FacebookShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className="iSocial_FB" />
              </FacebookShareButton>
            </div>
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <TwitterShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className="iSocial_Twitter" />
              </TwitterShareButton>
            </div>
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <LinkedinShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className="iSocial_LinkedIn" />
              </LinkedinShareButton>
            </div>
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <WhatsappShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className="iSocial_WhatsApp" />
              </WhatsappShareButton>
            </div>
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <TumblrShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className="iSocial_Tumblr" />
              </TumblrShareButton>
            </div>
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <RedditShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className={"iReddit_Logo"} />
              </RedditShareButton>
            </div>
            <div
              className="socialico"
              data-tip="msg to show"
              data-for="registerTip"
              data-place="bottom"
            >
              <TelegramShareButton
                className="socialico"
                url={
                  process.env.REACT_APP_domainUrl +
                  this.props.shareUrl +
                  (this.props.slug ? this.props.slug : this.props.shareId)
                }
              >
                <i className={"iTelegram_Logo"} />
              </TelegramShareButton>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
    return data;
  };

  showUser = () => {
    window.location.href = "/post/" + this.state.postId;
  };

  fetchMoreUsersData() {
    let sharedUserPagedData = this.state.sharedUserPagedData;

    this.setState({ isUserResultLoading: true });
    let pageable = sharedUserPagedData.pageable;
    this.getUsersWhoLiked(pageable.pageNumber + 1, 24)
      .then((pagedData) => {
        if (
          this.state.sharedUserPagedData &&
          this.state.sharedUserPagedData.content
        ) {
          pagedData.content = this.state.sharedUserPagedData.content.concat(
            pagedData.content
          );
        }
        this.setState({
          sharedUserPagedData: pagedData,
          isUserResultLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isUserResultLoading: false });
      });
  }

  onClickEditPostAs = () => {
    this.setState({
      postAsFlag: true
    }, () => {
      window.$("#postFilterModal").modal("show");
    })
  }

  onCloseEditPostAs = () => {
    window.$("#postFilterModal").modal("show");
    this.setState({
      postAsFlag: false
    })
  }

  onSelectPostAsData = (data) => {
    this.setState({
      entityId: data.entityId,
      parentEntityId: data.parentEntityId,
      entityName: data.entityName,
      coverImage: data.coverImage,
      userId: data.userId,
      secondaryEntityName: data.secondaryEntityName,
      hiddenPrivacy: data.hiddenPrivacy,
      entityType: data.entityType,
      entityData: data
    })
    this.onCloseEditPostAs();
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.currentUserDetails)) {
      let currentUserDetails = nextProps.currentUserDetails;
      this.setState({
        userId: currentUserDetails.userId,
        entityName: currentUserDetails.name,
        entityType: currentUserDetails.type,
        hiddenPrivacy: currentUserDetails.type === "BUSINESS_PAGES" || currentUserDetails.type === "CIRCLE" ? true : false,
        coverImage: currentUserDetails.profileImage ? currentUserDetails.profileImage : defaultImageSection(currentUserDetails.type),
        currentUserDetails: nextProps.currentUserDetails,
        secondaryEntityName: currentUserDetails.type === "CIRCLE" ? "Community Activity" : "Feeds",
      })
    }
  }

  render() {
    let shareContent;
    const linkData = process.env.REACT_APP_domainUrl + this.props.shareUrl + (this.props.slug ? this.props.slug : this.props.shareId);
    if (this.props.shareOpen === "true") {
      shareContent = (
        <>
          <div className="likesblk" style={{ margin: "0" }}>
            <>
              <button
                href="javascript:void(0)"
                className="share"
                data-tip="Share"
                data-toggle="dropdown"
                aria-expanded="true"
              ></button>
              {this.state.sharepopDisplay && this.state.sharesCount ? (
                <a
                  href="javascript:void(0)"
                  onClick={(e) => {
                    this.showShareMembers();
                  }}
                >
                  {this.state.sharesCount} <span> Share</span>
                </a>
              ) : (
                <a href="javascript:void(0)" style={{ cursor: "default" }}>
                  <span> Share</span>
                </a>
              )}
            </>

            <div
              className="dropdown-menu fadeIn animated allsocialshare show"
              id="share-open"
            >
              <div className="allsocialsharetop">
                {this.props.hideShareOnPortalBtn && (
                  <a
                    href="javascript:void(0)"
                    style={{
                      opacity: ".3",
                      cursor: "default !important",
                      pointerEvents: "none",
                    }}
                    className="socialico we"
                  >
                    <i className="iLogo_Fitted" />
                  </a>
                )}
                {!this.props.hideShareOnPortalBtn && this.state.modalEntityId && (
                  <a
                    href="javascript:void(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      this.openSharePost();
                    }}
                    className="socialico we"
                  >
                    <i className="iLogo_Fitted" />
                  </a>
                )}
                {this.state.copied && <span>Copied</span>}
                <a
                  id={
                    "copyLnk" +
                    (this.props.slug ? this.props.slug : this.props.shareId)
                  }
                  href="javascript:void(0)"
                  className="socialico copyLnk"
                >
                  <i className="iTxEdi_AddLink" />
                </a>
                {!isMobile ? (
                  this.props.absoluteShareUrl &&
                    this.props.absoluteShareUrl !== "" ? (
                    <a
                      href={
                        "mailto:?subject=Wenaturalist&body=" +
                        this.props.absoluteShareUrl
                      }
                      target="_blank"
                      className="socialico"
                    >
                      <i className="fa fa-envelope" />
                    </a>
                  ) : (
                    <a
                      href={
                        "mailto:?subject=Wenaturalist&body=" +
                        process.env.REACT_APP_domainUrl +
                        this.props.shareUrl +
                        (this.props.slug ? this.props.slug : this.props.shareId)
                      }
                      target="_blank"
                      className="socialico"
                    >
                      <i className="fa fa-envelope" />
                    </a>
                  )
                ) : (
                  <RWebShare
                    data={{
                      url:
                        process.env.REACT_APP_domainUrl +
                        this.props.shareUrl +
                        (this.props.slug
                          ? this.props.slug
                          : this.props.shareId),
                      title: "Share Via",
                    }}
                  >
                    <button className="socialico btn">
                      <i className="iMeatballs" />
                    </button>
                  </RWebShare>
                )}
              </div>
              {!isMobile ? (
                <div className="allsocialsharebott">
                  <FacebookShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className="iSocial_FB" />
                  </FacebookShareButton>
                  <TwitterShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className="iSocial_Twitter" />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className="iSocial_LinkedIn" />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className="iSocial_WhatsApp" />
                  </WhatsappShareButton>
                  <TumblrShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className="iSocial_Tumblr" />
                  </TumblrShareButton>
                  <RedditShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className={"iReddit_Logo"} />
                  </RedditShareButton>
                  <TelegramShareButton
                    className="socialico"
                    url={
                      process.env.REACT_APP_domainUrl +
                      this.props.shareUrl +
                      (this.props.slug ? this.props.slug : this.props.shareId)
                    }
                  >
                    <i className={"iTelegram_Logo"} />
                  </TelegramShareButton>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    } else {
      shareContent = (
        <>
          {!this.props.isContextMenuInThreeDots ? (
            <div className="likesblk" style={{ margin: "0" }}>
              {<>
                  <button
                    className="share"
                    data-toggle="dropdown"
                    aria-expanded="true"
                  ></button>
                  {this.state.sharepopDisplay && this.state.sharesCount ? (
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => {
                        this.showShareMembers();
                      }}
                    >
                      {this.state.sharesCount} <span> Share</span>
                    </a>
                  ) : (
                    <a href="javascript:void(0)" style={{ cursor: "default" }}>
                      <span> Share</span>
                    </a>
                  )}
                </>}

              <div
                className="dropdown-menu fadeIn animated allsocialshare"
                id="share-open"
              >
                {this.commonCode()}
              </div>

              <div
                id={
                  "shareuser" +
                  (this.props.slug ? this.props.slug : this.props.shareId) +
                  this.state.showId
                }
                className="modal fade dgpopup reqendorse shareModal"
                tabIndex="-1"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      {this.state.isModalLoading ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Loader
                            type="Grid"
                            color="#dcde63"
                            height={60}
                            width={60}
                            timeout={8000}
                          />
                        </div>
                      ) : (
                        <div
                          className="similarAllpopWrap forwcontwrap"
                          style={{ maxWidth: "424px" }}
                        >
                          <div>
                            <div className="similarAllpopHeader">
                              <h2>Member Share</h2>
                            </div>
                            <div className={"similarAllpopBody"}>
                              <Scrollbars
                                className={"forwcontlistmain"}
                                autoHide
                                autoHeight
                                autoHeightMin={0}
                                autoHeightMax={322}
                                universal={true}
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                              >
                                <div
                                  ref={(ref) => (this.scrollParentRef = ref)}
                                >
                                  {this.state.sharedUserPagedData.content &&
                                    this.state.sharedUserPagedData.content
                                      .length > 0 && (
                                      <InfiniteScroll
                                        element="ul"
                                        loadMore={this.fetchMoreUsersData.bind(
                                          this
                                        )}
                                        threshold={this.state.thresholdHeight}
                                        useWindow={false}
                                        getScrollParent={() =>
                                          this.scrollParentRef
                                        }
                                        hasMore={
                                          !this.state.isUserResultLoading &&
                                          !this.state.sharedUserPagedData.last
                                        }
                                        loader={
                                          <span
                                            className="login-success-error"
                                            style={{ textAlign: "center" }}
                                          >
                                            Loading...
                                          </span>
                                        }
                                      >
                                        {this.state.sharedUserPagedData.content.map(
                                          (item) => {
                                            return (
                                              <LikeMemberItem
                                                item={item}
                                                key={item.userId}
                                                share={true}
                                              />
                                            );
                                          }
                                        )}
                                      </InfiniteScroll>
                                    )}
                                </div>
                              </Scrollbars>
                            </div>
                          </div>

                          <div className="forwcontwrapbott">
                            {this.state.restrictionUserFlag && <div className="itemscount">Some shared posts are not displayed because of their visibility settings.</div>}
                            <button
                              className="butt btnsecondary close"
                              onClick={this.dismissModal}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="allsocialshare" id="share-open">
              <h5>Share</h5>
              {this.commonCode()}
            </div>
          )}
        </>
      );
    }
    return (
      <div className="sharedrop">
        {shareContent}
        {!this.props.hideShareOnPortalBtn && (
          <>
            <div
              id={"toppostwrap" + this.state.modalEntityId}
              className="modal fade dgpopup openPostbox"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    {
                      <section className="toppostwrap">
                        <figure className="userimg">
                          <img src={this.state.coverImage} alt="" />
                        </figure>
                        <div className="toppostinn">
                          <div className="topposthead">
                            <div className="dropdown">
                              You are Sharing as{" "}
                              <span className="valueBox">{(this.state.entityType?.toLowerCase() !== "individual") ? this.state.entityName : convertUnicode(this.state.entityName)}</span>
                            </div>
                            <div className="dropdown last">
                              in{" "}
                              <span className="valueBox" style={{ cursor: 'text' }}>{convertUnicode(this.state.secondaryEntityName)}</span>
                              {this.state.isShowEditOption &&
                                <button type="button" className="editbutt butt" onClick={this.onClickEditPostAs}>
                                  <i className="iEditBox" />
                                </button>
                              }
                              <a className="bkqstn" href="/post-help" target="_blank" title="Help">
                                <i className="iQuestion" />
                              </a>
                            </div>
                          </div>
                          <div className="toppostpopbody">
                            <div className="toppostpoptop">
                              <div className="tpbfieldlogo">
                                <img
                                  src="https://cdn.dscovr.com/images/quotes-logo.webp"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://cdn.dscovr.com/images/quotes-logo.png";
                                  }}
                                  alt=""
                                />
                              </div>
                              <div className="tpbfield">
                                <form action="">
                                  <MentionTextArea
                                    value={this.state.postText}
                                    onChange={this.handlePostTextUpdate}
                                    placeholder="Write here..."
                                  ></MentionTextArea>
                                  {this.state.smileDisplay && (
                                    <Picker
                                      pickerStyle={{
                                        width: "100%",
                                        height: "200px",
                                      }}
                                      onEmojiClick={this.onEmojiClick}
                                    />
                                  )}
                                  {linkData &&
                                    <div className="linkpostpreview">
                                      <div className="prevtitle">Preview</div>
                                      <div className="postarticledesc">
                                        {!_.isEmpty(this.state.og_data) &&
                                          <CommonLinkPreview linkdata={linkData} og_data={this.state.og_data} />
                                        }
                                      </div>
                                    </div>
                                  }
                                </form>
                              </div>
                            </div>
                            <div className="toppostpopbott">
                              <div className="tagfield">
                                <AutoSuggest
                                  hashTags={this.state.hashTags}
                                  onDelete={this.onDelete}
                                  onAddition={this.onAddition}
                                  handleTagsChange={this.handleTagsChange}
                                />

                                {/*<TagsInput value={this.state.hashTags}
                                                                       inputProps={{
                                                                           id: 'colon',
                                                                           placeholder: 'Add Hashtags'
                                                                       }}
                                                                       ref={'TagsRef'}
                                                                       onChange={this.handleTagsChange}
                                                                       addKeys={[9, 13, 32, 62, 188]}
                                                            />*/}
                                {/* <span className="khastginfo">Press enter after each # tag</span> */}
                              </div>
                              <div className="toppostpopfeatures">
                                <a
                                  href="javascript:void(0)"
                                  className="attchbtn"
                                  onClick={this.handleSmileDisplay}
                                >
                                  <i className="iEmoji" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="bottpostpopbody">
                            {this.state.userData &&
                              this.state.userData.type !== "COMPANY" &&
                              this.state.hiddenPrivacy === false && (
                                <div className="contxmenu">
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="iPrivacyLock" />
                                    {this.state.postPrivacy === "ANYONE"
                                      ? "Anyone"
                                      : this.state.postPrivacy === "CONNECTED"
                                        ? "Connected Only"
                                        : "Selected Only"}
                                  </button>
                                  <div
                                    className="dropdown-menu fadeIn animated"
                                    onChange={this.changePostPrivacy}
                                  >
                                    <label className="radio-container">
                                      Anyone
                                      <input
                                        type="radio"
                                        value="ANYONE"
                                        onClick={this.handleCheckedPrivacy}
                                        checked={
                                          this.state.postPrivacy === "ANYONE"
                                        }
                                      />
                                      <span className="radio-checkmark" />
                                    </label>
                                    <label className="radio-container">
                                      Connected Only
                                      <input
                                        type="radio"
                                        value="CONNECTED"
                                        onClick={this.handleCheckedPrivacy}
                                        checked={
                                          this.state.postPrivacy === "CONNECTED"
                                        }
                                      />
                                      <span className="radio-checkmark" />
                                    </label>
                                    <label className="radio-container">
                                      Selected Only
                                      <input
                                        type="radio"
                                        value="SELECTED"
                                        onClick={this.handleSelectedOnly}
                                        checked={
                                          this.state.postPrivacy === "SELECTED"
                                        }
                                      />
                                      <span className="radio-checkmark" />
                                    </label>
                                  </div>
                                </div>
                              )}

                            {this.state.error && this.state.error !== "" && (
                              <span className="login-success-error">
                                {this.state.error}
                              </span>
                            )}

                            <div className="bottpostpopright">
                              {this.state.errors &&
                                this.state.errors.privacyText && (
                                  <span
                                    className="login-feedback-error mt-1"
                                    style={{
                                      display: "block",
                                      width: "auto",
                                    }}
                                  >
                                    {this.state.errors.privacyText}
                                  </span>
                                )}
                              {this.state.errors &&
                                this.state.errors.connectionText && (
                                  <span
                                    className="login-feedback-error  post-error-tooltip"
                                    style={{
                                      display: "block",
                                      width: "auto",
                                    }}
                                  >
                                    {this.state.errors.connectionText}
                                  </span>
                                )}

                              <a
                                href="javascript:void(0)"
                                className="btn cancel"
                                onClick={this.hideModal}
                              >
                                Cancel
                              </a>
                              <input
                                type="submit"
                                onClick={this.handlePostSubmit}
                                className="btn"
                                value={
                                  this.state.isPostSubmitted
                                    ? "Loading..."
                                    : "POST"
                                }
                                disabled={this.state.isPostSubmitted}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    }
                  </div>
                </div>
              </div>
            </div>

            {this.state.postAsFlag && <CommonPostAsPopUp onCloseEditPostAs={this.onCloseEditPostAs} onSelectPostAsData={this.onSelectPostAsData} entityData={this.state.entityData} isShare={true} secondaryEntityName={this.state.secondaryEntityName} entityName={this.state.entityName} userId={this.state.userId} entityType={this.state.entityType} />}

            <div
              className="modal fade dgpopup confirmpop"
              id={"AcceptShare" + this.state.modalEntityId}
              tabIndex="-1"
              role="dialog"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <button
                    type="button"
                    onClick={this.hideModal}
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="confirm-popup-modal-body">
                    Your post is successfully shared.
                  </div>
                  <div className="modal-footer confirm-popup-modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.showUser();
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              id={"postSelectUserModal" + this.state.modalEntityId}
              className="chatlistpop modal fade dgpopup"
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    {this.state.isModalLoading ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "fixed",
                          top: 0,
                          left: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Loader
                          type="Grid"
                          color="#dcde63"
                          height={60}
                          width={60}
                          timeout={8000}
                        />
                      </div>
                    ) : (
                      <div className="forwcontwrap">
                        <div className="forwcontwraptop">
                          <h4>Select Users</h4>
                          <form
                            action=""
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type Connect Name"
                              id="searchGroupByChars"
                              onKeyUp={(evt) =>
                                this.keyPressedBySearchGroupByChars(evt)
                              }
                            />
                            <button
                              className="btn btnsearch"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <i className="fa fa-search" aria-hidden="true" />
                            </button>
                          </form>

                          {this.state.selectedList &&
                            this.state.selectedList.length > 0 ? (
                            <HorizontalScroll reverseScroll>
                              <div className="srchlist">
                                {this.state.selectedList &&
                                  this.state.selectedList.map((data, index) => {
                                    return (
                                      <div key={index} className="srchlistblk">
                                        <figure className="img">
                                          <img
                                            src={
                                              data.personalInfo.profileImage !=
                                                null
                                                ? getCDNUrl(
                                                  data &&
                                                  data.personalInfo &&
                                                  data.personalInfo
                                                    .profileImage
                                                )
                                                : "https://cdn.dscovr.com/images/users1.png"
                                            }
                                            alt=""
                                          />
                                        </figure>
                                        <span>
                                          {data.firstName} {data.lastName}
                                          {data.companyName}
                                        </span>
                                        {index !==
                                          this.state.userData.userId && (
                                            <i
                                              className="iCross"
                                              onClick={(e) =>
                                                this.removeUserToGroup(e, data)
                                              }
                                            />
                                          )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </HorizontalScroll>
                          ) : null}
                          <Scrollbars
                            className={"forwcontlistmain contactlist"}
                            autoHide
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={322}
                            universal={true}
                            renderTrackHorizontal={(props) => (
                              <div {...props} className="track-horizontal" />
                            )}
                            renderTrackVertical={(props) => (
                              <div {...props} className="track-vertical" />
                            )}
                          >
                            <ul>
                              {this.state.userList &&
                                this.state.userList
                                  .filter(
                                    (data) =>
                                      data.id !== this.state.userData.userId &&
                                      (this.state.searchUserByChars === "" ||
                                        (this.state.searchUserByChars &&
                                          data.firstName &&
                                          data.firstName
                                            .toLowerCase()
                                            .includes(
                                              this.state.searchUserByChars.toLowerCase()
                                            )) ||
                                        (this.state.searchUserByChars &&
                                          data.lastName &&
                                          data.lastName
                                            .toLowerCase()
                                            .includes(
                                              this.state.searchUserByChars.toLowerCase()
                                            )))
                                  )
                                  .map((data, index) => {
                                    return (
                                      <li key={index}>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={(e) =>
                                            this.addUserToGroup(e, data)
                                          }
                                        >
                                          <figure className="users">
                                            <img
                                              src={
                                                data.personalInfo
                                                  .profileImage != null
                                                  ? getCDNUrl(
                                                    data &&
                                                    data.personalInfo &&
                                                    data.personalInfo
                                                      .profileImage
                                                  )
                                                  : "https://cdn.dscovr.com/images/users1.png"
                                              }
                                              alt=""
                                            />
                                          </figure>
                                          <div>
                                            <div className="name">
                                              {data.firstName} {data.lastName}
                                              {data.companyName}{" "}
                                            </div>
                                          </div>
                                        </a>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </Scrollbars>
                        </div>

                        <div className="forwcontwrapbott">
                          <div className="itemscount">
                            {this.state.selectedList.length} Selected
                          </div>
                          <div>
                            <button
                              className="butt btnsecondary close"
                              id="forwardMessageModalCloseBtn"
                              onClick={this.clearUsers.bind(this)}
                            >
                              Close
                            </button>
                            <button
                              disabled={this.state.selectedList.length === 0}
                              className="btn"
                              onClick={this.handleUsersSelectSubmit}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestPostCreationFulfilled: (value) =>
      dispatch(requestPostCreationFulfilled(value)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Share));
