import {call, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import * as _ from "lodash";
import * as api from "../api/projectHomePageAPI.js";
import {
    loadCompanyList,
    loadEntityList,
    SET_COMPANY_LIST,
    SET_ENTITY_LIST,
    SET_PROJECT_DETAILS,
    setProjectDetailsOnSuccess,
} from "../reducer/showAllReducer.js";

import httpService from '../AxiosInterceptors';
import { userAgentGetData } from "../commons/CommonFunctions.js";
import {authService} from "../auth";

httpService.setupInterceptors();

/*function companyApi(action) {
    let mode = action.type;
    let userId = action.userId;
    let url =
        process.env.REACT_APP_userServiceURL +
        `/backend/${mode}/companies/${userId}`;

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });*/

function companyApi(action) {
    let userId = action.userId;
    let url =
        process.env.REACT_APP_userServiceURL +
        '/profile/get/private/business/pages?userId='+userId+userAgentGetData()+'&privateEntityId='+authService.getUserDetailsFromLocalStorage().entityId;

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });

}

function entityApi(action) {
    let userId = action.userId;
    let url =
        process.env.REACT_APP_userServiceURL +
        '/UserRelated/get-entities?userId='+userId+'&privateEntityId='+authService.getUserDetailsFromLocalStorage().entityId;

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });

}

/** saga worker that is responsible for the side effects */
function* loadCompany(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(companyApi, action.payload);

        if (
            response &&
            response.data &&
            response.data.body &&
            !_.isNull(response.data.body)
        ) {
            yield put(loadCompanyList(response.data.body));
        } else {
            console.log(response);
        }

        //   // store data to localStorage
        //   Object.keys(data.session).forEach(key, => {
        //     localStorage.setItem(key, data[key]);
        //   });
        //   // dispatch action to change redux state
        //   yield put(updateProfile(data.profile));
        //   // redirect to home route after successful login
        //   browserHistory.push('/home');
    } catch (e) {
        // catch error on a bad axios call
        // alert using an alert library
        console.log(e);
    }
}

function* loadEntity(action) {
    try {
        let response = yield call(entityApi, action.payload);

        if (
            response &&
            response.data &&
            response.data.body &&
            !_.isNull(response.data.body)
        ) {
            yield put(loadEntityList(response.data.body));
        } else {
            console.log(response);
        }

    } catch (e) {
        console.log(e);
    }
}

function* loadProjectDescription(action) {
    let response = yield call(api.fetchProjectDescription, action.payload.projectId || action.payload.id);
    if (
        response &&
        !_.isNull(response)
    ) {
        yield put(setProjectDetailsOnSuccess(response));
    } else {
        console.log(response);
    }
    //setProjectDetailsOnSuccess
}

export default function* watchCompanyLoad() {
    yield takeLatest(SET_COMPANY_LIST, loadCompany);
}

export function* watchProjectDescription() {
    yield takeLatest(SET_PROJECT_DETAILS, loadProjectDescription);
}

export function* watchEntityList() {
    yield takeLatest(SET_ENTITY_LIST, loadEntity);
}
