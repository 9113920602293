import React, { memo, useEffect, useState } from "react";
import { authService } from "../../auth";
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";
import ExploreTopBar from '../ExploreTopBar';
import axios from "axios";
import IndividualHashTagsCategory from './IndividualHashTagsCategory'
import InfiniteScroll from "react-infinite-scroller";
import { userAgentGetData } from "../../commons/CommonFunctions";
import NotFound from "../../404";
import CommonHashTagsDocuments from "./Common/CommonHashTagsDocuments";
import { getHasgtagsStatus, getUserWiseHashTagsDetails } from "../../services/HashTagsApi";
import { getUserByCustomUrl } from "../../services/UserApi";

const AllIndividualHashTagsDocuments = (props) => {

    const [userData, setUserData] = useState(authService.getUserDetailsFromLocalStorage());
    const [tagName, setTagName] = useState('');
    const [hashTagsDocuments, setHashTagsDocuments] = useState([]);
    const [hashTagsDocumentsPageNo, setHashTagsDocumentsPageNo] = useState(0)
    const [documentSuccess, setDocumentSuccess] = useState(false)
    const [thresholdHeight, setThresholdHeight] = useState(1000);
    const [customUrl, setCustomUrl] = useState('');
    const [userDetails, setUserDetails] = useState('');
    const [pageNotExist, setPageNotExist] = useState(false);

    useEffect(() => {
        tagNameValue();
        customUrlValue();
        getHashtagsDetails()
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }, []);

   
    const tagNameValue = () => {
        setTagName(props.match.params.slug);
    }

    const customUrlValue = () => {
        setCustomUrl(props.match.params.customurl);
    }

    const getUserDetailsByCustomUrl = async () => {
        let response = await getUserByCustomUrl({"customUrl": props.match.params.customurl, "otherUserId": userData?.userId});
        if(response?.message === "Success!") {
          setUserDetails(response.body);
          hashTagsDocument(response.body.userId, 0, 24, false);
        }
    }

    let cancelToken;
    const hashTagsDocument = async (userid, page, limit, concatWithPrevious) => {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source()

        setDocumentSuccess(false)
        let response = await getUserWiseHashTagsDetails({"userId": userid, "otherUserId": userData.userId, "page": page, "size": limit, "hashTag": props.match.params.slug, "category": "POST", "newsFeedType": "DOCUMENT"});
        if(response?.message === "Success!") {
            let pageData = response.body
            if (concatWithPrevious && hashTagsDocuments && hashTagsDocuments.content) {
                pageData.content = hashTagsDocuments.content.concat(pageData.content)
            }
            setHashTagsDocuments(pageData)
            setDocumentSuccess(false)
        } else {
            setDocumentSuccess(false)
        }
    }

    const loadmoreDocuments = () => {
        if (hashTagsDocuments && hashTagsDocuments.content) {
            setDocumentSuccess(true)
            let pageable = hashTagsDocuments.page.pageable;
            setHashTagsDocumentsPageNo(pageable.pageNumber + 1)
            hashTagsDocument(userDetails.userId, pageable.pageNumber + 1, 24, true)
        }
    }

    const getHashtagsDetails = async () => {
        let postData= {
            hashtag: props.match.params.slug
        }
        let response = await getHasgtagsStatus(postData);
        if(response?.status === 200) {
            getUserDetailsByCustomUrl();
        }
        if(response?.status === 410) {
            setPageNotExist(true)
        }
    }

    return (
        <div className="wrapper projectpage explorepage">
            <Header />
            <main className="dgcontainer hm3col">
                <div className="container">
                    {pageNotExist ? 
                        <NotFound /> :
                        <div className="row">
                            <LeftPanel />
                            <div className="col-md-9">
                                <div className={"projectinn explore trending exploreExtra" + (userDetails.type === "COMPANY" ? ' orgnzHash' : '')}>
                                    <ExploreTopBar tagName={"Documents"} isIndividualHashtag={true} key={userDetails} userDetails={userDetails} />
                                    <IndividualHashTagsCategory tagName={tagName} tagType="documents" customUrl={customUrl} profileInfo={userDetails} />

                                    <div className="dailyeditionblk explLatarticle trndBlgs alltrndBlgs expladventwrap trnd others" style={{paddingTop: '0px'}}>
                                        {hashTagsDocuments && hashTagsDocuments.page && (
                                            <InfiniteScroll
                                                loadMore={loadmoreDocuments.bind(this)}
                                                hasMore={!documentSuccess && !hashTagsDocuments.page.last}
                                                threshold={thresholdHeight}
                                            >
                                                <div className="row all">
                                                    {hashTagsDocuments &&
                                                        hashTagsDocuments.content &&
                                                        hashTagsDocuments.content.map((item, index) => {
                                                            return (
                                                                <div className="col-md-4">
                                                                   <CommonHashTagsDocuments item={item} key={item.id} />
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </InfiniteScroll>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </div>
    )
}

export default memo(AllIndividualHashTagsDocuments);