
import axios from "axios";

import httpService from '../../AxiosInterceptors';
import { userAgentGetData } from "../../commons/CommonFunctions";
import {authService} from "../../auth";
import { getUserByUserId } from "../../services/UserApi";

httpService.setupInterceptors();



export const fetchProfileData = async function (data) {
    let response = await getUserByUserId({"id": data.userId, "otherUserId": data.otherUserId});
    if(response?.message === "Success!") {
        return response?.body;
    } else {
        return {}
    }
};

export const fetchContactInfo = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/contact/info?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchPersonalProfileAddressData = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/address?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchPersonalProfileBioData = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/bio?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchPersonalProfileSkillsData = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/skills?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchPersonalProfileSkillsAndSpecializationData = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/skills-and-specialization?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchPersonalProfileSpecialtiesData = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/specialities?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchPersonalProfileEducation = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/education?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200 ) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchPersonalProfileExperience = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/experience?id=' + data.userId+'&otherUserId='+data.otherUserId+ '&privateEntityId=' + authService.getUserDetailsFromLocalStorage()?.entityId +"&page=" + 0 + "&size=" + 50 + userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200 && response.data.body) {
            return response.data.body.experience.content;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchPersonalProfileHashTags = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/post/getUserHashtags?userId=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        }
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchPersonalProfileNotJoinedCauses = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/cause/unjoined/list?userId=' + data.userId+'&otherUserId='+data.otherUserId+'&otherUserId='+data.otherUserId,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchPersonalProfileJoinedCauses = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/cause/joined/list?userId=' + data.userId+'&otherUserId='+data.otherUserId + "&page=" + 0 + "&size=" + 20,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body.causeList;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

//not done for user service
export const fetchPersonalProfileRecentActivity = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/activity/list?userId=' + data.userId +'&requestingUserId='+authService.getUserDetailsFromLocalStorage().userId + "&page=" + 0 +"&privateEntityId="+data.entityId+ "&size=" + 30,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body.content;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};

export const fetchPersonalProfileBusinessPages = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/private/business/pages?userId=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData()+'&privateEntityId='+authService.getUserDetailsFromLocalStorage().entityId,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
export const fetchPersonalProfileInterestsData = function (data) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/profile/get/interests?id=' + data.userId+'&otherUserId='+data.otherUserId+userAgentGetData(),
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};


export const fetchProfileBasicInfoData = function (data) {
    return axios({
        method: 'get',
        url: `${process.env.REACT_APP_userServiceURL}/profile/basic-info?userId=${data.userId}`,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
