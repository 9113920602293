import React, { Component, memo } from "react";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { authService } from "../auth";
import { additionalDaysTopUpForEntity, buttonShowDetails, getFormattedDate, userSubscriptionError } from "./DonationCommonFunction";
import DonationViewDonorsPopUp from "./DonationViewDonorsPopUp";
import Select from "react-select";
import uuid from "react-uuid";
import addDays from 'date-fns/addDays';
import * as _ from "lodash";
import { convertCurrencySystem, nFormatter } from "../commons/CommonFunctions";
import DonationTopUp from "./DonationTopUp";
import DonationTopUpPayment from "./DonationTopUpPayment";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Redirect } from "react-router-dom";

const ErorrMsgPopUp = withReactContent(alertPopUp);
class DonationStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            donatedStatsDataList: [],
            popUpData: {},
            viewDonorFlag: false,
            selectedStatsData: {},
            userBankDetailsData: [],
            entityConfigData: {},
            donationTopUpModal: false,
            donationPaymentModal: false,
            donationTopUpData: {},
            additionalDaysTopUpList: [],
            subscriptionPageRedirect: false
        }
    }

    getDonationStatsData = () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/donation/get/stats?userId=${this.state.userData.userId}&entityId=${this.props.entityId}&entityType=${this.props.entityType}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let donatedStatsDataList = [];
                let responseData = response.data.body;
                responseData && responseData.length > 0 && responseData.map((item) => {
                    item.displayRange = false;
                    item.displayBank = false;
                    item.bankDetail = { "institutionName": item.bankDetail?.nickName ? item.bankDetail?.nickName : item.bankDetail?.institutionName, "id": item.bankDetail?.id };
                    item.selectedBankData = { "label": item.bankDetail?.nickName ? item.bankDetail?.nickName : item.bankDetail?.institutionName, "value": item.bankDetail?.id };
                    donatedStatsDataList.push(item);
                })
                this.setState({
                    donatedStatsDataList: donatedStatsDataList
                }, () => {
                    let totalDonorsCount = this.state.donatedStatsDataList && this.state.donatedStatsDataList.map(item => item.donatedCount).reduce((prev, curr) => prev + curr, 0);
                    this.props.getDonorsCount(totalDonorsCount);
                    this.getBankDetailsDataDetails();
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    updateStatsDate = (statsData) => {
        let postData = {
            userId: this.state.userData.userId,
            entityId: this.props.entityId,
            entityType: this.props.entityType,
            id: statsData.donationId,
            startTime: statsData.startTime,
            endTime: statsData.endTime
        }
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/update/date`,
            headers: { "Content-Type": "application/json" },
            data: postData,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.displayRangeSelect(statsData.donationId, false);
            }
        }).catch((err) => {
            console.log(err);
            if(err.response.data?.status === "409 CONFLICT") {
                userSubscriptionError("DATE_UPDATE");
            }
        });
    }

    onDateChange = (date, entity) => {
        let donatedStatsDataList = this.state.donatedStatsDataList;
        let index = donatedStatsDataList && donatedStatsDataList.findIndex(data => data.donationId === entity.donationId);
        if (index > -1) {
            donatedStatsDataList[index].startTime = date.getTime();
            donatedStatsDataList[index].endTime = addDays(date, entity.timeFrame).getTime();
        }
        this.setState({
            donatedStatsDataList: donatedStatsDataList,
            selectedStatsData: entity
        });
    };

    displayRangeSelect = (id, flag) => {
        if(flag) {
            this.getEntityConfig();
        }
        let donatedStatsDataList = this.state.donatedStatsDataList;
        let index = donatedStatsDataList && donatedStatsDataList.findIndex(data => data.donationId === id);
        if (index > -1) {
            donatedStatsDataList[index].displayRange = flag;
            if (!flag &&  !_.isEmpty(this.state.selectedStatsData)) {
                donatedStatsDataList[index].startTime = this.state.selectedStatsData?.startTime;
                donatedStatsDataList[index].endTime = addDays(this.state.selectedStatsData?.startTime, this.state.selectedStatsData?.timeFrame).getTime();
            }
        }
        this.setState({
            donatedStatsDataList: donatedStatsDataList
        });
    };

    onClickDonationViewDonorPopUp = (id, type) => {
        this.setState({
            popUpData: { "modalType": type, "id": id, "entityType": this.props.entityType, "entityId": this.props.entityId, "entitySlug": this.props.entitySlug},
            viewDonorFlag: true
        }, () => {
            window.$("#donationViewDonorsPopUp").modal("show");
        })
    }

    onCloseDonationViewDonorPopUp = () => {
        window.$("#donationViewDonorsPopUp").modal("hide");
        this.setState({
            viewDonorFlag: false
        })
    }

    getBankDetailsDataDetails = () => {
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/getUserBankDetails?userId=${this.state.userData.userId}&metaDataRequired=false&privateEntityId=${this.state.userData.entityId}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.message === "Success!") {
                let data = response.data.body;
                let userBankData = [];
                data && data.map((item) => { userBankData.push({ "value": item.id, "label": item.institutionName }) })
                this.setState({
                    userBankDetailsData: userBankData
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    displayBankSection = (id, flag, bankData) => {
        let donatedStatsDataList = this.state.donatedStatsDataList;
        let index = donatedStatsDataList && donatedStatsDataList.findIndex(data => data.donationId === id);
        if (index > -1) {
            donatedStatsDataList[index].displayBank = flag;
            if (!flag && bankData) {
                donatedStatsDataList[index].selectedBankData = bankData;
                donatedStatsDataList[index].bankDetail = { "institutionName": bankData.label, "id": bankData.id };
            }
        }
        this.setState({
            donatedStatsDataList: donatedStatsDataList
        })
    }

    onChangeBankDetails = (data, bankdata) => {
        let postData = {
            userId: this.state.userData.userId,
            entityId: this.props.entityId,
            entityType: this.props.entityType,
            bankId: bankdata.value,
            id: data.donationId
        }
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/update/bank`,
            headers: { "Content-Type": "application/json" },
            data: postData,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.displayBankSection(data.donationId, false, bankdata);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getEntityConfig = async (statsData) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/user/subscription/donation/entity/config?userId=${this.state.userData.userId}&entityId=${this.props.entityId}&entityType=${this.props.entityType}&privateEntityId=${this.state.userData?.entityId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                this.setState({
                    entityConfigData: response.data.body,  
                }, () => {
                    if(!this.state.entityConfigData.additionalTopUpAvailable && this.state.entityConfigData?.currentPlan === "BRONZE") {
                        const additionalDaysTopUpConfirm = async () => {
                            const swalval = await ErorrMsgPopUp.fire(additionalDaysTopUpForEntity(this.props.entityType));
                            if (swalval && swalval.value === true) {
                                this.setState({
                                    subscriptionPageRedirect: true
                                })
                            }
                        };
                        additionalDaysTopUpConfirm();
                    } else {
                        if(statsData) {
                            this.onClickDonationTopup(statsData);
                        }
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onClickDonationTopup = (entity) => {
        entity.entityId = this.props.entityId;
        this.setState({
            donationTopUpData: entity, 
            donationTopUpModal: true
        }, () => {
            window.$("#donationTopUpPop").modal("show");
        })
    }

    onCloseDonationTopUp = () => {
        window.$("#donationTopUpPop").modal("hide");
        this.setState({
            donationTopUpData: {}, 
            donationTopUpModal: false
        })
    }

    onSuccessDonationTopUp = (daysData, id) => {
        window.$("#donationTopUpPop").modal("hide");
        let additionalDaysTopUpList = [{"entityName": this.props.entityName, "entityId": id, "startTime": this.state.donationTopUpData?.startTime, "additionalDaysTopUp": daysData}];
        this.setState({
            additionalDaysTopUpList: additionalDaysTopUpList,
            donationTopUpModal: false,
            donationPaymentModal: true
        }, () => {
            window.$("#donationTopUpPayment").modal("show");
        })
    }

    onCloseDonationPayment = () => {
        window.$("#donationTopUpPayment").modal("hide");
        this.setState({
            additionalDaysTopUpList: [],
            donationPaymentModal: false
        })
    }

    onSuccessDonationPayment = (entityList) => {
        window.$("#donationTopUpPayment").modal("hide");
        let donatedStatsDataList = this.state.donatedStatsDataList;
        entityList && entityList.map((entity) => {
            let index = donatedStatsDataList && donatedStatsDataList.findIndex(data => data.entityId === entity.entityId);
            if(index > -1) {
                donatedStatsDataList[index].startTime = entity.startTime;
                donatedStatsDataList[index].timeFrame = entity.timeFrame;
                donatedStatsDataList[index].endTime = entity.endTime;
            }
        })
        this.setState({
            donatedStatsDataList: donatedStatsDataList,
            additionalDaysTopUpList: [],
            donationPaymentModal: false
        })
    }

    componentDidMount() {
        this.getDonationStatsData();
    }

    render() {
        const { donatedStatsDataList, viewDonorFlag, popUpData, userBankDetailsData, entityConfigData, donationTopUpModal, donationTopUpData, donationPaymentModal, additionalDaysTopUpList, subscriptionPageRedirect } = this.state;
        const { entityType, donationConfig } = this.props;
        let currentTime = new Date().getTime();
        return (
            <>
                {subscriptionPageRedirect && <Redirect to={"/wenaturalists-pro"} />}
                {donatedStatsDataList && donatedStatsDataList.length > 0 && 
                    <div className="settMngPermsRow donationStatsWrap">
                        <h4>Fund Raise Stats</h4>
                        {donatedStatsDataList && donatedStatsDataList.map((statsData) => {
                            return (
                                <div className="donationStatsPrimaryRow">
                                    <div className="donatModuleInfo">
                                        <div className={!statsData.displayBank ? 'selectedBank': 'selectedBank openDrop'}>
                                            <label>Bank Name :</label>
                                            {!statsData.displayBank ?
                                                <h4><span>{statsData.bankDetail?.institutionName}</span> {((currentTime < statsData.endTime) && userBankDetailsData.length > 1) && donationConfig?.superAdminPermission && <a href="javascript:void(0)" className="edtbtn btn" onClick={(e) => this.displayBankSection(statsData.donationId, true)}><i className="iEditBox" /></a>}</h4> :
                                                <div className="d-flex align-items-center">
                                                    <Select
                                                        placeholder={"Select Bank"}
                                                        className="wnforSelect"
                                                        name={"userFilterOption"}
                                                        options={userBankDetailsData}
                                                        value={statsData.selectedBankData}
                                                        onChange={(e) => this.onChangeBankDetails(statsData, e)}
                                                        styles={{
                                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                const color = '#cfe7c7';
                                                                return {
                                                                    ...styles,
                                                                    backgroundColor: isDisabled
                                                                        ? null
                                                                        : isSelected
                                                                            ? '#cfe7c7'
                                                                            : isFocused
                                                                                ? '#cfe7c7'
                                                                                : null,
                                                                    color: isDisabled
                                                                        ? '#ccc'
                                                                        : isSelected
                                                                            ? color > 2
                                                                                ? '#00374d'
                                                                                : '#00374d'
                                                                            : data.color,
                                                                    cursor: isDisabled ? 'not-allowed' : 'default',

                                                                    ':active': {
                                                                        ...styles[':active'],
                                                                        backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                                    },
                                                                };
                                                            },
                                                        }}
                                                    />
                                                    <a href="javascript:void(0)" onClick={(e) => this.displayBankSection(statsData.donationId, false)} className="okBtn"><i className="iCross" /></a>
                                                </div>
                                            }
                                        </div>
                                        <div className='globalDT'>
                                            <div className={"dateVField" + (statsData.dateChangeable && donationConfig?.superAdminPermission ? " withEdit" : "")}>
                                                {getFormattedDate(statsData.startTime)} - {getFormattedDate(statsData.endTime)}
                                                {statsData.dateChangeable && donationConfig?.superAdminPermission && <button className="btn" onClick={() => this.displayRangeSelect(statsData.donationId, true)}><i className="iEditBox" /></button>}
                                                {/* {!statsData.dateChangeable && (currentTime < statsData.endTime) && (donationConfig.yetToStart || donationConfig.running) && <button className="btn" onClick={() => this.getEntityConfig(statsData)}><i className="iAddition" /></button>} */}
                                            </div>
                                            {statsData.displayRange && (
                                                <div className={entityConfigData && entityConfigData.additionalTopUpAvailable ? 'globalDTWrap withAddiTopup' : 'globalDTWrap'}>
                                                    <DatePicker
                                                        selected={statsData.startTime}
                                                        onChange={(date) => this.onDateChange(date, statsData)}
                                                        minDate={new Date()}
                                                        startDate={statsData.startTime}
                                                        endDate={statsData.endTime}
                                                        dropdownMode="select"
                                                        inline
                                                    />                                            
                                                    {/* {entityConfigData && entityConfigData.additionalTopUpAvailable && 
                                                        <div className='datePurchesInfo'>
                                                            <p>You can raise funds for {statsData.timeFrame} day{statsData.timeFrame > 1 && 's'} per {entityType === "CIRCLE" ? "community" : entityType.toLowerCase()} in your subscription plan.</p>
                                                            <p>TopUp to increase the number of days.
                                                                <button class="btn" onClick={(e) => this.onClickDonationTopup(statsData)}>Topup Now</button>
                                                            </p>
                                                        </div>
                                                    } */}
                                                    <div className='btnsBlk'>
                                                        <div className='ml-auto'>
                                                            <button type="button" className="btn" onClick={(e) => this.updateStatsDate(statsData)}>Apply</button>
                                                            <button type="button" className="btn" onClick={() => this.displayRangeSelect(statsData.donationId, false)}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="donationStatsRow">
                                        <div className="donationStatsCol">
                                            <button className="btn" onClick={(e) => statsData.viewsCount > 0 ? this.onClickDonationViewDonorPopUp(statsData.donationId, "VIEW") : e.preventDefault()}>
                                                <span>{nFormatter(statsData.viewsCount)}</span>
                                                <h5><i className="iEye_F" /> {statsData.viewsCount <= 1 ? 'View' : 'Views'}</h5>
                                            </button>
                                            {buttonShowDetails(statsData.donationId, "VIEW", entityType)}
                                        </div>
                                        <div className="donationStatsCol">
                                            <button className="btn" onClick={(e) => statsData.donatedCount > 0 ? this.onClickDonationViewDonorPopUp(statsData.donationId, "DONORS") : e.preventDefault()}>
                                                <span>{nFormatter(statsData.donatedCount)}</span>
                                                <h5><i className="iNetwork_F" /> {statsData.donatedCount <= 1 ? 'Donor' : 'Donors'}</h5>
                                            </button>
                                            {buttonShowDetails(statsData.donationId, "DONORS", entityType)}
                                        </div>
                                        <div className="donationStatsCol">
                                            <button className="btn" onClick={(e) => statsData.donatedAmount > 0 ? this.onClickDonationViewDonorPopUp(statsData.donationId, "DONORS_AMOUNT") : e.preventDefault()}>
                                                <span>{statsData.indian ? <>&#8377;</> : <>&#36;</>}{convertCurrencySystem(statsData.donatedAmount)}</span>
                                                <h5><i className="iWallet" /> Amount collected</h5>
                                            </button>
                                            {buttonShowDetails(statsData.donationId, "DONORS_AMOUNT", entityType)}
                                        </div>
                                    </div>
                                    <ReactTooltip
                                        className="rtoolTip donationInfoTexts"
                                        id={"donationInfoTexts" + statsData.donationId}
                                        place="top"
                                        type="dark"
                                        textColor="#00394D"
                                        effect="solid"
                                        backgroundColor="#ffffff"
                                        clickable
                                        globalEventOff="click"
                                        getContent={(dataTip) => <>{dataTip}</>} />
                                </div>
                            )
                        })}


                        {viewDonorFlag && <DonationViewDonorsPopUp popUpData={popUpData} onCloseDonationViewDonorPopUp={this.onCloseDonationViewDonorPopUp} key={uuid()} />}

                        {donationTopUpModal && <DonationTopUp key={uuid()} additionalDaysPlanList={entityConfigData?.additionalDaysPlan} isIndian={entityConfigData?.indian} donationTopUpData={donationTopUpData} donationType={entityType} onCloseDonationTopUp={this.onCloseDonationTopUp} onSuccessDonationTopUp={this.onSuccessDonationTopUp} />}

                        {donationPaymentModal && <DonationTopUpPayment key={uuid} additionalDaysPlanList={additionalDaysTopUpList} entityConfigData={entityConfigData} onCloseDonationPayment={this.onCloseDonationPayment} onSuccessDonationPayment={this.onSuccessDonationPayment} />}

                    </div>
                }
            </>
        );
    }
}

export default memo(DonationStats);
