import {call, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import * as _ from "lodash";
import {SET_DRAFTS, setDraftOnSuccess} from "../reducer/draftReducer.js";
import httpService from '../AxiosInterceptors';
import {authService} from "../auth";

httpService.setupInterceptors();

function draftApi(action) {
    let userData = authService.getUserDetailsFromLocalStorage();
    let mode = action.mode;
    let userId = action.userId;
    let searchText = action.searchText;
    let url =
        process.env.REACT_APP_userServiceURL +
        `/backend/drafts/search-draft-by-userId?userId=${userId}&privateEntityId=${userData.entityId}`;

    if (mode && !_.isNull(mode) && mode !== "all") {
        mode = mode.toLowerCase();
        url =
            process.env.REACT_APP_userServiceURL +
            `/drafts/find-project-draft-by-type-userId/${mode}/?userId=${userId}&privateEntityId=${userData.entityId}`;
    }

    if (searchText && searchText.length > 2) {
        url =
            process.env.REACT_APP_userServiceURL +
            "/backend/drafts/fulltext-search-draft-by-type-userId/" + mode + "/?userId=" + userId + "&searchValue=" + searchText+ "&privateEntityId="+userData.entityId;
    }

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });
}

/** saga worker that is responsible for the side effects */
function* loadDrafts(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(draftApi, action.payload);

        if (response &&
            response.data &&
            response.data.body &&
            !_.isNull(response.data.body) &&
            response.data.body.content &&
            !_.isNull(response.data.body.content)

        ) {
            yield put(setDraftOnSuccess(response.data.body.content));
        } else {
            console.log(response);
        }
    } catch (e) {
        console.log(e);
    }
}

export default function* watchDraftLoad() {
    yield takeEvery(SET_DRAFTS, loadDrafts);
}
