import React, {Component} from 'react';
import {renderToString} from "react-dom/server";
import Linkify from "react-linkify";
import stripHtml from "string-strip-html";
import {Link, Redirect} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {detailsPageRedirection, get720pTranscodedVideo, getCDNUrl, getDocumentCoverImage, isMobileDevice, videoThumbnailFunction} from "../../commons/CommonFunctions";
import Skeleton from "react-loading-skeleton";
import TimeAgo from "javascript-time-ago";
import httpService from "../../AxiosInterceptors";
import ReactHtmlParser from "react-html-parser";
import {authService} from "../../auth";
import axios from "axios";
import en from 'javascript-time-ago/locale/en';
import HideContent from "../HideContent";
import "../../explore/main-explore.min.css";
import { defaultProjectImage } from '../../project/component/Studio/StudioContentCommonFunction';

if (isMobileDevice()) {
    import("../../commons/styles/responsive-useragent.css").then(() => {
      console.log("Imported mobile css");
    });
}

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')
httpService.setupInterceptors();

var userId = null;

const userCustomUrl = authService.getUserDetailsFromLocalStorage()?.customUrl;

function matchHashTags(text) {
    if (!text || text.length === 0) {
        return text;
    }
    var string = text;
    var regex = /(#[a-z\d-_]+)/gi;
    var matches = string.matchAll(regex);
    for (var match of matches) {
        if (match && match.length > 1) {
            string = string.replace(match[0], '<span class="hposthashitem" ' +
                'style="font-family: Montserrat,sans-serif;font-weight: 500;font-size: 12px;line-height: 15px;color: #bfc52e;">' +
                '<a href="/profile-hashtag/'+match[0].substr(1)+ "/" + userCustomUrl + '"><i class="iHashtag"></i>' + match[0].substr(1) + '</a></span>');
        }
    }
    return string;
}

function transform(node, index) {
    if (node.type === 'text') {
        let modifiedString = matchHashTags(node.data)
        if (modifiedString !== node.data) {
            node.type = 'tag';
            node.name = 'div';
            return <>{ReactHtmlParser(modifiedString)}</>
        }
    }
}

const options = {
    decodeEntities: true,
    transform
};
class FeedsHidden extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            trendingArticle: [],
            trendingArticlePageNo: 0,
            trendingArticleViewMore: false,
            articleSuccess: false,
            photoList: [],
            photoListPageNo: 0,
            photoListViewMore: true,
            photoSuccess: false,
            otherSuccess:false,
            otherList:[],
            otherListPageNo:0,
            otherListViewMore:true,
            audioSuccess:false,
            audioList:[],
            audioListPageNo:0,
            audioListViewMore:true,
            videoList: [],
            videoListPageNo: 0,
            videoListViewMore: true,
            videoSuccess: false,
            documentList: [],
            documentListPageNo: 0,
            documentListViewMore: true,
            documentSuccess:false,
            showOnlyMyPost:false,
            redirectToProfile:'',
            userId: authService.getUserDetailsFromLocalStorage()?.userId
        }
        this.initFunction();
    }

    componentDidMount() {

        window.$('.collapser').click(function () {
            window.$(this).next().collapse('toggle');
        });

        window.$('a.dgscroll').click(function (e) {
            e.preventDefault();
            var target = window.$(window.$(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top - 200;
                window.$('body, html').animate({scrollTop: scrollTo + 'px'}, 500);
            }
        });

        var navPos = window.$('.draftglobalSticky').offset().top - 114;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.draftglobalSticky').addClass('is-sticky');
            } else {
                window.$('.draftglobalSticky').removeClass('is-sticky');
            }
        });          
    }

    initFunction() {
        window.$('.collapser').click(function () {
            window.$(this).next().collapse('toggle');
        });

        window.$('a.dgscroll').click(function (e) {
            e.preventDefault();
            var target = window.$(window.$(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top - 200;
                window.$('body, html').animate({scrollTop: scrollTo + 'px'}, 500);
            }
        });


            
        userId = this.state.userId;
        this.getTrendingArticle(this.state.trendingArticlePageNo,false);
        this.getPhotoList(this.state.photoListPageNo,false);
        this.getVideoList(this.state.videoListPageNo,false);
        this.getOtherItem(this.state.otherListPageNo,false);
        this.getAudioItem(this.state.audioListPageNo,false);
        this.getDocumentItem(this.state.documentListPageNo,false);
    }

    defaultFunction = () =>{
        this.setState({
            trendingArticlePageNo:0,
            photoListPageNo:0,
            videoListPageNo:0,
            otherListPageNo:0,
            audioListPageNo:0,
            documentListPageNo: 0
        },()=>{
            this.getTrendingArticle(this.state.trendingArticlePageNo,false);
            this.getPhotoList(this.state.photoListPageNo,false);
            this.getVideoList(this.state.videoListPageNo,false);
            this.getOtherItem(this.state.otherListPageNo,false);
            this.getAudioItem(this.state.audioListPageNo,false);
            this.getDocumentItem(this.state.documentListPageNo,false);
        })
    }

    viewMoreFunction(evt, type, paginationType) {
        evt.preventDefault();
        if (paginationType === undefined) {
            paginationType = 'next';
        }
        if (type === 'photo') {
            let pageNo = this.state.photoListPageNo + 1;
            this.setState({"photoListPageNo": pageNo});
            this.getPhotoList(pageNo,true);
        } else if (type === 'video') {
            let pageNo = this.state.videoListPageNo + 1;
            this.setState({"videoListPageNo": pageNo});
            this.getVideoList(pageNo,true);
        }else if (type === 'trendingArticle') {
            let pageNo = this.state.trendingArticlePageNo + 1;
            this.setState({"trendingArticlePageNo": pageNo});
            this.getTrendingArticle(pageNo,true);
        }else if (type === 'other') {
            let pageNo = this.state.otherListPageNo + 1;
            this.setState({"otherListPageNo": pageNo});
            this.getOtherItem(pageNo,true);
        }else if (type === 'audio') {
            let pageNo = this.state.audioListPageNo + 1;
            this.setState({"audioListPageNo": pageNo});
            this.getAudioItem(pageNo,true);
        } else if (type === "document") {
            let pageNo = this.state.documentListPageNo + 1;
            this.setState({"documentListPageNo": pageNo});
            this.getDocumentItem(pageNo,true);
        }

    }

    getVideoList(currentPageNo,concatWithPrevious) {
        this.setState({'videoSuccess': false})
        if (currentPageNo === undefined) {
            currentPageNo = 0;
        }

        let limit = 6;

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/hidden/post/list?userId=' + userId + '&newsFeedType=VIDEOS'+'&showOnlyMyPost='+this.state.showOnlyMyPost+ '&privateEntityId='+this.state.userData.entityId+'&page='  + currentPageNo + '&size=' + limit,
            withCredentials: true
        }).then(response => {
            if (response !== undefined && response.data !== undefined && response.data.status === '200 OK'
                && response.data.body !== undefined && response.data.body.content !== undefined) {
                if(concatWithPrevious){
                    this.state.videoList.push(...response.data.body.content);
                    this.setState({"videoList": this.state.videoList, 'videoSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }else{
                    this.setState({"videoList": response.data.body.content, 'videoSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }
            }

                this.setState({"videoListViewMore": !response.data.body.last});

            window.$('.playbutt').bind('contextmenu', function (e) {
                return false;
            });
        });
    }

    getPhotoList(currentPageNo,concatWithPrevious) {
        this.setState({'photoSuccess': false})
        if (currentPageNo === undefined) {
            currentPageNo = 0;
        }

        let limit = 6;

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/hidden/post/list?userId=' + userId + '&newsFeedType=PHOTOS'+'&showOnlyMyPost='+this.state.showOnlyMyPost+'&privateEntityId='+this.state.userData.entityId+'&page=' + currentPageNo + '&size=' + limit,
            withCredentials: true
        }).then(response => {
            if (response !== undefined && response.data !== undefined && response.data.status === '200 OK'
                && response.data.body !== undefined && response.data.body.content !== undefined) {
                if(concatWithPrevious){
                    this.state.photoList.push(...response.data.body.content);
                    this.setState({"photoList": this.state.photoList, 'photoSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }else{
                    this.setState({"photoList": response.data.body.content, 'photoSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }

                let photoListMap = {};
                let photoListOriginalMap = {}

                this.state.photoList.forEach((element, index) => {
                    let photoId = element.id;
                    photoListMap[photoId] = [];
                    photoListOriginalMap[photoId] = [];

                    element.attachmentIds.map((attachment, index) => {
                        photoListOriginalMap[photoId].push(getCDNUrl(attachment.attachmentUrl));
                        if (attachment.resizedImages && attachment.resizedImages['compressed'] != null) {
                            photoListMap[photoId].push(getCDNUrl(attachment.resizedImages['compressed']));
                        } else {
                            photoListMap[photoId].push(getCDNUrl(attachment.attachmentUrl));
                        }
                    })
                });
                this.setState({'photoListMap': photoListMap, 'photoListOriginalMap': photoListOriginalMap});
            }
            this.setState({"photoListViewMore": !response.data.body.last});
        });
    }

    renderTaggedUsers = (text) => {
        if (!text || text.length === 0) {
            return text;
        }
        text = text.split("@@@__").join("<a href=\"/profile/");
        text = text.split("^^__").join("\">")
        text = text.split("&&__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("@@@^^^").join("</a>");
        text = text.split("###__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/profile-hashtag/");
        text = text.split("&&__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/profile-hashtag/");
        text = text.split("###^^^").join("</a>");
        return text;
    }


    getAudioItem = (currentPageNo,concatWithPrevious) =>{
        this.setState({'audioSuccess': false})
        if (currentPageNo === undefined) {
            currentPageNo = 0;
        }


        let limit = 6;

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/hidden/post/list?userId=' + userId + '&newsFeedType=AUDIO'+'&showOnlyMyPost='+this.state.showOnlyMyPost+'&privateEntityId='+this.state.userData.entityId+'&page=' + currentPageNo + '&size=' + limit,
            withCredentials: true
        }).then(response => {
            if (response !== undefined && response.data !== undefined && response.data.status === '200 OK'
                && response.data.body !== undefined && response.data.body.content !== undefined) {
                if(concatWithPrevious){
                    this.state.audioList.push(...response.data.body.content);
                    this.setState({"audioList": this.state.audioList, 'audioSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }else{
                    this.setState({"audioList": response.data.body.content, 'audioSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }

                        this.setState({"audioListViewMore": !response.data.body.last});

            }
        });
    }

    getDocumentItem = (currentPageNo,concatWithPrevious) =>{
        this.setState({'documentSuccess': false})
        if (currentPageNo === undefined) {
            currentPageNo = 0;
        }
        let limit = 6;
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/hidden/post/list?userId=' + userId + '&newsFeedType=DOCUMENTS'+'&showOnlyMyPost='+this.state.showOnlyMyPost+'&privateEntityId='+this.state.userData.entityId+'&page='  + currentPageNo + '&size=' + limit,
            withCredentials: true
        }).then(response => {
            if (response !== undefined && response.data !== undefined && response.data.status === '200 OK'
                && response.data.body !== undefined && response.data.body.content !== undefined) {
                if(concatWithPrevious){
                    this.state.documentList.push(...response.data.body.content);
                    this.setState({"documentList": this.state.documentList, 'documentSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }else{
                    this.setState({"documentList": response.data.body.content, 'documentSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }
                this.setState({"documentListViewMore": !response.data.body.last});
            }
        });
    }

    callCssAfterDataLoad = ()=>{
        window.$('a.dgscroll').click(function (e) {
            e.preventDefault();
            var target = window.$(window.$(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top - 200;
                window.$('body, html').animate({scrollTop: scrollTo + 'px'}, 500);
            }
        });
    }


    getOtherItem(currentPageNo,concatWithPrevious){
        this.setState({'otherSuccess': false})
        if (currentPageNo === undefined) {
            currentPageNo = 0;
        }
        // var trendingArticlePageNo = 0;

        let limit = 6;

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/hidden/post/list?userId=' + userId + '&newsFeedType=TOP'+'&showOnlyMyPost='+this.state.showOnlyMyPost+'&privateEntityId='+this.state.userData.entityId+'&page=' + currentPageNo + '&size=' + limit,
            withCredentials: true
        }).then(response => {
            if (response !== undefined && response.data !== undefined && response.data.status === '200 OK'
                && response.data.body !== undefined && response.data.body.content !== undefined) {
                if(concatWithPrevious){
                    this.state.otherList.push(...response.data.body.content);
                    this.setState({"otherList": this.state.otherList, 'otherSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }else{
                    this.setState({"otherList": response.data.body.content, 'otherSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }

                        this.setState({"otherListViewMore": !response.data.body.last});

            }
        });
    }

    getTrendingArticle(currentPageNo,concatWithPrevious) {
        this.setState({'articleSuccess': false})
        if (currentPageNo === undefined) {
            currentPageNo = 0;
        }


        let limit = 6;

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/hidden/post/list?userId=' + userId + '&newsFeedType=ARTICLES'+'&showOnlyMyPost='+this.state.showOnlyMyPost+ '&privateEntityId='+this.state.userData.entityId+'&page=' + currentPageNo + '&size=' + limit,
            withCredentials: true
        }).then(response => {
            if (response !== undefined && response.data !== undefined && response.data.status === '200 OK'
                && response.data.body !== undefined && response.data.body.content !== undefined) {
                if(concatWithPrevious){
                    this.state.trendingArticle.push(...response.data.body.content);
                    this.setState({"trendingArticle": this.state.trendingArticle, 'articleSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }else{
                    this.setState({"trendingArticle": response.data.body.content, 'articleSuccess': true},()=>{
                        this.callCssAfterDataLoad();
                    });
                }
                        this.setState({"trendingArticleViewMore": !response.data.body.last});


            }
        });

    }

    getCoverImgUrl(arttachmentList) {

        var attachmentUrl = "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png";

        if (arttachmentList !== undefined) {
            for (var each of Object.values(arttachmentList)) {
                if (each.attachmentType === "COVER_IMAGE") {
                    attachmentUrl = getCDNUrl(this.getOptimizedArticleImage(each));
                    break;
                }
            }
        }

        return attachmentUrl;
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }

    getFormattedDate(milliseconds) {

        var utcSeconds = milliseconds;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        return timeAgo.format(d, 'mini-now')
    }

    getElementsFromList(list) {

        let str = "";
        if (list !== undefined) {
            for (let index = 0; index <= list.length; index++) {
                if (index <= 2 && list[index] !== undefined) {
                    str += "#" + list[index].replace(/\s/g, '') + " ";
                }
            }
        }
        return str;
    }

    getOptimizedArticleImage(attachment) {
        if (attachment.resizedImages && attachment.resizedImages['300x300'] != null) {
            return attachment.resizedImages['300x300'];
        }
        return attachment.attachmentUrl;
    }

    handleUnHideModal = (id, type) => {
        let data = {
            userId: this.state.userId,
            activityId: id
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/unhide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                if(type === "ARTICLE") {
                    this.setState({
                        trendingArticlePageNo: 0
                    }, () => {
                        this.getTrendingArticle(this.state.trendingArticlePageNo,false);
                    })
                } else if(type === "IMAGE") {
                    this.setState({
                        photoListPageNo: 0
                    }, () => {
                        this.getPhotoList(this.state.photoListPageNo,false);
                    })
                } else if(type === "VIDEO") {
                    this.setState({
                        videoListPageNo: 0
                    }, () => {
                        this.getVideoList(this.state.videoListPageNo,false);
                    })
                } else if(type === "OTHER") {
                    this.setState({
                        otherListPageNo: 0
                    }, () => {
                        this.getOtherItem(this.state.otherListPageNo,false);
                    })
                } else if(type === "AUDIO") {
                    this.setState({
                        audioListPageNo: 0
                    }, () => {
                        this.getAudioItem(this.state.audioListPageNo,false);
                    })
                } else {
                    this.setState({
                        documentListPageNo: 0
                    }, () => {
                        this.getDocumentItem(this.state.documentListPageNo,false);
                    })
                }
            } else {
                this.setState({'isSubmitted': false});
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({'error': 'Entity has already been unhidden', 'isSubmitted': false});
            } else {
                this.setState({'isSubmitted': false});
            }
        });
    }


    getCheckedData = (event)=>{
        event.preventDefault();
        this.setState({
            showOnlyMyPost: !this.state.showOnlyMyPost
        },()=>{
            this.defaultFunction();
        })
    }

    redirectToProfile = (customUrl) => {
        this.setState({redirectToProfile: customUrl});
    }

    detailsPageRedirection = (postId) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/backend/post/getPostId?postId=${postId}`,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.status === "200 OK") {
                this.props.history.push({
                    pathname: response.data.body ? `/studio/${response.data.body}` : `/post/${postId}`,
                    state: {
                        userId: this.state.userId,
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    render() {
        if(this.state.redirectToProfile) {
        return <Redirect push to={"/profile/" + this.state.redirectToProfile}/>
        }
        let articleContent;
        let photoContent;
        let videoContent;
        let otherContent;
        let audioContent;
        let documentContent;
        if (this.state.trendingArticle !== undefined && this.state.articleSuccess === true) {
            articleContent = this.state.trendingArticle.map((obj, index) => {
                    let linkifiedHtml = "<p class=\"pdtlsLink\">" + renderToString(<Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}>{obj.description
                        ? stripHtml(obj.description)
                        : ''}</Linkify>) + "</p>"
                    return <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg">
                                <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}>
                                    <LazyLoadImage effect="blur" src={getCDNUrl(this.getCoverImgUrl(obj.attachmentIds))}
                                                   alt=""/></Link>
                            </figure>
                            <div className="feedHiddendesc">
                                <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}>
                                {!obj.userEntityType ?
                                    <h4 className="compuser">{obj.userName !== undefined ? obj.userName : ''}
                                    </h4> :
                                    <h4 className="compuser">{obj.params['circleTitle']}</h4>}
                                
                                <div className="feedHiddenpostdtls">
                                    {obj.country &&<span class="address"><i class="iLocation"></i> {obj.country}</span>}
                                    <span>{this.getFormattedDate(obj.createTime)}</span>
                                </div>
                                </Link>
                            </div>
                            <a className="unhidebtn" onClick={e => {
                                    this.handleUnHideModal(obj.id, "ARTICLE")
                                }}><i class="iEye_OL" /></a>
                        </div>
                    </div>
                }
            )
        } else {
            articleContent = <>{Array(4)
                .fill()
                .map((item, index) => (

                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg">
                                <Skeleton height={100}/>
                            </figure>
                            <div className="feedHiddendesc">
                                <div className="compuser"><Skeleton/></div>
                            </div>
                        </div>
                    </div>
                ))}</>
        }
        if (this.state.photoList !== undefined && this.state.photoSuccess === true) {
            photoContent = this.state.photoList.map((obj, index) => {

                    let linkifiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(renderToString(<Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}>{obj.description
                        ? stripHtml(obj.description)
                        : ''}</Linkify>)) + "</p>";

                    linkifiedHtml = renderToString(ReactHtmlParser(linkifiedHtml, options));

                    return <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure style={{cursor: 'pointer'}} className={"bannimg" + (obj.attachmentIds.length === 0 && obj.paidPost ? " ppLock" : "")}>
                                <a href="javascript:void(0)" onClick={(e) => this.detailsPageRedirection(obj.id)}>
                                    {obj.userEntityType ? <i className="iImg"/> : 
                                        obj.attachmentIds.length > 0 && obj.attachmentIds[0] ? <img src={getCDNUrl(obj.attachmentIds[0].attachmentUrl)} alt=""/> : 
                                            obj.attachmentIds.length === 0 && obj.paidPost ? <img src={getCDNUrl(defaultProjectImage.attachmentUrl)} alt=""/> : 
                                                <i className="iImg"/>
                                    }
                                </a>
                            </figure>
                            <div className="feedHiddendesc">
                                <a href="javascript:void(0)" onClick={(e) => this.detailsPageRedirection(obj.id)}>
                                    {!obj.userEntityType ?
                                    <h4>{obj.userName !== undefined ? obj.userName : ''}</h4> :
                                    <h4 className="compuser">{obj.params['circleTitle']}</h4>}

                                    <div className="feedHiddenpostdtls">
                                        {obj.country &&<span class="address"><i class="iLocation"></i> {obj.country}</span>}
                                        <span>{this.getFormattedDate(obj.createTime)}</span>
                                    </div>
                                </a>
                            </div>
                            <a className="unhidebtn" onClick={e => {
                                    this.handleUnHideModal(obj.id, "IMAGE")
                                }}><i class="iEye_OL" /></a>
                        </div>
                    </div>
                }
            )
        } else {
            photoContent = <>{Array(4)
                .fill()
                .map((item, index) => (
                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg"><Skeleton height={100}/></figure>
                            <div className="feedHiddendesc">
                                <div className="feedHiddenpostdtls">
                                    <span><Skeleton/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}</>
        }
        if (this.state.videoList !== undefined && this.state.videoSuccess === true) {
            videoContent = this.state.videoList.map((obj, index) => {
                    let linkifiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(renderToString(<Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}>{obj.description
                        ? stripHtml(obj.description)
                        : ''}</Linkify>)) + "</p>"

                    linkifiedHtml = renderToString(ReactHtmlParser(linkifiedHtml, options));

                    return <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure style={{cursor: 'pointer'}} className={"bannimg" +(obj.attachmentIds.length === 0 && obj.paidPost ? " ppLock" : "")}>
                                <a href="javascript:void(0)" onClick={(e) => this.detailsPageRedirection(obj.id)}>
                                {obj.attachmentIds.length === 0 && obj.paidPost ? 
                                    <img src={getCDNUrl(defaultProjectImage.attachmentUrl)}
                                         alt=""
                                    /> 
                                    : 
                                    ((obj?.thumbnail) || (obj.attachmentIds[0].thumbnails && obj.attachmentIds[0].thumbnails[0])) &&
                                        <img src={getCDNUrl(videoThumbnailFunction(obj))}
                                             alt=""
                                        />
                                }
                                </a>
                            </figure> 
                            <div className="feedHiddendesc">
                                <a href="javascript:void(0)" onClick={(e) => this.detailsPageRedirection(obj.id)}>
                                    {!obj.userEntityType ?
                                    <h4>{obj.userName !== undefined ? obj.userName : ''}</h4> :
                                    <h4 className="compuser">{obj.params['circleTitle']}</h4>}

                                    <div className="feedHiddenpostdtls">
                                        {obj.country &&<span class="address"><i class="iLocation"></i> {obj.country}</span>}
                                        <span>{this.getFormattedDate(obj.createTime)}</span>                                    
                                    </div>
                                </a>
                            </div>
                            <a className="unhidebtn" onClick={e => {
                                    this.handleUnHideModal(obj.id, "VIDEO")
                                }}><i class="iEye_OL" /></a>                      
                        </div>
                    </div>
                }
            )
        } else {
            videoContent = <>{Array(4)
                .fill()
                .map((item, index) => (
                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg" style={{cursor: 'pointer'}}><Skeleton/></figure>
                            <div className="feedHiddendesc">
                                <div className="feedHiddenpostdtls">
                                    <span><Skeleton/></span>
                                </div>
                            </div>
                        </div>
                    </div>))}</>
        }

        if(this.state.otherList !== undefined && this.state.otherSuccess === true){
            otherContent = this.state.otherList.map((obj,index)=>{
                let linkifiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(renderToString(<Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}>{obj.description
                    ? stripHtml(obj.description)
                    : ''}</Linkify>)) + "</p>";

                linkifiedHtml = renderToString(ReactHtmlParser(linkifiedHtml, options));
                return <>
                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            {obj.postType === 'POST' &&  !obj.sharedEntityId  && obj.attachmentIds && obj.attachmentIds[0] && obj.attachmentIds[0].attachmentType && obj.attachmentIds[0].attachmentType === 'AUDIO' &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}><i className="iAudio"/></Link>
                                </figure>
                            }
                            {obj.postType === 'POST' && !obj.sharedEntityId && obj.attachmentIds && obj.attachmentIds[0] && obj.attachmentIds[0].attachmentType && obj.attachmentIds[0].attachmentType === 'IMAGE' &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}><i className="iImg"/></Link>
                                </figure>
                            }
                            {obj.postType === 'POST' && !obj.sharedEntityId && obj.attachmentIds && obj.attachmentIds[0] && obj.attachmentIds[0].attachmentType && (obj.attachmentIds[0].attachmentType === 'PPT' || obj.attachmentIds[0].attachmentType === 'PDF') &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}>
                                        <img src={obj.attachmentIds[0].attachmentType === 'PPT' ? "https://cdn.dscovr.com/images/ppt_icon.webp" : "https://cdn.dscovr.com/images/pdf_icon.webp"} alt="" />
                                    </Link>
                                </figure>
                            }
                            {obj.postType === 'POST' && !obj.sharedEntityId && obj.attachmentIds && !obj.attachmentIds[0] &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}><i className="iImg"/></Link>
                                </figure>
                            }
                            {obj.postType === 'LINK' && !obj.sharedEntityId &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}><i className="iLink"/></Link>
                                </figure>
                            }
                            {obj.postType === 'POST' && obj.sharedEntityId &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}><i className="iShare"/></Link>
                                </figure>
                            }
                            <div className="feedHiddendesc">
                                <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}>
                                {!obj.userEntityType ?
                                    <h4>{obj.userName !== undefined ? obj.userName : ''}</h4> :
                                    <h4 className="compuser">{obj.params['circleTitle']}</h4>}
                                <div className="feedHiddenpostdtls">
                                    {obj.country && <span className="address"><i className="iLocation"></i> {obj.country}</span>}
                                    <span>{this.getFormattedDate(obj.createTime)}</span>
                                </div>
                                </Link>
                            </div>
                            <a className="unhidebtn" onClick={e => {
                                this.handleUnHideModal(obj.id, "OTHER")
                            }}><i className="iEye_OL"/></a>
                        </div>
                    </div>
                </>
            })
        }else{
            otherContent = <>{Array(4)
                .fill()
                .map((item, index) => (

                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg">
                                <Skeleton height={100}/>
                            </figure>
                            <div className="feedHiddendesc">
                                <div className="compuser"><Skeleton/></div>
                            </div>
                        </div>
                    </div>
                ))}</>
        }


        if(this.state.audioList !== undefined && this.state.audioSuccess === true){
            audioContent = this.state.audioList.map((obj,index)=>{
                let linkifiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(renderToString(<Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}>{obj.description
                    ? stripHtml(obj.description)
                    : ''}</Linkify>)) + "</p>";

                linkifiedHtml = renderToString(ReactHtmlParser(linkifiedHtml, options));
                return <>
                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            {obj.postType === 'POST' &&  obj.attachmentIds && obj.attachmentIds[0] && obj.attachmentIds[0].attachmentType && obj.attachmentIds[0].attachmentType === 'AUDIO' &&
                            <figure className="bannimg" style={{cursor:'pointer'}}>
                                <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}><i className="iAudio"/></Link></figure>
                            }
                            <div className="feedHiddendesc">
                                <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}>
                                {!obj.userEntityType ?
                                    <h4>{obj.userName !== undefined ? obj.userName : ''}</h4> :
                                    <h4 className="compuser">{obj.params['circleTitle']}</h4>}
                                <div className="feedHiddenpostdtls">
                                    {obj.country && <span className="address"><i className="iLocation"></i> {obj.country}</span>}
                                    <span>{this.getFormattedDate(obj.createTime)}</span>
                                </div>
                                </Link>
                            </div>
                            <a className="unhidebtn" onClick={e => {
                                this.handleUnHideModal(obj.id, "AUDIO")
                            }}><i className="iEye_OL"/></a>
                        </div>
                    </div>
                </>
            })
        }else{
            audioContent = <>{Array(4)
                .fill()
                .map((item, index) => (

                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg">
                                <Skeleton height={100}/>
                            </figure>
                            <div className="feedHiddendesc">
                                <div className="compuser"><Skeleton/></div>
                            </div>
                        </div>
                    </div>
                ))}</>
        }

        if(this.state.documentList !== undefined && this.state.documentSuccess === true){
            documentContent = this.state.documentList.map((obj,index)=>{
                let linkifiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(renderToString(<Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}>{obj.description
                    ? stripHtml(obj.description)
                    : ''}</Linkify>)) + "</p>";

                linkifiedHtml = renderToString(ReactHtmlParser(linkifiedHtml, options));
                return <>
                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            {obj.postType === 'POST' && !obj.sharedEntityId && obj.attachmentIds && obj.attachmentIds[0] && obj.attachmentIds[0].attachmentType && (obj.attachmentIds[0].attachmentType === 'PPT' || obj.attachmentIds[0].attachmentType === 'PDF') &&
                                <figure style={{cursor: 'pointer'}} className="bannimg">
                                    <img src={getDocumentCoverImage(obj)} alt="" />
                                </figure>
                            }
                            <div className="feedHiddendesc">
                                <Link to={{pathname: "/post/" + obj.id, state: {userId: this.state.userId}}}>
                                {!obj.userEntityType ?
                                    <h4>{obj.userName !== undefined ? obj.userName : ''}</h4> :
                                    <h4 className="compuser">{obj.params['circleTitle']}</h4>}
                                <div className="feedHiddenpostdtls">
                                    {obj.country && <span className="address"><i className="iLocation"></i> {obj.country}</span>}
                                    <span>{this.getFormattedDate(obj.createTime)}</span>
                                </div>
                                </Link>
                            </div>
                            <a className="unhidebtn" onClick={e => {
                                this.handleUnHideModal(obj.id, "DOCUMENT")
                            }}><i className="iEye_OL"/></a>
                        </div>
                    </div>
                </>
            })
        }else{
            documentContent = <>{Array(4)
                .fill()
                .map((item, index) => (

                    <div className="col-md-4">
                        <div className="feedHiddenCol">
                            <figure className="bannimg">
                                <Skeleton height={100}/>
                            </figure>
                            <div className="feedHiddendesc">
                                <div className="compuser"><Skeleton/></div>
                            </div>
                        </div>
                    </div>
                ))}</>
        }

        return (
                <HideContent userId={this.state.userId}>
                    <div id="feeds"
                         className="tab-pane fade active show" style={{marginTop:'15px'}}>
                        <div className="projectinn explore trending exploreExtra">
                            <div className="explpopnaturalist trnd exploregenerelExtra draftglobalSticky">
                                <div className="draftglobalwrap whatsTrend">
                                    <div className="explpopnaturfirst">
                                        <div>
                                            {this.state.trendingArticle && this.state.trendingArticle.length > 0 && <a href="#explblog" className="dgscroll">Blogs</a>}
                                            {this.state.photoList && this.state.photoList.length > 0 && <a href="#explphoto" className="dgscroll" >Photos</a>}
                                            {this.state.videoList && this.state.videoList.length > 0 && <a href="#explvideo" className="dgscroll">Videos</a>}
                                            {this.state.audioList && this.state.audioList.length > 0 && <a href="#explaudio" className="dgscroll">Audios</a>}
                                            {this.state.documentList && this.state.documentList.length > 0 && <a href="#expldocument" className="dgscroll">Documents</a>}
                                            {this.state.otherList && this.state.otherList.length > 0 && <a href="#explother" className="dgscroll">Others</a> }
                                        </div>                                        
                                    </div>
                                    <div className="showmewrap" style={{marginTop: '11px'}}>
                                        <label className="switch" style={{marginRight: '0'}}>
                                            Show only my posts
                                            <input type="checkbox" checked={this.state.showOnlyMyPost} onClick={this.getCheckedData} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {this.state.trendingArticle && this.state.trendingArticle.length === 0 && this.state.photoList.length === 0 && this.state.videoList.length === 0 &&  this.state.audioList.length === 0 &&  this.state.otherList.length === 0 && this.state.documentList?.length === 0 &&
                                <div className="zdata col-12" style={{marginTop: '50px'}}>
                                    <div class="imgbox">
                                        <img src="https://cdn.dscovr.com/images/zroFeedsOthers.webp"
                                        onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroFeedsOthers.png"}} alt="" width={500} />
                                    </div>
                                    <div className="desc">
                                        <p style={{margin: '0px'}}>You have no Hidden Activities</p>
                                    </div>
                                </div>
                            }
                            {this.state.trendingArticle && this.state.trendingArticle.length > 0 && <div id="explblog" className="exploregenerel">
                                <div className="titlewrap"><h2>Blogs</h2></div>
                                <div className="dailyeditionwrap ls trnd">
                                    <div className="dailyeditionblk explLatarticle trndBlgs">
                                        <div className="row">
                                            {articleContent}
                                        </div>
                                        <div className="btnblock">
                                            {this.state.trendingArticleViewMore === true &&
                                                (
                                                    <a
                                                        onClick={(evt) => this.viewMoreFunction(evt, 'trendingArticle')}
                                                        href="#" className="btn morebtnsm">View more</a>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.photoList && this.state.photoList.length > 0 && <div id="explphoto" className="exploregenerel hideSectionall" >
                                <div className="titlewrap"><h2>Photos</h2></div>

                                <div className="expladventtopwrap">
                                    <div className="row">
                                        {photoContent}
                                    </div>

                                    {this.state.photoList.length > 5 && this.state.photoListViewMore === true &&
                                        (
                                            <div className="btnblock"><a href=""
                                                                         onClick={(evt) => this.viewMoreFunction(evt, 'photo')}
                                                                         className="btn">View more</a></div>
                                        )

                                    }


                                </div>
                            </div>}
                            {this.state.videoList && this.state.videoList.length > 0 && <div id="explvideo" className="exploregenerel hideSectionall">
                                <div className="titlewrap"><h2>Videos</h2></div>

                                <div className="expladventtopwrap">
                                    <div className="row">

                                        {videoContent}

                                    </div>

                                    {this.state.videoList.length > 5 && this.state.videoListViewMore === true &&
                                        (
                                            <div className="btnblock"><a href=""
                                                                         onClick={(evt) => this.viewMoreFunction(evt, 'video')}
                                                                         className="btn">View more</a></div>
                                        )

                                    }


                                </div>
                            </div>}
                            {this.state.audioList && this.state.audioList.length > 0 && <div id="explaudio" className="exploregenerel hideSectionall">
                                <div className="titlewrap"><h2>Audios</h2></div>
                                <div className="expladventtopwrap">
                                    <div className="row">
                                        {audioContent}
                                    </div>
                                    {this.state.audioList.length > 5 &&  this.state.audioListViewMore === true &&
                                        (
                                            <div className="btnblock"><a href=""
                                                                         onClick={(evt) => this.viewMoreFunction(evt, 'audio')}
                                                                         className="btn">View more</a></div>
                                        )

                                    }
                                </div>
                            </div>}
                            {this.state.documentList && this.state.documentList.length > 0 && <div id="expldocument" className="exploregenerel hideSectionall">
                                <div className="titlewrap"><h2>Documents</h2></div>
                                <div className="expladventtopwrap">
                                    <div className="row">
                                        {documentContent}
                                    </div>
                                    {this.state.documentList.length > 5 && this.state.documentListViewMore &&
                                        (
                                            <div className="btnblock"><a href=""
                                                                         onClick={(evt) => this.viewMoreFunction(evt, 'document')}
                                                                         className="btn">View more</a></div>
                                        )

                                    }
                                </div>
                            </div>}
                            {this.state.otherList && this.state.otherList.length > 0 && <div id="explother" className="exploregenerel hideSectionall">
                                <div className="titlewrap"><h2>Others</h2></div>
                                <div className="expladventtopwrap">
                                    <div className="row">
                                        {otherContent}
                                    </div>
                                    {this.state.otherList.length > 5 && this.state.otherListViewMore === true &&
                                        (
                                            <div className="btnblock"><a href=""
                                                                         onClick={(evt) => this.viewMoreFunction(evt, 'other')}
                                                                         className="btn">View more</a></div>
                                        )

                                    }
                                </div>
                            </div>}
                        </div>
                    </div>
                </HideContent>
        );
    }
}

export default FeedsHidden;
