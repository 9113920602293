import React, { Component } from "react";
import * as _ from "lodash";
import dateUtil from "date-and-time";
import { getCDNUrl, convertUnicode, nFormatter, isWebView, postMsg, redirectToProfilePage, getFileIcon } from "../commons/CommonFunctions";
import Linkify from "react-linkify";
import uuid from "react-uuid";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { renderToString } from "react-dom/server";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import { authService } from "../auth";
import { projectPageClickRequest } from "../reducer/projectPageClick/projectPageClick";
import { connect } from "react-redux";

class ProjectDetailsCommonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated()
        }
    }

    componentDidMount() {
        if(this.state.loginSuccess) {
            this.props.projectPageClickRequest({"projectId": this.props.projectDetails.projectId || this.props.projectDetails.id, "userId": this.state.userData.userId, "type": "PROJECT"});
        }
    }

    redirectToProfile = (customUrl, partyType) => {
        if (partyType === "CIRCLE") {
          window.location.href = "/community/" + customUrl;
        } else {
          window.location.href = "/profile/" + customUrl;
        }
      };

    getOptimizedFacultyImage = (personalInfo, defaultImg) => {
        if (
            personalInfo.resizedProfileImages &&
            personalInfo.resizedProfileImages["200x200"] != null
        ) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return defaultImg;
    };

    render() {
        let noOfDaysPosted = mappingUtils.getNoOfDaysAfterPosted(
            this.props.projectDetail && this.props.projectDetail.dateOfPosting
        );
        let title =
            this.props.projectDetail &&
            this.props.projectDetail.project &&
            this.props.projectDetail.project.title;
        let location =
            this.props.projectDetail &&
            this.props.projectDetail.project &&
            this.props.projectDetail.project.location;
        let fees =
            this.props.projectDetail &&
            this.props.projectDetail.project &&
            this.props.projectDetail.project.cost;
        let attachments = [];
        if (
            this.props.projectDetail &&
            this.props.projectDetail.project &&
            this.props.projectDetail.project.attachments &&
            this.props.projectDetail.project.attachments.length > 0
        ) {
            let attachedFiles = this.props.projectDetail.project.attachments;
            attachedFiles.map((data, index) => {
                let value = {
                    name: data.substring(data.lastIndexOf("/") + 1),
                    url: data,
                };
                attachments.push(value);
            });
        }
        let showProjectType;
        let projectType =
            this.props.projectDetail &&
            this.props.projectDetail.participationDetails &&
            this.props.projectDetail.participationDetails.projectType;
        let secondaryEntityType =
            this.props.projectDetail &&
            this.props.projectDetail.participationDetails &&
            this.props.projectDetail.participationDetails.secondaryEntityType;
        if (
            secondaryEntityType &&
            projectType &&
            !_.isNull(secondaryEntityType) &&
            !_.isNull(projectType)
        ) {
            if (projectType.toUpperCase() === "VOLUNTEER") {
                if (secondaryEntityType.toUpperCase() === "JOB") {
                    showProjectType = "VOLUNTEERING JOB";
                } else if (secondaryEntityType.toUpperCase() === "ASSIGNMENT") {
                    showProjectType = "VOLUNTEERING ASSIGNMENT";
                } else if (secondaryEntityType.toUpperCase() === "EVENT") {
                    showProjectType = "VOLUNTEERING EVENT";
                } else if (secondaryEntityType.toUpperCase() === "TRAINING") {
                    showProjectType = "VOLUNTEERING TRAINING";
                }
            } else {
                showProjectType = projectType;
            }
        } else {
            showProjectType = projectType;
        }
        let role =
            this.props.projectDetail &&
            this.props.projectDetail.project &&
            (this.props.projectDetail.project.jobRole ||
                this.props.projectDetail.project.assignmentRole);
        let qualifications =
            this.props.projectDetail &&
            this.props.projectDetail.project &&
            (this.props.projectDetail.project.qualifications ||
                this.props.projectDetail.project.qualificationRequired);
        let typeContent;
        if (
            this.props.projectDetail &&
            this.props.projectDetail.participationDetails &&
            this.props.projectDetail.participationDetails.secondaryEntityType ===
            "EVENT"
        ) {
            typeContent = "Mementos or Event Kits";
        } else if (
            this.props.projectDetail &&
            this.props.projectDetail.participationDetails &&
            this.props.projectDetail.participationDetails.secondaryEntityType ===
            "JOB"
        ) {
            typeContent = "Remarks";
        } else if (
            this.props.projectDetail &&
            this.props.projectDetail.participationDetails &&
            this.props.projectDetail.participationDetails.secondaryEntityType ===
            "ASSIGNMENT"
        ) {
            typeContent = "Certification or Accolades";
        } else if (
            this.props.projectDetail &&
            this.props.projectDetail.participationDetails &&
            this.props.projectDetail.participationDetails.secondaryEntityType ===
            "TRAINING"
        ) {
            typeContent = "Certification or Accolades";
        }
        let endDate = "";
        if (this.props.projectDetail && this.props.projectDetail.endDate > 0) {
            endDate = dateUtil.format(
                new Date(this.props.projectDetail.endDate),
                "DD MMM YYYY"
            );
        } else {
            endDate = "NA";
        }
        let faculties =
            this.props.projectDetail &&
            this.props.projectDetail &&
            this.props.projectDetail.faculties;
        let linkfiedHtml1 =
            '<p class="pdtlsLink">' +
            renderToString(
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {location && location.address
                        ? location.address.replace(/<br\/>/g, "\n").replace(/&nbsp;/g, " ")
                        : ""}
                </Linkify>
            )
                .replace(/&nbsp;/g, " ")
                .replace(/(\r\n|\r|\n)/g, "<br>")
                .replace(/(\\n)/g, "<br>") +
            "</p>";
        return (
            <div className="authModalBg loginwrap">
                <div
                    id="projectDetails"
                    className="modal fade dgpopup authLoginModal"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="projectdescwrap">
                                    <button className="close" onClick={this.props.openModalClose}><i className="iCross"></i></button>
                                    <div className="projdestopbar">
                                        <h1>{showProjectType}</h1>
                                        <ul>
                                            {this.props.projectDetail &&
                                                this.props.projectDetail.demolished ===
                                                false && (
                                                    <li className="posted">
                                                        {noOfDaysPosted === 0 ||
                                                            noOfDaysPosted === "0" ? (
                                                            <span>Posted Today</span>
                                                        ) : (
                                                            <span>
                                                                Posted {noOfDaysPosted}
                                                                {noOfDaysPosted < 2 ? "d" : "d"} ago
                                                            </span>
                                                        )}
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                    <div className="projdesheader">
                                        <div className="row">
                                            <div className="col-lg-4 jobsblk">
                                                <span>Title</span>
                                                <h4>{unescape(title)}</h4>
                                                <p className="addRss">
                                                    <i className="iLocation"></i>
                                                    <span>
                                                        {location && location.city},{" "}
                                                        {location && location.state},{" "}
                                                        {location && location.country}
                                                    </span>
                                                </p>
                                                {this.props.projectDetail &&
                                                    this.props.projectDetail.project &&
                                                    this.props.projectDetail.project
                                                        .isDisclosed == false &&
                                                    fees > 0 && (
                                                        <div className="price">
                                                            <i className="iWallet"></i>{" "}
                                                            {
                                                                this.props.projectDetail.project
                                                                    .currency
                                                            }{" "}
                                                            {nFormatter(fees, 2)}{" "}
                                                            {!_.isNull(
                                                                this.props.projectDetail.project
                                                                    .packageType
                                                            ) &&
                                                                !_.isUndefined(
                                                                    this.props.projectDetail.project
                                                                        .packageType
                                                                ) &&
                                                                this.props.projectDetail.project.packageType.toLowerCase() !==
                                                                "custom" &&
                                                                this.props.projectDetail.project
                                                                    .packageType}
                                                            <span>
                                                                {this.props.projectDetail.project
                                                                    .isNegotiable && "(Negotiable)"}
                                                            </span>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-lg-4 recruiter">
                                                <span>Creator</span>
                                                <div className="recruitblk">
                                                  
                                                    {this.props.projectDetail &&
                                                        this.props.projectDetail.creatorName && (
                                                            <div
                                                                className="recruit"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e)=> {e.preventDefault();redirectToProfilePage(this.props.projectDetail.customUrl,this.props.projectDetail.partyType)}}
                                                            >
                                                                <figure
                                                                    className="complogo"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e)=> {e.preventDefault();redirectToProfilePage(this.props.projectDetail.customUrl,this.props.projectDetail.partyType)}}
                                                                >
                                                                    <img
                                                                        src={
                                                                            !_.isUndefined(
                                                                                this.props.projectDetail &&
                                                                                this.props.projectDetail
                                                                                    .imageUrl
                                                                            ) &&
                                                                                !_.isNull(
                                                                                    this.props.projectDetail &&
                                                                                    this.props.projectDetail
                                                                                        .imageUrl
                                                                                )
                                                                                ? getCDNUrl(
                                                                                    this.props.projectDetail &&
                                                                                    this.props.projectDetail
                                                                                        .imageUrl
                                                                                )
                                                                                : this.props.projectDetail &&
                                                                                    this.props.projectDetail
                                                                                        .userType == "INDIVIDUAL"
                                                                                    ? "https://cdn.dscovr.com/images/users1.png"
                                                                                    : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </figure>
                                                                {this.props.projectDetail &&
                                                                    this.props.projectDetail.creatorName}
                                                            </div>
                                                        )}
                                                </div>
                                                {this.props.projectDetail &&
                                                    this.props.projectDetail.project &&
                                                    this.props.projectDetail.project
                                                        .companyName &&
                                                    !_.isNull(
                                                        this.props.projectDetail.project.companyName
                                                    ) &&
                                                    this.props.projectDetail.project
                                                        .companyName !== "null" && (
                                                        <>
                                                            <span>Organization</span>
                                                            <div className="recruit">
                                                                {/*<figure className="complogo">
                                        <img src={coverImage} alt=""/>
                                    </figure>*/}
                                                                <span>
                                                                    {unescape(
                                                                        this.props.projectDetail.project
                                                                            .companyName
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </>
                                                    )}
                                                {this.props.projectDetail &&
                                                    this.props.projectDetail.project &&
                                                    this.props.projectDetail.project
                                                        .websiteLink &&
                                                    this.props.projectDetail.project
                                                        .websiteLink[0] !== "" && (
                                                        <p>
                                                            <a
                                                                onClick={(event) =>
                                                                    window.open(
                                                                        this.props.projectDetail.project
                                                                            .websiteLink[0]
                                                                            ? mappingUtils.sanitize(
                                                                                this.props.projectDetail
                                                                                    .project.websiteLink[0]
                                                                            )
                                                                            : mappingUtils.sanitize(
                                                                                this.props.projectDetail
                                                                                    .project.websiteLink[0]
                                                                            ),
                                                                        "_blank"
                                                                    )
                                                                }
                                                                href="javascript:void(0)"
                                                            >
                                                                <i className="iGlobe" /> Website
                                                            </a>
                                                        </p>
                                                    )}
                                            </div>
                                            {this.props.projectDetail &&
                                                this.props.projectDetail.isOffLine === false &&
                                                this.props.projectDetail.demolished ===
                                                false && (
                                                    <div className="col-lg-4 opening">
                                                        {(this.props.projectDetail.participationDetails.secondaryEntityType === "FUNDRAISE" || this.props.projectDetail.lastDateOfApplication !== 0) &&
                                                            <>
                                                                <span>Application</span>
                                                                <p>
                                                                    Last date:{" "}
                                                                    {dateUtil.format(
                                                                        new Date(
                                                                            this.props.projectDetail.lastDateOfApplication
                                                                        ),
                                                                        "DD MMM YYYY"
                                                                    )}
                                                                </p>
                                                                {this.props.projectDetail && this.props.projectDetail.noOfOpenings && this.props.projectDetail.noOfOpenings > 0 && 
                                                                    <p>Openings:{" "}
                                                                        {this.props.projectDetail.noOfOpenings}
                                                                    </p>
                                                                }
                                                            </>
                                                        }
                                                        <div className="buttblk">
                                                            <a
                                                                className="btn btn-primary"
                                                                href="javascript:void(0)"
                                                                onClick={(e) =>
                                                                    this.props.handleApply(
                                                                        "/project/feeds/" +
                                                                        this.props.projectDetail.slug
                                                                    )
                                                                }
                                                            >


                                                                {
                                                                    this.props.projectDetail &&
                                                                this.props.projectDetail.participationDetails &&
                                                                    this.props.projectDetail.participationDetails.secondaryEntityType &&
                                                                this.props.projectDetail.participationDetails.secondaryEntityType ===
                                                                "FUNDRAISE" ? 'Contribute' : 'Apply'
                                                                }

                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className="projdesbody">
                                        <div className="projdesbodyinn">
                                            <div className="largetext">
                                                <h5>Brief Description</h5>
                                                <p>
                                                    <Linkify
                                                        componentDecorator={(
                                                            decoratedHref,
                                                            decoratedText,
                                                            key
                                                        ) => (
                                                            <a
                                                                target="blank"
                                                                href={decoratedHref}
                                                                key={key}
                                                            >
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >
                                                        {this.props.projectDetail &&
                                                            this.props.projectDetail.project &&
                                                            this.props.projectDetail.project
                                                                .shortBrief &&
                                                            !_.isNull(
                                                                this.props.projectDetail.project
                                                                    .shortBrief
                                                            ) &&
                                                            this.props.projectDetail.project.shortBrief
                                                                .split("\n")
                                                                .map((brief) => (
                                                                    <>
                                                                        {convertUnicode(brief)}
                                                                        <br />
                                                                    </>
                                                                ))}
                                                    </Linkify>
                                                </p>
                                            </div>
                                            {this.props.projectDetail &&
                                                this.props.projectDetail.project &&
                                                this.props.projectDetail.project
                                                    .description && (
                                                    <>
                                                        <h5>Detailed Description</h5>
                                                        {/* <Linkify
                                                            componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key
                                                            ) => (
                                                                <a
                                                                    target="blank"
                                                                    href={decoratedHref}
                                                                    key={key}
                                                                >
                                                                    {decoratedText}
                                                                </a>
                                                            )}
                                                        >
                                                            <p>
                                                                {this.props.projectDetail.project
                                                                    .description &&
                                                                    this.props.projectDetail.project.description
                                                                        .split("\n")
                                                                        .map((descrip) => (
                                                                            <>
                                                                                {convertUnicode(descrip)}
                                                                                <br />
                                                                            </>
                                                                        ))}
                                                            </p>
                                                        </Linkify> */}
                                                        <div className="projDetailsDesc">
                                                            <div className="editor-text" dangerouslySetInnerHTML={{__html: this.props.projectDetail?.project?.description}}/>
                                                        </div >
                                                    </>
                                                )}
                                            <div className="pdescattachwrap">
                                                {attachments &&
                                                    attachments.map((value) => {
                                                        //let file = JSON.parse(unescapeJs(value));
                                                        return (
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="pdescattachfile"
                                                                onClick={(e) => {
                                                                    isWebView()
                                                                        ? postMsg("download", value.url)
                                                                        : this.download_file_pdf(
                                                                            e,
                                                                            value.url
                                                                        );
                                                                }}
                                                                style={{ cursor: "default" }}
                                                            >
                                                                <span>
                                                                    {/* <i className="iDownloadFile1" /> */}
                                                                    {getFileIcon(value.name)}
                                                                    {value.name &&
                                                                        value.name.substring(
                                                                            value.name.indexOf("-") + 1
                                                                        )}
                                                                </span>
                                                            </a>
                                                        );
                                                    })}
                                            </div>
                                            {role && role.trim() != "type text" && (
                                                <>
                                                    <h5>Roles and Responsibilities</h5>
                                                    {/* <p dangerouslySetInnerHTML={{
                                    __html: role
                                        ? role
                                            .replace(/&nbsp;/g, ' ')
                                            .replace(/(\r\n|\r|\n)/g, '<br>')
                                        : ''
                                }}/> */}
                                                    <Linkify
                                                        componentDecorator={(
                                                            decoratedHref,
                                                            decoratedText,
                                                            key
                                                        ) => (
                                                            <a
                                                                target="blank"
                                                                href={decoratedHref}
                                                                key={key}
                                                            >
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >
                                                        <p>
                                                            {role &&
                                                                role.split("\n").map((role) => (
                                                                    <>
                                                                        {convertUnicode(role)}
                                                                        <br />
                                                                    </>
                                                                ))}
                                                        </p>
                                                    </Linkify>
                                                </>
                                            )}
                                            {faculties && faculties.length > 0 && (
                                                <div className="pdkeyspeaker">
                                                    <h5>
                                                        {!_.isNull(showProjectType) &&
                                                            showProjectType === "TRAINING"
                                                            ? "Faculty / Trainer"
                                                            : "Key Speakers"}
                                                    </h5>
                                                    <div className="projfollowscroll">
                                                        <OwlCarousel
                                                            id={"keyspeaker"}
                                                            className={"owlbgk"}
                                                            key={uuid()}
                                                            //items = '3'
                                                            rewind="true"
                                                            nav="true"
                                                            dots={false}
                                                            navText={[
                                                                "<i class='iArrow_Left'></i>",
                                                                "<i class='iArrow_Right'></i>",
                                                            ]}
                                                            responsive={mappingUtils.getResponsiveness()}
                                                        >
                                                            {faculties &&
                                                                faculties.map((faculty) => {
                                                                    return (
                                                                        <div
                                                                            className="item"
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <div className="projfollowblk">
                                                                                <figure
                                                                                    className="img"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.redirectToProfile(
                                                                                            faculty.customUrl,
                                                                                            null
                                                                                        );
                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            !_.isNull(
                                                                                                faculty.imageUrl
                                                                                            ) &&
                                                                                                !_.isUndefined(
                                                                                                    faculty.imageUrl
                                                                                                )
                                                                                                ? getCDNUrl(
                                                                                                    this.getOptimizedFacultyImage(
                                                                                                        faculty,
                                                                                                        faculty.imageUrl
                                                                                                    )
                                                                                                )
                                                                                                : faculty.userType === "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.webp" : "https://cdn.dscovr.com/images/users1.webp"
                                                                                        }
                                                                                        onError={(e) => {
                                                                                            e.target.onerror = null;
                                                                                            e.target.src = "https://cdn.dscovr.com/images/users1.png";
                                                                                        }}
                                                                                        alt=""
                                                                                    />
                                                                                </figure>
                                                                                <div>
                                                                                    <h4
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.redirectToProfile(
                                                                                                faculty.customUrl,
                                                                                                null
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {faculty &&
                                                                                            faculty.username}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </OwlCarousel>
                                                    </div>
                                                </div>
                                            )}
                                            {qualifications && qualifications.length > 0 && (
                                                <>
                                                    <h5>Qualification</h5>
                                                    <ul className="ulstyles">
                                                        {qualifications &&
                                                            qualifications.map((qual) => {
                                                                return (
                                                                    <li>
                                                                        <Linkify
                                                                            componentDecorator={(
                                                                                decoratedHref,
                                                                                decoratedText,
                                                                                key
                                                                            ) => (
                                                                                <a
                                                                                    target="blank"
                                                                                    href={decoratedHref}
                                                                                    key={key}
                                                                                >
                                                                                    {decoratedText}
                                                                                </a>
                                                                            )}
                                                                        >
                                                                            {qual &&
                                                                                qual.split("\n").map((qual) => (
                                                                                    <>
                                                                                        {convertUnicode(qual)}
                                                                                        <br />
                                                                                    </>
                                                                                ))}
                                                                        </Linkify>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </>
                                            )}
                                            <div className="pdincluexclu">
                                                <div className="row">
                                                    {this.props.projectDetail &&
                                                        this.props.projectDetail.project &&
                                                        this.props.projectDetail.project
                                                            .inclusions &&
                                                        this.props.projectDetail.project.inclusions
                                                            .length > 0 &&
                                                        this.props.projectDetail.project
                                                            .inclusions[0] != "" && (
                                                            <div className="col-md-6">
                                                                <h5>Inclusion</h5>
                                                                <Linkify
                                                                    componentDecorator={(
                                                                        decoratedHref,
                                                                        decoratedText,
                                                                        key
                                                                    ) => (
                                                                        <a
                                                                            target="blank"
                                                                            href={decoratedHref}
                                                                            key={key}
                                                                        >
                                                                            {decoratedText}
                                                                        </a>
                                                                    )}
                                                                >
                                                                    {this.props.projectDetail.project
                                                                        .inclusions &&
                                                                        this.props.projectDetail.project.inclusions.map(
                                                                            (incl) => (
                                                                                <p>
                                                                                    {incl &&
                                                                                        incl
                                                                                            .split("\n")
                                                                                            .map((incl) => (
                                                                                                <>
                                                                                                    {convertUnicode(incl)}
                                                                                                    <br />
                                                                                                </>
                                                                                            ))}
                                                                                </p>
                                                                            )
                                                                        )}
                                                                </Linkify>
                                                            </div>
                                                        )}
                                                    {this.props.projectDetail &&
                                                        this.props.projectDetail.project &&
                                                        this.props.projectDetail.project
                                                            .exclusions &&
                                                        this.props.projectDetail.project.exclusions
                                                            .length > 0 &&
                                                        this.props.projectDetail.project
                                                            .exclusions[0] != "" && (
                                                            <div className="col-md-6">
                                                                <h5>Exclusion</h5>
                                                                <Linkify
                                                                    componentDecorator={(
                                                                        decoratedHref,
                                                                        decoratedText,
                                                                        key
                                                                    ) => (
                                                                        <a
                                                                            target="blank"
                                                                            href={decoratedHref}
                                                                            key={key}
                                                                        >
                                                                            {decoratedText}
                                                                        </a>
                                                                    )}
                                                                >
                                                                    {this.props.projectDetail.project
                                                                        .exclusions &&
                                                                        this.props.projectDetail.project.exclusions.map(
                                                                            (excl) => (
                                                                                <p>
                                                                                    {excl &&
                                                                                        excl
                                                                                            .split("\n")
                                                                                            .map((excl) => (
                                                                                                <>
                                                                                                    {convertUnicode(excl)}
                                                                                                    <br />
                                                                                                </>
                                                                                            ))}
                                                                                </p>
                                                                            )
                                                                        )}
                                                                </Linkify>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            {this.props.projectDetail &&
                                                this.props.projectDetail.project &&
                                                this.props.projectDetail.project
                                                    .endTakeAway && (
                                                    <>
                                                        <h5>
                                                            {typeContent}
                                                            <span>
                                                                (Received by participant post
                                                                completion)
                                                            </span>
                                                        </h5>
                                                        <Linkify
                                                            componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key
                                                            ) => (
                                                                <a
                                                                    target="blank"
                                                                    href={decoratedHref}
                                                                    key={key}
                                                                >
                                                                    {decoratedText}
                                                                </a>
                                                            )}
                                                        >
                                                            <p>
                                                                {this.props.projectDetail.project
                                                                    .endTakeAway &&
                                                                    this.props.projectDetail.project.endTakeAway
                                                                        .split("\n")
                                                                        .map((endTakeAway) => (
                                                                            <>
                                                                                {convertUnicode(endTakeAway)}
                                                                                <br />
                                                                            </>
                                                                        ))}
                                                            </p>
                                                        </Linkify>
                                                    </>
                                                )}
                                            {this.props.projectDetail &&
                                                this.props.projectDetail.project &&
                                                this.props.projectDetail.project
                                                    .mediaAttachments &&
                                                this.props.projectDetail.project
                                                    .mediaAttachments.length > 0 &&
                                                this.props.projectDetail.project
                                                    .mediaAttachments[0] !== "" && (
                                                    <>
                                                        <h5>Media Coverage</h5>
                                                        <ul>
                                                            {this.props.projectDetail.project
                                                                .mediaAttachments &&
                                                                this.props.projectDetail.project.mediaAttachments.map(
                                                                    (media) => {
                                                                        return (
                                                                            <li className="pdtlsLink">
                                                                                <Linkify
                                                                                    componentDecorator={(
                                                                                        decoratedHref,
                                                                                        decoratedText,
                                                                                        key
                                                                                    ) => (
                                                                                        <a
                                                                                            target="blank"
                                                                                            href={decoratedHref}
                                                                                            key={key}
                                                                                        >
                                                                                            {decoratedText}
                                                                                        </a>
                                                                                    )}
                                                                                >
                                                                                    {media &&
                                                                                        media
                                                                                            .split("\n")
                                                                                            .map((media) => (
                                                                                                <>
                                                                                                    {convertUnicode(
                                                                                                        media
                                                                                                    )}
                                                                                                    <br />
                                                                                                </>
                                                                                            ))}
                                                                                </Linkify>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                        </ul>
                                                    </>
                                                )}
                                            {this.props.projectDetail &&
                                                this.props.projectDetail.project &&
                                                this.props.projectDetail.project
                                                    .specialization &&
                                                this.props.projectDetail.project.skills &&
                                                (this.props.projectDetail.project.skills
                                                    .length > 0 ||
                                                    this.props.projectDetail.project
                                                        .specialization.length > 0) && (
                                                    <div className="specialskills">
                                                        <h5>Skills and Specialization</h5>
                                                        <div className="specialskillsblk">
                                                            {this.props.projectDetail.project
                                                                .specialization &&
                                                                this.props.projectDetail.project
                                                                    .specialization.length > 0 && (
                                                                    <div className="spsktags">
                                                                        {" "}
                                                                        {this.props.projectDetail.project
                                                                            .specialization &&
                                                                            this.props.projectDetail.project.specialization.map(
                                                                                (sp) => (
                                                                                    <span>
                                                                                        {convertUnicode(sp)}
                                                                                    </span>
                                                                                )
                                                                            )}{" "}
                                                                    </div>
                                                                )}
                                                            {this.props.projectDetail &&
                                                                this.props.projectDetail.project &&
                                                                this.props.projectDetail.project
                                                                    .skills &&
                                                                this.props.projectDetail.project.skills
                                                                    .length > 0 && (
                                                                    <div className="spsktags">
                                                                        {" "}
                                                                        {this.props.projectDetail.project
                                                                            .skills &&
                                                                            this.props.projectDetail.project.skills.map(
                                                                                (skill) => (
                                                                                    <span>{unescape(skill)}</span>
                                                                                )
                                                                            )}{" "}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="pdincluexclu locaduration">
                                                {location && location.address && (
                                                    <>
                                                        <h5>Detailed Address </h5>
                                                        <p className="addRss">
                                                            <Linkify
                                                                componentDecorator={(
                                                                    decoratedHref,
                                                                    decoratedText,
                                                                    key
                                                                ) => (
                                                                    <a
                                                                        target="blank"
                                                                        href={decoratedHref}
                                                                        key={key}
                                                                    >
                                                                        {decoratedText}
                                                                    </a>
                                                                )}
                                                            >
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: linkfiedHtml1,
                                                                    }}
                                                                />
                                                            </Linkify>
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="pdincluexclu locaduration">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h5>Duration</h5>
                                                        <ul>
                                                            <li>
                                                                <span>Start Date</span>{" "}
                                                                {dateUtil.format(
                                                                    new Date(
                                                                        this.props.projectDetail &&
                                                                        this.props.projectDetail.startDate
                                                                    ),
                                                                    "DD MMM YYYY"
                                                                )}
                                                            </li>
                                                            {endDate !== "" && (
                                                                <li>
                                                                    <span>End Date</span> {endDate}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      projectPageClickProgress: state.projectPageClickReducer.projectPageClickProgress
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      projectPageClickRequest: (state) => dispatch(projectPageClickRequest(state))
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsCommonModal);
