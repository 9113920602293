import React from 'react'
import {authService} from '../auth';
import httpService from '../AxiosInterceptors';
import {Redirect} from "react-router-dom";
import Header from "../Header";
import axios from "axios";
import {getCDNUrl} from "../commons/CommonFunctions";
import AssignAdminPop from "./AssignAdminPop";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import ConnectionNotFoundError from "./ConnectionNotFoundError";
import ProfileDeactivationUpdateConfirmPopUp from "./ProfileDeactivationUpdateConfirmPopUp";
import "./main-settings.min.css";
import { profileDeactivationConfig } from '../services/UserApi';

httpService.setupInterceptors();

class ProfileDeactivationUpdateAdminRights extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            errors: {},
            isDisabled: true,
            error: null,
            success: null,
            details: '',
            config: {},
            projectClassName: 'nav-link active',
            projectTabClassName: 'tab-pane fade active show',
            businessPageClassName: 'nav-link',
            businessPageTabClassName: 'tab-pane fade tabCompanypages',
            circleClassName: 'nav-link',
            circleTabClassName: 'tab-pane fade tabCircles',
            assignToSingleConnect: true,
            adminList: [],
            type: '',
            allAdminAssigned: false,
            submitted: false,
            forAll: false,
            entityId: null,
            updatedData: {},
            message: '',
            redirectionRequired: false,
            redirectionLink: '/network',
        };
    }

    componentDidMount() {
        this.getDeactivationConfig();
    }


    getDeactivationConfig = async () => {
        this.setState({'isSubmitted': true});
        let response = await profileDeactivationConfig({"userId": this.state.userData.userId});
        if(response?.message === "Success!") {
            if(response?.status === "200 OK") {
                this.setState({'config': response.body, 'isSubmitted': false}, () => {
                    if (this.state.config.totalProjectCount === 0 && this.state.config.totalBusinessPageCount === 0 && this.state.config.totalCircleCount === 0) {
                        window.location.href = '/security-and-login';
                    }
                    if (this.state.config.totalProjectCount > 0) {
                        this.setState({
                            'projectClassName': 'nav-link active',
                            projectTabClassName: 'tab-pane fade active show'
                        })
                    } else if (this.state.config.totalBusinessPageCount > 0) {
                        this.setState({
                            'businessPageClassName': 'nav-link active',
                            'projectClassName': 'nav-link',
                            businessPageTabClassName: 'tab-pane fade active show',
                            projectTabClassName: 'tab-pane fade'
                        })
                    } else if (this.state.config.totalCircleCount > 0) {
                        this.setState({
                            'circleClassName': 'nav-link active',
                            'projectClassName': 'nav-link',
                            circleTabClassName: 'tab-pane fade active show',
                            projectTabClassName: 'tab-pane fade '
                        })
                    }
                });
            } else {
                this.setState({'isSubmitted': false});
            }
        } else {
            this.setState({'isSubmitted': false});
        }
    }


    assignAdminForSingle = (user) => {
        if (this.state.type === 'project') {
            let projects = this.state.config.projects;
            let value = projects[this.state.entityId];
            let updatedData = this.state.updatedData;
            if (value) {
                value.assigned = true;
                value.username = user.username;
                value.status = 'ASSIGNED'
                value.userId = user.id;
                updatedData[this.state.entityId] = value;
            }
            this.setState({config: {...this.state.config, projects: projects}, updatedData: updatedData}, () => {
                this.checkAllAdminAssigned();
            });

        } else if (this.state.type === 'circle') {
            let circles = this.state.config.circles;
            let value = circles[this.state.entityId];
            let updatedData = this.state.updatedData;
            if (value) {
                value.assigned = true;
                value.username = user.username;
                value.userId = user.id;
                value.status = 'ASSIGNED'
                updatedData[this.state.entityId] = value;
            }

            this.setState({config: {...this.state.config, circles: circles}, updatedData: updatedData}, () => {
                this.checkAllAdminAssigned();
            });
        } else if (this.state.type === 'businessPage') {
            let businessPages = this.state.config.businessPages;
            let value = businessPages[this.state.entityId];
            let updatedData = this.state.updatedData;
            if (value) {
                value.assigned = true;
                value.username = user.username;
                value.userId = user.id;
                value.status = 'ASSIGNED'
                updatedData[this.state.entityId] = value;
            }
            this.setState({
                config: {...this.state.config, businessPages: businessPages},
                updatedData: updatedData
            }, () => {
                this.checkAllAdminAssigned();
            });
        }
    }


    checkAllAdminAssigned = () => {
        let allAdminAssigned = true;
        let businessPages = this.state.config.businessPages;
        let circles = this.state.config.circles;
        let projects = this.state.config.projects;
        if (businessPages) {
            Object.keys(businessPages).map((data, index) => {
                if (!businessPages[data].assigned) {
                    allAdminAssigned = false;
                    return;
                }
            })
        }
        if (circles) {
            Object.keys(circles).map((data, index) => {
                if (!circles[data].assigned) {
                    allAdminAssigned = false;
                    return;
                }
            })
        }
        if (projects) {
            Object.keys(projects).map((data, index) => {
                if (!projects[data].assigned) {
                    allAdminAssigned = false;
                    return;
                }
            })
        }
        this.setState({'allAdminAssigned': allAdminAssigned});


    }


    getAssignAdminPopUpForSingle = (event, entityId, type) => {
        event.preventDefault();
        this.setState({
            forAll: false,
            entityId: entityId,
            type: type,
            'adminList': this.state.config.connectionList
        }, () => {
            if (this.state.adminList && this.state.adminList.length === 0) {
                let message = '';
                let redirectionRequired = false;
                if (this.state.userData.type === 'INDIVIDUAL') {
                    message = 'No connections to transfer the Admin Rights. Please make connections to proceed.';
                    redirectionRequired = true;
                } else if (this.state.userData.type === 'COMPANY') {
                    message = 'No employee to transfer the admin rights. Please add an employee to proceed.';
                }
                this.setState({message: message, redirectionRequired: redirectionRequired}, () => {
                    window.$('#connectionNotFound').modal('show');
                });

            } else {
                window.$('#assignsinglepop').modal('show');
            }
        })

    }

    getOptimizedImage(entity) {
        if (entity.resizedEntityImage && entity.resizedEntityImage['300x300'] != null) {
            return entity.resizedEntityImage['300x300'];
        }
        return entity.entityImage;
    }

    getResizedProfileImage(entity) {
        if (entity.resizedProfileImages && entity.resizedProfileImages['300x300'] != null) {
            return entity.resizedProfileImages['200x200'];
        }
        return entity.originalProfileImage;
    }

    getDefaultImage(entityType) {
        if (entityType && (entityType === 'EVENT' || entityType === 'TRAINING')) {
            return 'https://cdn.dscovr.com/images/project-default.jpg'
        }
        return 'https://cdn.dscovr.com/images/project-default.jpg';
    }


    getOptimizedBusinessPagesImage(entity) {
        if (entity.resizedEntityImage && entity.resizedEntityImage['200x200'] != null) {
            return entity.resizedEntityImage['200x200'];
        }
        return entity.entityImage;
    }

    cancel = (event) => {
        event.preventDefault();
        window.location.href = '/security-and-login'
    }

    cancelAdminRightRequest = (event, userId, entityId) => {
        event.preventDefault();
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL + '/backend/deactivation/cancel/admin/right?userId=' + userId + '&adminRightChangeQueueId=' + this.state.config.adminRightChangeQueueId + '&entityId=' + entityId,
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    //this.getDeactivationConfig();
                    this.handleDataAfterCancelled(entityId);
                    this.setState({'allAdminAssigned': false});
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleDataAfterCancelled = (entityId) => {
        if (this.state.config) {
            let businessPages = this.state.config.businessPages;
            let circles = this.state.config.circles;
            let projects = this.state.config.projects;
            let data = {};
            if (projects[entityId]) {
                data = projects[entityId];
                data.assigned = false;
                data.status = 'CANCELLED';
                projects[entityId] = data;
            } else if (circles[entityId]) {
                data = circles[entityId];
                data.assigned = false;
                data.status = 'CANCELLED';
                circles[entityId] = data;
            } else if (businessPages[entityId]) {
                data = businessPages[entityId];
                data.assigned = false;
                data.status = 'CANCELLED';
                businessPages[entityId] = data;
            }
            this.setState({
                config: {
                    ...this.state.config,
                    projects: projects,
                    circles: circles,
                    businessPages: businessPages
                }
            });


        }

    }

    handleSubmit = (event) => {
        this.setState({'submitted': true});
        event.preventDefault();
        let adminRights = {};
        let updatedData = this.state.updatedData;

        if (updatedData) {
            Object.keys(updatedData).map((data, index) => {
                let value = updatedData[data];
                if (value) {
                    let insertData = {};
                    insertData.userId = value.userId;
                    insertData.entityId = value.entityId;
                    insertData.title = value.title;
                    insertData.entityType = value.entityType;
                    insertData.status = 'PENDING';
                    adminRights[data] = insertData;
                }

            })
        }

        let postBody = {
                "userId": this.state.userData.userId,
                "adminRightChangeQueueId": this.state.config.adminRightChangeQueueId,
                "adminRights": adminRights,
                "privateEntityId":this.state.userData.entityId
            }
        ;
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/deactivation/update-admin-rights-deactivation-queue',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({'submitted': false});
                window.$('#profiledeactivationUpdateConfirmPopup').modal('show');
            }
        }).catch((err) => {
            if (err && err.response.data) {
                this.setState({
                    isLoaded: true,
                    'submitted': false,
                    error: {message: err.response.data.message, err: err.response}
                });

            }
        });

    };


    render() {
        const {error} = this.state;
        if (!this.state.loginSuccess) {
            return <Redirect to='/login'/>
        } else {
            return <div className="wrapper">
                <Header hamburgerVisibility={false}/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="admin_rightTTle">
                                    <h2>Transfer your Admin Rights</h2>
                                    <div className="admin_rightTTlebtmbx">
                                        <p>Before deleting your account, please transfer your admin rights. The admin
                                            rights will be transferred once all your requests are accepted.</p>

                                    </div>
                                </div>
                                <div className="dlttabBx">

                                    <div className="circMemInviteTabwrap">
                                        <div className="mcustHorizMenu">
                                            <div className="mcustHorizinn">
                                                <ul id="tabs" className="nav">
                                                    {this.state.config.totalProjectCount > 0 &&
                                                    <li className="nav-item"><a href="#projects" data-target=""
                                                                                data-toggle="tab"
                                                                                className={this.state.projectClassName}>Projects <span>{this.state.config.totalProjectCount}</span></a>
                                                    </li>
                                                    }
                                                    {this.state.config.totalBusinessPageCount > 0 &&
                                                    <li className="nav-item"><a href="#company-pages" data-target=""
                                                                                data-toggle="tab"
                                                                                className={this.state.businessPageClassName}>Organization
                                                        Pages <span>{this.state.config.totalBusinessPageCount}</span></a>
                                                    </li>
                                                    }
                                                    {this.state.config.totalCircleCount > 0 &&
                                                    <li className="nav-item"><a href="#circles" data-target=""
                                                                                data-toggle="tab"
                                                                                className={this.state.circleClassName}>Communities <span>{this.state.config.totalCircleCount}</span></a>
                                                    </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tabsContent" className="tab-content">
                                        <div id="projects" className={this.state.projectTabClassName}>
                                            {this.state.config.projects && Object.keys(this.state.config.projects).map((data, index) => {
                                                return <div className="invitpeopleblk sent" key={index}>
                                                    <div className="profrcablock">
                                                        <figure className="users">
                                                            <img
                                                                src={this.state.config.projects[data].entityImage ? getCDNUrl(this.getOptimizedImage(this.state.config.projects[data])) : this.getDefaultImage(this.state.config.projects[data].entityType)}
                                                                alt=""/>
                                                        </figure>
                                                        <div className="profrcadesc">
                                                            <h5><a
                                                                href="javascript:void(0)">{this.state.config.projects[data].title}</a>
                                                            </h5>
                                                            <p>{this.state.config.projects[data].entityType}</p>
                                                        </div>
                                                    </div>
                                                    <div className="invitpeopbtns">
                                                        <div className="pendingWrap">
                                                            {this.state.config.projects[data].status === 'PROCESSED' &&
                                                            <p>
                                                                <strong>Accepted</strong> on {mappingUtils.getStartDateCustom(this.state.config.projects[data].updateTime)}
                                                            </p>
                                                            }
                                                            {this.state.config.projects[data].status === 'REJECTED' &&
                                                            <label>Request denied</label>
                                                            }
                                                            {this.state.config.projects[data].status === 'CANCELLED' &&
                                                            <label>Request Cancelled</label>
                                                            }
                                                            {this.state.config.projects[data].status === 'REJECTED' &&
                                                            <span>by <span>{this.state.config.projects[data].username}</span></span>
                                                            }
                                                            {this.state.config.projects[data].status === 'REJECTED' &&
                                                            <p>on {mappingUtils.getStartDateCustom(this.state.config.projects[data].updateTime)}</p>
                                                            }
                                                            {this.state.config.projects[data].status === 'PENDING' &&
                                                            <p>Pending</p>
                                                            }
                                                            {this.state.config.projects[data].status === 'ASSIGNED' &&
                                                            <p>{this.state.config.projects[data].username}</p>
                                                            }
                                                        </div>
                                                        <div className="inviteby">
                                                            {this.state.config.projects[data].status === 'PROCESSED' &&
                                                            <label>Transferred to</label>
                                                            }

                                                            {this.state.config.projects[data].status === 'PENDING' &&
                                                            <button className="btn cancelbtn"
                                                                    onClick={(e) => this.cancelAdminRightRequest(e, this.state.config.projects[data].userId, this.state.config.projects[data].entityId)}>Cancel
                                                                Request</button>
                                                            }

                                                            {this.state.config.projects[data].status === 'PROCESSED' &&
                                                            <figure className="users"><img
                                                                src={this.state.config.projects[data].originalProfileImage ? getCDNUrl(this.getResizedProfileImage(this.state.config.projects[data])) : mappingUtils.returnDefaultImage(this.state.config.projects[data].userType)}
                                                                alt=""/></figure>
                                                            }
                                                            {this.state.config.projects[data].status === 'PROCESSED' &&
                                                            <h6>{this.state.config.projects[data].username}</h6>
                                                            }
                                                            {(this.state.config.projects[data].status === 'REJECTED' || this.state.config.projects[data].status === 'ASSIGNED' || this.state.config.projects[data].status === 'CANCELLED') &&
                                                            <button
                                                                onClick={(e) => this.getAssignAdminPopUpForSingle(e, this.state.config.projects[data].entityId, 'project')}
                                                                className="btn assignbtn">Assign Admin
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div id="company-pages" className={this.state.businessPageTabClassName}>
                                            {this.state.config.businessPages && Object.keys(this.state.config.businessPages).map((data, index) => {
                                                return <div className="invitpeopleblk sent" key={index}>
                                                    <div className="profrcablock">
                                                        <figure className="users">
                                                            <img
                                                                src={this.state.config.businessPages[data].entityImage ? getCDNUrl(this.getOptimizedBusinessPagesImage(this.state.config.businessPages[data])) : 'https://cdn.dscovr.com/images/DefaultBusiness.webp'} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/DefaultBusiness.png"}}
                                                                alt=""/>
                                                        </figure>
                                                        <div className="profrcadesc">
                                                            <h5><a
                                                                href="javascript:void(0)">{this.state.config.businessPages[data].title}</a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="invitpeopbtns">
                                                        <div className="pendingWrap">
                                                            {this.state.config.businessPages[data].status === 'PROCESSED' &&
                                                            <p>
                                                                <strong>Accepted</strong> on {mappingUtils.getStartDateCustom(this.state.config.businessPages[data].updateTime)}
                                                            </p>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'REJECTED' &&
                                                            <label>Request denied</label>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'REJECTED' &&
                                                            <span>by <span>{this.state.config.businessPages[data].username}</span></span>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'REJECTED' &&
                                                            <p>on {mappingUtils.getStartDateCustom(this.state.config.businessPages[data].updateTime)}</p>
                                                            }

                                                            {this.state.config.businessPages[data].status === 'CANCELLED' &&
                                                            <label>Request Cancelled</label>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'PENDING' &&
                                                            <p>Pending</p>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'ASSIGNED' &&
                                                            <p>{this.state.config.businessPages[data].username}</p>
                                                            }
                                                        </div>
                                                        <div className="inviteby">
                                                            {this.state.config.businessPages[data].status === 'PROCESSED' &&
                                                            <label>Transferred to</label>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'PROCESSED' &&
                                                            <figure className="users"><img
                                                                src={this.state.config.businessPages[data].originalProfileImage ? getCDNUrl(this.getResizedProfileImage(this.state.config.businessPages[data])) : mappingUtils.returnDefaultImage(this.state.config.businessPages[data].userType)}
                                                                alt=""/></figure>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'PENDING' &&
                                                            <button className="btn cancelbtn"
                                                                    onClick={(e) => this.cancelAdminRightRequest(e, this.state.config.businessPages[data].userId, this.state.config.businessPages[data].entityId)}>Cancel
                                                                Request</button>
                                                            }
                                                            {this.state.config.businessPages[data].status === 'PROCESSED' &&
                                                            <h6>{this.state.config.businessPages[data].username}</h6>
                                                            }
                                                            {(this.state.config.businessPages[data].status === 'REJECTED' || this.state.config.businessPages[data].status === 'ASSIGNED' || this.state.config.businessPages[data].status === 'CANCELLED') &&
                                                            <button
                                                                onClick={(e) => this.getAssignAdminPopUpForSingle(e, this.state.config.businessPages[data].entityId, 'businessPage')}
                                                                className="btn assignbtn">Assign Admin
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                        <div id="circles" className={this.state.circleTabClassName}>
                                            {this.state.config.circles && Object.keys(this.state.config.circles).map((data, index) => {
                                                return <div className="invitpeopleblk sent" key={index}>
                                                    <div className="profrcablock">
                                                        <figure className="users">
                                                            <img
                                                                src={this.state.config.circles[data].entityImage ? getCDNUrl(this.getOptimizedImage(this.state.config.circles[data])) : "https://cdn.dscovr.com/images/circleDefault.webp"} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}}
                                                                alt=""/>
                                                        </figure>
                                                        <div className="profrcadesc">
                                                            <h5><a
                                                                href="javascript:void(0)">{this.state.config.circles[data].title}</a>
                                                            </h5>
                                                            <p><i
                                                                className="iLocation"></i>{this.state.config.circles[data].country}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="invitpeopbtns">
                                                        <div className="pendingWrap">
                                                            {this.state.config.circles[data].status === 'PROCESSED' &&
                                                            <p>
                                                                <strong>Accepted</strong> on {mappingUtils.getStartDateCustom(this.state.config.circles[data].updateTime)}
                                                            </p>
                                                            }
                                                            {this.state.config.circles[data].status === 'REJECTED' &&
                                                            <label>Request denied</label>
                                                            }
                                                            {this.state.config.circles[data].status === 'CANCELLED' &&
                                                            <label>Request Cancelled</label>
                                                            }
                                                            {this.state.config.circles[data].status === 'REJECTED' &&
                                                            <span>by <span>{this.state.config.circles[data].username}</span></span>
                                                            }
                                                            {this.state.config.circles[data].status === 'REJECTED' &&
                                                            <p>on {mappingUtils.getStartDateCustom(this.state.config.circles[data].updateTime)}</p>
                                                            }
                                                            {this.state.config.circles[data].status === 'PENDING' &&
                                                            <p>Pending</p>
                                                            }
                                                            {this.state.config.circles[data].status === 'ASSIGNED' &&
                                                            <p>{this.state.config.circles[data].username}</p>
                                                            }
                                                        </div>
                                                        <div className="inviteby">
                                                            {this.state.config.circles[data].status === 'PROCESSED' &&
                                                            <label>Transferred to</label>
                                                            }
                                                            {this.state.config.circles[data].status === 'PROCESSED' &&
                                                            <figure className="users"><img
                                                                src={this.state.config.circles[data].originalProfileImage ? getCDNUrl(this.getResizedProfileImage(this.state.config.circles[data])) : mappingUtils.returnDefaultImage(this.state.config.circles[data].userType)}
                                                                alt=""/></figure>
                                                            }
                                                            {this.state.config.circles[data].status === 'PENDING' &&
                                                            <button className="btn cancelbtn"
                                                                    onClick={(e) => this.cancelAdminRightRequest(e, this.state.config.circles[data].userId, this.state.config.circles[data].entityId)}>Cancel
                                                                Request</button>
                                                            }
                                                            {this.state.config.circles[data].status === 'PROCESSED' &&
                                                            <h6>{this.state.config.circles[data].username}</h6>
                                                            }
                                                            {(this.state.config.circles[data].status === 'REJECTED' || this.state.config.circles[data].status === 'ASSIGNED' || this.state.config.circles[data].status === 'CANCELLED') &&
                                                            <button
                                                                onClick={(e) => this.getAssignAdminPopUpForSingle(e, this.state.config.circles[data].entityId, 'circle')}
                                                                className="btn assignbtn">Assign Admin
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                    </div>
                                    <AssignAdminPop adminList={this.state.adminList} forAll={this.state.forAll}
                                                    assignAdminForSingle={this.assignAdminForSingle}/>
                                </div>
                                <div className="tab_btm d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal"
                                            disabled={!this.state.allAdminAssigned || this.state.submitted}
                                            onClick={this.handleSubmit}>Update
                                    </button>
                                    <button type="button" className="btn btn-cancel" onClick={this.cancel}>Cancel
                                    </button>
                                </div>
                            </div>

                            <ProfileDeactivationUpdateConfirmPopUp/>
                            <ConnectionNotFoundError message={this.state.message}
                                                     redirectionRequired={this.state.redirectionRequired}
                                                     redirectionLink={this.state.redirectionLink}/>
                        </div>
                    </div>
                </main>
            </div>
        }
    }
}

export default ProfileDeactivationUpdateAdminRights
