import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {authService} from "../auth";
import {connect} from "react-redux";
import CauseLatestPostList from "./CauseLatestPostList";
import CausePhotosPostList from "./CausePhotosPostList";
import CauseVideoPostList from "./CauseVideoPostList";
import CauseArticlePostList from "./CauseArticlePostList";
import CauseAudioPostList from "./CauseAudioPostList";
import AuthModalComponent from "../LoginExtra/AuthModalComponent";
import SignUpModal from "../SignUpModal/signUpModal";
import axios from "axios";
import TimeAgo from 'javascript-time-ago'
import en from "javascript-time-ago/locale/en";
import UnAuthQuotePostItem from "../post/unAuthQuotePostItem";
import UnAuthArticlePostItem from "../post/UnAuthArticlePostItem";
import UnAuthVideoPostItem from "../post/unAuthVideoPostItem";
import UnAuthAudioPostItem from "../post/UnAuthAudioPostItem";
import UnAuthPhotosPostItem from "../post/UnAuthPhotosPostItem";
import UnAuthLinkPostItem from "../post/UnAuthLinkPostItem";
import UnAuthSharePostItem from "../post/UnAuthSharePostItem";
import LazyLoad from "react-lazyload";


TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

class CausePostNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postCreationResponse: this.props.postCreationResponse,
            activeKey: "latest_1",
            latestTabKey: "latest_1",
            photosTabKey: "photos_1",
            videosTabKey: "videos_1",
            articlesTabKey: "articles_1",
            audioTabKey: "audio_1",
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            loginRequired: false,
            signUpRequired:false,
            postLatestList: [],
            postPhotoList:[],
            postVideoList:[],
            postArticleList:[],
            postAudioList:[]
        };
    }

    componentDidMount() {
        window.$(".posthnavtab").sticky({topSpacing: 65, responsiveWidth: true});
        if (!this.state.loginSuccess) {
            this.unAuthCausePostList(0, 2)
            this.unAuthPhotosPostList(0,2)
            this.unAuthVideoPostList(0,2)
            this.unAuthArticlePostItem(0,2)
            this.unAuthAudioPostItem(0,2)
        }
    }

    componentWillReceiveProps(props) {
        if (props.postCreationResponse !== undefined) {
            let tabKey = this.state.activeKey;
            let value = tabKey.split("_");
            let tabKeyProp = value[0] + "TabKey";
            let tabKeyVal = value[0] + "_" + (parseInt(value[1]) + 1);
            this.setState({
                postCreationResponse: props.postCreationResponse,
                [tabKeyProp]: tabKeyVal,
                activeKey: tabKeyVal,
            });
        }
    }

    loginRequired=(slug)=> {
        this.setState({'loginRequired': true, 'pathname':slug})
        window.$('#loginModal').modal('show');
    }
    handleLoginClose = () => {
        this.setState({'loginRequired': false})
        window.$('#loginModal').modal('hide');
    }

    signUpRequired(){
        this.setState({'signUpRequired':true})
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({'signUpRequired': false})
        window.$('#signupModal').modal('hide');
    }

    handleTabSelect = (tabKey) => {
        this.setState({
            activeKey: tabKey,
        });
    };

    unAuthCausePostList = (page, size) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/unauth/cause/getNewsFeed?causeId=' + this.props.causeId + '&newsFeedType=LATEST' + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    postLatestList: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    unAuthPhotosPostList = (page,size)=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/unauth/cause/getNewsFeed?causeId=' + this.props.causeId + '&newsFeedType=PHOTOS' + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    postPhotoList: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    unAuthVideoPostList = (page,size)=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/unauth/cause/getNewsFeed?causeId=' + this.props.causeId + '&newsFeedType=VIDEOS' + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    postVideoList: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    unAuthArticlePostItem = (page,size) =>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/unauth/cause/getNewsFeed?causeId=' + this.props.causeId + '&newsFeedType=ARTICLES' + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    postArticleList: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    unAuthAudioPostItem = (page,size) =>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/unauth/cause/getNewsFeed?causeId=' + this.props.causeId + '&newsFeedType=AUDIO' + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.body && response.status === 200) {
                this.setState({
                    postAudioList: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }

    convertUtc = (item) => {
        var utcSeconds = item.createTime;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        return d.setUTCSeconds(utcSeconds / 1000);
    }

    render() {
        if (!this.state.loginSuccess) {
            return <><Tab.Container
                onSelect={this.handleTabSelect}
                activeKey={this.state.activeKey}
                defaultActiveKey={this.state.latestTabKey}
                id="homeposthnav"
                unmountOnExit={true}
            >
                <div className="posthnavtab">
                    <div className="mcustHorizMenu">
                        <div className="mcustHorizinn">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey={this.state.latestTabKey} title="Latest">
                                        Latest
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={this.state.photosTabKey} title="Photos"
                                    >
                                        Photos
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={this.state.videosTabKey} title="Videos">
                                        Videos
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={this.state.articlesTabKey} title="Blogs">
                                        Articles
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={this.state.audioTabKey}
                                        title="Audio"
                                    >
                                        Audio
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey={this.state.latestTabKey}>
                        <section className="hpostlist">
                        {this.state.postLatestList && this.state.postLatestList.content && this.state.postLatestList.content.map((item)=>{
                            return item.postType === 'ARTICLE'
                                ? <UnAuthArticlePostItem enablePostSharing={true} item={item} key={item.id}/>
                                : (item.attachmentIds && item.attachmentIds.length > 0)
                                    ? (item.attachmentIds[0].attachmentType === 'VIDEO' ?
                                            <LazyLoad once><UnAuthVideoPostItem  item={item} key={item.id}/></LazyLoad>
                                            : (item.attachmentIds[0].attachmentType === 'AUDIO' ?
                                                    <LazyLoad once><UnAuthAudioPostItem item={item}
                                                                                  key={item.id}/></LazyLoad>
                                                    : <LazyLoad once><UnAuthPhotosPostItem item={item}
                                                                                     key={item.id}/></LazyLoad>
                                            )
                                    )
                                    : (item.postType === 'LINK')
                                        ? <LazyLoad once><UnAuthLinkPostItem enablePostSharing={true} item={item} key={item.id}/>
                            </LazyLoad> : (item.sharedEntityId && item.sharedEntityId !== '')
                                            ? <UnAuthSharePostItem enablePostSharing={true} item={item} key={item.id}/>
                                            : <UnAuthQuotePostItem  item={item} key={item.id}/>
                        })}
                        </section>
                        <div className="zdata zfeed noLog">
                            <div className="imgbox">
                                <img src="https://cdn.dscovr.com/images/causes-null_ico2.svg" alt=""/>
                            </div>
                            <div className="desc">
                                <p>
                                    <strong>
                                        Support a cause, voice your concerns, find innovative
                                        solutions and unite with the world to protect nature
                                        better.
                                    </strong>
                                </p>
                                <p className="jointxt">
                                    <a
                                        href="javascript:void(0)"
                                        onClick={(e) => this.signUpRequired('/cause/' + this.props.slug)}
                                    >
                                        Join Us
                                    </a>{" "}
                                    to be a part of a bigger movement <br/>
                                    for Mother Nature.
                                </p>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.photosTabKey}>
                        {this.state.postPhotoList && this.state.postPhotoList.content && this.state.postPhotoList.content.map((item)=>{
                            return <section className="hpostlist"><LazyLoad once><UnAuthPhotosPostItem item={item} key={item.id}/></LazyLoad></section>
                        })}
                        <div className="zdata zfeed noLog">
                            <div className="imgbox"><img src="https://cdn.dscovr.com/images/causes-null_ico2.svg"
                                                         alt=""/></div>
                            <div className="desc">
                                <p><strong>Support a cause, voice your concerns, find innovative solutions and unite
                                    with the world to protect nature better.</strong></p>
                                <p className="jointxt"><a href="javascript:void(0)"
                                                          onClick={(e) => this.signUpRequired('/cause/' + this.props.slug)}>Join
                                    Us</a> to be a
                                    part of a bigger movement <br/>for Mother Nature.</p>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.videosTabKey}>
                        {this.state.postVideoList && this.state.postVideoList.content && this.state.postVideoList.content.map((item)=>{
                            return <section className="hpostlist"><LazyLoad once><UnAuthVideoPostItem  item={item} key={item.id}/></LazyLoad></section>
                        })}
                        <div className="zdata zfeed noLog">
                            <div className="imgbox"><img src="https://cdn.dscovr.com/images/causes-null_ico2.svg"
                                                         alt=""/></div>
                            <div className="desc">
                                <p><strong>Support a cause, voice your concerns, find innovative solutions and unite
                                    with the world to protect nature better.</strong></p>
                                <p className="jointxt"><a href="javascript:void(0)"
                                                          onClick={(e) => this.signUpRequired('/cause/' + this.props.slug)}>Join
                                    Us</a> to be a
                                    part of a bigger movement <br/>for Mother Nature.</p>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.articlesTabKey}>
                        <section className="hpostlist">
                        {this.state.postArticleList && this.state.postArticleList.content && this.state.postArticleList.content.map((item)=>{
                            return <UnAuthArticlePostItem enablePostSharing={true} item={item} key={item.id}/>
                        })}
                        </section>
                        <div className="zdata zfeed noLog">
                            <div className="imgbox"><img src="https://cdn.dscovr.com/images/causes-null_ico2.svg"
                                                         alt=""/></div>
                            <div className="desc">
                                <p><strong>Support a cause, voice your concerns, find innovative solutions and unite
                                    with the world to protect nature better.</strong></p>
                                <p className="jointxt"><a href="javascript:void(0)"
                                                          onClick={(e) => this.signUpRequired('/cause/' + this.props.slug)}>Join
                                    Us</a> to be a
                                    part of a bigger movement <br/>for Mother Nature.</p>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.audioTabKey}>
                        <section className="hpostlist">
                        {this.state.postAudioList && this.state.postAudioList.content && this.state.postAudioList.content.map((item)=>{
                            return <LazyLoad once><UnAuthAudioPostItem item={item} key={item.id}/></LazyLoad>
                        })}
                        </section>
                        <div className="zdata zfeed noLog">
                            <div className="imgbox"><img src="https://cdn.dscovr.com/images/causes-null_ico2.svg"
                                                         alt=""/></div>
                            <div className="desc">
                                <p><strong>Support a cause, voice your concerns, find innovative solutions and unite
                                    with the world to protect nature better.</strong></p>
                                <p className="jointxt"><a href="javascript:void(0)"
                                                          onClick={(e) => this.signUpRequired('/cause/' + this.props.slug)}>Join
                                    Us</a> to be a
                                    part of a bigger movement <br/>for Mother Nature.</p>
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname} history={this.props.history}/> : null}
                {this.state.signUpRequired === true ? <SignUpModal  handleSignUpClose={this.handleSignUpClose} history={this.props.history}/> : null}
            </>
        } else {
            return <Tab.Container
                onSelect={this.handleTabSelect}
                activeKey={this.state.activeKey}
                defaultActiveKey={this.state.latestTabKey}
                id="homeposthnav"
                unmountOnExit={true}
            >
                <div className="posthnavtab">
                    <div className="mcustHorizMenu">
                        <div className="mcustHorizinn">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey={this.state.latestTabKey} title="Latest">
                                        Latest
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={this.state.photosTabKey} title="Photos"
                                    >
                                        Photos
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={this.state.videosTabKey} title="Videos">
                                        Videos
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={this.state.articlesTabKey} title="Blogs">
                                      Articles
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={this.state.audioTabKey}
                                        title="Audio"
                                    >
                                        Audio
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey={this.state.latestTabKey}>
                        <CauseLatestPostList
                            key={this.state.latestTabKey}
                            causeId={this.props.causeId}
                            userId={this.props.userId}
                            enablePostSharing={true}
                            joined={this.props.joined}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.photosTabKey}>
                        <CausePhotosPostList
                            key={this.state.photosTabKey}
                            causeId={this.props.causeId}
                            userId={this.props.userId}
                            enablePostSharing={true}
                            joined={this.props.joined}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.videosTabKey}>
                        <CauseVideoPostList
                            key={this.state.videosTabKey}
                            causeId={this.props.causeId}
                            userId={this.props.userId}
                            enablePostSharing={true}
                            joined={this.props.joined}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.articlesTabKey}>
                        <CauseArticlePostList
                            key={this.state.articlesTabKey}
                            causeId={this.props.causeId}
                            userId={this.props.userId}
                            enablePostSharing={true}
                            joined={this.props.joined}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={this.state.audioTabKey}>
                        <CauseAudioPostList
                            key={this.state.audioTabKey}
                            causeId={this.props.causeId}
                            userId={this.props.userId}
                            enablePostSharing={true}
                            joined={this.props.joined}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        postCreationResponse:
            state.postReducer && state.postReducer.postCreationResult.value,
    };
};

export default connect(mapStateToProps)(CausePostNavbar);
