import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import { authService } from "../auth";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class LeftPanelUnauth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.$(".ltWPONDLogo").addClass('hide');
        }, 15000)
    }


    render() {
        return (
            <div className="col-md-3">
                <div className="leftpanel nologin">
                    <div className="sidemenu">
                        <ul>
                            {/* <li className="feeds"><NavLink to={"/feeds"} activeClassName="active">Feeds</NavLink></li>
                            <li className="explore"><NavLink to={"/explore"} activeClassName="active">Explore</NavLink></li> */}
                            <li className="studio"><NavLink to={"/studio"} activeClassName="active">Studio</NavLink></li>
                            <li className="message"><NavLink to={"/message"} activeClassName="active">Message</NavLink></li>
                            <li className="network"><NavLink to={"/network"} activeClassName="active">Network</NavLink></li>
                            <li className="circle"><NavLink to="/communities" activeClassName="active">Community</NavLink></li>
                            <li className="project"><NavLink to={"/project"} activeClassName="active">Project</NavLink></li>
                            {/* <li className="causes"><NavLink to={"/causes"} activeClassName="active">Causes</NavLink></li>
                            <li className="forum"><NavLink to="/forum" activeClassName="active">Forum</NavLink></li>
                            <li className="poll"><NavLink to="/poll" activeClassName="active">Poll</NavLink></li> */}
                        </ul>
                        <div className="topDropdownMob">
                            {this.state.loginSuccess ? 
                                <Link className="dropdown-item" to="/dashboard-settings">Dashboard and Settings</Link>
                            // <div className="dropdown">
                            //     <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage</a>
                            //     <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                            //         {this.state.loginSuccess ? <Link className="dropdown-item" to="/manage-invitation">Manage Invitations</Link> : null}
                            //         {this.state.loginSuccess ? <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link> : null}
                            //     </div>
                            // </div>
                             : null}
                            <div className="dropdown">
                                <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About WeNaturalists</a>
                                <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                    <Link className="dropdown-item" to="/about-us">About Us</Link>
                                    <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                    <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Terms of Use</a>
                                <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">
                                    <Link className="dropdown-item" to="/UserAgreement">User agreement</Link>
                                    <Link className="dropdown-item" to="/PrivacyPolicy">Privacy Policy</Link>
                                    <Link className="dropdown-item" to="/TermsConditions">Terms & conditions</Link>
                                    <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                    <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                    <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                    <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</a>
                                <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                    <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default (LeftPanelUnauth);
