import React, { memo } from "react";
import {Link, NavLink} from "react-router-dom";
import {setPref} from "./reducer/userPref";
import {connect} from "react-redux";
import {setNetworkCount} from "./reducer/network";
import axios from "axios";
import {authService} from "./auth";
import {setMessageCount, setMessageCountOnSuccess} from "./reducer/message";
import PostSectionPopUp from "./PostSectionPopUp";
import { getConfigForLicence } from "./services/LicenceApi";
import { getCDNUrl, redirectToProfilePage, redirectToWenat, redirectToWenatPage } from "./commons/CommonFunctions";
import { getUserByUserId } from "./services/UserApi";
class LeftPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pollCount: this.props.pollCount,
            newUserChatCnt: this.props.newUserChatCnt,
            userForumCount:0,
            userPollCount:0,
            projectCount: this.props.projectCount,
            networkCount: this.props.networkCount,
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            year: "",
            adminAccess: false,
            wenat: false
        };
    }

    async getUserProfileData() {
        let response = await getUserByUserId({"id": this.state.userData.userId, "otherUserId": this.state.userData.userId});
        if(response?.message === "Success!") {
            this.setState({ 'wenat': response.body?.wenat });
        }
    }

    componentDidMount() {
        this.props.getPref();
        if (this.state.userData && this.state.userData.type === 'INDIVIDUAL') {
            this.props.getNetworkCount()
        }

        if (this.props.messageTab === undefined) {
            this.getAllUnReadMessageBYAllTab();
        }
        this.getConfig();
        this.getUserProfileData();
        authService.setGeoLocation();
        setTimeout(() => {
            window.$(".ltWPONDLogo").addClass('hide');
        }, 15000)
    }

    componentWillUnmount() {
        window.$("#AcceptPopUp").modal("hide");
        window.$("#addForum").modal("hide");
        clearInterval(this.interval);
        this.interval = null;
    }


    getAllUnReadMessageBYAllTab() {
        axios.get(process.env.REACT_APP_userServiceURL + '/messaging/message_service/api/message/getAllUnReadMessageBYAllTab?userId=' + this.state.userData.userId +'&privateEntityId='+this.state.userData.entityId, {withCredentials: true}).then(response => {
            if (response && response.data.body !== null) {
                this.setState({newUserChatCnt: response.data.body})
            }
        });
    }

    getConfig = async () => {
        let response = await getConfigForLicence({ "userId": this.state.userData.userId, "entityId": this.state.userData.entityId });
        if (response?.message === "Success!") {
            this.setState({
                userLicenseData: response?.body
            })
            if(this.props.getLicenseConfig) {
                this.props.getLicenseConfig(response?.body);
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.projectCount !== undefined && this.state.projectCount !== props.projectCount) {
            this.setState({projectCount: props.projectCount});
        }

        if (props.newUserChatCnt !== undefined &&   this.state.newUserChatCnt !== props.newUserChatCnt) {
            this.setState({newUserChatCnt: props.newUserChatCnt});
            if(this.props.resetNewUserChatCnt){
                this.props.resetNewUserChatCnt();
            }
        }

        if (props.messageCount !== undefined &&  this.state.newUserChatCnt !== props.messageCount) {
            this.setState({newUserChatCnt: props.messageCount});
            this.props.setMessageCountOnSuccess();
        }



        if (props.messageReceivedResponse !== undefined
            && props.messageReceivedResponse.payload !== undefined
            && props.messageReceivedResponse.payload.body !== undefined
            && props.messageReceivedResponse.payload.body.privateEntityId
            && this.state.newUserChatCnt !== undefined
            && this.props.messageTab === undefined) {


            let messageJson = JSON.parse(props.messageReceivedResponse.payload.body);
            if (messageJson.deletedType === "All" || messageJson.messageType === "Acknowledge" || messageJson.senderId === this.state.userData.userId) {
            } else if (messageJson.senderId !== this.state.userData.userId && messageJson.messageGroupType !== 'NewsGroup' && messageJson.privateEntityId) {
                this.setState({"newUserChatCnt": this.state.newUserChatCnt + 1});
            }
        }
    }


    redirectToWenat = (url) => {
        if (window.ReactNativeWebView) {
            window.location.href = url;
        } else {
            window.open(url,"_blank")
        }
    }

    render() {
        const { userLicenseData, wenat } = this.state;
        return (
            <>

                <div className="col-md-3">
                    <div className="leftpanel">
                        <div className="sidemenu">
                            {userLicenseData?.type && userLicenseData?.type !== "SUPER_ADMIN" &&                             
                                <div style={{position: 'relative', paddingTop: '28px'}}>
                                    <div className="userprofile saName" onClick={() => redirectToProfilePage(userLicenseData?.userCustomUrl)} style={{cursor: "pointer"}}>
                                        <div className="spotHere">You are Here</div>
                                        <figure>
                                            <img src={userLicenseData?.profileImage ? getCDNUrl(userLicenseData?.profileImage) : userLicenseData?.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"} alt="" />
                                        </figure>
                                        <span>{userLicenseData?.name}</span>
                                    </div>
                                </div>
                            }
                            <ul>
                                <li>
                                    <NavLink to="/studio" className="studio" activeClassName="active"
                                        isActive={(_, { pathname }) => ["/studio"].includes(pathname)}>
                                        Studio
                                    </NavLink>
                                </li>
                                {this.state.loginSuccess && this.state.userData.type === 'INDIVIDUAL' &&
                                    <li>
                                        <NavLink to="/network" className="network" activeClassName="active"
                                            isActive={(_, { pathname }) => ["/network", "/popular-users", "/connect-requests", "/connected", "/following", "/pages", "/network-groups", "/followers", "/youfollow", "/community-page", "/organization", "/groups", "/people-you-may-know"].includes(pathname)}>
                                            Network
                                        </NavLink>
                                    </li>
                                }
                                <li>
                                    <NavLink to="/communities" className="circle" activeClassName="active">
                                        Community
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/project" className="project" activeClassName="active"
                                        isActive={(_, { pathname }) => ["/showMore/runningProject", "/project", "/showMore/completedProject"].includes(pathname)}>
                                        Projects
                                        {this.state.projectCount && this.state.projectCount > 0 ?
                                            <span>{this.state.projectCount}</span> : ''}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/forum" className="forum" activeClassName="active"
                                        isActive={(_, { pathname }) => ["/forum"].includes(pathname)}>
                                        Forum
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/poll" className="poll" activeClassName="active"
                                        isActive={(_, { pathname }) => ["/poll"].includes(pathname)}>
                                        Poll
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/message" className="message" activeClassName="active">
                                        Message
                                        {this.state.newUserChatCnt && this.state.newUserChatCnt > 0 ?
                                            <span>{this.state.newUserChatCnt}</span> : ''}
                                    </NavLink>
                                </li>
                            </ul>
                            
                            <div className="ltpostbuttwrap">
                                <a href="#addpostpop" className="btn" data-toggle="modal">
                                    <i className="iAddPost"/>Post
                                </a>
                            </div>

                            <div className="topDropdownMob">
                            {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showInThreeDot)) && <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>People of Nature Awards</Link>}
                                <Link className="dropdown-item" to="/dashboard-settings">Dashboard and Settings</Link>
                                {/* <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                        {this.state.loginSuccess && <Link className="dropdown-item" to="/manage-invitation">Manage Invitations</Link>}
                                        {this.state.loginSuccess && <Link className="dropdown-item" to={`/${this.state.userData.customUrl}/studio`}>Manage Studio</Link>}
                                        {this.state.loginSuccess && <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link>}
                                        <Link className="dropdown-item" to="/hidden-content">Hidden Content</Link>
                                        <Link className="dropdown-item" to="/analytics">Analytics</Link>
                                    </div>
                                </div> */}
                                {this.state.wenat && 
                                    <a className="dropdown-item" href="javascript:void(0)" onClick={()=>this.redirectToWenat(`${process.env.REACT_APP_weNatURL}/profile/${this.state.userData?.customUrl}`)}>Switch to WeNaturalists</a>
                                }
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About WeNaturalists</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                        <Link className="dropdown-item" to="/about-us">About Us</Link>
                                        <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                        <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                        {/* <Link className="dropdown-item" to="/world-people-of-nature-day">People of Nature Day</Link> */}
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Terms of Use</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">
                                        <Link className="dropdown-item" to="/UserAgreement">User agreement</Link>
                                        <Link className="dropdown-item" to="/PrivacyPolicy">Privacy Policy</Link>
                                        <Link className="dropdown-item" to="/TermsConditions">Terms & conditions</Link>
                                        <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                        <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                        <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                        <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</a>
                                    <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">        
                                        <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                        <Link className="dropdown-item" to="/Feedback">Feedback</Link>
                                        <Link className="dropdown-item" to="/signout">Sign Out</Link>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>

                <PostSectionPopUp postPopUpOpenFlag={this.props.postPopUpOpenFlag} />             
                    
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        preference: state.preference,
        projectCount:
            state.preference &&
            state.preference.pref.value &&
            state.preference.pref.value.totalOpportunityCount,
        networkCountPref: state.networkCountPref,
        networkCount:
            state.networkCountPref &&
            state.networkCountPref.networkCountResult.value &&
            state.networkCountPref.networkCountResult.value.page &&
            state.networkCountPref.networkCountResult.value.page.totalElements,
        messageCountPref: state.messageCountPref,
        messageCount:
            state.messageCountPref &&
            state.messageCountPref.messageCountResult &&
            state.messageCountPref.messageCountResult.value,

        messageReceivedResponse:
            state.notificationsReducer &&
            state.notificationsReducer.messageReceivedResult &&
            state.notificationsReducer.messageReceivedResult.value,
        yearData: state.WpondReducer?.yearData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPref: () => dispatch(setPref()),
        getNetworkCount: () => dispatch(setNetworkCount()),
        getMessageCount: () => dispatch(setMessageCount()),
        setMessageCountOnSuccess:()=>dispatch(setMessageCountOnSuccess()),
    };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(LeftPanel));
