import {call, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import * as _ from "lodash";
import {authService} from "../../auth";
import {SET_PREF, setPrefOnSuccess} from "../../reducer/userPref";

import httpService from '../../AxiosInterceptors';

httpService.setupInterceptors();

export const getPage = (state) => state.nextPage;

function prefApi() {
    let userId =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let userType = authService.getUserDetailsFromLocalStorage().type;
    let parentEntityId = authService.getUserDetailsFromLocalStorage().entityId;
    let url =
        process.env.REACT_APP_userServiceURL +
        `/backend/userPreference/fetch-userpreference-by-userid/${userId}/${userType}?privateEntityId=${parentEntityId}`;

    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response;
    }).catch((e) => {
        console.log(e);
        return e;
    });
}

/** saga worker that is responsible for the side effects */
function* loadPref(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(prefApi, action.payload);

        if (
            response &&
            response.data &&
            response.data.body &&
            !_.isNull(response.data.body)
        ) {
            yield put(setPrefOnSuccess(response.data.body));
        } else {
            console.log(response);
        }
    } catch (e) {
        // catch error on a bad axios call
        // alert using an alert library
        console.log(e);
    }
}


export default function* watchPrefLoad() {
    yield takeEvery(SET_PREF, loadPref);
}
