import React, { Component } from 'react'
import axios from 'axios';
import * as FileSaver from "file-saver";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { convertMsToTime } from './CommonFunctions';
import {authService} from "../auth";

const ErorrMsgPopUp = withReactContent(alertPopUp)
class DownloadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            processingDeleteRequest: false
        }
    }

    onClickDownloadModal = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/profile/download/config?entityId=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data?.body?.downloadAllowed) {
                this.setState({
                    isModalOpen: true
                }, () => {
                    window.$("#downloadComponentPopup").modal("show");
                })
            } else {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <>You can download after {convertMsToTime(response.data?.body?.nextDownloadTime)}</>,
                    })
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    onCloseDownloadModal = () => {
        window.$("#downloadComponentPopup").modal("hide");
        this.setState({
            isModalOpen: false
        })
    }


    handleDownloadProfileData = () => {
        this.setState({
            processingDeleteRequest: true
        })
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/pdf/${this.props.type === "CIRCLE" ? "circle" : "profile"}/generate?${this.props.type === "CIRCLE" ? "circleId" : "userId"}=${this.props.id}&privateEntityId=${authService.getUserDetailsFromLocalStorage().entityId}`,
            withCredentials: true,
            responseType: "arraybuffer",
            headers: {'Content-Type': 'application/pdf'},
        }).then((response) => {
            if (response && response.data) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                let filename = "WeNat_"+this.props.fileName+".pdf";
                FileSaver.saveAs(url, filename);
                this.setState({
                    processingDeleteRequest: false
                })
                this.onCloseDownloadModal();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                processingDeleteRequest: false
            })
        });
    }


    render() {
        const { isModalOpen, processingDeleteRequest } = this.state;
        return (
            <>
                <div className='dwnBtnOutside'>
                    <a href="javascript:void(0)" className="continfo" onClick={() => this.onClickDownloadModal()}>
                        <i className="iDownloadFile" /> Download
                    </a>
                </div>

                {isModalOpen && 
                    <div className="modal fade dgpopup confirmpop" id={"downloadComponentPopup"} tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                {!processingDeleteRequest && 
                                    <button type="button" className="close" onClick={(e) => this.onCloseDownloadModal()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                }
                                <div className="confirm-popup-modal-body">
                                    Are you sure you want to download {this.props.type === "CIRCLE" || this.props.type === "COMPANY_OTHERS" ? "profile of " + this.props.userName : "your profile"}?
                                </div>
                                <div className="modal-footer confirm-popup-modal-footer">
                                    <button type="button" className={"btn btn-primary" + (processingDeleteRequest ? " loading" : "")} disabled={processingDeleteRequest} onClick={() => this.handleDownloadProfileData()}>
                                        {processingDeleteRequest ? <>Processing <div class='custom-loader'></div></> : "Yes"}
                                    </button>
                                    {!processingDeleteRequest &&
                                        <button type="button" className="btn btn-secondary" onClick={(e) => this.onCloseDownloadModal()}>
                                            No
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default DownloadComponent;
