import React, { memo } from 'react';
import { authService } from '../auth';
import PresentationViewGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { download_file_pdf, getCDNUrl } from '../commons/CommonFunctions';
import PresentationFullscreen from './PresentationFullscreen';

class PresentationPostView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            loginRequired: false,
            presentationViewImages: [],
            pdfDownloadableImageUrl: "",
            documentTitle: this.props.documentTitle ? this.props.documentTitle : ""
        }
    }

    customControlsPresentation = (props) => {
        return (
            <>
                <h4 className='tresentation-title'>{this.props.documentTitle ? this.props.documentTitle : this.state.documentTitle}</h4>
                {this.state.loginSuccess && this.state.pdfDownloadableImageUrl && !this.props.isStudioPage ? 
                    <button type="button" className="btn download-attachment" onClick={(e) => download_file_pdf(e, this.state.pdfDownloadableImageUrl, this.props.documentTitle ? this.props.documentTitle : this.state.documentTitle)}>
                        <i className="iDownloadFile" />
                    </button> :
                    null
                }
            </>
        );
    };

    getPresentationViewImages = (attachments) => {
        let presentationViewImages = [];
        if (attachments) {
            this.setState({ showPresentationSection: true })
            attachments && attachments.relatedImages && attachments.relatedImages.map((data) => {
                presentationViewImages.push({ original: data, thumbnail: data })
            })
        }
        this.setState({
            presentationViewImages: presentationViewImages,
            showPresentationSection: presentationViewImages.length > 0 ? true : false,
            pdfDownloadableImageUrl: attachments.pdfDownloadableImage,
            documentTitle: attachments?.title
        });
    }

    componentDidMount() {
        this.getPresentationViewImages(this.props.attachments);
    }

    renderFullscreenButton = (onClick, isFullscreen) => (
        <PresentationFullscreen onClick={onClick} isFullscreen={isFullscreen} />
    )

    render() {
        const {presentationViewImages, showPresentationSection, pdfDownloadableImageUrl, loginSuccess} = this.state;
        return (
            showPresentationSection ? 
                <div className="presentation-view-gallery">
                    <PresentationViewGallery 
                        items={presentationViewImages} 
                        showIndex={true} 
                        autoPlay={false} 
                        showThumbnails={false} 
                        showPlayButton={false} 
                        disableKeyDown={true} 
                        infinite={false} 
                        renderCustomControls={this.customControlsPresentation}
                        renderFullscreenButton={this.renderFullscreenButton}
                     />
                </div> :
            null
        )
    }
}

export default memo(PresentationPostView);