import React from 'react'
import Header from '../Header';
import LeftPanel from '../LeftPanel';
import './main-userdashboard.min.css';
import {Link} from 'react-router-dom';
import {authService} from '../auth';
import { getConfigForLicence } from '../services/LicenceApi';
class UserDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            licenseUserDetails: {}
        };
    }

    getConfig = async () => {
        let response = await getConfigForLicence({"userId": this.state.userData.userId, "entityId": this.state.userData.entityId});
        if(response?.message === "Success!") {
            this.setState({
                licenseUserDetails: response.body
            })
        }
    }

    componentDidMount() {
        document.title = "User Dashboard | WeNaturalists";
        this.getConfig();
    }

    render() {
        const { licenseUserDetails } = this.state;
        return <div className="wrapper projectpage networkpage">
            
            <Header/>
            <main className="dgcontainer hm3col">
                <div className="container">
                    <div className="row">

                        <LeftPanel/>

                        <div className="col-md-9">                            
                            <div className="projectinn userDashbrdPage">

                                <h1 className="h1ttl">User Dashboard</h1>
                                <div className="userDashbrdWrap">
                                    <div className="userDashbrdLinkTab">
                                        {licenseUserDetails?.type === "SUPER_ADMIN" && 
                                            <Link className="userDashbrdLinkTabgrid dgcard iDonation-stats" to={{pathname: "/fund-raise"}}>
                                                <i className="iDonation-stats" />
                                                <p>Fund Raise Stats</p>
                                            </Link>
                                        }
                                        <Link className="userDashbrdLinkTabgrid dgcard ianalytics" to={{pathname: "/analytics"}}>
                                            <i className="ianalytics" />
                                            <p>Analytics</p>
                                        </Link>
                                        <Link className="userDashbrdLinkTabgrid dgcard iDraft_F" to={{pathname: "/forum-poll-draft"}}>
                                            <i className="iDraft_F" />
                                            <p>Drafts</p>
                                        </Link>
                                        <Link className="userDashbrdLinkTabgrid dgcard iHidden-Content" to={{pathname: "/hidden-content"}}>
                                            <i className="iHidden-Content" />
                                            <p>Hidden Content</p>
                                        </Link>
                                    </div>
                                </div>

                                <div className="othuserDashbrdLinks">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/security-and-login"}}>
                                                <i className="iSheild" /> Account Settings
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/manage-invitation"}}>
                                                <i className="iManage-Invitations" /> Manage Invitations
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: `/${this.state.userData.customUrl}/studio`}}>
                                                <i className="istudio" /> Manage Studio
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/saved-posts"}}>
                                                <i className="iBookmark_F" style={{fontSize: '30px'}} /> Saved Items
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/my-forum"}}>
                                                <i className="iForum_F" style={{fontSize: '30px'}} /> My Forum
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/my-poll"}}>
                                                <i className="iPolls_FL" style={{fontSize: '30px'}} /> My Poll
                                            </Link>
                                        </div>
                                        {licenseUserDetails?.accountInfoTabVisible && 
                                            <div className='col-sm-6'>
                                                <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/account-info"}}>
                                                    <i className="iAccount-info" style={{fontSize: '30px'}} /> Account Info
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>

        </div>
    }
}

export default UserDashboard;
