import React from 'react';
import axios from "axios";
import FooterDiv from "../FooterDiv";
import OtpInput from "react-otp-input";

import httpService from '../AxiosInterceptors';

httpService.setupInterceptors();

class ForgotPasswordOtp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            otp: '',
            numInputs: 6,
            separator: ' ',
            isDisabled: false,
            hasErrored: false,
            isInputNum: true,
            OTP: '',
            setOTP: '',
            data: {},
            resendOTP: false,
            timer: 0,
            message: '',
            success: false,
            minutes: 10,
            seconds: 0
        };

    }

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    componentDidMount() {
        let os = this.getMobileOperatingSystem()
        if (os !== 'iOS') {
            window.$(".OTP").keydown(function (event) {
                // Allow only backspace and delete
                if (event.keyCode == 46 || event.keyCode == 8) {
                    // let it happen, don't do anything
                } else {
                    // Ensure that it is a number and stop the keypress
                    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        event.preventDefault();
                    }
                }
            });
        }
        this.setState({data: this.props.location.state.resetPassword});

        this.resendButtonWait();
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    handleOtpChange = otp => {
        this.setState({otp});
    };


    resendButtonWait = (event) => {
        this.myInterval = setInterval(() => {
            const {seconds, minutes} = this.state

            if (seconds > 0) {
                this.setState(({seconds}) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({minutes}) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }


    handleSubmit = (event) => {
        event.preventDefault();

        let postBody = {
            "userId": this.state.data.userId,
            "transactionId": this.state.data.transactionId,
            "otp": this.state.otp
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/unauth/otp/verify',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.props.history.push({
                    pathname: '/change-password',
                    state: {
                        changePassword: this.state.data
                    }

                })
            }
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                setTimeout(() => {
                    this.revokeError();
                }, 5000);
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });

            }
            this.clearOtp();
        });

    };

    clearOtp = () => {
        this.setState({otp: ''});
    };

    revokeError() {
        this.setState({messages: ''});
        this.setState({error: false});
    }

    resendOtp = (event) => {
        event.preventDefault();
        let postBody = {
            "userId": this.state.data.userId,
            "mediaData": this.state.data.mediaData,
            "otpType": 'RESET_PASSWORD',
            "otpSource": this.state.data.email ? 'EMAIL' : 'MOBILE'
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/otp/re-send',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({
                    message: 'OTP resent successfully',
                    success: true,
                    resendOTP: false
                });
                let data = this.state.data;
                data.transactionId = res.body.transactionId;
                this.setState({data: data, minutes: 10, seconds: 0});
                this.resendButtonWait()
                setTimeout(() => {
                    this.setState({
                        message: '',
                        success: false
                    });
                }, 5000);

            }
        }).catch((err) => {
            console.log(err);
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });

            }
        });

    };

    render() {
        const {
            OTP,
            setOTP,
            otp,
            numInputs,
            separator,
            isDisabled,
            hasErrored,
            isInputNum,
            timer,
            minutes,
            seconds
        } = this.state;
        return <section className="signup-wrapper">
            <div className="signup-container pt-3">
                <div className="signup-box text-center forgotpass">
                    <div className="signup-boxinn otpwrap">
                        <a href="/" className='logotop'><span>my</span><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" class="logotop"/></a>
                        <h1 className="signup-header-text" style={{paddingTop: '48px'}}>Authentication</h1>
                        <p>Enter the 6-digit OTP sent on your {this.state.data.email ? 'email' : 'mobile'}, <a href="/#"
                                                                                                               className="otp-link">{this.state.data.userName}</a>
                        </p>
                        <div className="signup-form-container">
                            <form className="otp-form" onSubmit={this.handleSubmit}>
                                <div className="optfield">
                                    <OtpInput
                                        numInputs={numInputs}
                                        isDisabled={isDisabled}
                                        hasErrored={hasErrored}
                                        errorStyle="error"
                                        className="OTP"
                                        onChange={this.handleOtpChange}
                                        separator={<span>{separator}</span>}
                                        value={otp}
                                        inputStyle="OTP"
                                        shouldAutoFocus
                                    />
                                    {this.state.error &&
                                    < div className="login-feedback-error">
                                        <strong/> {this.state.error.message}.
                                    </div>
                                    }
                                </div>
                                <div>
                                    <button
                                        className="login-btn btn btn-primary mt-3"
                                        disabled={otp.length < numInputs} type="submit">
                                        Submit
                                    </button>
                                    <p className="resend">
                                        {minutes === 0 && seconds === 0
                                            ? <button type="button"
                                                      onClick={this.resendOtp}
                                                      className="counter_Btn btn mt-1">
                                                Resend
                                            </button>
                                            : <h1>OTP will expire
                                                in {minutes + ' min '}:{' ' + seconds < 10 ? `0${seconds} sec` : ' ' + seconds + ' sec'}</h1>
                                        }
                                        {
                                            this.state.success ? this.state.message : null
                                        }
                                    </p>

                                </div>
                            </form>
                        </div>


                    </div>
                </div>
                <FooterDiv/>
            </div>
        </section>


    }
}

export default ForgotPasswordOtp
